import React, {Component} from 'react';

//redux
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
// actions
import {ActionCreators} from '../../../actions';

//main NavBar
import MainNav from '../../../components/global_nav';
import MainSideBar from '../../../components/global_sidebar';

//custom sidebar
import SubSideBar from './sub_sidebar';

//select tag
import Select from 'react-select';

//custom styles
import '../../../stylesheet/styles.css';

import app from '../../../helpers/feathers';

//datatables
import '../../../stylesheet/datatables/datatables.css';

const $ = require('jquery');
$.DataTable = require('datatables.net');

class outgoing extends Component {
    constructor(props) {
        super(props);

        this.state = {
            sold: [],
            productsToTransfer: [],
            selectedBrand: '',
            selectedOption: '',
            selectedBranch: '',
            filter_color: null,
            filter_chassis: null,
            filter_model: '',
            selectChanged: false,
            filter_branch: null,
            selectBranchChanged: false,
            product_count: 0,
            modelsObj: [],
            mainId: '',
            outgoing: [],
        };
    }

    componentWillMount() {
        app.service('branches').find({
            query: {
                branch_name: this.props.userData.branch_info.branch_name
            }
        }).then((d) => {
            const v = d.data[0]._id;

            this.setState({
                mainId: v
            })

            this.props.actions.outgoingProducts(v).then(data => {
                this.props.actions.setOutgoingProducts(data);
            });
        })
        .catch((err) => {
            console.log(err)
        })
    }

    componentDidMount() {
        const productTable = $('.tbl-outgoing').DataTable({
            data: this.state.outgoing,
            columns: [
                {"title": "ID"},
                {"title": "MODEL NAME"},
                {"title": "BRAND NAME"},
                {"title": "COLOR"},
                {"title": "ENGINE NO"},
                {"title": "BRANCH"},
                {"title": "DATE TRANSFERRED"},
            ],
            columnDefs: [
                {
                    targets: [0],
                    visible: false
                }
            ],
            'dom': "<'row'<'col-sm-6'l><'col-sm-6'p>>" +
                "<'row'<'col-sm-12'tr>>" +
                "<'row'<'col-sm-12'i>>",
        });

        $('.search').keyup(function () {
            productTable.search($(this).val()).draw();
        });
    }

    updateData = data => {
        this.setState({
            outgoing: data,
            product_count: data.length
        });

        const table = $('.tbl-outgoing').DataTable();
        table.clear();
        table.rows.add(data);
        table.draw();
    }

    handleChange = (selectedOption) => {
        const value = selectedOption;

        if (value != null) {
            this.setState({
                selectedOption: value,
                filter_model: value,
                selectChanged: true
            });
        } else {
            this.setState({
                selectedOption: '',
                filter_model: '',
                selectChanged: false
            })
        }
    }

    handleChangeBrand = (selectedOption) => {
        const value = selectedOption;

        if (value != null) {
            this.setState({
                selectedBrand: value,
                selectedBrandChanged: true
            });
            const model = this.state.selectedOption.value,
                brand = value.value === 'all' ? null : value.value;

            this.props.actions.findChassisUsingBrand(brand)
                .then((d) => {
                    const models = d.data,
                        modelsObj = [];

                    models.map((v) => {
                        modelsObj.push({
                            label: v.model_name,
                            value: v._id
                        })
                    })

                    this.setState({
                        selectedOption: '',
                        filter_model: '',
                        modelsObj: modelsObj,
                    })
                })
        } else {
            this.setState({
                selectedBrand: '',
                selectedBrandChanged: false,
                modelsObj: [],
                selectedOption: '',
            })
        }
    }

    handleChangeBranch = (selectedOption) => {
        const value = selectedOption;

        if (value === null) {
            this.setState({
                selectedBranch: '',
                filter_branch: value,
                selectBranchChanged: false
            });
        } else {
            this.setState({
                selectedBranch: value,
                filter_branch: value,
                selectBranchChanged: true
            });
        }
    }

    filterOutgoingProducts = () => {
        const branch = this.state.mainId ? this.state.mainId : null;
        let {brand, model, engine} = this.refs;

        if (brand.value === '' && model.value === '' && engine.value === '') {
            this.updateData([]);
            return;
        }
        const query = {};
        if (brand.value !== '') {
            query.unit_brand_name = {
                $regex: brand.value
            }
        }
        if (model.value !== '') {
            query.unit_model_name = {
                $regex: model.value
            }
        }
        if (engine.value !== '') {
            query.engine_number = {
                $regex: engine.value
            }
        }

        query.branch = branch;
        query.transferred = {
            $in: [1]
        };
        query.type = 0;
        query.brandNew = 1;

        this.props.actions.outgoingProductsFilter(query)
            .then((data) => {
                this.updateData(data);
            })
    }

    branchOption = () => {
        const array = this.props.branch,
            newArray = [];

        array.map((obj) => {
            if (obj.value !== 'all' && obj.label !== 'MAIN') {
                newArray.push(obj)
            }
        })
        return newArray
    }

    render() {
        return (
            <div className="">
                {/* main NavBar component*/}
                <MainNav historyProp={this.props.history} usersProp={this.props.userData}/>
                <div className="container-fluid">
                    <div className="row full-width">
                        <div className="sidebar">
                            <div className="sidebar-content">
                                <MainSideBar page="inventory"/>
                            </div>
                        </div>
                        <SubSideBar history={this.props.history} allProps={this.props}
                                    userPermission={this.props.userPermission} pageName="outgoing"/>
                        <div className="sub-inv-main-content">
                            <div className="container-fluid">
                                <div className="application-container">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <h4 className="subheader-title">Outgoing Units</h4>
                                        </div>
                                        <div className="form-inline">
                                            <div className="col-md-12 subheader-label">
                                                ADVANCED FILTER
                                            </div>
                                            <div className="col-md-12">
                                                <br/>
                                            </div>
                                            <div className="container-fluid">
                                                <div className="row">
                                                    <div className="col">
                                                        <input className="form-control" placeholder="Brand"
                                                               ref="brand" onKeyUp={this.filterOutgoingProducts}/>
                                                    </div>
                                                    <div className="col">
                                                        <input className="form-control" placeholder="Model"
                                                               ref="model" onKeyUp={this.filterOutgoingProducts}/>
                                                    </div>
                                                    <div className="col">
                                                        <input className="form-control"
                                                               placeholder="Engine Number" ref="engine"
                                                               onKeyUp={this.filterOutgoingProducts}/>
                                                    </div>
                                                    <div className="col">
                                                        <button className="btn btn-block btn-second-primary"
                                                                onClick={() => this.filterOutgoingProducts()}>Search
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <br/>
                                    <div className="row">
                                        {/*<div className="col-md-12">
                                        <span>Result : {this.state.product_count}</span>
                                    </div>*/}
                                        <div className="col-md-12">
                                            <table className="table tbl-outgoing tbl-mobile">
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    app: state.feathersClient.app,
    product: state.product.outgoingProducts,
    userData: state.login.userData,
    userPermission: state.login.userPermission,
    models: state.category.modelsSelect,
    brandsSelect: state.category.brandsSelect,
    branch: state.category.branchesSelect,
});

function mapDispatchToProps(dispatch) {
    return {actions: bindActionCreators(ActionCreators, dispatch)}
}

export default connect(mapStateToProps, mapDispatchToProps)(outgoing);
