import React, {Component} from 'react';
import app from "../../../../helpers/feathers";

// react-router
import {Link} from 'react-router-dom';

//redux
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
// actions
import {ActionCreators} from '../../../../actions';

//main NavBar
import MainNav from '../../../../components/global_nav';
import MainSideBar from '../../../../components/acc_sidebar';
import SyncDataModal from '../../../../components/sync_data_modal';

//sub side bar
import SubSideBar from '../sub_sidebar';
import SelectUnitType from '../../accounting/select_customer_modal';
import EditCustomerModal from './edit_customer';
import AddToRepoModal from '../../accounting/add_to_repo_modal';

//custom styles
import '../../../../stylesheet/styles.css';

//datatables
import '../../../../stylesheet/datatables/datatables.css';
// import OldRecordsCustomerFullypaid from "./acc_customer_fullypaid";

const $ = require('jquery');
$.DataTable = require('datatables.net');

let that;

class OldRecordsCustomerRepossessedSh extends Component {

    constructor(props) {
        super(props);

        this.state = {
            data: [],
            customerName: "",
            accountNumber: "",
            totalCustomer: 0
        };
    }

    componentWillMount() {
        that = this;
        // this.getAllCustomers();

        // products select options
        that.props.actions.getProducts().then((data) => {
            that.props.actions.setProducts(data);
        });

        //inventory
        this.props.actions.getAllProducts().then((data) => {
            if (data) {
                that.props.actions.setAllProducts(data);
            }

        });

        // models for secondhand units in autosuggest
        app.service('motorcycle-models').find()
            .then((data) => {
                const d = data.data,
                    models = [];

                d.map((v) => {
                    models.push({
                        name: v.model_name
                    })
                })

                that.props.actions.setModelsForSecondhand(models);
            })
        .catch((err) => {
            console.log(err)
        })


    }

    componentDidMount() {
        const applicationTbl = $('.tbl-customer-repo').DataTable({
            "columnDefs": [
                {
                    targets: [0],
                    visible: false
                },
                {
                    targets: [1],
                    width: '20%'
                },
                {
                    targets: [4],
                    width: '10%'
                },
            ],
            "order": [[ 5, "desc" ]],
            columns: [
                {title: "obj"},
                {title: "NAME"},
                {title: "ACCOUNT NO."},
                {title: "BRANCH"},
                {title: "MODEL"},
                {title: "DATE CREATED"},
                {title: "ACTION"}
            ],
            "sDom": '<"bottom"<t>ip><"clear">'
        });


        $('.search').keyup(function () {
            applicationTbl.search($(this).val()).draw();
        });

        $('.tbl-customer-repo').on('click', 'button.btn-new-view', function () {
            const data = applicationTbl.row($(this).parents('tr')).data();
            that.props.actions.setCustomerInfo(data[0]);
            that.props.history.push('/view_customer_old_records/');
        });

        $('.tbl-customer-repo').on('click', 'button.btn-new-edit', function () {
            const data = applicationTbl.row($(this).parents('tr')).data();
            that.props.actions.setCustomerInfo(data[0]);
            $('#edit_customer').modal('show');
        });

        $('#edit_customer, #add_to_repo').on('hidden.bs.modal', function () {
            that.getLatestCustomers();
        });

        // const custService = app.service('customers');
        //
        // custService.on('created', () => {
        //     that.getAllCustomers();
        // });
        // custService.on('patched', () => {
        //     that.getAllCustomers();
        // })
        this.getLatestCustomers();
    }
    getLatestCustomers = () => {
        that.props.actions.getOldRecRepoSh(false,false,true).then((data) => {
            if (data) {
                const d = data.data;
                that.updateData(d);
                const totalNum = d.length;
                this.setState({totalCustomer: totalNum});
            }
        })
    };

    // getAllCustomers = () => {
    //     this.props.actions.getCustomersWithRepossessedUnits().then((data) => {

    //         if(data){
    //             that.updateData(data);
    //             const totalNum = data.length ;
    //             this.setState({totalCustomer:totalNum})
    //         }
    //         else{
    //             that.updateData([]);
    //         }
    //     });
    // }

    getAllCustomers = (customerName, accountNumber) => {
        that.props.actions.getOldRecRepoSh(customerName, accountNumber)
            .then((data) => {
                if (data) {
                    const d = data.data;
                    that.updateData(d);
                    const totalNum = data.data.length;
                    this.setState({totalCustomer: totalNum})
                }else{
                    that.updateData([]);
                    this.setState({totalCustomer: 0});

                }
            });
    }

    advancedSearch = () => {
        const that = this,
            customerName = this.state.customerName,
            accountNumber = this.state.accountNumber,
            query = {};

        // const permission = this.props.userPermission;

        if (customerName === '' && accountNumber === '') {
            that.getLatestCustomers()
            return;
        }

        // if (permission.inventory === 0) {
        //     return
        // }
        if (customerName) {
            query.name = {
                $regex: customerName.toUpperCase()
            }
        }

        if (accountNumber) {
            query.account_number = {
                $regex: accountNumber
            }
        }

        that.getAllCustomers(customerName.toUpperCase(), accountNumber.toUpperCase());

    }

    fastSearch = () => {

        let {customerName, accountNumber} = this.state;

        if (customerName.length > 2 || accountNumber.length > 0) {
            that.advancedSearch()
        }
    }

    updateData = (data) => {
        this.setState({
            data: data
        });

        const table = $('.tbl-customer-repo').DataTable();
        table.clear();
        table.rows.add(data);
        table.draw();
    }

    permissionAccounting = () => {
        const permission = this.props.userPermission;

        if (permission.accounting === 0) {
            return "not-visible"
        } else {
            return "";
        }
    }

    addRecord = () => {
        const permission = this.props.userPermission;

        if (permission.inventory === 2) {
            this.props.history.push('/add_customer_repo_old_records_sh');
        }
    }

    render() {
        return (
            <div className="" id="main">

                {/* main NavBar component*/}
                <MainNav historyProp={this.props.history} usersProp={this.props.userData}/>
                <SyncDataModal/>
                <EditCustomerModal/>
                <AddToRepoModal customer={this.props.customerInfo} history={this.props.history}/>
                <SelectUnitType history={this.props.history}/>
                <div className="container-fluid">
                    <div className="row full-width">
                        <div className="sidebar">
                            <div className="sidebar-content">
                                <MainSideBar page="old_records"/>
                            </div>
                        </div>
                        <SubSideBar history={this.props.history} userPermission={this.props.userPermission} page="repossessed_sh"/>
                        <div className="sub-inv-main-content">
                            <div className="application-container">
                                <div className="">
                                    <div className="row my-inventory">
                                        <div className="col-md-12">
                                            <div className="col-md-12">
                                                <div className="row col-md-12">
                                                    <div className="">
                                                        <h4 className="subheader-title">Old Records Repossessed</h4>
                                                    </div>
                                                    <div className="">
                                                        <button className="btn btn-second-primary add-new-btn"
                                                                onClick={() => this.addRecord()}>
                                                            <span><i className="fa fa-plus"/> Add</span>
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className="form-inline">
                                                    <div className="col-md-12 subheader-label">
                                                        ADVANCED FILTER
                                                    </div>
                                                    <div className="col-md-12">
                                                        <br/>
                                                    </div>
                                                    <div className="container-fluid">
                                                        <div className="row">
                                                            <div className="col">
                                                                <label>Name</label>
                                                                <input
                                                                    className="form-control full-width"
                                                                    placeholder="Enter Full name"
                                                                    onChange={(e) => this.setState({customerName: e.target.value})}
                                                                    onKeyUp={(e) => this.advancedSearch()}
                                                                />
                                                            </div>
                                                            <div className="col">
                                                                <label>Account No.</label>
                                                                <input
                                                                    className="form-control full-width"
                                                                    placeholder="Enter Account No.."
                                                                    onChange={(e) => this.setState({accountNumber: e.target.value})}
                                                                    onKeyUp={(e) => this.advancedSearch()}
                                                                />
                                                            </div>
                                                            <div className="col">
                                                                <label>&nbsp;</label>
                                                                <button className="btn btn-second-primary full-width"
                                                                        onClick={() => this.advancedSearch()}>Search
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="container-fluid">
                                    <div className="application-container">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <table className="table tbl-customer-repo tbl-mobile"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


const mapStateToProps = state => ({
    // app: state.feathersClient.app,
    mainapp: state.feathersClient.mainapp,
    customers: state.customers.customersTerms,
    userData: state.login.userData,
    // customerInfo:state.customers.customerInfo,
    customerInfo: state.customers.setNewCustomerData,
    userPermission: state.login.userPermission,
});

function mapDispatchToProps(dispatch) {
    return {actions: bindActionCreators(ActionCreators, dispatch)}
}

export default connect(mapStateToProps, mapDispatchToProps)(OldRecordsCustomerRepossessedSh);
