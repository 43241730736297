import React, {Component} from 'react';
//toastr
import toastr from 'toastr';
//redux
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
// actions
import {ActionCreators} from '../../../actions';

import app from '../../../helpers/feathers';

//datatables
import '../../../stylesheet/datatables/datatables.css';

const $ = require('jquery'), that = this;
$.DataTable = require('datatables.net');

class branch extends Component {

    constructor(props) {
        super(props);

        this.state = {
            branchList: []
        }
    }

    componentWillMount() {
        // get all models from category actions
        this.getBranchList();

        // triggered when new model was added
        this.listenCreatedBranch();

        // triggered when a model was deleted/removed
        this.listenDeletedBranch();
    }

    componentDidMount() {
        const that = this, tblBranch = $('.tbl-category-branch'),
            tblBrachDataTable = tblBranch.DataTable({
                data: this.props.category.branchesList,
                "columnDefs": [
                    {
                        "visible": false, "targets": 0
                    },
                    {
                        "targets": 1,
                        "width": '10%'
                    },
                    {
                        "targets": 3,
                        "orderable": false,
                        "width": '20%'
                    }
                ],
                columns: [
                    {title: "Id"},
                    {title: "No."},
                    {title: "Name"},
                    {title: "Action"},
                ],
                'dom': "<'row'<'col-sm-6'l><'col-sm-6'p>>" +
                    "<'row'<'col-sm-12'tr>>" +
                    "<'row'<'col-sm-12'i>>",
            });

        tblBranch.on('click', 'button.delete', function () {
            const data = tblBrachDataTable.row($(this).parents('tr')).data(),
                branch = data[2],
                permission = that.props.userPermission;
            let branchName = that.props.userData.branch_info.branch_name;

            if(branchName !== "MAIN"){
                return;
            }

            if (branch === 'MAIN') {
                toastr.error('Could not delete MAIN branch');
                return;
            }

            // call delete actions in category actions
            if (permission.inventory === 2) {
                that.props.actions.deleteId(data[0]);
                that.props.actions.deleteService('branches');
                that.refs.confirm_delete.click();
            }
        });

        //when edit button was clicked
        tblBranch.on('click', 'button.edit', function () {
            const data = tblBrachDataTable.row($(this).parents('tr')).data(),
                branchInfo = {
                    id: data[0],
                    name: data[2]
                },
                branch = data[2];
            let branchName = that.props.userData.branch_info.branch_name;

            if(branchName !== "MAIN"){
                return;
            }

            if (branch === 'MAIN') {
                toastr.error('Could not update MAIN branch');
                return;
            }

            that.refs.updateBranch.click();
            that.props.actions.branchUpdateInfo(branchInfo);
        });
    }

    getBranchList = () => {
        const that = this;
        this.props.actions.getAllBranches().then((data) => {
            if(data){
                that.props.actions.setBranches(data);
                that.setBranchList(data);
            }
        });
    }

    listenCreatedBranch = () => {
        const branches = app.service('branches'),
            that = this;

        branches.on('created', function (branch) {
            that.getBranchList();
        });

        branches.on('patched', function (branch) {
            that.getBranchList();
        });
    }

    listenDeletedBranch = () => {
        const branchModel = app.service('branches'),
            that = this;

        branchModel.on('removed', function (branch) {
            that.getBranchList();
        });
    }

    setBranchList = data => {
        this.setState({
            branchList: data
        });
        const table = $('.tbl-category-branch').DataTable();
        table.clear();
        table.rows.add(data);
        table.draw();
    }

    render() {
        return (
            <div className="row">
                <div className="col-md-12">
                    <div className="row col-md-6">
                        <div className="form-inline col">
                            <h4 className="subheader-title">Branches</h4>
                        </div>
                        <div className="col">
                            {
                                this.props.userData.branch_info.branch_name === "MAIN" ?
                                <button className="btn btn-second-primary add-new-btn" data-toggle="modal"
                                        data-target="#add_branch">
                                    <span><i className="fa fa-plus"/> Add </span>
                                </button> : null
                            }
                        </div>
                    </div>
                    <button className="btn btn-primary d-none btn-block" data-toggle="modal"
                            data-target="#update_branch" ref="updateBranch"><span className="fa fa-plus"/> Add
                    </button>
                    <a data-toggle="modal" data-target="#confirm_delete" ref="confirm_delete"></a>
                    <table className="table tbl-category-branch full-width tbl-mobile"></table>
                </div>
            </div>
        );
    }
}


const mapStateToProps = state => ({
    app: state.feathersClient.app,
    category: state.category,
    userPermission: state.login.userPermission,
    userData: state.login.userData,
});

function mapDispatchToProps(dispatch) {
    return {actions: bindActionCreators(ActionCreators, dispatch)}
}

export default connect(mapStateToProps, mapDispatchToProps)(branch);
