import React, { Component } from 'react';

//redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// actions
import { ActionCreators } from '../../../actions';

import moment from 'moment';

//main NavBar
import MainNav from '../../../components/global_nav';
import MainSideBar from '../../../components/global_sidebar';

//custom styles
import '../../../stylesheet/styles.css';

// To create a client side application
// import feathers from 'feathers/client';
// const app = feathers();
import 'amcharts3';
import 'amcharts3/amcharts/serial';
import 'amcharts3/amcharts/plugins/responsive/responsive';
import AmCharts from '@amcharts/amcharts3-react';

import app from '../../../helpers/feathers'

var that;

class dashboard extends Component {

    constructor(props) {
      super(props);
    
      this.state = {
        data: [],
        model: [{
                    "model": "Rouser 160",
                    "qty":  Math.floor((Math.random() * 2000) + 1)
                }, {
                    "model": "Barako 175",
                    "qty": Math.floor((Math.random() * 3000) + 1)
                }, {
                    "model": "Rouser RS 200",
                    "qty": Math.floor((Math.random() * 5000) + 1)
                }, {
                    "model": "Burgman 200",
                    "qty": Math.floor((Math.random() * 1500) + 1)
                }, {
                    "model": "Smash",
                    "qty": Math.floor((Math.random() * 8000) + 1)
                }, {
                    "model": "Raider J-FI",
                    "qty": Math.floor((Math.random() * 500) + 1)
                }, {
                    "model": "Raider 150",
                    "qty": Math.floor((Math.random() * 6000) + 1)
                }],
        sales_per_branch: [],
        sales_per_branch_length: 0,
        top_selling_models: [],
        top_selling_models_length: 0,
        top_selling_branch: [],
        top_selling_branch_length: 0,
        customers_per_branch: [],
        customers_per_branch_length: 0,
        unsold_units: [],
        unsold_units_length: 0,
        sold_units: [],
        sold_units_length: 0,
      };
    }

    componentWillMount(){
        that = this;
        //Brands
        this.props.actions.getAllBrands().then((data) => {
            if(data){
                that.props.actions.setBrands(data);
            }
        });

        //models
        that.props.actions.getCategoryModels().then((data) => {
            if (data){
                that.props.actions.setModels(data);
            }
        });

        //users data
        var id = localStorage.getItem('_id_secret')
        that.props.actions.getUserData(id)
        .then((data) => {
            if(data){
                that.props.actions.setUserData(data);
            }
        })
        //branches
        this.props.actions.getAllBranches().then((data) => {
            if(data){
                that.props.actions.setBranches(data);
            }
        });
        //positions
        this.props.actions.getAllPositions().then((data) => {
            if(data){
                that.props.actions.setPositions(data);    
            }
            
        });

        //sales per branch
        var daterange = [moment().format('MM/DD/YYYY')];
        // that.salesPerBranch(daterange)
        //top selling models
        var dashDaterange = [moment().format('YYYY-MM-DD')];
        // that.topSellingModels(dashDaterange)
        //top selling branch
        // that.topSellingBranch(daterange)
        //new customers per branch
        // that.customersPerBranch(daterange)

        // that.overallSales(daterange);

        that.unsold_units([moment().format('MM/DD/YYYY')]);
        that.sold_units([moment().format('MM/DD/YYYY')]);
        
    }

    componentDidMount() {
        var that = this;
    }

    multiarraySortDesc = (arr, columns, order_by) => {
        if(typeof columns == 'undefined') {
            columns = []
            for(var x=0;x<arr[0].length;x++) {
                columns.push(x);
            }
        }

        if(typeof order_by == 'undefined') {
            order_by = []
            for(var x=0;x<arr[0].length;x++) {
                order_by.push('ASC');
            }
        }

        function multisort_recursive(a,b,columns,order_by,index) {  
            var direction = order_by[index] == 'DESC' ? 1 : 0;

            var is_numeric = !isNaN(a[columns[index]]-b[columns[index]]);

            var x = is_numeric ? a[columns[index]] : a[columns[index]].toLowerCase();
            var y = is_numeric ? b[columns[index]] : b[columns[index]].toLowerCase();

            if(!is_numeric) {
                // x = helper.string.to_ascii(a[columns[index]].toLowerCase(),-1),
                // y = helper.string.to_ascii(b[columns[index]].toLowerCase(),-1);
            }

            if(x < y) {
                    return direction == 0 ? -1 : 1;
            }

            if(x == y)  {
                return columns.length-1 > index ? multisort_recursive(a,b,columns,order_by,index+1) : 0;
            }

            return direction == 0 ? 1 : -1;
        }

        return arr.sort(function (a,b) {
            return multisort_recursive(a,b,columns,order_by,0);
        });
    }

    enumDate = (startDate, endDate) => {
        var dates = [];

        var currDate = moment(startDate).startOf('day');
        var lastDate = moment(endDate).startOf('day');

        while(currDate.add(1, 'days').diff(lastDate) < 0) {
            // console.log(currDate.toDate());
            var mdate = moment(currDate.clone().toDate()).format('MM/DD/YYYY')
            dates.push(mdate);
           // dates.push(currDate.clone().toDate());
        }
        dates.unshift(moment(startDate).format('MM/DD/YYYY'));
        dates.push(moment(endDate).format('MM/DD/YYYY'));

        return dates
    };

    enumDateDash = (startDate, endDate) => {
        var dates = [];

        var currDate = moment(startDate).startOf('day');
        var lastDate = moment(endDate).startOf('day');

        while(currDate.add(1, 'days').diff(lastDate) < 0) {
            // console.log(currDate.toDate());
            var mdate = moment(currDate.clone().toDate()).format('YYYY-MM-DD')
            dates.push(mdate);
           // dates.push(currDate.clone().toDate());
        }
        dates.unshift(moment(startDate).format('YYYY-MM-DD'));
        dates.push(moment(endDate).format('YYYY-MM-DD'));

        return dates
    };

    salesPerBranch = (daterange) => {
        that.props.actions.salesPerBranch()
        .then((data) => {
            if(data){
                var d = data.data,
                graphData = [],
                dataLength = 0;

                d.map((branchData) => {
                    if (branchData){
                        var customerCount = branchData.allcustomers ? (branchData.allcustomers).length : 0;
                        var branch = branchData.branch_name;

                        if (customerCount){
                            var allcustomers  = branchData.allcustomers, 
                                sum = 0;

                           allcustomers.map((aa) => {
                                if (daterange.includes(aa.date_added_custom)){
                                    sum += aa.unitPrice
                                }
                           })

                            graphData.push({
                                branch: branch,
                                amount: sum
                            })
                        }else{

                            graphData.push({
                                branch: branch,
                                amount: 0
                            })
                        }
                    }
                })

                graphData.map((d) => {
                    dataLength += d.amount
                })

                this.setState({
                    sales_per_branch: graphData,
                    sales_per_branch_length: dataLength,
                    customers_per_branch_length: dataLength,
                })
            }
        })
    }

    topSellingModels = (daterange) => {
        that.props.actions.topSellingModels()
        .then((products) => {
            if(products){
                var prod = products.data,
                    myObj = [],
                    objStore = {},
                    dataLength = 0;

                prod.map((obj) => {
                    var model = obj.motorModels ? obj.motorModels.model_name : '',
                        price = obj.price,
                        date_sold = (obj.date_sold).substring(0,10);

                    if (daterange.includes(date_sold)){
                        if (Object.keys(objStore).indexOf(model) === -1){
                          objStore[model] = {
                            model: model,
                            qty: price
                          }
                        }else{
                          objStore[model] = {
                            model: model,
                            qty: (objStore[model].qty + price)
                          }
                        }   
                    }
                })

                for(var index in objStore){
                    dataLength += objStore[index].qty
                    myObj.push({
                        model: objStore[index].model,
                        qty: objStore[index].qty
                    })
                }

                var ranking = that.multiarraySortDesc(myObj, ['qty', 'model'], ['DESC','ASC']);

                this.setState({
                    top_selling_models: ranking,
                    top_selling_models_length: dataLength
                })
            }
        })
    }

    topSellingBranch = (daterange) => {
        app.service('branches').find()
        .then((branches) => {
            var graphData = [],
                branches = branches.data,
                dataLength = 0

            branches.map((obj) => {
                var customerCount = obj.allcustomers ? (obj.allcustomers).length : 0;
                var branch = obj.branch_name;
                var date_added = (obj.date_added).substring(0,10);

                if (customerCount){
                    var allcustomers  = obj.allcustomers, 
                        sum = 0;

                   allcustomers.map((aa) => {
                        if (daterange.includes(aa.date_added_custom)){
                            sum += aa.unitPrice
                        }
                   })
                    graphData.push({
                        branch: branch,
                        amount: sum
                    })
                }else{
                    graphData.push({
                        branch: branch,
                        amount: 0
                    })
                }
            })
            var ranking = that.multiarraySortDesc(graphData, ['amount', 'branch'], ['DESC','ASC']);
            graphData.map((d) => {
                dataLength += d.amount
            })
            this.setState({
                top_selling_branch: ranking,
                top_selling_branch_length: dataLength
            })
        })
    }

    customersPerBranch = (daterange) => {
        app.service('branches').find()
        .then((branches) => {
            var graphData = [],
                branches = branches.data,
                dataLength = 0;

            branches.map((obj) => {
                var customerCount = obj.allcustomers ? (obj.allcustomers).length : 0;
                var branch = obj.branch_name;
                var allcustomers = obj.allcustomers;

                var c = 0;

                allcustomers.map((a, i) => {
                        // console.log('all ',a)
                    if (daterange.includes(a.date_added_custom)){
                        // console.log('aaa ',a)
                        c += 1;
                    }
                })

                // console.log('ccc ',c)

                dataLength += c

                graphData.push({
                    branch: branch,
                    amount: c
                })
            })
            this.setState({
                customers_per_branch: graphData,
                customers_per_branch_length: dataLength,
            })
        })
    }

    overallSales = (daterange) => {
        that.props.actions.salesPerBranch()
        .then((data) => {
            var d = data.data,
            graphData = [],
            dataLength = 0;

            d.map((branchData) => {
                if (branchData){
                    var customerCount = branchData.allcustomers ? (branchData.allcustomers).length : 0;
                    var branch = branchData.branch_name;

                    if (customerCount){
                        var allcustomers  = branchData.allcustomers, 
                            sum = 0;

                       allcustomers.map((aa) => {
                            if (daterange.includes(aa.date_added_custom)){
                                sum += aa.unitPrice
                            }
                       })

                        graphData.push({
                            branch: branch,
                            amount: sum
                        })
                    }else{

                        graphData.push({
                            branch: branch,
                            amount: 0
                        })
                    }
                }
            })

            graphData.map((d) => {
                dataLength += d.amount
            })

            this.setState({
                overall_sales: graphData,
                overall_sales_length: dataLength,
                // customers_per_branch_length: dataLength,
            })
        })
    }
    
    permissionDashboard = () => {
        var permission = this.props.userPermission;

        if (permission.dashboard === 0){
            return "not-visible"
        }else{
            return "";
        }
    }

    salesPerBranchFilter = (value) => {
        if (value === "1"){
            var daterange = [moment().format('MM/DD/YYYY')];
            that.salesPerBranch(daterange);
            return;
        }
        if(value === "7"){
            var startDate = moment().subtract(6, 'days');
            var endDate = moment();
            var daterange = that.enumDate(startDate,endDate);
            that.salesPerBranch(daterange)
            return;
        }
        if (value === "30"){
            var startDate = moment().subtract(29, 'days');
            var endDate = moment();
            var daterange = that.enumDate(startDate,endDate);
            that.salesPerBranch(daterange)
            return;
        }
    }

    unsoldUnitsFilter = (value) => {
        if (value === "1"){
            var daterange = [moment().format('MM/DD/YYYY')];
            that.unsold_units(daterange);
            return;
        }
        if(value === "7"){
            var startDate = moment().subtract(6, 'days');
            var endDate = moment();
            var daterange = that.enumDate(startDate,endDate);
            that.unsold_units(daterange)
            return;
        }
        if (value === "30"){
            var startDate = moment().subtract(29, 'days');
            var endDate = moment();
            var daterange = that.enumDate(startDate,endDate);
            that.unsold_units(daterange)
            return;
        }
    }

    soldUnitsFilter = (value) => {
        if (value === "1"){
            var daterange = [moment().format('MM/DD/YYYY')];
            that.sold_units(daterange);
            return;
        }
        if(value === "7"){
            var startDate = moment().subtract(6, 'days');
            var endDate = moment();
            var daterange = that.enumDate(startDate,endDate);
            that.sold_units(daterange)
            return;
        }
        if (value === "30"){
            var startDate = moment().subtract(29, 'days');
            var endDate = moment();
            var daterange = that.enumDate(startDate,endDate);
            that.sold_units(daterange)
            return;
        }
    }

    topSellingModelsFilter = (value) => {
        if (value === "1"){
            var daterange = moment().format('YYYY-MM-DD')
            that.topSellingModels(daterange)
        }
        if (value === "7"){
            var startDate = moment().subtract(6, 'days');
            var endDate = moment();
            var daterange = that.enumDateDash(startDate, endDate)
            that.topSellingModels(daterange)
        }
        if (value === "30"){
            var startDate = moment().subtract(29, 'days');
            var endDate = moment();
            var daterange = that.enumDateDash(startDate, endDate)
            that.topSellingModels(daterange)
        }
    }

    topSellingBranchFilter = (value) => {
        if (value === "1"){
            var daterange = moment().format('MM/DD/YYYY')
            that.topSellingBranch(daterange)
        }
        if (value === "7"){
            var startDate = moment().subtract(6, 'days');
            var endDate = moment();
            var daterange = that.enumDate(startDate, endDate)
            that.topSellingBranch(daterange)
        }
        if (value === "30"){
            var startDate = moment().subtract(29, 'days');
            var endDate = moment();
            var daterange = that.enumDate(startDate, endDate)
            that.topSellingBranch(daterange)
        }
    }
    newCustomersPerBranchFilter = (value) => {

        if (value === "1"){
            var daterange = moment().format('MM/DD/YYYY')
            that.customersPerBranch(daterange)
        }
        if (value === "7"){
            var startDate = moment().subtract(6, 'days');
            var endDate = moment();
            var daterange = that.enumDate(startDate, endDate)
            that.customersPerBranch(daterange)
        }
        if (value === "30"){
            var startDate = moment().subtract(29, 'days');
            var endDate = moment();
            var daterange = that.enumDate(startDate, endDate)
            that.customersPerBranch(daterange)
        }
    }

    overallSalesFilter = (value) => {
        if (value === "1"){
            var daterange = [moment().format('MM/DD/YYYY')];
            that.overallSales(daterange);
            return;
        }
        if(value === "7"){
            var startDate = moment().subtract(6, 'days');
            var endDate = moment();
            var daterange = that.enumDate(startDate,endDate);
            that.overallSales(daterange)
            return;
        }
        if (value === "30"){
            var startDate = moment().subtract(29, 'days');
            var endDate = moment();
            var daterange = that.enumDate(startDate,endDate);
            that.overallSales(daterange)
            return;
        }
    }

    unsold_units = (daterange) => {
        var productService = app.service('products');

        // unsold units
        productService.find({
            query: {
                type: 0,
                date_created: {
                    $in: daterange
                }
            }
        })
        .then((data) => {

            var res = data.data,
                filtered = {},
                obj = [],
                count = 0;

            res.map((v) => {
                let modelName = v.motorModels ? v.motorModels.model_name : '';

                if(Object.keys(filtered).indexOf(modelName) === -1){
                    filtered[modelName] = {
                        count: 1
                    };
                }else{
                    filtered[modelName] = {
                        count:  filtered[modelName].count + 1
                    };
                }
                count++

                filtered[modelName]['modelName'] = modelName;
            })

            for (var index in filtered) {
                obj.push({
                    model: filtered[index].modelName,
                    count: filtered[index].count
                });
            }

            that.setState({
                unsold_units: obj,
                unsold_units_length: count
            })

            that.props.actions.setGraphUnsoldUnits(obj);
            that.props.actions.setGraphUnsoldUnitsCount(count);
        })
        .catch((err) => {
            return err;
        })
    }

    sold_units = (daterange) => {
        var productService = app.service('products');
        // sold units
        productService.find({
            query: {
                type: 1,
                date_sold: {
                    $in: daterange
                }
            }
        })
        .then((data) => {
            var res = data.data,
                filtered = {},
                obj = [],
                count = 0;

            res.map((v) => {
                let modelName = v.motorModels ? v.motorModels.model_name : '';

                if(Object.keys(filtered).indexOf(modelName) === -1){
                    filtered[modelName] = {
                        count: 1
                    };
                }else{
                    filtered[modelName] = {
                        count:  filtered[modelName].count + 1
                    };
                }
                count++

                filtered[modelName]['modelName'] = modelName;
            })

            for (var index in filtered) {
                obj.push({
                    model: filtered[index].modelName,
                    count: filtered[index].count
                });
            }

            that.setState({
                sold_units: obj,
                sold_units_length: count
            })
            

            that.props.actions.setGraphSoldUnits(obj);
            that.props.actions.setGraphSoldUnitsCount(count);
        })
        .catch((err) => {
            return err;
        })
    }

  	render() {

        var that = this;
        const unsold_units_config = {
            "type": "serial",
            "theme": "light",
            "categoryField": "model",
              "categoryAxis": {
                "gridPosition": "start",
                "gridAlpha": 0,
                "tickPosition": "start",
                "autoWrap": true,
                "autoGridCount": false,
                "gridCount": 50,
                "labelRotation": 45
            },
            "graphs": [ {
                "balloonText": "[[category]]: <b>[[value]]</b>",
                "fillAlphas": 0.8,
                "lineAlpha": 0.2,
                "type": "column",
                "valueField": "count",
                "autoColor": true
              } ],
            // "dataProvider": this.state.unsold_units,
            "dataProvider": this.props.graph_unsold_units,
            "export": {
                    "enabled": true
                },
            "responsive": {
                "enabled": true
              },
            "listeners": [{
                "event": "clickGraphItem",
                "method": function(event) {
                 // console.log()
                 // that.props.actions.setGraphType('sales_per_branch');
                 // that.props.actions.graphParam(event.item.category);
                 // that.props.history.push('/graph/:'+event.item.category);
                }
            }]
        };
        const sold_units_config = {
            "type": "serial",
            "theme": "light",
            "categoryField": "model",
              "categoryAxis": {
                "gridPosition": "start",
                "gridAlpha": 0,
                "tickPosition": "start",
                "autoWrap": true,
                "autoGridCount": false,
                "gridCount": 50,
                "labelRotation": 45
            },
            "graphs": [ {
                "balloonText": "[[category]]: <b>[[value]]</b>",
                "fillAlphas": 0.8,
                "lineAlpha": 0.2,
                "type": "column",
                "valueField": "count",
                "autoColor": true
              } ],
            // "dataProvider": this.state.sold_units,
            "dataProvider": this.props.graph_sold_units,
            "export": {
                    "enabled": true
                },
            "responsive": {
                "enabled": true
              },
            "listeners": [{
                "event": "clickGraphItem",
                "method": function(event) {
                 // console.log()
                 // that.props.actions.setGraphType('sales_per_branch');
                 // that.props.actions.graphParam(event.item.category);
                 // that.props.history.push('/graph/:'+event.item.category);
                }
            }]
        };

        const top_selling_models_config = {
            "type": "serial",
            "theme": "light",
            "categoryField": "model",
              "categoryAxis": {
                "gridPosition": "start",
                "gridAlpha": 0,
                "tickPosition": "start",
                "autoWrap": true,
                "autoGridCount": false,
                "gridCount": 50,
                "labelRotation": 45
            },
            "graphs": [ {
                "balloonText": "[[category]]: <b>[[value]]</b>",
                "fillAlphas": 0.8,
                "lineAlpha": 0.2,
                "type": "column",
                "valueField": "qty",
                "autoColor": true
              } ],
            "dataProvider": this.state.top_selling_models,
            "export": {
                    "enabled": true
                },
            "responsive": {
                "enabled": true
              },
            "listeners": [{
                "event": "clickGraphItem",
                "method": function(event) {
                 // console.log()
                 // that.props.actions.setGraphType('top_selling_models');
                 // that.props.actions.graphParam(event.item.category);
                 // that.props.history.push('/graph/:'+event.item.category);
                }
            }]
        };
        const top_selling_branch = {
            "type": "serial",
            "theme": "light",
            "categoryField": "branch",
              "categoryAxis": {
                "gridPosition": "start",
                "gridAlpha": 0,
                "tickPosition": "start",
                "autoWrap": true,
                "autoGridCount": false,
                "gridCount": 50,
                "labelRotation": 45
            },
            "graphs": [ {
                "balloonText": "[[category]]: <b>[[value]]</b>",
                "fillAlphas": 0.8,
                "lineAlpha": 0.2,
                "type": "column",
                "valueField": "amount",
                "autoColor": true
              } ],
            "dataProvider": this.state.top_selling_branch,
            "export": {
                    "enabled": true
                },
            "responsive": {
                "enabled": true
              },
            "listeners": [{
                "event": "clickGraphItem",
                "method": function(event) {
                 // console.log()
                 // that.props.actions.setGraphType('top_selling_branch');
                 // that.props.actions.graphParam(event.item.category);
                 // that.props.history.push('/graph/:'+event.item.category);
                }
            }]
        };
        const customers_per_branch = {
            "type": "serial",
            "theme": "light",
            "categoryField": "branch",
              "categoryAxis": {
                "gridPosition": "start",
                "gridAlpha": 0,
                "tickPosition": "start",
                "autoWrap": true,
                "autoGridCount": false,
                "gridCount": 50,
                "labelRotation": 45
            },
            "graphs": [ {
                "balloonText": "[[category]]: <b>[[value]]</b>",
                "fillAlphas": 0.8,
                "lineAlpha": 0.2,
                "type": "column",
                "valueField": "amount",
                "autoColor": true
              } ],
            "dataProvider": this.state.customers_per_branch,
            "export": {
                    "enabled": true
                },
            "responsive": {
                "enabled": true
              },
            "listeners": [{
                "event": "clickGraphItem",
                "method": function(event) {
                 // console.log()
                 // that.props.actions.setGraphType('customers_per_branch');
                 // that.props.actions.graphParam(event.item.category);
                 // that.props.history.push('/graph/:'+event.item.category);
                }
            }]
        };
        const config = {
            "type": "serial",
            "theme": "light",
            "categoryField": "branch",
              "categoryAxis": {
                "gridPosition": "start",
                "gridAlpha": 0,
                "tickPosition": "start",
                "autoWrap": true,
                "autoGridCount": false,
                "gridCount": 50,
                "labelRotation": 45
            },
            "graphs": [ {
                "balloonText": "[[category]]: <b>[[value]]</b>",
                "fillAlphas": 0.8,
                "lineAlpha": 0.2,
                "type": "column",
                "valueField": "amount",
                "autoColor": true
              } ],
            "dataProvider": this.state.data,
            "export": {
                    "enabled": true
                },
            "responsive": {
                "enabled": true
              },
            "listeners": [{
                "event": "clickGraphItem",
                "method": function(event) {
                 // console.log()
                 that.props.history.push('/graph/:'+event.item.category);
                }
            }]
        };

        const modelConfig = {
                "type": "serial",
                "theme": "light",
                "categoryField": "model",
                  "categoryAxis": {
                    "gridPosition": "start",
                    "gridAlpha": 0,
                    "tickPosition": "start",
                    "tickLength": 20
                },
                "graphs": [ {
                    "balloonText": "[[category]]: <b>[[value]]</b>",
                    "fillAlphas": 0.8,
                    "lineAlpha": 0.2,
                    "type": "column",
                    "valueField": "qty",
                    "autoColor": true
                  } ],
                "dataProvider": this.state.model,
                "export": {
                        "enabled": true
                    }
                }


        const overall_sales_config = {
            "type": "serial",
            "theme": "light",
            "categoryField": "branch",
              "categoryAxis": {
                "gridPosition": "start",
                "gridAlpha": 0,
                "tickPosition": "start",
                "autoWrap": true,
                "autoGridCount": false,
                "gridCount": 50,
                "labelRotation": 45
            },
            "graphs": [ {
                "balloonText": "[[category]]: <b>[[value]]</b>",
                "fillAlphas": 0.8,
                "lineAlpha": 0.2,
                "type": "column",
                "valueField": "amount",
                "autoColor": true
              } ],
            "dataProvider": this.state.overall_sales,
            "export": {
                    "enabled": true
                },
            "responsive": {
                "enabled": true
              },
            "listeners": [{
                "event": "clickGraphItem",
                "method": function(event) {
                 // console.log()
                 // that.props.actions.setGraphType('sales_per_branch');
                 // that.props.actions.graphParam(event.item.category);
                 // that.props.history.push('/graph/:'+event.item.category);
                }
            }]
        };

    	return (
     	 	<div className="">
     	 		{/* main NavBar component*/}
     	 		<MainNav historyProp={this.props.history} usersProp={this.props.userData} />
     	 		
     	 		<div className="container-fluid">
     	 			<div className="row full-width">
	     	 			<div className="sidebar">
	     	 				<div className="sidebar-content">
	     	 					<MainSideBar page="dashboard" />
	     	 				</div>
	     	 			</div>
                        {
                            this.props.userPermission.dashboard === 0 ?
                            <div className="main-content inv-main-content">
                                <div className="no-access-div">
                                    <div className="col-md-12">
                                        <h1>Access denied</h1>
                                    </div>
                                </div>
                            </div>
                            : null
                        }
                        <div className={this.permissionDashboard()}>
    	     	 			<div className="main-content inv-main-content" style={{marginTop: 15}}>
                                <span className="">Home / Dashboard</span>
                                <h4>Dashboard 
                                    {
                                        // <button onClick={() => this.deletions() }><span className="fa fa-trash" /></button>
                                    }
                                </h4>
                                <br/>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="row">
                                            <div className="col-md-9">
                                                {/* <h5>Unsold Units : {this.state.unsold_units_length}</h5> */}
                                                <h5>Unsold Units : {this.props.graph_unsold_units_count}</h5>
                                            </div>
                                            <div className="col-md-3">
                                                <select className="form-control float-right" onChange={(e) => this.unsoldUnitsFilter(e.target.value)}>
                                                    <option value="1">Today</option>
                                                    <option value="7">Last 7 days</option>
                                                    <option value="30">Last 30 days</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <br />
                                        </div>
                                        <div className="background-white">
                                            {
                                                this.props.graph_unsold_units_count ?
                                                <AmCharts.React
                                                  style={{
                                                    width: "100%",
                                                    height: "500px"
                                                  }}
                                                  options={unsold_units_config}
                                                /> :
                                                <div className="chart-empty">
                                                    <small>
                                                    THE CHART CONTAINS NO DATA
                                                    </small>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <br/>
                                        <br/>
                                        <div className="row">
                                            <div className="col-md-9">
                                                {/* <h5>Sold Units : {this.state.sold_units_length}</h5> */}
                                                <h5>Sold Units : {this.props.graph_sold_units_count}</h5>
                                            </div>
                                            <div className="col-md-3">
                                                <select className="form-control float-right" onChange={(e) => this.soldUnitsFilter(e.target.value)}>
                                                    <option value="1">Today</option>
                                                    <option value="7">Last 7 days</option>
                                                    <option value="30">Last 30 days</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <br />
                                        </div>
                                        
                                        <div className="background-white">
                                            {
                                                // this.state.sold_units_length ?
                                                this.props.graph_sold_units_count ?
                                                <AmCharts.React
                                                  style={{
                                                    width: "100%",
                                                    height: "500px"
                                                  }}
                                                  options={sold_units_config}
                                                /> :
                                                <div className="chart-empty">
                                                    <small>
                                                    THE CHART CONTAINS NO DATA
                                                    </small>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <br/>
                                <br/>
                                <br/>
                            </div>
	     	 			</div>
     	 			</div>
     	 		</div>
     	 	</div>
    	);
  	}
}


const mapStateToProps = state => ({
   app: state.feathersClient.app,
   loginStatus: state.login.loginStatus,
   userData: state.login.userData,
   userPermission: state.login.userPermission,
   graph_unsold_units: state.dashboard.graph_unsold_units,
   graph_unsold_units_count: state.dashboard.graph_unsold_units_count,
   graph_sold_units: state.dashboard.graph_sold_units,
   graph_sold_units_count: state.dashboard.graph_sold_units_count,
});

function mapDispatchToProps(dispatch) {
   return { actions: bindActionCreators(ActionCreators, dispatch) }
}

export default connect(mapStateToProps, mapDispatchToProps)(dashboard);