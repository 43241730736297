import React, { Component } from 'react';

//redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// actions
import { ActionCreators } from '../../../actions';

//main NavBar
import MainNav from '../../../components/global_nav';
import MainSideBar from '../../../components/global_sidebar';

//custom sidebar
import SubSideBar from './sub_sidebar';

//select tag
import Select from 'react-select';

import toastr from 'toastr';

import moment from 'moment';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

//custom styles
import '../../../stylesheet/styles.css';

//datatables
import '../../../stylesheet/datatables/datatables.css';
const $ = require('jquery');
$.DataTable = require('datatables.net');

var that;

class transfer_secondhand extends Component {

    constructor(props) {
        super(props);
    
        this.state = {
            sold: [],
            productsToTransfer: [],
            selectedBrand: '',
            selectedOption: '',
            selectedBranch: '',
            filter_color: null,
            filter_chassis: null,
            filter_model: '',
            selectChanged: false,
            filter_branch: null,
            selectBranchChanged: false,
            mainId: '',
            product_count: 0,
            date_transferred:  moment()

        };
    }

    componentWillMount(){
        that = this;
        that.loadTable();
    }

    loadTable = () => {
      var main = {};
      this.props.app.service('branches').find({
        query: {
          branch_name: 'MAIN'
        }
      })
      .then((d) => {
        var v = d.data[0]._id;
        main.id = v;

        that.setState({
          mainId: v 
        })


      this.props.actions.productsToTransfer(v, true).then(data => {
            that.props.actions.setProductsToTransfer(data);
            // that.updateData(data);
        });

      })
    }

    componentDidMount() {
       
        var that = this,
        productTable =  $('.tbl-transfer-secondhand').DataTable({
            data: this.state.sold,
            columns: [
                { "title": "ID" },
                // { "title": '<input type="checkbox" />' },
                { "title": "" },
                { "title": "MODEL NAME" },
                { "title": "BRAND NAME" },
                { "title": "COLOR" },
                { "title": "ENGINE NO" }
            ],
             columnDefs: [
                {
                  targets: [0],
                  visible: false
                },
                {
                  targets: [1],
                  width: '5%',
                  orderable: false
                }
            ],
            "sDom": '<"bottom"<t>ip><"clear">',
            info: false,
            "bFilter": false,
        });

        $('.search').keyup(function(){
            productTable.search($(this).val()).draw() ;
        });

        var transferObj = [];

        $('.tbl-transfer-secondhand').on('change', '.select-product', function() {
          var data = productTable.row( $(this).parents('tr') ).data();
          console.log(data[0])
          if (transferObj.includes(data[0])){
            var index = transferObj.indexOf(data[0])
            if(index > -1){
              transferObj.splice(index, 1);

              that.setState({
                productsToTransfer: transferObj
              })
            }
          }else{
            transferObj.push(data[0])

              that.setState({
                productsToTransfer: transferObj
              })
          }

          console.log('productsToTransfer ', that.state.productsToTransfer)
        });
    
    }


    updateData = data => {
        this.setState({
            sold: data,
            product_count: data.length
        });

        const table = $('.tbl-transfer-secondhand').DataTable();
        table.clear();
        table.rows.add(data);
        table.draw();

    }

    handleChange = (selectedOption) => {
        var value = selectedOption;

        if (value != null){
            this.setState({
                selectedOption: value,
                filter_model: value,
                selectChanged: true
            });
        }else{
            this.setState({
                selectedOption: '',
                filter_model: '',
                selectChanged: false
            })
        }
    }

    handleChangeBrand = (selectedOption) => {

        var value = selectedOption;

        if (value != null){
            this.setState({
                selectedBrand: value,
                selectedBrandChanged: true
            });
            var model = that.state.selectedOption.value,
                brand = value.value === 'all' ? null : value.value;

            that.props.actions.findChassisUsingBrand(brand)
            .then((d) => {
                var models = d.data,
                    modelsObj = [];

                models.map((v) => {
                    modelsObj.push({
                        label: v.model_name,
                        value: v._id
                    })
                })

                that.setState({
                    selectedOption: '',
                    filter_model: '',
                    modelsObj: modelsObj,
                })
            })
        }else{
            that.setState({
                selectedBrand: '',
                selectedBrandChanged: false,
                modelsObj: [],
                selectedOption: '',
            })
        }
    }

    handleChangeBranch = (selectedOption) => {
        var value = selectedOption;

        if (value === null){
            this.setState({
                selectedBranch: '',
                filter_branch: value,
                selectBranchChanged: false
            });
        }else{
            this.setState({
                selectedBranch: value,
                filter_branch: value,
                selectBranchChanged: true
            });
        }
    }

  startTransfer = () => {
    // var brand = that.state.selectedBrand.value,
    //     model = that.state.selectedOption,
    //     chassis = that.state.filter_chassis,
    //     branch = that.state.selectedBranch;

    var branch = that.state.selectedBranch ? that.state.selectedBranch.value : null,
        prod = that.state.productsToTransfer,
        date_transferred = that.state.date_transferred;


    if (!prod.length){
      toastr.remove();
      toastr.error('Please select at least one unit')
    }
    else if(!branch){
      toastr.remove();
      toastr.error('Please select a branch')
    }
    else{
      var data = {
        units: prod,
        transferred_by: {
          user_id: that.props.userData._id,
          user_name: that.props.userData.fullname
        },
        local_branch: branch,
        date_transferred: date_transferred
      };

      // that.props.app.service('transfers').find()
      // .then((data) => {
      //   console.log(data)
      // })
      // .catch((err) =>{
      //   console.log(err)
      // })

      // return;

      that.props.actions.startTransfer(data)
      .then((res) => {
        if(res){
          that.loadTable();
          console.log('saved')
          that.setState({
            productsToTransfer: [],
            selectedBranch: '',
            selectedBrand: '',
            selectedBrandChanged: false,
          })
          that.updateData([]);
          toastr.remove();
          toastr.success('Transfer complete')
        }else{
          console.log('not')
          toastr.remove();
          toastr.error('An error occured during transfer')
        }
      })
    }


  }

  branchOption = () => {
    var array = this.props.branch,
      newArray = [];

    array.map((obj) => {
      if(obj.value !== 'all' && obj.label !== 'MAIN'){
        newArray.push(obj)
      }
    })
    return newArray
  }

  filterProductsToTransfer = () => {
    var branch = that.state.mainId ? that.state.mainId : null,
        brand = that.state.selectedBrand ? that.state.selectedBrand.value : null,
        model = that.state.selectedOption ? that.state.selectedOption.value : null,
        color = that.state.filter_color ? (that.state.filter_color).trim() : that.state.filter_color,
        chassis_number = that.state.filter_chassis ? (that.state.filter_chassis).trim() : that.state.filter_chassis;

        if (!brand && !model && !color && !chassis_number){
            that.refs.filter_color.value = null;
            that.refs.filter_chassis.value = null;
          return;
        }else{
          var filters = {
            brand,
            model,
            color,
            chassis_number,
            branch,
          };

          var query = {};

          if (brand){
            if (brand !== 'all'){
              query.brand = brand
            }
          }
          if (model){
            if (model !== 'all'){
              query.model = model
            }
          }
          if (color){
            if (color.toLowerCase() !== 'all'){
              query.color = color.toUpperCase()
            }
          }
          if (chassis_number){
              query.engine_number = chassis_number
          }

          query.branch = branch;
          query.transferred = {$in: [0,2]};

          that.props.actions.productsToTransferFilter(query, true)
          .then((data) => {
            console.log(data)
            that.updateData(data);
          })
        }
  }

    handleDateTransferred = (date) => {
      this.setState({
        date_transferred: date
      })
    }

  	render() {
    	return (
     	 	<div className="">
     	 		{/* main NavBar component*/}
     	 		<MainNav historyProp={this.props.history} usersProp={this.props.userData} />
     	 		<div className="container-fluid">
     	 			<div className="row full-width">
	     	 			<div className="sidebar">
	     	 				<div className="sidebar-content">
	     	 					<MainSideBar page="inventory"/>
	     	 				</div>
	     	 			</div>
              <SubSideBar history={this.props.history} allProps={this.props} userPermission={this.props.userPermission} pageName="second_transfer"/>
	     	 			<div className="sub-inv-main-content">
                <div className="container-fluid">
                {
                  // <div className="row background-white">
                  //     <div className="d-flex flex-row justify-content-left content-header">
                  //          <input type="search" className="form-control form-control-lg align-items-center search" placeholder="&#xF002; Search" />
                  //     </div>
                  // </div>
                }
                    <div className="row margin-20 padding-20">
                        <div className="col-md-12">
                          <h4>Units to Transfer (Secondhand)</h4>
                          <br />
                        </div>
                        <div className="col-md-12">
                            <div className="form-inline">
                                <div className="col-md-12">
                                ADVANCED FILTER 
                                </div>
                                <div className="col-md-12">
                                    <br />
                                </div>
                                <div className="col">
                                    <Select
                                        name="selectBrand"
                                        value={this.state.selectedBrand.value}
                                        onChange={this.handleChangeBrand}
                                        options={this.props.brandsSelect}
                                        clearableValue={true}
                                        placeholder="Select Brand"
                                    />
                                </div>
                                <div className="col">
                                  <Select
                                      name="select-model"
                                      value={this.state.selectedOption.value}
                                      onChange={this.handleChange}
                                      // options={this.props.models}
                                      options={this.state.modelsObj}
                                      clearableValue={true}
                                      placeholder="Select Model"
                                    />
                                </div>
                                <div className="col">
                                    <input
                                        className="form-control full-width"
                                        placeholder="Enter Color"
                                        onChange={(e) => this.setState({filter_color: e.target.value })}
                                        ref="filter_color"
                                    />
                                </div>
                                <div className="col">
                                    <input
                                        className="form-control full-width"
                                        placeholder="Enter Engine Number"
                                        onChange={(e) => this.setState({filter_chassis: e.target.value })}
                                        ref="filter_chassis"
                                    />
                                </div>
                                <div className="col">
                                    <button className="btn btn-block btn-primary" onClick={() => this.filterProductsToTransfer() }>Search</button>
                                </div>
                            </div>
                        </div>
                    </div>
                  <div className="row margin-20 padding-20">
                      <div className="col-md-12">
                          <span>Result : {this.state.product_count}</span>
                      </div>
                      <div className="col-md-12">
                          <br />
                      </div>
                      <div className="col-md-12 background-white">
                          <table className="table tbl-transfer-secondhand tbl-mobile">
                          </table>
                      </div>
                      <div className="col-md-12">
                        <br />
                        <br />
                      </div>
                      <div className="col-md-12">
                          <div className="row">
                            <div className="col-md-6">
                              
                            </div>
                            <div className="col-md-6">
                              <div className="row">
                                <div className="col-md-4">
                                  <Select
                                      name="select-branch"
                                      value={this.state.selectedBranch.value}
                                      onChange={this.handleChangeBranch}
                                      options={this.branchOption()}
                                      clearableValue={true}
                                      placeholder="Select Branch"
                                  />
                                </div>
                                <div className="col-md-4">
                                  <DatePicker
                                      selected={this.state.date_transferred}
                                      onChange={this.handleDateTransferred}
                                      peekNextMonth
                                      showMonthDropdown
                                      showYearDropdown
                                      dropdownMode="select"
                                      className="form-control"
                                  />
                                </div>
                                <div className="col-md-4">
                                    <button className="btn btn-primary btn-block" onClick={() => this.startTransfer() }>Transfer</button>
                                </div>
                                
                              </div>
                              
                            </div>
                            
                          </div>

                      </div>
                  </div>
                </div>
	     	 			</div>
     	 			</div>
     	 		</div>
     	 	</div>
    	);
  	}
}

const mapStateToProps = state => ({
  app: state.feathersClient.app,
  product: state.product.productsToTransfer,
  userData: state.login.userData,
  userPermission: state.login.userPermission,
  models: state.category.modelsSelect,
  brandsSelect: state.category.brandsSelect,
  branch: state.category.branchesSelect,
});

function mapDispatchToProps(dispatch) {
   return { actions: bindActionCreators(ActionCreators, dispatch) }
}

export default connect(mapStateToProps, mapDispatchToProps)(transfer_secondhand);
