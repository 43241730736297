import React, { Component } from 'react';
import toastr from 'toastr';

//redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// actions
import { ActionCreators } from '../../../actions';

const $ = require('jquery');

class add_position_modal extends Component {

	constructor(props) {
	  	super(props);
	
	  	this.state = {};
	}

	addPosition = () => {
		
		var positionName = this.refs.positionName.value,
		 	that = this;

		if(positionName.length < 1){
			// toastr.error('failed');

			return false;
		}

		this.props.actions.addPosition(positionName).then((data) => {
			if(data === 'exist'){
				toastr.error(positionName + ' already exists.');
			}else if(data === 'success') {
				toastr.success(positionName + ' has been successfully added');
				that.closeModal();
			}else{
				toastr.error('Failed to add. Please try again.');
			}
		});

	}

	closeModal = () => {
		var that = this;

		that.refs.cancel.click();
		that.refs.positionName.value = '';
	}

	componentDidMount() {
		
	}

  	render() {
    	return (
      		<div className="modal fade" id="add_position" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" ref="addPosition" data-backdrop="static" data-keyboard="false">

			  	<div className="modal-dialog" role="document">
			    	<div className="modal-content">
			      		<div className="modal-header">
			        		<h5 className="modal-title" id="exampleModalLabel">Add User Position</h5>
			        			<button type="button" className="close" onClick={this.closeModal} aria-label="Close">
						          <span aria-hidden="true">&times;</span>
						        </button>
			      		</div>
				      	<div className="modal-body">
				        	<div className="form-group">
				        		<label>Position Type</label>
				        		<input type="text" className="form-control" ref="positionName"/>
				        	</div>
				      	</div>
				      	<div className="modal-footer">
				        	<a data-dismiss="modal" ref="cancel"></a>
				        	<button type="button" className="btn btn-secondary" onClick={this.closeModal}>Cancel</button>
				        	<button type="button" className="btn btn-primary" onClick={this.addPosition}>Save</button>
				      	</div>
			    	</div>
			  	</div>
			</div>
    	);
  	}
}


const mapStateToProps = state => ({
   	category: state.category,
   	app: state.feathersClient.app
});

function mapDispatchToProps(dispatch) {
   return { actions: bindActionCreators(ActionCreators, dispatch) }
}

export default connect(mapStateToProps, mapDispatchToProps)(add_position_modal);