import React, {Component} from 'react';
import app from '../../../helpers/feathers';
// react-router
import {Link} from 'react-router-dom';

//redux
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
// actions
import {ActionCreators} from '../../../actions';

//main NavBar
import MainNav from '../../../components/global_nav';
import MainSideBar from '../../../components/acc_sidebar';

//sub side bar
import SubSideBar from '../accounting/sub_sidebar';

//custom styles
import '../../../stylesheet/styles.css';

//datatables
import '../../../stylesheet/datatables/datatables.css';
import EditCustomerSecondhandModal from "./edit_customer_secondhand_modal";

const $ = require('jquery');
$.DataTable = require('datatables.net');
var that;

class customer_with_secondhand_unit_cash extends Component {


    constructor(props) {
        super(props);

        this.state = {
            data: [],
            customerName: "",
            accountNumber: ""
        };
    }

    componentWillMount() {
        that = this;
        // this.getAllCustomers();

        // products select options
        that.props.actions.getProducts().then((data) => {
            that.props.actions.setProducts(data);
        });

        //inventory
        this.props.actions.getAllProducts().then((data) => {
            if (data) {
                that.props.actions.setAllProducts(data);
            }

        });
    }

    componentDidMount() {
        var applicationTbl = $('.tbl-customer-secondhand-cash').DataTable({
            // data: this.props.customers,
            "columnDefs": [
                {
                    "visible": false, "targets": 5
                },
                {
                    targets: 0,
                    orderable: false,
                    width: 150
                },
                {
                    targets: [4],
                    orderable: false,
                    width: 50
                }
            ],
            "order": [[ 3, "desc" ]],
            columns: [
                {title: "NAME"},
                {title: "ACCOUNT NO."},
                {title: "BRANCH"},
                {title: "DATE CREATED"},
                {title: "ACTION"}
            ],
            "sDom": '<"bottom"<t>ip><"clear">'
        });


        $('.search').keyup(function () {
            applicationTbl.search($(this).val()).draw();
        });

        $('.tbl-customer-secondhand-cash').on('click', 'button.btn-new-view', function () {
            var data = applicationTbl.row($(this).parents('tr')).data(),
                name = (data[0]).replace(/ /g, "");
            that.props.actions.setCustomerInfo(data[5]);
            that.props.history.push('/view_customer_secondhand');
        });

        $('.tbl-customer-secondhand-cash').on('click', 'button.btn-new-edit', function () {
            var data = applicationTbl.row($(this).parents('tr')).data(),
                name = (data[0]).replace(/ /g, "");
            that.props.actions.setCustomerInfo(data[5]);
            $('#edit_customer').modal('show');

        });
        var custService = app.service('customer-secondhand-unit');

        custService.on('created', () => {
            // that.getAllCustomers();
            that.getAllCustomers();
        })
        custService.on('patched', () => {
            // that.getAllCustomers();
            that.getAllCustomers();
        })

        that.getLatestCustomers();

    }


    // getAllCustomers = () => {
    //     // this.props.actions.getTermsCustomers().then((data) => {
    //     this.props.actions.getCustomersWithSecondhandUnits().then((data) => {
    //              console.log('customers ', data)

    //         if(data){
    //             that.updateData(data);
    //         }
    //         else{
    //              that.updateData([]);
    //            }
    //     });

    //     this.props.app.service('products').find()
    //     .then((data) => {
    //         // console.log('products ', data)
    //     })

    //     // this.props.app.service('customers').remove('5a7d7ebe6a03f17fb120f9cd')
    //     // .then(() => {

    //     // })

    // }


    getAllCustomers = (customerName, accountNumber) => {
        that.props.actions.getTermsCustomersSecondHandCash(customerName, accountNumber)
            .then((data) => {
                if (data) {
                    var d = data.data;
                    that.updateData(d);
                    var totalNum = data.data.length;
                    this.setState({totalCustomer: totalNum})
                }
            })
    }

    getLatestCustomers = () => {
        that.props.actions.getTermsCustomersSecondHandCash(false,false,true)
        .then((res) => {
            if(res){
                that.updateData(res.data);
                var totalNum = res.data.length;
                that.setState({totalCustomer: totalNum})
            }
        })
    }

    advancedSearch = () => {
        var that = this,
            customerName = this.state.customerName,
            accountNumber = this.state.accountNumber,
            query = {};

        var permission = this.props.userPermission;

        if (!customerName && !accountNumber) return;

        if (permission.inventory === 0) {
            return
        }
        if (customerName) {
            query.name = {
                $regex: customerName.toUpperCase()
            }
        }

        if (accountNumber) {
            query.account_number = {
                $regex: accountNumber
            }
        }

        that.getAllCustomers(customerName.toUpperCase(), accountNumber.toUpperCase());

    }

    fastSearch = () => {

        let {customerName, accountNumber} = this.state;

        if (customerName.length > 0 || accountNumber.length > 0) {
            that.advancedSearch()
        } else {
            that.updateData([])
        }
    }

    updateData = (data) => {
        this.setState({
            data: data
        });

        const table = $('.tbl-customer-secondhand-cash').DataTable();
        table.clear();
        table.rows.add(data);
        table.draw();
    }

    permissionCustomer = () => {
        var permission = this.props.userPermission;

        if (permission.customer === 0) {
            return "not-visible"
        } else {
            return "";
        }
    }

    render() {
        return (
            <div className="">

                {/* main NavBar component*/}
                <MainNav historyProp={this.props.history} usersProp={this.props.userData} systemType="accounting"/>

                <EditCustomerSecondhandModal/>
                <div className="container-fluid">
                    <div className="row full-width">
                        <div className="sidebar">
                            <div className="sidebar-content">
                                <MainSideBar page="customer"/>
                            </div>
                        </div>
                        <SubSideBar history={this.props.history} userPermission={this.props.userPermission}/>
                        <div>
                            <div className="sub-main-content mobile-customer-content-wrapper">
                                <div className="container margin-top-40-percent">
                                    <div className="application-container2">
                                        <div className="form-inline">
                                            <div className="col-md-12">
                                                <h4>Search By Filter</h4>
                                                <br/>
                                            </div>
                                            <div className="col-md-4">
                                                <label>Name</label>
                                                <input
                                                    className="form-control full-width"
                                                    placeholder="Enter Full Name"
                                                    onChange={(e) => this.setState({customerName: e.target.value})}
                                                    onKeyUp={(e) => this.fastSearch()}
                                                />
                                            </div>
                                            <div className="col-md-4">
                                                <label>&nbsp;</label>
                                                <button className="btn full-width btn-second-primary btn-sm"
                                                        onClick={() => this.advancedSearch()}>Search
                                                </button>
                                            </div>
                                            {
                                                /*

                                                    <div className="col-md-4">
                                                        <label>Account No.</label>
                                                        <input
                                                            className="form-control full-width"
                                                            placeholder="Enter Account No.."
                                                            onChange={(e) => this.setState({accountNumber: e.target.value })}
                                                            onKeyUp={(e) => this.fastSearch()}
                                                        />
                                                    </div>
                                                */
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="container-fluid">
                                    <div className="application-container">
                                        <div className="form-inline">
                                            <span className="global__header-label">Customers with Secondhand Cash</span>
                                        </div>
                                        <div>
                                            <br/>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <table className="table tbl-customer-secondhand-cash background-white tbl-mobile"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


const mapStateToProps = state => ({
    app: state.feathersClient.app,
    customers: state.customers.customersTerms,
    userData: state.login.userData,
    userPermission: state.login.userPermission,
});

function mapDispatchToProps(dispatch) {
    return {actions: bindActionCreators(ActionCreators, dispatch)}
}

export default connect(mapStateToProps, mapDispatchToProps)(customer_with_secondhand_unit_cash);
