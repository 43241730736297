import React, {Component} from 'react';
import toastr from 'toastr';

//redux
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
// actions
import {ActionCreators} from '../../../actions';

//select tag
import Select from 'react-select';

const $ = require('jquery');

var that

class edit_user_modal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedPosition: '',
            fullname: '',
            username: '',
            passwordIsStrong: false,
            emailInput: '',
            validEmail: false,
            existingEmail: false,
        };
    }

    componentWillMount() {
        that = this;
    }

    editUser = () => {
        var fullname = this.refs.user_name.value,
            username = this.refs.user_username.value,
            password = this.refs.user_password.value,
            // email = this.refs.email.value ? this.refs.email.value : that.props.userInfoEdit.userEmail,
            email = this.refs.email.value,
            position = this.state.selectedPosition,
            user_id = this.props.userInfoEdit.user_id;

        if (password !== '') {
            console.log(password)
            if (!that.state.passwordIsStrong) {
                return
            }
        }

        if (fullname.trim() === '' && username.trim() === '' && password.trim() === '' && email.trim() === '' && position === '') {
            toastr.info("No changes made")
            return;
        } else {
            if (
                fullname === that.props.userInfoEdit.name &&
                username === that.props.userInfoEdit.username &&
                email === that.props.userInfoEdit.userEmail &&
                position.value === that.props.userInfoEdit.selectedPos.value
            ) {
                toastr.info('No changes made')
            } else {
                if(email){
                    if(this.state.validEmail){

                        if (!this.state.existingEmail) {
                            // let toAdd = {
                            //     fullname: fullname.length > 0 ? fullname : that.props.userInfoEdit.name,
                            //     username: username.length > 0 ? username : that.props.userInfoEdit.username,
                            //     position: position.value ? position.value : that.props.userInfoEdit.selectedPos.value,
                            //     password: password,
                            //     email: email.length > 0 ? email : that.props.userInfoEdit.userEmail,
                            // };

                            let toAdd = {
                                fullname: fullname,
                                username: username,
                                position: position.value,
                                password: password,
                                email: email,
                            };

                            that.props.actions.saveUserUpdate(user_id, toAdd).then((data) => {
                                if (data.status) {
                                    if(data.data == "taken"){
                                        toastr.error(data.message);
                                        return;
                                    }
                                    else if(data.data == "same"){
                                        toastr.info(data.message);
                                        return;
                                    }else{
                                        toastr.success(data.message);
                                        that.closeModal();
                                    }
                                } else {
                                    toastr.error(data.message);
                                    that.closeModal();
                                }
                            })
                        } else {
                            toastr.error('Email Address is in use')
                        }
                    }
                }else{
                    if (!this.state.existingEmail) {
                        // let toAdd = {
                        //     fullname: fullname.length > 0 ? fullname : that.props.userInfoEdit.name,
                        //     username: username.length > 0 ? username : that.props.userInfoEdit.username,
                        //     position: position.value ? position.value : that.props.userInfoEdit.selectedPos.value,
                        //     password: password,
                        //     email: email.length > 0 ? email : that.props.userInfoEdit.userEmail,
                        // };

                        let toAdd = {
                            fullname: fullname,
                            username: username,
                            position: position.value,
                            password: password,
                            email: email,
                        };

                        that.props.actions.saveUserUpdate(user_id, toAdd).then((data) => {
                            if (data.status) {
                                if(data.data == "taken"){
                                    toastr.error(data.message);
                                    return;
                                }
                                else if(data.data == "same"){
                                    toastr.info(data.message);
                                    return;
                                }else{
                                    toastr.success(data.message);
                                    that.closeModal();
                                }
                            } else {
                                toastr.error(data.message);
                                that.closeModal();
                            }
                        })
                    } else {
                        toastr.error('Email Address is in use')
                    }
                }
            }
        }
        return;
    }

    handleChangePosition = (selectedOption) => {
        var value = selectedOption;
        this.setState({
            selectedPosition: value
        });
    }

    closeModal = () => {
        this.refs.cancel.click();
        this.refs.user_name.value = '';
        this.refs.user_username.value = '';
        this.setState({
            selectedPosition: '',
            fullname: '',
            username: '',
            emailInput: '',
        })
    }

    closeModel = () => {
        $('.modal').modal('hide');
    }

    componentDidMount() {

    }

    passwordStrength = (e) => {
        var regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{6,}$/;
        var val = e.target.value;
        var m = val.match(regex);
        var matched = m ? true : false;

        that.setState({
            passwordIsStrong: matched
        })
    }


    emailLowerCase = () => {
        const value = this.refs.email.value;

        if(value !== ""){
            this.props.actions.getCheckEmailIfExist(value).then((data) => {
                this.setState({
                    existingEmail: data,
                });
            });
        }else{
            this.setState({
                existingEmail: false,
            });
        }
        const validEmailRegex =
            RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);
        this.setState({
            validEmail: validEmailRegex.test(value),
            emailInput: value
        });
    }

    render() {
        return (
            <div className="modal fade" id="edit_user" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel"
                 aria-hidden="true" ref="add_branch" data-backdrop="static" data-keyboard="false">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Edit User</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="form-group">
                                <label>Name : {this.props.userInfoEdit.name}</label>
                                <input type="text" className="form-control" ref="user_name"/>
                            </div>
                            <div className="form-group">
                                <span>Email : {this.props.userInfoEdit.userEmail}</span>
                                <input type="email"
                                       className="password-form-control text-lowercase"
                                       value={
                                           this.state.emailInput}
                                       onChange={this.emailLowerCase}
                                       onKeyUp={this.emailLowerCase}
                                       disabled={this.state.inputsDisabled}
                                       ref="email"
                                       required/>
                                <small className="text-danger">
                                    {
                                        this.state.validEmail ? this.state.existingEmail ? "Email address is already taken." : "" :
                                            "Please enter a valid email address."
                                    }
                                </small>
                            </div>
                            <div className="form-group">
                                <label>Username : {this.props.userInfoEdit.username}</label>
                                <input type="text" className="password-form-control" ref="user_username"/>
                            </div>
                            <div className="form-group">
                                <label>Password : ******</label>
                                <input
                                    type="password"
                                    className="password-form-control"
                                    ref="user_password"
                                    onChange={(e) => this.passwordStrength(e)}
                                />
                                <small className="text-danger">
                                    {
                                        this.state.passwordIsStrong ? "" : "Use at least 6 alphanumeric characters, Password value should atleast have 1 Uppercase letter and 1 numeric character"
                                    }
                                </small>
                            </div>
                            <div className="form-group">
                                <label>Role : {this.props.userInfoEdit.position}</label>
                                <Select
                                    required
                                    className={this.state.selectedPosition === '' && this.state.clickSubmit ? "error-select" : null}
                                    name="select-position"
                                    value={this.state.selectedPosition.value}
                                    onChange={this.handleChangePosition}
                                    options={this.props.position}
                                    clearableValue={true}
                                    placeholder="Select Position"
                                    ref="user_position"
                                />
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" onClick={this.closeModal}>Cancel
                            </button>
                            <a data-dismiss="modal" ref="cancel"/>
                            <button type="button" className="btn btn-primary" onClick={this.editUser}>Save</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


const mapStateToProps = state => ({
    app: state.feathersClient.app,
    category: state.category,
    userInfoEdit: state.users.userInfoEdit,
    position: state.category.positionsSelect,
});

function mapDispatchToProps(dispatch) {
    return {actions: bindActionCreators(ActionCreators, dispatch)}
}

export default connect(mapStateToProps, mapDispatchToProps)(edit_user_modal);
