import React, {Component} from 'react';

import CustomerModal from './select_customer_modal';

// react-router
import {Link} from 'react-router-dom';
class sub_sidebar extends Component {

    componentDidMount() {
        let permission = this.props.userPermission
        // console.log('sidebar', this.props.history.location.pathname);
        // console.log('permission', permission);
    }

    add_customer = () => {
        var permission = this.props.userPermission,
            that = this;

        if (permission !== undefined) {
            if (permission.accounting !== 2) {
                return
            } else {
                that.props.history.push('/add_new_customer')
            }
        }
    }

    render() {
        var pathName = this.props.history.location.pathname,
            {page} = this.props;
        let permission = this.props.userPermission,
            // link_installment = "/customer/",
            // link_cash = "/customer/cash",
            // link_bad_record = "/customer/badrecords"
            link_installment = permission !== undefined ? permission.accounting === 0 ? "#" : "/acc_customer/" : "/acc_customer/",
            link_secondhand = permission !== undefined ? permission.accounting === 0 ? "#" : "/customer_secondhand_unit/" : "/customer_secondhand_unit/",
            link_cash = permission !== undefined ? permission.accounting === 0 ? "#" : "/customer_cash" : "/customer_cash",
            link_bad_record = permission !== undefined ? permission.accounting === 0 ? "#" : "/customer_badrecords" : "/customer_badrecords",
            add_customer_btn = permission !== undefined ? permission.accounting !== 2 ? "#" : "modal" : "modal",
            new_customer = permission !== undefined ? permission.accounting !== 2 ? "#" : "/add_customer" : "/add_customer";

        return (
            <div>
            {/*<CustomerModal history={this.props.history}/>*/}
                {/*<CustomerModal history={this.props.history}/>*/}
                <div className="sub-sidebar web-acc-reports__wrapper-ledger">
                    <ul className="list">
                        <li className="non-nav subheader__name">
                            <h4>Ledger</h4>
                        </li>
                        {/*<li className="nav-item">*/}
                        {/*    <button*/}
                        {/*            className="btn padding-10-40" data-toggle={add_customer_btn}*/}
                        {/*            data-target={"#selectUnitType"}>*/}
                        {/*        NEW CUSTOMER*/}
                        {/*    </button>*/}
                        {/*</li>*/}
                        <li className="nav-item">
                            <h6>Brand New Customer</h6>
                        </li>
                        <span className='and-text'>&</span>
                        <li className="nav-item">
                            <h6>Secondhand Customer</h6>
                        </li>
                        {
                            /*
                                <li className="nav-item">
                                    <Link className={pathName === '/acc_customer/' ? "nav-link activeLabel" : (page == 'acc_customer' ? "nav-link activeLabel" : "nav-link")}  to={link_installment}>Customer List</Link>
                                </li>
                            */
                        }
                    </ul>
                </div>
                <div className="sub-sidebar tab-acc-dashboard__wrapper overwrite-height-by-120">
                    <ul className="list">
                        <li className="non-nav subheader__name">
                            <h4>Ledger</h4>
                        </li>
                        <li className="nav-item">
                            <h6>Brand New Customer and Secondhand Customer</h6>
                        </li>
                        {
                            /*
                                <li className="nav-item">
                                    <Link className={pathName === '/acc_customer/' ? "nav-link activeLabel" : (page == 'acc_customer' ? "nav-link activeLabel" : "nav-link")}  to={link_installment}>Customer List</Link>
                                </li>
                            */
                        }
                    </ul>
                </div>
                <div className="sub-sidebar mobile-acc-reports__wrapper">
                    <ul className="list">
                        <li className="non-nav subheader__name">
                            <h4>Ledger</h4>
                            <h6>Brand New and Secondhand Customers</h6>
                        </li>
                    </ul>
                </div>
            </div>
        );
    }
}

export default sub_sidebar;
