import React, { Component } from 'react';

//main NavBar
import MainNav from '../../../components/global_nav';
import MainSideBar from '../../../components/acc_sidebar';

// moment
import Moment from 'moment';
import {IntlProvider, FormattedMessage, FormattedNumber} from 'react-intl';
import Cleave from 'cleave.js/react';

import ReactDataSheet from 'react-datasheet';

//toastr
import toastr from 'toastr';
import Toggle from 'react-bootstrap-toggle';

import app from '../../../helpers/feathers'

//sub side bar
import SubSideBar from './sub_sidebar'; 

import defaultImage from '../../../assets/img/default_avatar.jpg';

//custom styles
import '../../../stylesheet/styles.css';


import html2canvas from 'html2canvas';
import jsPDF from '../../../../node_modules/jspdf/dist/jspdf.min.js';

//redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// actions
import { ActionCreators } from '../../../actions';

import { twoDecimalPlaces, numberWithCommas } from '../../../helpers';
const $ = require('jquery');
$.DataTable = require('datatables.net');

var that
const table_view = '.tbl-view';
class view_schedule_sh extends Component {
    constructor(props) {
        super(props);
    
        this.state = {
           ledgerData : []
        };
    }

    componentWillMount(){
        that = this;


      
    }

    componentDidMount() {

        var customer_account_number =  this.props.setNewCustomerData.account_number,
            customer_type = this.props.setNewCustomerData.payment_method, 
            data = [];


        if(customer_type == 0){
                $('.tbl-view').hide();                
                $('.button-print').hide(); 
        }

        app.service('ledger-secondhand').find({
            query : {
                account_number : customer_account_number,
                current : 1
            }
        })
        .then((result)=> {

             console.log('VALUE OF LEDGER FIND', result)
            if(result.total){
                let res = result.data,
                multiplier = 0,                
                output = [],
                balance = 0;

                res.map((value, i) => {

                    multiplier += 1;

                    let balance = (parseFloat(value.monthly_amortization) * multiplier),
                        displayed_balance = Math.round(value.balance - balance),
                        displayed_ma = Math.round(value.monthly_amortization);

                    data.push([
                        value._id,
                        '','','','','',
                        value.due_date,
                        numberWithCommas(twoDecimalPlaces(displayed_ma)),                        
                        value.balance ? numberWithCommas(twoDecimalPlaces((displayed_balance))) : ''
                        
                    ])
                })

                that.setState({
                    ledgerData : data
                })

                that.updateDataTable(table_view, data)
            }else{
                that.updateDataTable(table_view, [])               
            }           
           
        })
        .catch((err) => {
            console.log('ERROR', err)
        })



        
        var mainDtTable = $(table_view).DataTable({
            data: this.state.ledgerData,
            "columnDefs": [
                {
                    "visible": false,
                    "targets": 0
                },
         
            ],
            columns: [
                { title: "ID" },
                { title: "Date" },
                { title: "Invoice" },
                { title: "DR" },
                { title: "CR" },
                { title: "balance" },
                { title: "DATE DUE" },
                { title: "MA" },           
                { title: "BALANCE" },     
            ],
            "paging": false,
            "pageLength": 36,
            "searching": false,
            "ordering": false,
            "info": false,
            "sDom": '<"bottom"<t>ip><"clear">' 
        });        
    }



    updateDataTable = (table_class, data) => {
        // console.log('updateDataTable ')
        const table = $(table_class).DataTable();
        table.clear();
        table.rows.add(data);
        table.draw();
    }


       



    printDocument() {
        const input = document.getElementById('divToPrint');
        html2canvas(input)
          .then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF({
                orientation: 'landscape',
            });
            pdf.addImage(imgData, 'JPEG', 0, 0);
            // pdf.output('dataurlnewwindow');
            pdf.save(that.props.viewReportData.report_title+".pdf");
          });
    }


    redirect = () => {
        that.props.actions.setCustomerInfo(this.props.setNewCustomerData);
        that.props.history.push('/view_ledger_secondhand/');
    }
    
    backMain = () => {
        that.props.history.push('/accounting');        
    }


    

    render() {

        let customer = this.props.setNewCustomerData;

        return (
            <div className="">
                {/* main NavBar component*/}
                <MainNav
                    historyProp={this.props.history}
                    userPermission={this.props.userPermission}
                    usersProp={this.props.userData}
                    systemType="accounting"
                />
                
                <div className="container-fluid">
                    <div className="row full-width">
                        <div className="sidebar">
                            <div className="sidebar-content">
                                <MainSideBar page="customer"/>
                            </div>
                        </div>
                       <SubSideBar history={this.props.history} userPermission={this.props.userPermission} path = "/acc_add_customer"/>
                        <div className="sub-main-content">
                           <div className="container-fluid application-container">
                            <a href="javascript:;" onClick={() => this.props.history.goBack()} className="text-gray customer-details no-textdecoration"><span className="fa fa-chevron-left" /> Customer / {customer.name}</a>
                            <br/>
                            <br/>
                                                            
                                <div className="tab-content background-white adjust-div">
                                    <div role="tabpanel" className="" id="home">
                                        <div className="">
                                            <div className="background-white p-5" >
                                                <div className="container-fluid" id = "divToPrint">
                                                    <div className="row d-flex flex-row align-items-center">
                                                        <div className="col-md-2 d-flex justify-content-center avatar-logo">
                                                            <img src={defaultImage} className="avatar" alt="logo"/>
                                                        </div>
                                                        <div className="col-md-10">
                                                            <div className="row">
                                                                <div className="col">
                                                                    <h4>{customer.title}. {customer.name}</h4>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col"> 
                                                                     <span>Account No</span>  
                                                                    <p>{customer.account_number}</p>
                                                                                                                                 
                                                                </div>
                                                                <div className="col">
                                                                    <span>Branch</span>
                                                                    <p>{customer.branch_info.branch_name}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <br/>
                                                    <div className="personal-infos">                                                        
                                                        <div className="row">
                                                            <div className = "col">

                                                            </div>
                                                            <div className = "col">
                                                             <button className="print-not-visible btn btn-success btn-redirect" onClick = {this.backMain}><i className="fa fa-refresh"></i> Back to main menu</button>
                                                            </div>
                                                        </div>

                                                        <div className="row">
                                                            
                                                            <div className="col">
                                                                <span><strong>Model:</strong> {customer.unit.name} </span>
                                                            </div>
                                                            <div className="col">
                                                                <span><strong>Engine Number: </strong> {customer.secondhand_unit_info.engine_number} </span>
                                                            </div>
                                                        </div>                                                        
                                                        <div className="row">
                                                            
                                                             <div className="col">
                                                                <span><strong>Promisory Note: </strong> {customer.promisory_note ? customer.promisory_note : 'N/A'} </span>
                                                            </div> 
                                                            <div className="col">
                                                                 <span><strong>Terms: </strong> <span className = "new-terms">{customer.term ? customer.term+" months" : 'N/A' }  </span></span>
                                                            </div>
                                                        </div> 
                                                    </div>
                                                    <br/>
                                                    <div className = "row">
                                                        <div className="col-md-12">
                                                            <div className="container customer_sch">       
                                                            <a href="javascript:;" className="print-not-visible button-print btn btn-info" onClick={() => window.print() }><i className="fa fa-print"></i> Print Schedule</a>    
                                                            <br/>                                                                                                  
                                                            <button className="print-not-visible button-print btn btn-success btn-redirect" onClick = {this.redirect}><i className="fa fa-arrow-right"></i> Go to Ledger</button>
                                                            <br/>   
                                                            <table  className="table table-sm tbl-view table-bordered"></table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


const mapStateToProps = state => ({
    setNewCustomerData : state.customers.setNewCustomerData,
    ledger: state.customers.ledgerTableData,
      
});

function mapDispatchToProps(dispatch) {
   return { actions: bindActionCreators(ActionCreators, dispatch) }
}

export default connect(mapStateToProps, mapDispatchToProps)(view_schedule_sh);