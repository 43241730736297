import React, {Component} from 'react';

//redux
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
// actions
import {ActionCreators} from '../../../actions';

//select tag
import Select from 'react-select';
import 'react-select/dist/react-select.css';

//main NavBar
import MainNav from '../../../components/global_nav';
import MainSideBar from '../../../components/global_sidebar';

//custom sidebar
import SubSideBar from './sub_sidebar';

import {
    encodeToString
} from '../../../helpers';

import moment from 'moment';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import '../report/plugins/daterangepicker.css';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import Autosuggest from 'react-autosuggest';
// import theme from 'theme.css';

import appFeathers from '../../../helpers/feathers';

import toastr from 'toastr';

const $ = require('jquery');

toastr.options = {
    "positionClass": "toast-bottom-right"
}

let app;
const _0x896d = ["\x74\x65\x73\x74\x32"];
const secret_user = _0x896d[0], secret_pass = _0x896d[0]

class add_inventory_secondhand extends Component {
    constructor(props) {
        super(props);

        this.state = {
            model: null,
            engine_number: '',
            selectedArea: '',
            selectedAreaChanged: false,
            color: null,
            // date_delivered: moment().format('MM/DD/YYYY'),
            date_delivered: moment(),
            location: null,
            delivery_receipt_number: null,
            // delivery_receipt_date: moment().format('MM/DD/YYYY'),
            delivery_receipt_date: moment(),
            invoice_number: null,
            // invoice_date: moment().format('MM/DD/YYYY'),
            invoice_date: moment(),
            invoice_date_changed: false,
            price: null,
            saveTemplate: false,
            models: this.props.models,
            selectedOption: '',
            selectChanged: false,
            selectedBranch: '',
            selectedBranchChanged: false,
            selectedBrand: '',
            selectedBrandChanged: false,
            selectBranch: [
                {value: this.props.userData.branch_info._id, label: this.props.userData.branch_info.branch_name}
            ],
            saving: false,
            today: moment().format('MM/DD/YYYY'),
            account_number: '',

            modelsObj: [],

            value: '',
            suggestions: [],
        };
    }

    componentWillMount() {
        //AREA PROPS
        this.props.actions.getArea();
        appFeathers.service('motorcycle-models').find()
            .then((data) => {
                const d = data.data,
                    models = [];

                d.map((v) => {
                    models.push({
                        name: v.model_name
                    })
                })
                this.props.actions.setModels(models);
            })
        .catch((err) => {
            console.log(err)
        })
    }

    componentDidMount() {
        $('.hide-view-customer').css('display', 'none', '!important');
        const static_branch = {
            value: this.props.userData.branch_info._id,
            label: this.props.userData.branch_info.branch_name
        }

        this.setState({selectedBranch: static_branch});
        $('#confirmation_modal').on('hidden.bs.modal', function () {
            this.setState({
                confirmed: false, submitting: false
            })
        })

        const areaService = appFeathers.service('customer-area');

        areaService.find()
            .then((e) => {
            }).catch((error) => {
        })

        const mapStateToProps = state => ({
            mainapp: state.feathersClient.mainapp,
            areaList: state.customers.areaList,
        });

    }

    componentWillUnmount() {
        // $('.tbl-inventory').css('display','block')
    }

    hasWhiteSpace = (s) => {
        if (s != null) {
            return s.indexOf(' ') >= 0;
        }
    }

    singleToast = (text) => {
        toastr.remove();
        toastr.error(text)
    }

    clearInputs = () => {
        this.setState({
            value: '',
            delivery_receipt_date: moment(),
            saving: false,
        })

        this.refs.customer_name.value = '';
        this.refs.engine_number.value = '';
        this.refs.price.value = '';
        this.refs.discount.value = '';
        this.refs.account_number.value = '';
        this.refs.remarks.value = '';

    }

    saveTemplate = () => {
        if (this.state.saveTemplate) {
            this.setState({saveTemplate: false})
        } else {
            this.setState({saveTemplate: true})
        }
    }

    saveAndAdd = (saveAndClose) => {

        let {address} = this.refs;

        const model = this.state.value.trim(),
            engine_number = (this.refs.engine_number.value).trim(),
            price = (this.refs.price.value).trim(),
            discount = (this.refs.discount.value).trim() ? (this.refs.discount.value).trim() : '',
            customer_name = (this.refs.customer_name.value).trim(),
            account_number = (this.refs.account_number.value).trim(),
            date_foreclosed = this.state.delivery_receipt_date,
            remarks = (this.refs.remarks.value).trim(),
            area = this.state.selectedAreaChange ? this.state.selectedArea.label : '',
            customer_address = (address.value).trim(),
            branch = this.props.userData.branch_info._id;

        const permission = this.props.userPermission;
        if (permission.inventory !== 2) {
            return;
        }

        if (this.state.saving) {
            toastr.remove();
            toastr.warning('Please wait while saving your data');
            return;
        }

        if (model === '') {
            toastr.remove();
            toastr.error('Please enter Model Name');
            $('.react-autosuggest__input').focus();
            return;
        }
        if (engine_number === '') {
            toastr.remove();
            toastr.error('Please enter Engine Number');
            $('[name="engine_number"]').focus();
            return;
        }
        if (price === '') {
            toastr.remove();
            toastr.error('Please enter Price');
            $('[name="price"]').focus();
            return;
        } else {
            if (isNaN(price)) {
                toastr.remove();
                toastr.error('Please enter a valid amount for selling price');
                $('[name="price"]').focus();
                return;
            }
        }
        if (discount === '') {
            // toastr.remove();
            // toastr.error('Please enter Discount');
            // $('[name="discount"]').focus();
            // return;
        } else {
            if (isNaN(parseFloat(discount))) {
                toastr.remove();
                toastr.error('Please enter a valid amount for discount');
                $('[name="discount"]').focus();
                return;
            }
        }
        if (customer_name === '') {
            toastr.remove();
            toastr.error('Please enter Customer Name');
            $('[name="customer_name"]').focus();
            return;
        }
        if (account_number === '') {
            toastr.remove();
            toastr.error('Please enter Account Number');
            $('[name="account_number"]').focus();
            return;
        }

        // if(that.state.saving){
        // 	toastr.remove();
        // 	toastr.warning('Please wait...');
        // }

        this.setState({saving: true})

        const input = {
            customer: customer_name,
            customer_address: customer_address,
            area: area,
            account_number: account_number,
            date_foreclosed: date_foreclosed,
            model: {
                name: model.toUpperCase()
            },
            engine_number: engine_number,
            remarks: remarks,
            selling_price: price,
            discount: discount,
            origin_id: this.props.userData.branch_info.branch_name + (Date.now()),
            date_added_custom: moment().format('MM/DD/YYYY'),
            main_status: 0,
            type: 0,
            branch: branch
        }

        //check if unit has the same model AND engine number
        appFeathers.service('secondhand').find({
            query: {
                model: {
                    name: model.toUpperCase()
                },
                engine_number: engine_number
            }
        }).then((ifExists) => {
            if (ifExists.total) {
                toastr.remove();
                toastr.error('Engine Number ' + engine_number + ' already exists');
                this.setState({
                    saving: false
                })
            } else {
                appFeathers.service('secondhand').create(input)
                    .then((local_result) => {
                        toastr.remove();
                        toastr.success('Unit has been successfully added');
                        this.clearInputs();
                        /*appFeathers.authenticate({
                            strategy: 'local',
                            username: secret_user,
                            password: secret_pass,
                        })
                        .then((authenticated) => {
                            appFeathers.service('secondhand').create({
                                customer: local_result.customer,
                                address : local_result.address,
                                area: local_result.area,
                                account_number: local_result.account_number,
                                date_forclosed: local_result.date_foreclosed,
                                branch_info: local_result.branch_info,
                                model: {
                                    name: local_result.model.name
                                },
                                engine_number: local_result.engine_number,
                                remarks: local_result.remarks,
                                selling_price: local_result.selling_price,
                                discount: local_result.discount,
                                origin_id: local_result.origin_id,
                                date_added_custom: local_result.date_added_custom,
                                main_status: 0,
                                type: 0,
                            })
                            .then((main_result) => {
                                console.log('main_result ', main_result)
                                setTimeout(() => {
                                               that.props.history.push('/inventory_secondhand/');
                                      }, 2000)


                            })
                            .catch((main_error) => {
                                appFeathers.service('secondhand').patch(local_result._id, {main_status: 2})
                                .then((patched) => {
                                    console.log('patched ', patched)
                                })
                                console.log('main_error ',main_error)
                            })
                        })
                        .catch((main_auth_error) => {
                            console.log('auth error ', main_auth_error)
                            appFeathers.service('secondhand').patch(local_result._id, {main_status: 2})
                            .then((patched) => {
                                console.log('patched ', patched)
                            })
                        })*/
                    })
                    .catch((error) => {
                        this.setState({
                            saving: false
                        })
                        toastr.remove();
                        toastr.error('An error occured. Please try again');
                    })
            }
        })
    }


    handleSelectedArea = (selectedOption) => {
        const value = selectedOption;
        if (value != null) {
            // that.refs.area.value = "";
            this.setState({
                selectedArea: value,
                selectedAreaChange: true,
            });
        } else {
            this.setState({
                selectedArea: '',
                selectedAreaChange: false,
                // areaOther : true,
            })
        }

    }


    areaOption = () => {
        const array = this.props.areaList;
        return array;
    }

    branchOption = () => {
        const array = this.props.branch,
            newArray = []
        array.map((obj) => {
            if (obj.value !== 'all') {
                newArray.push(obj)
            }
        })
        return newArray
    }

    modelOption = () => {

        const array = this.props.models,
            newArray = []

        array.map((obj) => {
            if (obj.value !== 'all') {
                newArray.push(obj)
            }
        })
        return newArray
    }

    brandOption = () => {

        const array = this.props.brandsSelect,
            newArray = []

        array.map((obj) => {
            if (obj.value !== 'all') {
                newArray.push(obj)
            }
        })
        return newArray
    }

    handleChange = (selectedOption) => {

        const value = selectedOption === null ? null : selectedOption;

        if (value != null) {
            this.setState({
                selectedOption: value,
                selectChanged: true
            });
        } else {
            this.setState({
                selectedOption: '',
                selectChanged: false
            })
        }
    }

    permissionInventory = () => {
        const permission = this.props.userPermission;

        if (permission.inventory === 0) {
            return "not-visible"
        } else {
            return "";
        }
    }

    // handleDateDelivered = (e, p) => {
    handleDateDelivered = (date) => {
        // const d = p.startDate;
        // const formatted = moment(d).format('MM/DD/YYYY');
        // const date = moment(d);

        this.setState({
            date_delivered: date
        })
    }

    handleDeliveryReceiptDate = (date) => {
        this.setState({
            delivery_receipt_date: date
        })
    }

    onChange = (event, {newValue}) => {
        this.setState({
            value: newValue
        });
    };

    // Autosuggest will call this function every time you need to update suggestions.
    // You already implemented this logic above, so just use it.
    onSuggestionsFetchRequested = ({value}) => {
        this.setState({
            suggestions: this.getSuggestions(value)
        });
    };

    // Autosuggest will call this function every time you need to clear suggestions.
    onSuggestionsClearRequested = () => {
        this.setState({
            suggestions: []
        });
    };

// languages = this.props.modelsForSeconhandUnits != undefined ? this.props.modelsForSeconhandUnits : [] ;

// Teach Autosuggest how to calculate suggestions for any given input value.
    getSuggestions = value => {
        const inputValue = value.trim().toLowerCase();
        const inputLength = inputValue.length;
        const languages = this.props.modelsForSecondhandUnits;

        return inputLength === 0 ? [] : languages.filter(lang =>
            lang.name.toLowerCase().slice(0, inputLength) === inputValue
        );
    };


    render() {
        const {value, suggestions} = this.state;
        const inputProps = {
            placeholder: 'Enter model name',
            value,
            onChange: this.onChange,
            className: 'form-control'
        };

        // When suggestion is clicked, Autosuggest needs to populate the input
        // based on the clicked suggestion. Teach Autosuggest how to calculate the
        // input value for every given suggestion.
        const getSuggestionValue = suggestion => suggestion.name;

        // Use your imagination to render suggestions.
        const renderSuggestion = suggestion => (
            <div>
                {suggestion.name}
            </div>
        );
        return (
            <div className="">
                {/* main NavBar component*/}
                <MainNav historyProp={this.props.history} usersProp={this.props.userData}/>
                <div className="container-fluid">
                    <div className="row full-width">
                        <div className="sidebar">
                            <div className="sidebar-content">
                                <MainSideBar page="inventory"/>
                            </div>
                        </div>
                        <SubSideBar history={this.props.history} userPermission={this.props.userPermission}/>
                        <div className="sub-inv-main-content add_inventory_secondhand">
                            <div className="application-container">
                                {
                                    this.props.userPermission.inventory === 0 ?
                                        <div className="row no-access-div">
                                            <div className="col-md-12">
                                                <h1>Access denied</h1>
                                            </div>
                                        </div>
                                        : null
                                }
                                <div className={this.permissionInventory()}>
                                    <div className="row margin-y-24">
                                        <div className="col-md-12">
                                            <a href="javascript:;"
                                               onClick={() => this.props.history.push('/inventory_secondhand/')}
                                               className="text-gray no-textdecoration breadcrumb-inv">
                                                <span className="fa fa-chevron-left"/> Inventory / Add Secondhand Units
                                            </a>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <h4 className="subheader-title">Add Secondhand Units</h4>
                                                    <div className="inv-form__wrapper">
                                                        <div className="inv-form__col">
                                                            <div className="inv-form__div">
                                                                <span>Model</span>
                                                                <Autosuggest
                                                                    suggestions={suggestions}
                                                                    onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                                                                    onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                                                                    getSuggestionValue={getSuggestionValue}
                                                                    renderSuggestion={renderSuggestion}
                                                                    inputProps={inputProps}
                                                                    className="form-control"
                                                                />
                                                            </div>
                                                            <div className="inv-form__div">
                                                                <span>Customer Address</span>
                                                                <input
                                                                    className="form-control"
                                                                    placeholder="Add address"
                                                                    name="address"
                                                                    ref="address"
                                                                />
                                                            </div>
                                                            <div className="inv-form__div">
                                                                <span>Engine Number</span>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    name="engine_number"
                                                                    ref="engine_number"
                                                                    placeholder="Enter Engine Number"
                                                                />
                                                            </div>
                                                            <div className="inv-form__div">
                                                                <span>Selling Price</span>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    name="price"
                                                                    ref="price"
                                                                    maxLength={13}
                                                                    placeholder="Enter Price"
                                                                />
                                                            </div>
                                                            <div className="inv-form__div">
                                                                <span>Discount (%)</span>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    name="discount"
                                                                    ref="discount"
                                                                    maxLength={3}
                                                                    placeholder="Enter Discount Percentage"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="inv-form__col">
                                                            <div className="inv-form__div">
                                                                <span>Customer Name</span>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    name="customer_name"
                                                                    ref="customer_name"
                                                                    placeholder="Enter Customer Name"
                                                                />
                                                            </div>
                                                            <div className="inv-form__div">
                                                                <span>Area</span>
                                                                <Select
                                                                    name="select-area"
                                                                    value={this.state.selectedArea.value}
                                                                    onChange={this.handleSelectedArea}
                                                                    options={this.areaOption()}
                                                                    clearableValue={true}
                                                                    placeholder="Select Area"
                                                                />
                                                            </div>
                                                            <div className="inv-form__div">
                                                                <span>Account Number</span>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    name="account_number"
                                                                    ref="account_number"
                                                                    maxLength={20}
                                                                    placeholder="Enter Account Number"
                                                                />
                                                            </div>
                                                            <div className="inv-form__div">
                                                                <span>Date Foreclosed</span> <br />
                                                                <DatePicker
                                                                    selected={this.state.delivery_receipt_date}
                                                                    onChange={this.handleDeliveryReceiptDate}
                                                                    peekNextMonth
                                                                    showMonthDropdown
                                                                    showYearDropdown
                                                                    dropdownMode="select"
                                                                    className="form-control"
                                                                />
                                                            </div>
                                                            <div className="inv-form__div">
                                                                <span>Remarks</span>
                                                                <input
                                                                    className="form-control"
                                                                    placeholder="Add remarks"
                                                                    name="remarks"
                                                                    ref="remarks"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <br/>
                                                    <div className="row">
                                                        <div className="col">
                                                            <div className="form-check">
                                                                {
                                                                    // <label className="form-check-label">
                                                                    //   <input className="form-check-input" onChange={() => this.saveTemplate() } type="checkbox" value="" />
                                                                    //   	Save template for next entry <br/>
                                                                    //   	(Except Chassis and Engine Number)
                                                                    // </label>
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className="col">
                                                            <div className="row">
                                                                <div className="col">
                                                                    {
                                                                        // <button className="btn col-md-12 text-white bg-active-color" onClick={() => this.saveAndAdd(true) }>Save and Add New Entry</button>
                                                                    }
                                                                </div>
                                                                <div className="col">
                                                                    <button
                                                                        className="btn btn-block btn-second-primary"
                                                                        onClick={() => this.saveAndAdd(false)}>Save
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


const mapStateToProps = state => ({
    app: state.feathersClient.app,
    mainapp: state.feathersClient.mainapp,
    models: state.category.modelsSelect,
    branch: state.category.branchesSelect,
    brandsSelect: state.category.brandsSelect,
    userData: state.login.userData,
    userPermission: state.login.userPermission,
    areaList: state.customers.areaList,
    modelsForSecondhandUnits: state.product.modelsForSecondhandUnits,
});

function mapDispatchToProps(dispatch) {
    return {actions: bindActionCreators(ActionCreators, dispatch)}
}

export default connect(mapStateToProps, mapDispatchToProps)(add_inventory_secondhand);

