import React, {Component} from 'react';
import app from '../../../../helpers/feathers';
// react-router
import {Link} from 'react-router-dom';
//redux
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
// actions
import {ActionCreators} from '../../../../actions';
import defaultImage from '../../../../assets/img/default_avatar.jpg';
//toastr
import toastr from 'toastr';
import moment from 'moment';
//select tag
import Select from 'react-select';
import ConfirmationModalSH from '../../customer/confirm_modal_sh'
//main NavBar
import MainNav from '../../../../components/global_nav';
import MainSideBar from '../../../../components/acc_sidebar';
//sub side bar
import SubSideBar from '../sub_sidebar';
import DatePicker from 'react-datepicker';
import {
    encodeToString
} from '../../../../helpers'
import Autosuggest from 'react-autosuggest';
//custom styles
import '../../../../stylesheet/styles.css';
//datatables
import '../../../../stylesheet/datatables/datatables.css';

const $ = require('jquery');
$.DataTable = require('datatables.net');

var that;
var _0x896d = ["\x74\x65\x73\x74\x32"];
var secret_user = _0x896d[0], secret_pass = _0x896d[0]

class add_customer_cash_old_rec_sh extends Component {

    constructor(props) {
        super(props);

        this.state = {
            selectedModel: '',
            clickSubmit: false,
            selectedTerms: '',
            selectedTitle: '',
            selectedTitleChanged: false,
            selectedArea: '',
            selectedAreaChanged: false,
            selectedTermsChanged: false,
            submitting: false,
            selectedBranch: '',
            selectBranch: [
                {value: this.props.userData.branch_info._id, label: this.props.userData.branch_info._branch_name}
            ],
            mobile_num: '',
            age: '',
            selectedBrand: '',
            selectedBrandChanged: false,
            selectedOption: '',
            selectChanged: false,
            chassisOption: '',
            chassisChanged: false,
            modelsObj: [],
            chassisObj: [],
            termsIsDisabled: false,

            value: '',
            suggestions: [],
            secondhand_chassis: [],
            showDiv: false,
            confirmed: false,
            date_purchased: moment(),
            first_payment_date: moment(),
        };

    }

    componentWillMount() {
        that = this;
        if (that.props.type === null) {
            this.props.actions.getArea();
            // that.props.history.push('/customer')
        }
    }

    componentDidMount() {
        $('.hide-view-customer').css('display', 'none', '!important')

        // var localBranch = {
        //     value: localStorage.getItem('local_branch_id'),
        //     label: localStorage.getItem('local_branch_name')
        // }

        // this.setState({selectedBranch: localBranch})

        $('#confirmation_modal_sh').on('shown.bs.modal', function () {
            $(".modal #submitForm").focus();
        });

    }

    handleDatePurchased = (date) => {
        this.setState({date_purchased: date})
    }
    handleFirstPayment = (date) => {
        this.setState({first_payment_date: date})
    }

    handleChangeModel = (selectedOption) => {
        var value = selectedOption;

        if (value) {
            this.setState({
                selectedModel: value
            });
        } else {
            this.setState({
                selectedModel: ''
            });
        }

        $('#confirmation_modal_sh').on('hidden.bs.modal', function () {
            that.setState({
                confirmed: false
            })
        })

    }


    confirmation = () => {

        $('#confirmation_modal_sh').modal('show')

    }

    submitForm = () => {
        that.setState({confirmed: true})

        setTimeout(() => {
            that.addCustomer();
        }, 500)
    }

    handleChangeBranch = (selectedOption) => {
        var value = selectedOption === null ? '' : selectedOption;
        this.setState({
            selectedBranch: value
        });
    }

    handleselectedTerms = (selectedOption) => {
        var value = selectedOption;
        if (value != null) {
            if (value.value === 0) { // cash
                that.refs.terms.value = "";
                that.refs.account_number.value = "";
                that.refs.promisory_note.value = "";
                that.setState({
                    termsIsDisabled: true
                })
            } else { // installment
                that.setState({
                    termsIsDisabled: false
                })
            }
            this.setState({
                selectedTerms: value,
                selectedTermsChanged: true,
            });
        } else {
            this.setState({
                selectedTerms: '',
                selectedTermsChanged: false,
                termsIsDisabled: true,
            });
        }
    }


    handleselectedTitle = (selectedOption) => {
        var value = selectedOption;
        if (value != null) {
            if (value.value) {
                this.setState({selectedTitle: value})
            }
        } else {
            this.setState({selectedTitle: '', selectedTitleChanged: false})
        }
    }


    validateForm = (formData) => {
        var a = formData;
        if (formData.type === 1) {
            if (a.name !== '' &&
                a.unit !== '' &&
                // a.branch != '' &&
                a.mobile !== '' &&
                a.tin !== '' &&
                a.address.present !== '' &&
                a.address.length_of_stay !== '' &&
                a.age !== '' &&
                a.employer.present !== '' &&
                a.months !== '' &&
                a.terms.down_payment !== '' &&
                a.terms.monthly_payment !== ''
            ) {
                return true;
            }

            return false;
        } else {

            if (a.name !== '' &&
                a.unit !== '' &&
                a.branch !== '' &&
                a.mobile !== '' &&
                a.tin !== '' &&
                a.address.present !== '' &&
                a.age !== '' &&
                a.payment !== '') {
                return true;
            }

            return false;
        }
    }

    redirect = () => {
        setTimeout(() => {
            $("#confirmation_modal_sh").modal("hide")
            that.props.history.push('/view_customer_secondhand/');
        }, 2000)
    }

    showDiv = () => {
        that.setState({showDiv: true})
    }

    hideDiv = () => {
        that.setState({showDiv: false})
    }

    addCustomer = () => {
        let {
            value, //model name
            selectedTerms,
            selectedTitle,
            selectedArea,
            selectedAreaChange,
            date_purchased,
            first_payment_date,
            submitting,
            confirmed,
        } = this.state;
        let {
            engine_number,
            contact_number,
            name,
            customer_address,
            area,
            payment,
            or_number,
            remarks,
        } = this.refs;
        let { userData } = this.props;

        if(submitting){
            toastr.remove();
            toastr.info("Please wait while saving data");
            return;
        }

        if( value.trim() === '' ){
            toastr.remove();
            toastr.info("Please enter model name");
            return;
        }
        if( engine_number.value === '' ){
            toastr.remove();
            toastr.info("Please enter engine number");
            return;
        }
        if(selectedTitle === ''){
            toastr.remove();
            toastr.info("Please select title");
            return;
        }
        if( (name.value).trim() === '' ){
            toastr.remove();
            toastr.info("Please enter customer name");
            return;
        }
        if(selectedArea === ''){
            if( (area.value).trim() === '' ){
                toastr.remove();
                toastr.info("Please select customer area");
                return;
            }
        }
        if( (payment.value).trim() === '' ){
            toastr.remove();
            toastr.info("Please enter payment");
            return;
        }else{
            if( isNaN(payment.value) ){
                toastr.remove();
                toastr.info("Please enter a numeric value for payment");
                return;
            }
        }

        if( date_purchased === null ){
            toastr.remove();
            toastr.info("Please enter date purchased");
            return;
        }
        if(or_number.value === ''){
            toastr.remove();
            toastr.info("Please enter OR number");
            return;
        }

        let formData = {
            name: name.value,
            title: selectedTitle.value,
            unit: {
                name: value
            },
            unit_engine_number: engine_number.value,
            payment_method: 0, // 0 = cash
            contact_number: contact_number.value,
            customer_address: customer_address.value,
            area: selectedAreaChange ? selectedArea.label : area.value,
            payment: payment.value,
            date_purchased: date_purchased.format("MM/DD/YYYY"),
            date_purchased_formatted: date_purchased.format("MM/YYYY"),
            or_number: or_number.value,
            old_unit: 1,
            branch: userData.branch_info._id,
            unit_model: value,
            remarks: remarks.value
        };


        if (!confirmed) {
            that.confirmation();
            return
        }

        that.setState({submitting: true});

        let customerSecondhandService = app.service('customer-secondhand-unit');
        that.props.actions.checkIfExistCustomerSH(formData)
            .then((result) => {
                if (result.status) {
                    toastr.info(result.message)
                    this.setState({submitting: false});
                } else {
                    this.props.actions.addCustomerWithSecondhandUnit(formData).then((data) => {
                        if (data) {
                            toastr.remove();
                            toastr.success('Customer has been successfully added');
                            that.clearInputs();

                            $("#confirmation_modal_sh").modal("hide")
                            that.setState({submitting: false})

                        } else {
                            toastr.remove();
                            toastr.info('An error occured. Please try again');
                        }
                    });
                }
            })
    }

    onChange = (event, {newValue}) => {
        this.setState({
            value: newValue
        });

        if (newValue) {
        } else {
            that.setState({
                chassisOption: ''
            })
        }

        that.props.actions.getSecondhandUnitsUsingModel(newValue)
            .then((response) => {
                if (!response.length) {
                    that.setState({
                        chassisOption: ''
                    })
                }
                that.setState({
                    secondhand_chassis: response
                })
            });
    };

    // Autosuggest will call this function every time you need to update suggestions.
    // You already implemented this logic above, so just use it.
    onSuggestionsFetchRequested = ({value}) => {
        this.setState({
            suggestions: that.getSuggestions(value)
        });
    };

    // Autosuggest will call this function every time you need to clear suggestions.
    onSuggestionsClearRequested = () => {
        this.setState({
            suggestions: []
        });
    };

// languages = this.props.modelsForSeconhandUnits != undefined ? this.props.modelsForSeconhandUnits : [] ;

    // Teach Autosuggest how to calculate suggestions for any given input value.
    getSuggestions = value => {
        const inputValue = value.trim().toLowerCase();
        const inputLength = inputValue.length;
        const languages = this.props.modelsForSecondhandUnits;

        return inputLength === 0 ? [] : languages.filter(lang =>
            lang.name.toLowerCase().slice(0, inputLength) === inputValue
        );
    };


    handleSelectedArea = (selectedOption) => {
        var value = selectedOption;
        if (value != null) {
            that.refs.area.value = "";
            this.setState({
                selectedArea: value,
                selectedAreaChange: true,
            });
        } else {
            this.setState({
                selectedArea: '',
                selectedAreaChange: false,
                // areaOther : true,
            })
        }

    }

    areaOption = () => {
        var array = this.props.areaList;
        return array
    }

    back = () => {
        this.props.history.push("/customer_secondhand_unit")
    }
    clearInputs = () => {
        let {
            engine_number,
            contact_number,
            name,
            customer_address,
            area,
            payment,
            or_number,
            remarks,
        } = this.refs;

        this.setState({value: '',selectedTitle: '',selectedArea: '',selectedTerms: '',confirmed: false,selectedAreaChange: false});
        engine_number.value = '';
        contact_number.value = '';
        name.value = '';
        customer_address.value = '';
        area.value = '';
        payment.value = '';
        or_number.value = '';
        remarks.value = '';
    }

    render() {
        const {value, suggestions,selectedTerms} = this.state;
        const inputProps = {
            placeholder: 'Enter Model Name',
            value,
            onChange: this.onChange
        };

        // When suggestion is clicked, Autosuggest needs to populate the input
        // based on the clicked suggestion. Teach Autosuggest how to calculate the
        // input value for every given suggestion.
        const getSuggestionValue = suggestion => suggestion.name;

        // Use your imagination to render suggestions.
        const renderSuggestion = suggestion => (
            <div>
                {suggestion.name}
            </div>
        );
        return (
            <div className="">
                {/* main NavBar component*/}
                <MainNav historyProp={this.props.history} usersProp={this.props.userData}/>

                <ConfirmationModalSH showDiv={this.showDiv} hideDiv={this.hideDiv} submitForm={this.submitForm}/>

                <div className="container-fluid">
                    <div className="row full-width">
                        <div className="sidebar">
                            <div className="sidebar-content">
                                <MainSideBar page="old_records"/>
                            </div>
                        </div>
                        <SubSideBar history={this.props.history} path="/acc_add_customer"/>
                        <div className="sub-main-content">
                            <div className="container-fluid application-container">
                                <a href="javascript:;"
                                   onClick={() => this.props.history.goBack()}
                                   className="text-gray no-textdecoration">
                                    <span className="fa fa-chevron-left"/> Old Records / Add Customer (Cash) / Secondhand Units
                                </a>
                                <br/>
                                <br/>
                                <div className="add-customer-secondhand-unit">
                                    <div className="background-white padding-20">
                                        <form ref="customerForm"
                                              id="needs-validation" noValidate>
                                            <div className="container-fluid">
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="row">
                                                            <div className="col">
                                                                <span>Model</span>
                                                                <Autosuggest
                                                                    suggestions={suggestions}
                                                                    onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                                                                    onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                                                                    getSuggestionValue={getSuggestionValue}
                                                                    renderSuggestion={renderSuggestion}
                                                                    inputProps={inputProps}
                                                                    className="form-control"
                                                                />
                                                            </div>
                                                            <div className="col">
                                                                <span>Engine Number</span>
                                                                <input className="form-control" ref="engine_number" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <br/>
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="row">
                                                            <div className="col">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <br/>
                                                <div className="row">
                                                    <div className="col-md-3">
                                                        <span>Title </span>
                                                        <Select
                                                            name="select-title"
                                                            value={this.state.selectedTitle.value}
                                                            onChange={this.handleselectedTitle}
                                                            options={[
                                                                {value: 'MS', label: 'MS'},
                                                                {value: 'MRS', label: 'MRS'},
                                                                {value: 'MR', label: 'MR'},
                                                                {value: 'N/A', label: 'N/A'},

                                                            ]}
                                                            clearableValue={true}
                                                            placeholder="MS/MRS/MR"
                                                        />
                                                    </div>
                                                    <div className="col">
                                                        <div className="row">
                                                            <div className="col">
                                                                <span>Customer Name </span>
                                                                <input type="text" className="form-control" ref="name"
                                                                       required/>

                                                                <span><i>(Last name, Firs name, MI)</i></span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <br/>
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="row">
                                                            <div className="col">
                                                                <span>Customer Address </span>
                                                                <input type="text" className="form-control"
                                                                       ref="customer_address" required/>
                                                            </div>
                                                            <div className="col">
                                                                <span>Contact Number </span>
                                                                <input type="text" className="form-control"
                                                                       ref="contact_number" required/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <br/>
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="row">
                                                            <div className="col">
                                                                <span>Area</span>
                                                                <Select
                                                                    name="select-area"
                                                                    value={this.state.selectedArea.value}
                                                                    onChange={this.handleSelectedArea}
                                                                    options={this.areaOption()}
                                                                    clearableValue={true}
                                                                    placeholder="Select Area"
                                                                />
                                                            </div>
                                                            <div className="col">
                                                                <span>If other area (please specify)</span>
                                                                <input type="text" className="form-control" ref="area"
                                                                       disabled={this.state.selectedAreaChange}/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <br/>
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="row">
                                                            <div className="col">
                                                                <span>Amount</span>
                                                                <input type="text" className="form-control"
                                                                       ref="payment"/>
                                                            </div>
                                                            <div className="col">
                                                                <span>Date Purchased</span>
                                                                <DatePicker
                                                                    selected={this.state.date_purchased}
                                                                    onChange={this.handleDatePurchased}
                                                                    peekNextMonth
                                                                    showMonthDropdown
                                                                    showYearDropdown
                                                                    dropdownMode="select"
                                                                    className="form-control"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <br/>
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="row">
                                                            <div className="col">
                                                            {
                                                                // <div>
                                                                //     <span>Date of First Payment</span>
                                                                //     <DatePicker
                                                                //         selected={this.state.first_payment_date}
                                                                //         onChange={this.handleFirstPayment}
                                                                //         peekNextMonth
                                                                //         showMonthDropdown
                                                                //         showYearDropdown
                                                                //         dropdownMode="select"
                                                                //         className="form-control"
                                                                //     />
                                                                // </div>
                                                            }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <br/>
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="row">
                                                            <div className="col">
                                                                <span>OR Number</span>
                                                                <input type="text" className="form-control"
                                                                       ref="or_number"/>
                                                            </div>
                                                            <div className="col">
                                                                <span>Remarks</span>
                                                                <input type="text" className="form-control"
                                                                       ref="remarks"/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <br/>
                                                <div className="row">
                                                    <br/>
                                                    <br/>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-3">
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="row">
                                                            <div className="col">
                                                                <button className="btn btn-block btn-primary"
                                                                        type="button" onClick={this.addCustomer}>
                                                                    <span className="fa fa-save"/> SAVE
                                                                </button>
                                                            </div>
                                                            <div className="col">
                                                                <button className="btn btn-block btn-danger"
                                                                        type="button" onClick={this.clearInputs}>
                                                                    <span className="fa fa-remove"/> CANCEL
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <br/>
                                                    <br/>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


const mapStateToProps = state => ({
    customer: state.customers,
    productSelect: state.product.productSelect,
    branchSelect: state.category.branchesSelect,
    models: state.category.modelsSelect,
    add_cust_model: state.category.add_cust_model,
    brandsSelect: state.category.brandsSelect,
    type: state.customers.type,
    userData: state.login.userData,
    addCustBranch: state.customers.addCustBranch,
    modelsForSecondhandUnits: state.product.modelsForSecondhandUnits,
    areaList: state.customers.areaList,
    session_id: state.login.userData._id,
});

function mapDispatchToProps(dispatch) {
    return {actions: bindActionCreators(ActionCreators, dispatch)}
}

export default connect(mapStateToProps, mapDispatchToProps)(add_customer_cash_old_rec_sh);
