import React from 'react';
//redux
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
// actions
import {ActionCreators} from '../../actions';

import {Link} from 'react-router-dom';
import toastr from 'toastr';
import eversureLogo from '../../assets/logo/eversure_logo.png';

class resetpassword extends React.PureComponent {

	constructor(props) {
		super(props);

		this.state = {
			email: ''
		}
	}

	resetPassword = () => {
		const that = this;
		let { email } = this.state;

		var isEmail = (email) => {
			const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	    return re.test(String(email).toLowerCase());
		}

		if(!email){
			toastr.remove()
			toastr.info("Please enter email")
		}else{
				toastr.remove()
			if(isEmail(email)){
				that.props.actions.requestForReset(email)
				.then((res) => {
					that.refs.email.value = '';
					that.setState({email: ''})
					if(res.status){
						toastr.success(res.message)
					}else{
						toastr.error(res.message)
					}
				})
			}else{
				toastr.info("Please enter a valid email")
			}
		}
	}

	inputSubmitted = (e) => {
    if (e.key === 'Enter') {
        this.resetPassword();
    }
	}

	render() {
		return (
			<div className="container">
				<div className="row">
					<div className="col-lg-4"></div>
					<div className="col-lg-4 col-sm-12">
						<div className="reset-pass">
                        	<div className="logo">
								<center>
									<img src={eversureLogo} alt="logo"/>
									<br />
									<br />
									<br />
								</center>
							</div>
							<div className="form-group page-header">
								<h1>Reset Password</h1>
							</div>
							<div className="form-group">
								<label>Email</label>
								<input
									className="form-control"
									placeholder="Please enter your email here"
									onChange={(e) => this.setState({email: e.target.value})}
									onKeyPress={(e) => this.inputSubmitted(e)}
									ref="email"
								/>
							</div>
							<div className="form-group">
								<button className="btn btn-block btn-primary es-btn-bg" onClick={this.resetPassword}>Reset</button>
							</div>
							<div className="form-group login-link">
								<Link to="/">Login</Link>
							</div>
						</div>
					</div>
					<div className="col-lg-4"></div>
				</div>
			</div>
		);
	}
}


const mapStateToProps = state => ({
    login: state,
});

function mapDispatchToProps(dispatch) {
    return {actions: bindActionCreators(ActionCreators, dispatch)}
}

export default connect(mapStateToProps, mapDispatchToProps)(resetpassword);
