
import app from '../../../helpers/feathers';


import React, {Component} from 'react';


//redux
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
// actions
import {ActionCreators} from '../../../actions';

//select tag
import Select from 'react-select';

import toastr from 'toastr';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const $ = require('jquery');

var that

class edit_financial_modal extends Component {

    constructor(props) {
        super(props);

        this.state = {};
    }

    componentWillMount() {
        that = this;
        this.props.actions.getArea();

    }


    componentDidMount() {

        $(".numbers-only .form-control").on("keyup", function () {
            var valid = /^\d{0,11}(\.\d{0,2})?$/.test(this.value),
                val = this.value;

            if (!valid) {
                console.log("Invalid input!");
                this.value = val.substring(0, val.length - 1);
            }
        });
        $(document).on("change", ".qty1", function () {
            var sum = 0;
            $(".qty1").each(function () {
                sum += +$(this).val();
            });
            $(".total").val(sum);
        });


        $(document).on("change", ".qty2", function () {
            var sum = 0;
            $(".qty2").each(function () {
                sum += +$(this).val();
            });
            $(".total1").val(sum);
        });

        let {financialInfo} = this.props.financialInfo;
    }

    // clearInputs = () => {
    // 		this.refs.account_number.value = "";
    // 		this.refs.name.value = "";
    // 		this.refs.payment.value = "";
    // 		this.refs.remarks.value = "";
    // 		this.refs.customer_address.value = "";
    // }


    editFinancial = () => {

        const that = this;
        let query = {};
        let {financialInfo} = this.props;
        let {
            cash_sales, downpayment, collection_ma, total_collection, furniture_fixes, office_equipment,
            other_fix_assets, freebies, commision, incentives, advertising_promotions, salaries_wages,
            sss_phic_pagibig, employee_benefits, meal_allowance, am_gas, bm_gas, ci_gas, l3_gas, tax_license, rental,
            repair_maintenance, light_water, communication, office_supplies, mailing_expenses, transportation, medical_assistance,
            photocopy, cleaning_materials, water_refill, other_expenses, total_expenses,for_date
        } = this.refs;

        if (
            for_date.value === '' &&
            cash_sales.value === '' &&
            downpayment.value === '' &&
            collection_ma.value === '' &&
            total_collection.value === '' &&
            furniture_fixes.value === '' &&
            office_equipment.value === '' &&
            other_fix_assets.value === '' &&
            freebies.value === '' &&
            commision.value === '' &&
            incentives.value === '' &&
            advertising_promotions.value === '' &&
            salaries_wages.value === '' &&
            sss_phic_pagibig.value === '' &&
            employee_benefits.value === '' &&
            meal_allowance.value === '' &&
            am_gas.value === '' &&
            bm_gas.value === '' &&
            ci_gas.value === '' &&
            l3_gas.value === '' &&
            tax_license.value === '' &&
            rental.value === '' &&
            repair_maintenance.value === '' &&
            light_water.value === '' &&
            communication.value === '' &&
            office_supplies.value === '' &&
            mailing_expenses.value === '' &&
            transportation.value === '' &&
            medical_assistance.value === '' &&
            photocopy.value === '' &&
            cleaning_materials.value === '' &&
            water_refill.value === '' &&
            other_expenses.value === '' &&
            total_expenses.value === '') {
            toastr.info("No changes made");
            return;
        }

        if(for_date.value){
            query.for_date = for_date.value
        }
        if (cash_sales.value) {
            query.cash_sales = cash_sales.value
        }
        if (downpayment.value) {
            query.downpayment = downpayment.value
        }
        if (collection_ma.value) {
            query.collection_ma = collection_ma.value
        }
        if (total_collection.value) {
            query.total_collection = total_collection.value
        }
        if (furniture_fixes.value) {
            query.furniture_fixes = furniture_fixes.value
        }
        if (office_equipment.value) {
            query.office_equipment = office_equipment.value
        }
        if (other_fix_assets.value) {
            query.other_fix_assets = other_fix_assets.value
        }
        if (freebies.value) {
            query.freebies = freebies.value
        }
        if (commision.value) {
            query.commision = commision.value
        }
        if (incentives.value) {
            query.incentives = incentives.value
        }
        if (advertising_promotions.value) {
            query.advertising_promotions = advertising_promotions.value
        }
        if (salaries_wages.value) {
            query.salaries_wages = salaries_wages.value
        }
        if (sss_phic_pagibig.value) {
            query.sss_phic_pagibig = sss_phic_pagibig.value
        }
        if (employee_benefits.value) {
            query.employee_benefits = employee_benefits.value
        }
        if (meal_allowance.value) {
            query.meal_allowance = meal_allowance.value
        }
        if (am_gas.value) {
            query.am_gas = am_gas.value
        }
        if (bm_gas.value) {
            query.bm_gas = bm_gas.value
        }
        if (ci_gas.value) {
            query.ci_gas = ci_gas.value
        }
        if (l3_gas.value) {
            query.l3_gas = l3_gas.value
        }
        if (tax_license.value) {
            query.tax_license = tax_license.value
        }
        if (rental.value) {
            query.rental = rental.value
        }
        if (repair_maintenance.value) {
            query.repair_maintenance = repair_maintenance.value
        }
        if (light_water.value) {
            query.light_water = light_water.value
        }
        if (communication.value) {
            query.communication = communication.value
        }
        if (office_supplies.value) {
            query.office_supplies = office_supplies.value
        }
        if (mailing_expenses.value) {
            query.mailing_expenses = mailing_expenses.value
        }
        if (transportation.value) {
            query.transportation = transportation.value
        }
        if (medical_assistance.value) {
            query.medical_assistance = medical_assistance.value
        }
        if (photocopy.value) {
            query.photocopy = photocopy.value
        }
        if (cleaning_materials.value) {
            query.cleaning_materials = cleaning_materials.value
        }
        if (water_refill.value) {
            query.water_refill = water_refill.value
        }
        if (other_expenses.value) {
            query.other_expenses = other_expenses.value
        }
        if (total_expenses.value) {
            query.total_expenses = total_expenses.value
        }

        app.service('financial').patch(financialInfo._id, query)
            .then((data) => {
                console.log('DATA OF FINANCIAL', data);
                toastr.success('Financial report successfully updated');
                that.closeModal();
            })
            .catch((err) => {
                console.log('ERRROR UPDATING', err);
            })


    }


    closeModal = () => {

        $('.form-control').val('');
        $('.modal').modal('hide');
    }

    render() {

        return (
            <div className="modal fade  bd-example-modal-lg" id="edit_customer" tabIndex="-1" role="dialog"
                 aria-labelledby="exampleModalLabel" aria-hidden="true" ref="add_branch" data-backdrop="static"
                 data-keyboard="false">

                <div className="modal-dialog modal-custom modal-lg " role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Edit Financial Statement</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row" style={{marginBottom: 20}}>
                                <div className="col-md-6">
                                    <h5>Date - {that.props.financialInfo.for_date}</h5>
                                    <input className="form-control" ref="for_date" placeholder="Enter Date" />
                                </div>
                            </div>
                            <h4>Collection (<small><i>Brand new and Repo</i></small>)</h4>
                            <div className="row numbers-only">
                                <div className="col">
                                    <span>Cash Sales - {that.props.financialInfo.cash_sales}</span>
                                    <input type="text" className="form-control qty1" id="cash_sales" ref="cash_sales"
                                           placeholder="0.00" defaultValue={that.props.financialInfo.cash_sales}/>
                                </div>
                                <div className="col">
                                    <span>Downpayments - {that.props.financialInfo.downpayment}</span>
                                    <input type="text" className="form-control qty1" id="downpayment" ref="downpayment"
                                           placeholder="0.00" onChange={this.handleNum2}
                                           defaultValue={that.props.financialInfo.downpayment}/>
                                </div>
                                <div className="col">
                                    <span>Collections <small>(monthly_amortization) - </small> {that.props.financialInfo.collection_ma}</span>
                                    <input type="text" className="form-control qty1" id="ma" ref="collection_ma"
                                           placeholder="0.00" onChange={this.handleNum3}
                                           defaultValue={that.props.financialInfo.collection_ma}/>
                                </div>
                                <div className="col">
                                    <span>TOTAL COLLECTION - {that.props.financialInfo.total_collection}</span>
                                    <input type="text" className="form-control total" id="total" ref="total_collection"
                                           readOnly/>
                                </div>
                            </div>

                            <h4>Expenses</h4>
                            <hr/>
                            <div className="row numbers-only">
                                <div className="col-md-12">
                                    <h6><i>Capital Expendeture :</i></h6>
                                    <div className="row">
                                        <div className="col">
                                            <span>Furniture and Fixtures - {that.props.financialInfo.furniture_fixes}</span>
                                            <input type="text" className="form-control qty2" ref="furniture_fixes"
                                                   placeholder="0.00"
                                                   defaultValue={that.props.financialInfo.furniture_fixes}/>
                                        </div>
                                        <div className="col">
                                            <span>Office Equipments - {that.props.financialInfo.office_equipment}</span>
                                            <input type="text" className="form-control qty2" ref="office_equipment"
                                                   placeholder="0.00"
                                                   defaultValue={that.props.financialInfo.office_equipment}/>
                                        </div>
                                        <div className="col">
                                            <span>Other fixed assets - {that.props.financialInfo.other_fix_assets}</span>
                                            <input type="text" className="form-control qty2" ref="other_fix_assets"
                                                   placeholder="0.00"
                                                   defaultValue={that.props.financialInfo.other_fix_assets}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <br/>
                            <div className="row numbers-only">
                                <div className="col-md-12">
                                    <h6><i>Marketing Expenses :</i></h6>
                                    <div className="row">
                                        <div className="col">
                                            <span>Freebies/Gas for release - {that.props.financialInfo.freebies}</span>
                                            <input type="text" className="form-control qty2" ref="freebies"
                                                   placeholder="0.00" defaultValue={that.props.financialInfo.freebies}/>
                                        </div>
                                        <div className="col">
                                            <span>Commission - {that.props.financialInfo.commision}</span>
                                            <input type="text" className="form-control qty2" ref="commision"
                                                   placeholder="0.00"
                                                   defaultValue={that.props.financialInfo.commision}/>
                                        </div>
                                        <div className="col">
                                            <span>Incentives - {that.props.financialInfo.incentives}</span>
                                            <input type="text" className="form-control qty2" ref="incentives"
                                                   placeholder="0.00"
                                                   defaultValue={that.props.financialInfo.incentives}/>
                                        </div>
                                        <div className="col">
                                            <span>Advertising and Promotions - {that.props.financialInfo.advertising_promotions}</span>
                                            <input type="text" className="form-control qty2"
                                                   ref="advertising_promotions" placeholder="0.00"
                                                   defaultValue={that.props.financialInfo.advertising_promotions}/>
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <br/>
                            <div className="row numbers-only">
                                <div className="col-md-12">
                                    <h6><i>Administrative Expenses :</i></h6>
                                    <div className="row">
                                        <div className="col">
                                            <span>Salaries &amp; Wages  - {that.props.financialInfo.salaries_wages}</span>
                                            <input type="text" className="form-control qty2 " ref="salaries_wages"
                                                   placeholder="0.00"
                                                   defaultValue={that.props.financialInfo.salaries_wages}/>
                                        </div>
                                        <div className="col">
                                            <span>SSS/PHIC/Pag-ibig - {that.props.financialInfo.sss_phic_pagibig}</span>
                                            <input type="text" className="form-control qty2" ref="sss_phic_pagibig"
                                                   placeholder="0.00"
                                                   defaultValue={that.props.financialInfo.sss_phic_pagibig}/>
                                        </div>
                                        <div className="col">
                                            <span>Employee's Benefits - {that.props.financialInfo.employee_benefits}</span>
                                            <input type="text" className="form-control qty2" ref="employee_benefits"
                                                   placeholder="0.00"
                                                   defaultValue={that.props.financialInfo.employee_benefits}/>
                                        </div>
                                        <div className="col">
                                            <span>Meal Allowance - {that.props.financialInfo.meal_allowance}</span>
                                            <input type="text" className="form-control qty2" ref="meal_allowance"
                                                   placeholder="0.00"
                                                   defaultValue={that.props.financialInfo.meal_allowance}/>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <br/>
                            <div className="row numbers-only">
                                <div className="col-md-12">
                                    <h6>Gasoline :</h6>
                                    <div className="row">
                                        <div className="col">
                                            <span>AM -{that.props.financialInfo.am_gas}</span>
                                            <input type="text" className="form-control qty2" ref="am_gas"
                                                   placeholder="0.00" defaultValue={that.props.financialInfo.am_gas}/>
                                        </div>
                                        <div className="col">
                                            <span>BM -{that.props.financialInfo.bm_gas}</span>
                                            <input type="text" className="form-control qty2" ref="bm_gas"
                                                   placeholder="0.00" defaultValue={that.props.financialInfo.bm_gas}/>
                                        </div>
                                        <div className="col">
                                            <span>CI -{that.props.financialInfo.ci_gas}</span>
                                            <input type="text" className="form-control qty2" ref="ci_gas"
                                                   placeholder="0.00" defaultValue={that.props.financialInfo.ci_gas}/>
                                        </div>
                                        <div className="col">
                                            <span>L300/H100/Multicab -{that.props.financialInfo.l3_gas}</span>
                                            <input type="text" className="form-control qty2" ref="l3_gas"
                                                   placeholder="0.00" defaultValue={that.props.financialInfo.l3_gas}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <br/>
                            <div className="row numbers-only">
                                <div className="col-md-12">
                                    <div className="row">
                                        <div className="col">
                                            <span>Taxes &amp; Licenses  -{that.props.financialInfo.tax_license}</span>
                                            <input type="text" className="form-control qty2" ref="tax_license"
                                                   placeholder="0.00"
                                                   defaultValue={that.props.financialInfo.tax_license}/>
                                        </div>
                                        <div className="col">
                                            <span>Rental -{that.props.financialInfo.rental}</span>
                                            <input type="text" className="form-control qty2" ref="rental"
                                                   placeholder="0.00" defaultValue={that.props.financialInfo.rental}/>
                                        </div>
                                        <div className="col">
                                            <span>Repair and Maintenance -{that.props.financialInfo.repair_maintenance}</span>
                                            <input type="text" className="form-control qty2" ref="repair_maintenance"
                                                   placeholder="0.00"
                                                   defaultValue={that.props.financialInfo.repair_maintenance}/>
                                        </div>
                                        <div className="col">
                                            <span>Light &amp; Water - {that.props.financialInfo.light_water}</span>
                                            <input type="text" className="form-control qty2" ref="light_water"
                                                   placeholder="0.00"
                                                   defaultValue={that.props.financialInfo.light_water}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <br/>
                            <div className="row numbers-only">
                                <div className="col-md-12">
                                    <div className="row">
                                        <div className="col">
                                            <span>Communication  - {that.props.financialInfo.communication}</span>
                                            <input type="text" className="form-control qty2" ref="communication"
                                                   placeholder="0.00"
                                                   defaultValue={that.props.financialInfo.communication}/>
                                        </div>
                                        <div className="col">
                                            <span>Office Supplies  - {that.props.financialInfo.office_supplies}</span>
                                            <input type="text" className="form-control qty2" ref="office_supplies"
                                                   placeholder="0.00"
                                                   defaultValue={that.props.financialInfo.office_supplies}/>
                                        </div>
                                        <div className="col">
                                            <span>Mailing Expenses  - {that.props.financialInfo.mailing_expenses}</span>
                                            <input type="text" className="form-control qty2" ref="mailing_expenses"
                                                   placeholder="0.00"
                                                   defaultValue={that.props.financialInfo.mailing_expenses}/>
                                        </div>
                                        <div className="col">
                                            <span>Transportaion &amp; Travel  - {that.props.financialInfo.transportation}</span>
                                            <input type="text" className="form-control qty2" ref="transportation"
                                                   placeholder="0.00"
                                                   defaultValue={that.props.financialInfo.transportation}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <br/>
                            <div className="row numbers-only">
                                <div className="col-md-12">
                                    <div className="row">
                                        <div className="col">
                                            <span>Medical Assistance - {that.props.financialInfo.medical_assistance}</span>
                                            <input type="text" className="form-control qty2" ref="medical_assistance"
                                                   placeholder="0.00"
                                                   defaultValue={that.props.financialInfo.medical_assistance}/>
                                        </div>
                                        <div className="col">
                                            <span>Photocopy - {that.props.financialInfo.photocopy}</span>
                                            <input type="text" className="form-control qty2" ref="photocopy"
                                                   placeholder="0.00"
                                                   defaultValue={that.props.financialInfo.photocopy}/>
                                        </div>
                                        <div className="col">
                                            <span>Cleaning Materials - {that.props.financialInfo.cleaning_materials}</span>
                                            <input type="text" className="form-control qty2" ref="cleaning_materials"
                                                   placeholder="0.00"
                                                   defaultValue={that.props.financialInfo.cleaning_materials}/>
                                        </div>
                                        <div className="col">
                                            <span>Water Refill - {that.props.financialInfo.water_refill}</span>
                                            <input type="text" className="form-control qty2" ref="water_refill"
                                                   placeholder="0.00"
                                                   defaultValue={that.props.financialInfo.water_refill}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <br/>
                            <div className="row numbers-only">
                                <div className="col-md-6">
                                    <div className="row">
                                        <div className="col">
                                            <span>Other Expenses - {that.props.financialInfo.other_expenses} </span>
                                            <input type="text" className="form-control qty2" ref="other_expenses"
                                                   placeholder="0.00"
                                                   defaultValue={that.props.financialInfo.other_expenses}/>
                                        </div>
                                        <div className="col">
                                            <span>Total Expenses - {that.props.financialInfo.total_expenses}</span>
                                            <input type="text" className="form-control total1" ref="total_expenses"
                                                   readOnly/>
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" onClick={this.closeModal}>Cancel
                            </button>
                            <a data-dismiss="modal" ref="cancel"/>
                            <button type="button" className="btn btn-second-primary" onClick={this.editFinancial}>Save</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


const mapStateToProps = state => ({
    app: state.feathersClient.app,
    mainapp: state.feathersClient.mainapp,
    category: state.category,
    payment: state.customers.customerPayments,
    userInfoEdit: state.users.userInfoEdit,
    paymentInfoEdit: state.customers.paymentInfoEdit,
    position: state.category.positionsSelect,
    customerInfo: state.customers.customerInfo,
    brandsSelect: state.category.brandsSelect,
    paymentTableData: state.customers.paymentTableData,
    setCustomerInfo: state.customers.setCustomerInfo,
    customer: state.customers,
    productSelect: state.product.productSelect,
    branchSelect: state.category.branchesSelect,
    models: state.category.modelsSelect,
    add_cust_model: state.category.add_cust_model,
    type: state.customers.type,
    userData: state.login.userData,
    session_id: state.login.userData._id,
    addCustBranch: state.customers.addCustBranch,
    areaList: state.customers.areaList,
    customers: state.customers.customersTerms,
    financialInfo: state.reports.financialInfo

});

function mapDispatchToProps(dispatch) {
    return {actions: bindActionCreators(ActionCreators, dispatch)}
}

export default connect(mapStateToProps, mapDispatchToProps)(edit_financial_modal);
