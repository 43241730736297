import React from 'react';
import './App.css';
// react router
import {Route, withRouter, Switch, HashRouter as Router} from 'react-router-dom';
//redux
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as LoginActions from './actions/login';

/* pages START */
import Login from './pages/login/login'
import ResetPassword from './pages/login/resetpassword'
import Dashboard from './pages/main/dashboard/dashboard'
import Notifications from './pages/main/dashboard/notifications'
import LandingInventory from './pages/main/inventory/landing_page'

import Payments from './pages/main/payments/payments';

import Application from './pages/main/customer/customer';
import ViewApplication from './pages/main/customer/view_customer';

import AddCustomer from './pages/main/customer/add_customer';
import AllCustomers from './pages/main/accounting/all_customers';
import CustomerCash from './pages/main/customer/customer_cash';
import CustomerBadRecords from './pages/main/customer/customer_badrecords';
import CustomerSecondhand from './pages/main/customer/customer_secondhand';
import ViewCustomerSecondhand from './pages/main/customer/view_customer_secondhand';

import EditCustomerSecondhand from './pages/main/customer/edit_customer_secondhand_modal';

import ViewCustomerSecondhand2 from './pages/main/customer/view_customer_secondhand2';

import AllUnits from './pages/main/inventory/master_list';
import InventoryList from './pages/main/inventory/inventory';
import AddInventorySecondhand from './pages/main/inventory/add_inventory_secondhand';
import InventoryListSecondhand from './pages/main/inventory/inventory_secondhand';
import InventoryFilter from './pages/main/inventory/inventory_filter';
import AddInventory from './pages/main/inventory/add_inventory';
import InventorySold from './pages/main/inventory/sold';
import InventorySoldSecondhand from './pages/main/inventory/sold_secondhand';
import InventoryTransfer from './pages/main/inventory/transfer';
import InventoryTransferSecondhand from './pages/main/inventory/transfer_secondhand';
import InventoryOutgoing from './pages/main/inventory/outgoing';
import InventoryOutgoingSecondhand from './pages/main/inventory/outgoing_secondhand';
import ViewInventory from './pages/main/inventory/view_inventory';
import ViewInventorySecondhand from './pages/main/inventory/view_inventory_secondhand';
import UpdateInventory from './pages/main/inventory/update_inventory';
import UpdateSecondHand from './pages/main/inventory/update_secondhand';
import DuplicateInventory from './pages/main/inventory/duplicate_entries';
import DuplicateInventoryEngine from './pages/main/inventory/duplicate_entries_engine';
import SearchDr from './pages/main/inventory/search_dr';

import AccLanding from './pages/main/accounting/landing_page'
import OldRecordsLanding from './pages/main/old_records/landing_page'
import AccAddCustomer from './pages/main/accounting/acc_add_customer'
import AccCustomerCash from './pages/main/accounting/customer_cash'
import FullyPaidCustomers from './pages/main/accounting/fully_paid_customers'
import CustomerRepossessedUnits from './pages/main/accounting/repossessed'

import OverdueCustomer from "./pages/main/accounting/overdue_customer";
import ViewOverdueCustomer from "./pages/main/accounting/view_overdue_details";

import CreateFinancialStatement from "./pages/main/accounting/acc_create_financial_statement";
import FinancialStatement from './pages/main/accounting/financial_statement';

import InventoryReports from './pages/main/report/inventory_reports';
import CreateInventoryReport from './pages/main/report/create_inventory_report';
import ViewInventoryReport from './pages/main/report/view_inventory_report';
import CreateReport from './pages/main/report/create_report';
import CreateReportSold from './pages/main/report/create_report_sold';
import ViewSoldUnits from './pages/main/report/view_sold_units';
import CreateReportNoClearance from './pages/main/report/create_report_no_clearance';
import CreateReportWithWarranty from './pages/main/report/create_report_with_warranty';
import CreateReportTba from './pages/main/report/create_report_tba';
import CreateReportTotalCost from './pages/main/report/create_report_total_cost';
import CreateReportTotalCostSold from './pages/main/report/create_report_total_cost_sold';
import NoClearance from './pages/main/report/no_clearance';
import WithWarranty from './pages/main/report/with_warranty';
import Tba from './pages/main/report/tba';
import TotalCost from './pages/main/report/total_cost';
import TotalCostSold from './pages/main/report/total_cost_sold';
import TotalCashAndInstallments from './pages/main/report/cash_and_installments';
import ForBir from './pages/main/report/for_bir';

import CustomerWithSecondhandUnitsCash from './pages/main/customer/customer_secondhand_unit_cash';
import FullyPaidSHCustomers from './pages/main/accounting/fully_paid_sh_customers'
import CustomerSHRepossessedUnits from './pages/main/accounting/repossessed_sh'


import SalesReports from './pages/main/report/sales_reports';
import CreateSalesReport from './pages/main/report/create_sales_report';
import ViewSalesReport from './pages/main/report/view_sales_report';
import Test from './pages/main/report/view_sales_report-test';

import User from './pages/main/user/user';
import UserInfo from './pages/main/user/user_info';
import AddUser from './pages/main/user/add_user';
import UserPermission from './pages/main/user/user_permission';

import Category from './pages/main/category/category';
import CategoryUsers from './pages/main/category/category_users';
import CreateSchedulePayment from "./pages/main/accounting/create_schedule_payment";
import CustomerPerArea from "./pages/main/accounting/customer_per_area";
import CreateCustomerPerAreaSH from "./pages/main/accounting/create_customer_per_area_secondhand";

import TotatCustomerPaid from "./pages/main/accounting/total_customers_paid";
import TotatCustomerPaidSH from "./pages/main/accounting/total_customers_paid_secondhand";

import NewCustomers from "./pages/main/accounting/new_customers";
import CreateNewCustomers from "./pages/main/accounting/create_new_customers_report";
import ViewNewCustomers from "./pages/main/accounting/view_new_customers_report";
import RPcustomers from "./pages/main/accounting/repo_customers_report";

import CreateRPCustomers from "./pages/main/accounting/create_rp_customers_report";
import FPcustomers from "./pages/main/accounting/fullypaid_customers_report";
import CreateFPCustomers from "./pages/main/accounting/create_fp_customers_report";

import CreateCustomerPerArea from "./pages/main/accounting/create_customer_per_area";
import CreateTotalCustomerPaid from "./pages/main/accounting/create_total_customer_paid_reports";
import CreateTotalCustomerPaidSH from "./pages/main/accounting/create_total_customer_paid_reports_secondhand";

import TotalMA from "./pages/main/accounting/total_ma.js";
import TotalMASH from "./pages/main/accounting/total_ma_secondhand.js";


import CreateTotalMAReport from "./pages/main/accounting/create_total_ma_reports";
import CreateTotalMAReportSH from "./pages/main/accounting/create_total_ma_reports_secondhand";
// import ReportInvetory from './pages/main/report/sales_report_page';


import AccCustomer from './pages/main/accounting/customer';
import Queues from './pages/main/accounting/queues';
import SecondHandCustomer from './pages/main/accounting/customer_secondhand_unit';
import AccountsPayable from './pages/main/accounting/accounts_payable';

import ViewCustomer from './pages/main/accounting/acc_view_customer';

import ViewSchedule from './pages/main/accounting/view_schedule';
import ViewScheduleSH from './pages/main/accounting/view_schedule_sh';
import InventoryIncoming from "./pages/main/inventory/incoming";

import CustomerLedger from './pages/main/accounting/customer_ledger';
import ViewCustomerLedgerSecondhand from './pages/main/accounting/view_ledger_secondhand';
import ViewCustomerLedger from './pages/main/accounting/view_ledger';


import ViewFinancial from './pages/main/accounting/view_financial';
import ResAccAddCustomer from "./pages/main/old_records/acc_add_customer";
import ResAccAddCustomerCash from "./pages/main/old_records/acc_add_customer_cash";
import ResAccAddCustomerFullyPaid from "./pages/main/old_records/acc_add_customer_fullypaid";
import ResAccAddCustomerRepo from "./pages/main/old_records/acc_add_customer_repo";
import TotalPaid from "./pages/main/accounting/total_paid";
import TotalPaidSH from "./pages/main/accounting/total_paid_secondhand";

import SchedulePayments from "./pages/main/accounting/schedule_payments";
import ComputingPenalties from "./pages/main/accounting/computing_penalties";
import ScheduleOfCollectibles from "./pages/main/accounting/schedule_of_collectibles";
import AccountNoPayment from "./pages/main/accounting/account_no_payment";
import AccountNoPaymentSH from "./pages/main/accounting/account_no_payment_secondhand";
import CreateTotalPaidReport from "./pages/main/accounting/create_total_paid_reports";
import CreateTotalPaidReportSH from "./pages/main/accounting/create_total_paid_reports_secondhand";
import CreateScheduleOfCollectibles from "./pages/main/accounting/create_schedule_of_collectibles";
import CreateComputationPenalties from "./pages/main/accounting/create_computation_penalties";
import CreateNoAccountPayment from "./pages/main/accounting/create_account_no_payment";
import CreateNoAccountPaymentSH from "./pages/main/accounting/create_account_no_payment_secondhand";
import CreateTotalCurrentMonthly from "./pages/main/accounting/create_total_current_monthly";
import viewAccountNoPayment from "./pages/main/accounting/view_account_no_payment";
import viewAccountingReports from "./pages/main/accounting/view_accounting_reports";
import viewAccountingReportsSH from "./pages/main/accounting/view_accounting_reports_secondhand";
import viewAccountNoPaymentSH from "./pages/main/accounting/view_account_no_payment_sh";
import viewCustomerPerAreaReports from "./pages/main/accounting/view_customer_per_area_reports";
import viewCustomerPerAreaReportsSH from "./pages/main/accounting/view_customer_per_area_reports_secondhand";
import viewTotalPaidCustomersReportsSH from "./pages/main/accounting/view_total_paid_customers_report_secondhand";


import AddNewCustomer from './pages/main/customer/add_new_customer';
import ViewFPCustomers from "./pages/main/accounting/view_fp_customers_report";
import ViewRPCustomers from "./pages/main/accounting/view_rp_customers_report";
import ViewNewSHCustomers from "./pages/main/accounting/view_new_sh_customers_report";


import  viewTotalMAReports from "./pages/main/accounting/view_total_ma_reports";
import  viewTotalMAReportsSH from "./pages/main/accounting/view_total_ma_reports_secondhand";
import AddCustomerSecondhandUnit from './pages/main/customer/add_customer_secondhand_unit';

import viewTotalPaidCustomersReports from "./pages/main/accounting/view_total_paid_customers_report";
import CustomerInstallment from "./pages/main/old_records/acc_customer";
import OldRecordCustomerCash from "./pages/main/old_records/acc_customer_cash";
import ViewCustomerOldRecords from "./pages/main/old_records/view_customer";
import OldRecordsCustomerFullypaid from "./pages/main/old_records/acc_customer_fullypaid";
import OldRecordsCustomerRepossessed from "./pages/main/old_records/acc_customer_repossessed";

import CustomerInstallmentSh from "./pages/main/old_records/secondhand/acc_customer";
import OldRecordCustomerCashSh from "./pages/main/old_records/secondhand/acc_customer_cash";
import OldRecordsCustomerFullypaidSh from "./pages/main/old_records/secondhand/acc_customer_fullypaid";
import OldRecordsCustomerRepossessedSh from "./pages/main/old_records/secondhand/acc_customer_repossessed";
import AddOldRecordsCustomersSh from "./pages/main/old_records/secondhand/add_customer_old_rec_sh"; // installment old records secondhand
import AddOldRecordsCustomersCashSh from "./pages/main/old_records/secondhand/add_customer_cash_old_rec_sh"; // cash old records secondhand
import AddOldRecordsCustomersFullypaidSh from "./pages/main/old_records/secondhand/add_customer_fullypaid_old_rec_sh"; // fully paid old records secondhand
import AddOldRecordsCustomersRepoSh from "./pages/main/old_records/secondhand/add_customer_repo_old_rec_sh"; // repossessed old records secondhand
// import ViewCustomerOldRecordsSh from "./pages/main/old_records/secondhand/view_customer";


/* pages END */

class App extends React.PureComponent {

    componentWillMount() {
    }

    componentDidMount() {
    }

    render() {
        const AuthenticatedPages = <Router>
            <Route exact path='/dash' component={Dashboard}/>
            <Route exact path='/notifications' component={Notifications}/>
            <Route exact path='/' component={LandingInventory}/>

            <Route exact path='/payments' component={Payments}/>

            <Route path='/customer/' component={Application}/>
            <Route path='/view_customer' component={ViewApplication}/>
            <Route path='/customer/add_customer/:data' component={AddCustomer}/>
            <Route path='/customer_cash' component={CustomerCash}/>
            <Route path='/customer_badrecords' component={CustomerBadRecords}/>
            <Route path='/customer_secondhand' component={CustomerSecondhand}/>

            <Route path='/edit_customer_secondhand_modal' component={EditCustomerSecondhand}/>

            <Route path='/view_customer_secondhand_x' component={ViewCustomerSecondhand}/>
            <Route path='/view_customer_secondhand' component={ViewCustomerSecondhand2}/>

            <Route path='/all_units' component={AllUnits}/>
            <Route path='/inventory_add_secondhand' component={AddInventorySecondhand}/>
            <Route path='/inventory/' component={InventoryList}/>
            <Route path='/inventory_filter' component={InventoryFilter}/>
            <Route path='/inventory_add' component={AddInventory}/>
            <Route path='/inventory_sold' component={InventorySold}/>
            <Route path='/inventory_transfer' component={InventoryTransfer}/>
            <Route path='/inventory_outgoing' component={InventoryOutgoing}/>
            <Route path='/inventory_incoming' component={InventoryIncoming}/>
            <Route path='/view_inventory' component={ViewInventory}/>
            <Route path='/view_secondhand' component={ViewInventorySecondhand}/>
            <Route path='/update_inventory' component={UpdateInventory}/>
            <Route path='/update_secondhand' component={UpdateSecondHand}/>
            <Route path='/inventory_secondhand' component={InventoryListSecondhand}/>
            <Route path='/inventory_sold_secondhand' component={InventorySoldSecondhand}/>
            <Route path='/inventory_transfer_secondhand' component={InventoryTransferSecondhand}/>
            <Route path='/inventory_outgoing_secondhand' component={InventoryOutgoingSecondhand}/>
            <Route path='/inventory_duplicate_entries' component={DuplicateInventory}/>
            <Route path='/inventory_duplicate_entries_engine' component={DuplicateInventoryEngine}/>
            <Route path='/inventory_search_dr' component={SearchDr}/>

            <Route exact path='/' component={LandingInventory}/>
            <Route exact path='/accounting' component={AccLanding}/>
            <Route exact path='/old_records' component={OldRecordsLanding}/>
            {/*<Route path='/add_new_customer' component={AccAddCustomer}/>*/}
            <Route exact path='/all_customers' component={AllCustomers} />
            <Route exact path='/queues' component={Queues} />
            <Route exact path='/acc_add_customer' component={AccAddCustomer} />
            <Route exact path='/acc_customer_cash' component={AccCustomerCash}/>
            <Route path="/acc_customer_per_area" component={CustomerPerArea}/>
            <Route path="/acc_create_customer_per_area" component={CreateCustomerPerArea}/>
            <Route path="/acc_create_customer_per_area_secondhand" component={CreateCustomerPerAreaSH}/>

            <Route exact path='/fully_paid_customers' component={FullyPaidCustomers}/>
            <Route exact path='/customers_repossessed_units' component={CustomerRepossessedUnits}/>
            <Route path='/customer_secondhand_unit_cash' component={CustomerWithSecondhandUnitsCash}/>
            <Route exact path='/fully_paid_sh_customers' component={FullyPaidSHCustomers}/>
            <Route exact path='/customers_sh_repossessed_units' component={CustomerSHRepossessedUnits}/>

            <Route exact path='/old_record_add_customer' component={ResAccAddCustomer}/>
            <Route exact path='/old_record_add_customer_cash' component={ResAccAddCustomerCash}/>
            <Route exact path='/old_record_add_customer_fullypaid' component={ResAccAddCustomerFullyPaid}/>
            <Route exact path='/old_record_add_customer_repo' component={ResAccAddCustomerRepo}/>


            <Route exact path='/old_record_customer' component={CustomerInstallment}/>
            <Route exact path='/old_record_customer_cash' component={OldRecordCustomerCash}/>
            <Route exact path='/old_record_customer_fullypaid' component={OldRecordsCustomerFullypaid}/>
            <Route exact path='/old_record_customer_repossessed' component={OldRecordsCustomerRepossessed}/>
            <Route exact path='/view_customer_old_records' component={ViewCustomerOldRecords}/>


            <Route exact path='/old_record_customer_sh' component={CustomerInstallmentSh}/>
            <Route exact path='/old_record_customer_cash_sh' component={OldRecordCustomerCashSh}/>
            <Route exact path='/old_record_customer_fullypaid_sh' component={OldRecordsCustomerFullypaidSh}/>
            <Route exact path='/old_record_customer_repossessed_sh' component={OldRecordsCustomerRepossessedSh}/>

            <Route exact path='/add_customer_old_records_sh' component={AddOldRecordsCustomersSh}/>
            <Route exact path='/add_customer_cash_old_records_sh' component={AddOldRecordsCustomersCashSh}/>
            <Route exact path='/add_customer_fullypaid_old_records_sh' component={AddOldRecordsCustomersFullypaidSh}/>
            <Route exact path='/add_customer_repo_old_records_sh' component={AddOldRecordsCustomersRepoSh}/>

            {/*<Route exact path='/rep_acc_add_customer_cash' component={ResAccAddCustomerCash}/>
            <Route exact path='/rep_acc_add_customer_fullypaid' component={ResAccAddCustomerFullyPaid}/>
            <Route exact path='/rep_acc_add_customer_repo' component={ResAccAddCustomerRepo}/>*/}

            <Route path='/report' component={InventoryReports}/>
            <Route path='/report_create_inventory_report' component={CreateInventoryReport}/>
            <Route path='/report_sales_report' component={SalesReports}/>
            <Route path='/report_create_sales_report' component={CreateSalesReport}/>
            <Route path='/report_view_inventory_report' component={ViewInventoryReport}/>
            <Route path='/report_view_sales_report' component={ViewSalesReport}/>
            <Route path='/report_test' component={Test}/>
            <Route path='/report_create_report' component={CreateReport}/>
            <Route path='/report_create_report_sold' component={CreateReportSold}/>
            <Route path='/view_sold_units' component={ViewSoldUnits}/>
            <Route path='/report_no_clearance' component={NoClearance}/>
            <Route path='/report_with_warranty' component={WithWarranty}/>
            <Route path='/report_create_report_no_clearance' component={CreateReportNoClearance}/>
            <Route path='/report_create_report_with_warranty' component={CreateReportWithWarranty}/>
            <Route path='/report_create_report_total_cost' component={CreateReportTotalCost}/>
            <Route path='/report_create_report_total_cost_sold' component={CreateReportTotalCostSold}/>
            <Route path='/report_create_report_tba' component={CreateReportTba}/>
            <Route path='/report_tba' component={Tba}/>
            <Route path='/report_total_cost' component={TotalCost}/>
            <Route path='/report_total_cost_sold' component={TotalCostSold}/>
            <Route path='/report_cash_and_installments' component={TotalCashAndInstallments}/>
            <Route path='/bir_report' component={ForBir}/>

            <Route path='/user' component={User}/>
            <Route path='/user/:name' component={UserInfo}/>
            <Route path='/user_new_user' component={AddUser}/>
            <Route path='/user_permissions' component={UserPermission}/>

            <Route path='/settings' component={Category}/>
            <Route path='/settings_users' component={CategoryUsers}/>

            <Route path="/overdue_customer" component={OverdueCustomer}/>
            <Route path="/view_overdue_details" component={ViewOverdueCustomer}/>

            <Route path="/acc_financial_statement" component={FinancialStatement}/>
            <Route path="/acc_create_financial_statement" component={CreateFinancialStatement}/>

            <Route exact path='/acc_customer' component={AccCustomer}/>
            <Route exact path='/customer_secondhand_unit' component={SecondHandCustomer}/>
            <Route exact path='/accounts_payable' component={AccountsPayable}/>
            <Route path="/ledger" component={CustomerLedger}/>
            <Route path="/view_ledger" component={ViewCustomerLedger}/>
            <Route path="/view_ledger_secondhand" component={ViewCustomerLedgerSecondhand}/>
            <Route path="/acc_view_customer" component={ViewCustomer}/>
            <Route path="/view_schedule" component={ViewSchedule}/>
            <Route path="/view_schedule_sh" component={ViewScheduleSH}/>

            <Route path="/view_financial" component={ViewFinancial}/>


            <Route path="/acc_total_paid" component={TotalPaid}/>
            <Route path="/acc_total_paid_secondhand" component={TotalPaidSH}/>

            <Route path="/acc_schedule_payments" component={SchedulePayments}/>
            <Route path="/acc_computing_penalties" component={ComputingPenalties}/>
            <Route path="/acc_schedule_of_collectibles" component={ScheduleOfCollectibles}/>
            <Route path="/acc_account_no_payment" component={AccountNoPayment}/>
            <Route path="/acc_account_no_payment_secondhand" component={AccountNoPaymentSH}/>
            <Route path="/acc_create_total_paid_report" component={CreateTotalPaidReport}/>
            <Route path="/acc_create_total_paid_report_secondhand" component={CreateTotalPaidReportSH}/>
            <Route path="/acc_create_schedule_of_collectibles" component={CreateScheduleOfCollectibles}/>
            <Route path="/acc_create_account_no_payment" component={CreateNoAccountPayment}/>
            <Route path="/acc_view_account_no_payment" component={viewAccountNoPayment}/>
            <Route path="/acc_create_account_no_payment_secondhand" component={CreateNoAccountPaymentSH}/>
            <Route path="/acc_create_total_current_monthly" component={CreateTotalCurrentMonthly}/>
            <Route path="/acc_create_computation_penalties" component={CreateComputationPenalties}/>
            <Route path="/acc_create_schedule_payment" component={CreateSchedulePayment}/>


            <Route path="/acc_total_customers_paid" component={TotatCustomerPaid}/>
            <Route path="/acc_total_customers_paid_secondhand" component={TotatCustomerPaidSH}/>


            <Route path="/new_customers" component={NewCustomers}/>
            <Route path="/create_new_customers_report" component={CreateNewCustomers}/>
            <Route path="/view_new_customers_report" component={ViewNewCustomers}/>
            <Route path="/repo_customers_report" component={RPcustomers}/>
            <Route path="/create_rp_customers_report" component={CreateRPCustomers}/>
            <Route path="/fullypaid_customers_report" component={FPcustomers}/>
            <Route path="/create_fp_customers_report" component={CreateFPCustomers}/>


            <Route path="/acc_create_total_customer_paid_reports" component={CreateTotalCustomerPaid}/>
            <Route path="/acc_create_total_customer_paid_reports_secondhand" component={CreateTotalCustomerPaidSH}/>

            <Route path="/acc_total_ma" component={TotalMA}/>
            <Route path="/acc_total_ma_secondhand" component={TotalMASH}/>
            <Route path="/acc_create_total_ma_reports" component={CreateTotalMAReport}/>
            <Route path="/acc_create_total_ma_reports_secondhand" component={CreateTotalMAReportSH}/>
            <Route path="/acc_view_accounting_reports" component={viewAccountingReports}/>
            <Route path="/acc_view_accounting_reports_secondhand" component={viewAccountingReportsSH}/>
            <Route path="/acc_view_account_no_payment_sh" component={viewAccountNoPaymentSH}/>
            <Route path="/acc_view_customer_per_area_reports" component={viewCustomerPerAreaReports}/>
            <Route path="/acc_view_customer_per_area_reports_secondhand" component={viewCustomerPerAreaReportsSH}/>

            <Route path="/acc_view_total_paid_customers_report_secondhand" component={viewTotalPaidCustomersReportsSH}/>

            <Route path="/acc_view_total_paid_customers_report" component={viewTotalPaidCustomersReports}/>
            <Route path="/view_fp_customers_report" component={ViewFPCustomers}/>
            <Route path="/view_rp_customers_report" component={ViewRPCustomers}/>
            <Route path="/view_new_sh_customers_report" component={ViewNewSHCustomers}/>

            <Route path="/acc_view_total_ma_reports" component={viewTotalMAReports}/>
            <Route path="/acc_view_total_ma_reports_secondhand" component={viewTotalMAReportsSH}/>

            <Route path='/add_new_customer' component={AddNewCustomer}/>
            <Route path='/add_new_customer_secondhand_unit' component={AddCustomerSecondhandUnit}/>


        </Router>
        const UnAuthenticatedPages = <Router>
            <Route exact path='/' component={Login}/>
            <Route exact path='/reset_password' component={ResetPassword}/>
        </Router>

        return (
            <div>{this.props.loginStatus ? AuthenticatedPages : UnAuthenticatedPages}</div>
        );
    }
}

const mapStateToProps = state => ({
    loginStatus: state.login.loginStatus,
});

function mapDispatchToProps(dispatch) {
    return {actions: bindActionCreators(Object.assign({}, LoginActions), dispatch)}
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps, null, {pure: false})(App));
