import React, {Component} from 'react';
//redux
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
// actions
import {ActionCreators} from '../../actions';

import {Link} from 'react-router-dom';

//custom styles
import '../../stylesheet/styles.css';
import eversureLogo from '../../assets/logo/eversure_logo.png';

import app from '../../helpers/feathers'

import toastr from 'toastr';
import {MY_APP} from "../../helpers";

toastr.options = {
    "positionClass": "toast-bottom-right"
}

const $ = require('jquery');

window.Popper = require('popper.js');
require('bootstrap')

class dashboard extends React.PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            username: '',
            password: '',
            loginAllowed: true
        };
    }

    componentWillMount() {
    }

    componentDidMount() {

    }

    abc = () => {
        console.log(Math.random());
    }

    login = () => {
        var username = this.state.username,
            password = this.state.password;

        // console.log(username+' '+password); return;

        if (username.trim() === '' && password.trim() === '') {
            this.refs.username.value = '';
            this.refs.password.value = '';
            toastr.remove();
            toastr.info('Please enter your username and password', 'Log In');
            return;
        }

        if (username.trim() === '') {
            toastr.remove();
            toastr.info('Please enter your username', 'Log In');
            return;
        }

        if (password.trim() === '') {
            toastr.remove();
            toastr.info('Please enter your password', 'Log In');
            return;
        }

        // console.log(username+' '+password); return;

        // this.props.actions.loginUser();
        // Set up Feathers client side
        toastr.info('Checking user credentials', 'Log In')

        if (!this.state.loginAllowed) {
            return;
        }

        this.setState({
            loginAllowed: false
        })

        var that = this;
        app.authenticate({
            strategy: 'local',
            'username': username,
            'password': password
        }).then(function (result) {

            that.refs.username.value = '';
            that.refs.password.value = '';
            toastr.remove();

            // that.props.actions.setUserData(result.user);

            that.props.actions.getUserPermissions(result.user.type);

            app.service("users").find({
                query: {
                    _id: result.user._id
                }
            })
            .then((d) => {
                var user = d.data[0];

                that.props.actions.setUserData(user);
                that.props.actions.loginUser(true);
            })
            .catch((e) => {
                // console.log('e')
                // console.log(e)
            })

            // that.props.actions.getUserId(username)
            // .then((data) => {
            // 	var id = data.data[0]._id,
            // 		position_type = data.data[0].type;

            // 	localStorage.setItem('_id_secret',id);
            // 	localStorage.setItem('_position_secret',position_type);
            // 	that.props.actions.getUserData(id)
            // 	.then((data) => {
            // 		if(data){
            // 			that.props.actions.setUserData(data);
            // 		}
            // 	})

            // 	that.props.actions.getUserPermissions(position_type)
            // .then((data) => {
            // 	if (data){
            // 		var permissions = data[0].permissions;
            // 		that.props.actions.setUserPermissions(permissions);
            // 	}else{
            // 		var defaultPermissions = {
            //              dashboard: 0,
            //              inventory: 0,
            //              user: 0,
            //              customer: 0,
            //              report: 0,
            //              category: 0,
            //              accounting: 0
            //          };
            // 		that.props.actions.setUserPermissions(defaultPermissions);
            // 	}
            // })
            // })

        }).catch(function (error) {
            // console.log('Not Authenticated!', error.message);
            that.props.actions.loginUser(false);
            that.setState({password: '', loginAllowed: true});
            that.refs.password.value = '';
            toastr.remove();
            toastr.error('Login failed. Please try again.', 'Log In', {timeOut: 3000})
        });
    }

    del = () => {
        if (window.confirm('Are you sure?') === true) {
            this.props.actions.removeUsers();
        }
    }

    inputSubmitted = (e) => {
        if (e.key === 'Enter') {
            this.login();
        }
    }

    render() {
        return (
            <div className="login-content">
                <div className="container">
                    <div className="login__wrapper">
                        <div className="login__logo"><img src={eversureLogo} alt="logo"/></div>
                        <div className="login__content">
                            <h1>Welcome!</h1>
                            <span>Login to your account</span>
                            <div className="login__content-form">
                                <div className="form-group">
                                    <input
                                        className="form-control"
                                        onChange={(e) => this.setState({username: e.target.value})}
                                        onKeyPress={(e) => this.inputSubmitted(e)}
                                        placeholder="Username"
                                        type="text"
                                        ref="username"
                                    />
                                </div>
                                <div className="form-group">
                                    <input
                                        className="form-control"
                                        onChange={(e) => this.setState({password: e.target.value})}
                                        onKeyPress={(e) => this.inputSubmitted(e)}
                                        placeholder="Password"
                                        ref="password"
                                        type="password"
                                    />
                                </div>
                                <div className="form-group">
                                    <button className="btn btn-block btn-second-primary" onClick={this.login}>Login</button>
                                </div>
                                <div className="form-group forgot-password">
                                    <Link to="/reset_password">Forgot Password?</Link>
                                </div>
                            </div>
                        </div>
                        <div className="login__footer">
                            v{MY_APP.version}
                        </div>
                    </div>
                    {/*<div className="login-main">*/}
                    {/*  <div className="row">*/}
                    {/*    <div className="col-lg-12">*/}
                    {/*    	<div className="form-group">*/}
                    {/*    		<center>*/}
                    {/*    			<img src={eversureLogo} alt="logo"/>*/}
                    {/*    			<h4>Login</h4>*/}
                    {/*    			<br />*/}
                    {/*    		</center>*/}
                    {/*    	</div>*/}
                    {/*    	<div className="form-group">*/}
                    {/*    		<input*/}
                    {/*    			className="form-control"*/}
                    {/*    			onChange={(e) => this.setState({username: e.target.value})}*/}
                    {/*    			onKeyPress={(e) => this.inputSubmitted(e) }*/}
                    {/*    			placeholder="Username"*/}
                    {/*    			type="text"*/}
                    {/*    			ref="username"*/}
                    {/*    		/>*/}
                    {/*    	</div>*/}
                    {/*    	<div className="form-group">*/}
                    {/*    		<input*/}
                    {/*    			className="form-control"*/}
                    {/*    			onChange={(e) => this.setState({password: e.target.value})}*/}
                    {/*    			onKeyPress={(e) => this.inputSubmitted(e) }*/}
                    {/*    			placeholder="Password"*/}
                    {/*    			ref="password"*/}
                    {/*    			type="password"*/}
                    {/*    		/>*/}
                    {/*    	</div>*/}
                    {/*    	<div className="form-group">*/}
                    {/*    		<button className="btn btn-block btn-primary" onClick={this.login}>Login</button>*/}
                    {/*    	</div>*/}
                    {/*    </div>*/}
                    {/*  </div>*/}
                    {/*</div>*/}
                </div>
            </div>
        );
    }
}


const mapStateToProps = state => ({
    login: state,
});

function mapDispatchToProps(dispatch) {
    return {actions: bindActionCreators(ActionCreators, dispatch)}
}

export default connect(mapStateToProps, mapDispatchToProps)(dashboard);
