import React, {Component} from 'react';
import toastr from 'toastr';

//redux
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
// actions
import {ActionCreators} from '../../../actions';

import moment from 'moment';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import '../report/plugins/daterangepicker.css';
import app from '../../../helpers/feathers';
import DatePicker from "react-datepicker";

const $ = require('jquery');

const _0x896d = ["\x74\x65\x73\x74\x32"], secret_user = _0x896d[0], secret_pass = _0x896d[0];

class accept_incoming_modal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            dateToday: moment(),
            processing: false
        };
    }

    componentWillMount() {
    }

    accept = () => {
        const id = this.props.acceptDataId,
        branch = this.props.userData.branch_info._id,
        transferred_from_branch = this.props.acceptDataId.branch;
        let { processing } = this.state;

        if(processing){
            toastr.remove();
            toastr.info('Please wait while saving data');
            return;
        }

        this.setState({processing: true})

        app.service('products').patch(id, {
            transferred: 2,
            dateReceived: this.state.dateToday,
            branch: branch,
            transferred_from_branch: transferred_from_branch
        }).then((data) => {
                
            this.setState({processing: false})
            toastr.remove();
            toastr.success('Unit successfully added');
            this.refs.cancel.click();

            // const localOId = localStorage.getItem('local_branch_name')+(Date.now());
            // const saveToLocal = {
            //     // _id: data._id,
            //     branch: branch,
            //     brand: data.brand,
            //     model: data.model,
            //     engine_number: data.engine_number,
            //     chassis_number: data.chassis_number,
            //     chas: data.chas,
            //     color: data.color,
            //     delivery: {
            //         date: data.delivery.date,
            //         location: data.delivery.location,
            //         receipt_number: data.delivery.receipt_number,
            //         receipt_date: data.delivery.receipt_date,
            //     },
            //     invoice: {
            //         invoice_number: data.invoice.invoice_number,
            //         date: data.invoice.date,
            //     },
            //     date_added_custom: moment().format('MM/DD/YYYY'),
            //     customer: data.customer,
            //     date_sold: data.date_sold,
            //     price: data.price,
            //     list_price: data.list_price ? data.list_price : data.price,
            //     type: data.type,
            //     warranty_claims: data.warranty_claims,
            //     clearances: data.clearances,
            //     tba: data.tba,
            //     created_by: data.created_by,
            //     received_brand_name: data.brands.brand_name,
            //     received_model_name: data.motorModels.model_name,
            //     received_from_branch: data.branches.branch_name,
            //     main_product_status: 1,
            //     transferred: 0,
            //     brandNew: data.brandNew,
            //     // origin_id: localOId,
            //     // transferred_from_branch: data.transferred_from_branch,
            //     transferred_from_branch: data.transferred_from_branch,
            //     year_added: data.year_added,
            //     deletedInBranch: data.deletedInBranch,
            //     dateTransferred: data.dateTransferred,
            //     origin_id: data.origin_id
            //     // transferred_from_branch: null
            // };

            // app.service('products').patch(id, {
            //     branch: branch,
            //     transferred_from_branch: data.transferred_from_branch
            // })
            // .then(() => {
                
            //     this.setState({processing: false})
            //     toastr.remove();
            //     toastr.success('Unit successfully added');
            //     this.refs.cancel.click();
            // }).catch((e) => {
            //     this.setState({processing: false})
            //     toastr.remove();
            //     toastr.error('Error saving');
            //     this.refs.cancel.click();
            // })
        }).catch((err) => {
            console.log('err ', err)
            this.setState({processing: false})
            toastr.remove();
            toastr.error('Error saving');
            this.refs.cancel.click();
        });
    }

    closeModel = () => {
        $('.modal').modal('hide');
    }

    acceptUnit = (date) => {
        this.setState({
            dateToday: date
        });
    }

    componentDidMount() {

    }

    render() {
        return (
            <div className="modal fade" id="accept_incoming" tabIndex="-1" role="dialog"
                 aria-labelledby="exampleModalLabel" aria-hidden="true" ref="accept_incoming" data-backdrop="static">
                <div className="modal-dialog modal-sm" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Accept Unit</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="form-group">
                                <span>Date Received</span>
                            </div>
                            <div className="form-group">
                                {/*<DateRangePicker onApply={this.acceptUnit} singleDatePicker>
                                    <button className="form-control">
                                        {
                                            this.state.dateToday
                                        }
                                    </button>
                                </DateRangePicker>*/}

                                <DatePicker
                                    selected={this.state.dateToday}
                                    // onChange={this.handleDeliveryReceiptDate}
                                    onChange={this.acceptUnit}
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    className="form-control"
                                />
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal"
                                    ref="cancel">Cancel
                            </button>
                            <button type="button" className="btn btn-primary" onClick={this.accept}>Save</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


const mapStateToProps = state => ({
    category: state.category,
    app: state.feathersClient.app,
    mainapp: state.feathersClient.mainapp,
    acceptDataId: state.product.acceptDataId,
    userData: state.login.userData,
});

function mapDispatchToProps(dispatch) {
    return {actions: bindActionCreators(ActionCreators, dispatch)}
}

export default connect(mapStateToProps, mapDispatchToProps)(accept_incoming_modal);