import{
	SAMPLE_SALES,
	GRAPH_TYPE,
	GRAPH_PARAM,
	SET_NOTIFICATIONS,
	SET_UNREAD_NOTIFS,
	SET_GRAPH_UNSOLD_UNITS,
	SET_GRAPH_UNSOLD_UNITS_COUNT,
	SET_GRAPH_SOLD_UNITS,
	SET_GRAPH_SOLD_UNITS_COUNT,
}
from '../constants/dashboard';

const initialState = {
	sales: [],
	graphType: '',
	graphParam: '',
	notifications: [],
	unread: 0,
	graph_unsold_units: [],
	graph_unsold_units_count: [],
	graph_sold_units: [],
	graph_sold_units_count: [],
}

export default function category(state = initialState, action) {
	switch (action.type){

		case SAMPLE_SALES:
			return {
				...state,
				sales: action.data
			}
		break

		case GRAPH_TYPE:
			return {
				...state,
				graphType: action.data
			}
		break;

		case GRAPH_PARAM:
			return {
				...state,
				graphParam: action.data
			}
		break;
		case SET_NOTIFICATIONS: return { ...state, notifications: action.data }; break;
		case SET_UNREAD_NOTIFS: return { ...state, unread: action.data }; break;
		case SET_GRAPH_UNSOLD_UNITS: return { ...state, graph_unsold_units: action.data }
		case SET_GRAPH_UNSOLD_UNITS_COUNT: return { ...state, graph_unsold_units_count: action.data }
		case SET_GRAPH_SOLD_UNITS: return { ...state, graph_sold_units: action.data }
		case SET_GRAPH_SOLD_UNITS_COUNT: return { ...state, graph_sold_units_count: action.data }

		default: return state;
	}
}