import React, { Component } from 'react';

//redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// actions
import { ActionCreators } from '../../../actions';

import {
    encodeToString,
    leadingZero,
} from '../../../helpers'

import toastr from 'toastr';

toastr.options.fadeOut = 2500;

const $ = require('jquery');

class confirm_request extends Component {

	constructor(props) {
  	super(props);
  	this.state = {
  		btnDisabled: false
  	};
	}

	resetPassword = () => {
		const that = this;
		let { account_data } = that.props;
		let email = account_data[0].email;
		let id = account_data[0]._id;

		that.setState({btnDisabled: true});

		that.props.actions.sendMail(email, id)
		.then((res) => {
			that.closeModal()
			toastr.remove()
			that.setState({btnDisabled: false});
			if(res.status){
				toastr.success("Mail successfully sent!");
			}else{
				toastr.error("Error sending mail");
			}
		})
		.catch((error) => {
			that.closeModal()
			toastr.remove()
			that.setState({btnDisabled: false});
			toastr.error("Error sending mail");
		})
	}

	closeModal = () => {
		this.refs.cancel.click();
	}

	render() {
  	return (
    		<div className="modal fade bd-example-modal-sm" id="confirm_request" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" ref="confirm_request" data-backdrop="static">
		  	<div className="modal-dialog modal-md" role="document">
		    	<div className="modal-content">
		      		<div className="modal-header">
		        		<h5 className="modal-title" id="exampleModalLabel">Account Details</h5>
		        			<button type="button" className="close" data-dismiss="modal" aria-label="Close">
					          <span aria-hidden="true">&times;</span>
					        </button>
		      		</div>
			      	<div className="modal-body">
				        	<div className="form-group">
			        			{
			        				this.props.account_data.map((v, i) => {
			        					return <div className="row" key={i}>
			        									<div className="col-md-4">Name:</div><div className="col-md-8">{v.fullname}</div>
			        									<div className="col-md-4">Email:</div><div className="col-md-8">{v.email}</div>
			        									<div className="col-md-4">Branch:</div><div className="col-md-8">{v.branch_info.branch_name}</div>
			        								</div>
			        				})
			        			}
				        	</div>
				        	{
				        		/*
						        	<div className="form-group">
						        		<label>Unit Price</label>
						        		<input className="form-control" ref="selling_price" />
						        	</div>
				        		*/
				        	}
			      	</div>
			      	<div className="modal-footer">
				        	<button type="button" className="btn btn-secondary" data-dismiss="modal" ref="cancel">Cancel</button>
				        	<button type="button" className="btn btn-primary" onClick={this.resetPassword} disabled={this.state.btnDisabled} >Reset Password</button>
			      	</div>
		    	</div>
		  	</div>
		</div>
  	);
	}
}


const mapStateToProps = state => ({
   	session_id: state.login.userData._id
});

function mapDispatchToProps(dispatch) {
   return { actions: bindActionCreators(ActionCreators, dispatch) }
}

export default connect(mapStateToProps, mapDispatchToProps)(confirm_request);