import React, {Component} from 'react';

// react-router
import {Link} from 'react-router-dom';

//redux
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
// actions
import {ActionCreators} from '../../../actions';

import defaultImage from '../../../assets/img/default_avatar.jpg';

//toastr
import toastr from 'toastr';

import moment from 'moment';

//select tag
import Select from 'react-select';
import DatePicker from 'react-datepicker';

//main NavBar
import MainNav from '../../../components/global_nav';
import MainSideBar from '../../../components/acc_sidebar';

import ViewSchedule from './view_schedule_modal'
import ConfirmationModal from './confirmation_modal'

//sub side bar
import SubSideBar from './sub_sidebar';

import {
    encodeToString,
    leadingZero,
} from '../../../helpers'

import Autosuggest from 'react-autosuggest';
//custom styles
import '../../../stylesheet/styles.css';

//datatables
import '../../../stylesheet/datatables/datatables.css';
import app from '../../../helpers/feathers';

const $ = require('jquery');
$.DataTable = require('datatables.net');

class ResAccAddCustomerFullyPaid extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedModel: '',
            clickSubmit: false,
            selectedTerms: '',
            selectedTitle: '',
            selectedArea: '',
            selectedAreaChanged: false,
            selectedTermsChanged: false,
            selectedTitleChanged: false,
            submitting: false,
            selectedBranch: '',
            selectBranch: [
                {value: localStorage.getItem('local_branch_id'), label: localStorage.getItem('local_branch_name')}
            ],
            mobile_num: '',
            age: '',
            selectedBrand: '',
            selectedBrandChanged: false,
            selectedOption: '',
            selectChanged: false,
            chassisOption: '',
            chassisChanged: false,
            modelsObj: [],
            chassisObj: [],
            model: null,
            termsIsDisabled: false,
            areaOthers: false,
            date_purchased: moment(),
            suggestions: [],
            value: '',
            showDiv: false,
            confirmed: false,
            account_status: '',
            date_closed: moment(),
        };

    }

    componentWillMount() {
        //AREA PROPS
        this.props.actions.getArea();
    }

    componentDidMount() {
        const that = this;
        $('.hide-view-customer').css('display', 'none', '!important')

        const localBranch = {
            id: this.props.userData.branch_info._id,
            value: this.props.userData.branch_info
        };

        this.setState({selectedBranch: localBranch});

        $('#confirmation_modal').on('hidden.bs.modal', function () {
            that.setState({
                confirmed: false, submitting: false
            });
        });

        $('#confirmation_modal').on('shown.bs.modal', function () {
            $(".modal #submitForm").focus();
        });
    }

    handleChangeModel = (selectedOption) => {
        const value = selectedOption;

        if (value) {
            this.setState({
                selectedModel: value
            });
        } else {
            this.setState({
                selectedModel: ''
            });
        }
    }

    handleChangeBranch = (selectedOption) => {
        const value = selectedOption === null ? '' : selectedOption;
        this.setState({
            selectedBranch: value
        });
    }

    handleChangeAccountStatus = (selectedOption) => {
        const value = selectedOption === null ? '' : selectedOption;
        this.setState({
            account_status: value
        });
    }

    branchOption = () => {
        const array = this.props.branchSelect,
            newArray = []
        array.map((obj) => {
            if (obj.value !== 'all') {
                newArray.push(obj)
            }
        })
        return newArray;
    }


    showDiv = () => {
        this.setState({showDiv: true})
    }

    hideDiv = () => {
        this.setState({showDiv: false})
    }

    handleselectedTerms = (selectedOption) => {
        const value = selectedOption;
        if (value !== null) {
            // 0 is CASH
            if (value.value === 0) {
                this.refs.terms.value = "";
                this.setState({
                    account_status: '',
                    termsIsDisabled: true
                })
            } else {
                this.setState({
                    termsIsDisabled: false
                })
            }
            this.setState({
                selectedTerms: value,
                selectedTermsChanged: true,
            });
        } else {
            this.setState({
                account_status: '',
                selectedTerms: '',
                selectedTermsChanged: false,
                termsIsDisabled: true,
            });
        }
    }

    handleselectedTitle = (selectedOption) => {
        const value = selectedOption;
        if (value !== null) {
            if (value.value) {
                this.setState({selectedTitle: value})
            }
        } else {
            this.setState({selectedTitle: '', selectedTitleChanged: false})
        }
    }


    handleSelectedArea = (selectedOption) => {
        const value = selectedOption;
        if (value !== null) {
            this.refs.area.value = "";
            this.setState({
                selectedArea: value,
                selectedAreaChange: true,
            });
        } else {
            this.setState({
                selectedArea: '',
                selectedAreaChange: false,
                // areaOther : true,
            });
        }

    }

    areaOption = () => {
        return this.props.areaList;
    }

    validateForm = (formData) => {
        const a = formData;

        if (formData.type === 1) {
            return a.name !== '' &&
                a.unit !== '' &&
                // a.branch !== '' &&
                a.mobile !== '' &&
                a.tin !== '' &&
                a.address.present !== '' &&
                a.address.length_of_stay !== '' &&
                a.age !== '' &&
                a.employer.present !== '' &&
                a.months !== '' &&
                a.terms.down_payment !== '' &&
                a.terms.monthly_payment !== '';
        } else {
            return a.name !== '' &&
                a.unit !== '' &&
                a.branch !== '' &&
                a.mobile !== '' &&
                a.tin !== '' &&
                a.address.present !== '' &&
                a.age !== '' &&
                a.payment !== '';
        }
    }

    redirectToModal = () => {
        let {customerInfo} = this.props;
        this.props.actions.setLedgerItemId(customerInfo);
        $('#view_sched_modal').modal('show');
    }

    confirmation = () => {
        $('#confirmation_modal').modal('show')
    }

    submitForm = () => {
        this.setState({confirmed: true})
        setTimeout(() => {
            this.handleAddCustomer();
        }, 500)
    }

    onChange = (event, {newValue}) => {
        this.setState({
            value: newValue
        });
    };

    handleAddCustomer = () => {
        let {chassisOption, selectedOption, selectedBranch, selectedTerms, selectedTitle, selectedModel, termsIsDisabled, date_purchased, account_status,date_closed} = this.state;
        let {name, account_number, address, contact_number, payment, terms, promisory_note, remarks, area, unit_brand, unit_model, unit_engine_number,chassis,color,or_number} = this.refs

        if (this.state.submitting) {
            toastr.info("Please wait while saving data")
            return;
        }

        const form = this.refs.customerForm,
            formData = {
                name: ((name.value).toUpperCase()).trim(),
                branchId: selectedBranch.id,
                title: selectedTitle.value,
                branch: selectedBranch.value,
                term: (terms.value).trim(),
                payment: (payment.value).trim(),
                type: 1,
                account_number: termsIsDisabled ? '' : (account_number.value).trim(),
                customer_address: (address.value).trim(),
                contact_number: (contact_number.value).trim(),
                promisory_note: promisory_note.value,
                date_purchased: moment(date_purchased).format("MM/DD/YYYY"),
                date_purchased_formatted: moment(date_purchased).format("MM/YYYY"),
                date_closed: moment(date_closed).format("MM/DD/YYYY"),
                remarks: remarks.value,
                area: this.state.selectedAreaChange ? this.state.selectedArea.label : area.value,
                old_unit: 1,
                unit_brand: unit_brand.value,
                unit_model: unit_model.value,
                unit_engine_number: unit_engine_number.value,
                unit_chassis: chassis.value,
                unit_color: color.value,
                or_number: or_number.value,
                account_status: 1,
            };
        const i_chassis_number = selectedModel.chassis_number;


        if (!(unit_brand.value).trim()) {
            toastr.remove();
            toastr.info('Please enter brand name');
            return;
        }

        if (!(unit_model.value).trim()) {
            toastr.remove();
            toastr.info('Please enter model name');
            return;
        }

        if ((unit_engine_number.value).trim() === '') {
            unit_engine_number.value = '';
            toastr.remove();
            toastr.info('Please enter engine number');
            return;
        }

        if ((chassis.value).trim() === '') {
            chassis.value = '';
            toastr.remove();
            toastr.info('Please enter chassis number');
            return;
        }

        if ((color.value).trim() === '') {
            color.value = '';
            toastr.remove();
            toastr.info('Please enter unit color');
            return;
        }
        if (!(this.refs.terms.value).trim()) {
            toastr.remove();
            toastr.info('Please enter terms');
            return;
        }else{
            if(isNaN(this.refs.terms.value)){
                toastr.remove();
                toastr.info('Please enter a numeric value for terms');
                return;
            }
        }

        if (!(this.refs.account_number.value).trim()) {
            toastr.remove();
            toastr.info('Please enter account number');
            return;
        }

        if (selectedTitle === '') {
            toastr.remove();
            toastr.info('Please select customer title');
            return;
        }

        if (!(this.refs.name.value).trim()) {
            toastr.remove();
            toastr.info('Please enter customer name');
            return;
        }

        if (!(this.refs.address.value).trim()) {
            toastr.remove();
            toastr.info('Please enter customer address');
            return;
        }
        if (!(this.refs.payment.value).trim()) {
            toastr.remove();
            toastr.info('Please enter amount for payment');
            return;
        }

        if (!(this.refs.promisory_note.value).trim()) {
            toastr.remove();
            toastr.info('Please enter promissory note');
            return;
        }

        if (isNaN((this.refs.payment.value).trim())) {
            toastr.remove();
            toastr.info('Please enter a valid amount for payment');
            return;
        } else {
            if ((this.refs.payment.value).trim() === 0) {
                toastr.remove();
                toastr.info('Please enter a valid amount for payment');
                return;
            } else {
                if (leadingZero((this.refs.payment.value).trim())) {
                    toastr.remove();
                    toastr.info('Please enter a valid amount for payment');
                    return;
                }
            }
        }

        if((or_number.value).trim() === ''){
            toastr.remove();
            toastr.info("Please enter OR Number");
            return;
        }

        if (!this.state.confirmed) {
            this.confirmation();
            return
        }

        this.setState({submitting: true});

        this.props.actions.addCustomer(formData).then((data) => {
            if (data === 'BadRequest') {
                toastr.error('Please fill in required fields.');
                this.setState({submitting: false})
            }
            if (data.status === 'error') {
                toastr.error('An error occured');
                this.setState({submitting: false});
            }
            if (data.status === 'exists') {
                toastr.error('Account Number already exists');
                this.setState({submitting: false});
                this.clearUnitOption()
            } else if (data.status === 'success') {
                toastr.remove();
                toastr.success('Customer has been successfully added.');

                const custId = data.customer_id,
                    prodService = app.service('products'),
                    dateSold = moment().format('MM/DD/YYYY');

                let i = 0;

                this.props.actions.checkIfExist(data.account_number)
                    .then((result) => {
                        if (result.status) {
                            toastr.error(result.message)
                        } else {
                            this.props.actions.setNewCustomerData(data.data)
                            $('#add_ledger .btn-primary').prop('disabled', true);
                        }
                    }).catch((error) => {
                    // console.log('error ', error)
                    toastr.error(error.message)
                })

                // const res = data.data;
                // app.service('products').find({
                //     /*query: {
                //         origin_id: res.product.origin_id
                //     }*/
                // }).then((mainProduct) => {
                //     const unit_id = mainProduct.data[0]._id,
                //         mainformData = {
                //             // _id: custId,
                //             name: (res.name).toUpperCase(),
                //             unit: unit_id,
                //             // unitPrice: res.unitPrice,
                //             // unitName: res.unitName,
                //             branchId: res.branchId,
                //             // branchId: this.props.addCustBranch ? this.props.addCustBranch[0].value : '',
                //             branch: res.branchId,
                //             // branch: this.props.addCustBranch ? this.props.addCustBranch[0].value : '',
                //             term: res.term,
                //             payment: res.payment,
                //             type: res.type,
                //             account_number: res.account_number,
                //             origin_id: res.origin_id,
                //             title: res.title,
                //             expiry_status: res.expiry_status,
                //             area: res.area,
                //             recompute_status: res.recompute_status,
                //             promisory_note: res.promisory_note,
                //             date_purchased: res.date_purchased,
                //             remarks: res.remarks,
                //             has_ledger: res.has_ledger,
                //             old_unit: 1,
                //             unit_brand: unit_brand,
                //             unit_model: unit_model,
                //             unit_engine_number: unit_engine_number,
                //         };

                //     this.props.actions.mainaddCustomer(mainformData)
                //         .then((maindata) => {
                //             if (maindata) {
                //                 app.service('customers').patch(
                //                     custId,
                //                     {
                //                         main_customer_id: maindata.result._id
                //                     }
                //                 ).then((data) => {
                //                     console.log('patch data ', data)
                //                 }).catch((error) => {
                //                     console.log('patch ', error)
                //                 });
                //             } else {
                //                 app.service('customers')
                //                     .patch(custId, {main_customer_status: 2})
                //                     .then((data) => {
                //                         console.log('local patched upon main add error')
                //                     });
                //             }
                //         }).catch(() => {
                //         app.service('customers')
                //             .patch(custId, {main_customer_status: 2})
                //             .then((data) => {
                //                 console.log('local patched upon main catch error')
                //             });
                //     });

                //     setTimeout(() => {
                //         $('.hide-view-customer').css('display', 'block', '!important')
                //         $("#confirmation_modal").modal("hide")
                //         this.clearInput();
                //         this.setState({submitting: false})
                //     }, 2000)
                // });

                setTimeout(() => {
                    $('.hide-view-customer').css('display', 'block', '!important')
                    $("#confirmation_modal").modal("hide")
                    this.clearInput();
                    this.setState({submitting: false})
                }, 2000)
                
            } else {
                toastr.error('Network error. Please try again.');
                this.setState({submitting: false})
            }
        });
    }

    clearUnitOption = () => {
        this.setState({
            selectedBrand: '',
            selectedBrandChanged: false,
            modelsObj: [],
            selectedOption: '',
            chassisOption: '',
            chassisChanged: false,
            chassisObj: [],
        })
    }

    handleDatePurchased = (date) => {
        this.setState({date_purchased: date})
    }

    handleDateClosed = (date) => {
        this.setState({date_closed: date})
    }

    back = () => {
        this.props.history.push('/acc_customer/');
    }

    clearInput = () => {
        let {
            name,
            account_number,
            contact_number,
            payment,
            terms,
            promisory_note,
            remarks,
            area,
            unit_brand,
            unit_model,
            unit_engine_number,
            color,
            chassis,
            or_number,
        } = this.refs;
        let {selectedArea} = this.state;

        name.value = "";
        account_number.value = "";
        contact_number.value = "";
        payment.value = "";
        terms.value = "";
        promisory_note.value = "";
        remarks.value = "";
        area.value = "";
        unit_brand.value = "";
        unit_model.value = "";
        unit_engine_number.value = "";
        color.value = "";
        chassis.value = "";
        or_number.value = "";

        this.setState({selectedArea: '', selectedAreaChange: false, selectedTitle: '', date_purchased: moment()});
        // this.props.history.goBack();
    }

    render() {
        let name = this.refs.name ? this.refs.name.value : '';
        let showDiv = this.state.showDiv;
        const {value, suggestions, termsIsDisabled} = this.state;
        const inputProps = {
            placeholder: 'Search or Enter Area',
            value,
            onChange: this.onChange
        };
        const getSuggestionValue = suggestion => suggestion.name;

        // Use your imagination to render suggestions.
        const renderSuggestion = suggestion => (
            <div>
                {suggestion.name}
            </div>
        );
        return (
            <div className="">

                <MainNav historyProp={this.props.history} usersProp={this.props.userData} systemType="accounting"/>
                <ViewSchedule name={name}/>
                <ConfirmationModal name={name} showDiv={this.showDiv} hideDiv={this.hideDiv}
                                   submitForm={this.submitForm}/>
                <div className="container-fluid">
                    <div className="row full-width">
                        <div className="sidebar">
                            <div className="sidebar-content">
                                <MainSideBar page="old_records"/>
                            </div>
                        </div>
                        <SubSideBar history={this.props.history} path="/acc_add_customer" page="fully_paid"/>
                        <div className="sub-main-content sub-inv-main-content">
                            <div className="container-fluid">
                                <div className="application-container">
                                    <a href="" className="no-textdecoration breadcrumb-inv">
                                        <span className="fa fa-chevron-left"/> Customers / Fully Paid
                                    </a>
                                    <h4 className="subheader-title">Fully Paid</h4>
                                    <form onSubmit={this.handleAddCustomer.bind(this)} ref="customerForm"
                                          id="needs-validation" noValidate>
                                        <div className="container-fluid">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="row">
                                                        <div className="col">
                                                            <span>Brand</span>
                                                            <input className="form-control"
                                                                   placeholder="Enter Brand" ref="unit_brand"/>
                                                        </div>
                                                        <div className="col">
                                                            <span>Model</span>
                                                            <input className="form-control"
                                                                   placeholder="Enter Model" ref="unit_model"/>
                                                        </div>
                                                        <div className="col">
                                                            <span>Engine Number</span>
                                                            <input className="form-control"
                                                                   placeholder="Enter Engine Number"
                                                                   ref="unit_engine_number"/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <br/>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="row">
                                                        <div className="col">
                                                            <span>Chassis Number </span>
                                                            <input type="text" className="form-control" ref="chassis"/>
                                                        </div>
                                                        <div className="col">
                                                            <span>Color </span>
                                                            <input type="text" className="form-control" ref="color"/>
                                                        </div>
                                                        <div className="col">
                                                            <span>Terms</span>
                                                            <input type="text" className="form-control" ref="terms"
                                                                   disabled={this.state.termsIsDisabled}/>
                                                        </div>
                                                        <div className="col">
                                                            <span>Account Number</span>
                                                            <input type="text" className="form-control"
                                                                   ref="account_number"
                                                                   disabled={this.state.termsIsDisabled}/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <br/>
                                            <div className="row">
                                                <div className="col">
                                                    <span>Contact Number </span>
                                                    <input type="text" className="form-control"
                                                           ref="contact_number" required/>
                                                </div>
                                                <div className="col">
                                                    <span>Title </span>
                                                    <Select
                                                        name="select-title"
                                                        value={this.state.selectedTitle.value}
                                                        onChange={this.handleselectedTitle}
                                                        options={[
                                                            {value: 'MS', label: 'MS'},
                                                            {value: 'MRS', label: 'MRS'},
                                                            {value: 'MR', label: 'MR'},
                                                            {value: 'N/A', label: 'N/A'},

                                                        ]}
                                                        clearableValue={true}
                                                        placeholder="MS/MRS/MR"
                                                    />
                                                </div>
                                                <div className="col-md-7">
                                                    <span>Customer Name </span>
                                                    <input type="text" className="form-control" ref="name"
                                                           required/>
                                                    <span><i>(Last name, First name, MI)</i></span>
                                                </div>
                                            </div>
                                            <br/>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="row">
                                                        <div className="col">
                                                            <span>Customer Address </span>
                                                            <input type="text" className="form-control"
                                                                   ref="address" required/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <br/>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="row">
                                                        <div className="col">
                                                            <span>Area</span>
                                                            <Select
                                                                name="select-area"
                                                                value={this.state.selectedArea.value}
                                                                onChange={this.handleSelectedArea}
                                                                options={this.areaOption()}
                                                                clearableValue={true}
                                                                placeholder="Select Area"
                                                            />
                                                        </div>
                                                        <div className="col">
                                                            <span>If other area (please specify)</span>
                                                            <input type="text" className="form-control" ref="area"
                                                                   disabled={this.state.selectedAreaChange}/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <br/>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="row">
                                                        <div className="col">
                                                            <span>{this.state.termsIsDisabled ? "" : "Down"} Payment</span>
                                                            <input type="text" className="form-control"
                                                                   ref="payment"/>
                                                        </div>
                                                        <div className="col">
                                                            <span>Promissory Note</span>
                                                            <input type="text" className="form-control"
                                                                   ref="promisory_note"
                                                                   disabled={this.state.termsIsDisabled}/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <br/>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="row">
                                                        <div className="col">
                                                            <span>Date Purchased</span>
                                                            <br/>
                                                            <DatePicker
                                                                selected={this.state.date_purchased}
                                                                onChange={this.handleDatePurchased}
                                                                peekNextMonth
                                                                showMonthDropdown
                                                                showYearDropdown
                                                                dropdownMode="select"
                                                                className="form-control"
                                                            />
                                                        </div>
                                                        <div className="col">
                                                            <span>Date Closed</span>
                                                            <br/>
                                                            <DatePicker
                                                                selected={this.state.date_closed}
                                                                onChange={this.handleDateClosed}
                                                                peekNextMonth
                                                                showMonthDropdown
                                                                showYearDropdown
                                                                dropdownMode="select"
                                                                className="form-control"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <br/>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="row">
                                                        <div className="col">
                                                            <span>OR Number</span>
                                                            <input type="text" className="form-control"
                                                                   ref="or_number"/>
                                                        </div>
                                                        <div className="col">
                                                            <span>Remarks</span>
                                                            <input type="text" className="form-control"
                                                                   ref="remarks"/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <br/>
                                            <div className="row">
                                                <div className="col-md-8">
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="row">
                                                        <div className="col">
                                                            <button className="btn btn-block btn-danger" id="send"
                                                                    type="button" onClick={this.clearInput}>
                                                                <span className="fa fa-remove"/> CANCEL
                                                            </button>
                                                        </div>
                                                        <div className="col">
                                                            <button className="btn btn-block btn-primary"
                                                                    type="button" onClick={this.handleAddCustomer}>
                                                                <span className="fa fa-save"/> SAVE
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    app: state.feathersClient.app,
    mainapp: state.feathersClient.mainapp,
    customerInfo: state.customers.customerInfo,
    customer: state.customers,
    productSelect: state.product.productSelect,
    branchSelect: state.category.branchesSelect,
    models: state.category.modelsSelect,
    add_cust_model: state.category.add_cust_model,
    brandsSelect: state.category.brandsSelect,
    type: state.customers.type,
    userData: state.login.userData,
    session_id: state.login.userData._id,
    addCustBranch: state.customers.addCustBranch,
    areaList: state.customers.areaList,
});

function mapDispatchToProps(dispatch) {
    return {actions: bindActionCreators(ActionCreators, dispatch)}
}

export default connect(mapStateToProps, mapDispatchToProps)(ResAccAddCustomerFullyPaid);