import React, {Component} from 'react';
import app from '../../../helpers/feathers';
import SyncDataModal from '../../../components/sync_data_modal';
// react-router
import {Link} from 'react-router-dom';
import moment from 'moment';
import toastr from 'toastr';

//redux
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
// actions
import {ActionCreators} from '../../../actions';

//main NavBar
import MainNav from '../../../components/global_nav';
// import MainSideBar from '../../../components/global_sidebar';

import MainSideBar from '../../../components/acc_sidebar';
//sub side bar
import SubSideBar from './sub_sidebar';


//custom styles
import '../../../stylesheet/styles.css';

//datatables
import '../../../stylesheet/datatables/datatables.css';

const $ = require('jquery');
$.DataTable = require('datatables.net');

var that;

class queues extends Component {

    _isMounted = false;

    constructor(props) {
        super(props);

        this.state = {
            data: [],
            customerName: "",
            accountNumber: "",
            searching: false,
            totalCustomer: 0
        };
    }

    componentWillMount() {
        that = this;
    }

    componentDidMount() {

        that._isMounted = true;

        const applicationTbl = $('.tbl-queues').DataTable({
            // data: this.props.customers,
            "columnDefs": [
                {
                    "visible": false, "targets": 0
                },
                // {
                //     targets: 7,
                //     orderable: false,
                //     width: 30
                // },
            ],
            // "order": [[ 6, "desc" ]],
            columns: [
                {title: ""},
                {title: "ACTION"},
                {title: ""},
            ],
            "sDom": '<"bottom"<t>ip><"clear">'
        });


        $('.search').keyup(function () {
            applicationTbl.search($(this).val()).draw();
        });

        $('.tbl-queues').on('click', 'button.update', function () {
            const data = applicationTbl.row($(this).parents('tr')).data();

            that.props.actions.ResumeProcess(data[0])
            .then((res) => {
                if(res){
                    toastr.success("Data successfully updated")
                }else{
                    toastr.error("Failed to update data")
                }
            })
        });

        // this.getq()

    }

    componentWillUnmount(){
        that._isMounted = false;
    }

    fastSearch = () => {

        let {customerName, accountNumber} = this.state;

        if (customerName.length > 0 || accountNumber.length > 0) {
            that.advancedSearch()
        }
    }

    updateData = (data) => {
        this.setState({
            data: data
        });
        const table = $('.tbl-queues').DataTable();
        table.clear();
        table.rows.add(data);
        table.draw();
    };

    permissionAccounting = () => {
        const permission = this.props.userPermission;
        return permission.accounting === 0 ? "not-visible" : "";
        // if (permission.accounting === 0){
        //     return "not-visible"
        // }else{
        //     return "";
        // }
    };

    addq = () => {

        let obj = {
            service: 'customer-secondhand-unit',
            action: 'UPDATE SECONDHAND UNIT INFO',
            type: 'patch',
            id: '123',
            data: {
                current_owner : 0
            }
        }
        this.props.actions.SetQueues(obj);

        setTimeout(() => {
            this.getq();
        }, 1000 * 2)
    }
    getq = () => {
        this.props.actions.GetQueues()
        .then((data) => {
            that.updateData(data.length ? data : [])
        })
    }
    clearq = () => {
        this.props.actions.SetQueues();
        setTimeout(() => {
            that.getq();
        }, 1000 * 5)
    }


    render() {
        return (
            <div className="">
                {/* main NavBar component*/}
                <MainNav historyProp={this.props.history} usersProp={this.props.userData} systemType="accounting"/>
                <div className="container-fluid">
                    <div className="row full-width">
                        <div className="sidebar">
                            <div className="sidebar-content">
                                <MainSideBar page="customer"/>
                            </div>
                        </div>
                        <SubSideBar
                            history={this.props.history}
                            userPermission={this.props.userPermission}
                            page="queues"
                            path="/queues"
                            pageTitle="QUEUES"
                        />
                        <div>
                            <div className="sub-main-content mobile-container-installment">
                                <div className="container">
                                </div>

                                <div className="container-fluid">
                                    <div className="application-container">
                                        <div className="row padding-0-15">
                                            <div className="col">
                                                <span className="global__header-label">Queues</span>
                                                {
                                                    // <button type="button" className="btn btn-primary" onClick={this.addq} >Add Queue</button>
                                                    // <button className="btn btn-danger" onClick={this.clearq} >Clear Queue</button>
                                                }
                                            </div>
                                        </div>
                                        <div>
                                            <br/>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <table className="table tbl-queues tbl-mobile"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


const mapStateToProps = state => ({
    app: state.feathersClient.app,
    customers: state.customers.customersTerms,
    userData: state.login.userData,
    userPermission: state.login.userPermission,
});

function mapDispatchToProps(dispatch) {
    return {actions: bindActionCreators(ActionCreators, dispatch)}
}

export default connect(mapStateToProps, mapDispatchToProps)(queues);
