import React, {Component} from 'react';
import app from '../../../helpers/feathers';
import SyncDataModal from '../../../components/sync_data_modal';
// react-router
import {Link} from 'react-router-dom';

//redux
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
// actions
import {ActionCreators} from '../../../actions';

//main NavBar
import MainNav from '../../../components/global_nav';
// import MainSideBar from '../../../components/global_sidebar';

import MainSideBar from '../../../components/acc_sidebar';
//sub side bar
import SubSideBar from './sub_sidebar';


//custom styles
import '../../../stylesheet/styles.css';

//datatables
import '../../../stylesheet/datatables/datatables.css';


import SelectUnitType from './select_customer_modal';
import EditCustomerModal from './edit_customer_modal';
import RepossessModal from './repossess_modal';
import DeleteAccountModal from './delete_account_modal';

const $ = require('jquery');
$.DataTable = require('datatables.net');

var that;

class acc_customer extends Component {

    _isMounted = false;

    constructor(props) {
        super(props);

        this.state = {
            data: [],
            customerName: "",
            accountNumber: "",
            searching: false,
            totalCustomer: 0
        };
    }

    componentWillMount() {
        that = this;
    }

    componentDidMount() {

        that._isMounted = true;
        const { customers_bn_installment } = this.props;
        that.setState({totalCustomer: customers_bn_installment ? customers_bn_installment.length : 0})
        const applicationTbl = $('.tbl-customer').DataTable({
            // data: this.props.customers,
            data: customers_bn_installment ? customers_bn_installment : [],
            "columnDefs": [
                {
                    "visible": false, "targets": 7
                },
                {
                    targets: [2],
                    orderable: false

                },
                {
                    targets: [0, 2],
                    orderable: false,
                    width: '15%'
                }
            ],
            "order": [[ 5, "desc" ]],
            columns: [
                {title: "NAME"},
                {title: "ACCOUNT NO."},
                {title: "ADDRESS"},
                {title: "CONTACT NO."},
                {title: "BALANCE"},
                {title: "DATE CREATED"},
                {title: "ACTION"}
            ],
            "sDom": '<"bottom"<t>ip><"clear">'
        });


        $('.search').keyup(function () {
            applicationTbl.search($(this).val()).draw();
        });

        $('.tbl-customer').on('click', 'button.btn-new-view', function () {
            const data = applicationTbl.row($(this).parents('tr')).data(),
                name = (data[0]).replace(/ /g, "");
            that.props.actions.setCustomerInfo(data[7]);
            that.props.history.push('/view_customer/');
        });
        //
        //
        $('.tbl-customer').on('click', 'button.btn-new-edit', function () {
            const data = applicationTbl.row($(this).parents('tr')).data();
            that.props.actions.setCustomerInfo(data[7]);
            $('#edit_customer').modal('show');
        });

        $('.tbl-customer').on('click', 'button.repossess', function () {
            const data = applicationTbl.row($(this).parents('tr')).data();

            that.props.actions.setCustomerInfo(data[7]);
            $('#repossessed_modal').modal('show');
        });

        $('#edit_customer').on('hidden.bs.modal', function () {
            that.getLatestCustomers();
        });


        // const custService = app.service('customers');
        // custService.on('created', () => {
        //     that.getAllCustomers();
        // });
        // custService.on('patched', () => {
        //     that.getAllCustomers();
        // })

        that.getLatestCustomers();

    }
    componentWillUnmount(){
        that._isMounted = false;
    }

    advancedSearch = () => {
        var that = this,
            customerName = this.state.customerName,
            accountNumber = this.state.accountNumber,
            query = {};

        // var permission = this.props.userPermission;

        if (customerName === '' && accountNumber === '') {
            // that.getLatestCustomers();
            that.setDefaultList();
            return;
        }

        // if (permission.inventory === 0) {
        //     return
        // }
        if (customerName) {
            query.name = {
                $regex: customerName.toUpperCase()
            }
        }

        if (accountNumber) {
            query.account_number = {
                $regex: accountNumber
            }
        }

        that.getAllCustomers(customerName.toUpperCase(), accountNumber.toUpperCase());

    }

    fastSearch = () => {

        let {customerName, accountNumber} = this.state;

        if (customerName.length > 0 || accountNumber.length > 0) {
            that.advancedSearch()
        }
    }


    // getAllCustomers = (query) => {
    //     this.props.actions.getTermsCustomers(query).then((data) => {

    //         if(data){
    //             that.props.actions.setTermsCustomers(data);
    //             that.updateData(data);
    //             var totalNum = data.length ;
    //             this.setState({totalCustomer:totalNum})
    //         }
    //         else{
    //             that.updateData([]);
    //         }
    //     });
    // }

    getAllCustomers = (customerName, accountNumber) => {
        that.props.actions.getTermsCustomers(customerName, accountNumber).then((data) => {
            if (data.data) {
                const d = data.data;
                // that.props.actions.setTermsCustomers(d);
                that.updateData(d);
                const totalNum = d.length;
                this.setState({totalCustomer: totalNum});
            }
        })
    };

    getLatestCustomers = () => {
        if(that._isMounted){
            that.props.actions.getTermsCustomers(false,false,true)
            .then((res) => {
                if(res.data){
                    // that.updateData(res.data);
                    const totalNum = res.data.length;
                    that.setState({totalCustomer: totalNum});
                }
            })
        }
    }

    setDefaultList = () => {
        const { customers_bn_installment } = this.props;
        const data = customers_bn_installment;
        const table = $('.tbl-customer').DataTable();
        table.clear();
        table.rows.add(data);
        table.draw();
    }

    updateData = (data) => {
        this.setState({
            data: data
        });
        const table = $('.tbl-customer').DataTable();
        table.clear();
        table.rows.add(data);
        table.draw();
    };

    permissionAccounting = () => {
        const permission = this.props.userPermission;
        return permission.accounting === 0 ? "not-visible" : "";
        // if (permission.accounting === 0){
        //     return "not-visible"
        // }else{
        //     return "";
        // }
    };

    render() {
        return (
            <div className="">
                {/* main NavBar component*/}
                <MainNav historyProp={this.props.history} usersProp={this.props.userData} systemType="accounting"/>
                <SyncDataModal/>
                <EditCustomerModal/>
                <RepossessModal history={this.props.history}/>
                <SelectUnitType history={this.props.history}/>
                <div className="container-fluid">
                    <div className="row full-width">
                        <div className="sidebar">
                            <div className="sidebar-content">
                                <MainSideBar page="customer"/>
                            </div>
                        </div>
                        <SubSideBar
                            history={this.props.history}
                            userPermission={this.props.userPermission}
                            page="acc_customer"
                            path="/acc_add_customer"
                            pageTitle="CUSTOMERS_BRANDNEW"
                        />
                        <div>
                            <div className="sub-main-content mobile-container-installment">
                                <div className="container">
                                    <div className="application-container2">
                                        <div className="form-inline">
                                            <div className="col-md-12">
                                                <h4>Search By Filter</h4>
                                                <br/>
                                            </div>
                                            <div className="col-md-4">
                                                <label>Name</label>
                                                <input
                                                    className="form-control full-width"
                                                    placeholder="Enter Full Name"
                                                    onChange={(e) => this.setState({customerName: e.target.value})}
                                                    ref="customer_name"
                                                    onKeyUp={(e) => this.advancedSearch()}
                                                />
                                            </div>
                                            <div className="col-md-4">
                                                <label>Account No.</label>
                                                <input
                                                    className="form-control full-width"
                                                    placeholder="Enter Account No."
                                                    onChange={(e) => this.setState({accountNumber: e.target.value})}
                                                    ref="account_number"
                                                    onKeyUp={(e) => this.advancedSearch()}
                                                />
                                            </div>
                                            <div className="col-md-4">
                                                <label>&nbsp;</label>
                                                <button className="btn full-width btn-second-primary btn-sm"
                                                        onClick={() => this.advancedSearch()}>Search
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="container-fluid">
                                    <div className="application-container">
                                        <div className="row padding-0-15">
                                            <div className="col">
                                                <span className="global__header-label">Customers with Brand New Units (Installment)</span>
                                                <span className="badge badge-info customer-count float-right">No of Customers : {this.state.totalCustomer} </span>
                                            </div>
                                        </div>
                                        <div>
                                            <br/>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <table className="table tbl-customer tbl-mobile"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


const mapStateToProps = state => ({
    app: state.feathersClient.app,
    customers: state.customers.customersTerms,
    customers_bn_installment: state.customers.customers_bn_installment,
    userData: state.login.userData,
    userPermission: state.login.userPermission,
});

function mapDispatchToProps(dispatch) {
    return {actions: bindActionCreators(ActionCreators, dispatch)}
}

export default connect(mapStateToProps, mapDispatchToProps)(acc_customer);
