import React, {Component} from 'react';
// react-router
import {Link} from 'react-router-dom';

//redux
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
// actions
import {ActionCreators} from '../../../actions';

//main NavBar
import MainNav from '../../../components/global_nav';
import MainSideBar from '../../../components/global_sidebar';

//sub side bar
import SubSideBar from './sub_sidebar';

//custom styles
import '../../../stylesheet/styles.css';

const $ = require('jquery');
$.DataTable = require('datatables.net');

var that

class customer_badrecords extends Component {

    constructor(props) {
        super(props);

        this.state = {
            badrecords: [],
            param: ''
        };
    }

    componentWillMount() {
        that = this;
        that.customersWithBadRecord();

        that.props.app.service('customers').find()
            .then((data) => {
                console.log('customers ', data)
            })
    }

    componentDidMount() {
        // $('.hide-view-customer').css('display', 'none', '!important')
        $('.ct-payments-tbl').css('display', 'none', '!important');
        $('.p-5').removeClass('background-white');
        $('.hide-view-customer.installment').css('padding', '1px', '!important');
        var tblBadRecords = $('.tbl-customer-bad').DataTable({
            data: this.state.badrecords,
            columnDefs: [
                {
                    targets: [3],
                    orderable: false,
                    width: '8%'
                },
                {
                    targets: [4, 5],
                    visible: false,
                }
            ],
            columns: [
                {title: "NAME"},
                {title: "ACCOUNT NO."},
                {title: "BRANCH"},
                {title: "ACTION"},
                {title: ""},
                {title: "TYPE"},
            ],
            "sDom": '<"bottom"<t>ip><"clear">'
        });

        $('.tbl-customer-bad').on('click', 'button.view-badrecord', function () {
            var data = tblBadRecords.row($(this).parents('tr')).data(),
                name = (data[0]).replace(/ /g, "");

            that.props.actions.setCustomerInfo(data[4]);
            that.props.history.push('/customer/' + name);
        });

        $('.search').keyup(function () {
            tblBadRecords.search($(this).val()).draw();
        });

        var customerService = that.props.app.service('customers');

        customerService.on('patched', () => {
            that.customersWithBadRecord()
        })
    }

    customersWithBadRecord = () => {
        that.props.actions.getCashCustomers().then((data) => {
            that.props.actions.setCashCustomers(data);
        });
        that.props.actions.getTermsCustomers().then((data) => {
            that.props.actions.setTermsCustomers(data);
        });
        that.props.actions.customersWithBadRecord()
            .then((data) => {
                that.updateData(data);
            })
    }

    updateData = (data) => {
        this.setState({
            badrecords: data
        });

        const table = $('.tbl-customer-bad').DataTable();
        table.clear();
        table.rows.add(data);
        table.draw();
    }

    render() {
        return (
            <div className="">

                {/* main NavBar component*/}
                <MainNav historyProp={this.props.history} usersProp={this.props.userData}/>

                <div className="container-fluid">
                    <div className="row full-width">
                        <div className="sidebar">
                            <div className="sidebar-content">
                                <MainSideBar page="customer"/>
                            </div>
                        </div>
                        <SubSideBar history={this.props.history} allProps={this.props}
                                    userPermission={this.props.userPermission}/>
                        <div className="sub-main-content">
                            <div className="d-flex flex-row justify-content-left content-header">
                                <input type="search" className="form-control form-control-lg align-items-center search"
                                       placeholder="&#xF002; Search"/>
                            </div>
                            <div className="container-fluid">
                                <div className="application-container">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <table
                                                className="table tbl-customer-bad tbl-striped background-white tbl-mobile"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


const mapStateToProps = state => ({
    app: state.feathersClient.app,
    userData: state.login.userData,
    userPermission: state.login.userPermission,
});

function mapDispatchToProps(dispatch) {
    return {actions: bindActionCreators(ActionCreators, dispatch)}
}

export default connect(mapStateToProps, mapDispatchToProps)(customer_badrecords);
