import React, {
    Component
} from 'react';

//redux
import {
    connect
} from 'react-redux';
import {
    bindActionCreators
} from 'redux';
// actions
import {
    ActionCreators
} from '../../../actions';

//main NavBar
import MainNav from '../../../components/global_nav';
import MainSideBar from '../../../components/acc_sidebar';

//sub side bar
import SubSideBar from './report_sub_sidebar';

//custom helpers
import {
    numberWithCommas
} from '../../../helpers/';

//custom styles
import '../../../stylesheet/styles.css'

//datatables
import '../../../stylesheet/datatables/datatables.css';
// import '../../../stylesheet/datatables/buttons.dataTables.min.css'

//React Print Sample

// import ReactToPrint from 'react-to-print';


//daterange
import moment from 'moment';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import './plugins/daterangepicker.css';

import html2canvas from 'html2canvas';
import jsPDF from '../../../../node_modules/jspdf/dist/jspdf.min.js';

import toastr from 'toastr';
const $ = require('jquery');
$.DataTable = require('datatables.net');


var that

class view_tota_paid_customers extends Component {


    constructor(props) {
        super(props);

        this.state = {
            brand: [],
            filter_reporttype: null,
            filter_daterange: null,
            filter_branch: '',
            filter_showby: null,
            filter_brand: null,
            filter_columnby: '',
            filter_columndata: null,
            sample:null,
            saving:false,
            drPickerApplied: false,
            totalPaid:0,
            table_data: []
        };
    }





    componentWillMount() {
        that = this;

        var reportDetail = that.props.viewReportData,
            table_data = reportDetail.table_data,
            total = 0;


        if (that.props.viewReportData.daterange_start !== null){
            that.setState({
                drPickerApplied: true
            })
        }

        that.updateData(table_data);

        // reportDetail.table_data.length && reportDetail.table_data.map((res) => {
        //     total += parseFloat(res[3])
        // })

        // that.setState({totalPaid: total})


    }

    componentDidMount() {
        var reportDetail = that.props.viewReportData,
            report_type = reportDetail.report_type;

        if (report_type == "repo_customers"){
            var dt_columns = [
                {title: ""},
                { title: "ACCOUNT #" },
                { title: "FULL NAME"},
                { title: "DATE PURCHASED" },
                { title: "DATE REPOSSESSED" }
            ];
        }

        console.log('report_type ', reportDetail)


        var viewReport = $('.tbl-view-report-pdf').DataTable({
            data: this.state.table_data,
            "columnDefs": [
                {
                    "visible": false, "targets": 0
                },
            ],
            columns: dt_columns,
            "sDom": '<"bottom"<t>ip><"clear">',
            info: false,
            paging: false,
            "bFilter": false,
            dom: 'Bfrtip',
            buttons: [
                'print'
            ]
        });
    }

    updateData = (data) => {
        this.setState({
            table_data: data
        });
        const table = $('.tbl-view-report-pdf').DataTable();
        table.clear();
        table.rows.add(data);
        table.draw();
    }

    printDocument() {
        const input = document.getElementById('divToPrint');
        html2canvas(input)
            .then((canvas) => {
                const imgData = canvas.toDataURL('image/png');
                const pdf = new jsPDF({
                    orientation: 'landscape',
                });
                pdf.addImage(imgData, 'JPEG', 0, 0);
                // pdf.output('dataurlnewwindow');
                pdf.save(that.props.viewReportData.report_title+".pdf");
            });
    }

    mailTo = () => {
        var attachments = "test"
        var body_message = 'test';
        var email = '';
        var subject = 'Eversure Report';
        var mailto_link = 'mailto:' + email + '?subject=' + subject + '&body=' + body_message;
        var win = window.open(mailto_link, 'emailWindow');
        if (win && win.open && !win.closed) win.close();
    }

    render() {

        let { viewReportData } = this.props;
        return (
            <div className="">
                <MainNav historyProp={this.props.history} usersProp={this.props.userData} systemType="accounting"/>

                <div className="container-fluid">
                    <div className="row full-width">
                        <div className="sidebar">
                            <div className="sidebar-content">
                                <MainSideBar page="report"/>
                            </div>
                        </div>
                        <SubSideBar
                            history={this.props.history}
                            userPermission={this.props.userPermission}
                            pageTitle="RPRTS_REPO_CUSST"
                        />
                        <div className="sub-main-content__view">
                            <div className="container-fluid">
                                <div className="padding-20 background-white">
                                    <div className="col-md-12">
                                        <div className="row">
                                            <div className="col">
                                                <div className="row report-option-icons-2">
                                                    <a href="javascript:;" className="print-not-visible" onClick={() => this.printDocument()}><i className="fa fa-download"></i></a>
                                                    {
                                                        // <a href="javascript:;"><i className="fa fa-file-pdf-o"></i></a>
                                                        // <a href="javascript:;" className="print-not-visible" onClick={() => this.mailTo() }><i className="fa fa-envelope-o"></i></a>
                                                    }
                                                    <a href="javascript:;" className="print-not-visible" onClick={() => window.print() }><i className="fa fa-print"></i></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row padding-20">
                                    <a onClick={() => this.props.history.goBack()} className="print-not-visible">Accounting


                                        Reports / {this.props.viewReportData.report_title}</a>
                                </div>
                                <div className="row padding-20 background-white" id="divToPrint">
                                    <div className="col-md-6">
                                        <h2>{this.props.viewReportData.report_title}</h2>
                                        <p>Created By {this.props.viewReportData.user_fullname}</p>
                                    </div>
                                    <div className="col-md-1">
                                    </div>
                                    <div className="col-md-5">
                                        <h5>LIST OF FULLY PAID CUSTOMERS</h5>
                                        <span>
                                        {
                                            moment(viewReportData.createdAt).format('LL')
                                        }
                                        </span><br />
                                        <span>{this.props.userData.branch_info.branch_name+' BRANCH'}</span>
                                    </div>
                                    <div className="col-md-12">
                                        <br />
                                    </div>
                                    <div className="col-md-12">
                                        <div className="header-pagination-container">
                                            <ul className="pagination">

                                            </ul>

                                        </div>
                                        <h3 className="float-right"><strong>{this.props.viewReportData.extraData ? 'TOTAL COST = '+numberWithCommas(this.props.viewReportData.extraData) : ''}</strong></h3>
                                        <table className="table tbl-view-report-pdf">
                                        </table>
                                        {/* <span className = "alert alert-info customer-count">TOTAL: {this.state.totalPaid}</span> */}
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


const mapStateToProps = state => ({
    app: state.feathersClient.app,
    userData: state.login.userData,
    viewReportData: state.reports.viewReportData,
});

function mapDispatchToProps(dispatch) {
    return { actions: bindActionCreators(ActionCreators, dispatch) }
}

export default connect(mapStateToProps, mapDispatchToProps)(view_tota_paid_customers);
