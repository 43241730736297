import React, {Component} from 'react';

//redux
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import * as ProductsActions from '../../../actions/product';

import moment from 'moment';

//main NavBar
import MainNav from '../../../components/global_nav';
import MainSideBar from '../../../components/global_sidebar';

//custom styles
import '../../../stylesheet/styles.css';

import 'amcharts3';
import 'amcharts3/amcharts/serial';
import 'amcharts3/amcharts/plugins/responsive/responsive';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import AddPaymentModal from './add_payment_modal';

import app from '../../../helpers/feathers';

//datatables
import '../../../stylesheet/datatables/datatables.css';

const $ = require('jquery');
$.DataTable = require('datatables.net');

const that = this;

class payments extends Component {

    constructor(props) {
        super(props);

        this.state = {
            payments: [],
            count: 0,
            filter_date_paid: moment()
        };
    }

    componentWillMount() {
        this.getPaymentsList();
    }

    componentDidMount() {
        const productTable = $('.tbl-payment').DataTable({
            data: this.state.payments,
            columns: [
                {"title": "object"},
                {"title": "SUPPLIER NAME"},
                {"title": "RECEIPT NO."},
                {"title": "AMOUNT PAID"},
                {"title": "DATE PAID"},
                {"title": "REMARKS"},
            ],
            columnDefs: [
                {
                    targets: [0],
                    visible: false
                }
            ],
            'dom': "<'row'<'col-sm-6'l><'col-sm-6'p>>" +
                "<'row'<'col-sm-12'tr>>" +
                "<'row'<'col-sm-12'i>>"
        });

        const paymentService = app.service('payment');

        paymentService.on('created', function () {
            // that.getPaymentsList();
        })
        paymentService.on('removed', function () {
            // that.getPaymentsList();
        })
    }

    getPaymentsList = () => {
        this.props.actions.getPaymentsList()
            .then((data) => {
                this.update_table(data);
            })
    }


    update_table = (data) => {
        this.setState({
            payments: data
        });

        const table = $('.tbl-payment').DataTable();
        table.clear();
        table.rows.add(data);
        table.draw();

    }

    clickModal = () => {
        const permission = this.props.userPermission;

        if (permission.inventory === 2) {
            this.refs.add_payment.click();
        }
        this.createListener();
    }

    createListener = () => {
        const payment = app.service('payment'),
            that = this;

        payment.on('created', products => {
            that.filter_payment();
        });
    }

    handleDatePaid = (date) => {
        this.setState({
            filter_date_paid: date
        });
    }

    filter_payment = () => {
        const filter_date = this.state.filter_date_paid;
        const query = {
            date_paid: moment(filter_date).format('MM/DD/YYYY')
        };

        this.props.actions.filterPaymentsList(query)
            .then((data) => {
                this.update_table(data)
            });
    }
    permissionInventory = () => {
        const permission = this.props.userPermission;

        if (permission.inventory === 0) {
            return "not-visible"
        } else {
            return "";
        }
    }

    render() {
        return (
            <div className="">
                {/* main NavBar component*/}
                <MainNav historyProp={this.props.history} usersProp={this.props.userData}/>
                <AddPaymentModal/>
                <div className="container-fluid">
                    <div className="row full-width">
                        <div className="sidebar">
                            <div className="sidebar-content">
                                <MainSideBar page="payments"/>
                            </div>
                        </div>
                        {
                            // this.props.userPermission.inventory === 0 ?
                            this.props.userData.user_position_info.position_type !== 'ADMINISTRATOR' ?
                                <div className="main-content">
                                    <div className="no-access-div">
                                        <div className="col-md-12">
                                            <h1>Access denied</h1>
                                        </div>
                                    </div>
                                </div>
                                : null
                        }
                        <div
                            className={this.props.userData.user_position_info.position_type !== 'ADMINISTRATOR' ? "not-visible" : ""}>
                            <div className="main-content">
                                <div className="row col-md-8">
                                    <div className="col">
                                        <h4 className="subheader-title">Payments</h4>
                                    </div>
                                    <div className="col">
                                        <a href="#" data-toggle="modal" data-target="#add_payment"
                                           ref="add_payment"></a>
                                        <button className="btn btn-second-primary add-new-btn"
                                                onClick={() => this.clickModal()}>
                                            <span><i className="fa fa-plus"/> Add Payment</span>
                                        </button>
                                    </div>
                                </div>
                                <br/>
                                <div className="row col-md-6">
                                    <div className="col">
                                        <DatePicker
                                            selected={this.state.filter_date_paid}
                                            onChange={this.handleDatePaid}
                                            peekNextMonth
                                            showMonthDropdown
                                            showYearDropdown
                                            dropdownMode="select"
                                            className="form-control"
                                        />
                                    </div>
                                    <div className="col">
                                        <button className="btn btn-block btn-primary payment-filter-btn"
                                                onClick={() => this.filter_payment()}>Filter
                                        </button>
                                    </div>
                                </div>
                                <br/>
                                <div className="row">
                                    <div className="col-md-12">
                                        <table className="table tbl-payment tbl-mobile">
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


const mapStateToProps = state => ({
    loginStatus: state.login.loginStatus,
    userData: state.login.userData,
    userPermission: state.login.userPermission,
});

function mapDispatchToProps(dispatch) {
    return {actions: bindActionCreators(Object.assign({}, ProductsActions), dispatch)}
}

export default connect(mapStateToProps, mapDispatchToProps)(payments);