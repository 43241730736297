import React, { Component } from 'react';
import toastr from 'toastr';

//select tag
import Select from 'react-select';
import 'react-select/dist/react-select.css';

//redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// actions
import { ActionCreators } from '../../../actions';

const $ = require('jquery');

var that;

class update_model_modal extends Component {

	constructor(props) {
	  	super(props);
	
	  	this.state = {
	  		id: null,
	  		modelName: '',
	  		newModelName: '',
	        selectedBrand: '',
	        selectedBrandChanged: false,
	  	};  
	}

	componentWillMount(){

		that = this;
	}

	closeModalCallback = () => {
		that.refs.cancel.click();
		that.refs.modelName.value = '';
		that.setState({
			selectedBrand: ''
		})
	}

	updateModel = () => {
		
		var modelName = this.state.modelName,
			updatedModelName = (this.refs.modelName.value.trim()).toUpperCase(),
		 	that = this,
		 	query = {};

		// if(modelName === updatedModelName){
		// 	that.closeModalCallback()
		// 	toastr.info('No changes made');
		// 	return;
		// }

		query.data_status = false;

		if(updatedModelName){
			query.model_name = updatedModelName;
			query.data_status = true;
		}
		if(this.state.selectedBrand){
			query.brand = this.state.selectedBrand.value;
			query.data_status = true;
		}

		if (!query.data_status){
			console.log(query.data_status)
			return;
		}else{

			if (!this.state.selectedBrand){
				if(this.props.category.model_name === updatedModelName){
					that.closeModalCallback()
					toastr.info('No changes made');
					return;
				}
			}
			if (!updatedModelName){
				if (this.props.category.brand_details.brand_name === this.state.selectedBrand.label){
					that.closeModalCallback()
					toastr.info('No changes made');
					return;
				}
			}

			if(this.props.category.model_name === updatedModelName && this.props.category.brand_details.brand_name === this.state.selectedBrand.label){
				that.closeModalCallback()
				toastr.info('No changes made');
				return;
			}else{
				that.props.actions.updateModel(this.props.category._id, query)
				.then((res) => {
					that.closeModalCallback()
					if (res){
						toastr.success('Model successfully updated!');
					}else{
						toastr.error('Could not update model!');
					}
				})
			}
		}

		// console.log(query)

		return;

		that.props.actions.updateModel(this.props.category._id, updatedModelName)
		.then((res) => {
			that.closeModalCallback()
			if (res){
				toastr.success('Model successfully updated!');
			}else{
				toastr.error('Could not update model!');
			}
		})

		// this.props.actions.addModel(modelName).then((data) => {
		// 	if(data === 'exist'){
		// 		toastr.error(modelName + ' is already exists.');
		// 	}else if(data === 'success') {
		// 		that.refs.cancel.click();
		// 		toastr.success(modelName + ' is successfully added');
		// 	}else{
		// 		toastr.error('Failed to add. Please try again.');
		// 	}
		// });

	}

	// componentWillReceiveProps(data) {
	// 	this.updateModelProps(data.params.name, data.params.id);
	// }

	// updateModelProps = (name, id) => {
	// 	console.log(id, name);
	// 	this.setState({
	// 		modelName: name,
	// 		id: id
	// 	})
	// }

	brandOption = () => {

		var array = this.props.brandsSelect,
			newArray = []

		array.map((obj) => {
			if(obj.value !== 'all'){
				newArray.push(obj)
			}
		})
		return newArray
	}

    handleChangeBrand = (selectedOption) => {

        var value = selectedOption;

        if (value != null){
            this.setState({
                selectedBrand: value,
                selectedBrandChanged: true
            });
        }else{
            this.setState({
                selectedBrand: '',
                selectedBrandChanged: false
            })
        }

    }

	onChangeModel = (e) => {
		// console.log(e.target.value)
	}

  	render() {
    	return (
      		<div className="modal fade" id="update_model" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" ref="updateModel" data-backdrop="static" data-keyboard="false">
			  	<div className="modal-dialog" role="document">
			    	<div className="modal-content">
			      		<div className="modal-header">
			        		<h5 className="modal-title" id="exampleModalLabel">Update Motorcycle Model</h5>
			        			<button type="button" className="close" onClick={this.closeModalCallback} aria-label="Close">
						          <span aria-hidden="true">&times;</span>
						        </button>
			      		</div>
				      	<div className="modal-body">
				        	<div className="form-group">
				        		<label>Brand Name: <strong>{this.props.category.brand_details ? this.props.category.brand_details.brand_name : ''}</strong></label>
				        		<Select
                                    name="selectBrand"
                                    value={this.state.selectedBrand.value}
                                    onChange={this.handleChangeBrand}
                                    options={this.brandOption()}
                                    clearableValue={true}
                                    placeholder="Select Brand"
                                />
				        	</div>
				        	<div className="form-group">
				        		<label>Model Name: <strong>{this.props.category.model_name}</strong></label>
				        		<input
				        			type="text"
				        			key={(this.props.category).length > 0 ? 'input' : 'data'}
				        			className="form-control"
				        			ref="modelName"
				        			// defaultValue={this.props.category.model_name}
				        			onChange={this.onChangeModel}
				        		/>
				        	</div>
				      	</div>
				      	<div className="modal-footer">
				        	<a data-dismiss="modal" ref="cancel"></a>
				        	<button type="button" className="btn btn-secondary" onClick={this.closeModalCallback}>Cancel</button>
				        	<button type="button" className="btn btn-primary" onClick={this.updateModel}>Update</button>
				      	</div>
			    	</div>
			  	</div>
			</div>
    	);
  	}
}


const mapStateToProps = state => ({
   	category: state.category.updateModelInfo,
   	app: state.feathersClient.app,
	brandsSelect: state.category.brandsSelect,
});

function mapDispatchToProps(dispatch) {
   return { actions: bindActionCreators(ActionCreators, dispatch) }
}

export default connect(mapStateToProps, mapDispatchToProps)(update_model_modal);