import React, { Component } from 'react';
import app from "../../../helpers/feathers";

// react-router
import { Link } from 'react-router-dom';

//redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// actions
import { ActionCreators } from '../../../actions';

//main NavBar
import MainNav from '../../../components/global_nav';
import MainSideBar from '../../../components/acc_sidebar';
import SyncDataModal from '../../../components/sync_data_modal';

//sub side bar
import SubSideBar from './sub_sidebar';
import SelectUnitType from './select_customer_modal';
import EditCustomerModal from './edit_customer_modal';
import AddToRepoModal from './add_to_repo_modal';



//custom styles
import '../../../stylesheet/styles.css';

//datatables
import '../../../stylesheet/datatables/datatables.css';

const $ = require('jquery');
$.DataTable = require('datatables.net');

var g_branch = localStorage.getItem('_branch_secret'), that;
var _0x896d=["\x74\x65\x73\x74\x32"];var secret_user=_0x896d[0],secret_pass=_0x896d[0]

class customers_with_repossessed_untis extends Component {


    constructor(props) {
        super(props);

        this.state = {
            data: [],
            customerName: "",
            accountNumber : "",
            totalCustomer:0
        };
    }

    componentWillMount() {
        that = this;
            // this.getAllCustomers();

            // products select options
            that.props.actions.getProducts().then((data) => {
                that.props.actions.setProducts(data);
            });

        //inventory
        this.props.actions.getAllProducts().then((data) => {
            if(data){
                that.props.actions.setAllProducts(data);
            }

        });

        // check connection to main server
        // app.authenticate({
        //     strategy: 'local',
        //     'username': secret_user,
        //     'password': secret_pass
        // })
        //     .then(() => {
        //         that.props.actions.connectedToMainServer(true);
        //     })
        //     .catch(() => {
        //         that.props.actions.connectedToMainServer(false);
        //     })

        // models for secondhand units in autosuggest
        app.service('motorcycle-models').find()
            .then((data) => {
                var d = data.data,
                    models = [];

                d.map((v) => {
                    models.push({
                        name: v.model_name
                    })
                })

                that.props.actions.setModelsForSecondhand(models);
            })
        .catch((err) => {
            console.log(err)
        })


    }

    componentDidMount() {
        var applicationTbl = $('.tbl-customer-repo').DataTable({
            "columnDefs": [
                {
                    targets: [0],
                    width: '15%'
                },
                {
                    targets: [5],
                    width: 80,
                    orderable: false
                },
            ],
            "order": [[ 4, "desc" ]],
            columns: [
                { title: "NAME" },
                { title: "ACCOUNT NO." },
                { title: "AREA" },
                // { title: "MODEL" },
                // { title: "ENGINE NO."},
                // { title: "DATE PURCHASED"},
                { title: "REMARKS"},
                { title: "DATE CREATED"},
                { title: "ACTION" }
            ],
            "sDom": '<"bottom"<t>ip><"clear">'
        });


        $('.search').keyup(function(){
            applicationTbl.search($(this).val()).draw() ;
        });

        $('.tbl-customer-repo').on('click', 'button.btn-new-view', function () {
            var data = applicationTbl.row( $(this).parents('tr') ).data(),
                name = (data[0]).replace(/ /g,"");
                
            that.props.actions.setCustomerInfo(data[6]);
            that.props.history.push('/view_customer/');
        });

        $('.tbl-customer-repo').on('click', 'button.btn-new-edit', function() {
            var data = applicationTbl.row( $(this).parents('tr') ).data();
            that.props.actions.setCustomerInfo(data[6]);
            $('#edit_customer').modal('show');
        });
        $('.tbl-customer-repo').on('click', 'button.add_repo', function() {
            var data = applicationTbl.row( $(this).parents('tr') ).data();
            that.props.actions.setCustomerInfo(data[6]);
            $('#add_to_repo').modal('show');
        });

        $('#edit_customer, #add_to_repo').on('hidden.bs.modal', function () {
            that.getAllCustomers();
        });

        // var custService = app.service('customers');
        //
        // custService.on('created', () => {
        //     that.getAllCustomers();
        // });
        // custService.on('patched', () => {
        //     that.getAllCustomers();
        // })

        that.getLatestCustomers();
    }

    getAllCustomers = (customerName, accountNumber) => {
        that.props.actions.getCustomersWithRepossessedUnits(customerName,accountNumber)
            .then((data) => {
                if(data){
                    var d = data.data;
                    that.updateData(d);
                    var totalNum = data.data.length;
                    this.setState({totalCustomer:totalNum})
                }


            })
    }

    getLatestCustomers = () => {
        that.props.actions.getCustomersWithRepossessedUnits(false,false,true)
        .then((res) => {
            if(res){
                that.updateData(res.data);
                var totalNum = res.data.length;
                this.setState({totalCustomer:totalNum})
            }
        })
    }

    advancedSearch = () => {
        var that = this,
            customerName = this.state.customerName,
            accountNumber = this.state.accountNumber,
            query = {};

        // var permission = this.props.userPermission;

        if (customerName == '' && accountNumber == ''){
            that.updateData([])
            return;
        }

        // if (permission.inventory === 0) {
        //     return
        // }
        if (customerName) {
            query.name = {
                $regex: customerName.toUpperCase()
            }
        }

        if (accountNumber) {
            query.account_number = {
                $regex: accountNumber
            }
        }

        that.getAllCustomers(customerName.toUpperCase(), accountNumber.toUpperCase());

    }

    fastSearch = () => {

        let { customerName, accountNumber } = this.state;

        if(customerName.length > 2 || accountNumber.length > 0){
            that.advancedSearch()
        }
    }

    updateData = (data) => {
        this.setState({
            data: data
        });

        const table = $('.tbl-customer-repo').DataTable();
        table.clear();
        table.rows.add(data);
        table.draw();
    }

    permissionAccounting = () => {
        var permission = this.props.userPermission;

        if (permission.accounting === 0){
            return "not-visible"
        }else{
            return "";
        }
    }

    render() {
        return (
            <div className="">

                {/* main NavBar component*/}
                <MainNav historyProp={this.props.history} usersProp={this.props.userData} systemType="accounting"/>

                <SyncDataModal />
                <EditCustomerModal />
                <AddToRepoModal customer = {this.props.customerInfo}  history={this.props.history}/>
                <SelectUnitType history={this.props.history} />

                <div className="container-fluid">
                    <div className="row full-width">
                        <div className="sidebar">
                            <div className="sidebar-content">
                                <MainSideBar page="customer"/>
                            </div>
                        </div>
                        <SubSideBar
                            history={this.props.history}
                            page="repossessed"
                            path = "/acc_add_customer"
                            pageTitle="CUSTOMERS_REPOSSESSED"
                        />
                        <div>
                            <div className="sub-main-content mobile-customer-content-wrapper">
                                <div className = "container margin-top-40-percent">
                                    <div className="application-container2">
                                        <div className = "form-inline">
                                            <div className="col-md-12">
                                                <h4>Search By Filter</h4>
                                                <br />
                                            </div>
                                            <div className="col-md-3">
                                                <label>Name</label>
                                                <input
                                                    className="form-control full-width"
                                                    placeholder="Enter Full Name"
                                                    onChange={(e) => this.setState({customerName: e.target.value })}
                                                    onKeyUp={(e) => this.advancedSearch()}
                                                />
                                            </div>
                                            <div className="col-md-3">
                                                <label>Account No.</label>
                                                <input
                                                    className="form-control full-width"
                                                    placeholder="Enter Account No."
                                                    onChange={(e) => this.setState({accountNumber: e.target.value })}
                                                    onKeyUp={(e) => this.advancedSearch()}
                                                />
                                            </div>
                                            <div className="col-md-3">
                                                <label>&nbsp;</label>
                                                <button className  = "btn btn-second-primary full-width" onClick={() => this.advancedSearch() } >Search</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="container-fluid">
                                    <div className="application-container">
                                        <div className = "row">
                                            <div className="col-md-12">
                                                <span className="global__header-label">Customers with Repossessed Units</span>
                                                <span className="badge badge-info customer-count float-right">No of Customers : {this.state.totalCustomer} </span>
                                            </div>
                                        </div>
                                        <div>
                                            <br />
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <table className="table tbl-customer-repo background-white tbl-mobile"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


const mapStateToProps = state => ({
    // app: state.feathersClient.app,
    mainapp: state.feathersClient.mainapp,
    customers: state.customers.customersTerms,
    userData: state.login.userData,
    // customerInfo:state.customers.customerInfo,
    customerInfo:state.customers.setNewCustomerData,
    userPermission: state.login.userPermission,
});

function mapDispatchToProps(dispatch) {
    return { actions: bindActionCreators(ActionCreators, dispatch) }
}

export default connect(mapStateToProps, mapDispatchToProps)(customers_with_repossessed_untis);
