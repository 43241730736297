import React, {Component} from 'react';
//main NavBar
import MainNav from '../../../components/global_nav';
import MainSideBar from '../../../components/acc_sidebar';

//custom styles
import '../../../stylesheet/styles.css';

//redux
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import dashboardLogo from '../../../assets/icons/dashboard.svg';
import inventoryLogo from '../../../assets/icons/inventory.svg';
import paymentsLogo from '../../../assets/icons/payments.svg';
import customerLogo from '../../../assets/icons/customer.svg';
import reportLogo from '../../../assets/icons/reports.svg';
import userLogo from '../../../assets/icons/user.svg';
import settingLogo from '../../../assets/icons/settings.svg';
import financialStatementIcon from '../../../assets/icons/financialstatement_icon.svg';
import ledgerIcon from '../../../assets/icons/ledger_icon.svg';

// actions
import * as LoginActions from '../../../actions/login';

import feathers from '../../../helpers/feathers';
import app from "../../../helpers/feathers";
import moment from "moment";
import overdueLogo from "../../../assets/icons/overdue-white.png";
import financeLogo from "../../../assets/icons/financial-statement-white.png";
import OldRecordsCustomer from "./acc_customer";

const $ = require('jquery');
$.DataTable = require('datatables.net');

class oldRecordsLandingPage extends Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    componentDidMount() {
        $('.back-button-header').hide();
        app.service('customers').find({
            query: {
                $limit: 15
            }
        }).then((ok) => {
                // console.log('ok ', ok)
            }).catch((err) => {
                return err;
                // console.log('err ', err)
            });
    }

    componentWillMount() {
        $('.back-button-header').hide();

        let LOCAL_APP = app,
            LOCAL_CUSTOMERS = LOCAL_APP.service("customers"),
            LOCAL_SH_CUSTOMERS = LOCAL_APP.service("customer-secondhand-unit");

        // LOCAL_CUSTOMERS.find({
        // }).then((customers) => {
        //         if (customers.total) {
        //             let customerData = customers.data;

        //             var recursive = function (index) {
        //                 let itemData = customerData[index],
        //                     id = itemData._id,
        //                     new_date_formatted = moment(itemData.date_purchased).format("MM/YYYY");

        //                 if (index > 0) {
        //                     LOCAL_CUSTOMERS.patch(id, {
        //                         date_purchased_formatted: new_date_formatted
        //                     })
        //                         .then(() => {
        //                             return recursive(index - 1)
        //                         })
        //                         .catch(() => {
        //                             return recursive(index - 1)
        //                         })
        //                 } else {
        //                     LOCAL_CUSTOMERS.patch(id, {
        //                         date_purchased_formatted: new_date_formatted
        //                     })
        //                         .then(() => {
        //                             // return recursive(index - 1)
        //                         })
        //                         .catch(() => {
        //                             // return recursive(index - 1)
        //                         })
        //                 }
        //             }

        //             recursive(customers.data.length - 1)
        //         }
        //     })
        // .catch((err) => {
        //     console.log(err)
        // })

        // LOCAL_SH_CUSTOMERS.find()
        //     .then((customers) => {
        //         if (customers.total) {
        //             let customerData = customers.data;

        //             var recursive = function (index) {
        //                 let itemData = customerData[index],
        //                     id = itemData._id,
        //                     new_date_formatted = moment(itemData.date_purchased).format("MM/YYYY");

        //                 if (index > 0) {
        //                     LOCAL_SH_CUSTOMERS.patch(id, {
        //                         date_purchased_formatted: new_date_formatted
        //                     })
        //                         .then(() => {
        //                             return recursive(index - 1)
        //                         })
        //                         .catch(() => {
        //                             return recursive(index - 1)
        //                         })
        //                 } else {
        //                     LOCAL_SH_CUSTOMERS.patch(id, {
        //                         date_purchased_formatted: new_date_formatted
        //                     })
        //                         .then(() => {
        //                             // return recursive(index - 1)
        //                         })
        //                         .catch(() => {
        //                             // return recursive(index - 1)
        //                             console.log('done')
        //                         })
        //                 }
        //             }

        //             recursive(customers.total - 1)
        //         }
        //     })
        // .catch((err) => {
        //     console.log(err)
        // })
    }

    gotoPage = (page) => {
        this.props.history.push(page)
    }

    render() {
        return (
            <div className="">
                <MainNav historyProp={this.props.history} userPermission={this.props.userPermission}
                         usersProp={this.props.userData} systemType="accounting"/>
                <div className="container-fluid">
                    <div className="row full-width">
                        <div className="sidebar">
                            <div className="sidebar-content">
                                <MainSideBar page="old_records"/>
                            </div>
                        </div>
                        <OldRecordsCustomer history={this.props.history}/>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    userData: state.login.userData,
});

function mapDispatchToProps(dispatch) {
    return {actions: bindActionCreators(Object.assign({}, LoginActions), dispatch)}
}

export default connect(mapStateToProps, mapDispatchToProps)(oldRecordsLandingPage);
