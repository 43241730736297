import React, { Component } from 'react';

// react-router
import { Link } from 'react-router-dom';

//redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// actions
import { ActionCreators } from '../../../actions';

//main NavBar
import MainNav from '../../../components/global_nav';
import MainSideBar from '../../../components/global_sidebar';

//sub side bar
import SubSideBar from './sub_sidebar'; 

//custom styles
import '../../../stylesheet/styles.css';


const $ = require('jquery');
$.DataTable = require('datatables.net');

class customer_cash extends Component {
 
  	constructor(props) {
        super(props);
    
        this.state = {
            data: []
        };
    }

    componentWillMount() {
        this.getAllCustomers();
    }

    componentDidMount() {
        $('.hide-view-customer').css('display', 'none', '!important')
        
        var that = this,
            applicationTbl = $('.tbl-customer-cash').DataTable({
            data: this.props.customers,
            "columnDefs": [
                {
                    "visible": false, "targets": 4
                },
                {
                    targets: [3],
                    orderable: false,
                    width: '10%'
                }
            ],
            columns: [
                { title: "NAME" },
                { title: "ACCOUNT NO." },
                { title: "BRANCH" },
                { title: "ACTION" }
            ],
            "sDom": '<"bottom"<t>ip><"clear">' 
        });


        $('.search').keyup(function(){
            console.log('asdas')
            applicationTbl.search($(this).val()).draw() ;
        });

        $('.tbl-customer-cash').on('click', 'button.view', function () {
          var data = applicationTbl.row( $(this).parents('tr') ).data(),
                name = (data[0]).replace(/ /g,"");
            that.props.actions.setCustomerInfo(data[4]);
            that.props.history.push('/customer/'+name)
        });
    }

    getAllCustomers = () => {
        var that = this;
        this.props.actions.getCashCustomers().then((data) => {
            this.props.actions.setCashCustomers(data);
            that.updateData(data);
        });
    }


    

    updateData = (data) => {
        this.setState({
            data: data
        });

        const table = $('.tbl-customer-cash').DataTable();
        table.clear();
        table.rows.add(data);
        table.draw();
    } 

  	render() {
    	return (
     	 	<div className="">  
                
     	 		{/* main NavBar component*/}
     	 		<MainNav historyProp={this.props.history} usersProp={this.props.userData} />

     	 		<div className="container-fluid">
     	 			<div className="row full-width">
	     	 			<div className="sidebar">
	     	 				<div className="sidebar-content">
	     	 					<MainSideBar page="customer"/>
	     	 				</div>
	     	 			</div>
                        <SubSideBar history={this.props.history} userPermission={this.props.userPermission} />
	     	 			<div className="sub-main-content">
	     	 				<div className="d-flex flex-row justify-content-left content-header">
                                 <input type="search" className="form-control form-control-lg align-items-center search" placeholder="&#xF002; Search" />
                            </div>
                            <div className="container-fluid">
                                <div className="application-container">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <table className="table tbl-customer-cash background-white tbl-mobile"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
	     	 			</div>
     	 			</div>
     	 		</div>
     	 	</div>
    	);
  	}
}

const mapStateToProps = state => ({
   customers: state.customers.customersCash,
   userData: state.login.userData,
});

function mapDispatchToProps(dispatch) {
   return { actions: bindActionCreators(ActionCreators, dispatch) }
}

export default connect(mapStateToProps, mapDispatchToProps)(customer_cash);
