import React, {Component} from 'react';

import app from '../../../helpers/feathers';
//redux
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
// actions
import {ActionCreators} from '../../../actions';

//main NavBar
import MainNav from '../../../components/global_nav';
import MainSideBar from '../../../components/acc_sidebar';
import SyncDataModal from '../../../components/sync_data_modal';

//custom sidebar
import SubSideBar from './sub_sidebar';

//select tag
import Select from 'react-select';
//toastr
import toastr from 'toastr';

import Tooltip from "react-simple-tooltip"

//daterange
import moment from 'moment';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import '../report/plugins/daterangepicker.css';

import EditFinancialModal from './edit_financial_modal';

//custom styles
import '../../../stylesheet/styles.css';

//datatables
import '../../../stylesheet/datatables/datatables.css';

const $ = require('jquery');
$.DataTable = require('datatables.net');

var g_branch = localStorage.getItem('_branch_secret'), that;
var _0x896d = ["\x74\x65\x73\x74\x32"];
var secret_user = _0x896d[0], secret_pass = _0x896d[0];

class financial_statement extends Component {

    constructor(props) {
        super(props);

        this.state = {
            showModal: false,
            product: this.props.product.product_list,
            products: [],
            product_count: 0,
            selectedOption: '',
            models: this.props.models,
            filter_model: null,
            filter_color: null,
            filter_branch: null,
            selectChanged: false,
            selectBranchChanged: false,
            selectedBranch: '',
            selectedBrand: '',
            selectedBrandChanged: false,
            selectBranch: [
                {value: localStorage.getItem('local_branch_id'), label: localStorage.getItem('local_branch_name')}
            ],
            modelsObj: [],
            startDate: moment().subtract(29, 'days'),
            endDate: moment(),
            drPickerApplied: false,
            selectedDaterange: [],
        };
    }

    componentWillMount() {

        that = this;

        this.getfinancial();


    }

    componentDidMount() {
        var ckr = [];
        this.props.branch.map(item => {
            ckr.push(item.value)
        })
        var branchExists = ckr.includes("all");
        if (!branchExists) {
            this.props.branch.unshift({
                value: 'all',
                label: 'All'
            })
        }

        var productTable = $('.tbl-financial').DataTable({
            data: this.state.products,
            columns: [
                {"title": "model object"},
                {"title": "NAME"},
                {"title": "BRANCH"},
                {"title": "DATE"},
                {"title": "ACTION"}
            ],
            columnDefs: [
                {
                    targets: [0],
                    visible: false
                },
                {
                    targets: 4,
                    orderable: false,
                    width: 80
                },
            ],
            "sDom": '<"bottom"<t>ip><"clear">',
            info: false,
        });

        $('.search').keyup(function () {
            productTable.search($(this).val()).draw();
        });

        $('.tbl-financial').on('click', '.btn-new-view', function () {
            var data = productTable.row($(this).parents('tr')).data();
            that.props.actions.financialInfo(data[5]);
            that.props.history.push('/view_financial')
        })

        $('.tbl-financial').on('click', 'button.btn-new-edit', function () {
            var data = productTable.row($(this).parents('tr')).data();
            that.props.actions.financialInfo(data[5]);
            $('#edit_customer').modal('show');
        });

        $('#edit_customer').on('hidden.bs.modal', function () {
            that.getfinancial();
        })
    }


    getfinancial = () => {
        this.props.actions.getfinancialStatement().then((data) => {

            if (data) {
                that.props.actions.financialInfo(data);
                that.updateData(data);
            } else {
                that.updateData([]);
            }
        });
    }


    updateData = (data) => {
        this.setState({
            data: data
        });

        const table = $('.tbl-financial').DataTable();
        table.clear();
        table.rows.add(data);
        table.draw();
    }


    userPermission = () => {
        // var position = localStorage.getItem('_position_secret');
        // this.props.actions.getUserPermissions(position)
        //     .then((data) => {
        //         if (data) {
        //             var permissions = data[0].permissions;
        //             that.props.actions.setUserPermissions(permissions);
        //             if (permissions.inventory > 0) {
        //                 $('.tbl-inventory').css('width', '100%');
        //                 // $('.tbl-inventory th:last').css('width','5%');
        //             }
        //         }
        //     })
    }

    permissionInventory = () => {
        var permission = this.props.userPermission;

        if (permission.inventory === 0) {
            return "not-visible"
        } else {
            return "";
        }
    }

    addFinancial = () => {
        // var permission = this.props.userPermission;
        //
        // if (permission.accounting === 2){
        this.props.history.push('/acc_create_financial_statement');
        // }else{
        //     return;
        // }
    }


    render() {

        return (
            <div className="">
                {/* main NavBar component*/}
                <MainNav historyProp={this.props.history} usersProp={this.props.userData} systemType="accounting" />
                <EditFinancialModal/>
                <SyncDataModal/>
                <div className="container-fluid">
                    <div className="row full-width">
                        <div className="sidebar">
                            <div className="sidebar-content">
                                <MainSideBar page="financial_statement"/>
                            </div>
                        </div>
                        {
                            /*<div className="row background-white"><h1>You have no access to this page</h1></div> */}

                        <div className="main-content">
                            <div className="container-fluid">
                                <div>
                                    <div className="row margin-20">
                                        <div className="col-md-12">
                                            <div className="row">
                                                <div className="col-md-10">
                                                    <h4>Financial Statement Report List</h4>
                                                </div>
                                                <div className="col-md-2">
                                                    <button className="btn btn-block btn-second-primary pull-right"
                                                            onClick={() => this.addFinancial()}>
                                                        <span className="fa fa-plus"/> Add
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row margin-20">
                                        <div className="col-md-6">
                                            <input className="form-control search" placeholder="Search financial statement" />
                                        </div>

                                        <div className="col-md-12">
                                            <br/>
                                        </div>
                                        <div className="col-md-12 background-white">
                                            <table className="table tbl-financial tbl-mobile"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    app: state.feathersClient.app,
    branch: state.category.branchesSelect,
    brandsSelect: state.category.brandsSelect,
    mainapp: state.feathersClient.mainapp,
    models: state.category.modelsSelect,
    product: state.product,
    selectmodellist: state.category.modelsSelect,
    userData: state.login.userData,
    userPermission: state.login.userPermission,
});

function mapDispatchToProps(dispatch) {
    return {actions: bindActionCreators(ActionCreators, dispatch)}
}

export default connect(mapStateToProps, mapDispatchToProps)(financial_statement);
