import React, { Component } from 'react';

//redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// actions
import { ActionCreators } from '../../../actions';

import {
    encodeToString,
    leadingZero,
} from '../../../helpers'

import app from '../../../helpers/feathers'

import toastr from 'toastr';

toastr.options.fadeOut = 2500;

const $ = require('jquery');

class delete_payment_modal extends Component {

	constructor(props) {
	  	super(props);
	  	this.state = {
	  	};
	}

	closeModal = () => {
		$('.modal').modal('hide');
	}

    deletePayment = () => {
        let that = this;
        let { payment_id } = this.props;

        app.service('customer-payments').remove(payment_id)
        .then(() => {
            toastr.success("Payment successfully deleted");
            $('.modal').modal('hide');
        })
        .catch(() => {
            toastr.error("Failed to delete payment. Please try again");
            that.closeModal();
        })

    }

  	render() {
    	return (
      		<div className="modal fade" id="delete_payment" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" data-backdrop="static">
			  	<div className="modal-dialog" role="document">
			    	<div className="modal-content">
			      		<div className="modal-header">
			        		<h5 className="modal-title" id="exampleModalLabel">Delete Payment</h5>
			        			<button type="button" className="close" data-dismiss="modal" aria-label="Close">
						          <span aria-hidden="true">&times;</span>
						        </button>
			      		</div>
				      	<div className="modal-body">
				        	<div className="form-group">
				        		<label>Are you sure you sure you want to delete this payment? </label><br />
				        	</div>
				      	</div>
				      	<div className="modal-footer">
					        	<button type="button" className="btn btn-secondary" data-dismiss="modal" ref="cancel">Cancel</button>
					        	<button type="button" className="btn btn-danger" onClick={this.deletePayment}>YES</button>
				      	</div>
			    	</div>
			  	</div>
			</div>
    	);
  	}
}


const mapStateToProps = state => ({
    payment_id: state.customers.payment_id,
   	session_id: state.login.userData._id
});

function mapDispatchToProps(dispatch) {
   return { actions: bindActionCreators(ActionCreators, dispatch) }
}

export default connect(mapStateToProps, mapDispatchToProps)(delete_payment_modal);