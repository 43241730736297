import React, {Component} from 'react';

//redux
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
// actions
import {ActionCreators} from '../../../actions';

//main NavBar
import MainNav from '../../../components/global_nav';
import MainSideBar from '../../../components/global_sidebar';

//select tag
import Select from 'react-select';
import 'react-select/dist/react-select.css';

import app from '../../../helpers/feathers';

//custom styles
import '../../../stylesheet/styles.css';

import moment from 'moment';

import {
    numberWithCommas
} from '../../../helpers/';

import ReactHTMLTableToExcel from 'react-html-table-to-excel';

// To create a client side application
// import feathers from 'feathers/client';
// const app = feathers();
import 'amcharts3';
import 'amcharts3/amcharts/serial';
import 'amcharts3/amcharts/plugins/responsive/responsive';

class for_bir extends Component {

    constructor(props) {
        super(props);

        this.state = {
            sales_per_branch: [],
            sales_per_branch_length: 0,
            top_selling_models: [],
            top_selling_models_length: 0,
            top_selling_branch: [],
            top_selling_branch_length: 0,
            customers_per_branch: [],
            customers_per_branch_length: 0,
            salesPerDay: [],
            unsold_units: [],
            unsold_units_length: 0,
            sold_units: [],
            bir: [],
            sold_units_length: 0,
            prev_year: moment().subtract(1, 'year').format('YYYY'),
            selectYear: ''
        };
    }

    componentWillMount() {
        app.service('branches').find({
            query: {
                branch_name: 'MAIN'
            }
        }).then((d) => {
            const mainbranchid = d.data[0]._id;
            this.props.actions.for_bir(mainbranchid)
                .then((res) => {
                    this.setState({
                        bir: res
                    });
                });
        })
        .catch((err) => {
            console.log(err)
        });
    }

    componentDidMount() {
    }

    permissionDashboard = () => {
        const permission = this.props.userPermission;

        if (permission.dashboard === 0) {
            return "not-visible"
        } else {
            return "";
        }
    }

    dtAddRow = () => {
    }

    multiarraySortDesc = (arr, columns, order_by) => {
        if (typeof columns == 'undefined') {
            columns = []
            for (let x = 0; x < arr[0].length; x++) {
                columns.push(x);
            }
        }

        if (typeof order_by == 'undefined') {
            order_by = []
            for (let x = 0; x < arr[0].length; x++) {
                order_by.push('ASC');
            }
        }

        function multisort_recursive(a, b, columns, order_by, index) {
            const direction = order_by[index] === 'DESC' ? 1 : 0;
            const is_numeric = !isNaN(a[columns[index]] - b[columns[index]]);
            const x = is_numeric ? a[columns[index]] : a[columns[index]].toLowerCase();
            const y = is_numeric ? b[columns[index]] : b[columns[index]].toLowerCase();

            if (x < y) {
                return direction === 0 ? -1 : 1;
            }

            if (x === y) {
                return columns.length - 1 > index ? multisort_recursive(a, b, columns, order_by, index + 1) : 0;
            }

            return direction === 0 ? 1 : -1;
        }

        return arr.sort(function (a, b) {
            return multisort_recursive(a, b, columns, order_by, 0);
        });
    }

    handleChangeSelectYear = (selectedOption) => {
        const value = selectedOption;
        if (value != null) {
            this.setState({
                selectYear: value,
            });
            app.service('branches').find({
                query: {
                    branch_name: 'MAIN'
                }
            }).then((d) => {
                const mainbranchid = d.data[0]._id;
                this.props.actions.for_bir(mainbranchid, value.value)
                    .then((res) => {
                        this.setState({
                            bir: res ? res : ''
                        });
                    });
            });
        }
    }

    render() {
        let overall_total_cost = 0;
        let tr;
        const bir_data = this.state.bir,
            ranking = bir_data ? this.multiarraySortDesc(bir_data, ['branch'], ['ASC', 'DESC']) : '';
            tr = <tr key={0}>
                <td>--</td>
                <td>--</td>
                <td>--</td>
                <td>--</td>
                <td>--</td>
                <td>--</td>
                <td>--</td>
                <td>--</td>
                <td>--</td>
                <td>--</td>
                <td>--</td>
                <td>--</td>
                <td>--</td>
                <td>--</td>
                <td>--</td>
                <td>--</td>
            </tr>;

        if (bir_data) {
            tr = ranking.map((a, i) => {
                let total_current_units = a.current_unsold_count + a.current_sold_count;

                return <tr key={i}>
                    <td>{a.branch}</td>
                    <td>{a.model}</td>
                    <td>{a.previous_unsold_count}</td>
                    <td>{total_current_units}</td>
                    <td></td>
                    <td></td>
                    <td>{a.current_sold_count}</td>
                    <td></td>
                    <td></td>
                    <td>{((a.previous_unsold_count + total_current_units) - a.current_sold_count)}</td>
                    <td>Unit</td>
                    <td style={{
                        textAlign: 'right',
                        paddingRight: 5
                    }}>{numberWithCommas(a.price ? a.price : 0) + '.00'}</td>
                    <td>Standard</td>
                    <td style={{textAlign: 'right', paddingRight: 5}}>
                        {numberWithCommas((a.price) * ((a.previous_unsold_count + total_current_units) - a.current_sold_count)) + '.00'}
                    </td>
                    <td>-</td>
                    <td>Parked</td>
                </tr>
            })

            bir_data.map((a) => {
                let total_current_units = a.current_unsold_count + a.current_sold_count;
                overall_total_cost += ((a.price) * ((a.previous_unsold_count + total_current_units) - a.current_sold_count));
            });
        }

        let {userPermission} = this.props,
            reportPermission = userPermission.report;

        let prev_year = this.state.selectYear ? (this.state.selectYear.value - 1) : this.state.prev_year;

        const initial_year = new Date().getFullYear();
        const start_year = initial_year + 1;
        const obj = [];

        for (let i = start_year; i > start_year - 75; i--) {
            obj.push({
                label: i,
                value: i,
            });
        }

        return (
            <div className="">
                {/* main NavBar component*/}
                <MainNav historyProp={this.props.history} usersProp={this.props.userData}/>
                <div className="container-fluid">
                    <div className="row full-width">
                        <div className="sidebar">
                            <div className="sidebar-content">
                                <MainSideBar page="report"/>
                            </div>
                        </div>
                        {
                            this.props.userPermission.dashboard === 0 ?
                                <div className="main-content inv-main-content">
                                    <div className="no-access-div">
                                        <div className="col-md-12">
                                            <h1>Access Denied</h1>
                                        </div>
                                    </div>
                                </div>
                                : null
                        }
                        <div className={this.permissionDashboard()}>
                            <div className="main-content inv-main-content">
                                <div className="col">
                                    <h4>Report For BIR </h4>
                                    <br/>
                                    <a href="#"
                                       onClick={() => this.props.history.goBack()}
                                       className="text-gray no-textdecoration breadcrumb-inv">
                                        <span className="fa fa-chevron-left"/> Reports
                                    </a>
                                </div>

                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="col-md-12">
                                            <br/>
                                        </div>
                                        <div className="padding-10">
                                            <div className="row col-md-12">
                                                <div className="col">
                                                    <Select
                                                        name="selectYear"
                                                        value={this.state.selectYear.value}
                                                        onChange={this.handleChangeSelectYear}
                                                        options={obj}
                                                        placeholder="Select Year"
                                                    />
                                                </div>
                                                <div className="col">
                                                    {
                                                        reportPermission !== 2 ? null :
                                                            <ReactHTMLTableToExcel
                                                                id="test-table-xls-button"
                                                                className=" float-right btn btn-primary download-table-xls-button"
                                                                table="table-to-xls"
                                                                filename="reportForBIR"
                                                                sheet="tablexls"
                                                                buttonText="Download as Excel File"
                                                            />
                                                    }
                                                </div>
                                                <div className="col-md-12">
                                                    <br/>
                                                </div>
                                                <div className="col-md-12 tbl-bir">
                                                    <table className="table-bordered tbl-excel background-white" id="table-to-xls">
                                                        <thead>
                                                        <tr>
                                                            <th></th>
                                                            <th></th>
                                                            <th></th>
                                                            <th></th>
                                                            <th></th>
                                                            <th></th>
                                                            <th></th>
                                                            <th></th>
                                                            <th></th>
                                                            <th></th>
                                                            <th></th>
                                                            <th></th>
                                                            <th></th>
                                                            <th></th>
                                                            <th></th>
                                                            <th></th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        <tr>
                                                            <td colSpan="16">BEMI LUZON MOTORCYCLE CORP. <br/> SCHEDULE
                                                                OF
                                                                INVENTORY<br/>{'FOR THE YEAR ENDED DECEMBER 31, ' + prev_year}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan="16"></td>
                                                        </tr>
                                                        <tr>
                                                            <td rowSpan="4">Location</td>
                                                            <td rowSpan="4">Description</td>
                                                            <td colSpan="8">QUANTITY</td>
                                                            <td rowSpan="4">Unit of Measurment</td>
                                                            <td rowSpan="4">Cost per Unit</td>
                                                            <td rowSpan="4">Costing Method</td>
                                                            <td rowSpan="4">Total Cost or Value</td>
                                                            <td rowSpan="4">Net Realizable Value</td>
                                                            <td rowSpan="4">Status</td>
                                                        </tr>
                                                        <tr>
                                                            <td rowSpan="3">Beg Balance</td>
                                                            <td colSpan="3">RECEIVED</td>
                                                            <td colSpan="3">REMOVED</td>
                                                            <td rowSpan="3">End Balance</td>
                                                        </tr>
                                                        <tr>
                                                            <td rowSpan="2">Purchased</td>
                                                            <td rowSpan="2">Manufactured</td>
                                                            <td rowSpan="2">Transferred In</td>
                                                            <td rowSpan="2">Sold</td>
                                                            <td rowSpan="2">Transferred Out</td>
                                                            <td rowSpan="2">Consignment</td>
                                                        </tr>
                                                        <tr>
                                                        </tr>
                                                        <tr>
                                                        </tr>
                                                        {tr}
                                                        <tr>
                                                            <td>&nbsp;</td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong>TOTAL</strong></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td style={{textAlign: 'right', paddingRight: 5}}>
                                                                <strong>{numberWithCommas(overall_total_cost) + '.00'}</strong>
                                                            </td>
                                                            <td></td>
                                                            <td></td>
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <br/>
                                <br/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    app: state.feathersClient.app,
    mainapp: state.feathersClient.mainapp,
    loginStatus: state.login.loginStatus,
    userData: state.login.userData,
    userPermission: state.login.userPermission,
});

function mapDispatchToProps(dispatch) {
    return {actions: bindActionCreators(ActionCreators, dispatch)}
}

export default connect(mapStateToProps, mapDispatchToProps)(for_bir);