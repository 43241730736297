import React, {Component} from "react";
import app from "../../../helpers/feathers";

//redux
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
// actions
import {ActionCreators} from "../../../actions";

//main NavBar
import MainNav from "../../../components/global_nav";
import MainSideBar from "../../../components/acc_sidebar";

//sub side bar
import SubSideBar from "./report_sub_sidebar";

//Select
import Select from "react-select";
import "react-select/dist/react-select.css";

//custom styles
import "../../../stylesheet/styles.css";

//datatables
import "../../../stylesheet/datatables/datatables.css";

const $ = require("jquery");
$.DataTable = require("datatables.net");

var that;

class computingPenalties extends Component {
    constructor(props) {
        super(props);

        this.state = {
            inventory_reports: []
        };
    }

    componentWillMount() {
        that = this;
        that.inventoryReports();
        app.service("reports").find().then(data => {
            console.log("reports ", data);
            const d = data.data;
            d.map(v => {
                // that.props.app.service('reports').remove(v._id)
            });
        });
    }

    componentDidMount() {
        that = this;
        that.inventoryReports();
        that.props.app
            .service("reports")
            .find()
            .then(data => {
                console.log("reports ", data);
                var d = data.data;
                d.map(v => {
                    // that.props.app.service('reports').remove(v._id)
                });
            });
    }

    componentDidMount() {
        let inventoryTbl = $(".computing_penalties_tbl").DataTable({
            columns: [
                {title: "DATE"},
                {title: "TITLE"},
                {title: "CREATED BY"},
                {title: ""},
                {title: ""}
            ],
            data: [],
            columnDefs: [
                {
                    targets: [0],
                    width: "20%"
                },
                {
                    targets: [1],
                    width: "40%"
                },
                {
                    targets: [2],
                    width: "30%"
                },
                {
                    targets: [3],
                    width: "15%",
                    orderable: false
                },
                {
                    targets: [4],
                    visible: false
                }
            ],
            sDom: '<"bottom"<t>ip><"clear">'
        });

        $(".search").keyup(function () {
            inventoryTbl.search($(this).val()).draw();
        });

        // view-report
        $(".computing_penalties_tbl").on("click", ".view-report", function () {
            const data = inventoryTbl.row($(this).parents("tr")).data();
            that.props.actions.viewReportData(data[4]);
            that.props.history.push("/acc_view_inventory_report");
        });
        app.service("reports").on("created", () => {
            that.inventoryReports();
        });
    }

    permissionAccounting = () => {
        const permission = this.props.userPermission;
        if (permission.accounting === 0) {
            return "not-visible";
        } else {
            return "";
        }
    };

    inventoryReports = () => {
        that.props.actions.getAllInventoryReports(0).then(data => {
            that.productList(data);
        });
    };

    productList = data => {
        this.setState({
            inventory_reports: data
        });

        const table = $(".schedule_of_payments").DataTable();
        table.clear();
        table.rows.add(data);
        table.draw();
    };

    createComputationOfPenalties = () => {
        // let {userPermission} = this.props,
        //     reportPermission = userPermission.accounting;
        //
        // if (reportPermission !== 2) {
        //     return;
        // }

        this.props.history.push("/acc_create_computation_penalties");
    };

    render() {
        return (
            <div className="">
                {/* main NavBar component*/}
                <MainNav
                    historyProp={this.props.history}
                    usersProp={this.props.userData}
                    systemType="accounting"
                />

                <div className="container-fluid">
                    <div className="row full-width">
                        <div className="sidebar">
                            <div className="sidebar-content">
                                <MainSideBar page="report"/>
                            </div>
                        </div>
                        <SubSideBar
                            history={this.props.history}
                        />
                        <div>
                            <div className="sub-main-content">
                                <div className="d-flex flex-row justify-content-left content-header">
                                    <input
                                        type="search"
                                        className="form-control form-control-lg align-items-center search"
                                        placeholder="&#xF002; Search"
                                    />
                                </div>
                                <div className="container-fluid">
                                    <div className="application-container">
                                        <div className="row">
                                            <div className="col-md-10">
                                                <h4>Computing of Penalties</h4>
                                            </div>
                                            <div className="col-md-2">
                                                <button
                                                    className="btn btn-block btn-primary"
                                                    onClick={() => this.createComputationOfPenalties()}
                                                >
                                                    Create
                                                </button>
                                            </div>
                                        </div>
                                        <div>
                                            <br/>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <table className="computing_penalties_tbl table background-white tbl-mobile"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    app: state.feathersClient.app,
    userData: state.login.userData,
    userPermission: state.login.userPermission
});

function mapDispatchToProps(dispatch) {
    return {actions: bindActionCreators(ActionCreators, dispatch)};
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(computingPenalties);
