import React, {Component} from 'react';

// react-router
import {Link} from 'react-router-dom';

//redux
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
// actions
import {ActionCreators} from '../../../actions';

import defaultImage from '../../../assets/img/default_avatar.jpg';

//toastr
import toastr from 'toastr';

import moment from 'moment';

//select tag
import Select from 'react-select';
import DatePicker from 'react-datepicker';

//main NavBar
import MainNav from '../../../components/global_nav';
import MainSideBar from '../../../components/acc_sidebar';

import ViewSchedule from './view_schedule_modal'
import ConfirmationModal from './confirmation_modal'

//sub side bar
import SubSideBar from './sub_sidebar';

import {
    encodeToString,
    leadingZero,
} from '../../../helpers'

import Autosuggest from 'react-autosuggest';
//custom styles
import '../../../stylesheet/styles.css';

//datatables
import '../../../stylesheet/datatables/datatables.css';
import app from "../../../helpers/feathers";

const $ = require('jquery');
$.DataTable = require('datatables.net');

var that;
var _0x896d = ["\x74\x65\x73\x74\x32"];
var secret_user = _0x896d[0], secret_pass = _0x896d[0]

class acc_add_customer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedModel: '',
            clickSubmit: false,
            selectedTerms: '',
            selectedTitle: '',
            selectedArea: '',
            selectedAreaChanged: false,
            selectedTermsChanged: false,
            selectedTitleChanged: false,
            submitting: false,
            selectedBranch: '',
            selectBranch: [
                {value: localStorage.getItem('local_branch_id'), label: localStorage.getItem('local_branch_name')}
            ],
            mobile_num: '',
            age: '',
            selectedBrand: '',
            selectedBrandChanged: false,
            selectedOption: '',
            selectChanged: false,
            chassisOption: '',
            chassisChanged: false,
            modelsObj: [],
            chassisObj: [],
            model: null,
            termsIsDisabled: false,
            areaOthers: false,
            date_purchased: moment(),
            first_payment_date: moment(),
            suggestions: [],
            value: '',
            showDiv: false,
            confirmed: false,
            models: this.props.models,
            modelPlaceholder: "Select Model",
            brandsSelectOptions: [],
        };

    }

    componentWillMount() {
        that = this;
        const _this = this;
        if (that.props.type === null) {
            // that.props.history.push('/customer')
        }

        function getProducts(){
            app.service("products").find({
                query: {
                    $limit: 1
                }
            }).then((productData) => {
                console.log('productData ',productData)
                if(productData.total){

                }
            }).catch((productError) => {
                console.log('productError ',productError )
            })
        }

        // getProducts();

        // app.authenticate({
        //     strategy: "local",
        //     username: "stratium",
        //     password: "unitb1ts",
        // }).then((data) => {
        //     console.log('data ',data)
            
        // }).catch((error) => {
        //     console.log('error ',error)
        // })
        // app.service('products').find({
        //     query: {
        //         $limit: 1
        //     }
        // }).then((e) => {
        //     console.log(e)
        //     let c = [];
        //     e.data.forEach(a => {
        //         if (a.branches.branch_name === _this.props.userData.branch_info.branch_name) {
        //             c.push(a);
        //             setTimeout(val => {
        //                 _this.brandOption(c);
        //             }, 250);
        //         }
        //     })
        // }).catch((error) => {
        //     console.log(error)
        // });
    }
    componentDidMount() {
        const _this = this;
        const { selectOptionBrands } = _this.props;
        const areaService = app.service('customer-area');
        // areaService.find()
        //     .then((e) => {
        //     }).catch((error) => {
        // });
        $('.hide-view-customer').css('display', 'none', '!important');
        const localBranch = {
            value: localStorage.getItem('local_branch_id'),
            label: localStorage.getItem('local_branch_name')
        };
        this.setState({selectedBranch: localBranch});
        $('#confirmation_modal').on('hidden.bs.modal', function () {
            that.setState({
                confirmed: false, submitting: false
            })
        })

        $('#confirmation_modal').on('shown.bs.modal', function () {
            $(".modal #submitForm").focus();
        });

        // set brands options in "Select" element
        _this.setState({brandsSelectOptions: selectOptionBrands})
    }

    handleChangeModel = (selectedOption) => {
        const value = selectedOption;
        this.setState({
            selectedModel: value ? value : ''
        });
    };

    handleChangeBranch = (selectedOption) => {
        const value = selectedOption === null ? '' : selectedOption;
        this.setState({
            selectedBranch: value
        });
    };

    branchOption = () => {
        let array = this.props.branchSelect;
        let newArray = [];
        array.map((obj) => {
            if (obj.value !== 'all') {
                newArray.push(obj)
            }
        });
        return newArray
    };


    showDiv = () => {
        that.setState({showDiv: true})
    };

    hideDiv = () => {
        that.setState({showDiv: false})
    };


    handleselectedTerms = (selectedOption) => {
        const value = selectedOption;
        if (value != null) {
            if (value.value === 0) {
                that.refs.terms.value = "";
                that.setState({
                    termsIsDisabled: true
                })
            } else {
                that.setState({
                    termsIsDisabled: false
                })
            }
            this.setState({
                selectedTerms: value,
                selectedTermsChanged: true,
            });
        } else {
            this.setState({
                selectedTerms: '',
                selectedTermsChanged: false,
                termsIsDisabled: true,
            });
        }
    };

    handleselectedTitle = (selectedOption) => {
        var value = selectedOption;
        if (value != null) {
            if (value.value) {
                this.setState({selectedTitle: value})
            }
        } else {
            this.setState({selectedTitle: '', selectedTitleChanged: false})
        }
    }


    handleSelectedArea = (selectedOption) => {
        var value = selectedOption;
        if (value != null) {
            that.refs.area.value = "";
            this.setState({
                selectedArea: value,
                selectedAreaChange: true,
            });
        } else {
            this.setState({
                selectedArea: '',
                selectedAreaChange: false,
                // areaOther : true,
            })
        }

    }

    areaOption = () => {
        const array = this.props.areaList;
        return array;
    }

    validateForm = (formData) => {
        const a = formData;
        if (formData.type === 1) {
            if (a.name !== '' &&
                a.unit !== '' &&
                // a.branch !== '' &&
                a.mobile !== '' &&
                a.tin !== '' &&
                a.address.present !== '' &&
                a.address.length_of_stay !== '' &&
                a.age !== '' &&
                a.employer.present !== '' &&
                a.months !== '' &&
                a.terms.down_payment !== '' &&
                a.terms.monthly_payment !== ''
            ) {
                return true;
            }
            return false;
        } else {

            if (a.name !== '' &&
                a.unit !== '' &&
                a.branch !== '' &&
                a.mobile !== '' &&
                a.tin !== '' &&
                a.address.present !== '' &&
                a.age !== '' &&
                a.payment !== '') {
                return true;
            }

            return false;
        }
    }


    redirectToModal = () => {


        let {customerInfo} = this.props;

        that.props.actions.setLedgerItemId(customerInfo);

        $('#view_sched_modal').modal('show');

    }


    confirmation = () => {
        this.confirm.click()
    }

    submitForm = () => {
        that.setState({confirmed: true});
        setTimeout(() => {
            that.handleAddCustomer();
        }, 500)
    }

    motorcycle_unit = (chassis_number) => {
        // this.state.selectedModel.label
        const o_chassis_number = [];
        that.props.mainapp.authenticate({
            strategy: 'local',
            username: secret_user,
            password: secret_pass,
        })
            .then(() => {
                app.service('products').find({
                    query: {
                        chassis_number: chassis_number
                    }
                })
                    .then((data) => {
                        if (data.total) {
                            var mainProdId = data.data[0]._id;
                            o_chassis_number.push(mainProdId)
                        }
                    })
            })
        return o_chassis_number;

        // console.log(chassis_number)
    }


    onChange = (event, {newValue}) => {
        this.setState({
            value: newValue
        });
    };


    handleAddCustomer = () => {
        let {chassisOption, selectedOption, selectedBranch, selectedTerms, selectedTitle, selectedModel, termsIsDisabled, date_purchased, selectedBrand, first_payment_date} = this.state;
        let {name, account_number, address, contact_number, payment, terms, promisory_note, remarks, area, or_number,} = this.refs;

        if (this.state.submitting) {
            toastr.info("Please wait while saving data");
            return;
        }
        let form = this.refs.customerForm,
            formData = {
                name: ((name.value).toUpperCase()).trim(),
                unit: chassisOption.unitId,
                unitPrice: chassisOption.unitPrice,
                unitName: selectedOption.label,
                // branchId: selectedBranch.value,
                title: selectedTitle.value,
                branch: this.props.userData.branch_info._id,
                // branch: this.props.userData.branch_info.branch_name,
                // branch: this.props.addCustBranch ? this.props.addCustBranch[0].value : '',
                term: (terms.value).trim(),
                payment: (payment.value).trim(),
                type: selectedTerms.value,
                account_number: termsIsDisabled ? '' : (account_number.value).trim(),
                customer_address: (address.value).trim(),
                contact_number: (contact_number.value).trim(),
                promisory_note: promisory_note.value,
                date_purchased: moment(date_purchased).format("MM/DD/YYYY"),
                date_purchased_formatted: moment(date_purchased).format("MM/YYYY"),
                remarks: remarks.value,
                area: this.state.selectedAreaChange ? this.state.selectedArea.label : area.value,
                unit_brand: selectedBrand.label,
                unit_model: selectedOption.label,
                unit_engine_number: chassisOption.label,
                first_payment_date: first_payment_date,
                or_number: or_number.value,
            };
        const i_chassis_number = selectedModel.chassis_number;
        if (chassisOption === '') {
            toastr.remove();
            toastr.info('Please select a unit');
            return;
        }

        if (!this.state.selectedTermsChanged) {
            toastr.remove();
            toastr.info('Please select payment method');
            return;
        }

        if (this.state.selectedTerms.value === 1) {
            if (!(this.refs.terms.value).trim()) {
                toastr.remove();
                toastr.info('Please enter terms');
                return;
            }else{
                if(isNaN(this.refs.terms.value)){
                    toastr.remove();
                    toastr.info('Please enter a numeric value for terms');
                    return;
                }
            }
        }

        if (this.state.selectedTerms.value === 1) {
            if (!(this.refs.account_number.value).trim()) {
                toastr.remove();
                toastr.info('Please enter account number');
                return;
            }
        }

        if (selectedTitle == '') {
            toastr.remove();
            toastr.info('Please select customer title');
            return;
        }

        if (!(this.refs.name.value).trim()) {
            toastr.remove();
            toastr.info('Please enter customer name');
            return;
        }

        if (!(this.refs.address.value).trim()) {
            toastr.remove();
            toastr.info('Please enter customer address');
            return;
        }

        if(!this.state.selectedAreaChange){
            if(area.value == ""){
                toastr.remove();
                toastr.info('Please select area');
                return;
            }
        }

        if (!(this.refs.payment.value).trim()) {
            toastr.remove();
            toastr.info('Please enter amount for payment');
            return;
        }

        if (isNaN((this.refs.payment.value).trim())) {
            toastr.remove();
            toastr.info('Please enter a valid amount for payment');
            return;
        } else {
            if ((this.refs.payment.value).trim() == 0) {
                toastr.remove();
                toastr.info('Please enter a valid amount for payment');
                return;
            } else {
                if (leadingZero((this.refs.payment.value).trim())) {
                    toastr.remove();
                    toastr.info('Please enter a valid amount for payment');
                    return;
                }
            }
        }

        if (this.state.selectedTerms.value === 1) {

            if (!(this.refs.promisory_note.value).trim()) {
                toastr.remove();
                toastr.info('Please enter promissory note');
                return;
            }else{
                if ((this.refs.promisory_note.value).trim() == 0) {
                    toastr.remove();
                    toastr.info('Please enter a valid amount for promissory note');
                    return;
                } else {
                    if (leadingZero((this.refs.promisory_note.value).trim())) {
                        toastr.remove();
                        toastr.info('Please enter a valid amount for promissory note');
                        return;
                    }
                }
            }
        }

        if((or_number.value).trim() === ''){
            toastr.remove();
            toastr.info("Please enter OR Number");
            return;
        }


        if (!this.state.confirmed) {
            that.confirmation();
            return
        }
        this.setState({submitting: true});
        this.props.actions.addCustomer(formData).then((data) => {
            if (data === 'BadRequest') {
                toastr.error('Please fill in required fields.');
                that.setState({submitting: false})
            }
            if (data.status === 'exists') {
                toastr.error('Account Number already exists');
                that.setState({submitting: false});
                that.clearUnitOption()
            } else if (data.status === 'success') {
                toastr.remove();
                toastr.success('Customer has been successfully added.');
                const custId = data.customer_id,
                    prodService = app.service('products'),
                    dateSold = moment().format('MM/DD/YYYY');

                let i = 0;

                that.props.actions.setNewCustomerData(data.data);
                $('#add_ledger .btn-primary').prop('disabled', true);

                let recursive = function (value) {
                    if (value > 0) {
                        let start_date = moment(data.data.date_purchased).add(1, 'months').format('MM/DD/YYYY'),
                            first_payment = data.data.first_payment_date,
                            due_date = moment(first_payment).add(i, 'months').format('MM/DD/YYYY'),
                            date_value = moment(first_payment).add(i, 'months').format('MM/YYYY'),
                            promisory_note = parseFloat(data.data.promisory_note),
                            balance = promisory_note;

                        i += 1;

                        let raw_data = {
                            customer_id: data.customer_id,
                            account_number: data.data.account_number,
                            area: data.data.area,
                            due_date: due_date,
                            date_value: date_value,
                            monthly_amortization: (balance / parseFloat(terms.value)),
                            balance: balance,
                            promisory_note: promisory_note,
                            branch: that.props.userData.branch_info._id,
                            added_by: that.props.session_id,
                            current: 1,
                        };
                        that.props.actions.insertToLedger(raw_data)
                            .then((result) => {
                                return recursive(value - 1);
                            })
                            .catch((e) => {
                                // console.log('error ', e)
                            })

                    } else {
                        that.props.actions.updateLedger(data.customer_id, 1).then((result) => {
                            // console.log('result ', result)
                        });

                        // toastr.success('Ledger successfully created!');
                        // $('#add_ledger').modal('hide');
                    }

                };

                recursive(terms.value)

                setTimeout(() => {
                    $('.hide-view-customer').css('display', 'block', '!important')
                    $("#confirmation_modal").modal("hide")
                    $('.modal .close-modal').click();
                    // that.props.history.push('/view_schedule/');
                    that.props.actions.setCustomerInfo(data.data)
                    that.props.history.push('/view_customer/');
                    // that.setState({submitting: false})
                }, 2000)
            } else {
                toastr.error('Network error. Please try again.');
                that.setState({submitting: false})
            }
        });
    }

    mobileNum = (evt) => {
        var allowed = [48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 16, 107, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105];

        var val = evt.target.value;

        if (val.length <= 13) {
            this.setState({
                mobileNum: val
            })
        } else {
            return;
        }
    }

    ageCharLimit = (evt) => {
        var val = evt.target.value;

        if (val.length <= 3) {
            this.setState({
                age: val
            })
        } else {
            return;
        }
    }

    brandOption = (designatedBrand) => {
        const array = this.props.brandsSelect,
            newArray = [];
        array.map((obj) => {
            if (obj.value !== 'all') {
                newArray.push(obj)
            }
        });
        return newArray
    };

    handleChangeBrand = (selectedOption) => {
        this.setState({
            selectedBrand: selectedOption
        });

        const value = selectedOption;

        if (value != null) {
            this.setState({
                selectedBrand: value,
                selectedBrandChanged: true,
                modelPlaceholder: 'Loading...'
            });
            const model = that.state.selectedOption.value,
                brand = value.value;
            that.props.actions.findChassisUsingBrand(brand)
                .then((d) => {
                    that.setState({
                        modelPlaceholder: 'Select Model',
                    })
                    if(d){
                        var models = d.data,
                            modelsObj = [];
    
                        models.map((v) => {
                            modelsObj.push({
                                label: v.model_name,
                                value: v._id
                            })
                        })
    
                        that.setState({
                            selectedOption: '',
                            chassisOption: '',
                            modelsObj: modelsObj,
                            chassisObj: []
                        })
                    }else{
                        that.setState({
                            modelPlaceholder: 'Select Model',
                        })
    
                        that.setState({
                            selectedOption: '',
                            chassisOption: '',
                            modelsObj: modelsObj,
                            chassisObj: []
                        })
                        // toastr.error("Failed to retrieve data. Please try again")
                    }
                })
        } else {
            that.setState({
                selectedBrand: '',
                selectedBrandChanged: false,
                modelsObj: [],
                selectedOption: '',
                chassisOption: '',
                chassisChanged: false,
                chassisObj: [],
            })
        }
    }

    modelOption = () => {
        var array = this.props.models,
            newArray = []

        array.map((obj) => {
            if (obj.value !== 'all') {
                newArray.push(obj)
            }
        })
        return newArray
    }

    handleChange = (selectedOption) => {
        const value = selectedOption;

        this.setState({
            chassisOption: '',
            chassisChanged: false
        })

        if (value != null) {
            this.setState({
                selectedOption: value,
                selectChanged: true,
            });
            that.props.actions.findChassisUsingModel(value.value)
                .then((d) => {
                    if(d){
                        const models = d.data,
                            chassisObj = [];
                        models.map((v) => {
                            chassisObj.push({
                                label: v.engine_number,
                                value: v.engine_number,
                                unitPrice: v.price ? v.price : 0,
                                unitId: v._id,
                            })
                        });

                        that.setState({
                            chassisObj: chassisObj
                        })
                    }
                })

        } else {
            this.setState({
                selectedOption: '',
                selectChanged: false,
                chassisOption: '',
                chassisChanged: false
            })
        }
    }

    handleChangeChassis = (selectedOption) => {
        const value = selectedOption;

        if (value != null) {
            this.setState({
                chassisOption: value,
                chassisChanged: true
            });

        } else {
            this.setState({
                chassisOption: '',
                chassisChanged: false,
            })
        }
    }

    clearUnitOption = () => {
        that.setState({
            selectedBrand: '',
            selectedBrandChanged: false,
            modelsObj: [],
            selectedOption: '',
            chassisOption: '',
            chassisChanged: false,
            chassisObj: [],
        })
    }
    handleDatePurchased = (date) => {
        this.setState({date_purchased: date})
    }
    handleFirstPaymentDate = (date) => {
        this.setState({first_payment_date: date})
    }

    back = () => {
        this.props.history.push('/acc_customer/');
    }


    render() {

        let name = this.refs.name ? this.refs.name.value : '';
        let showDiv = this.state.showDiv;
        const {value, suggestions,selectedTerms} = this.state;
        const inputProps = {
            placeholder: 'Search or Enter Area',
            value,
            onChange: this.onChange
        };
        const getSuggestionValue = suggestion => suggestion.name;

        // Use your imagination to render suggestions.
        const renderSuggestion = suggestion => (
            <div>
                {suggestion.name}
            </div>
        );
        
        const _this = this;
        let { brandsSelectOptions } = _this.state;
        return (
            <div className="">

                <MainNav historyProp={this.props.history}
                         usersProp={this.props.userData} systemType="accounting"/>
                <ViewSchedule name={name}/>
                <ConfirmationModal name={name} showDiv={this.showDiv} hideDiv={this.hideDiv}
                                   submitForm={this.submitForm}/>
                <div className="container-fluid">
                    <div className="row full-width">
                        <div className="sidebar">
                            <div className="sidebar-content">
                                <MainSideBar page="customer"/>
                            </div>
                        </div>
                        <SubSideBar history={this.props.history} path="/acc_add_customer"/>
                        <div className="sub-main-content">
                            <div className="container-fluid application-container">
                                <a href="#" onClick={() => this.props.history.push('/acc_customer/')}
                                   className="text-gray no-textdecoration">
                                    <span className="fa fa-chevron-left"/> Customer / Add Customer / Brand New Unit
                                </a>
                                <br/>
                                <br/>
                                <div className="">
                                    <div className="background-white padding-10">
                                        <form onSubmit={this.handleAddCustomer.bind(this)} ref="customerForm"
                                              id="needs-validation" noValidate>
                                            <div className="container-fluid">
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="row">
                                                            <div className="col">
                                                                <span>Brand</span>
                                                                <Select
                                                                    name="selectBrand"
                                                                    value={this.state.selectedBrand.value}
                                                                    onChange={this.handleChangeBrand}
                                                                    // options={this.brandOption()}
                                                                    options={brandsSelectOptions}
                                                                    clearableValue={true}
                                                                    placeholder="Select Brand"
                                                                />
                                                            </div>
                                                            <div className="col">
                                                                <span>Model</span>
                                                                <Select
                                                                    name="form-field-name"
                                                                    value={this.state.selectedOption}
                                                                    onChange={this.handleChange}
                                                                    options={this.state.modelsObj}
                                                                    placeholder={this.state.modelPlaceholder}
                                                                />
                                                            </div>
                                                            <div className="col">
                                                                <span>Engine Number</span>
                                                                <Select
                                                                    // className={this.state.selectedModel === '' && this.state.clickSubmit ? "error-select" : null}
                                                                    name="select-model"
                                                                    value={this.state.chassisOption.value}
                                                                    onChange={this.handleChangeChassis}
                                                                    options={this.state.chassisObj}
                                                                    clearableValue={true}
                                                                    placeholder="Select Engine Number"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <br/>
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="row">

                                                            <div className="col">
                                                                <span>Payment Method</span>
                                                                <Select
                                                                    name="select-model"
                                                                    value={this.state.selectedTerms.value}
                                                                    onChange={this.handleselectedTerms}
                                                                    options={[
                                                                        {value: 0, label: 'Cash'},
                                                                        {value: 1, label: 'Installment'},
                                                                    ]}
                                                                    clearableValue={true}
                                                                    placeholder="Select Payment Method"
                                                                />
                                                            </div>

                                                            <div className="col">
                                                                <span>Terms (if Installment) </span>
                                                                <input type="text" className="form-control" ref="terms"
                                                                       disabled={this.state.termsIsDisabled}/>
                                                            </div>


                                                            <div className="col">
                                                                <span>Account Number</span>
                                                                <input type="text" className="form-control"
                                                                       ref="account_number"
                                                                       disabled={this.state.termsIsDisabled}/>
                                                            </div>
                                                            <div className="col">
                                                                <span>Contact Number </span>
                                                                <input type="text" className="form-control"
                                                                       ref="contact_number" required/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <br/>
                                                <div className="row">
                                                    <div className="col-md-3">
                                                        <span>Title </span>
                                                        <Select
                                                            name="select-title"
                                                            value={this.state.selectedTitle.value}
                                                            onChange={this.handleselectedTitle}
                                                            options={[
                                                                {value: 'MS', label: 'MS'},
                                                                {value: 'MRS', label: 'MRS'},
                                                                {value: 'MR', label: 'MR'},
                                                                {value: 'N/A', label: 'N/A'},

                                                            ]}
                                                            clearableValue={true}
                                                            placeholder="MS/MRS/MR"
                                                        />
                                                    </div>
                                                    <div className="col">
                                                        <span>Customer Name </span>
                                                        <input type="text" className="form-control" ref="name"
                                                               required/>
                                                        <span><i>(Last name , First name , MI)</i></span>
                                                    </div>
                                                </div>
                                                <br/>
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="row">
                                                            <div className="col">
                                                                <span>Customer Address </span>
                                                                <input type="text" className="form-control"
                                                                       ref="address" required/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <br/>
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="row">
                                                            <div className="col">
                                                                <span>Area</span>
                                                                <Select
                                                                    name="select-area"
                                                                    value={this.state.selectedArea.value}
                                                                    onChange={this.handleSelectedArea}
                                                                    options={this.areaOption()}
                                                                    clearableValue={true}
                                                                    placeholder="Select Area"
                                                                />
                                                            </div>
                                                            <div className="col">
                                                                <span>If other area (please specify)</span>
                                                                <input type="text" className="form-control" ref="area"
                                                                       disabled={this.state.selectedAreaChange}/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <br/>
                                                <div className="row">
                                                    <div className="col-md-12"/>
                                                </div>
                                                <br/>
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="row">
                                                            <div className="col">
                                                                <span>{this.state.termsIsDisabled ? "Amount" : "Down Payment"} </span>
                                                                <input type="text" className="form-control"
                                                                       ref="payment"/>
                                                            </div>
                                                            <div className="col">
                                                                <span>Promissory Note</span>
                                                                <input type="number" className="form-control"
                                                                       ref="promisory_note"
                                                                       disabled={this.state.termsIsDisabled}/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <br/>
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="row">
                                                            <div className="col">
                                                                <span>Date Purchased</span>
                                                                <DatePicker
                                                                    selected={this.state.date_purchased}
                                                                    onChange={this.handleDatePurchased}
                                                                    peekNextMonth
                                                                    showMonthDropdown
                                                                    showYearDropdown
                                                                    dropdownMode="select"
                                                                    className="form-control"
                                                                />
                                                            </div>
                                                            <div className="col">
                                                                <div style={{display: !selectedTerms.value ? 'none' : 'block'}}>
                                                                <span>Date of First Payment</span>
                                                                <DatePicker
                                                                    selected={this.state.first_payment_date}
                                                                    onChange={this.handleFirstPaymentDate}
                                                                    peekNextMonth
                                                                    showMonthDropdown
                                                                    showYearDropdown
                                                                    dropdownMode="select"
                                                                    className="form-control"
                                                                />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <br/>
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="row">
                                                            <div className="col">
                                                                <span>OR Number</span>
                                                                <input type="text" className="form-control"
                                                                       ref="or_number"/>
                                                            </div>
                                                            <div className="col">
                                                                <span>Remarks</span>
                                                                <input type="text" className="form-control"
                                                                       ref="remarks"/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <br/>

                                                <div className="row">
                                                    <br/>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-3"/>
                                                    <div className="col-md-6">
                                                        <div className="row">
                                                            <div className="col">
                                                                <button className="btn btn-block btn-primary"
                                                                        type="button" onClick={this.handleAddCustomer}>
                                                                    <span className="fa fa-save"/> SAVE
                                                                </button>
                                                                <a className="conf-modal btn-danger" ref={input => this.confirm = input} data-toggle="modal" data-target="#confirmation_modal"></a>
                                                            </div>
                                                            <div className="col">
                                                                <button className="btn btn-block btn-danger" id="send"
                                                                        type="button" onClick={this.back}>
                                                                    <span className="fa fa-remove"/> CANCEL
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3"/>
                                                </div>
                                                <div className="row">
                                                    <br/>
                                                    <br/>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


const mapStateToProps = state => ({
    // app: state.feathersClient.app,
    mainapp: state.feathersClient.mainapp,
    customerInfo: state.customers.customerInfo,
    customer: state.customers,
    productSelect: state.product.productSelect,
    branchSelect: state.category.branchesSelect,
    models: state.category.modelsSelect,
    add_cust_model: state.category.add_cust_model,
    brandsSelect: state.category.brandsSelect,
    type: state.customers.type,
    userData: state.login.userData,
    session_id: state.login.userData._id,
    addCustBranch: state.customers.addCustBranch,
    areaList: state.customers.areaList,
    selectOptionBrands: state.category.selectOptionBrands,
});

function mapDispatchToProps(dispatch) {
    return {actions: bindActionCreators(ActionCreators, dispatch)}
}

export default connect(mapStateToProps, mapDispatchToProps)(acc_add_customer);
