import React, {Component} from 'react';

//redux
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
// actions
import {ActionCreators} from '../../../actions';

import moment from 'moment';

//main NavBar
import MainNav from '../../../components/global_nav';
import MainSideBar from '../../../components/acc_sidebar';

//custom styles
import '../../../stylesheet/styles.css';

// To create a client side application
// import feathers from 'feathers/client';
// const app = feathers();
import 'amcharts3';
import 'amcharts3/amcharts/serial';
import 'amcharts3/amcharts/plugins/responsive/responsive';
import AmCharts from '@amcharts/amcharts3-react';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import AddPaymentModal from './add_payment_modal';

import app from '../../../helpers/feathers';

//datatables
import '../../../stylesheet/datatables/datatables.css';

const $ = require('jquery');
$.DataTable = require('datatables.net');

var that;

class accounts_payable extends Component {

    constructor(props) {
        super(props);

        this.state = {
            payments: [],
            table_data: [],
            count: 0,
            filter_date_paid: moment()
        };
    }

    componentWillMount() {
        that = this;
        that.getPaymentsList();
        that.table_data();
    }

    remove = () => {
        // app.service('payment').find()
        // .then((data) => {
        //     var d = data.data;

        //     d.map((v) => {
        //         app.service('payment').remove(v._id)
        //     })
        // })
    }

    componentDidMount() {

        var dt_table = $('.tbl-accounts-payable').DataTable({
            data: this.state.table_data,
            columns: [
                {"title": "DEALER CODE"},
                {"title": "DEALER NAME"},
                {"title": "INVOICE NUMBER"},
                {"title": "INVOICE DATE"},
                {"title": "D.R. NUMBER"},
                {"title": "D.R. DATE"},
                {"title": "MODEL"},
                {"title": "QTY"},
                {"title": "PRICE"},
            ],
            columnDefs: [
                {
                    targets: [0],
                    visible: false
                }
            ],
            info: false,
            "sDom": '<"bottom"<t>ip><"clear">'
        });

        var paymentService = app.service('payment');

        paymentService.on('created', function () {
            that.getPaymentsList();
        })
        paymentService.on('removed', function () {
            that.getPaymentsList();
        })
    }

    table_data = (data) => {
        that.props.actions.getAccountsPayable().then(data => {
            if(data){
            that.productList(data.data);
            }
        });
    };


    productList = data => {
        this.setState({
            table_data: data
        });

        const table = $(".tbl-accounts-payable").DataTable();
        table.clear();
        table.rows.add(data);
        table.draw();
    };


    getPaymentsList = () => {
        that.props.actions.getPaymentsList()
            .then((data) => {
                // that.update_table(data);
            })
    }


    update_table = (data) => {
        this.setState({
            payments: data
        });

        const table = $('.tbl-accounts-payable').DataTable();
        table.clear();
        table.rows.add(data);
        table.draw();

    }

    clickModal = () => {
        var permission = this.props.userPermission;

        if (permission.inventory != 2) {
            return;
        }

        this.refs.add_payment.click();
    }

    handleDatePaid = (date) => {
        this.setState({
            filter_date_paid: date
        })
    }

    filter_payment = () => {
        var filter_date = this.state.filter_date_paid;
        var query = {
            date_paid: moment(filter_date).format('MM/DD/YYYY')
        };

        that.props.actions.filterPaymentsList(query)
            .then((data) => {
                that.update_table(data)
            })
    }
    permissionInventory = () => {
        var permission = this.props.userPermission;

        if (permission.inventory === 0) {
            return "not-visible"
        } else {
            return "";
        }
    }

    render() {
        let {history, userData, userPermission} = this.props,
            {promisory_note, brandNew, terms} = this.state;
        return (
            <div className="">
                {/* main NavBar component*/}
                <MainNav historyProp={history} usersProp={userData} systemType="accounting"/>
                <AddPaymentModal/>

                <div className="container-fluid">
                    <div className="row full-width">
                        <div className="sidebar">
                            <div className="sidebar-content">
                                <MainSideBar page="accounts_payable"/>
                            </div>
                        </div>
                        {/*<div className={this.permissionInventory()}>*/}
                        {/*<div className={this.props.userData.user_position_info.position_type !== 'ADMINISTRATOR' ? "not-visible" : ""}>*/}

                        <div>
                            <div className="main-content">
                                <span className="text-gray">Accounting / Accounts Payable</span>
                                <h4>Accounts Payable
                                    {
                                        // <span className="fa fa-remove" onClick={() => this.remove()}/>
                                    }
                                </h4>
                                <br/>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="row">
                                            <br/>
                                        </div>
                                        <div>
                                            <table className="table tbl-accounts-payable tbl-mobile" style={{width: '100%'}}/>
                                        </div>
                                    </div>
                                </div>
                                <br/>
                                <br/>
                                <br/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


const mapStateToProps = state => ({
    app: state.feathersClient.app,
    loginStatus: state.login.loginStatus,
    userData: state.login.userData,
    userPermission: state.login.userPermission,
});

function mapDispatchToProps(dispatch) {
    return {actions: bindActionCreators(ActionCreators, dispatch)}
}

export default connect(mapStateToProps, mapDispatchToProps)(accounts_payable);
