import React, { Component } from 'react';
import app from "../../../helpers/feathers";
//redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// actions
import { ActionCreators } from '../../../actions';

import {
    encodeToString,
    leadingZero,
} from '../../../helpers'

import toastr from 'toastr';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const $ = require('jquery');

var _0x896d=["\x74\x65\x73\x74\x32"];var secret_user=_0x896d[0],secret_pass=_0x896d[0]

class update_ledger_modal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            date_paid: moment()
        };
    }

    closeModal = () => {
        $('.modal').modal('hide');
    };

    deleteReport = () =>{
        let id = this.props.deleteUnsoldReport, that = this;
        app.service('reports').remove(id)
            .then(() => {
                toastr.success('Report has been deleted');
                that.closeModal();
            }).catch((err)=>{
            toastr.success('Report not deleted');
            that.closeModal();
        })
    };

    render() {
        return (
            <div className="modal fade bd-example-modal-sm" id="delete_modal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" ref="add_ledger" data-backdrop="static">
                <div className="modal-dialog modal-sm" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel"/>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <h5>Are you sure you want to delete this report ? </h5>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal" ref="cancel">Cancel</button>
                            <button type="button" className="btn btn-primary" onClick={this.deleteReport}>OK</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


const mapStateToProps = state => ({
    category: state.category,
    deleteUnsoldReport : state.reports.deleteUnsoldReport,
    app: state.feathersClient.app,
    mainapp: state.feathersClient.mainapp,
    customer: state.customers.customerLedgerInfo,
    customerInfo: state.customers.customerInfo,
    session_id: state.login.userData._id,
    ledgerItemId: state.customers.ledgerItemId,
    remainingBalance: state.customers.remainingBalance,
});

function mapDispatchToProps(dispatch) {
    return { actions: bindActionCreators(ActionCreators, dispatch) }
}

export default connect(mapStateToProps, mapDispatchToProps)(update_ledger_modal);
