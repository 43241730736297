import React, {Component} from 'react';

//redux
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
// actions
import {ActionCreators} from '../../../actions';

//select tag
import Select from 'react-select';
import 'react-select/dist/react-select.css';

//main NavBar
import MainNav from '../../../components/global_nav';
import MainSideBar from '../../../components/global_sidebar';

import moment from 'moment';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import '../report/plugins/daterangepicker.css';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import app from '../../../helpers/feathers';

//custom sidebar
import SubSideBar from './sub_sidebar';

import toastr from 'toastr';

const $ = require('jquery');

toastr.options = {
    "positionClass": "toast-bottom-right"
}

let that;

class add_inventory extends Component {
    constructor(props) {
        super(props);

        this.state = {
            brand: null,
            model: null,
            engine_number: '',
            chassis_number: '',
            color: '',
            date_delivered: moment(),
            location: null,
            delivery_receipt_number: '',
            delivery_receipt_date: moment(),
            invoice_number: null,
            invoice_date: moment(),
            list_price: '',
            price: '',
            saveTemplate: false,
            models: this.props.models,
            selectedOption: '',
            selectChanged: false,
            // selectedBranch: this.props.userData.branch_info.branch_name == 'MAIN' ? '' : {
            //     label: this.props.userData.branch_info.branch_name,
            //     value: this.props.userData.branch_info._id
            // },
            selectedBranch: {
                label: this.props.userData.branch_info.branch_name,
                value: this.props.userData.branch_info._id
            },
            selectedBranchChanged: false,
            selectedBrand: '',
            selectedBrandChanged: false,
            warranty_claims: false,
            clearances: false,
            tba: false,
            second_hand: false,
            modelsObj: [],
            headOfficeId: '',
            isSubmitting: false,
        };
    }

    componentWillMount() {

        // const models = [],
        // 	that = this;

        // this.props.actions.getCategoryModels().then((data) => {
        //           that.props.actions.setModels(data);
        //       });

        //       if(this.props.models){
        // 	this.props.models.forEach((value, index) => {
        // 		models.push({
        // 			value: value[0],
        // 			label: value[2]
        // 		})
        // 	});
        //       }

        // this.setState({
        // 	models: models
        // })

        this.getHeadBranchInfo();
    }

    componentDidMount() {
        that = this;

        // const position = localStorage.getItem('_position_secret')

        // app.service('permission').on('patched', function(){
        // 	that.props.actions.getUserPermissions(position)
        //  		.then((data) => {
        //  			if (data){
        //  				const permissions = data[0].permissions;
        //  				that.props.actions.setUserPermissions(permissions);
        //  			}
        //  		})
        // 		})
    }

    getHeadBranchInfo = () => {
        let headOffice = this.mainBranchOption(true);

        this.setState({headOfficeId: headOffice})
    }

    hasWhiteSpace = (s) => {
        if (s != null) {
            return s.indexOf(' ') >= 0;
        }
    }

    singleToast = (text) => {
        toastr.remove();
        toastr.error(text)
    }

    clearInputs = () => {
        this.setState({
            selectedBrand: '',
            model: null,
            engine_number: null,
            chassis_number: null,
            color: null,
            // date_delivered: null,
            location: null,
            delivery_receipt_number: null,
            // delivery_receipt_date: null,
            invoice_number: null,
            // invoice_date: null,
            price: null,
            selectedOption: '',
            // selectedBranch: '',
        })

        // this.refs.brand.value = '';
        // this.refs.model.value = '';
        this.refs.engine_number.value = '';
        this.refs.chassis_number.value = '';
        this.refs.color.value = '';
        // this.refs.date_delivered.value = '';
        // this.refs.location.value = '';
        this.refs.delivery_receipt_number.value = '';
        // this.refs.delivery_receipt_date.value = '';
        this.refs.invoice_number.value = '';
        // this.refs.invoice_date.value = '';
        this.refs.price.value = '';
    }

    saveTemplate = () => {
        if (this.state.saveTemplate) {
            this.setState({saveTemplate: false})
        } else {
            this.setState({saveTemplate: true})
        }
    }

    saveAndAdd = (saveAndClose) => {
        const that = this;
        const brand = this.state.selectedBrand,
            model = this.state.model,
            selectedmodel = this.state.selectedOption,
            engine_number = this.state.engine_number,
            chassis_number = this.state.chassis_number,
            color = this.state.color,
            date_delivered = this.state.date_delivered,
            location = this.state.selectedBranch ? this.state.selectedBranch : this.props.userData.branch_info,
            delivery_receipt_number = this.state.delivery_receipt_number,
            delivery_receipt_date = this.state.delivery_receipt_date,
            invoice_number = this.state.invoice_number,
            invoice_date = this.state.invoice_date,
            price = this.state.price,
            saveTemplate = that.state.saveTemplate,
            warranty_claims = that.state.warranty_claims ? 1 : 0,
            clearances = that.state.clearances ? 1 : 0,
            tba = that.state.tba ? 1 : 0,
            second_hand = that.state.second_hand ? 0 : 1;

        const permission = this.props.userPermission;

        if (permission.inventory !== 2) {
            return;
        }

        const trapper = {
            query: {
                chassis_number: chassis_number
            }
        }

        const data = {
            brand: brand.value,
            model: selectedmodel ? selectedmodel.value : null,
            engine_number: engine_number ? engine_number.toUpperCase() : '',
            chas: chassis_number ? chassis_number.toUpperCase() : '',
            color: color ? color ? color.toUpperCase() : '' : '',
            branch: location.value,
            delivery: {
                date: date_delivered,
                // location: that.mainBranchOption(true),
                location: "MAIN",
                receipt_number: delivery_receipt_number ? delivery_receipt_number : '--',
                receipt_date: delivery_receipt_number ? delivery_receipt_date : '--',
            },
            receipt_number: delivery_receipt_number ? delivery_receipt_number : '--',
            invoice: {
                invoice_number: invoice_number ? invoice_number.trim() : '',
                date: invoice_number ? invoice_date : '',
            },
            price: price ? price.trim() : '',
            warranty_claims: warranty_claims,
            clearances: clearances,
            tba: tba,
            date_created: moment().format('MM/DD/YYYY'),
            origin_id: 'MAIN' + Date.now(),
            brandNew: second_hand,
            year_added: moment(date_delivered).format('YYYY'),
            unit_brand_name: brand.label,
            unit_model_name: selectedmodel.label,
            unit_engine_number: engine_number ? engine_number.toUpperCase() : ''
        }

        if (brand === '') {
            this.singleToast('Please select a brand');
            $('[name="selectBrand"]').focus();
        } else if (selectedmodel === '') {
            this.singleToast('Please select a model');
            $('[name="model"]').focus();
        } else if (engine_number === '') {
            this.singleToast('Please enter engine number');
            $('[name="engine_number"]').focus();
        } else if (chassis_number === '') {
            this.singleToast('Please enter chassis number');
            $('[name="chassis_number"]').focus();
        } else if (color === '') {
            this.singleToast('Please enter color');
            $('[name="color"]').focus();
        } else if (date_delivered == null) {
            this.singleToast('Please select date');
            $('[name="date_delivered"]').focus();
        }
        /*else if (location === '') {
            this.singleToast('Please select branch');
            // $('[name="date_delivered"]').focus();
        }
        else if (delivery_receipt_number === '') {
            this.singleToast('Please enter delivery receipt number');
            $('[name="delivery_receipt_number"]').focus();
            return;
        }else if (delivery_receipt_date == null) {
            this.singleToast('Please select delivery receipt date');
            $('[name="delivery_receipt_date"]').focus();
        }
        */
        // else if (price === '') {
        // 	this.singleToast('Please enter price')
        // 	$('[name="price"]').focus();
        // 	return;
        // }
        else {
            const { isSubmitting } = that.state;
            if(isSubmitting) return;
            that.setState({isSubmitting: true});
            app.service('products').find({
                paginate: {
                    default: 10,
                    max: 50
                },
                query: {
                    engine_number: data.engine_number,
                    model: data.model,
                    $limit: 10,
                }
            }).then((d) => {
                    if (d.total) {
                        toastr.remove();
                        toastr.error('Engine Number ' + engine_number.toUpperCase() + ' already exists');
                        that.setState({isSubmitting: false});
                    } else {
                        app.service('products').find({
                            paginate: {
                                default: 10,
                                max: 50
                            },
                            query: {
                                chas: data.chas,
                                model: data.model,
                                $limit: 10,
                            }
                        }).then((dd) => {
                                if (dd.total) {
                                    toastr.remove();
                                    toastr.error('Chassis Number ' + chassis_number.toUpperCase() + ' already exists');
                                    that.setState({isSubmitting: false});
                                } else {
                                    app.service('products').create(data).then((result) => {
                                        toastr.remove();
                                        toastr.success('Product successfully added');
                                        that.setState({isSubmitting: false});
                                        if (saveAndClose) {
                                            if (this.state.saveTemplate) {
                                                this.setState({
                                                    chassis_number: '',
                                                    engine_number: ''
                                                })

                                                this.refs.engine_number.value = '';
                                                this.refs.chassis_number.value = '';
                                            } else {
                                                this.clearInputs();
                                            }
                                        } else {
                                            this.clearInputs()
                                            setTimeout(() => {
                                                this.props.history.push('inventory');
                                            }, 1000)
                                        }
                                    }).catch((err) => {
                                        console.log('create prod error ', err)
                                        toastr.remove();
                                        toastr.error('An error occurred!. Please try again');
                                        that.setState({isSubmitting: false});
                                    })
                                }
                            })
                        .catch((e) => {
                            console.log(e)
                            toastr.remove();
                            toastr.error('An error occurred!. Please try again');
                            that.setState({isSubmitting: false});
                        })
                    }
                })
            .catch((e) => {
                console.log(e)
                toastr.remove();
                toastr.error('An error occurred!. Please try again');
                that.setState({isSubmitting: false});
            })
        }
    }

    branchOption = () => {
        const array = this.props.branch,
            newArray = []
        array.map((obj) => {
            if (obj.value !== 'all') {
                newArray.push(obj)
            }
        })
        return newArray
    }

    modelOption = () => {
        const array = this.props.models,
            newArray = []

        array.map((obj) => {
            if (obj.value !== 'all') {
                newArray.push(obj)
            }
        })
        return newArray
    }

    brandOption = () => {
        const array = this.props.brandsSelect,
            newArray = []

        array.map((obj) => {
            if (obj.value !== 'all' && obj.branch !== 'MAIN') {
                newArray.push(obj)
            }
        })

        return newArray
    }

    handleChange = (selectedOption) => {
        const value = selectedOption;

        if (value != null) {
            this.setState({
                selectedOption: value,
                selectChanged: true
            });
        } else {
            this.setState({
                selectedOption: '',
                selectChanged: false
            })
        }
    }

    handleChangeBranch = (selectedOption) => {
        const value = selectedOption;
        if (value != null) {
            this.setState({
                selectedBranch: value,
                selectedBranchChanged: true
            });
        } else {
            this.setState({
                selectedBranch: '',
                selectedBranchChanged: false
            })
        }
    }

    handleChangeBrand = (selectedOption) => {
        const value = selectedOption;

        if (value != null) {
            this.setState({
                selectedBrand: value,
                selectedBrandChanged: true
            });
            const model = that.state.selectedOption.value,
                brand = value.value === 'all' ? null : value.value;

            that.props.actions.findChassisUsingBrand(brand)
                .then((d) => {
                    const models = d.data,
                        modelsObj = [];

                    models.map((v) => {
                        modelsObj.push({
                            label: v.model_name,
                            value: v._id
                        })
                    })

                    that.setState({
                        selectedOption: '',
                        modelsObj: modelsObj,
                    })
                })
        } else {
            that.setState({
                selectedBrand: '',
                selectedBrandChanged: false,
                modelsObj: [],
                selectedOption: '',
            })
        }
    }

    permissionInventory = () => {
        const permission = this.props.userPermission;

        if (permission.inventory === 0) {
            return "not-visible"
        } else {
            return "";
        }
    }

    mainBranchOption = (value = false) => {
        const array = this.props.branch, newArray = [];
        array.map((obj) => {
            if (obj.label === 'MAIN') {
                newArray.push(obj)
            } else {
                newArray.push(obj);
            }
        });
        return value ? newArray[0].value : newArray;
    }

    defaultBranch = (value) => {
        const userBranch = this.props.userData.branch;
        const array = this.props.branch, newArray = [];

        array.map((obj) => {
            if (userBranch === obj.value) {
                newArray.push({'branch': obj});
                const disable = obj.label !== 'MAIN';
                newArray.push({'isDisabled': disable})
            }
        });

        return value === 'getValue' ? newArray[0].branch.value : newArray[1].isDisabled;
    }

    handleDateDelivered = (date) => {
        // const d = p.startDate;
        // const formatted = moment(d).format('MM/DD/YYYY');
        // const date = moment(d);
        this.setState({
            date_delivered: date
        })
    }

    handleInvoiceDate = (date) => {
        this.setState({
            invoice_date_changed: true,
            invoice_date: date
        })
    }

    // handleDateDelivered = (e, p) => {
    // 	const d = p.startDate;
    // 	const formatted = moment(d).format('MM/DD/YYYY');

    // 	this.setState({
    // 		date_delivered: formatted
    // 	})
    // }

    // handleInvoiceDate = (e,p) => {
    // 	const d = p.startDate;
    // 	const formatted = moment(d).format('MM/DD/YYYY');

    // 	this.setState({
    // 		invoice_date: formatted
    // 	})
    // }

    // handleDeliveryReceiptDate = (e,p) => {
    // 	const d = p.startDate;
    // 	const formatted = moment(d).format('MM/DD/YYYY');

    // 	this.setState({
    // 		delivery_receipt_date: formatted
    // 	})
    // }


    handleDeliveryReceiptDate = (date) => {
        this.setState({
            delivery_receipt_date: date
        })
    }

    warranty_change = (e) => {
        const val = e.target.checked;
        that.setState({
            warranty_claims: val
        })
    }

    tba_change = (e) => {
        const val = e.target.checked;
        that.setState({
            tba: val
        })
    }

    clearances = (e) => {
        const val = e.target.checked;
        that.setState({
            clearances: val
        })
    }

    second_hand = (e) => {
        const val = e.target.checked;
        that.setState({
            second_hand: val
        })
    }

    keyPress = (event) => {
        console.log('--')
        console.log(event)
    }


    render() {
        return (
            <div className="">
                {/* main NavBar component*/}
                <MainNav historyProp={this.props.history} usersProp={this.props.userData}/>
                <div className="container-fluid">
                    <div className="row full-width">
                        <div className="sidebar">
                            <div className="sidebar-content">
                                <MainSideBar page="inventory"/>
                            </div>
                        </div>
                        <SubSideBar history={this.props.history} userPermission={this.props.userPermission}/>
                        <div className="sub-inv-main-content add_inventory">
                            <div className="application-container">
                                {
                                    this.props.userPermission.inventory === 0 ?
                                        <div className="row no-access-div">
                                            <div className="col-md-12">
                                                <h1>Access denied</h1>
                                            </div>
                                        </div>
                                        : null
                                }
                                <div className={this.permissionInventory()}>
                                    <div className="row margin-y-24">
                                        <div className="col-md-12">
                                            <a href="#"
                                               onClick={() => this.props.history.goBack()}
                                               className="text-gray no-textdecoration breadcrumb-inv">
                                                <span className="fa fa-chevron-left"/> Inventory / Add Unit
                                            </a>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <h4 className="subheader-title">Add Inventory</h4>
                                                    <div className="inv-form__wrapper">
                                                        <div className="inv-form__col">
                                                            <div className="inv-form__div">
                                                                <span>Brand</span>
                                                                <Select
                                                                    name="selectBrand"
                                                                    value={this.state.selectedBrand.value}
                                                                    onChange={this.handleChangeBrand}
                                                                    options={this.brandOption()}
                                                                    clearableValue={true}
                                                                    placeholder="Select Brand"
                                                                    onKeyPress={this.keyPress}
                                                                />
                                                            </div>
                                                            <div className="inv-form__div">
                                                                <span>Model</span>
                                                                <Select
                                                                    name="form-field-name"
                                                                    value={this.state.selectedOption}
                                                                    onChange={this.handleChange}
                                                                    // options={this.modelOption()}
                                                                    options={this.state.modelsObj}
                                                                    placeholder="Select Model"
                                                                />
                                                            </div>
                                                            <div className="inv-form__div">
                                                                <span>Engine Number</span>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    name="engine_number"
                                                                    ref="engine_number"
                                                                    onChange={(e) => this.setState({engine_number: e.target.value})}
                                                                    maxLength={20}
                                                                />
                                                            </div>
                                                            <div className="inv-form__div">
                                                                <span>Chassis Number</span>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    name="chassis_number"
                                                                    ref="chassis_number"
                                                                    onChange={(e) => this.setState({chassis_number: e.target.value})}
                                                                    maxLength={20}/>
                                                            </div>
                                                            <div className="inv-form__div">
                                                                <span>Color</span>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    name="color"
                                                                    ref="color"
                                                                    onChange={(e) => this.setState({color: e.target.value})}
                                                                />
                                                            </div>
                                                            <div className="inv-form__div">
                                                                <span>Date Received</span>
                                                                <DatePicker
                                                                    selected={this.state.date_delivered}
                                                                    onChange={this.handleDateDelivered}
                                                                    peekNextMonth
                                                                    showMonthDropdown
                                                                    showYearDropdown
                                                                    dropdownMode="select"
                                                                    className="form-control"
                                                                />
                                                            </div>
                                                        </div>

                                                        <div className="inv-form__col">
                                                            <div className="inv-form__div">
                                                                <span>Branch</span>
                                                                <Select
                                                                    name="selectBranch"
                                                                    value={this.state.selectedBranch.value ? this.state.selectedBranch.value : this.props.userData.branch_info._id}
                                                                    onChange={this.handleChangeBranch}
                                                                    options={this.mainBranchOption()}
                                                                    clearableValue={true}
                                                                    placeholder="Select Branch"
                                                                    disabled={this.defaultBranch('isDisabled')}
                                                                />
                                                            </div>
                                                            <div className="inv-form__div">
                                                                <span>Delivery Receipt Number</span>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    name="delivery_receipt_number"
                                                                    ref="delivery_receipt_number"
                                                                    onChange={(e) => this.setState({delivery_receipt_number: e.target.value})}
                                                                    maxLength={20}
                                                                />
                                                            </div>
                                                            <div className="inv-form__div">
                                                                <span>Delivery Receipt Date</span>
                                                                <DatePicker
                                                                    selected={this.state.delivery_receipt_date}
                                                                    onChange={this.handleDeliveryReceiptDate}
                                                                    peekNextMonth
                                                                    showMonthDropdown
                                                                    showYearDropdown
                                                                    dropdownMode="select"
                                                                    className="form-control"
                                                                />
                                                                {/*// <DateRangePicker*/}
                                                                {/*//    onApply={this.handleDeliveryReceiptDate}*/}
                                                                {/*//    singleDatePicker*/}
                                                                {/*// >*/}
                                                                {/*//  <button className="form-control">*/}
                                                                {/*//      {*/}
                                                                {/*//          this.state.delivery_receipt_date*/}
                                                                {/*//      }*/}
                                                                {/*//  </button>*/}
                                                                {/*// </DateRangePicker>*/}
                                                                {/*// <input*/}
                                                                {/*// 	type="date"*/}
                                                                {/*// 	className="form-control"*/}
                                                                {/*// 	name="delivery_receipt_date"*/}
                                                                {/*// 	ref="delivery_receipt_date"*/}
                                                                {/*// 	onChange={(e) => this.setState({delivery_receipt_date: e.target.value})}*/}
                                                                {/*// />*/}
                                                            </div>
                                                            <div className="inv-form__div">
                                                                <span>Invoice Number</span>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    name="invoice_number"
                                                                    ref="invoice_number"
                                                                    onChange={(e) => this.setState({invoice_number: e.target.value})}
                                                                    maxLength={20}
                                                                />
                                                            </div>
                                                            <div className="inv-form__div">
                                                                <span>Invoice Date</span>
                                                                <DatePicker
                                                                    selected={this.state.invoice_date}
                                                                    onChange={this.handleInvoiceDate}
                                                                    peekNextMonth
                                                                    showMonthDropdown
                                                                    showYearDropdown
                                                                    dropdownMode="select"
                                                                    className="form-control"
                                                                />
                                                            </div>
                                                            <div className="inv-form__div">
                                                                <span>Price</span>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    name="price"
                                                                    ref="price"
                                                                    onChange={(e) => this.setState({price: e.target.value})}
                                                                    maxLength={13}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <label className="inv-form__check">
                                                            <input type="checkbox"
                                                                   onChange={(e) => this.warranty_change(e)}
                                                                   ref="warranty_claims"/>
                                                            Warranty Booklet
                                                        </label>
                                                        <label className="inv-form__check">
                                                            <input type="checkbox"
                                                                   onChange={(e) => this.clearances(e)}
                                                                   ref="clearances"/>
                                                            Clearances
                                                        </label>
                                                        <label className="inv-form__check">
                                                            <input type="checkbox"
                                                                   onChange={(e) => this.tba_change(e)}
                                                                   ref="tba"/>
                                                            TBA's
                                                        </label>
                                                    </div>
                                                    <div>
                                                        <label className="form-check-label">
                                                            <input className="form-check-input"
                                                                   onChange={() => this.saveTemplate()}
                                                                   type="checkbox" value=""/>
                                                            Save template for next entry
                                                            (Except Chassis and Engine Number)
                                                        </label>
                                                    </div>
                                                    <div className="row padding-y-15 align-content-end">
                                                        <div className="inv-form__button-wrapper col">
                                                            <button
                                                                className="btn btn-block btn-second-primary"
                                                                onClick={() => this.saveAndAdd(true)}>Save and
                                                                Add New Entry
                                                            </button>
                                                        </div>
                                                        <div className="inv-form__button-wrapper col">
                                                            <button
                                                                className="btn btn-block btn-second-primary"
                                                                onClick={() => this.saveAndAdd(false)}>Save and
                                                                Close
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


const mapStateToProps = state => ({
    models: state.category.modelsSelect,
    branch: state.category.branchesSelect,
    brandsSelect: state.category.brandsSelect,
    userData: state.login.userData,
    userPermission: state.login.userPermission,
});

function mapDispatchToProps(dispatch) {
    return {actions: bindActionCreators(ActionCreators, dispatch)}
}

export default connect(mapStateToProps, mapDispatchToProps)(add_inventory);

