import React, { Component } from 'react';
import toastr from 'toastr';

//redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// actions
import { ActionCreators } from '../../../actions';

const $ = require('jquery');

var that;

class update_branch_modal extends Component {

	constructor(props) {
	  	super(props);
	
	  	this.state = {
	  		id: null,
	  		branchName: '',
	  		newBranchName: ''
	  	};  
	}

	componentWillMount() {
		that = this;
	}

	componentDidMount(){
	}

	closeModalCallback = () => {
		that.refs.cancel.click();
		that.refs.branchName.value = '';
	}

	updateBranch = () => {
		
		var branchName = this.state.branchName,
			updatedBranchName = this.refs.branchName.value.trim(),
		 	that = this;

		if(branchName === updatedBranchName){
			that.closeModalCallback();
			toastr.info('No changes made');
			return;
		}

		if(this.props.updateBranch.name === updatedBranchName){
			that.closeModalCallback();
			toastr.info('No changes made');
			return;
		}

		this.props.actions.updateBranch(this.props.updateBranch.id, updatedBranchName.toUpperCase())
		.then((res) => {
			that.closeModalCallback();
			if (res){
				toastr.success('Branch successfully updated!');
			}else{
				toastr.error('Could not update branch!');
			}
		})

		// this.props.actions.addModel(modelName).then((data) => {
		// 	if(data === 'exist'){
		// 		toastr.error(modelName + ' is already exists.');
		// 	}else if(data === 'success') {
		// 		that.refs.cancel.click();
		// 		toastr.success(modelName + ' is successfully added');
		// 	}else{
		// 		toastr.error('Failed to add. Please try again.');
		// 	}
		// });

	}

	// componentWillReceiveProps(data) {
	// 	this.updateModelProps(data.params.name, data.params.id);
	// }

	// updateModelProps = (name, id) => {
	// 	console.log(id, name);
	// 	this.setState({
	// 		modelName: name,
	// 		id: id
	// 	})
	// }

	onChangeBranch = (e) => {
		// console.log(e.target.value)
	}

  	render() {
    	return (
      		<div className="modal fade" id="update_branch" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" ref="updateModel" data-keyboard="false">
			  	<div className="modal-dialog" role="document">
			    	<div className="modal-content">
			      		<div className="modal-header">
			        		<h5 className="modal-title" id="exampleModalLabel">Update Branch</h5>
			        			<button type="button" className="close" onClick={this.closeModalCallback} aria-label="Close">
						          <span aria-hidden="true">&times;</span>
						        </button>
			      		</div>
				      	<div className="modal-body">
				        	<div className="form-group">
				        		<label>Branch Name : <strong>{this.props.updateBranch.name}</strong></label>
				        		<input type="text" key={(this.props.updateBranch).length > 0 ? 'input' : 'data'} className="form-control" ref="branchName"
				        		// defaultValue={this.props.updateBranch.name}
				        		onChange={this.onChangeBranch}/>
				        	</div>
				      	</div>
				      	<div className="modal-footer">
				        	<a data-dismiss="modal" ref="cancel"></a>
				        	<button type="button" className="btn btn-secondary" onClick={this.closeModalCallback}>Cancel</button>
				        	<button type="button" className="btn btn-primary" onClick={this.updateBranch}>Update</button>
				      	</div>
			    	</div>
			  	</div>
			</div>
    	);
  	}
}


const mapStateToProps = state => ({
   	category: state.category.updateModelInfo,
   	updateBranch: state.category.updateBranchInfo,
   	app: state.feathersClient.app
});

function mapDispatchToProps(dispatch) {
   return { actions: bindActionCreators(ActionCreators, dispatch) }
}

export default connect(mapStateToProps, mapDispatchToProps)(update_branch_modal);