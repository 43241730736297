import React, {Component} from 'react';

//redux
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
// actions
import {ActionCreators} from '../../../actions';

//main NavBar
import MainNav from '../../../components/global_nav';
import MainSideBar from '../../../components/global_sidebar';

//sub side bar
import SubSideBar from './sub_sidebar';

import app from '../../../helpers/feathers';

//custom styles
import '../../../stylesheet/styles.css';

//datatables
import '../../../stylesheet/datatables/datatables.css';
import ConfirmationDelete from "../inventory/confirmation_delete";

const $ = require('jquery');
$.DataTable = require('datatables.net');

class inventory_reports extends Component {
    constructor(props) {
        super(props);

        this.state = {
            inventory_reports: []
        };
    }

    componentWillMount() {
        this.inventoryReports();
    }

    componentDidMount() {
        const that = this, inventoryTbl = $('.inventory_reports').DataTable({
            columns: [
                {"title": "DATE"},
                {"title": "TITLE"},
                {"title": "CREATED BY"},
                {"title": ""},
                {"title": ""},
            ],
            data: this.state.inventory_reports,
            columnDefs: [
                {
                    targets: [0],
                    width: '20%'
                },
                {
                    targets: [1],
                    width: '40%'
                },
                {
                    targets: [2],
                    width: '30%'
                },
                {
                    targets: [3],
                    width: '15%',
                    orderable: false
                },
                {
                    targets: [4],
                    visible: false,
                }
            ],
            'dom': "<'row'<'col-sm-6'l><'col-sm-6'p>>" +
                "<'row'<'col-sm-12'tr>>" +
                "<'row'<'col-sm-12'i>>",
        });

        $('.search').keyup(function () {
            inventoryTbl.search($(this).val()).draw();
        });

        // view-report
        $('.inventory_reports').on('click', '.view-report', function () {
            const data = inventoryTbl.row($(this).parents('tr')).data();
            that.props.actions.viewReportData(data[4]);
            that.props.history.push('/report_view_inventory_report')
        });

        // delete-report
        $('.inventory_reports').on('click', '.delete_report', function () {
            const data = inventoryTbl.row($(this).parents('tr')).data();
            const id = data[4];
            const permission = that.props.userPermission;

            if (permission.inventory === 2) {
                that.props.actions.deleteId(id);
                that.props.actions.deleteService('reports');
                that.refs.confirm_delete.click();
            }
        });

        that.createListener();
    }

    createListener = () => {
        const products = app.service('reports');

        products.on('created', products => {
            this.inventoryReports();
        });

        products.on('removed', products => {
            this.inventoryReports();
        });
    }

    permissionReport = () => {
        const permission = this.props.userPermission;

        if (permission.report === 0) {
            return "not-visible"
        } else {
            return "";
        }
    }

    inventoryReports = () => {
        this.props.actions.getAllInventoryReports(0)
        .then((data) => {
            if(data){
            this.productList(data);
            }
        });
    }

    productList = data => {
        this.setState({
            inventory_reports: data
        });

        const table = $('.inventory_reports').DataTable();
        table.clear();
        table.rows.add(data);
        table.draw();
    }

    createReport = () => {
        let {userPermission} = this.props,
            reportPermission = userPermission.report;

        if (reportPermission !== 2) {
            return;
        }

        this.props.history.push('/report_create_report');
    }

    render() {
        return (
            <div className="">
                {/* main NavBar component*/}
                <MainNav historyProp={this.props.history} usersProp={this.props.userData}/>
                <ConfirmationDelete/>
                <div className="container-fluid">
                    <div className="row">
                        <div className="sidebar">
                            <div className="sidebar-content">
                                <MainSideBar page="report"/>
                            </div>
                        </div>
                        <SubSideBar history={this.props.history} userPermission={this.props.userPermission}/>
                        {
                            this.props.userPermission.report === 0 ?
                                <div className="sub-inv-main-content">
                                    <div className="no-access-div">
                                        <div className="col-md-12">
                                            <h1>Access denied</h1>
                                        </div>
                                    </div>
                                </div>
                                : null
                        }
                        <div className={this.permissionReport()}>
                            <div className="sub-inv-main-content">
                                <div className="container-fluid">
                                    <div className="application-container">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <h4 className="subheader-title">Unsold Units Report List</h4>
                                            </div>
                                            <div className="">
                                                <button className="btn btn-block btn-second-primary add-new-btn"
                                                        onClick={() => this.createReport()}>
                                                    <span><i className="fa fa-plus"/> Create</span>
                                                </button>
                                            </div>
                                        </div>
                                        <div className="">
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <div className="justify-content-left content-header">
                                                        <input type="search"
                                                               className="search search-class form-control"
                                                               placeholder="&#xF002; Search"/>
                                                    </div>
                                                </div>
                                            </div>
                                            <br/>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <a data-toggle="modal" data-target="#confirm_delete" ref="confirm_delete"></a>
                                                    <table className="inventory_reports table tbl-mobile">
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


const mapStateToProps = state => ({
    app: state.feathersClient.app,
    userData: state.login.userData,
    userPermission: state.login.userPermission,
});

function mapDispatchToProps(dispatch) {
    return {actions: bindActionCreators(ActionCreators, dispatch)}
}

export default connect(mapStateToProps, mapDispatchToProps)(inventory_reports);
