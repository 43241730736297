import React, { Component } from 'react';

//main NavBar
import MainNav from '../../../components/global_nav';
// import MainSideBar from '../../../components/global_sidebar';

import MainSideBar from '../../../components/acc_sidebar';


import defaultImage from '../../../assets/img/default_avatar.jpg';


// moment
import Moment from 'moment';
import Cleave from 'cleave.js/react';
import { FormattedNumber } from 'react-intl';

import ReactDataSheet from 'react-datasheet';

//toastr
import toastr from 'toastr';
import Toggle from 'react-bootstrap-toggle';

// custom helper
import {
    numberWithCommas
} from '../../../helpers/';

//sub side bar
import SubSideBar from '../accounting/sub_sidebar'; 

//custom styles
import '../../../stylesheet/styles.css';

//redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// actions
import { ActionCreators } from '../../../actions';
const $ = require('jquery');
$.DataTable = require('datatables.net');

var that

class view_customer_secondhand extends Component {


    constructor(props) {
        super(props);

        console.log('props', props)
    
        this.state = {
            activeTab: 'home',
            modelName: '',
            payment: [],
            paymentData: [],
            form: {},
            currentBalance: 0,
            add: false,
            delinquent: false,
            saving: false,
            toggleActive: false
        };
    }

    componentWillMount(){
        that = this;
        let { customer } = that.props,
        {
            account_number,
            promisory_note
        } = customer;

        var badRecord = that.props.customer.bad_record;

        that.setState({
            delinquent: badRecord === 0 ? false : true
        })

        that.props.actions.getShLedger(account_number, promisory_note)
        .then((res) => {
            if(res.status){
                that.updateTable(res.data)
            }else{
                that.updateTable([])
            }
        })
    }

    componentDidMount() {
        $('.table-due-date').DataTable({
            "paging": false,
            "pageLength": 36,
            "searching": false,
            "ordering": false,
            "info": false,
            "sDom": '<center<"bottom"<t>p><"clear">>'
        });

        // var tblPayment = $('.tbl-payment').DataTable({
        //     "searching": false,
        //     "ordering": false,
        //     "info":     false
        // });

        $('.tbl-payment').DataTable({
            "paging": false,
            "async": false,
            "searching": false,
            "empty": false,
            "info": false,
            "ordering": false,
        });
        
         var empty = $(".tbl-payment tbody tr:eq(0) td").html();
         if(empty == "No data available in table")
         {
            $(".tbl-payment tbody tr:eq(0) td").hide();
         }

        if(this.props.customer.type === 1){
            // this.getPayments();
        }
        if(!this.props.customer.payment_method){
            $(".sh-customer-ledger").hide()
        }

        $(".secondhand-ledger-table").DataTable({
            data: this.props.sh_ledger,
            "columnDefs": [
                {
                    "visible": false,
                    "targets": 0
                },
            ],
            columns: [
                {title: ""},
                {title: "Date"},
                {title: "Invoice"},
                {title: "DR"},
                {title: "CR"},
                {title: "Balance"},
                {title: " DATE DUE"},
                {title: "MA"},
                {title: " BALANCE"},

            ],
            "paging": false,
            "pageLength": 100,
            "searching": false,
            "ordering": false,
            "info": false,
            "sDom": '<"bottom"<t>ip><"clear">'
        })
    }

    getPayments = () => {
        var customerId = this.props.customer._id,
            that = this;
        this.props.actions.getCustomerPayments(customerId).then(data => {

            console.log('customer payment', data);

            that.getPaymentsInfo('load', data);
            that.props.actions.setCustomerPayments(data);
            that.updatePayment(data);
        });
    }

    updateTable = (data) => {
        this.setState({
            data: data
        });

        const table = $('.secondhand-ledger-table').DataTable();
        table.clear();
        table.rows.add(data);
        table.draw();
    }

    updatePayment = (data) => {
        this.setState({
            payment: data
        });
    }

    getModelInfo = () => {
        var modelId = this.props.customer.product != undefined ? this.props.customer.product.model: 0,
            data = this.props.model;

            console.log('model props ',data)

            for(var i in data){
                if(data[i][0] === modelId){
                    return data[i][2];
                }
            }
    }

    getBrandInfo = () => {
        var brandId = this.props.customer.product != undefined ? this.props.customer.product.brand: 0,
        data = this.props.brand;
            for(var i in data){
                if(data[i][0] === brandId){
                    return data[i][2];
                }
            }
    }

    getPaymentDue = () => {
        var startDate = this.props.customer.date_added,
            termsLength = this.props.customer.terms.months,
            monthlyPayment = this.props.customer.terms.monthly_payment,
            rows = [],
            paymentRows = [];

            for(var i = 1; i <= termsLength; i++){
                var newMonths = Moment(startDate).add(i, 'months').format('MM/DD/YYYY');
                rows.push(<tr key={i}><td>{newMonths}</td><td><FormattedNumber value={monthlyPayment}  style="currency" currency="Php" /></td></tr>)
            }

        return rows;
    }

    getPaymentsInfo = (value, paymentHistory) => {
        var paymentData = [],
            paymentHistory = paymentHistory,
            dataLength = paymentHistory.length,
            termsLength = this.props.customer.terms.months,
            newLength = termsLength - dataLength,
            balance = this.props.customer.product.price - this.props.customer.terms.down_payment,
            startDate = this.props.customer.date_added,
            payedAmount = 0,
            currentBalance = this.state.currentBalance;


        paymentHistory.map(x => {
            payedAmount += x[2];
            payedAmount += x[4];
            paymentData.push(
                <tr key={i} className="table-primary text-center">
                    <td>{Moment(x[1]).format('MM/DD/YYYY')}</td>
                    <td><FormattedNumber value={x[2]} className="form-control" style="currency" currency="Php" /></td>
                    <td><FormattedNumber value={x[3] / 100} style="percent" /></td>
                    <td><FormattedNumber value={x[4]} className="form-control" style="currency" currency="Php" /></td>
                    <td><FormattedNumber value={balance - payedAmount} className="form-control" style="currency" currency="Php" /></td>
                    <td>{x[6]}</td> 
                </tr>
            )
        });

        this.setState({
            currentBalance: balance - payedAmount
        });

        if(value === 'load'){
            for(var i = 1; i <= newLength; i++){
                var newMonths = Moment(startDate).add(i, 'months').format('MM/DD/YYYY');
                paymentData.push(<tr key={i} className="text-center">
                        <td>MM/DD/YYYY</td>
                        <td>Php 0.00</td>
                        <td>0%</td>
                        <td>Php 0.00</td>
                        <td><FormattedNumber value={balance - payedAmount} className="form-control" style="currency" currency="Php" /></td>
                        <td>--</td>
                    </tr>
                );
            }
        }else{
            var permission = this.props.userPermission;
            if(permission.customer !== 2){
                return;
            }

            this.setState({
                add: true
            })
            paymentData.push(
                <tr key={0} className="text-center">
                    <td><input type="date" className="form-control" name="payment_date" onChange={this.handleOnChange}/></td>
                    <td><Cleave className="form-control" placeholder="Amount" options={{numeral: true, numeralThousandsGroupStyle: 'thousand'}} name="amount" onChange={this.handleOnChange}/></td>
                    <td><Cleave className="form-control" placeholder="Interest" options={{numeral: true, numeralThousandsGroupStyle: 'thousand'}} name="interest" onChange={this.handleOnChange}/></td>
                    <td><Cleave className="form-control" placeholder="Rebate" options={{numeral: true, numeralThousandsGroupStyle: 'thousand'}} name="rebate" onChange={this.handleOnChange}/></td>
                    <td><FormattedNumber value={balance - payedAmount} className="form-control" style="currency" currency="Php" /></td>
                    <td><input type="text" className="form-control" name="remarks" onChange={this.handleOnChange}/></td>
                </tr>);
            for(var i = 1; i <= (newLength - 1); i++){
                var newMonths = Moment(startDate).add(i, 'months').format('MM/DD/YYYY');
                paymentData.push(<tr key={i} className="text-center">
                        <td>MM/DD/YYYY</td>
                        <td>Php 0.00</td>
                        <td>0%</td>
                        <td>Php 0.00</td>
                        <td><FormattedNumber value={payedAmount - balance} className="form-control" style="currency" currency="Php" /></td>
                        <td>--</td>
                    </tr>
                );
            }
        }

        this.setState({
            paymentData: paymentData
        });
        
    }

    handleOnChange = (e) => {
        var form = this.state.form,
            value = (e.target.name === 'amount' || e.target.name === 'interest' || e.target.name === 'rebate') ? parseFloat((e.target.value).replace(/,/g , "")) : e.target.value;
        
        form[e.target.name] = value;
        this.setState({
            form: form
        });
    }


    savePayments = () => {
        // console.log(this.refs.amount.value);
        // var tblPayment = $('.tbl-payment').DataTable();
        // tblPayment.rows().every( function ( rowIdx, tableLoop, rowLoop ) {
        //     var data = this.data();
        //     console.log('data', data[0])
        // });
        var form = this.state.form,
            count = 0,
            balance = this.props.customer.product.price - this.props.customer.terms.down_payment,
            payment = 0,
            that = this;

        for(var data in form){
            var value = form[data];

            if(data === 'amount' || data === 'rebate'){
                payment += value;
            }

            if(value != '' || value != undefined){
                count++;
            }
        }

        if(count < 5){
            toastr.error('Please fill in required fields');
            return false;
        }

        form['customer_id'] = this.props.customer._id;
        form['balance'] = (balance - payment);

        this.props.actions.addPayment(form).then(data => {
            if(data === 'success'){
                toastr.success('Payment sucessfully added.')
                that.getPayments();
            }
        });

    }

    cancel = () => {
        this.setState({
            add: false
        });
        this.getPayments();
    }

    setAsDelinquent = () => {

        if(that.state.saving){
            return false;
        }else{
            that.setState({
                delinquent: true,
                saving: true
            })
        }

        var user_id = that.props.customer._id,
            status = 1,
            set_by = that.props.userData._id,
            delinquent_payee = 1;

        var data = {
            user_id: user_id,
            status: status,
            set_by: set_by,
            delinquent_payee: delinquent_payee
        }

        that.props.actions.setAsDelinquent(data)
        .then((data) => {
            that.setState({
                saving: false
            })
        })
    }
    removeDelinquency = () => {

        if(that.state.saving){
            return false;
        }else{
            that.setState({
                delinquent: false,
                saving: true
            })
        }

        var user_id = that.props.customer._id,
            status = 1,
            set_by = that.props.userData._id,
            delinquent_payee = 0;

        var data = {
            user_id: user_id,
            status: status,
            set_by: set_by,
            delinquent_payee: delinquent_payee
        }

        that.props.actions.removeDelinquency(data)
        .then((data) => {
            that.setState({
                saving: false
            })
        })
    }

    onToggle = () => {
        var permission = this.props.userPermission;
        if(permission.customer !== 2){
            return;
        }

        if (this.state.delinquent){
            that.removeDelinquency();
        }else{
            that.setAsDelinquent();
        }
    }

  	render() {
            var modelName = this.getModelInfo(),
            brandName = this.getBrandInfo();

            let {customer} = this.props,
                unit = customer.secondhand_unit_info;

            // paymentDueDate = this.props.customer.type === 1 ? this.getPaymentDue() : null;
    	return (
     	 	<div className="">
     	 		{/* main NavBar component*/}
     	 		<MainNav
                    historyProp={this.props.history}
                    userPermission={this.props.userPermission}
                    usersProp={this.props.userData}
                    systemType="accounting"
                />
     	 		
     	 		<div className="container-fluid">
     	 			<div className="row full-width">
	     	 			<div className="sidebar">
	     	 				<div className="sidebar-content">
	     	 					<MainSideBar page="customer"/>
	     	 				</div>
	     	 			</div>
                       <SubSideBar history={this.props.history}/>
	     	 			<div className="sub-main-content">
                            {
    	     	 				//<div className="d-flex flex-row justify-content-center content-header">
                                    //<button className="btn application-btn">APPROVE</button>
                                    //<button className="btn application-btn">REJECT</button>
                                //</div>
                            }
                            <div className="container-fluid application-container">
                            <a href="javascript:;"
                               onClick={() => this.props.history.goBack()} className="text-gray no-textdecoration">
                                <span className="fa fa-chevron-left" /> Customer / {this.props.customer.name}</a>
                            <br/>
                            <br/>
                                {/* <ul className="nav nav-tabs" role="tablist">
                                    <li role="presentation" className={this.state.activeTab === 'home' ? 'active' : ''} onClick={() => this.navigateTab('home')}><a href="javascript:;" aria-controls="home" role="tab" data-toggle="tab">PROFILE</a></li>
                                   <li role="presentation" className={this.state.activeTab === 'payments' ? 'active' : ''} onClick={() => this.navigateTab('payments')}><a href="javascript:;" aria-controls="payments" role="tab" data-toggle="tab">PAYMENTS</a></li>
                                </ul>*/}
                                
                                <div className="tab-content background-white">
                                    <div role="tabpanel"
                                         className={"hide-view-customer tab-pane view-cust "
                                         + (this.state.activeTab === 'home' ? 'active' : '') } id="home">
                                        <div className="">
                                            <div className="background-white p-5">
                                                <div className="container-fluid">
                                                    <div className="row d-flex flex-row align-items-center">
                                                        <div className="col-md-2 d-flex justify-content-center ">
                                                            <img src={defaultImage} className="avatar" alt="logo"/>
                                                        </div>

                                                        <div className="col-md-10">
                                                        {
                                                            // <div className="row">
                                                            //     <div className="col-md-9">
                                                            //         <h4>{this.props.customer.name}</h4>
                                                            //     </div>
                                                            //     <div className="col-md-3">
                                                                    
                                                            //     </div>
                                                            // </div>
                                                        }
                                                            <div className="row">
                                                                <div className="col">
                                                                    <h4>{customer.title} {customer.name}</h4>
                                                                    <span>{customer.payment_method ? customer.account_number : <span
                                                                            className="badge badge-success badge-pill">CASH</span>}</span>
                                                                </div>
                                                                <div className="col">
                                                                    <span>Date Purchased</span>
                                                                    <p>{Moment(this.props.customer.date_purchased).format('MMMM D, YYYY')}</p>
                                                                </div>
                                                                <div className="col">
                                                                    <span>Branch</span>
                                                                    <p>{customer.branch != undefined ? customer.branch_info.branch_name : '' }</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <br/>
                                                    <div className="personal-info">
                                                        <div className="row">
                                                            <h5><strong>UNIT INFORMATION</strong></h5>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col">
                                                                <span><strong>Model:</strong>  {customer.old_unit ? customer.unit_model : (unit ? unit.model.name : <span className="text-danger">REMOVED</span>)}</span>
                                                            </div>
                                                            <div className="col">
                                                                <span><strong>Previous Customer:</strong> {unit ? unit.customer : ''}</span>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col">
                                                                <span><strong>Engine Number:</strong>  {unit ? unit.engine_number : ''}</span>
                                                            </div>
                                                            <div className="col">
                                                                <span><strong>Account Number:</strong>  {unit ? unit.account_number : ''} </span>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col">
                                                                <span><strong>Selling Price:</strong>  {unit ? unit.selling_price : ''}</span>
                                                            </div>
                                                            <div className="col">
                                                                <span><strong>Date Foreclosed:</strong> {unit ? Moment(unit.date_forclosed).format('MM/DD/YYYY') : ''}</span>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col">
                                                                <span><strong>Discount:</strong>  {unit.discount !== '' && unit.discount !== undefined ? unit.discount+'%' : ''}</span>
                                                            </div>
                                                            <div className="col">
                                                                <span><strong>Remarks:</strong>  {unit ? unit.remarks : ''}</span>
                                                            </div>
                                                        </div>
                                                        {
                                                            // this.props.customer.type === 1 ? 
                                                            // <div>
                                                            //     <div className="row">
                                                            //     {
                                                            //         // <div className="col">
                                                            //         //     <span><strong>Total Price:</strong>  <FormattedNumber value={this.props.customer.product != undefined ? this.props.customer.product.price : 0}  style="currency" currency="Php" /> </span>
                                                            //         // </div>
                                                            //     }
                                                            //         <div className="col">
                                                            //             <span><strong>Terms:</strong>  {this.props.customer.term}</span>
                                                            //         </div>
                                                            //         <div className="col">
                                                            //             <span><strong>Down Payment:</strong>  <FormattedNumber value={this.props.customer.payment}  style="currency" currency="Php" /> </span>
                                                            //         </div>
                                                            //     </div>
                                                            //     <div className="row">
                                                            //         <div className="col">
                                                            //             <span><strong>Date Sold:</strong>  {Moment(this.props.customer.date_added).format('MMMM D, YYYY')}</span>
                                                            //         </div>
                                                            //         <div className="col"></div>
                                                            //     </div>
                                                            // </div>
                                                            // :
                                                            // <div className="row">
                                                            //     <div className="col">
                                                            //         <span><strong>Date Sold:</strong>  {Moment(this.props.customer.date_added).format('MMMM D, YYYY')}</span>
                                                            //     </div>
                                                            //     <div className="col">
                                                            //         <span><strong>Payment:</strong>  <FormattedNumber value={this.props.customer.payment}  style="currency" currency="Php" /> </span>
                                                            //     </div>
                                                            // </div>
                                                        }
                                                    </div>
                                                    <br/>
                                                    <hr />
                                                </div>

                                                <div className="row sh-customer-ledger">
                                                    <div className="col-md-12">
                                                        <a href="javascript:;"
                                                           className="print-not-visible button-print btn btn-info"
                                                           onClick={() => window.print()}
                                                        >
                                                           <i className="fa fa-print"/> Print Schedule
                                                        </a>
                                                    </div>
                                                    <br />
                                                    <br />
                                                    <div className="col-md-12">
                                                        <table className="table table-bordered secondhand-ledger-table"/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
	     	 			</div>
     	 			</div>
     	 		</div>
     	 	</div>
    	);
  	}
}


const mapStateToProps = state => ({
    customer: state.customers.customerInfo,
    payment: state.customers.customerPayments,
    sh_ledger: state.customers.sh_ledger,
    model: state.category.modelsList,
    brand: state.category.brandsList,
    userData: state.login.userData,
    userPermission: state.login.userPermission,
});

function mapDispatchToProps(dispatch) {
   return { actions: bindActionCreators(ActionCreators, dispatch) }
}

export default connect(mapStateToProps, mapDispatchToProps)(view_customer_secondhand);
