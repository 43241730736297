import React, {Component} from 'react';
import app from "../../../helpers/feathers";

//main NavBar
import MainNav from '../../../components/global_nav';
import MainSideBar from '../../../components/acc_sidebar';

import SelectUnitType from './select_customer_modal';

// moment
import Moment from 'moment';
import {IntlProvider, FormattedMessage, FormattedNumber} from 'react-intl';
import Cleave from 'cleave.js/react';

import ReactDataSheet from 'react-datasheet';

//toastr
import toastr from 'toastr';
import Toggle from 'react-bootstrap-toggle';

import RepossessModal from '../accounting/repossess_modal';
//sub side bar
import SubSideBar from '../accounting/sub_sidebar';

import defaultImage from '../../../assets/img/default_avatar.jpg';

//custom styles
import '../../../stylesheet/styles.css';


import html2canvas from 'html2canvas';
import jsPDF from '../../../../node_modules/jspdf/dist/jspdf.min.js';

//redux
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
// actions
import {ActionCreators} from '../../../actions';

const $ = require('jquery');
$.DataTable = require('datatables.net');

var that

class view_customer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            activeTab: 'home',
            modelName: '',
            payment: [],
            paymentData: [],
            form: {},
            currentBalance: 0,
            add: false,
            delinquent: false,
            saving: false,
            show: false,
            toggleActive: false,
            monthly: '',
            expiry: false,

            remaining: 0
        };
    }

    componentWillMount() {
        that = this;

        that.getCustomerPayments();

        let {customer, total_payments} = this.props,
            product_price = customer.product ? customer.product.price : 0,
            promisory_note = customer.promisory_note,
            down_payment = customer.payment,
            initialBal = (promisory_note - total_payments);


        var totalPaid = customer.customer_payments,
            totalDebit = customer.customer_payments;

        let total_pay = 0,
            total_debit = 0;


        totalPaid.length > 0 && totalPaid.map((values) => {
            total_pay += Number(values.total_paid)
        })

        totalDebit.length > 0 && totalDebit.map((data) => {
            total_debit += Number(data.debit ? data.debit : 0);
        })

        var remain = Number(promisory_note) - Number(total_pay) + Number(total_debit)

        this.setState({
            remaining: remain
        })


        that.getLedgerInfo(initialBal);

        var badRecord = that.props.customer.bad_record;

        that.setState({
            delinquent: badRecord === 0 ? false : true
        })

        /* 
            in case unit has not been tagged as `sold` after creating customer,
            this will update unit status to `sold`
         */
        if(customer.unit){
            let productsService = app.service('products');
            productsService.find({
                query: {
                    _id: customer.unit,
                    type: 0
                }
            }).then((result) => {
                productsService.patch(customer.unit, {type: 1})
                .then(() => {

                })
                .catch((e) => {
                    console.log(e)
                })
            })
            .catch((e) => {
                console.log(e)
            })
        }
        if(customer.account_status == 2){
            if(customer.old_unit){
                if(customer.transferred_to_sh){
                    app.service("secondhand").find({
                        query: {
                            engine_number: customer.unit_engine_number,
                        }
                    })
                    .then((find_sh_unit) => {
                        // console.log(find_sh_unit)
                    })
                    .catch((e) => {
                        console.log(e)
                    })
                }
            }
        }


    }

    componentDidMount() {
        if ($('span.exp').text() == 'CONTRACT IS EXPIRED') {

            $('.checkbox-data').hide();

            $('span.new-terms').text('99 months');
        } else {
            $('.checkbox-data').show();

        }

        if ($('p#account_number_section').text() == 'CASH') {
            $('button.btn.btn-success.show_sched').hide();
        } else {
            $('button.btn.btn-success.show_sched').show();
        }

        $('#table-ledger').css('visibility', 'hidden');
        $('.hide_sched').hide();
        $('.button-print').hide();
        $('.show_sched').on('click', function () {
            $('#table-ledger').css('visibility', 'visible');
            $('.show_sched').hide();
            $('.button-print').show();
            $('.hide_sched').show();
        });
        $('.hide_sched').on('click', function () {
            $('#table-ledger').css('visibility', 'hidden');
            $('.hide_sched').hide();
            $('.button-print').hide();
            $('.show_sched').show();
        });


        let {customer, total_payments} = this.props,
            {unit, start_date} = this.state,
            product_price = customer.product ? customer.product.price : 0,
            promisory_note = customer.promisory_note,
            down_payment = customer.payment,
            initialBal = promisory_note - down_payment,
            terms = customer.term,

            ma = promisory_note / terms,
            remaining_balance = ((promisory_note - down_payment) - total_payments);

        this.setState({
            monthly: ma
        })

        if (customer.type == 0) {
            $('.menu').hide();
            $('.cbox').hide();
            $(".customer_sch").hide()
        }

        if (customer.account_status == 1 || customer.account_status == 2) {
            $(".customer_sch").hide()
        }

        var mainDtTable = $('.tbl-ledger').DataTable({
            data: this.props.ledger,
            "columnDefs": [
                {
                    "visible": false,
                    "targets": 0
                },

            ],
            columns: [
                {title: "ID"},
                {title: "Date"},
                {title: "Invoice"},
                {title: "DR"},
                {title: "CR"},
                {title: "Balance"},
                {title: "DATE DUE"},
                {title: "MA"},
                {title: "BALANCE"},
            ],
            "paging": false,
            "pageLength": 36,
            "searching": false,
            "ordering": false,
            "info": false,
            "sDom": '<"bottom"<t>ip><"clear">'
        });


        var paymentTb = $('.tpaid').DataTable({
            data: this.props.paymentTableData,
            "columnDefs": [
                {
                    "visible": false,
                    "targets": 0
                },
            ],
            columns: [
                {title: ""},
                {title: "DATE"},
                {title: "OR NUMBER"},
                {title: "DR"},
                {title: "CR"},
                {title: "REBATE"},
                {title: " BALANCE"},
                {title: "REMARKS"},

            ],
            "paging": false,
            "pageLength": 100,
            "searching": false,
            "ordering": false,
            "info": false,
            "sDom": '<"bottom"<t>ip><"clear">'
        });

        $('.tbl-ledger').on('click', '.edit', function () {
            var tr = mainDtTable.row($(this).parents('tr'))
            var row = $(this).parents('tr')[0],
                tds = $('>td', row),
                {editing} = that.state;
            var data = mainDtTable.row($(this).parents('tr')).data();

            that.props.actions.setLedgerItemId(data[0]);
            $('#update_ledger').modal('show');
            return;
            if (editing) {
                toastr.warning('Action not allowed');
                return;
            } else {
                that.setState({editing: true})
            }


            tds[2].innerHTML = '<input type="text">'
            tds[3].innerHTML = '<input type="date" className="amount-paid-input" ref="amount_paid">'
            {/*tds[3].innerHTML = <DatePicker
                                    selected={that.state.first_payment_date}
                                    onChange={that.handleFirstPaymentDate}
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    className="form-control"
                                /> // date paid */
            }
            tds[4].innerHTML = '<input type="text" ref="rebate" className="rebate-input">'
            tds[8].innerHTML = '<button className="btn btn-sm btn-success save" title="Save"><span className="fa fa-check" /></button> <button className="btn btn-sm btn-danger cancel" title="Cancel"><span className="fa fa-close" /></button>'
        })

        $('.table-due-date').DataTable({
            "paging": false,
            "pageLength": 36,
            "searching": false,
            "ordering": false,
            "info": false,
            "sDom": '<center<"bottom"<t>p><"clear">>'
        });


        $('.tbl-payment').DataTable({
            "paging": false,
            "async": false,
            "searching": false,
            "empty": false,
            "info": false,
            "ordering": false,
        });

        var empty = $(".tbl-payment tbody tr:eq(0) td").html();
        if (empty == "No data available in table") {
            $(".tbl-payment tbody tr:eq(0) td").hide();
        }

    }


    getCustomerPayments = () => {
        let {customer, total_payments} = this.props,
            promisory_note = customer.promisory_note,
            remaining_balance = (promisory_note - total_payments);

        that.props.actions.getCustomerPayment(customer.account_number, promisory_note)
            .then((res) => {
                if (res.status) {
                    that.updateDataTable('.tpaid', res.data)
                } else {
                    that.updateDataTable('.tpaid', [])
                }
            })
    }


    getLedgerInfo = (initialBal) => {

        let {customer} = this.props,
            account_number = customer.account_number;

        that.props.actions.customerLedger(account_number, initialBal)
            .then((result) => {

                if (result.status) {
                    that.updateDataTable('.tbl-ledger', result.data)
                }
            })
            .catch(() => {

            })

        const query = {
            _id: customer.product ? customer.product.model : 0
        }
        app.service('motorcycle-models').find().then((result) => {
            if (result.data.length) {
                let brand = result.data[0].brand_details.brand_name,
                    model = result.data[0].model_name,
                    unit = `${brand} ${model}`
                that.setState({unit: unit})
            }
        })
        .catch((e) => {
            console.log(e)
        });
    }


    status_change = (e) => {
        var val = e.target.checked,
            id = this.props.customer._id;
        that.setState({
            expiry: val
        })

        that.props.actions.updateExpiry(id, val)
            .then((data) => {

                toastr.remove();
                toastr.success('Customer Contract Expired');
            })

        setTimeout(() => {
            that.props.history.push("/acc_customer");
        }, 3000);

    }

    getPayments = () => {
        var customerId = this.props.customer._id,
            that = this;
        this.props.actions.getCustomerPayments(customerId).then(data => {

            that.props.actions.setCustomerPayments(data);
            that.updatePayment(data);
        });
    }

    updateDataTable = (table_class, data) => {
        // console.log('updateDataTable ')
        const table = $(table_class).DataTable();
        table.clear();
        table.rows.add(data);
        table.draw();
    }

    updatePayment = (data) => {
        this.setState({
            payment: data
        });
    }

    getModelInfo = () => {
        var modelId = this.props.customer.product != undefined ? this.props.customer.product.model : 0,
            data = this.props.model;
        for (var i in data) {
            if (data[i][0] === modelId) {
                return data[i][2];
            }
        }
    }

    getBrandInfo = () => {
        var brandId = this.props.customer.product != undefined ? this.props.customer.product.brand : 0,
            data = this.props.brand;

        for (var i in data) {
            if (data[i][0] === brandId) {
                return data[i][2];
            }
        }
    }

    getPaymentDue = () => {
        var startDate = this.props.customer.date_added,
            termsLength = this.props.customer.terms.months,
            monthlyPayment = this.props.customer.terms.monthly_payment,
            rows = [],
            paymentRows = [];

        for (var i = 1; i <= termsLength; i++) {
            var newMonths = Moment(startDate).add(i, 'months').format('MM/DD/YYYY');
            rows.push(<tr key={i}>
                <td>{newMonths}</td>
                <td><FormattedNumber value={monthlyPayment} style="currency" currency="Php"/></td>
            </tr>)
        }

        return rows;
    }

    handleOnChange = (e) => {
        var form = this.state.form,
            value = (e.target.name === 'amount' || e.target.name === 'interest' || e.target.name === 'rebate') ? parseFloat((e.target.value).replace(/,/g, "")) : e.target.value;

        form[e.target.name] = value;
        this.setState({
            form: form
        });
    }


    savePayments = () => {

        // var form = this.state.form,
        //     count = 0,
        //     balance = this.props.customer.product.price - this.props.customer.terms.down_payment,
        //     payment = 0,
        //     that = this;

        // for(var data in form){
        //     var value = form[data];

        //     if(data === 'amount' || data === 'rebate'){
        //         payment += value;
        //     }

        //     if(value != '' || value != undefined){
        //         count++;
        //     }
        // }

        // if(count < 5){
        //     toastr.error('Please fill in required fields');
        //     return false;
        // }

        // form['customer_id'] = this.props.customer._id;
        // form['balance'] = (balance - payment);

        // this.props.actions.addPayment(form).then(data => {
        //     if(data === 'success'){
        //         toastr.success('Payment sucessfully added.')
        //         that.getPayments();
        //     }
        // });

    }

    cancel = () => {
        this.setState({
            add: false
        });
        this.getPayments();
    }

    setAsDelinquent = () => {

        if (that.state.saving) {
            return false;
        } else {
            that.setState({
                delinquent: true,
                saving: true
            })
        }

        var user_id = that.props.customer._id,
            status = 1,
            set_by = that.props.userData._id,
            delinquent_payee = 1;

        var data = {
            user_id: user_id,
            status: status,
            set_by: set_by,
            delinquent_payee: delinquent_payee
        }

        that.props.actions.setAsDelinquent(data)
            .then((data) => {
                that.setState({
                    saving: false
                })
            })
    }
    removeDelinquency = () => {

        if (that.state.saving) {
            return false;
        } else {
            that.setState({
                delinquent: false,
                saving: true
            })
        }

        var user_id = that.props.customer._id,
            status = 1,
            set_by = that.props.userData._id,
            delinquent_payee = 0;

        var data = {
            user_id: user_id,
            status: status,
            set_by: set_by,
            delinquent_payee: delinquent_payee
        }

        that.props.actions.removeDelinquency(data)
            .then((data) => {
                that.setState({
                    saving: false
                })
            })
    }

    onToggle = () => {
        var permission = this.props.userPermission;
        if (permission.customer !== 2) {
            return;
        }

        if (this.state.delinquent) {
            that.removeDelinquency();
        } else {
            that.setAsDelinquent();
        }
    }


    printDocument() {
        const input = document.getElementById('divToPrint');
        html2canvas(input)
            .then((canvas) => {
                const imgData = canvas.toDataURL('image/png');
                const pdf = new jsPDF({
                    orientation: 'landscape',
                });
                pdf.addImage(imgData, 'JPEG', 0, 0);
                // pdf.output('dataurlnewwindow');
                pdf.save(that.props.viewReportData.report_title + ".pdf");
            });
    }


    showDiv() {
        var showTable = document.getElementById("table-ledger");
        if (showTable.display == "none") {
            showTable.display = "block";
        } else {
            showTable.display = "none";
        }
    }

    redirect = () => {
        that.props.actions.setCustomerInfo(this.props.customer);
        that.props.history.push('/view_ledger/');
    }
    repo = () => {
        that.props.actions.setCustomerInfo(this.props.customer);
        $('#repossessed_modal').modal('show');
    }
    setExpiry = () => {
        // let { customer } = this.props;

        // app.service("customers").patch(customer._id, {expiry_status: false})
        // .then((res) => {
        //     console.log(res)
        // })
        // .catch((e) => {
        //     console.log(e)
        // })
    }

    render() {

        var expiry = this.props.customer.expiry_status;
        let {customer} = this.props,
            prohibited_acc = ['1', '2'],
            {account_status, type, old_unit, unit_brand, unit_model, unit_engine_number,unit_chassis,unit_color} = customer,
            promisory = this.props.customer.promisory_note;

        var modelName = this.getModelInfo(),
            brandName = this.getBrandInfo();
        // paymentDueDate = this.props.customer.type === 1 ? this.getPaymentDue() : null;
        return (
            <div className="">
                {/* main NavBar component*/}
                <MainNav
                    historyProp={this.props.history}
                    userPermission={this.props.userPermission}
                    usersProp={this.props.userData}
                    systemType="accounting"
                />
                <SelectUnitType history={this.props.history}/>
                <RepossessModal history={this.props.history}/>

                <div className="container-fluid">
                    <div className="row full-width">
                        <div className="sidebar">
                            <div className="sidebar-content print-not-visible">
                                <MainSideBar page="customer"/>
                            </div>
                        </div>
                        <SubSideBar
                            history={this.props.history}
                            userPermission={this.props.userPermission}
                            path="/acc_add_customer"
                        />
                        <div className="sub-main-content">
                            <div className="container-fluid application-container">
                                <a href="javascript:;" onClick={() => this.props.history.goBack()}
                                   className="text-gray customer-details no-textdecoration"><span
                                    className="fa fa-chevron-left"/> Customer / {this.props.customer.name}</a>
                                <br/>
                                <br/>

                                <div className="tab-content background-white adjust-div">
                                    <div role="tabpanel"
                                         className={"hide-view-customer tab-pane view-cust " + (this.state.activeTab === 'home' ? 'active' : '')}
                                         id="home">
                                        <div className="">
                                            <div className="background-white p-5">
                                                <div className="container-fluid">
                                                    <div className="row d-flex flex-row align-items-center">
                                                        <div className="col-md-2 d-flex justify-content-center ">
                                                            <img src={defaultImage} className="avatar" alt="logo"/>
                                                        </div>
                                                        <div className="col-md-10">
                                                            <div className="row">
                                                                <div className="col">
                                                                    <h4>{this.props.customer.title} {this.props.customer.name}</h4>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col">
                                                                    <span>Account Number</span>
                                                                    <p id="account_number_section">{this.props.customer.account_number ? this.props.customer.account_number :
                                                                        <h5><span
                                                                            className="badge badge-success badge-pill">CASH</span>
                                                                        </h5>}</p>
                                                                </div>

                                                                {
                                                                    account_status == 1 ?
                                                                        <div className="col">
                                                                            <div>
                                                                                <span>Date Closed</span>
                                                                                <p>{Moment(this.props.customer.date_closed).format('MMMM DD, YYYY')}</p>
                                                                            </div>
                                                                        </div> : ''
                                                                }
                                                                {
                                                                    account_status == 2 ?
                                                                        <div className="col">
                                                                            <div>
                                                                                <span>Date Repossessed</span>
                                                                                <p>{Moment(this.props.customer.date_repossessed).format('MMMM DD, YYYY')}</p>
                                                                            </div>
                                                                        </div> : ''
                                                                }

                                                                <div className="col">
                                                                    {
                                                                        account_status == 1 ? <h4><span
                                                                            className="badge badge-success badger">FULLY PAID</span>
                                                                        </h4> : ''
                                                                    }
                                                                    {
                                                                        account_status == 2 ? <div><h4><span
                                                                            className="badge badge-success badger">REPOSSESSED</span>
                                                                        </h4></div> : ''
                                                                    }
                                                                    {
                                                                        !prohibited_acc.includes(customer.account_status) ?
                                                                            <div>
                                                                                <span>Remaining Balance </span>
                                                                                <p>
                                                                                    {

                                                                                        !this.props.customer.type ? "N/A" :
                                                                                            <FormattedNumber
                                                                                                value={parseInt(this.state.remaining)}
                                                                                                style="currency"
                                                                                                currency="Php"/>
                                                                                    }
                                                                                </p>
                                                                            </div>
                                                                            : null
                                                                    }

                                                                </div>
                                                                <div className="col">
                                                                    <span>Branch</span>
                                                                    <p>{customer.branch_info ? customer.branch_info.branch_name : ''}</p>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                {
                                                                    account_status == 2 ? <div className="col">
                                                                        <span>Remarks</span>
                                                                        <p>{this.props.customer.remarks2}</p>
                                                                    </div> : null
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <br/>
                                                    <div className="personal-info">
                                                        <div className="row">
                                                            <div className="col">
                                                                <h5><strong>UNIT INFORMATION</strong></h5>
                                                            </div>

                                                            <div className="col">

                                                                {
                                                                    !prohibited_acc.includes(customer.account_status) ?
                                                                        <button className="btn btn-success menu"
                                                                                onClick={this.redirect}>Go to Ledger <i
                                                                            className="fa fa-arrow-right"></i></button>
                                                                        : null
                                                                }
                                                                &nbsp;
                                                                {
                                                                    !prohibited_acc.includes(customer.account_status) && customer.type == 1 ?
                                                                        <button className="btn btn-danger repossess"
                                                                                title="Repossess" onClick={this.repo}>
                                                                            <span
                                                                                className="fa fa-archive"></span> Repossess
                                                                        </button> : ''
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col">
                                                                <span><strong>Model:</strong> {
                                                                    // old_unit ? unit_model : modelName
                                                                    unit_model
                                                                }</span>
                                                            </div>
                                                            <div className="col">
                                                                <span><strong>Brand:</strong> {old_unit ? unit_brand : brandName}</span>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col">
                                                                <span><strong>Color:</strong> {old_unit ? unit_color : (this.props.customer.product != undefined ? this.props.customer.product.color : '')}</span>
                                                            </div>
                                                            <div className="col">
                                                                <span><strong>Engine Number:</strong> {old_unit ? unit_engine_number : (this.props.customer.product != undefined ? this.props.customer.product.engine_number : '')} </span>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col">
                                                                <span><strong>Chassis Number:</strong> {old_unit ? unit_chassis : (this.props.customer.product != undefined ? this.props.customer.product.chas : '')}</span>
                                                            </div>
                                                            <div className="col">
                                                                <span><strong>{this.props.customer.type ? "Down" : ""} Payment:</strong>  <FormattedNumber
                                                                    value={this.props.customer.payment} style="currency"
                                                                    currency="Php"/> </span>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col">
                                                                <span><strong>OR Number:</strong> {this.props.customer.or_number ? this.props.customer.or_number : ''}</span>
                                                            </div>
                                                            <div className="col">
                                                                <span><strong>Contact Number:</strong> {this.props.customer.contact_number ? this.props.customer.contact_number : ''}</span>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col">
                                                                <span><strong>Customer Address:</strong> {this.props.customer.customer_address ? this.props.customer.customer_address : ''}</span>
                                                            </div>
                                                            <div className="col">
                                                                <span><strong>Remarks:</strong> {this.props.customer.remarks}</span>
                                                            </div>
                                                        </div>

                                                        <div className="row">
                                                            <div className="col">
                                                                <span><strong>Date Purchased:</strong> {Moment(this.props.customer.date_purchased).format('MM/DD/YYYY')}</span>
                                                            </div>
                                                            <div className="col">
                                                                <span><strong>Area:</strong> {this.props.customer.area}</span>
                                                            </div>

                                                        </div>
                                                        <div className="row">
                                                            <div className="col">
                                                                <span><strong>Promissory Note: </strong>
                                                                    {
                                                                        !prohibited_acc.includes(customer.account_status) ?
                                                                            !this.props.customer.type ? "N/A" :
                                                                                <FormattedNumber
                                                                                    value={parseInt(this.props.customer.promisory_note)}
                                                                                    style="currency" currency="Php"/>
                                                                            : 'N/A'
                                                                    }


                                                                 </span>
                                                            </div>
                                                            <div className="col">
                                                                <span><strong>Terms:</strong> <span
                                                                    className="new-terms"> {this.props.customer.term ? this.props.customer.term + ' months' : 'N/A'}</span></span>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col">
                                                             <span><strong>Monthly Amortization: </strong>
                                                                 {
                                                                     !prohibited_acc.includes(customer.account_status) ?
                                                                         !this.props.customer.type ? "N/A" :
                                                                             <FormattedNumber
                                                                                 value={parseInt(this.state.monthly)}
                                                                                 style="currency" currency="Php"/>
                                                                         : 'N/A'
                                                                 }
                                                             </span>
                                                            </div>
                                                            <div className="col">

                                                                {
                                                                    !prohibited_acc.includes(customer.account_status) || type == 0 ?
                                                                        <div className="cbox">
                                                                            <span
                                                                                className="exp badge badge-success">{this.props.customer.expiry_status ? 'CONTRACT IS EXPIRED' : ''}</span>
                                                                            <label className="checkbox-data">
                                                                                <input type="checkbox"
                                                                                       onChange={(e) => this.status_change(e)}/> Check
                                                                                if customer contract is expired
                                                                            </label>
                                                                        </div> : null
                                                                }


                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col">

                                                                {
                                                                    !prohibited_acc.includes(customer.account_status) ?
                                                                        <div>
                                                                            <button
                                                                                className="btn btn-success show_sched"
                                                                                onClick={() => this.showDiv()}>Show
                                                                                Schedule
                                                                            </button>
                                                                            <button
                                                                                className="btn btn-danger hide_sched"
                                                                                onClick={() => this.showDiv()}>Hide
                                                                                Schedule
                                                                            </button>
                                                                        </div> : null
                                                                }

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <br/>
                                                    <div className="row">
                                                        <div className="col-md-12">

                                                            <div className="container-fluid scroll-y">
                                                                {
                                                                    account_status == 1 ?
                                                                        <table id=""
                                                                               className="table table-lg tpaid table-bordered"/>
                                                                        : ''
                                                                }

                                                            </div>
                                                            <a href="javascript:;"
                                                               className="print-not-visible button-print btn btn-info"
                                                               onClick={() => window.print()}>
                                                                <i className="fa fa-print"/>
                                                                Print Schedule
                                                            </a>
                                                            <div className="container-fluid customer_sch scroll-y">


                                                                <br/>
                                                                <br/>
                                                                <div id="divToPrint">
                                                                    <table id="table-ledger"
                                                                           className="table table-sm tbl-ledger table-bordered "/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>


                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


const mapStateToProps = state => ({
    app: state.feathersClient.app,
    customer: state.customers.customerInfo,
    payment: state.customers.customerPayments,
    model: state.category.modelsList,
    brand: state.category.brandsList,
    userData: state.login.userData,
    userPermission: state.login.userPermission,
    ledger: state.customers.ledgerTableData,
    total_payments: state.customers.total_payments,
    paymentTableData: state.customers.paymentTableData,
    viewReportData: state.reports.viewReportData,
});

function mapDispatchToProps(dispatch) {
    return {actions: bindActionCreators(ActionCreators, dispatch)}
}

export default connect(mapStateToProps, mapDispatchToProps)(view_customer);
