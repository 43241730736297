import React, {Component} from 'react';

//redux
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
// actions
import {ActionCreators} from '../../../actions';

//select tag
import Select from 'react-select';
import 'react-select/dist/react-select.css';

//main NavBar
import MainNav from '../../../components/global_nav';
import MainSideBar from '../../../components/global_sidebar';

import moment from 'moment';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import '../report/plugins/daterangepicker.css';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

//custom helpers
import {
    numberWithCommas
} from '../../../helpers/';

//custom sidebar
import SubSideBar from './sub_sidebar';

import toastr from 'toastr';

import app from '../../../helpers/feathers';

const $ = require('jquery');

toastr.options = {
    "positionClass": "toast-bottom-right"
}

class update_secondhand extends Component {
    constructor(props) {
        super(props);

        this.state = {
            brand: null,
            model: null,
            engine_number: null,
            chassis_number: null,
            color: null,
            // date_delivered: moment().format('MM/DD/YYYY'),
            date_delivered: moment(),
            location: null,
            delivery_receipt_number: null,
            // delivery_receipt_date: moment().format('MM/DD/YYYY'),
            date_foreclosed: moment(),
            delivery_receipt_date: moment(),
            invoice_number: null,
            // invoice_date: moment().format('MM/DD/YYYY'),
            invoice_date: moment(),
            price: null,
            saveTemplate: false,
            models: this.props.models,
            selectedOption: '',
            selectChanged: false,
            selectedBranch: '',
            selectedBranchChanged: false,
            selectBranch: [
                {value: localStorage.getItem('local_branch_id'), label: localStorage.getItem('local_branch_name')}
            ],
            selectedBrand: '',
            selectedBrandChanged: false,
            warranty_claims: this.props.updateUnitInfo.warranty_claims,
            clearances: this.props.updateUnitInfo.clearances,
            tba: this.props.updateUnitInfo.tba,
            warranty_claims_is_changed: false,
            tba_is_changed: false,
            clearances_is_changed: false,
            date_delivered_changed: false,
            invoice_date_changed: false,
            delivery_receipt_date_changed: false,
            changes_made: false,
            saving: false,
            modelsObj: [],
            discount: '',
            account_number: '',
            remarks: '',
        };
    }

    componentWillMount() {
        const static_branch = {
            value: localStorage.getItem('local_branch_id'),
            label: localStorage.getItem('local_branch_name')
        }

        this.setState({selectedBranch: static_branch});
    }

    componentDidMount() {
        const app = this.props.app;

        // if (this.state.warranty_claims) {
        //     this.refs.warranty_claims.click();
        // }
        // if (this.state.clearances) {
        //     this.refs.clearances.click();
        // }
        // if (this.state.tba) {
        //     this.refs.tba.click();
        // }
    }

    hasWhiteSpace = (s) => {
        if (s != null) {
            return s.indexOf(' ') >= 0;
        }
    }

    singleToast = (text) => {
        toastr.remove();
        toastr.error(text)
    }

    clearInputs = () => {
        this.setState({
            selectedBrand: '',
            model: null,
            engine_number: null,
            chassis_number: null,
            color: null,
            // date_delivered: null,
            location: null,
            delivery_receipt_number: null,
            // delivery_receipt_date: null,
            invoice_number: null,
            // invoice_date: null,
            price: null,
            selectedOption: '',
            selectedBranch: '',
        })

        // this.refs.brand.value = '';
        // this.refs.model.value = '';
        this.refs.engine_number.value = '';
        this.refs.chassis_number.value = '';
        this.refs.color.value = '';
        // this.refs.date_delivered.value = '';
        // this.refs.location.value = '';
        this.refs.delivery_receipt_number.value = '';
        // this.refs.delivery_receipt_date.value = '';
        this.refs.invoice_number.value = '';
        // this.refs.invoice_date.value = '';
        // this.refs.price.value = '';
    }

    saveTemplate = () => {
        if (this.state.saveTemplate) {
            this.setState({saveTemplate: false})
        } else {
            this.setState({saveTemplate: true})
        }
    }

    save = (saveAndClose) => {
        const brand = this.state.selectedBrand,
            model = this.state.model,
            selectedmodel = this.state.selectedOption,
            engine_number = this.state.engine_number,
            chassis_number = this.state.chassis_number,
            discount = this.state.discount,
            remarks = this.state.remarks,
            account_number = this.state.account_number,
            date_delivered = this.state.date_delivered,
            location = this.state.selectedBranch,
            delivery_receipt_number = this.state.delivery_receipt_number,
            delivery_receipt_date = this.state.delivery_receipt_date,
            date_foreclosed = this.state.date_foreclosed,
            invoice_number = this.state.invoice_number,
            invoice_date = this.state.invoice_date,
            price = this.state.price,
            saveTemplate = this.state.saveTemplate,
            warranty_claims = this.state.warranty_claims ? 1 : 0,
            clearances = this.state.clearances ? 1 : 0,
            tba = this.state.tba ? 1 : 0,
            owner_name = this.refs.owner_name.value.trim(),
            branch = this.props.userData.branch_info._id;

        // console.log(location+' '+brand+' '+selectedmodel); return;
        const permission = this.props.userPermission;

        if (permission.inventory !== 2) {
            return;
        }

        if (this.state.saving) {
            return;
        }

        const trapper = {
            query: {
                chassis_number: chassis_number
            }
        }

        const unit = this.props.updateSecondhandUnitInfo,
            current_engine_number = unit.engine_number,
            current_model = unit.model.name,
            current_account_number = unit.account_number,
            current_date_foreclosed = unit.date_foreclosed,
            current_selling_price = unit.selling_price,
            current_discount = unit.discount,
            current_owner_name = unit.customer,
            origin_id = unit.origin_id;

        // const data = {
        // 	brand: brand.value,
        // 	model: selectedmodel ? selectedmodel.value : null,
        // 	engine_number: engine_number,
        // 	chas: chassis_number,
        // 	color: color ? color.toUpperCase() : '',
        // 	branch: localStorage.getItem('local_branch_id'),
        // 	delivery: {
        // 		date: date_delivered,
        // 		location: localStorage.getItem('local_branch_id'),
        // 		receipt_number: delivery_receipt_number,
        // 		receipt_date: delivery_receipt_date,
        // 	},
        // 	invoice: {
        // 		invoice_number: invoice_number,
        // 		date: invoice_number ? invoice_date : '',
        // 	},
        // 	price: price,
        // 	warranty_claims: warranty_claims,
        // 	clearances: clearances,
        // 	tba: tba,
        // 	date_created: moment().format('MM/DD/YYYY'),
        // }

        const product_id = this.props.updateSecondhandUnitInfo._id,
            query = {}

        query.data_status = false;

        if (engine_number !== current_engine_number) {
            if (engine_number) {
                query.engine_number = engine_number
                query.data_status = true;
            }
        }
        if (discount !== current_discount) {
            if (discount) {
                query.discount = discount
                query.data_status = true;
            }
        }
        if (account_number !== current_account_number) {
            if (account_number) {
                query.account_number = account_number
                query.data_status = true;
            }
        }
        if (moment(date_foreclosed).format('MM/DD/YYYY') !== moment(current_date_foreclosed).format('MM/DD/YYYY')) {
            query.date_foreclosed = date_foreclosed
            query.data_status = true;
        }
        if (price !== current_selling_price) {
            if (price) {
                query.selling_price = price;
                query.data_status = true;
            }
        }
        if (remarks) {
            query.remarks = remarks.toUpperCase();
            query.data_status = true;
        }
        if (owner_name !== current_owner_name) {
            if (owner_name) {
                query.customer = owner_name;
                query.data_status = true;
            }
        }

        if (isNaN(discount)) {
            toastr.remove();
            toastr.warning('Please enter a valid amount')
            $('[name="discount"]').focus();
            return;
        }
        if (!query.data_status) {
            toastr.remove();
            toastr.info('No changes made');
            return;
        }

        this.setState({
            saving: true
        })

        if (engine_number !== current_engine_number) {
            app.service('secondhand').find({
                query: {
                    model: {
                        name: current_model
                    },
                    engine_number: engine_number
                }
            }).then((e_data) => {
                if (e_data.total) {
                    toastr.remove();
                    toastr.error('Engine Number ' + engine_number + ' already exists');
                    this.setState({
                        saving: false
                    });
                } else {
                    app.service('secondhand').patch(product_id, query)
                        .then((result) => {
                            toastr.remove();
                            toastr.success('Unit successfully updated');

                            // app.service('secondhand').find({
                            //     query: {
                            //         origin_id: origin_id
                            //     }
                            // }).then((main_secondhand) => {
                            //     const secondhand_id = main_secondhand.data[0]._id;
                            //     app.service('secondhand').patch(secondhand_id, query)
                            //     .then((main_secondhand_patched) => {
                            //         console.log('main_secondhand_patched ', main_secondhand_patched)
                            //     })
                            // })

                            setTimeout(() => {
                                this.props.history.push('/inventory_secondhand/');
                            }, 2000)
                        }).catch((err) => {
                            console.log('Error ', err)

                            this.setState({
                                saving: false
                            });

                            toastr.remove();
                            toastr.error('An error occured!. Please try again');
                        });
                }
            })
        } else {
            app.service('secondhand').patch(product_id, query)
                .then((result) => {
                    if (result) {
                        toastr.remove();
                        toastr.success('Unit successfully updated');
                        // app.service('secondhand').find({
                        //     query: {
                        //         origin_id: origin_id
                        //     }
                        // }).then((main_secondhand) => {
                        //     const secondhand_id = main_secondhand.data[0]._id;
                        //     app.patch(secondhand_id, query)
                        //         .then((main_secondhand_patched) => {
                        //             console.log('main_secondhand_patched ', main_secondhand_patched)
                        //         })
                        // })
                        setTimeout(() => {
                            this.props.history.push('/inventory_secondhand/');
                        }, 2000)
                    } else {
                        this.setState({
                            saving: false
                        })
                        toastr.remove();
                        toastr.error('An error occured!. Please try again');
                    }
                }).catch((err) => {
                console.log('Error ', err)
                this.setState({
                    saving: false
                })
                toastr.remove();
                toastr.error('An error occured!. Please try again');
            })
        }
    }

    updateMainInventory = (id, query) => {
        const productService = app.service('products');

        productService.patch(id, query)
            .then((update) => {
                console.log('update ', update)
            }).catch((error) => {
                console.log('update error ', error)
            });
    }

    branchOption = () => {
        const array = this.props.branch,
            newArray = []
        array.map((obj) => {
            if (obj.value !== 'all') {
                newArray.push(obj)
            }
        });
        return newArray;
    }

    modelOption = () => {
        const array = this.props.models,
            newArray = []

        array.map((obj) => {
            if (obj.value !== 'all') {
                newArray.push(obj)
            }
        });
        return newArray;
    }

    brandOption = () => {
        const array = this.props.brandsSelect,
            newArray = []

        array.map((obj) => {
            if (obj.value !== 'all') {
                newArray.push(obj)
            }
        });
        return newArray;
    }

    handleChange = (selectedOption) => {
        const value = selectedOption;

        if (value != null) {
            this.setState({
                selectedOption: value,
                selectChanged: true
            });
        } else {
            this.setState({
                selectedOption: '',
                selectChanged: false
            });
        }
        this.setState({
            changes_made: true
        });
    }

    handleChangeBranch = (selectedOption) => {
        const value = selectedOption;

        if (value != null) {
            this.setState({
                selectedBranch: value,
                selectedBranchChanged: true
            });
        } else {
            this.setState({
                selectedBranch: '',
                selectedBranchChanged: false
            });
        }
    }


    handleChangeBrand = (selectedOption) => {
        this.setState({
            selectedBrand: selectedOption
        });

        const value = selectedOption;

        if (value != null) {
            this.setState({
                selectedBrand: value,
                selectedBrandChanged: true
            });
            const model = this.state.selectedOption.value,
                brand = value.value;
            this.props.actions.findChassisUsingBrand(brand)
                .then((d) => {
                    const models = d.data,
                        modelsObj = [];

                    models.map((v) => {
                        modelsObj.push({
                            label: v.model_name,
                            value: v._id
                        });
                    });

                    this.setState({
                        selectedOption: '',
                        chassisOption: '',
                        modelsObj: modelsObj,
                    });
                });
        } else {
            this.setState({
                selectedBrand: '',
                selectedBrandChanged: false,
                modelsObj: [],
                selectedOption: '',
            });
        }
    }

    permissionInventory = () => {
        const permission = this.props.userPermission;

        if (permission.inventory === 0) {
            return "not-visible"
        } else {
            return "";
        }
    }

    mainBranchOption = (value = false) => {
        const array = this.props.branch,
            newArray = [];

        array.map((obj) => {
            if (obj.label === 'MAIN') {
                newArray.push(obj)
            }
        });

        return value ? newArray[0].value : newArray;
    }

    handleDateForeclosed = (date) => {

        this.setState({
            date_foreclosed: date
        });
    }

    render() {
        const unit = this.props.updateSecondhandUnitInfo,
            model = unit.model.name,
            engine_number = unit.engine_number,
            date_created = moment(unit.createdAt).format('MM/DD/YYYY'),
            date_foreclosed = moment(unit.date_foreclosed).format('MM/DD/YYYY'),
            discount = unit.discount,
            account_number = unit.account_number,
            remarks = unit.remarks,
            customer = unit.customer,
            price = unit.selling_price;

        return (
            <div className="">
                {/* main NavBar component*/}
                <MainNav historyProp={this.props.history} usersProp={this.props.userData}/>
                <div className="container-fluid">
                    <div className="row full-width">
                        <div className="sidebar">
                            <div className="sidebar-content">
                                <MainSideBar page="inventory"/>
                            </div>
                        </div>
                        <SubSideBar history={this.props.history} userPermission={this.props.userPermission}/>
                        <div className="sub-inv-main-content add_inventory">
                            <div className="application-container">
                                {
                                    this.props.userPermission.inventory === 0 ?
                                        <div className="row no-access-div">
                                            <div className="col-md-12">
                                                <h1>Access denied</h1>
                                            </div>
                                        </div>
                                        : null
                                }
                                <div className={this.permissionInventory()}>
                                    <div className="row margin-y-24">
                                        <div className="col-md-12">
                                            <a
                                                href="javascript:;"
                                                onClick={() => this.props.history.goBack()}
                                                className="text-gray no-textdecoration breadcrumb-inv"
                                            >
                                                <span className="fa fa-chevron-left"/> Inventory / {model}
                                            </a>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <h4 className="subheader-title">Update Unit</h4>
                                                    <br/>
                                                    <div className="inv-form__wrapper">
                                                        <div className="inv-form__col">
                                                            <div className="inv-form__div">
                                                                <span>Model : <b>{model}</b></span>
                                                                <input type="text"
                                                                       className="form-control"
                                                                       value={model}
                                                                       disabled={true}/>
                                                            </div>
                                                            <div className="inv-form__div">
                                                                <span>Engine No. : <b>{engine_number}</b></span>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    name="engine_number"
                                                                    ref="engine_number"
                                                                    onChange={(e) => this.setState({engine_number: e.target.value})}
                                                                    maxLength={20}
                                                                    placeholder="Enter Engine No."
                                                                />
                                                            </div>
                                                            <div className="inv-form__div">
                                                                <span>Selling Price : <b>{typeof price !== undefined ? numberWithCommas(price ? price : 0) : 0}</b></span>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    name="price"
                                                                    ref="price"
                                                                    onChange={(e) => this.setState({price: e.target.value})}
                                                                    maxLength={20}
                                                                    placeholder="Enter Selling Price"
                                                                />
                                                            </div>
                                                            <div className="inv-form__div">
                                                                <span>Discount (%) : <b>{(typeof discount != 'undefined' ? `${discount}%` : null)}</b></span>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    name="discount"
                                                                    ref="discount"
                                                                    onChange={(e) => this.setState({discount: e.target.value})}
                                                                    maxLength={5}
                                                                    placeholder="Enter Discount"
                                                                />
                                                            </div>
                                                            <div className="inv-form__div">
                                                                <br/>
                                                            </div>
                                                        </div>
                                                        <div className="inv-form__col">
                                                            <div className="inv-form__div">
                                                                <span>Customer Name : <b>{customer}</b></span>
                                                                <input type="text"
                                                                       className="form-control"
                                                                       ref="owner_name"
                                                                       placeholder="Enter Customer Name"/>
                                                            </div>
                                                            <div className="inv-form__div">
                                                                <span>Account No. : <b>{account_number}</b></span>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    name="account_number"
                                                                    ref="account_number"
                                                                    onChange={(e) => this.setState({account_number: e.target.value})}
                                                                    maxLength={20}
                                                                    placeholder="Enter Account No."
                                                                />
                                                            </div>
                                                            <div className="inv-form__div">
                                                                <span>Date Foreclosed: <b>{moment(date_foreclosed).format('MM/DD/YYYY')}</b></span>
                                                                <DatePicker
                                                                    selected={this.state.date_foreclosed}
                                                                    onChange={this.handleDateForeclosed}
                                                                    peekNextMonth
                                                                    showMonthDropdown
                                                                    showYearDropdown
                                                                    dropdownMode="select"
                                                                    className="form-control"
                                                                />
                                                            </div>
                                                            <div className="inv-form__div">
                                                                <span>Remarks : <b>{remarks}</b></span>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    name="remarks"
                                                                    ref="remarks"
                                                                    onChange={(e) => this.setState({remarks: e.target.value})}
                                                                    maxLength={20}
                                                                    placeholder="Enter Remarks"
                                                                />
                                                            </div>
                                                            <div className="inv-form__div">
                                                                <button className="btn btn-primary col-md-12"
                                                                        onClick={() => this.save()}>
                                                                    Save Changes
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


const mapStateToProps = state => ({
    app: state.feathersClient.app,
    mainapp: state.feathersClient.mainapp,
    models: state.category.modelsSelect,
    branch: state.category.branchesSelect,
    brandsSelect: state.category.brandsSelect,
    userData: state.login.userData,
    userPermission: state.login.userPermission,
    updateUnitInfo: state.product.updateUnitInfo,
    updateSecondhandUnitInfo: state.product.updateSecondhandUnitInfo,
});

function mapDispatchToProps(dispatch) {
    return {actions: bindActionCreators(ActionCreators, dispatch)}
}

export default connect(mapStateToProps, mapDispatchToProps)(update_secondhand);

