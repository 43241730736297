import React, {Component} from 'react';

//redux
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
// actions
import {ActionCreators} from '../../../actions';

//main NavBar
import MainNav from '../../../components/global_nav';
import MainSideBar from '../../../components/global_sidebar';

//custom sidebar
import SubSideBar from './sub_sidebar';

import app from '../../../helpers/feathers';

//select tag
import Select from 'react-select';

import toastr from 'toastr';

//daterange
import moment from 'moment';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import '../report/plugins/daterangepicker.css';

//custom styles
import '../../../stylesheet/styles.css';

//datatables
import '../../../stylesheet/datatables/datatables.css';

const $ = require('jquery');
$.DataTable = require('datatables.net');

const that = this;

class cash_and_installments extends Component {

    constructor(props) {
        super(props);

        this.state = {
            showModal: false,
            product: this.props.product.product_list,
            products: [],
            product_count: 0,
            selectedOption: '',
            models: this.props.models,
            filter_model: null,
            filter_color: null,
            filter_branch: null,
            filter_engine_number: null,
            selectChanged: false,
            selectedBranch: '',
            selectedBrand: '',
            selectedBrandChanged: false,
            selectBranchChanged: false,
            modelsObj: [],
            startDate: moment().subtract(29, 'days'),
            endDate: moment(),
            drPickerApplied: false,
            selectedDaterange: false,
            total: 0
        };
    }

    componentWillMount() {
    }

    componentDidMount() {

        const productTable = $('.tbl-cash-installments').DataTable({
            data: this.state.products,
            columns: [
                {"title": "MODEL"},
                {"title": "PAYMENT"},
            ],
            columnDefs: [
                // {
                //     targets: [6],
                //     orderable: false,
                //     width: '15%',
                // },
                // {
                //     targets: [0],
                //     visible: false
                // }
            ],
            info: false,
            "sDom": '<"bottom"<t>ip><"clear">'
        });

        $('.search').keyup(function () {
            productTable.search($(this).val()).draw();
        });
    }

    permissionInventory = () => {
        const permission = this.props.userPermission;

        if (permission.inventory === 0) {
            return "not-visible"
        } else {
            return "";
        }
    }


    productList = data => {
        this.setState({
            products: data,
            product_count: data.length
        });

        const table = $('.tbl-cash-installments').DataTable();
        table.clear();
        table.rows.add(data);
        table.draw();

    }

    handleChange = (selectedOption) => {
        const value = selectedOption;

        if (value !== null) {
            this.setState({
                selectedOption: value,
                selectChanged: true
            });

            if (value.value === 1) {
                this.props.actions.getTotalInstallment()
                    .then((data) => {
                        this.productList(data)
                        this.setState({
                            total: data.extra
                        });
                    });
            } else {
                this.props.actions.getTotalCash()
                    .then((data) => {
                        this.productList(data)
                        this.setState({
                            total: data.extra
                        });
                    });
            }
        } else {
            this.setState({
                selectedOption: '',
                selectChanged: false,
            });
        }
    }

    render() {
        return (
            <div className="">
                {/* main NavBar component*/}
                <MainNav historyProp={this.props.history} usersProp={this.props.userData}/>
                <div className="container-fluid">
                    <div className="row full-width">
                        <div className="sidebar">
                            <div className="sidebar-content">
                                <MainSideBar page="report"/>
                            </div>
                        </div>
                        <SubSideBar history={this.props.history} allProps={this.props}
                                    userPermission={this.props.userPermission} pageName="cash_and_installments"/>
                        <div className="sub-inv-main-content">
                            <div className="container-fluid">
                                {
                                    this.props.userPermission.inventory === 0 ?
                                        <div className="row no-access-div">
                                            <div className="col-md-12">
                                                <h1>Access denied</h1>
                                            </div>
                                        </div>
                                        : null
                                }
                                <div className={this.permissionInventory()}>
                                    <div className="application-container">
                                        <div className="row">
                                            <div className="col-md-8">
                                                <h4 className="subheader-title">Installments and Cash Payments</h4>
                                            </div>
                                            <div className="col-md-3">
                                                <Select
                                                    className="add-new-btn"
                                                    name="select-model"
                                                    value={this.state.selectedOption.value}
                                                    onChange={this.handleChange}
                                                    // options={this.props.models}
                                                    options={[
                                                        {label: 'Cash', value: 0},
                                                        {label: 'Installment', value: 1},
                                                    ]}
                                                    clearableValue={true}
                                                    placeholder="Select Type"
                                                />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-4">
                                                <div className="justify-content-left content-header">
                                                    <input type="search"
                                                           className="search-class search form-control"
                                                           placeholder="&#xF002; Search"/>
                                                </div>
                                            </div>
                                        </div>
                                        <br/>
                                        <div className="row">
                                            <div className="col-md-8">
                                                <span>Result : {this.state.product_count}</span>
                                            </div>
                                            <div className="col-md-4">
                                                <span>TOTAL : {this.state.total}</span>
                                            </div>
                                            <div className="col-md-12">
                                                <table className="table tbl-cash-installments tbl-mobile">
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    app: state.feathersClient.app,
    product: state.product,
    models: state.category.modelsSelect,
    branch: state.category.branchesSelect,
    brandsSelect: state.category.brandsSelect,
    selectmodellist: state.category.modelsSelect,
    userData: state.login.userData,
    userPermission: state.login.userPermission,
});

function mapDispatchToProps(dispatch) {
    return {actions: bindActionCreators(ActionCreators, dispatch)}
}

export default connect(mapStateToProps, mapDispatchToProps)(cash_and_installments);
