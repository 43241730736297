import React, { Component } from 'react';

import CustomerModal from './select_customer_modal';

// react-router
import { Link } from 'react-router-dom';

class sub_sidebar extends Component {

	componentDidMount() {
	  	var pathName = this.props.history.location.pathname;
	    let permission = this.props.userPermission;
		console.log(permission)
	}

  render() {
  	var pathName = this.props.history.location.pathname;
    let permission = this.props.userPermission,
        // link_installment = "/customer/",
        // link_cash = "/customer/cash",
        // link_bad_record = "/customer/badrecords"
        link_installment = permission !== undefined ? permission.customer === 0 ? "#" : "/customer/" : "/customer/",
        link_customer_secondhand = permission !== undefined ? permission.customer === 0 ? "#" : "/customer_secondhand/" : "/customer_secondhand/",
        link_cash = permission !== undefined ? permission.customer === 0 ? "#" : "/customer_cash" : "/customer_cash",
        link_bad_record = permission !== undefined ? permission.customer === 0 ? "#" : "/customer_badrecords" : "/customer_badrecords",
        add_customer_btn =  permission !== undefined ? permission.customer !== 2 ? "#" : "modal" : "#"

    return (
    	<div>
            {/* select customer modal*/}
 	 		<CustomerModal history={this.props.history} />
	     	<div className="sub-sidebar">
	            <ul className="nav flex-column list">
	                <li className="nav-item">
	                    <h4>Customers</h4>
	                </li>
	                <li className="nav-item">
	                    <button className="btn padding-10-40" data-toggle={add_customer_btn} data-target={pathName.includes('add_customer') ? null : "#selectCustomerModal-disabled"} >NEW CUSTOMER</button>
	                </li>
	                <li className="nav-item">
	                    <Link className={pathName === '/customer/' ? "nav-link activeLabel" : "nav-link"}  to={link_installment}>Customer List</Link>
	                </li>
	                <li className="nav-item">
	                    <Link className={pathName === '/customer_secondhand/' ? "nav-link activeLabel" : "nav-link"}  to={link_customer_secondhand}>Customer List <br /> (2nd Hand)</Link>
	                </li>
	                {
	                // <li className="nav-item">
	                //     <Link className={pathName === '/customer_cash' ? "nav-link activeLabel" : "nav-link"} to={link_cash}>Cash</Link>
	                // </li>
	                // <li className="nav-item">
	                //     <Link className={pathName === '/customer_badrecords' ? "nav-link activeLabel" : "nav-link"} to={link_bad_record}>Bad Records</Link>
	                // </li>
	                }
	            </ul>
	        </div>
        </div>
    );
  }
}

export default sub_sidebar;
