import React, {Component} from 'react';

// react-router
import {Link} from 'react-router-dom';

class sub_sidebar extends Component {

    componentDidMount() {
    }

    add_customer = () => {
        const permission = this.props.userPermission,
            that = this;

        if (permission !== undefined) {
            if (permission.accounting === 2) {
                that.props.history.push('/add_new_customer')
            }
        }
    }

    render() {
        const pathName = this.props.path,
            {page, path} = this.props;
        let permission = this.props.userPermission,
            link_installment = permission !== undefined ? permission.accounting === 0 ? "#" : "/old_record_customer/" : "/old_record_customer/",
            link_cash_customer = permission !== undefined ? permission.accounting === 0 ? "#" : "/old_record_customer_cash/" : "/old_record_customer_cash/",
            link_fully_paid = permission !== undefined ? permission.accounting === 0 ? "#" : "/old_record_customer_fullypaid/" : "/old_record_customer_fullypaid/",
            link_customer_repossessed = permission !== undefined ? permission.accounting === 0 ? "#" : "/old_record_customer_repossessed/" : "/old_record_customer_repossessed/";

        let link_installment_sh = permission !== undefined ? (permission.accounting === 0 ? "#" : "/old_record_customer_sh/") : "/old_record_customer_sh/";
        let link_cash_sh = permission !== undefined ? (permission.accounting === 0 ? "#" : "/old_record_customer_cash_sh/") : "/old_record_customer_cash_sh/";
        let link_fullypaid_sh = permission !== undefined ? (permission.accounting === 0 ? "#" : "/old_record_customer_fullypaid_sh/") : "/old_record_customer_fullypaid_sh/";
        let link_repossessed_sh = permission !== undefined ? (permission.accounting === 0 ? "#" : "/old_record_customer_repossessed_sh/") : "/old_record_customer_repossessed_sh/";

        let {pageTitle} = this.props;

        return (
            <div>
                {/* select customer modal*/}
                <div className="sub-sidebar inv-sub-sidebar old-record-sub-sidebar">
                    {/*<ul className="nav flex-column list">*/}
                    <ul className="list">
                        {/*<li className="nav-item">*/}
                        <li className="non-nav subheader__name">
                            <h4>Customers</h4>
                        </li>
                        <li className="nav-item">Brand New</li>
                        <li className={pageTitle === "CUSTOMERS_BRANDNEW" ? "nav-item active" : "nav-item"}>
                            <Link
                                className={pathName === '/old_record_customer/' ? "nav-link activeLabel" : (page === 'installment' ? "nav-link activeLabel" : "nav-link")}
                                to={link_installment}>Installment</Link>
                        </li>
                        <li className={pageTitle === "CUSTOMERS_BRANDNEW_CASH" ? "nav-item active" : "nav-item"}>
                            <Link
                                className={pathName === '/old_record_customer_cash/' ? "nav-link activeLabel" : (page === 'cash' ? "nav-link activeLabel" : "nav-link")}
                                to={link_cash_customer}>Cash</Link>
                        </li>

                        <li className={pageTitle === "CUSTOMERS_FULLY_PAID" ? "nav-item active" : "nav-item"}>
                            <Link
                                className={pathName === '/old_record_customer_fullypaid/' ? "nav-link activeLabel" : (page === 'fully_paid' ? "nav-link activeLabel" : "nav-link")}
                                to={link_fully_paid}>Fully Paid</Link>
                        </li>
                        <li className={pageTitle === "CUSTOMERS_REPOSSESSED" ? "nav-item active" : "nav-item"}>
                            <Link
                                className={pathName === '/old_record_customer_repossessed/' ? "nav-link activeLabel" : (page === 'repo' ? "nav-link activeLabel" : "nav-link")}
                                to={link_customer_repossessed}>Repossessed</Link>
                        </li>
                        {window.outerWidth <= 768 ? '' :
                            <li className="nav-item divider"></li>
                        }
                        <li className="nav-item">Secondhand</li>
                        <li className={page === "installment_sh" ? "nav-item active" : "nav-item"}>
                            <Link
                                className={(page === 'installment_sh' ? "nav-link activeLabel" : "nav-link")}
                                to={link_installment_sh}>Installment</Link>
                        </li>
                        <li className={page === "cash_sh" ? "nav-item active" : "nav-item"}>
                            <Link
                                className={(page === 'cash_sh' ? "nav-link activeLabel" : "nav-link")}
                                to={link_cash_sh}>Cash</Link>
                        </li>
                        <li className={page === "fully_paid_sh" ? "nav-item active" : "nav-item"}>
                            <Link
                                className={(page === 'fully_paid_sh' ? "nav-link activeLabel" : "nav-link")}
                                to={link_fullypaid_sh}>Fully Paid</Link>
                        </li>
                        <li className={page === "repossessed_sh" ? "nav-item active" : "nav-item"}>
                            <Link
                                className={(page === 'repossessed_sh' ? "nav-link activeLabel" : "nav-link")}
                                to={link_repossessed_sh}>Repossessed</Link>
                        </li>
                        <br/>
                    </ul>
                </div>
            </div>
        );
    }
}

export default sub_sidebar;