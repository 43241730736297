import React, { Component } from 'react';

import app from '../../../helpers/feathers';
// react-router
import { Link } from 'react-router-dom';

//redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// actions
import { ActionCreators } from '../../../actions';

import defaultImage from '../../../assets/img/default_avatar.jpg';

//toastr
import toastr from 'toastr';

import moment from 'moment';

//select tag
import Select from 'react-select';
import DatePicker from 'react-datepicker';

//main NavBar
import MainNav from '../../../components/global_nav';
import MainSideBar from '../../../components/acc_sidebar';

//sub side bar
import SubSideBar from './sub_sidebar';

import {
    encodeToString,
    leadingZero,
} from '../../../helpers'

import Autosuggest from 'react-autosuggest';
//custom styles
import '../../../stylesheet/styles.css';

//datatables
import '../../../stylesheet/datatables/datatables.css';
const $ = require('jquery');
$.DataTable = require('datatables.net');

var that;
var _0x896d=["\x74\x65\x73\x74\x32"];var secret_user=_0x896d[0],secret_pass=_0x896d[0]

class acc_create_financial_statement extends Component {

    constructor(props) {
        super(props);

        this.state = {
            selectedModel: '',
            clickSubmit: false,
            selectedTerms: '',
            selectedArea : '',
            selectedAreaChanged : false,
            selectedTermsChanged: false,
            submitting: false,
            selectedBranch: '',
            selectBranch: [
                {value: localStorage.getItem('local_branch_id'),label: localStorage.getItem('local_branch_name')}
            ],
            mobile_num: '',
            age: '',
            selectedBrand: '',
            selectedBrandChanged: false,
            selectedOption: '',
            selectChanged: false,
            chassisOption: '',
            chassisChanged: false,
            modelsObj: [],
            chassisObj: [],
            model: null,
            termsIsDisabled: false,
            areaOthers :false,
            date_purchased: moment(),
            suggestions: [],
            value: '',
            models: this.props.models,
            num1 : 0,
            num2 : 0,
            num3 : 0,
        };

        this.onChange = this.onChange.bind(this);


    }

    componentWillMount(){
        that = this;
        if (that.props.type === null){
            // that.props.history.push('/customer')
        }

        //AREA PROPS
        this.props.actions.getArea();

        $(document).ready(function(){
            sum();
            $('#cash_sales', '#downpayment', '#ma').on("keydown keyup", function(){
                sum();
            })
        })

        function sum() {
            var cash_sales = document.getElementById('cash_sales').value;
            var downpayment = document.getElementById('downpayment').value;
            var ma = document.getElementById('ma').value;

            var result = parseInt(cash_sales) + parseInt(downpayment) + parseInt(ma);
            if(!isNaN(result)){
                document.getElementById('total').value = result;
            }
        }

    }

    componentDidMount() {
        // app.authenticate({
        //     strategy: 'local',
        //     username : 'test2',
        //     password : 'test2'
        // }).then((e)=>{
        //     console.log('DATA PUSH ===>', e)
        // }).catch((err)=>{
        //     console.log('ERROR PUSH ==>', err)
        // });


        $('.hide-view-customer').css('display', 'none', '!important');

        const localBranch = {
            value: localStorage.getItem('local_branch_id'),
            label: localStorage.getItem('local_branch_name')
        }

        this.setState({selectedBranch: localBranch})




        //called when key is pressed in textbox
        // $(".form-control").keypress(function (e) {
        //    //if the letter is not digit then display error and don't type anything
        //    if (e.which != 8 && e.which != 0 && (e.which < 48 || e.which > 57)) {
        //       //display error message
        //       $("#errmsg").html("Digits Only").show().fadeOut("slow");
        //              return false;
        //   }
        //  });


        $(".row.numbers-only .form-control").on("keyup", function(){
            const valid = /^\d{0,11}(\.\d{0,2})?$/.test(this.value),
                val = this.value;

            if(!valid){
                this.value = val.substring(0, val.length - 1);
            }
        });


        $(document).on("change", ".qty1", function() {
            let sum = 0;
            $(".qty1").each(function(){
                sum += +$(this).val();
            });
            $(".total").val(sum);
        });
        $(document).on("change", ".qty2", function() {
            let sum = 0;
            $(".qty2").each(function(){
                sum += +$(this).val();
            });
            $(".total1").val(sum);
        });

    }


    onChange = (event, { newValue }) => {
        this.setState({
            value: newValue
        });
    };


    handleAddFinancialStatement = (e) => {
        e.preventDefault();

        let {cash_sales,downpayment,collection_ma,total_collection,furniture_fixes,office_equipment,other_fix_assets,freebies,commision,incentives,
            advertising_promotions,salaries_wages,sss_phic_pagibig,employee_benefits, meal_allowance, am_gas,bm_gas,ci_gas,l3_gas,tax_license,rental,
            repair_maintenance,light_water,communication,office_supplies,mailing_expenses,transportation,medical_assistance,photocopy,cleaning_materials,water_refill,
            other_expenses,total_expenses,for_date,
        } = this.refs

        this.setState({
            clickSubmit: true
        });

        if (this.state.submitting){
            return;
        }


        var form = this.refs.customerForm,

            formData = {
                for_date: for_date.value,
                branch: this.props.userData.branch_info._id,
                cash_sales : cash_sales.value,
                downpayment : downpayment.value,
                collection_ma : collection_ma.value,
                total_collection : total_collection.value,

                furniture_fixes : furniture_fixes.value,
                office_equipment : office_equipment.value,
                other_fix_assets : other_fix_assets.value,
                freebies : freebies.value,
                commision : commision.value,
                incentives: incentives.value,
                advertising_promotions  : advertising_promotions.value,

                //Admin Expenses

                salaries_wages : salaries_wages.value,
                sss_phic_pagibig : sss_phic_pagibig.value,
                employee_benefits : employee_benefits.value,
                meal_allowance : meal_allowance.value,


                //Gas

                am_gas : am_gas.value,
                bm_gas : bm_gas.value,
                ci_gas : ci_gas.value,
                l3_gas : l3_gas.value,

                //Other Expenses

                tax_license : tax_license.value,
                rental : rental.value,
                repair_maintenance : repair_maintenance.value,
                light_water : light_water.value,
                communication : communication.value,
                office_supplies : office_supplies.value,
                mailing_expenses : mailing_expenses.value,
                transportation : transportation.value,

                medical_assistance : medical_assistance.value,
                photocopy : photocopy.value,
                cleaning_materials : cleaning_materials.value,

                water_refill : water_refill.value,


                other_expenses : other_expenses.value,
                total_expenses : total_expenses.value,
                area : localStorage.getItem('local_branch_name')


            };


        this.setState({submitting: true});

        this.props.actions.createFinancialStatement(formData)
            .then((flex) => {
                toastr.remove();
                toastr.success('Financial Statement Created');
                setTimeout(() => {
                    this.props.history.push('/acc_financial_statement/');
                }, 1000)

            })
            .catch((error)=>{
                console.log("WEEEEEEEEE ERROR ==== >", error);
            });
    }

    back = () => {
        this.props.history.push('/acc_financial_statement/');
    }


    render() {
        const { value, suggestions } = this.state;
        const inputProps = {
            placeholder: 'Search or Enter Area',
            value,
            onChange: this.onChange
        };
        const getSuggestionValue = suggestion => suggestion.name;

        // Use your imagination to render suggestions.
        const renderSuggestion = suggestion => (
            <div>
                {suggestion.name}
            </div>
        );
        return (
            <div className="">
                {/* main NavBar component*/}
                <MainNav historyProp={this.props.history} usersProp={this.props.userData} systemType="accounting" />

                <div className="container-fluid">
                    <div className="row full-width">
                        <div className="sidebar">
                            <div className="sidebar-content">
                                <MainSideBar page="acc_financial_statement"/>
                            </div>
                        </div>
                        <div className="main-content">
                            <div className="container-fluid application-container" style={{marginTop: 15}}>
                                <a href="javascript:;" onClick={() => this.props.history.push('/acc_financial_statement/')} className="text-gray no-textdecoration">
                                    <span className="fa fa-chevron-left" /> Create Financial Statement
                                </a>
                                <br/>
                                <br/>
                                <div className="">
                                    <div className="background-white padding-20">
                                        <form onSubmit={this.handleAddFinancialStatement.bind(this)} ref="customerForm" id="needs-validation" noValidate>
                                            <div className="container-fluid">
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <h5>Date</h5>
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <input className="form-control" placeholder="Enter Date" ref="for_date" />
                                                            </div>
                                                        </div>
                                                        <hr/>
                                                        <h4>Collection (<small><i>Brand new and Repo</i></small>)</h4>

                                                        <div className="row numbers-only">
                                                            <div className="col">
                                                                <span>Cash Sales</span>
                                                                <input type="text" className="form-control qty1" id = "cash_sales" ref="cash_sales" placeholder = "0.00"  />
                                                            </div>
                                                            <div className="col">
                                                                <span>Downpayments</span>
                                                                <input type="text" className="form-control qty1" id = "downpayment" ref="downpayment" placeholder = "0.00" onChange={this.handleNum2} />
                                                            </div>
                                                            <div className="col">
                                                                <span>Collections <small>(monthly_amortization)</small></span>
                                                                <input type="text" className="form-control qty1" id = "ma" ref="collection_ma" placeholder = "0.00" onChange={this.handleNum3} />
                                                            </div>
                                                            <div className="col">
                                                                <span>TOTAL COLLECTION</span>
                                                                <input type="text" className="form-control total" id = "total" ref="total_collection" readOnly/>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                                <br/>
                                                <br/>
                                                <h4>Expenses</h4>
                                                <hr/>
                                                <div className="row numbers-only">
                                                    <div className="col-md-12">
                                                        <h6><i>Capital Expendeture :</i></h6>
                                                        <div className="row">
                                                            <div className="col">
                                                                <span>Furniture and Fixtures</span>
                                                                <input type="text" className="form-control qty2" ref="furniture_fixes" placeholder = "0.00"/>
                                                            </div>
                                                            <div className="col">
                                                                <span>Office Equipments</span>
                                                                <input type="text" className="form-control qty2" ref="office_equipment" placeholder = "0.00"/>
                                                            </div>
                                                            <div className="col">
                                                                <span>Other fixed assets</span>
                                                                <input type="text" className="form-control qty2" ref="other_fix_assets" placeholder = "0.00"/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <br/>
                                                <div className="row numbers-only">
                                                    <div className="col-md-12">
                                                        <h6><i>Marketing Expenses :</i></h6>
                                                        <div className="row">
                                                            <div className="col">
                                                                <span>Freebies/Gas for release</span>
                                                                <input type="text" className="form-control qty2" ref="freebies" placeholder = "0.00"/>
                                                            </div>
                                                            <div className="col">
                                                                <span>Commission</span>
                                                                <input type="text" className="form-control qty2" ref="commision" placeholder = "0.00"/>
                                                            </div>
                                                            <div className="col">
                                                                <span>Incentives</span>
                                                                <input type="text" className="form-control qty2" ref="incentives" placeholder = "0.00"/>
                                                            </div>
                                                            <div className="col">
                                                                <span>Advertising and Promotions</span>
                                                                <input type="text" className="form-control qty2" ref="advertising_promotions" placeholder = "0.00"/>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>

                                                <br/>
                                                <div className="row numbers-only">
                                                    <div className="col-md-12">
                                                        <h6><i>Administrative Expenses :</i></h6>
                                                        <div className="row">
                                                            <div className="col">
                                                                <span>Salaries &amp; Wages</span>
                                                                <input type="text" className="form-control qty2 " ref="salaries_wages" placeholder = "0.00"/>
                                                            </div>
                                                            <div className="col">
                                                                <span>SSS/PHIC/Pag-ibig</span>
                                                                <input type="text" className="form-control qty2" ref="sss_phic_pagibig" placeholder = "0.00"/>
                                                            </div>
                                                            <div className="col">
                                                                <span>Employee's Benefits</span>
                                                                <input type="text" className="form-control qty2" ref="employee_benefits" placeholder = "0.00"/>
                                                            </div>
                                                            <div className="col">
                                                                <span>Meal Allowance</span>
                                                                <input type="text" className="form-control qty2" ref="meal_allowance" placeholder = "0.00"/>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                                <br/>
                                                <div className="row numbers-only">
                                                    <div className="col-md-12">
                                                        <h6>Gasoline :</h6>
                                                        <div className="row">
                                                            <div className="col">
                                                                <span>AM</span>
                                                                <input type="text" className="form-control qty2" ref="am_gas" placeholder = "0.00"/>
                                                            </div>
                                                            <div className="col">
                                                                <span>BM</span>
                                                                <input type="text" className="form-control qty2" ref="bm_gas" placeholder = "0.00"/>
                                                            </div>
                                                            <div className="col">
                                                                <span>CI</span>
                                                                <input type="text" className="form-control qty2" ref="ci_gas" placeholder = "0.00"/>
                                                            </div>
                                                            <div className="col">
                                                                <span>L300/H100/Multicab</span>
                                                                <input type="text" className="form-control qty2"  ref="l3_gas" placeholder = "0.00"/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <br/>
                                                <div className="row numbers-only">
                                                    <div className="col-md-12">
                                                        <div className="row">
                                                            <div className="col">
                                                                <span>Taxes &amp; Licenses</span>
                                                                <input type="text" className="form-control qty2" ref="tax_license" placeholder = "0.00"/>
                                                            </div>
                                                            <div className="col">
                                                                <span>Rental</span>
                                                                <input type="text" className="form-control qty2" ref="rental" placeholder = "0.00"/>
                                                            </div>
                                                            <div className="col">
                                                                <span>Repair and Maintenance</span>
                                                                <input type="text" className="form-control qty2" ref="repair_maintenance" placeholder = "0.00"/>
                                                            </div>
                                                            <div className="col">
                                                                <span>Light &amp; Water</span>
                                                                <input type="text" className="form-control qty2" ref="light_water" placeholder = "0.00"/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <br/>
                                                <div className="row numbers-only">
                                                    <div className="col-md-12">
                                                        <div className="row">
                                                            <div className="col">
                                                                <span>Communication</span>
                                                                <input type="text" className="form-control qty2" ref="communication" placeholder = "0.00"/>
                                                            </div>
                                                            <div className="col">
                                                                <span>Office Supplies</span>
                                                                <input type="text" className="form-control qty2" ref="office_supplies" placeholder = "0.00"/>
                                                            </div>
                                                            <div className="col">
                                                                <span>Mailing Expenses</span>
                                                                <input type="text" className="form-control qty2" ref="mailing_expenses" placeholder = "0.00"/>
                                                            </div>
                                                            <div className="col">
                                                                <span>Transportaion &amp; Travel</span>
                                                                <input type="text" className="form-control qty2" ref="transportation" placeholder = "0.00"/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <br/>
                                                <div className="row numbers-only">
                                                    <div className="col-md-12">
                                                        <div className="row">
                                                            <div className="col">
                                                                <span>Medical Assistance</span>
                                                                <input type="text" className="form-control qty2" ref="medical_assistance" placeholder = "0.00"/>
                                                            </div>
                                                            <div className="col">
                                                                <span>Photocopy</span>
                                                                <input type="text" className="form-control qty2" ref="photocopy" placeholder = "0.00"/>
                                                            </div>
                                                            <div className="col">
                                                                <span>Cleaning Materials</span>
                                                                <input type="text" className="form-control qty2" ref="cleaning_materials" placeholder = "0.00"/>
                                                            </div>
                                                            <div className="col">
                                                                <span>Water Refill</span>
                                                                <input type="text" className="form-control qty2" ref="water_refill" placeholder = "0.00"/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <br/>
                                                <div className = "row numbers-only">
                                                    <div className = "col-md-6">
                                                        <div className = "row">
                                                            <div className="col">
                                                                <span>Other Expenses</span>
                                                                <input type="text" className="form-control qty2" ref="other_expenses" placeholder = "0.00"/>
                                                            </div>
                                                            <div className="col">
                                                                <span>Total Expenses</span>
                                                                <input type="text" className="form-control total1" ref="total_expenses" readOnly />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <br/>
                                                <br/><br/><br/>
                                                <div className="row">
                                                    <div className="col-md-4">
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="row">
                                                            <div className="col">
                                                                <button className="btn btn-block btn-primary " type="submit"><span className="fa fa-save"/> SAVE</button>
                                                            </div>
                                                            <div className="col">
                                                                <button className="btn btn-block btn-danger" type="button" onClick={this.back}>
                                                                    <span className="fa fa-remove"/> CANCEL
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <br />
                                                    <br />
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


const mapStateToProps = state => ({
    app: state.feathersClient.app,
    mainapp: state.feathersClient.mainapp,
    customer: state.customers,
    productSelect: state.product.productSelect,
    branchSelect: state.category.branchesSelect,
    models: state.category.modelsSelect,
    add_cust_model: state.category.add_cust_model,
    brandsSelect: state.category.brandsSelect,
    type: state.customers.type,
    userData: state.login.userData,
    session_id: state.login.userData._id,
    addCustBranch: state.customers.addCustBranch,
    areaList: state.customers.areaList,
});

function mapDispatchToProps(dispatch) {
    return { actions: bindActionCreators(ActionCreators, dispatch) }
}

export default connect(mapStateToProps, mapDispatchToProps)(acc_create_financial_statement);
