import React, {Component} from 'react';

// react-router
import {Link} from 'react-router-dom';

//redux
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
// actions
import {ActionCreators} from '../../../actions';

//main NavBar
import MainNav from '../../../components/global_nav';
import MainSideBar from '../../../components/global_sidebar';

//custom sidebar
import SubSideBar from './sub_sidebar';

//select tag
import Select from 'react-select';

//custom styles
import '../../../stylesheet/styles.css';

//datatables
import '../../../stylesheet/datatables/datatables.css';

import toastr from 'toastr';

import app from '../../../helpers/feathers';

toastr.options = {
    "positionClass": "toast-bottom-right"
}

const $ = require('jquery');
$.DataTable = require('datatables.net');

var that;

class user_permission extends Component {

    constructor(props) {
        super(props);

        this.state = {
            data: [],
            selectDashboard: [
                {label: 'No access', value: 0},
                {label: 'View only', value: 1},
                {label: 'Full access', value: 2},
            ],
            selectInventory: [
                {label: 'No access', value: 0},
                {label: 'View only', value: 1},
                {label: 'Full access', value: 2},
            ],
            selectCustomer: [
                {label: 'No access', value: 0},
                {label: 'View only', value: 1},
                {label: 'Full access', value: 2},
            ],
            selectReports: [
                {label: 'No access', value: 0},
                {label: 'View only', value: 1},
                {label: 'Full access', value: 2},
            ],
            selectUser: [
                {label: 'No access', value: 0},
                {label: 'View only', value: 1},
                {label: 'Full access', value: 2},
            ],
            selectCategory: [
                {label: 'No access', value: 0},
                {label: 'View only', value: 1},
                {label: 'Full access', value: 2},
            ],
            selectAccounting: [
                {label: 'No access', value: 0},
                {label: 'View only', value: 1},
                {label: 'Full access', value: 2},
            ],
            selectedDashboard: '',
            selectedInventory: '',
            selectedCustomer: '',
            selectedReports: '',
            selectedUser: '',
            selectedCategory: '',
            selectedAccounting: '',
            userPermissionInfo: [],
            selectedUserId: null,
            adminId: '',
            selectedDesignation: false
        };
    }

    componentWillMount() {
        that = this;
        app.service('user-position').find({
            query: {
                position_type: 'ADMINISTRATOR'
            }
        })
        .then((data) => {
            const adminId = data.data[0]._id;
            that.setState({adminId: adminId, selectedUserId: adminId})
        })
        .catch((err) => {
            console.log(err)
        })

        this.getDesignation();
        this.setInitialPermissions();
        // this.activeUserInPermission();
    }

    componentDidMount() {
    }

    getAllUsers = () => {
        this.props.actions.getAllUsersForPermissions().then((data) => {
            that.updateData(data);
        });
    }

    updateData = (data) => {
        this.setState({
            data: data[1]
        });

        const table = $('.tbl-users-permission').DataTable();
        table.clear();
        table.rows.add(data[1]);
        table.draw();
    }

    handleChangeDashboard = (selected) => {
        var value = selected;

        if (value === null) {
            this.setState({
                selectedDashboard: '',
            });
        } else {
            this.setState({
                selectedDashboard: value,
            });
        }
    }

    handleChangeInventory = (selected) => {
        var value = selected;

        if (value === null) {
            this.setState({
                selectedInventory: '',
            });
        } else {
            this.setState({
                selectedInventory: value,
            });
        }
    }

    handleChangeCustomer = (selected) => {
        this.setState({selectedCustomer: selected})
        var value = selected;

        if (value === null) {
            this.setState({
                selectedCustomer: '',
            });
        } else {
            this.setState({
                selectedCustomer: value,
            });
        }
    }

    handleChangeReports = (selected) => {
        var value = selected;

        if (value === null) {
            this.setState({
                selectedReports: '',
            });
        } else {
            this.setState({
                selectedReports: value,
            });
        }
    }

    handleChangeUser = (selected) => {
        var value = selected;

        if (value === null) {
            this.setState({
                selectedUser: '',
            });
        } else {
            this.setState({
                selectedUser: value,
            });
        }
    }

    handleChangeCategory = (selected) => {
        var value = selected;

        if (value === null) {
            this.setState({
                selectedCategory: '',
            });
        } else {
            this.setState({
                selectedCategory: value,
            });
        }
    }

    handleChangeAccounting = (selected) => {
        var value = selected;

        if (value === null){
            this.setState({
                selectedAccounting: '',
            });
        }else{
            this.setState({
                selectedAccounting: value,
            });
        }
    }

    updateState = () => {
        this.setState({
            selectedDashboard: this.props.selectedPermissions ? this.props.selectedPermissions.dashboard : '',
            selectedInventory: this.props.selectedPermissions ? this.props.selectedPermissions.inventory : '',
            selectedCustomer: this.props.selectedPermissions ? this.props.selectedPermissions.customer : '',
            selectedReports: this.props.selectedPermissions ? this.props.selectedPermissions.report : '',
            selectedUser: this.props.selectedPermissions ? this.props.selectedPermissions.user : '',
            selectedCategory: this.props.selectedPermissions ? this.props.selectedPermissions.category : '',
            selectedAccounting: this.props.selectedPermissions ? this.props.selectedPermissions.accounting : '',
        })
    }

    savePermission = () => {
        var that = this;

        var permission = that.props.userPermission;
        if (permission.user !== 2) {
            return;
        }

        var selectedDashboard = this.state.selectedDashboard ? this.state.selectedDashboard.value : '',
            selectedInventory = this.state.selectedInventory ? this.state.selectedInventory.value : '',
            selectedCustomer = this.state.selectedCustomer ? this.state.selectedCustomer.value : '',
            selectedReports = this.state.selectedReports ? this.state.selectedReports.value : '',
            selectedUser = this.state.selectedUser ? this.state.selectedUser.value : '',
            selectedCategory = this.state.selectedCategory ? this.state.selectedCategory.value : '',
            selectedAccounting = this.state.selectedAccounting ? this.state.selectedAccounting.value : '',
            selectedUserId = this.state.selectedUserId === null ? '5a7c2341077d691cee1b5f85' : this.state.selectedUserId,
            comparison = {
                dashboard: selectedDashboard,
                inventory: selectedInventory,
                user: selectedUser,
                customer: selectedCustomer,
                report: selectedReports,
                category: selectedCategory,
                accounting: selectedAccounting,
            };

        var theProps = {
            dashboard: this.props.selectedPermissions.dashboard.value,
            inventory: this.props.selectedPermissions.inventory.value,
            user: this.props.selectedPermissions.user.value,
            customer: this.props.selectedPermissions.customer.value,
            report: this.props.selectedPermissions.report.value,
            category: this.props.selectedPermissions.category.value,
            accounting: this.props.selectedPermissions.accounting.value,
        }

        if (!this.state.selectedDesignation || this.state.selectedDesignation == 'Administrator') {
            // console.log("this is admin");
            return;
        }

        if (
            selectedDashboard === this.props.selectedPermissions.dashboard.value &&
            selectedInventory === this.props.selectedPermissions.inventory.value &&
            selectedUser === this.props.selectedPermissions.user.value &&
            selectedCustomer === this.props.selectedPermissions.customer.value &&
            selectedReports === this.props.selectedPermissions.report.value &&
            selectedCategory === this.props.selectedPermissions.category.value &&
            selectedAccounting === this.props.selectedPermissions.accounting.value
        ) {
            toastr.info('No changes made')
            return;
        }

        that.props.actions.checkPermissions(selectedUserId).then((response) => {
            if (response.status > 0) {
                that.props.actions.updatePermissions(response.data, selectedDashboard, selectedInventory, selectedCustomer, selectedReports, selectedUser, selectedCategory, selectedAccounting)
                    .then((data) => {
                        toastr.remove();
                        toastr.success('Permissions saved!');
                        that.setInitialPermissions(this.state.selectedUserId);
                    })
                    .catch((err) => {
                        console.log('updatePermissions err ', err)
                    })
            } else {
                that.props.actions.savePermissions(selectedUserId, selectedDashboard, selectedInventory, selectedCustomer, selectedReports, selectedUser, selectedCategory, selectedAccounting).then((data) => {
                    toastr.remove();
                    toastr.success('Permissions saved!');
                    that.setInitialPermissions(this.state.selectedUserId);
                })
                    .catch((err) => {
                        console.log('savePermissions err ', err)
                    })
            }
        })
    };

    activeUserInPermission = (id) => {
        that.props.actions.activeUserInPermission(id);
    };

    setUserInPermission = (id, designation, perm, permID) => {
        const that = this;
        this.setState({selectedUserId: permID[0], selectedDesignation: permID[1]});
        this.setInitialPermissions(permID[0]);
        this.activeUserInPermission(permID[0]);
    };

    setInitialPermissions = (id = null) => {
        const that = this;
        app.service('user-position').find({
            query: {
                position_type: 'ADMINISTRATOR'
            }
        }).then((data) => {
            const a = this.props.activeUserInPermission === null ? data.data[0]._id : this.props.activeUserInPermission;
            that.props.actions.getSelectedPermissions(a)
            .then((data) => {
                let defaultPermissions = {
                    dashboard: 0,
                    inventory: 0,
                    user: 0,
                    customer: 0,
                    report: 0,
                    category: 0,
                    accounting: 0
                };
                const permissions = data.total > 0 ? data.data[0].permissions : defaultPermissions;
                that.props.actions.saveSelectedPermissions(permissions);
                that.updateState();
            })
        })
        .catch((err) => {
            console.log(err)
        })

    }

    getDesignation = () => {
        const that = this,
            tr = [];
        that.props.actions.getUserDesignation().then((data) => {
            if(data.length){
                that.props.actions.setDesignation(data);
            }
        });
    };

    designationList = () => {
        return this.props.designationList.map((result, k) => {
            let btnClass = result[0] === this.props.activeUserInPermission ? 'btn btn-block btn-primary' : 'btn btn-block btn-secondary'
            // icon = result[0] === this.props.activeUserInPermission ? <i class="fa fa-chevron-right pull-right"></i> : ''
            return <tr key={k}>
                <td>
                    <button className={btnClass}
                            onClick={() => this.setUserInPermission(result[0], result[1], this.props.activeUserInPermission, result)}>{result[1]}</button>
                </td>
            </tr>
        })
    }

    render() {
        return (
            <div className="">
                {/* main NavBar component*/}
                <MainNav historyProp={this.props.history} usersProp={this.props.userData}/>

                <div className="container-fluid">
                    <div className="row full-width">
                        <div className="sidebar">
                            <div className="sidebar-content">
                                <MainSideBar page="user"/>
                            </div>
                        </div>
                        <SubSideBar history={this.props.history} pageName="user_permission"
                                    userPermission={this.props.userPermission}/>
                        <div className="sub-inv-main-content">
                            {/*<div className="d-flex flex-row justify-content-left content-header">
                                 <input type="search" className="form-control form-control-lg align-items-center search" placeholder="&#xF002; Search" />
                            </div>*/}
                            <div className="container-fluid">
                                <div className="permissions-container">
                                    <div className="form-inline">
                                        <h4>User Roles/Permissions</h4>
                                    </div>
                                    <div>
                                        <br/>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="background-white">
                                                <table className="table tbl-users-permission tbl-mobile">
                                                    <tbody>
                                                    {
                                                        this.designationList()
                                                    }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div className="col-md-8">
                                            <div className="background-white padding-20">
                                                <table className="table u-permission-table tbl-mobile">
                                                    <tbody>
                                                    <tr>
                                                        <td>Dashboard Management</td>
                                                        <td>
                                                            <Select
                                                                name="selectDashboard"
                                                                value={this.state.selectedDashboard.value}
                                                                onChange={this.handleChangeDashboard}
                                                                options={this.state.selectDashboard}
                                                                placeholder="Select Permission"
                                                            />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Inventory Management</td>
                                                        <td>
                                                            <Select
                                                                name="selectInventory"
                                                                value={this.state.selectedInventory.value}
                                                                onChange={this.handleChangeInventory}
                                                                options={this.state.selectInventory}
                                                                placeholder="Select Permission"
                                                            />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Customer Management</td>
                                                        <td>
                                                            <Select
                                                                name="selectCustomer"
                                                                value={this.state.selectedCustomer.value}
                                                                onChange={this.handleChangeCustomer}
                                                                options={this.state.selectCustomer}
                                                                placeholder="Select Permission"
                                                            />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Reports Management</td>
                                                        <td>
                                                            <Select
                                                                name="selectReports"
                                                                value={this.state.selectedReports.value}
                                                                onChange={this.handleChangeReports}
                                                                options={this.state.selectReports}
                                                                placeholder="Select Permission"
                                                            />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>User Management</td>
                                                        <td>
                                                            <Select
                                                                name="selectUser"
                                                                value={this.state.selectedUser.value}
                                                                onChange={this.handleChangeUser}
                                                                options={this.state.selectUser}
                                                                placeholder="Select Permission"
                                                            />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Category Management</td>
                                                        <td>
                                                            <Select
                                                                name="select-location"
                                                                value={this.state.selectedCategory.value}
                                                                onChange={this.handleChangeCategory}
                                                                options={this.state.selectCategory}
                                                                clearableValue={false}
                                                                placeholder="Select Permission"
                                                            />
                                                        </td>
                                                    </tr>
                                                    {
                                                        <tr>
                                                            <td>Accounting Management</td>
                                                            <td>
                                                                <Select
                                                                    name="accounting-select"
                                                                    value={this.state.selectedAccounting.value}
                                                                    onChange={this.handleChangeAccounting}
                                                                    options={this.state.selectAccounting}
                                                                    clearableValue={false}
                                                                    placeholder="Select Permission"
                                                                />
                                                            </td>
                                                        </tr>
                                                    }
                                                    <tr>
                                                        <td></td>
                                                        <td>
                                                            <button className="btn btn-block btn-primary"
                                                                    onClick={() => this.savePermission()}>Save
                                                            </button>
                                                        </td>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


const mapStateToProps = state => ({
    app: state.feathersClient.app,
    users: state.users,
    designationList: state.users.designationList,
    selectedPermissions: state.users.selectedPermissions,
    activeUserInPermission: state.users.activeUserInPermission,
    userData: state.login.userData,
    userPermission: state.login.userPermission,
});

function mapDispatchToProps(dispatch) {
    return {actions: bindActionCreators(ActionCreators, dispatch)}
}

export default connect(mapStateToProps, mapDispatchToProps)(user_permission);
