import React, {Component} from 'react';

//redux
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
// actions
import {ActionCreators} from '../../../actions';

import app from '../../../helpers/feathers';
import toastr from 'toastr';

//main NavBar
import MainNav from '../../../components/global_nav';
import MainSideBar from '../../../components/global_sidebar';

//custom sidebar
import SubSideBar from './sub_sidebar';

import ConfirmationDelete from './confirmation_delete';

//select tag
import Select from 'react-select';

//daterange
import moment from 'moment';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import '../report/plugins/daterangepicker.css';

//custom styles
import '../../../stylesheet/styles.css';

//datatables
import '../../../stylesheet/datatables/datatables.css';

let that;
const $ = require('jquery');
$.DataTable = require('datatables.net');

class sold extends Component {
    pageIsMounted = false;

    constructor(props) {
        super(props);

        this.state = {
            sold: [],
            showModal: false,
            product: this.props.product.product_list,
            products: [],
            product_count: 0,
            selectedOption: '',
            models: this.props.models,
            filter_model: null,
            filter_color: null,
            filter_branch: null,
            filter_engine_number: null,
            selectChanged: false,
            selectedBranch: '',
            selectedBrand: '',
            selectedBrandChanged: false,
            selectBranchChanged: false,
            modelsObj: [],
            startDate: moment().subtract(29, 'days'),
            endDate: moment(),
            drPickerApplied: false,
            selectedDaterange: false,
        };
    }

    componentWillMount() {
        that = this;
        this.props.actions.getAllSoldProducts().then(data => {
            this.props.actions.setAllSoldProducts(data);
            // that.updateData(data);
        });

        if (this.props.userData.user_position_info.position_type !== 'ADMINISTRATOR'
            || this.props.userData.branch_info.branch_name !== 'MAIN') {
            this.state.selectedBranch = {
                value: this.props.userData.branch_info._id,
                label: this.props.userData.branch_info.branch_name
            };
        }
    }

    componentDidMount() {
        that.pageIsMounted = true;
        const productTable = $('.tbl-sold').DataTable({
            data: this.props.product,
            columns: [
                {"title": ""},
                {"title": "DATE SOLD"},
                {"title": "CUSTOMER NAME"},
                {"title": "ACCOUNT NO."},
                {"title": "MODEL NAME"},
                {"title": "ENGINE NO."},
                {"title": "BRANCH"},
                {"title": "TERM"},
                {"title": "PAYMENT"},
                {"title": "ACTION"},
            ],
            columnDefs: [
                {
                    targets: [0],
                    visible: false
                }
            ],
            'dom': "<'row'<'col-sm-6'l><'col-sm-6'p>>" +
                "<'row'<'col-sm-12'tr>>" +
                "<'row'<'col-sm-12'i>>",
        });

        $('.search').keyup(function () {
            productTable.search($(this).val()).draw();
        });

        $('.tbl-sold').on('click', '.edit', function () {
            const data = productTable.row($(this).parents('tr')).data();
            const permission = that.props.userPermission;
            // const origin = data[0].delivery.location;

            if (permission.inventory === 2) {
                that.props.actions.updateUnitInfo(data[0]);
                that.props.history.push('/update_inventory');
            }
        })

        $('.tbl-sold').on('click', '.delete', function () {
            let { userData } = that.props;
            const data = productTable.row($(this).parents('tr')).data();
            const unit_id = data[0]._id;
            const permission = that.props.userPermission;

            if(userData.branch_info.branch_name == 'MAIN'){

                that.props.actions.deleteId(unit_id);
                that.props.actions.deleteService('products');
                that.refs.confirm_delete.click();
                // app.service("products").remove(unit_id)
                // .then(() => {
                //     toastr.success("Unit successfully deleted")
                //     that.advancedFilter()
                // })
                // .catch(() => {
                //     toastr.error("Failed to delete unit")
                // })
            }
        })

        $('#confirm_delete').on('hidden.bs.modal', function () {
            that.advancedFilter();
        });

    }

    componentWillUnmount(){
        that.pageIsMounted = false;
    }

    updateData = data => {
        this.setState({
            sold: data,
            product_count: data.length
        });

        const table = $('.tbl-sold').DataTable();
        table.clear();
        table.rows.add(data);
        table.draw();

    }

    advancedFilter = () => {

        const that = this,
            color = this.state.filter_color ? this.state.filter_color : null,
            branch = this.state.selectBranchChanged ? this.state.filter_branch.value : null,
            daterange = this.state.drPickerApplied ? this.state.selectedDaterange : null,
            {brand, model, engine} = this.refs,
            query = {};

        const permission = this.props.userPermission;
        if (permission.inventory === 0) {
            return;
        }
        if (!daterange && model.value === '' && !branch && brand.value === '' && engine.value === '') {
            that.updateData([]);
            return;
        }
        if (daterange) {
            query.date_sold = {
                $in: daterange
            }
        }
        if (brand.value !== '') {
            query.unit_brand_name = {
                $regex: brand.value
            }
        }
        if (model.value !== '') {
            query.unit_model_name = {
                $regex: model.value
            }
        }
        if (engine.value !== '') {
            query.$or = [
                {
                    engine_number: {
                        $regex: engine.value
                    }
                },
                {
                    unit_engine_number: {
                        $regex: engine.value
                    }
                },
            ]
        }

        if (branch) {
            if (branch !== 'all') {
                query.branch = branch
            }
        }

        query.type = 1;

        if(that.pageIsMounted){
            that.props.actions.inventoryAdvancedFilter2(query).then((data) => {
                that.updateData(data);
            });
        }
    }


    enumDate = (startDate, endDate) => {
        const dates = [];

        const currDate = moment(startDate).startOf('day');
        const lastDate = moment(endDate).startOf('day');

        while (currDate.add(1, 'days').diff(lastDate) < 0) {
            const mdate = moment(currDate.clone().toDate()).format('MM/DD/YYYY')
            dates.push(mdate);
        }
        dates.unshift(moment(startDate).format('MM/DD/YYYY'));
        dates.push(moment(endDate).format('MM/DD/YYYY'));

        this.setState({
            selectedDaterange: dates
        })
    }

    handleApply(event, picker) {
        that.setState({
            startDate: picker.startDate,
            endDate: picker.endDate,
            drPickerApplied: true
        });

        that.enumDate(picker.startDate, picker.endDate);
    }

    handleChange = (selectedOption) => {
        const value = selectedOption;

        if (value != null) {
            this.setState({
                selectedOption: value,
                filter_model: value,
                selectChanged: true
            });
        } else {
            this.setState({
                selectedOption: '',
                filter_model: '',
                selectChanged: false
            })
        }
    }

    handleChangeBranch = (selectedOption) => {
        const value = selectedOption;

        if (value === null) {
            this.setState({
                selectedBranch: '',
                filter_branch: value,
                selectBranchChanged: false
            });
        } else {
            this.setState({
                selectedBranch: value,
                filter_branch: value,
                selectBranchChanged: true
            });
        }
    }

    render() {

        return (
            <div className="">
                {/* main NavBar component*/}
                <MainNav historyProp={this.props.history} usersProp={this.props.userData}/>
                <ConfirmationDelete/>
                <div className="container-fluid">
                    <div className="row full-width">
                        <div className="sidebar">
                            <div className="sidebar-content">
                                <MainSideBar page="inventory"/>
                            </div>
                        </div>
                        <SubSideBar history={this.props.history} allProps={this.props}
                                    userPermission={this.props.userPermission} pageName="sold"/>
                        <div className="sub-inv-main-content">
                            <div className="container-fluid">
                                <div className="application-container">
                                    {
                                        // <div className="row background-white">
                                        //     <div className="d-flex flex-row justify-content-left content-header">
                                        //          <input type="search" className="form-control form-control-lg align-items-center search" placeholder="&#xF002; Search" />
                                        //     </div>
                                        // </div>
                                    }
                                    <div className="row my-inventory">
                                        <div className="col-md-12">
                                            <div className="form-inline">
                                                <h4 className="subheader-title">Units Sold</h4>
                                            </div>
                                            <div className="">
                                                <div className="subheader-label">
                                                    ADVANCED FILTER
                                                </div>
                                                <div className="col-md-12">
                                                    <br/>
                                                </div>
                                                <div className="row">
                                                    <div className="col">
                                                        <DateRangePicker
                                                            onApply={this.handleApply}
                                                            startDate={this.state.startDate}
                                                            endDate={this.state.endDate}
                                                        >
                                                            <button className="form-control">
                                                                {
                                                                    this.state.drPickerApplied ? (moment(this.state.startDate).format('ll') + ' - ' + moment(this.state.endDate).format('ll')) : 'Select date'
                                                                }
                                                            </button>
                                                        </DateRangePicker>
                                                    </div>
                                                    <div className="col">
                                                        <input className="form-control" placeholder="Enter Brand"
                                                               ref="brand" onKeyUp={this.advancedFilter}/>
                                                    </div>
                                                    <div className="col">
                                                        <input className="form-control" placeholder="Enter Model"
                                                               ref="model" onKeyUp={this.advancedFilter}/>
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <br/>
                                                </div>
                                                <div className="row">
                                                    <div className="col">
                                                        <input className="form-control"
                                                               placeholder="Enter Engine Number" ref="engine"
                                                               onKeyUp={this.advancedFilter}/>
                                                    </div>
                                                    <div className="col">
                                                        <Select
                                                            name="select-branch"
                                                            value={this.state.selectedBranch.value}
                                                            onChange={this.handleChangeBranch}
                                                            options={this.props.branch}
                                                            disabled={this.props.userData.user_position_info.position_type !== 'ADMINISTRATOR' || this.props.userData.branch_info.branch_name !== 'MAIN'}
                                                            clearableValue={true}
                                                            placeholder="Branch"
                                                        />
                                                    </div>
                                                    <div className="col">
                                                        <button className="btn btn-second-primary width-100"
                                                                onClick={() => this.advancedFilter()}>Search
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <br/>
                                    </div>
                                    <div className="row">
                                        {/*<div className="col-md-12">
                                        <span>Result : {this.state.product_count}</span>
                                    </div>*/}
                                        <div className="col-md-12">
                                            <a data-toggle="modal" data-target="#confirm_delete"
                                               ref="confirm_delete"></a>
                                            <table className="table tbl-sold tbl-mobile">
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    app: state.feathersClient.app,
    product: state.product,
    models: state.category.modelsSelect,
    branch: state.category.branchesSelect,
    brandsSelect: state.category.brandsSelect,
    selectmodellist: state.category.modelsSelect,
    userData: state.login.userData,
    userPermission: state.login.userPermission,
});

function mapDispatchToProps(dispatch) {
    return {actions: bindActionCreators(ActionCreators, dispatch)}
}

export default connect(mapStateToProps, mapDispatchToProps)(sold);
