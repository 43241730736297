import React, { Component } from 'react';


//redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// actions
import { ActionCreators } from '../../../actions';

//select tag
import Select from 'react-select';

import app from '../../../helpers/feathers'

import toastr from 'toastr';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const $ = require('jquery');

var that

class repossess_sh_modal extends Component {

	constructor(props) {
	  	super(props);
	
	  	this.state = {
	  		date_repossessed: moment(),
	  		date_changed: false,
	  		submitting: false
	  	};
	}

	componentWillMount(){
		that = this;
	}
	
	handleDateRepossessed = (date) => {		
		this.setState({date_repossessed: date})
    }

	repossess = () => {
		let { customerInfo } = this.props,
		{ remarks } = this.refs,
		{ date_repossessed, submitting } = this.state;

		app.service('customer-secondhand-unit').patch(customerInfo._id, {
			account_status: 2,			
			date_repossessed: moment(date_repossessed).format('MM/DD/YYYY'),
			repossess_remarks: remarks.value
		})
		.then((patch) => {
			console.log('patch ', patch)

			app.service('secondhand').patch(customerInfo.secondhand_unit_info._id, {
				transfer_status : 0
			}).then((sh)=>{

			}).catch((err) => {
				console.log('ERROR', err)
			})

			that.closeModal();
			toastr.success("Successfully set as `Repossessed`")
			that.props.history.push("/customers_sh_repossessed_units/");
		})
		.catch((err) => {
			that.closeModal();
			toastr.error("An error occured. Please try again")
			console.log('err ', err)
		})
	}


	closeModal = () => {
		$('.form-control').val('');	 
		$('.modal').modal('hide');
	}

  	render() {			
  		let { customerInfo } = this.props;
    	return (
      		<div className="modal fade  bd-example-modal-lg " id="repossessed_sh_modal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" ref="add_branch" data-backdrop="static" data-keyboard="false">

			  	<div className="modal-dialog  modal-lg " role="document">
			    	<div className="modal-content">
			      		<div className="modal-header">
			        		<h5 className="modal-title" id="exampleModalLabel">Repossess</h5>
			        			<button type="button" className="close" data-dismiss="modal" aria-label="Close">
						          <span aria-hidden="true">&times;</span>
						        </button>
			      		</div>
				      	<div className="modal-body">
				      		<div className = "form-group">
					      			<p>
					      				{`Are you sure you want to set this account (${customerInfo.name} - ${customerInfo.account_number}) as 'Repossessed' ? `}
					      			</p>
				      		</div>
				      		<div className = "form-group">
				      			<div className="row">
					      			<div className="col-lg-6">
					      				<label>Date Reposssessed</label> <br />
					      				<DatePicker
											selected={this.state.date_repossessed}
											onChange={this.handleDateRepossessed}
											peekNextMonth
											showMonthDropdown
											showYearDropdown
											dropdownMode="select"
											className="form-control"
										/>
					      			</div>
					      			<div className="col-lg-6">
					      				<label>Remarks</label>
					      				<input className="form-control" ref="remarks" />
					      			</div>
				      			</div>
				      		</div>
				      	</div>
				      	<div className="modal-footer">
				        	<button type="button" className="btn btn-secondary" onClick={this.closeModal}>NO</button>
				        	<a data-dismiss="modal" ref="cancel"></a>
				        	<button type="button" className="btn btn-primary" onClick={this.repossess}>YES</button>
				      	</div>
			    	</div>
			  	</div>
			</div>
    	);
  	}
}


const mapStateToProps = state => ({
   	app: state.feathersClient.app,
   	customerInfo: state.customers.customerInfo,
   	mainapp: state.feathersClient.mainapp,

});

function mapDispatchToProps(dispatch) {
   return { actions: bindActionCreators(ActionCreators, dispatch) }
}

export default connect(mapStateToProps, mapDispatchToProps)(repossess_sh_modal);