import {
    PRODUCT_LIST,
    SELECTED_MODEL,
    SELECTED_MODEL_SET,
    ADVANCED_FILTER,
    SET_ALL_PRODUCTS,
    SELECTED_FILTERS,
    SET_ALL_SOLD_PRODUCTS,
    PRODUCTS_TO_TRANSFER,
    OUTGOING_PRODUCTS,
    PRODUCT_INFO,
    UPDATE_UNIT_INFO,
    MODELS_FOR_SECONDHAND_UNITS,
    FOR_PRICING,
    PRICES_UPDATED,
    BRAND_NEW_UNITS,
    SECONDHAND_UNITS,
} from '../constants/product';

// Moment
import Moment from 'moment';

import {
    numberWithCommas
} from '../helpers/';

import feathers from '../helpers/feathers'
import {
    SET_INCOMING_PRODUCTS,
    ACCEPT_DATA_ID,
    DELETE_ID,
    DELETE_SERVICE,
    UPDATE_SECONDHAND_UNIT_INFO
} from "../constants/product";

const q_options = {
    limit: 150
}

export function productList(data) {
    return {
        type: PRODUCT_LIST,
        data: data
    }
}

export function getProducts(secondhand) {
    return (dispatch, getState) => {
        const productService = feathers.service('products');
        let query;

        if (secondhand) {
            query = {
                paginate: {
                    default: 10,
                    max: 50
                },
                query: {
                    type: 0,
                    brandNew: 0,
                    $limit: q_options.limit
                }
            }
        } else {
            query = {
                paginate: {
                    default: 10,
                    max: 50
                },
                query: {
                    type: 0,
                    brandNew: 1,
                    $limit: q_options.limit
                }
            }
        }

        return productService.find(query).then((products) => {
            const results = products.data,
                filtered = {},
                cleanData = [];

            results.map((obj) => {
                let model = obj.model,
                    modelName = obj.motorModels ? obj.motorModels.model_name : obj.received_model_name !== null ? obj.received_model_name : '',
                    brandName = obj.brands ? obj.brands.brand_name : obj.received_brand_name !== null ? obj.received_brand_name : '',
                    branch = obj.branches ? obj.branches.branch_name : '',
                    date_received = Moment(obj.date_created).format('MM/DD/YYYY'),
                    chassis_number = obj.chassis_number,
                    engine_number = obj.engine_number,
                    color = obj.color;

                cleanData.push([
                    obj,
                    modelName,
                    // brandName,
                    chassis_number,
                    engine_number,
                    branch,
                    date_received,
                    // color,
                    '<button title="Edit" class="btn btn-sm btn-primary edit"><span class="fa fa-edit" /></button> ' +
                    '<button title="Delete" class="btn btn-sm btn-danger delete"><span class="fa fa-trash" /></button> ' +
                    '<button title="View" class="btn btn-sm btn-warning view"><span class="fa fa-eye" /></button>'
                ])
            })
            return Promise.resolve(cleanData);
        }).catch((err) => {
            return Promise.resolve(false);
        })
    }
}

export function searchAllUnits(query = {}, initial3 = false) {
    return (dispatch, getState) => {
        const productService = feathers.service('products');
        let { userData } = getState().login;
        let session = {
            branchId: userData.branch_info._id,
            branchName: userData.branch_info.branch_name,
        }
        let deleteBtn = '';

        if(session.branchName !== "MAIN"){
            query.branch = session.branchId
        }else{
            deleteBtn = '<button title="Delete" class="btn btn-sm btn-danger delete"><span class="fa fa-trash" /></button>'
        }

        if(initial3){
            query.$limit = 3;
            query.$sort = {
                createdAt: -1
            };
        }else{
            query.$limit = q_options.limit;
        }

        return productService.find({
            paginate: {
                default: 10,
                max: 50
            },
            query: query
        }).then((products) => {
            const results = products.data,
                filtered = {},
                cleanData = [];
            results.map((obj) => {
                let model = obj.model,
                    modelName = obj.motorModels == null ? (obj.unit_model_name ? obj.unit_model_name : '--') : obj.motorModels.model_name,
                    brandName = obj.brands ? obj.brands.brand_name : obj.received_brand_name !== null ? obj.received_brand_name : '',
                    branch = obj.branches != null ? obj.branches.branch_name : '',
                    date_received = obj.dateReceived ? Moment(obj.dateReceived).format('MM/DD/YYYY') : Moment(obj.delivery.date).format('MM/DD/YYYY'),
                    chassis_number = obj.chas,
                    engine_number = obj.engine_number,
                    color = obj.color;

                const chassisExists = cleanData.find(element => element[2] === chassis_number);

                if (!chassisExists) {
                    cleanData.push([
                        obj,
                        modelName,
                        engine_number,
                        branch,
                        'BRAND NEW',
                        obj.type ? "SOLD" : "UNSOLD",
                        // obj.createdAt,
                        Moment(obj.createdAt).format("MM/DD/YYYY"),
                        '<button title="View" class="btn btn-sm btn-warning view"><span class="fa fa-eye" /></button> '+deleteBtn,
                        // <button class="delete">delete</button>
                    ])
                }
            })
            dispatch(setBnUnits(cleanData))
            return Promise.resolve(cleanData);
        }).catch((err) => {
            console.log(err)
            return Promise.resolve(false);
        })
    }
}

export const setBnUnits = (data) => {
    return {
        type: BRAND_NEW_UNITS,
        data: data
    }
}
// search for secondhand units
export const searchAllShUnits = (query, initial3 = false) => {
    return (dispatch,getState) => {
        let Service = feathers.service("secondhand");
        let { userData } = getState().login;
        let output = {};
        let data = [];
        let session = {
            branchId: userData.branch_info._id,
            branchName: userData.branch_info.branch_name,
        }
        let deleteBtn = '';

        if(session.branchName !== "MAIN"){
            query.branch = session.branchId
        }else{
            deleteBtn = '<button title="Delete" class="btn btn-sm btn-danger delete"><span class="fa fa-trash" /></button>'
        }

        query.$limit = q_options.limit

        if(initial3){
            query.$limit = 3;
            query.$sort = {
                createdAt: -1
            };
        }

        return Service.find({
            paginate: {
                default: 10,
                max: 50
            },
            query: query
        })
        .then((result) => {
            if(result.data.length > 0){
                let col = result.data;
                col.map((v, i) => {

                    let modelName = v.model.name,
                        { engine_number } = v,
                        branch = v.branch ? (v.branch_info.branch_name ? v.branch_info.branch_name : '') : '';

                    data.push([
                        v,
                        modelName,
                        engine_number,
                        branch,
                        'SECONDHAND',
                        v.type ? "SOLD" : "UNSOLD",
                        Moment(v.createdAt).format("MM/DD/YYYY"),
                        // v.createdAt,
                        '<button title="View" class="btn btn-sm btn-warning view"><span class="fa fa-eye" /></button> '+deleteBtn
                    ])
                })

                output.status = true;
                output.data = data;
                dispatch(setShUnits(data));
                return Promise.resolve(output);
            }else{
                output.status = true;
                output.data = data;
                return Promise.resolve(output);
            }

        })
        .catch((e) => {
            return Promise.resolve(false);
        })
    }
}

export const setShUnits = (data) => {
    return{
        type: SECONDHAND_UNITS,
        data: data
    }
}

export function inventoryAdvancedFilter(query, secondhand) {
    return (dispatch, getState) => {
        const productService = feathers.service('products');
        if (secondhand) {
            query.brandNew = 0;
        } else {
            query.brandNew = 1;
        }

        query.$limit = q_options.limit

        return productService.find({
            paginate: {
                default: 10,
                max: 50
            },
            query: query
        }).then((products) => {
            const results = products.data,
                filtered = {},
                cleanData = [];
            results.map((obj) => {
                let model = obj.model,
                    // modelName = obj.motorModels ? obj.motorModels.model_name : obj.received_model_name ? obj.received_model_name : '',
                    modelName = obj.motorModels == null ? (obj.unit_model_name ? obj.unit_model_name : '--') : obj.motorModels.model_name,
                    brandName = obj.brands ? obj.brands.brand_name : obj.received_brand_name !== null ? obj.received_brand_name : '',
                    branch = obj.branches != null ? obj.branches.branch_name : '',
                    date_received = obj.dateReceived ? Moment(obj.dateReceived).format('MM/DD/YYYY') : Moment(obj.delivery.date).format('MM/DD/YYYY'),
                    // chassis_number = obj.chassis_number,
                    chassis_number = obj.chas,
                    engine_number = obj.engine_number,
                    color = obj.color;

                const chassisExists = cleanData.find(element => element[2] === chassis_number);

                if (!chassisExists) {
                    cleanData.push([
                        obj,
                        modelName,
                        // brandName,
                        chassis_number,
                        engine_number,
                        branch,
                        date_received,
                        // color,
                        '<button title="Edit" class="btn btn-sm btn-primary edit"><span class="fa fa-edit" /></button> ' +
                        '<button title="Delete" class="btn btn-sm btn-danger delete"><span class="fa fa-trash" /></button> ' +
                        // '<ConfirmationDelete onYes={() => this.deleteData(\'test\')}/> ' +
                        '<button title="View" class="btn btn-sm btn-warning view"><span class="fa fa-eye" /></button>'
                    ])
                }
            })
            return Promise.resolve(cleanData);
        }).catch((err) => {
            return Promise.resolve(false);
        })
    }
}

export function searchDr(query, secondhand) {
    return (dispatch, getState) => {
        const productService = feathers.service('products');

        query.$limit = q_options.limit

        return productService.find({
            paginate: {
                default: 10,
                max: 50
            },
            query: query
        }).then((products) => {
            const results = products.data,
                filtered = {},
                cleanData = [];

            results.map((obj) => {
                let model = obj.model,
                    modelName = Object.keys(obj.motorModels).length > 0 ? obj.motorModels.model_name : (obj.received_model_name !== null ? obj.received_model_name : ''),
                    brandName = obj.brands ? obj.brands.brand_name : obj.received_brand_name !== null ? obj.received_brand_name : '',
                    branch = obj.branches ? obj.branches.branch_name : '',
                    date_received = Moment(obj.delivery.date).format('MM/DD/YYYY'),
                    // chassis_number = obj.chassis_number,
                    chassis_number = obj.chas,
                    engine_number = obj.engine_number,
                    color = obj.color,
                    editBtn = '<button title="Edit" class="btn btn-sm btn-success edit"><span class="fa fa-edit" /></button>',
                    clearances = obj.clearances === 1 ? '' : '<button title="Click to add Clearances" class="btn btn-sm btn-primary clearance"><span class="fa fa-list-alt" /></button> ',
                    warranty_claims = obj.warranty_claims === 1 ? '' : '<button title="Click to add Warranty Claims" class="btn btn-sm btn-danger warranty"><span class="fa fa-check-square" /></button> ',
                    tba = obj.tba === 1 ? '' : '<button title="Click to add TBA\'s " class="btn btn-sm btn-warning tba"><span class="fa fa-wrench" /></button>';

                cleanData.push([
                    obj,
                    modelName,
                    // brandName,
                    chassis_number,
                    engine_number,
                    branch,
                    date_received,
                    `${editBtn} ${clearances} ${warranty_claims} ${tba}`
                ]);
            });

            return Promise.resolve(cleanData);
        }).catch((err) => {
            return Promise.resolve(false);
        })
    }
}

export function setProducts(data) {
    return {
        type: PRODUCT_LIST,
        data: data
    }
}

export function selectModel(data) {
    return {
        type: SELECTED_MODEL,
        data: data
    }
}

export function getSelectedModel(data, advanced = false, model = null, color = null, branch = null, brand = null) {
    return (dispatch, getState) => {
        const productService = feathers.service('products'),
            newData = [];
        let filter;

        if (advanced) {

            if (color) {
                if (color.toLowerCase() === 'all') {
                    color = null
                } else {
                    color = color.toUpperCase()
                }
            }

            if (branch) {
                if (branch.toLowerCase() === 'all') {
                    branch = null
                }
            }

            if (model) {
                if (model.toLowerCase() === 'all') {
                    model = null
                }
            }

            if (brand) {
                if (brand.toLowerCase() === 'all') {
                    brand = null
                }
            }

            if (model && !color && !branch && !brand) {
                filter = {
                    query: {
                        model: model,
                        type: 0,
                    }
                }
            } else if (model && color && !branch && !brand) {
                filter = {
                    query: {
                        model: model,
                        color: color,
                        type: 0,
                    }
                }
            } else if (model && !color && branch && !brand) {
                filter = {
                    query: {
                        model: model,
                        branch: branch,
                        type: 0,
                    }
                }
            } else if (model && !color && !branch && brand) {
                filter = {
                    query: {
                        model: model,
                        brand: brand,
                        type: 0,
                    }
                }
            } else if (model && color && branch && !brand) {
                filter = {
                    query: {
                        model: model,
                        color: color,
                        branch: branch,
                        type: 0,
                    }
                }
            } else if (model && color && !branch && brand) {
                filter = {
                    query: {
                        model: model,
                        color: color,
                        brand: brand,
                        type: 0,
                    }
                }
            } else if (model && !color && branch && brand) {
                filter = {
                    query: {
                        model: model,
                        branch: branch,
                        brand: brand,
                        type: 0,
                    }
                }
            } else if (!model && color && !branch && !brand) {
                filter = {
                    query: {
                        color: color,
                        type: 0,
                    }
                }
            } else if (!model && color && branch && !brand) {
                filter = {
                    query: {
                        color: color,
                        branch: branch,
                        type: 0,
                    }
                }
            } else if (model && color && !branch && !brand) {
                filter = {
                    query: {
                        color: color,
                        model: model,
                        type: 0,
                    }
                }
            } else if (!model && color && !branch && brand) {
                filter = {
                    query: {
                        color: color,
                        branch: brand,
                        type: 0,
                    }
                }
            } else if (!model && color && branch && brand) {
                filter = {
                    query: {
                        color: color,
                        brand: brand,
                        branch: branch,
                        type: 0,
                    }
                }
            } else if (!model && !color && branch && !brand) {
                filter = {
                    query: {
                        branch: branch,
                        type: 0,
                    }
                }
            } else if (!model && !color && branch && brand) {
                filter = {
                    query: {
                        branch: branch,
                        brand: brand,
                        type: 0,
                    }
                }
            } else if (!model && !color && !branch && brand) {
                filter = {
                    query: {
                        brand: brand,
                        type: 0,
                    }
                }
            }

            return productService.find(filter).then((products) => {
                const prod = products.data

                prod.forEach((value, index) => {
                    const rawDate = new Date(value.delivery.date),
                        newDate = rawDate.toLocaleDateString()

                    newData.push([
                        value._id,
                        value.engine_number,
                        value.chassis_number,
                        value.color,
                        newDate,
                        value.branches.branch_name,
                        '<button class="btn table-btn border-radius-50 text-white filtered-models">More</button>',
                        value.price,
                    ]);
                });
                return Promise.resolve(newData);
            }).catch((err) => {
                return Promise.resolve([]);
            });
        } else {
            return productService.find({
                query: {
                    model: data,
                    type: 0,
                },
            }).then((products) => {
                const prod = products.data

                prod.forEach((value, index) => {
                    const rawDate = new Date(value.delivery.date),
                        newDate = rawDate.toLocaleDateString()

                    newData.push([
                        value._id,
                        value.engine_number,
                        value.chassis_number,
                        value.color,
                        newDate,
                        value.branches.branch_name,
                        '<button class="btn table-btn border-radius-50 text-white filtered-models">More</button>',
                        value.price,
                        value.branches.branch_name,
                        value.branches.branch_name,
                    ])
                });
                return Promise.resolve(newData);
            }).catch((err) => {
                return Promise.resolve([]);
            });
        }
    }
}

export function selectedModelSet(data) {
    return {
        type: SELECTED_MODEL_SET,
        data: data
    }
}

export function advancedFilter(status) {
    return {
        type: ADVANCED_FILTER,
        status: status
    }
}

// for select
export function getAllProducts(branch) {
    return (dispatch, getState) => {
        const productService = feathers.service('products');

        return productService.find({
            query: {
                type: 0,
                $limit: q_options.limit
            }
        }).then((products) => {
            const results = products.data,
                data = [];

            results.forEach((value, index) => {
                data.push([value._id, value.motorModels, value.chas, value.price]);
            });
            return Promise.resolve(data);
        }).catch((err) => {
                            return Promise.resolve([]);
                        });
    }
}

export function setAllProducts(products) {
    return {
        type: SET_ALL_PRODUCTS,
        data: products
    }
}

export function setselectedFilters(model, color, brand) {
    return {
        type: SELECTED_FILTERS,
        model: model,
        color: color,
        brand: brand,
    }
}

export function getAllSoldProducts(secondhand) {
    return (dispatch, getState) => {
        const productService = feathers.service('products');
        let query;

        if (secondhand) {
            query = {
                query: {
                    type: 1,
                    brandNew: 0,
                    $limit: q_options.limit
                }
            }
        } else {
            query = {
                query: {
                    type: 1,
                    $limit: q_options.limit
                }
            }
        }

        return productService.find(query)
            .then((data) => {
                const products = data.data,
                    res = [];

                products.map((value, index) => {
                    const model = value.motorModels.model_name,
                        engine_number = value.engine_number,
                        branch = value.branches.branch_name,
                        date_received = value.dateReceived === null ? value.createdAt : value.dateReceived,
                        formattedDate = Moment(date_received).format('MM/DD/YYYY'),
                        date_sold = Moment(value.date_sold).format('MM/DD/YYYY'),
                        customer_name = value.customer_info.name,
                        account_number = value.customer_info.account_number,
                        term = value.customer_info.term,
                        payment = value.customer_info.payment;

                    res.push([value, date_sold, customer_name, account_number, model, engine_number, branch, term, numberWithCommas(payment)]);
                })
                return Promise.resolve(res)
            }).catch(() => {
                return Promise.resolve(false)
            });
    }
}

export function inventoryAdvancedFilter2(query, secondhand) {
    return (dispatch, getState) => {
        const productService = feathers.service('products');

        let branchName = getState().login.userData.branch_info.branch_name;
        let branchId = getState().login.userData.branch_info._id;
        let deleteBtn = '';

        if (secondhand) {
            query.brandNew = 0;
        }

        query.$limit = q_options.limit;

        if(branchName == 'MAIN'){
            deleteBtn = '<button title="Delete" class="btn btn-sm btn-danger delete"><span class="fa fa-trash" /></button>'
        }

        return productService.find({
            query: query
        }).then((data) => {
            const products = data.data,
                res = [];

            products.map((value, index) => {
                const model = value.motorModels == null ? value.unit_model_name : value.motorModels.model_name,
                    engine_number = value.engine_number,
                    branch = value.branches === null ? '--' : value.branches.branch_name,
                    date_received = value.dateReceived === null ? value.createdAt : value.dateReceived,
                    formattedDate = Moment(date_received).format('MM/DD/YYYY'),
                    date_sold = Moment(value.date_sold).format('MM/DD/YYYY'),
                    customer_name = (value.customer_info === null ? '--' : value.customer_info.name),
                    account_number = value.customer_info !== null ? (value.customer_info.account_number ? value.customer_info.account_number : 'CASH') : '',
                    term = (value.customer_info === null ? '--' : (value.customer_info.term ? value.customer_info.term : 'N/A')),
                    payment = (value.customer_info === null ? '--' : value.customer_info.payment),
                    btn = '<button title="Edit" class="btn btn-sm btn-primary edit"><span class="fa fa-edit" /></button>';

                res.push([value, date_sold, customer_name, account_number, model, engine_number, branch, term, numberWithCommas(payment), btn+' '+deleteBtn]);
            });
            return Promise.resolve(res);
        }).catch((err) => {
            return Promise.resolve(false)
        });
    }
}

export function setAllSoldProducts(products) {
    return {
        type: SET_ALL_SOLD_PRODUCTS,
        data: products
    }
}

export function productsToTransfer(id, secondhand) {
    return (dispatch, getState) => {
        const productService = feathers.service('products');
        let query;

        if (secondhand) {
            query = {
                paginate: {
                    default: 10,
                    max: 50
                },
                query: {
                    type: 0,
                    transferred: 0,
                    branch: id,
                    brandNew: 0,
                    $limit: q_options.limit,
                }
            }
        } else {
            query = {
                paginate: {
                    default: 10,
                    max: 50
                },
                query: {
                    type: 0,
                    transferred: 0,
                    branch: id,
                    brandNew: 1,
                    $limit: q_options.limit,
                }
            }
        }

        return productService.find(query)
            .then((products) => {
                const results = products.data,
                    data = [];

                results.forEach((value, index) => {
                    const brand = value.brands ? value.brands.brand_name : '',
                        modelName = value.motorModels ? value.motorModels.model_name : '',
                        checkBox = '<input type="checkbox" class="select-product">';

                    data.push([value._id, checkBox, modelName, brand, value.color, value.engine_number]);
                });

                return Promise.resolve(data);
            }).catch((err) => {
                return Promise.resolve([]);
            });

    }
}

export function setProductsToTransfer(data) {
    return {
        type: PRODUCTS_TO_TRANSFER,
        data: data
    }
}

export function startTransfer(data) {
    return (dispatch, getState) => {
        const transferService = feathers.service('transfers'),
            productsService = feathers.service('products'),
            units = data.units;

        return transferService.create(data)
            .then((d) => {
                units.map((v) => {
                    productsService.patch(v, {
                        transferred: 1,
                        transferredTo: data.local_branch,
                        dateTransferred: data.date_transferred
                    }).then(() => {

                    });
                });
                return Promise.resolve(true);
            }).catch((err) => {
                return Promise.resolve(false)
            });
    }
}

export function outgoingProducts(v) {
    return (dispatch, getState) => {
        const productService = feathers.service('products');

        return productService.find({
            paginate: {
                default: 10,
                max: 50
            },
            query: {
                type: 0,
                transferred: {
                    $in: [1, 2]
                },
                branch: v
            }
        }).then((products) => {
            const results = products.data,
                data = [];

            results.forEach((value, index) => {
                const brand = value.brands ? value.brands.brand_name : '',
                    modelName = value.motorModels ? value.motorModels.model_name : '',
                    branch = value.branchTransferred ? value.branchTransferred.branch_name : '',
                    status = value.transferred === 2 ? '<h4 class="badge badge-success">Received</h4>' : '',
                    date_transferred = Moment(value.dateTransferred).format('MM/DD/YYYY');

                data.push([value._id, modelName, brand, value.color, value.chassis_number, branch, date_transferred]);
            });

            return Promise.resolve(data);
        }).catch((err) => {
            return Promise.resolve([]);
        });
    }
}

export function outgoingProductsFilter(query) {
    return (dispatch, getState) => {
        const productService = feathers.service('products');

        return productService.find({
            query: query
        }).then((products) => {
            const results = products.data,
                data = [];

            results.forEach((value, index) => {
                const brand = value.brands ? value.brands.brand_name : '',
                    modelName = value.motorModels ? value.motorModels.model_name : '',
                    branch = value.branchTransferred ? value.branchTransferred.branch_name : '',
                    status = value.transferred === 2 ? '<h4 class="badge badge-success">Received</h4>' : '',
                    date_transferred = Moment(value.dateTransferred).format('MM/DD/YYYY');

                data.push([value._id, modelName, brand, value.color, value.engine_number, branch, date_transferred]);
            });

            return Promise.resolve(data);
        }).catch((err) => {
            return Promise.resolve([]);
        });
    }
}

export function setOutgoingProducts(data) {
    return {
        type: OUTGOING_PRODUCTS,
        data: data
    }
}

export function productsToTransferFilter2(data) {
    return (dispatch, getState) => {
        const productsService = feathers.service('products'),
            brand = data.brand,
            model = data.model,
            color = data.color ? (data.color).toUpperCase() : data.color,
            chassis_number = data.chassis_number;
        let query;


        if (brand && !model && !color && !chassis_number) {
            if (brand === 'all') {
                query = {
                    query: {
                        type: 0,
                        transferred: 0,
                    }
                }
            } else {
                query = {
                    query: {
                        brand: brand,
                        type: 0,
                        transferred: 0,
                    }
                }
            }
        }
        if (brand && model && !color && !chassis_number) {

            if (brand === 'all' && model === 'all') {
                query = {
                    query: {
                        type: 0,
                        transferred: 0,
                    }
                }
            }

            if (brand === 'all' && model !== 'all') {
                query = {
                    query: {
                        model: model,
                        type: 0,
                        transferred: 0,
                    }
                }
            }

            if (brand !== 'all' && model === 'all') {
                query = {
                    query: {
                        brand: brand,
                        type: 0,
                        transferred: 0,
                    }
                }
            }
        }
        if (brand && !model && color && !chassis_number) {

            if (brand === 'all') {
                query = {
                    query: {
                        color: color,
                        type: 0,
                        transferred: 0,
                    }
                }
            } else {
                query = {
                    query: {
                        brand: brand,
                        color: color,
                        type: 0,
                        transferred: 0,
                    }
                }
            }
        }
        if (brand && !model && !color && chassis_number) {
            if (brand === 'all') {
                query = {
                    query: {
                        chassis_number: chassis_number,
                        type: 0,
                        transferred: 0,
                    }
                }
            } else {
                query = {
                    query: {
                        brand: brand,
                        chassis_number: chassis_number,
                        type: 0,
                        transferred: 0,
                    }
                }
            }
        }

        if (!brand && model && !color && !chassis_number) {
            if (model === 'all') {
                query = {
                    query: {
                        type: 0,
                        transferred: 0,
                    }
                }
            } else {
                query = {
                    query: {
                        model: model,
                        type: 0,
                        transferred: 0,
                    }
                }
            }
        }
        if (!brand && model && !color && chassis_number) {
            if (model === 'all') {
                query = {
                    query: {
                        chassis_number: chassis_number,
                        type: 0,
                        transferred: 0,
                    }
                }
            } else {
                query = {
                    query: {
                        model: model,
                        chassis_number: chassis_number,
                        type: 0,
                        transferred: 0,
                    }
                }
            }
        }
        if (!brand && model && color && !chassis_number) {
            if (model === 'all') {
                query = {
                    query: {
                        color: color,
                        type: 0,
                        transferred: 0,
                    }
                }
            } else {
                query = {
                    query: {
                        model: model,
                        color: color,
                        type: 0,
                        transferred: 0,
                    }
                }
            }
        }

        if (!brand && !model && color && !chassis_number) {
            if (color === 'all') {
                query = {
                    query: {
                        type: 0,
                        transferred: 0,
                    }
                }
            } else {
                query = {
                    query: {
                        color: color,
                        type: 0,
                        transferred: 0,
                    }
                }
            }
        }
        if (!brand && !model && color && chassis_number) {
            query = {
                query: {
                    color: color,
                    chassis_number: chassis_number,
                    type: 0,
                    transferred: 0,
                }
            }
        }

        if (!brand && !model && !color && chassis_number) {
            if (chassis_number === 'all') {
                query = {
                    query: {
                        type: 0,
                        transferred: 0,
                    }
                }
            } else {
                query = {
                    query: {
                        chassis_number: chassis_number,
                        type: 0,
                        transferred: 0,
                    }
                }
            }
        }

        if (brand && model && color && !chassis_number) {

            if (brand === 'all' && model === 'all') {
                query = {
                    query: {
                        color: color,
                        type: 0,
                        transferred: 0,
                    }
                }
            } else if (brand !== 'all' && model === 'all') {
                query = {
                    query: {
                        brand: brand,
                        color: color,
                        type: 0,
                        transferred: 0,
                    }
                }
            } else if (brand === 'all' && model !== 'all') {
                query = {
                    query: {
                        model: model,
                        color: color,
                        type: 0,
                        transferred: 0,
                    }
                }
            } else {
                query = {
                    query: {
                        brand: brand,
                        model: model,
                        color: color,
                        type: 0,
                        transferred: 0,
                    }
                }
            }
        }

        if (brand && model && !color && chassis_number) {

            if (brand === 'all' && model === 'all') {
                query = {
                    query: {
                        chassis_number: chassis_number,
                        type: 0,
                        transferred: 0,
                    }
                }
            } else if (brand === 'all' && model !== 'all') {
                query = {
                    query: {
                        model: model,
                        chassis_number: chassis_number,
                        type: 0,
                        transferred: 0,
                    }
                }
            } else if (brand !== 'all' && model === 'all') {
                query = {
                    query: {
                        brand: brand,
                        chassis_number: chassis_number,
                        type: 0,
                        transferred: 0,
                    }
                }
            } else {
                query = {
                    query: {
                        brand: brand,
                        model: model,
                        chassis_number: chassis_number,
                        type: 0,
                        transferred: 0,
                    }
                }
            }
        }

        if (!brand && model && color && chassis_number) {
            if (model === 'all') {
                query = {
                    query: {
                        color: color,
                        chassis_number: chassis_number,
                        type: 0,
                        transferred: 0,
                    }
                }
            } else {
                query = {
                    query: {
                        model: model,
                        color: color,
                        chassis_number: chassis_number,
                        type: 0,
                        transferred: 0,
                    }
                }
            }
        }

        if (brand && model && color && chassis_number) {
            if (brand === 'all' && model === 'all') {
                query = {
                    query: {
                        color: color,
                        chassis_number: chassis_number,
                        type: 0,
                        transferred: 0,
                    }
                }
            } else if (brand === 'all' && model !== 'all') {
                query = {
                    query: {
                        model: model,
                        color: color,
                        chassis_number: chassis_number,
                        type: 0,
                        transferred: 0,
                    }
                }
            } else if (brand !== 'all' && model === 'all') {
                query = {
                    query: {
                        brand: brand,
                        color: color,
                        chassis_number: chassis_number,
                        type: 0,
                        transferred: 0,
                    }
                }
            } else {
                query = {
                    query: {
                        brand: brand,
                        model: model,
                        color: color,
                        chassis_number: chassis_number,
                        type: 0,
                        transferred: 0,
                    }
                }
            }
        }

        query.$limit = q_options.limit

        return productsService.find(query)
            .then((products) => {
                const results = products.data,
                    output = [];

                results.forEach((value, index) => {
                    const brand = value.brands ? value.brands.brand_name : '',
                        modelName = value.motorModels ? value.motorModels.model_name : '',
                        checkBox = '<input type="checkbox" class="select-product">';

                    output.push([value._id, checkBox, modelName, brand, value.color, value.chassis_number]);
                });

                return Promise.resolve(output);
            }).catch((err) => {
                return Promise.resolve([]);
            })
    }
}

export function productsToTransferFilter(query, secondhand) {
    return (dispatch, getState) => {
        const productsService = feathers.service('products')

        if (secondhand) {
            query.brandNew = 0;
        } else {
            query.brandNew = 1;
        }

        query.$limit = q_options.limit;

        return productsService.find({
            paginate: {
                default: 10,
                max: 50
            },
            query: query
        }).then((products) => {
            const results = products.data,
                output = [];

            results.forEach((value, index) => {
                const brand = value.brands ? value.brands.brand_name : '',
                    modelName = value.motorModels ? value.motorModels.model_name : '',
                    checkBox = '<input type="checkbox" class="select-product">';

                output.push([value._id, checkBox, modelName, brand, value.color, value.engine_number]);
            });

            return Promise.resolve(output);
        }).catch((err) => {
            const data = [];
            return Promise.resolve(data);
        });
    }
}

export function productInfo(data) {
    return {
        type: PRODUCT_INFO,
        data: data
    }
}

export function updateUnitInfo(data) {
    return {
        type: UPDATE_UNIT_INFO,
        data: data
    }
}

export function findChassisUsingBrand(brand) {
    return (dispatch, getState) => {
        let query;

        if (brand) {
            query = {
                paginate: {
                    default: 10,
                    max: 50
                },
                query: {
                    brand: brand,
                    $limit: 75
                    // $limit: 1
                }
            }
        } else {
            query = ''
        }

        return feathers.service('motorcycle-models').find(query)
        .then((d) => {
            if(d.total){
                return Promise.resolve(d)
            }else{
                return Promise.resolve(false)
            }
            // let data = [];
            // data = d;
            // return Promise.resolve(d.total ? data : false)
        })
        .catch((e) => {
            if(e.code == 408){
                return feathers.service('motorcycle-models').find(query)
                .then((d) => {
                    if(d.total){
                        return Promise.resolve(d)
                    }else{
                        return Promise.resolve(false)
                    }
                })
                .catch((err) => {
                    return Promise.resolve(false)
                })
            }
            return Promise.resolve(false)
        })

        
    }
}

export function findChassisUsingModel(model) {
    return (dispatch, getState) => {

        const branch = getState().login.userData.branch_info._id;

        return feathers.service('products').find({
            paginate: {
                default: 10,
                max: 50
            },
            query: {
                model: model,
                type: 0,
                branch: branch,
                transferred: {
                    $nin: [1]
                },
                // $limit: q_options.limit,
            }
        }).then((d) => {
            let data = [];
            data = d;

            return Promise.resolve(data)
        }).catch((e) => {
            return Promise.resolve(false)
        })
    }
}

export function countProducts(localbranch = false){
    return (dispatch, getState) => {
        const productsService = feathers.service('products');
        const res = {};
        const query = {
            $limit: 10,
            $sort: {
                createdAt: 1
            },
            deletedInBranch: {
                $ne: 1
            },
            type: 0, //unsold
        }
        if(localbranch){
            query.branch = localbranch;
        }

        return productsService.find({
            paginate: {
                default: 10,
                max: 50,
            },
            query: query
        }).then((data) => {
            const dataCount = data.data.length;
            res.status = true;
            res.data = data.total;
            return Promise.resolve(res);
        }).catch((error) => {
            res.status = false;
            res.data = error;
            return Promise.resolve(res);
        })
    }
}

export function recursiveProducts(total, localbranch = false){
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            const productsService = feathers.service('products');
            let res = {};
            let limit = 200;
            let recursive_count = total / limit;
            let collection = [];
            function recursive(value){
                if(value > 0){
                    let skip = ((total * value) / recursive_count);
                    let qry = {
                        $limit: limit,
                        $skip: skip,
                        $sort: {
                            createAt: 1
                        },
                        type: 0, // unsold
                    };
                    if(localbranch){
                        qry.branch = localbranch
                    }
                    productsService.find({
                        paginate: {
                            default: 10,
                            max: 50,
                        },
                        query: qry
                    }).then((result) => {
                        let d = result.data;
                        d.map((item, i) => {
                            collection.push(item)
                        })
                        recursive(value - 1)
                    }).catch((error) => {
                        recursive(value - 1)
                    })
                }else{
                    const last_items_limit = (total % limit);
                    let qry = {
                        $limit: last_items_limit,
                        $sort: {
                            createAt: -1
                        },
                        type: 0, // unsold
                    }
                    if(localbranch){
                        qry.branch = localbranch
                    }
                    return productsService.find({
                        paginate: {
                            default: 10,
                            max: 50,
                        },
                        query: qry
                    }).then((result) => {
                        let d = result.data;
                        d.map((item, i) => {
                            collection.push(item)
                        })
                        res.status = true;
                        res.data = collection;
                        resolve(res);
                    }).catch((error) => {
                        
                        res.status = true;
                        res.data = collection;
                        resolve(res);
                    })
                }
            }

            // return Promise.resolve();
            return recursive(recursive_count - 1);
        })
    }
}
export function duplicateProducts(array){
    return (dispatch) => {
        const output = [];

        const existedRows = {}, updatedData = [];
        const filteredData = Object.values(array.reduce((acc, cur) => {
            const key = cur.chas + cur.model;
            if (!acc[key]) {
              acc[key] = [cur];
            } else {
              acc[key].push(cur);
            }
            return acc;
          }, {})).filter(group => group.length > 1).flat();

        filteredData.forEach((value, index) => {
            const brand = value.brands ? value.brands.brand_name : '',
                // modelName = value.motorModels ? value.motorModels.model_name : '',
                modelName = value.unit_model_name ? value.unit_model_name : '',
                branch = value.branches ? value.branches.branch_name : '',
                status = value.transferred === 2 ? '<h4 class="badge badge-success">Received</h4>' : '',
                date_transferred = Moment(value.dateTransferred).format('MM/DD/YYYY'),
                button = '<button title="Delete" class="btn btn-sm btn-danger delete"><span class="fa fa-trash" /></button> ';

            output.push([value._id, modelName, value.chas, value.engine_number, branch, button, value]);
        });

        return Promise.resolve(output)
    }
}
export function duplicateProducts1(arr){
    return (dispatch) => {
        const output = [];

        const existedRows = {}, updatedData = [];
        arr.forEach(function (a) {
            const key = a.model + '_~_' + a.chas;
            const keys = Object.keys(existedRows);

            if (keys.indexOf(key) === -1) {
                existedRows[key] = 1
            } else {
                existedRows[key] = existedRows[key] + 1
            }
        });
        arr.forEach(function (a) {
            const key = a.model + '_~_' + a.chas;
            if (existedRows[key] > 1) {
                updatedData.push(a);
            }
        });

        updatedData.forEach((value, index) => {
            const brand = value.brands ? value.brands.brand_name : '',
                modelName = value.motorModels ? value.motorModels.model_name : '',
                branch = value.branches ? value.branches.branch_name : '',
                status = value.transferred === 2 ? '<h4 class="badge badge-success">Received</h4>' : '',
                date_transferred = Moment(value.dateTransferred).format('MM/DD/YYYY'),
                button = '<button title="Delete" class="btn btn-sm btn-danger delete"><span class="fa fa-trash" /></button> ';

            output.push([value._id, modelName, value.chas, branch, button, value]);
        });

        return Promise.resolve(output)
    }
}

export function duplicateProductsPrev() {
    return (dispatch, getState) => {
        const productsService = feathers.service('products');

        return productsService.find({
            paginate: {
                default: 10,
                max: 50
            },
            query: {
                $limit: q_options.limit,
                deletedInBranch: {
                    $ne: 1
                }
            }
        }).then((data) => {
            const arr = data.data,
                output = [];

            const existedRows = {}, updatedData = [];
            arr.map(function (a) {
                const key = a.model + '_~_' + a.chas;
                const keys = Object.keys(existedRows);

                if (keys.indexOf(key) === -1) {
                    existedRows[key] = 1
                } else {
                    existedRows[key] = existedRows[key] + 1
                }
            });
            arr.map(function (a) {
                const key = a.model + '_~_' + a.chas;
                if (existedRows[key] > 1) {
                    updatedData.push(a);
                }
            });

            updatedData.forEach((value, index) => {
                const brand = value.brands ? value.brands.brand_name : '',
                    modelName = value.motorModels ? value.motorModels.model_name : '',
                    branch = value.branches ? value.branches.branch_name : '',
                    status = value.transferred === 2 ? '<h4 class="badge badge-success">Received</h4>' : '',
                    date_transferred = Moment(value.dateTransferred).format('MM/DD/YYYY'),
                    button = '<button title="Delete" class="btn btn-sm btn-danger delete"><span class="fa fa-trash" /></button> ';

                output.push([value._id, modelName, value.chas, branch, button]);
            });

            return Promise.resolve(output)
        })
        .catch((err) => {
            console.log(err)
            return Promise.resolve(false)
        })

    }
}
export function duplicateEngineProducts1(arr){
    return (dispatch) => {
        const output = [];

        const existedRows = {}, updatedData = [];
        arr.forEach(function (a) {
            const key = a.model + '_~_' + a.engine_number;
            const keys = Object.keys(existedRows);

            if (keys.indexOf(key) === -1) {
                existedRows[key] = 1
            } else {
                existedRows[key] = existedRows[key] + 1
            }
        });
        arr.forEach(function (a) {
            const key = a.model + '_~_' + a.engine_number;
            if (existedRows[key] > 1) {
                updatedData.push(a);
            }
        });

        updatedData.forEach((value, index) => {
            const brand = value.brands ? value.brands.brand_name : '',
                modelName = value.motorModels ? value.motorModels.model_name : '',
                branch = value.branches ? value.branches.branch_name : '',
                status = value.transferred === 2 ? '<h4 class="badge badge-success">Received</h4>' : '',
                date_transferred = Moment(value.dateTransferred).format('MM/DD/YYYY'),
                button = '<button title="Delete" class="btn btn-sm btn-danger delete"><span class="fa fa-trash" /></button> ';

            output.push([value._id, modelName, value.engine_number, branch, button, value]);
        });

        return Promise.resolve(output)
    }
}

export function duplicateEngineProducts(arr){
    return (dispatch) => {
        const output = [];

        const existedRows = {}, updatedData = [];
          const filteredData = Object.values(arr.reduce((acc, cur) => {
              const key = cur.engine_number + cur.model;
              if (!acc[key]) {
                acc[key] = [cur];
              } else {
                acc[key].push(cur);
              }
              return acc;
            }, {})).filter(group => group.length > 1).flat();

          filteredData.forEach((value, index) => {
            const brand = value.brands ? value.brands.brand_name : '',
                // modelName = value.motorModels ? value.motorModels.model_name : '',
                modelName = value.unit_model_name ? value.unit_model_name : '',
                branch = value.branches ? value.branches.branch_name : '',
                status = value.transferred === 2 ? '<h4 class="badge badge-success">Received</h4>' : '',
                date_transferred = Moment(value.dateTransferred).format('MM/DD/YYYY'),
                button = '<button title="Delete" class="btn btn-sm btn-danger delete"><span class="fa fa-trash" /></button> ';

            output.push([value._id, modelName, value.engine_number, value.chas, branch, button, value]);
        });

        return Promise.resolve(output)
    }
}


export function duplicateEngineProductsPrev(chassis) {
    return (dispatch, getState) => {
        const productsService = feathers.service('products');

        return productsService.find({
            paginate: {
                default: 10,
                max: 50
            },
            query: {
                $limit: q_options.limit,
                deletedInBranch: {
                    $ne: 1
                }
            }
        }).then((data) => {
            const arr = data.data,
                output = [];

            console.log(data)

            const existedRows = {}, updatedData = [];
            arr.map(function (a) {
                const key = a.model + '_~_' + a.engine_number;
                const keys = Object.keys(existedRows);

                if (keys.indexOf(key) === -1) {
                    existedRows[key] = 1
                } else {
                    existedRows[key] = existedRows[key] + 1
                }
            });
            arr.map(function (a) {
                const key = a.model + '_~_' + a.engine_number;
                if (existedRows[key] > 1) {
                    updatedData.push(a);
                }
            });

            updatedData.forEach((value, index) => {
                const brand = value.brands ? value.brands.brand_name : '',
                    modelName = value.motorModels ? value.motorModels.model_name : '',
                    branch = value.branches ? value.branches.branch_name : '',
                    status = value.transferred === 2 ? '<h4 class="badge badge-success">Received</h4>' : '',
                    date_transferred = Moment(value.dateTransferred).format('MM/DD/YYYY'),
                    button = '<button title="Delete" class="btn btn-sm btn-danger delete"><span class="fa fa-trash" /></button> ';

                output.push([value._id, modelName, value.engine_number, branch, button]);
            });

            return Promise.resolve(output)
        }).catch((err) => {
            console.log(err)
            return Promise.resolve(false)
        })

    }
}

export function getTotalCash() {
    return (dispatch, getState) => {
        const customerService = feathers.service('customers');


        return customerService.find({
            paginate: {
                default: 10,
                max: 50
            },
            query: {
                type: 0
            }
        }).then((customer) => {
            const results = customer.data,
                output = [];
            let total = 0;

            results.forEach((value, index) => {
                const model = value.unit_model,
                    payment = value.payment;

                output.push([model, numberWithCommas(payment)]);
                total += payment;
            });

            output.extra = numberWithCommas(total);

            return Promise.resolve(output);

        }).catch((err) => {
            return Promise.resolve([]);
        })

    }
}

export function getTotalInstallment() {
    return (dispatch, getState) => {
        const customerService = feathers.service('customers');


        return customerService.find({
            paginate: {
                default: 10,
                max: 50
            },
            query: {
                type: 1
            }
        }).then((customer) => {
            const results = customer.data,
                output = [];
            let total = 0;

            results.forEach((value, index) => {
                const model = value.unit_model,
                    payment = value.payment;

                output.push([model, numberWithCommas(payment)]);
                total += payment;
            });

            output.extra = numberWithCommas(total);

            return Promise.resolve(output);

        }).catch((err) => {
            return Promise.resolve([]);
        })

    }
}

export function getPaymentsList() {
    return (dispatch, getState) => {
        const paymentService = feathers.service('payment')

        return paymentService.find({
            paginate: {
                default: 10,
                max: 50
            },
        })
            .then((payment) => {
                const results = payment.data,
                    output = [];

                results.forEach((value, index) => {
                    const supplier_name = value.supplier_name ? value.supplier_name : '',
                        receipt_number = value.receipt_number ? value.receipt_number : '',
                        amount = value.amount ? value.amount : '',
                        date_paid = value.date_paid,
                        remarks = value.remarks ? value.remarks : ''

                    output.push([value, supplier_name, receipt_number, numberWithCommas(amount), Moment(date_paid).format('MM/DD/YYYY'), remarks]);
                });

                return Promise.resolve(output);
            }).catch((e) => {
                const data = [];
                return Promise.resolve(data)
            })
    }
}

export function filterPaymentsList(query) {
    return (dispatch, getState) => {
        const paymentService = feathers.service('payment')

        return paymentService.find({
            paginate: {
                default: 10,
                max: 50
            },
            query: query
        }).then((payment) => {
            const results = payment.data,
                output = [];

            results.forEach((value, index) => {
                const supplier_name = value.supplier_name ? value.supplier_name : '',
                    receipt_number = value.receipt_number ? value.receipt_number : '',
                    amount = value.amount ? value.amount : '',
                    date_paid = value.date_paid,
                    remarks = value.remarks ? value.remarks : ''

                output.push([value, supplier_name, receipt_number, numberWithCommas(amount), Moment(date_paid).format('MM/DD/YYYY'), remarks]);
            });

            return Promise.resolve(output);
        }).catch(() => {
            const data = [];
            return Promise.resolve(data)
        })
    }
}

export function setModelsForSecondhand(data) {
    return {
        type: MODELS_FOR_SECONDHAND_UNITS,
        data: data
    }
}


export function getSecondhandUnits() {
    return (dispatch, getState) => {
        const secondhandService = feathers.service('secondhand')

        return secondhandService.find({
            paginate: {
                default: 10,
                max: 50
            },
        }).then((result) => {
                const d = result.data,
                    output = [];

                d.map((value) => {
                    output.push([
                        value,
                        value.model.name,
                        value.engine_number,
                        value.date_added_custom,
                        numberWithCommas(value.selling_price),
                        value.branch_info.branch_name,
                        '<button title="Edit" class="btn btn-sm btn-primary edit"><span class="fa fa-edit" /></button> ' +
                        '<button title="Delete" class="btn btn-sm btn-danger delete"><span class="fa fa-trash" /></button> ' +
                        '<button title="View" class="btn btn-sm btn-warning view"><span class="fa fa-eye" /></button>'
                    ])
                })

                return Promise.resolve(output)
            }).catch((err) => {
                return Promise.resolve([])
            })

    }
}

export function secondhandAdvancedFilter(query) {
    return (dispatch, getState) => {
        const secondhandService = feathers.service('secondhand');

        return secondhandService.find({
            paginate: {
                default: 10,
                max: 50
            },
            query: query
        }).then((result) => {
            const d = result.data,
                output = [];

            d.map((value) => {
                output.push([
                    value,
                    value.model.name,
                    value.engine_number,
                    value.date_added_custom,
                    numberWithCommas(value.selling_price),
                    value.branch_info ? value.branch_info.branch_name : null,
                    '<button title="Edit" class="btn btn-sm btn-primary edit"><span class="fa fa-edit" /></button> ' +
                    '<button title="Delete" class="btn btn-sm btn-danger delete"><span class="fa fa-trash" /></button> ' +
                    '<button title="View" class="btn btn-sm btn-warning view"><span class="fa fa-eye" /></button>'
                ])
            })
            return Promise.resolve(output)
        }).catch((err) => {
            return Promise.resolve([])
        })

    }
}

export function secondhandSoldAdvancedFilter(query) {
    return (dispatch, getState) => {
        const secondhandService = feathers.service('secondhand');

        return secondhandService.find({
            paginate: {
                default: 10,
                max: 50
            },
            query: query
        }).then((result) => {
            const d = result.data,
                output = [];

            d.map((value) => {
                output.push([
                    value,
                    value.model.name,
                    value.engine_number,
                    value.date_added_custom,
                    numberWithCommas(value.selling_price),
                    value.branch_info ? value.branch_info.branch_name : '',
                    '<button title="View" class="btn btn-sm btn-warning view"><span class="fa fa-eye" /></button>'
                ])
            })

            return Promise.resolve(output)
        }).catch((err) => {
            return Promise.resolve([])
        })

    }
}

export function forPricing(data) {
    return (dispatch, getState) => {
        dispatch({
            type: FOR_PRICING,
            data: data
        })
    }
}

export function pricesUpdated(data) {
    return (dispatch, getState) => {
        dispatch({
            type: PRICES_UPDATED,
            data: data
        })
    }
}

export function getIncomingProducts(user, pass, branch, secondhand) {
    return (dispatch, getState) => {
        return feathers.service('products').find({
            paginate: {
                default: 10,
                max: 50
            },
            query: {
                transferred: 1,
                transferredTo: branch,
                brandNew: secondhand ? 0 : 1,
                $limit: 100
            }
        }).then((products) => {
            const results = products.data,
                data = [],
                buttons = '<button class="btn btn-sm btn-warning view-details" title="View">' +
                    '<span class="fa fa-eye" />' +
                    '</button> ' +
                    '<button class="btn btn-sm btn-success accept" title="Accept">' +
                    '<span class="fa fa-check" />' +
                    '</button>';

            results.forEach((value, index) => {
                data.push([
                    value,
                    Moment(value.dateTransferred).format('MMMM DD, YYYY'),
                    value.motorModels ? value.motorModels.model_name : '',
                    value.engine_number,
                    value.branches ? value.branches.branch_name + ' BRANCH' : '',
                    buttons,
                    value]);
            });
            // return data;
            return Promise.resolve(data);
        }).catch((err) => {
            return Promise.resolve(false);
        });
    }
}

export function setIncomingProducts(data) {
    return {
        type: SET_INCOMING_PRODUCTS,
        data: data
    }
}

export function acceptDataId(data) {
    return {
        type: ACCEPT_DATA_ID,
        data: data
    }
}

export function deleteId(data) {
    return (dispatch, getState) => {
        dispatch({
            type: DELETE_ID,
            data: data
        })
    }
}

export function deleteService(data) {
    return (dispatch, getState) => {
        dispatch({
            type: DELETE_SERVICE,
            data: data
        })
    }
}

export function updateSecondhandUnitInfo(data) {
    return {
        type: UPDATE_SECONDHAND_UNIT_INFO,
        data: data
    }
}