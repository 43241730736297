import React, {Component} from 'react';

//redux
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
// actions
import {ActionCreators} from '../../../actions';

//main NavBar
import MainNav from '../../../components/global_nav';
import MainSideBar from '../../../components/global_sidebar';

//custom sidebar
import SubSideBar from './sub_sidebar';

//select tag
import Select from 'react-select';

import toastr from 'toastr';

import app from '../../../helpers/feathers';

import moment from 'moment';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

//custom styles
import '../../../stylesheet/styles.css';

//datatables
import '../../../stylesheet/datatables/datatables.css';

const $ = require('jquery');
$.DataTable = require('datatables.net');

var that;

class transfer extends Component {
    pageIsMounted = false;

    constructor(props) {
        super(props);

        this.state = {
            sold: [],
            productsToTransfer: [],
            selectedBrand: '',
            selectedOption: '',
            selectedBranch: '',
            filter_color: null,
            filter_chassis: null,
            filter_model: '',
            selectChanged: false,
            filter_branch: null,
            selectBranchChanged: false,
            mainId: '',
            product_count: 0,
            modelsObj: [],
            date_transferred: moment(),
            transferring: false,
        };
    }

    componentWillMount() {
        that = this;
        this.loadTable();
    }

    loadTable = () => {
        const main = {};
        app.service('branches').find({
            query: {
                branch_name: 'MAIN'
            }
        })
        .then((d) => {
            const v = d.data[0]._id;
            main.id = v;

            this.setState({
                mainId: v
            })

            this.props.actions.productsToTransfer(v).then(data => {
                this.props.actions.setProductsToTransfer(data);
                // that.updateData(data);
            });
        })
        .catch((err) => {
            console.log(err)
        })
    }

    componentDidMount() {

        const that = this,
            productTable = $('.tbl-transfer').DataTable({
                data: this.state.sold,
                columns: [
                    {"title": "ID"},
                    // { "title": '<input type="checkbox" />' },
                    {"title": ""},
                    {"title": "MODEL NAME"},
                    {"title": "BRAND NAME"},
                    {"title": "COLOR"},
                    {"title": "ENGINE NO"}
                ],
                columnDefs: [
                    {
                        targets: [0],
                        visible: false
                    },
                    {
                        targets: [1],
                        width: '5%',
                        orderable: false
                    }
                ],
                'dom': "<'row'<'col-sm-6'l><'col-sm-6'p>>" +
                    "<'row'<'col-sm-12'tr>>" +
                    "<'row'<'col-sm-12'i>>",
                "bFilter": false,
            });

        $('.search').keyup(function () {
            productTable.search($(this).val()).draw();
        });

        that.pageIsMounted = true;  

        const transferObj = [];

        $('.tbl-transfer').on('change', '.select-product', function () {
            const data = productTable.row($(this).parents('tr')).data();
            if (transferObj.includes(data[0])) {
                const index = transferObj.indexOf(data[0])
                if (index > -1) {
                    transferObj.splice(index, 1);

                    that.setState({
                        productsToTransfer: transferObj
                    })
                }
            } else {
                transferObj.push(data[0])

                that.setState({
                    productsToTransfer: transferObj
                })
            }

            const productService = app.service('products');

            productService.on('patched', function () {
                app.service('branches').find({
                    paginate: {
                        default: 10,
                        max: 50
                    },
                    query: {
                        branch_name: that.props.userData.branch_info.branch_name
                    }
                }).then((d) => {
                    const v = d.data[0]._id;
                    that.props.actions.productsToTransfer(v).then(data => {
                        that.updateData(data);
                    });
                })
            })
        });

    }

    componentWillUnmount(){
        that.pageIsMounted = false;
    }

    updateData = data => {
        this.setState({
            sold: data,
            product_count: data.length
        });

        const table = $('.tbl-transfer').DataTable();
        table.clear();
        table.rows.add(data);
        table.draw();

    }

    handleChange = (selectedOption) => {
        const value = selectedOption;

        if (value != null) {
            this.setState({
                selectedOption: value,
                filter_model: value,
                selectChanged: true
            });
        } else {
            this.setState({
                selectedOption: '',
                filter_model: '',
                selectChanged: false
            })
        }
    }

    handleChangeBranch = (selectedOption) => {
        const value = selectedOption;

        if (value === null) {
            this.setState({
                selectedBranch: '',
                filter_branch: value,
                selectBranchChanged: false
            });
        } else {
            this.setState({
                selectedBranch: value,
                filter_branch: value,
                selectBranchChanged: true
            });
        }
    }

    startTransfer = () => {
        const branch = this.state.selectedBranch ? this.state.selectedBranch.value : null,
            prod = this.state.productsToTransfer,
            date_transferred = this.state.date_transferred;
        let that = this;
        let { transferring } = this.state;

        if(transferring){
            toastr.remove();
            toastr.info('Please wait while transferring unit/s');
            return;
        }

        if (!prod.length) {
            toastr.remove();
            toastr.error('Please select at least one unit')
        } else if (!branch) {
            toastr.remove();
            toastr.error('Please select a branch')
        } else {
            const data = {
                units: prod,
                transferred_by: {
                    user_id: this.props.userData._id,
                    user_name: this.props.userData.fullname
                },
                local_branch: branch,
                date_transferred: date_transferred,
            };

            this.setState({transferring: true})
            
            this.props.actions.startTransfer(data)
                .then((res) => {
                    if (res) {
                        this.loadTable();
                        this.setState({
                            productsToTransfer: [],
                            selectedBranch: '',
                            selectedBrand: '',
                            selectedBrandChanged: false,
                        })
                        this.updateData([]);
                        toastr.remove();
                        toastr.success('Transfer complete')
                    } else {
                        toastr.remove();
                        toastr.error('An error occured during transfer')
                    }
                    that.setState({transferring: false})
                });
        }
    }

    branchOption = () => {
        const array = this.props.branch,
            newArray = [];

        array.map((obj) => {
            if (obj.value !== 'all' && obj.label !== 'MAIN') {
                newArray.push(obj)
            }
        })
        return newArray
    }

    filterProductsToTransfer = () => {
        const branch = this.props.userData.branch_info._id;
        let {brand, model, engine} = this.refs;

        if (brand.value === '' && model.value === '' && engine.value === '') {
            this.updateData([]);
            return;
        }
        const query = {};
        if (brand.value !== '') {
            query.unit_brand_name = {
                $regex: brand.value
            }
        }
        if (model.value !== '') {
            var mod = model.value;
            query.unit_model_name = {
                // $regex: mod.replace(/\s*\(.*?\)\s*/g, '')
                $regex: model.value.includes('GIXXER') ? 'GIXXER' : model.value.replace(/\s*\(.*?\)\s*/g, '')
            }
        }
        if (engine.value !== '') {
            query.engine_number = {
                $regex: engine.value
            }
        }
        query.branch = branch;
        query.transferred = {$in : [0,2]};

        this.props.actions.productsToTransferFilter(query)
        .then((data) => {
            this.updateData(data);
        })
    }

    handleDateTransferred = (date) => {
        this.setState({
            date_transferred: date
        })
    }

    render() {
        return (
            <div className="">
                {/* main NavBar component*/}
                <MainNav historyProp={this.props.history} usersProp={this.props.userData}/>
                <div className="container-fluid">
                    <div className="row full-width">
                        <div className="sidebar">
                            <div className="sidebar-content">
                                <MainSideBar page="inventory"/>
                            </div>
                        </div>
                        <SubSideBar history={this.props.history} allProps={this.props}
                                    userPermission={this.props.userPermission} pageName="transfer"/>
                        <div className="sub-inv-main-content">
                            <div className="container-fluid">
                                <div className="application-container">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <h4 className="subheader-title">Units to Transfer</h4>
                                        </div>
                                        <div className="form-inline">
                                            <div className="col-md-12 subheader-label">
                                                ADVANCED FILTER
                                            </div>
                                            <div className="col-md-12">
                                                <br/>
                                            </div>

                                            <div className="container-fluid">
                                                <div className="row">
                                                    <div className="col">
                                                        <input className="form-control" placeholder="Brand"
                                                               ref="brand" onKeyUp={this.filterProductsToTransfer}/>
                                                    </div>
                                                    <div className="col">
                                                        <input className="form-control" placeholder="Model"
                                                               ref="model" onKeyUp={this.filterProductsToTransfer}/>
                                                    </div>
                                                    <div className="col">
                                                        <input className="form-control"
                                                               placeholder="Engine Number" ref="engine"
                                                               onKeyUp={this.filterProductsToTransfer}/>
                                                    </div>
                                                    <div className="col">
                                                        <button className="btn btn-block btn-second-primary"
                                                                onClick={() => this.filterProductsToTransfer()}>Search
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="row">
                                        {/*<div className="col-md-12">
                                            <span>Result : {this.state.product_count}</span>
                                        </div>*/}
                                        <div className="col-md-12">
                                            <br/>
                                        </div>
                                        <div className="col-md-12">
                                            <table className="table tbl-transfer tbl-mobile">
                                            </table>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="row">
                                                <div className="col-md-2">

                                                </div>
                                                <div className="col-md-10">
                                                    <div className="row">
                                                        <div className="col">
                                                            <Select
                                                                name="select-branch"
                                                                value={this.state.selectedBranch.value}
                                                                onChange={this.handleChangeBranch}
                                                                options={this.branchOption()}
                                                                clearableValue={true}
                                                                placeholder="Branch"
                                                            />
                                                        </div>
                                                        <div className="col">
                                                            <DatePicker
                                                                selected={this.state.date_transferred}
                                                                onChange={this.handleDateTransferred}
                                                                peekNextMonth
                                                                showMonthDropdown
                                                                showYearDropdown
                                                                dropdownMode="select"
                                                                className="form-control"
                                                            />
                                                        </div>
                                                        <div className="col">
                                                            <button className="btn btn-second-primary btn-block"
                                                                    onClick={() => this.startTransfer()}>Transfer
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    app: state.feathersClient.app,
    product: state.product.productsToTransfer,
    userData: state.login.userData,
    userPermission: state.login.userPermission,
    models: state.category.modelsSelect,
    brandsSelect: state.category.brandsSelect,
    branch: state.category.branchesSelect,
});

function mapDispatchToProps(dispatch) {
    return {actions: bindActionCreators(ActionCreators, dispatch)}
}

export default connect(mapStateToProps, mapDispatchToProps)(transfer);
