import * as login from './login';
import * as category from './category';
import * as product from './product';
import * as users from './users';
import * as customers from './customers';
import * as dashboard from './dashboard';
import * as reports from './reports';
// import * as feathersClient from './feathers_config';

export const ActionCreators = Object.assign({}, 
	login,
	category,
	product,
	users,
	customers,
	dashboard,
	reports,
);