import React, {Component} from 'react';
import app from '../../../helpers/feathers';

//main NavBar
import MainNav from '../../../components/global_nav';
import MainSideBar from '../../../components/acc_sidebar';

// moment
import Moment from 'moment';
import {IntlProvider, FormattedMessage, FormattedNumber} from 'react-intl';
import Cleave from 'cleave.js/react';

import ReactDataSheet from 'react-datasheet';

import { numberWithCommas } from '../../../helpers'


//toastr
import toastr from 'toastr';
import Toggle from 'react-bootstrap-toggle';

//sub side bar
import SubSideBar from './sub_sidebar';

import defaultImage from '../../../assets/img/default_avatar.jpg';

//custom styles
import '../../../stylesheet/styles.css';


import html2canvas from 'html2canvas';
import jsPDF from '../../../../node_modules/jspdf/dist/jspdf.min.js';


//redux
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';


// actions
import {ActionCreators} from '../../../actions';
// import {get} from 'https';

const $ = require('jquery');
$.DataTable = require('datatables.net');

var that

class view_financial extends Component {


    constructor(props) {
        super(props);

        console.log('props', props)

        this.state = {
            activeTab: 'home',
            modelName: '',
            payment: [],
            paymentData: [],
            form: {},
            currentBalance: 0,
            add: false,
            delinquent: false,
            saving: false,
            show: false,
            toggleActive: false

        };
    }

    componentWillMount() {
        that = this;

        that.getFinancial();
    }

    componentDidMount() {

    }


    getFinancial = () => {

        let {financialInfo} = this.props,
            cash_sales = financialInfo.cash_sales;

        var date_created = financialInfo.createdAt,
            date_value = Moment(date_created).format('MMMM DD, YYYY');


        that.props.actions.financialData()
            .then((result) => {
                console.log('FINANCIAL INFORMATION ===> ', result)


                console.log('CASH WOWOW', cash_sales)

                if (result.status) {
                    console.log('result.data ', result.data)
                    that.updateDataTable('.tbl-ledger', result.data)
                }
            })
            .catch((error) => {
                console.log('ERROR GET FINANCIAL', error)
            })
    }


    updateDataTable = (table_class, data) => {
        // console.log('updateDataTable ')
        const table = $(table_class).DataTable();
        table.clear();
        table.rows.add(data);
        table.draw();
    }

    print = () => {
        window.print();
    }


    // printDocument() {
    //     const input = document.getElementById('divToPrint');
    //     html2canvas(input)
    //       .then((canvas) => {
    //         const imgData = canvas.toDataURL('image/png');
    //         const pdf = new jsPDF({
    //             orientation: 'landscape',
    //         });
    //         pdf.addImage(imgData, 'JPEG', 0, 0);
    //         // pdf.output('dataurlnewwindow');
    //         pdf.save(that.props.viewReportData.report_title+".pdf");
    //       });
    // }


    render() {
        console.log(this.props.financialInfo);
        return (
            <div className="">
                {/* main NavBar component*/}
                <MainNav
                    historyProp={this.props.history}
                    userPermission={this.props.userPermission}
                    usersProp={this.props.userData}
                    systemType="accounting"
                />

                <div className="container-fluid">
                    <div className="row full-width">
                        <div className="sidebar">
                            <div className="sidebar-content">
                                <MainSideBar page="financial_statement"/>
                            </div>
                        </div>

                        <div className="main-content">


                            <div className="container-fluid application-container" style={{marginTop: 20}}>
                                <div className="col-md-12">
                                    <button className="btn btn-small btn-primary" onClick={() => this.print()}><i
                                        className="fa fa-print"></i> Print
                                    </button>
                                </div>
                                <br/>
                                <div className="tab-content background-white adjust-div">
                                    <div role="tabpanel"
                                         className={"hide-view-customer tab-pane view-cust " + (this.state.activeTab === 'home' ? 'active' : '')}
                                         id="home">
                                        <div className="">
                                            <div className="background-white p-5">
                                                <div className="container-fluid">
                                                    <div className="row d-flex flex-row align-items-center">
                                                        <div className="col-md-12">
                                                            <div className="row">
                                                                <div className="col-md-12">
                                                                    <div className="title-header">
                                                                        <h6> {this.props.financialInfo.branches ? this.props.financialInfo.branches.branch_name : "NO"} BRANCH</h6>
                                                                        <p>Summary of Collection and Expenses</p>
                                                                        <p>{that.props.financialInfo.for_date ? "As of "+that.props.financialInfo.for_date : 'Date not specified'} </p>


                                                                    </div>
                                                                    <br/>
                                                                    <div className="row">
                                                                        <div className="col-sm-12">
                                                                            <center><h3>FINANCIAL STATEMENT REPORT</h3>
                                                                            </center>
                                                                        </div>
                                                                        <div className="col-md-12">
                                                                            <br/>
                                                                        </div>
                                                                        <div className="col-sm-6">
                                                                            <center><h5 className="title-main">
                                                                                <b>EXPENSES:</b></h5></center>
                                                                            <br/>
                                                                            <center><h6><b><i>Capital
                                                                                Expenditure </i></b></h6></center>
                                                                            <center>
                                                                                <div className="content-area">
                                                                                    <table>
                                                                                        <tbody>
                                                                                            <tr>
                                                                                                <td>
                                                                                                    Furniture and Fixture -
                                                                                                </td>
                                                                                                <td>{this.props.financialInfo.furniture_fixes ? numberWithCommas(this.props.financialInfo.furniture_fixes) : 'No Value'}</td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td>
                                                                                                    Office Equipment -
                                                                                                </td>
                                                                                                <td>{this.props.financialInfo.office_equipment ? numberWithCommas(this.props.financialInfo.office_equipment) : 'No Value'}</td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td>
                                                                                                    Other Fixed Assets -
                                                                                                </td>
                                                                                                <td>{this.props.financialInfo.other_fix_assets ? numberWithCommas(this.props.financialInfo.other_fix_assets) : 'No Value'}</td>
                                                                                            </tr>
                                                                                        </tbody>
                                                                                    </table>
                                                                                </div>
                                                                            </center>
                                                                            <br/>
                                                                            <center><h6><b><i>Marketing Expenses</i></b>
                                                                            </h6></center>
                                                                            <center>
                                                                                <div className="content-area">
                                                                                    <table>
                                                                                        <tbody>
                                                                                            <tr>
                                                                                                <td>
                                                                                                    Freebies and Gas for
                                                                                                    release -
                                                                                                </td>
                                                                                                <td>{this.props.financialInfo.freebies ? numberWithCommas(this.props.financialInfo.freebies) : 'No Value'}</td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td>
                                                                                                    Commission -
                                                                                                </td>
                                                                                                <td>{this.props.financialInfo.commision ? numberWithCommas(this.props.financialInfo.commision) : 'No Value'}</td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td>
                                                                                                    Incentives -
                                                                                                </td>
                                                                                                <td>{this.props.financialInfo.incentives ? numberWithCommas(this.props.financialInfo.incentives) : 'No Value'}</td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td>
                                                                                                    Advertising &amp; Promotions
                                                                                                    -
                                                                                                </td>
                                                                                                <td>{this.props.financialInfo.advertising_promotions ? numberWithCommas(this.props.financialInfo.advertising_promotions) : 'No Value'}</td>
                                                                                            </tr>
                                                                                        </tbody>
                                                                                    </table>
                                                                                </div>
                                                                            </center>

                                                                            <br/>
                                                                            <center><h6><b><i>Administrative
                                                                                Expenses</i></b></h6></center>
                                                                            <center>
                                                                                <div className="content-area">
                                                                                    <table>
                                                                                        <tbody>
                                                                                        <tr>
                                                                                            <td>
                                                                                                Salaries and Wages -
                                                                                            </td>
                                                                                            <td>{this.props.financialInfo.salaries_wages ? numberWithCommas(this.props.financialInfo.salaries_wages) : 'No Value'}</td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td>
                                                                                                SSS/PHIC/Pagi-ibig -
                                                                                            </td>
                                                                                            <td>{this.props.financialInfo.sss_phic_pagibig ? numberWithCommas(this.props.financialInfo.sss_phic_pagibig) : 'No Value'}</td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td>
                                                                                                Employees Benefits -
                                                                                            </td>
                                                                                            <td>{this.props.financialInfo.employee_benefits ? numberWithCommas(this.props.financialInfo.employee_benefits) : 'No Value'}</td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td>
                                                                                                Meal Allowance-
                                                                                            </td>
                                                                                            <td>{this.props.financialInfo.meal_allowance ? numberWithCommas(this.props.financialInfo.meal_allowance) : 'No value'}</td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td>
                                                                                                <b> Gas :</b>
                                                                                            </td>
                                                                                            <td>

                                                                                            </td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td>
                                                                                                AM -
                                                                                            </td>
                                                                                            <td>
                                                                                                {this.props.financialInfo.am_gas ? numberWithCommas(this.props.financialInfo.am_gas) : 'No Value'}
                                                                                            </td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td>
                                                                                                BM -
                                                                                            </td>
                                                                                            <td>
                                                                                                {this.props.financialInfo.bm_gas ? numberWithCommas(this.props.financialInfo.bm_gas) : 'No Value'}
                                                                                            </td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td>
                                                                                                CI -
                                                                                            </td>
                                                                                            <td>
                                                                                                {this.props.financialInfo.ci_gas ? numberWithCommas(this.props.financialInfo.ci_gas) : 'No Value'}
                                                                                            </td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td>
                                                                                                L300 / H100/ Multicab -
                                                                                            </td>
                                                                                            <td>
                                                                                                {this.props.financialInfo.l3_gas ? numberWithCommas(this.props.financialInfo.l3_gas) : 'No Value'}
                                                                                            </td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td>
                                                                                                Tax and Licenses -
                                                                                            </td>
                                                                                            <td>
                                                                                                {this.props.financialInfo.tax_license ? numberWithCommas(this.props.financialInfo.tax_license) : 'No Value'}
                                                                                            </td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td>
                                                                                                Rental -
                                                                                            </td>
                                                                                            <td>
                                                                                                {this.props.financialInfo.rental ? numberWithCommas(this.props.financialInfo.rental) : 'No Value'}
                                                                                            </td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td>
                                                                                                Repair and Maintenance -
                                                                                            </td>
                                                                                            <td>
                                                                                                {this.props.financialInfo.repair_maintenance ? numberWithCommas(this.props.financialInfo.repair_maintenance) : 'No Value'}
                                                                                            </td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td>
                                                                                                Light and Water -
                                                                                            </td>
                                                                                            <td>
                                                                                                {this.props.financialInfo.light_water ? numberWithCommas(this.props.financialInfo.light_water) : 'No Value'}
                                                                                            </td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td>
                                                                                                Communication -
                                                                                            </td>
                                                                                            <td>
                                                                                                {this.props.financialInfo.communication ? numberWithCommas(this.props.financialInfo.communication) : 'No Value'}
                                                                                            </td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td>
                                                                                                Office Supplies -
                                                                                            </td>
                                                                                            <td>
                                                                                                {this.props.financialInfo.office_supplies ? numberWithCommas(this.props.financialInfo.office_supplies) : 'No Value'}
                                                                                            </td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td>
                                                                                                Mailing Expenses -
                                                                                            </td>
                                                                                            <td>
                                                                                                {this.props.financialInfo.mailing_expenses ? numberWithCommas(this.props.financialInfo.mailing_expenses) : 'No Value'}
                                                                                            </td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td>
                                                                                                Transportation and
                                                                                                Travel -
                                                                                            </td>
                                                                                            <td>
                                                                                                {this.props.financialInfo.transportation ? numberWithCommas(this.props.financialInfo.transportation) : 'No Value'}
                                                                                            </td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td>
                                                                                                Medical assistance -
                                                                                            </td>
                                                                                            <td>
                                                                                                {this.props.financialInfo.medical_assistance ? numberWithCommas(this.props.financialInfo.medical_assistance) : 'No Value'}
                                                                                            </td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td>
                                                                                                <b> Miscellaneous
                                                                                                    expenses :</b>
                                                                                            </td>
                                                                                            <td>

                                                                                            </td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td>
                                                                                                Photocopy -
                                                                                            </td>
                                                                                            <td>
                                                                                                {this.props.financialInfo.photocopy ? numberWithCommas(this.props.financialInfo.photocopy) : 'No Value'}
                                                                                            </td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td>
                                                                                                Cleaning Materials -
                                                                                            </td>
                                                                                            <td>
                                                                                                {this.props.financialInfo.cleaning_materials ? numberWithCommas(this.props.financialInfo.cleaning_materials) : 'No Value'}
                                                                                            </td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td>
                                                                                                Water Refill -
                                                                                            </td>
                                                                                            <td>
                                                                                                {this.props.financialInfo.water_refill ? numberWithCommas(this.props.financialInfo.water_refill) : 'No Value'}
                                                                                            </td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td>
                                                                                                Other Expenses -
                                                                                            </td>
                                                                                            <td>
                                                                                                {this.props.financialInfo.other_expenses ? numberWithCommas(this.props.financialInfo.other_expenses) : 'No Value'}
                                                                                            </td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td>
                                                                                                <b>Total Expenses </b> -
                                                                                            </td>
                                                                                            <td>
                                                                                                {this.props.financialInfo.total_expenses ? numberWithCommas(this.props.financialInfo.total_expenses) : 'No Value'}
                                                                                            </td>
                                                                                        </tr>
                                                                                        </tbody>
                                                                                    </table>
                                                                                </div>
                                                                            </center>
                                                                        </div>
                                                                        <div className="col-sm-6">
                                                                            <center><h5 className="title-main">
                                                                                <b>COLLECTION:</b></h5></center>
                                                                            <br/>
                                                                            <center><h6><b><i>Brand New &amp; Repo </i></b>
                                                                            </h6></center>
                                                                            <center>
                                                                                <div className="content-area">
                                                                                    <table>
                                                                                        <tbody>
                                                                                        <tr>
                                                                                            <td>
                                                                                                Cash Sales -
                                                                                            </td>
                                                                                            <td>{this.props.financialInfo.cash_sales ? numberWithCommas(this.props.financialInfo.cash_sales) : 'No Value'}</td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td>
                                                                                                Downpayments -
                                                                                            </td>
                                                                                            <td>{numberWithCommas(this.props.financialInfo.downpayment)}</td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td>
                                                                                                Collection (Monthly
                                                                                                Amortization) -
                                                                                            </td>
                                                                                            <td>{numberWithCommas(this.props.financialInfo.collection_ma)}</td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td>
                                                                                                <b> Total collection
                                                                                                    - </b>
                                                                                            </td>
                                                                                            <td>{numberWithCommas(this.props.financialInfo.total_collection)}</td>
                                                                                        </tr>
                                                                                        </tbody>
                                                                                    </table>
                                                                                </div>
                                                                            </center>

                                                                        </div>

                                                                    </div>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <div className="row">

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


const mapStateToProps = state => ({
    customer: state.customers.customerInfo,
    payment: state.customers.customerPayments,
    model: state.category.modelsList,
    brand: state.category.brandsList,
    userData: state.login.userData,
    userPermission: state.login.userPermission,
    ledger: state.customers.ledgerTableData,
    total_payments: state.customers.total_payments,
    viewReportData: state.reports.viewReportData,
    financialInfo: state.reports.financialInfo
});

function mapDispatchToProps(dispatch) {
    return {actions: bindActionCreators(ActionCreators, dispatch)}
}

export default connect(mapStateToProps, mapDispatchToProps)(view_financial);
