import React, {Component} from 'react';
//toastr
import toastr from 'toastr';
//redux
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
// actions
import {ActionCreators} from '../../../actions';

import app from '../../../helpers/feathers';

//datatables
import '../../../stylesheet/datatables/datatables.css';

const $ = require('jquery');
$.DataTable = require('datatables.net');

class category extends Component {
    constructor(props) {
        super(props);

        this.state = {
            models: [],
            modelInfo: {}
        };

    }

    componentWillMount() {
        // get all models from category actions
        this.getModelList();

        // triggered when new model was added
        this.listenCreatedModels();

        // triggered when a model was deleted/removed
        this.listenDeletedModels();
    }

    componentDidMount() {
        const that = this, modelTable = $('.tbl-category-model').DataTable({
            data: this.props.category.modelsList,
            "columnDefs": [
                {
                    "visible": false, "targets": [0, 5]
                },
                {
                    "targets": 1,
                    "width": '10%'
                },
                {
                    "targets": 4,
                    "orderable": false,
                    "width": '20%'
                }
            ],
            columns: [
                {title: "Id"},
                {title: "No."},
                {title: "Name"},
                {title: "Brand"},
                {title: "Action"},
                {title: "Model Obj"},
            ],
            'dom': "<'row'<'col-sm-6'l><'col-sm-6'p>>" +
                "<'row'<'col-sm-12'tr>>" +
                "<'row'<'col-sm-12'i>>",
        });

        //when delete button was clicked

        $('.tbl-category-model').on('click', 'button.delete', function () {
            const data = modelTable.row($(this).parents('tr')).data(),
                permission = that.props.userPermission;

            //call delete actions in category actions
            that.props.actions.modelInUse(data[0])
                .then((modelInUse) => {
                    if (modelInUse) {
                        toastr.error('Could not delete. Item is in use')
                    } else {
                        if (permission.inventory === 2) {
                            that.props.actions.deleteId(data[0]);
                            that.props.actions.deleteService('motorcycle-models');
                            that.refs.confirm_delete.click();
                        }
                    }
                })
                .catch((err) => {
                    console.log(err)
                });
        });

        //when edit button was clicked
        $('.tbl-category-model').on('click', 'button.edit', function () {
            const data = modelTable.row($(this).parents('tr')).data(),
                modelInfo = {
                    id: data[0],
                    name: data[2]
                };
            that.refs.updateModel.click();
            that.props.actions.modelUpdateInfo(data[5]);
        });
        this.getModelList();
    }

    getModelList = () => {
        this.props.actions.getCategoryModels().then((data) => {
            if(data){
                this.props.actions.setModels(data);
                this.modelList(data);
            }
        }).catch(() => {
            const data = [];
            this.props.actions.setModels(data);
            this.modelList(data);
        });
    }

    modelList = data => {
        this.setState({
            models: data
        });

        const table = $('.tbl-category-model').DataTable();
        table.clear();
        table.rows.add(data);
        table.draw();

    }

    listenCreatedModels = () => {
        const motorcycleModel = app.service('motorcycle-models'),
            that = this;

        motorcycleModel.on('created', function (models) {
            that.getModelList();
        });
        motorcycleModel.on('patched', function (models) {
            that.getModelList();
        });
    }

    listenDeletedModels = () => {
        const motorcycleModel = app.service('motorcycle-models'),
            that = this;

        motorcycleModel.on('removed', function (models) {
            that.getModelList();
        });
    }

    updateModelList = data => {
        const table = $('.tbl-category-model').DataTable();
        const modelTables = this.state.models;
        const newData = modelTables.concat(data);

        this.setState({
            models: newData
        });
        table.clear();
        table.rows.add(newData);
        table.draw();
    }


    render() {
        return (
            <div className="row">
                <div className="col-md-12">
                    <div className="row col-md-6">
                        <div className="form-inline col">
                            <h4 className="subheader-title">Models</h4>
                        </div>
                        <div className="col">
                            <button className="btn btn-second-primary add-new-btn" data-toggle="modal"
                                    data-target="#add_model">
                                <span><i className="fa fa-plus"/> Add </span>
                            </button>
                        </div>
                    </div>
                    <button className="btn btn-primary d-none btn-block" data-toggle="modal" data-target="#update_model"
                            ref="updateModel">
                        <span className="fa fa-plus"/> Add
                    </button>
                    <a data-toggle="modal" data-target="#confirm_delete" ref="confirm_delete"></a>
                    <table className="table full-width tbl-category-model tbl-mobile"></table>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    category: state.category,
    userPermission: state.login.userPermission,
});

function mapDispatchToProps(dispatch) {
    return {actions: bindActionCreators(ActionCreators, dispatch)}
}

export default connect(mapStateToProps, mapDispatchToProps)(category);
