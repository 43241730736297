export const CUSTOMERS_TERMS_LIST = "CUSTOMERS_TERMS_LIST";
export const CUSTOMERS_CASH_LIST = "CUSTOMERS_CASH_LIST";
export const SET_CUSTOMERS = "SET_CUSTOMERS";
export const SET_CUSTOMER_TYPE = "SET_CUSTOMER_TYPE";
export const SET_CUSTOMER_INFO = "SET_CUSTOMER_INFO";
export const SET_CUSTOMER_PAYMENTS = "SET_CUSTOMER_PAYMENTS";
export const BRANCH_IN_ADD_CUST = "BRANCH_IN_ADD_CUST";
export const SET_CUSTOMER_LEDGER_INFO = "SET_CUSTOMER_LEDGER_INFO";
export const SET_LEDGER_TABLE_DATA = "SET_LEDGER_TABLE_DATA";
export const SET_LEDGER_ITEM_ID = "SET_LEDGER_ITEM_ID";
export const SET_REMAINING_BALANCE = "SET_REMAINING_BALANCE";
export const SET_PAYMENTS = "SET_PAYMENTS";
export const SET_TOTAL_PAYMENTS = "SET_TOTAL_PAYMENTS";
export const SET_ALL_CUSTOMERS = "SET_ALL_CUSTOMERS";
export const SET_CURRENT_PN = "SET_CURRENT_PN";
export const AREA_NAME_LIST = "AREA_NAME_LIST";
export const SET_RECOMPUTE_DATA = "SET_RECOMPUTE_DATA";

export const CUSTOMERS_OVERDUE_LIST = "CUSTOMERS_OVERDUE_LIST";

export const SELECTED_PAYMENTS = "SELECTED_PAYMENTS";
export const SELECTED_SCHEDULE = "SELECTED_SCHEDULE";
export const EDIT_PAYMENT = "EDIT_PAYMENT";
export const PAYMENT_INFO_EDIT = "PAYMENT_INFO_EDIT";
export const UPDATE_CUSTOMER_INFO = "UPDATE_CUSTOMER_INFO";
export const SET_NEW_CUSTOMER_DATA = "SET_NEW_CUSTOMER_DATA";

export const SET_QUEUES = "SET_QUEUES";
export const SET_PAYMENT_ID = "SET_PAYMENT_ID";

// customers w/ brand new units
export const CUST_BN_UNITS = "CUST_BN_UNITS";
// customers w/ secondhand units
export const CUST_SH_UNITS = "CUST_SH_UNITS";
// customers w/ brand new units (installment)
export const CUST_BN_UNITS_INST = "CUST_BN_UNITS_INST";
// // customers w/ brand new units (cash)
// export const CUST_BN_UNITS_CA = "CUST_BN_UNITS_CA";

