import React, { Component } from 'react';

// moment
import moment from 'moment';
import {IntlProvider, FormattedMessage, FormattedNumber} from 'react-intl';
import Cleave from 'cleave.js/react';

import ReactDataSheet from 'react-datasheet';
// import DatePicker from 'react-datepicker';
// import 'react-datepicker/dist/react-datepicker.css';

import app from '../../../helpers/feathers'

//toastr
import toastr from 'toastr';
import Toggle from 'react-bootstrap-toggle';


//main NavBar
import MainNav from '../../../components/global_nav';
import MainSideBar from '../../../components/acc_sidebar';
//sub side bar
import SubSideBar from './sub_sidebar';

import AddPaymentModal from './add_sh_payment_modal';
import DeletePaymentModalSh from './delete_payment_sh_modal';
import AddDebitModal from './add_sh_debit_modal';
import ReComputeModal from './sh_recompute_modal';
import FullPaidModal from './full_paid_modal';
import EditPaymentModalSH from './edit_payment_modal_sh';
import PaymentHistoryModal from './payment_history_sh_modal';

import defaultImage from '../../../assets/img/default_avatar.jpg';

import { twoDecimalPlaces, numberWithCommas } from '../../../helpers';

//custom styles
import '../../../stylesheet/styles.css';

//redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// actions
import { ActionCreators } from '../../../actions';
const $ = require('jquery');
$.DataTable = require('datatables.net');

var that

class view_ledger_secondhand extends Component {
    constructor(props) {
        super(props);

        this.state = {
            table_data: [],
            start_date: '',
            unit: '',
            editing: false,
            first_payment_date: moment(),
            currentBalance: 0,
            recompute_status: '',
            promisory_note: 0,
            sh_total_payments: 0
        };
    }

    componentWillMount(){
        that = this;
        let { customer, total_payments } = this.props,
            // product_price = customer.selling_price ? customer.selling_price : 0,
            promisory_note = customer.promisory_note,
            recompute_status = '1',
            down_payment = customer.payment,
            initialBal ;

        if(recompute_status === '1'){
            initialBal = (promisory_note - total_payments);
            this.setState({recompute_status: 'Original Customer'})
        }else{
            initialBal = (promisory_note - total_payments);
            this.setState({recompute_status: 'New Customer'})
        }

        that.getLedgerInfo(initialBal);

        that.props.actions.getFirstDate(customer.account_number)
            .then((result) => {
                // console.log('result ', result)
                that.setState({start_date: result.data})
            })

        // that.updateRemainingBalance(true)

        that.getCustomerPayments();
        // that.props.actions.clearData('customer-payments');
    }

    componentDidMount() {


        if($('.status-badge').text() == 'EXPIRED'){
            $('.status-badge').show();
        }
        else{
            $('.status-badge').hide();
        }

        if($('.status-badge').text() == 'EXPIRED'){

            $('.terms-container').text('99 months');

        }

        let { customer, total_payments } = this.props,
            { unit, start_date} = this.state,
            // product_price = customer.secondhand_unit_info.selling_price,
            promisory_note = customer.promisory_note,
            down_payment = customer.payment,
            recompute_status = '1',
            initialBal = promisory_note - down_payment,
            remaining_balance;


        if(recompute_status === '1'){
            remaining_balance = (promisory_note - total_payments);
        }else{
            remaining_balance = (promisory_note - total_payments);
        }


        var mainDtTable = $('.tbl-ledger').DataTable({
            data: this.props.ledger,
            "columnDefs": [
                {
                    "visible": false,
                    "targets": 0
                },
                // {
                //     "targets": 9,
                //     "width": '10%',
                // },
            ],
            columns: [
                { title: "ID" },
                { title: "DATE DUE" },
                { title: "MA" },
                // { title: "AMOUNT" },
                // { title: "DATE PAID" },
                // { title: "REBATE" },
                // { title: "OVERDUE" },
                { title: "BALANCE" },
                // { title: "REMARKS" },
                // { title: "" },
            ],
            "paging": false,
            "pageLength": 36,
            "searching": false,
            "ordering": false,
            "info": false,
            "sDom": '<"bottom"<t>ip><"clear">'
        });

        var paymentDtTable = $('.tbl-customer-payment').DataTable({
            data: this.props.paymentTableData,
            "columnDefs": [
                {
                    "visible": false,
                    "targets": 0
                },
            ],
            columns: [
                { title: "" },
                { title: "DATE " },
                { title: "OR NUMBER" },
                { title: "DR" },
                { title: "CR" },
                { title: "REBATE" },
                { title: "BALANCE" },

                { title: "REMARKS" },
                { title: "EDIT" , className : 'no-print' },
            ],
            "paging": false,
            "pageLength": 100,
            "searching": false,
            "ordering": false,
            "info": false,
            "sDom": '<"bottom"<t>ip><"clear">'
        });



        $('.tbl-ledger').on('click','.edit', function(){
            var tr = mainDtTable.row( $(this).parents('tr') )
            var row = $(this).parents('tr')[0],
                tds = $('>td', row),
                { editing } = that.state;
            var data = mainDtTable.row( $(this).parents('tr') ).data();

            that.props.actions.setLedgerItemId(data[0]);

            $('#update_ledger').modal('show'); return;


            // $(this).parents('tr')
            //   .remove()
            //   .draw();

            //   return;


            if(editing){
                toastr.info('Action not allowed'); return;
            }else{
                that.setState({editing: true})
            }


            tds[2].innerHTML = '<input type="text">'
            tds[3].innerHTML = '<input type="date" className="amount-paid-input" ref="amount_paid">'
            {/*tds[3].innerHTML = <DatePicker
                                    selected={that.state.first_payment_date}
                                    onChange={that.handleFirstPaymentDate}
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    className="form-control"
                                /> // date paid */}
            tds[4].innerHTML = '<input type="text" ref="rebate" className="rebate-input">'
            tds[8].innerHTML = '<button class="btn btn-sm btn-success save" title="Save"><span class="fa fa-check" /></button> <button class="btn btn-sm btn-danger cancel" title="Cancel"><span class="fa fa-close" /></button>'
        })

        $('.tbl-ledger').on('click','.cancel',function(){
            that.setState({editing: false})
            that.getLedgerInfo(remaining_balance);
        })

        $('.tbl-ledger').on('click','.save',function(){
            var amount_paid = $('.amount_paid').val(),
                rebate = $('.rebate').val();

        })

        $('#add_payment').on('hidden.bs.modal', function () {
            that.getCustomerPayments();
            // that.getLedgerInfo(remaining_balance);
            // that.updateRemainingBalance(false);
            $('#add_payment input#payment_input').val('');
            that.getStartDate();
            that.checkIfFullyPaid();
        })

        $('#add_debit').on('hidden.bs.modal', function () {
            that.getCustomerPayments();
            // that.getLedgerInfo(remaining_balance);
            // that.updateRemainingBalance(false);
            $('#add_debit input#payment_input').val('');
            that.getStartDate();
            that.checkIfFullyPaid();
        })

        $('#re_compute').on('hidden.bs.modal', function () {
            that.getCustomerPayments();
            $('#re_compute .form-valid').val('');
            that.getStartDate();
            that.checkIfFullyPaid();
        })

        $('.tbl-customer-payment').on('click', '.edit', function() {
            var data = paymentDtTable.row( $(this).parents('tr') ).data();

            that.props.actions.setCustomerPayments(data[0]);

            $('#editSH').modal('show');
        });

        $('.tbl-customer-payment').on('click', '.delete', function () {
            var data = paymentDtTable.row($(this).parents('tr')).data();

            that.props.actions.setPaymentId(data[0]._id);
            $('#delete_payment_sh').modal('show');
        });

        $('#editSH').on('hidden.bs.modal', function () {
            that.getCustomerPayments();
            that.getStartDate();
        })

        $('#delete_payment_sh').on('hidden.bs.modal', function () {
            that.getCustomerPayments();
            that.getStartDate();
            that.checkIfFullyPaid();
        })

        $('#full_paid').on('hidden.bs.modal', function () {
            that.props.history.push("/fully_paid_sh_customers/")
        })

        that.checkIfFullyPaid();

    }

    getStartDate = () =>{
        let { customer, total_payments } = this.props;
        that.props.actions.getFirstDate(customer.account_number)
            .then((result) => {
                that.setState({start_date: result.data})
            })
    }

    checkIfFullyPaid = () => {
        let { customer, total_payments } = this.props,
            promisory_note = customer.promisory_note,
            remaining_balance = (promisory_note  - total_payments);

        app.service('customer-payments-secondhand').find({
            query : {
                account_number : customer.account_number,
                current : 1,
                $limit : 1,
                $sort: {
                    date_paid: -1
                }
            }
        }).then((data)=> {

            if(data.total){
                var last_date_paid = data.data[0].date_paid;
                that.props.actions.getSHCustomerPayment(customer.account_number, promisory_note)
                    .then((res) => {
                        let remainingBalance = promisory_note - res.total_payments;

                        if(remainingBalance <= 0){
                            $('.modal').modal("hide"); // hide all modal if any exists
                            $('#full_paid').modal("show");
                            that.updateAccountStatus(last_date_paid);
                        }
                    })
            }
        })
        .catch((err) => {
            // console.log(err)
        })

    }

    updateAccountStatus = (last_date_paid) => {
        let { customer } = this.props;

        app.service("customer-secondhand-unit").patch(customer._id, {account_status: 1, date_closed: last_date_paid })
            .then((data) => {
                that.props.mainapp.service("customer-secondhand-unit").find({
                    query : {
                        account_number : customer.account_number
                    }
                }).then((entry)=>{
                    if(entry.data.length > 0){
                        let mainId = entry.data[0]._id;
                        that.props.mainapp.service("customer-secondhand-unit").patch(mainId, {account_status: 1, date_closed : last_date_paid})
                            .then((data)=>{
                            }).catch((err)=>{
                            // console.log('ERROR MAIN', err)
                        })
                    }
                })
            })
            .catch((errors)=>{
                // console.log('ERROR DATA LOCAL', errors)
            })
    }

    handleFirstPaymentDate = (date) => {
        this.setState({first_payment_date: date})
    }

    getCustomerPayments = () => {
        let { customer, total_payments } = this.props,
            promisory_note = customer.promisory_note,
            remaining_balance = (promisory_note  - total_payments);


        that.props.actions.getSHCustomerPayment(customer.account_number, promisory_note)
            .then((res) => {
                that.setState({sh_total_payments: res.total_payments})
                if(res.status){
                    that.updateDataTable('.tbl-customer-payment', res.data)
                }else{
                    that.updateDataTable('.tbl-customer-payment', [])
                }
            })
    }

    updateRemainingBalance = (initial) => {
        let { customer, total_payments } = this.props,
            down_payment = customer.payment,
            promisory_note = customer.promisory_note,
            recompute_status = customer.recompute_status,
            initialBal;
        if(recompute_status === '1'){
            initialBal = (promisory_note - total_payments);
        }else{
            initialBal = (promisory_note - total_payments);
        }



        that.props.actions.getTotalAmountPaid(customer.account_number)
            .then((res) => {
                let total_payments = res.data,
                    initialBalance = promisory_note,
                    remainingBalance = initialBalance - total_payments;

                that.props.actions.setRemainingBalance(remainingBalance);
                that.setState({currentBalance: remainingBalance});
                if(!initial){
                    that.props.actions.updateRemainingBalance(customer.account_number, remainingBalance)
                        .then((res) => {

                            if(res.status){
                                that.getLedgerInfo(initialBal)
                            }

                        })
                }
            })
    }

    getLedgerInfo = (initialBal) => {

        let { customer } = this.props,
            account_number = customer.account_number;

        that.props.actions.customerLedger(account_number, initialBal)
            .then((result) => {
                if(result.status){
                    // that.setState({table_data: result.data})
                    that.tableData(result.data)
                    that.updateDataTable('.tbl-ledger',result.data)
                }
            })
            .catch(() => {

            })



    }

    tableData = (data) => {
        let dt = [];
        data.map((v, i) => {
            dt.push(
                <tr key={i}>
                    <td>{v.due_date}</td>
                    <td>{v.amount_paid}</td>
                    <td>{v.date_paid}</td>
                    <td>{v.rebate}</td>
                    <td>{v.overdue}</td>
                    <td>{v.balance}</td>
                    <td>{v.remarks}</td>
                    <td><button>Update</button></td>
                </tr>
            );
        })
        that.setState({table_data: dt})
    }

    updateDataTable = (table_class, data) => {
        const table = $(table_class).DataTable();
        table.clear();
        table.rows.add(data);
        table.draw();
    }

    addDebit = () => {
        let { customer } = this.props;
        that.props.actions.setLedgerItemId(customer);
        $('#add_debit').modal('show');
    }

    addPayment = () => {
        let { customer } = this.props;

        that.props.actions.setLedgerItemId(customer);
        $('#add_payment').modal('show');
    }

    reCompute = () => {
        let { customer } = this.props;

        that.props.actions.setLedgerItemId(customer);
        $('#re_compute').modal('show');
    }
    showPaymentHistory = () => {
        $("#payment_history").modal("show");
    }

    render() {
        let { customer, total_payments } = this.props,
            { start_date, sh_total_payments} = this.state,
            {old_unit, unit_engine_number, unit_brand, unit_model, unit} = customer,
            branch_name = customer.branch_info ? customer.branch_info.branch_name : "",
            // unit = customer.secondhand_unit_info.model.name,
            customer_id = customer._id,
            customer_name = customer.name,
            account_number = customer.account_number,
            // unit_price = customer.secondhand_unit_info.selling_price,
            engine_number = customer.old_unit ? customer.unit_engine_number : customer.secondhand_unit_info.engine_number,
            status = customer.expiry_status,
            chassis_number = '',
            terms = customer.term,
            date_purchased = moment(customer.date_purchased).format('MMMM DD, YYYY') ,
            first_payment_date = moment(customer.first_payment_date).format('MMMM DD, YYYY') ,
            promisory_note = customer.promisory_note,
            down_payment = customer.payment,
            or_number = customer.or_number ? customer.or_number : '',
            recompute_status = '1',
            paid_status = '',
            remaining_balance;
        if(recompute_status === '1'){
            remaining_balance = (promisory_note  - sh_total_payments);

        }else{
            remaining_balance = (promisory_note - sh_total_payments);
        }

        if (remaining_balance <= '0.00') {
            paid_status = 'FULLY PAID'
        } else {
            paid_status = ''

        }

        if(customer.expiry_status == true){
            status = 'EXPIRED';
        }
        else{
            status = 'ACTIVE';
        }

        return (
            <div className="">
                <MainNav historyProp={this.props.history} userPermission={this.props.userPermission} usersProp={this.props.userData} systemType="accounting" />
                <AddPaymentModal />
                <DeletePaymentModalSh />
                <AddDebitModal />
                <FullPaidModal />
                <PaymentHistoryModal />
                <EditPaymentModalSH />
                <ReComputeModal remainingBalance = {remaining_balance}  down_payment = {down_payment} customer_name = {customer_name}/>
                <div className="container-fluid">
                    <div className="row full-width">
                        <div className="sidebar">
                            <div className="sidebar-content">
                                <MainSideBar page="ledger"/>
                            </div>
                        </div>
                        <div>
                            <div className="main-content">
                                <div className="row customer-ledger__wrapper">
                                    <div className="col-md-12">
                                        <div className="col no-print">
                                            <div className="row no-print">
                                                <div className="col-lg-12">
                                                    <div className="subheader-title">
                                                        Customer Ledger
                                                    </div>
                                                    <div className="row">
                                                        <div id="idNotToPrintThisDiv" className="col-lg-8">
                                                            <a href="#"
                                                               onClick={() => this.props.history.goBack()}
                                                               className="text-gray no-textdecoration">
                                                                <span className="fa fa-chevron-left"/> CUSTOMER LEDGER
                                                                / {customer.name}
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-12">
                                                    <br/>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className="customer-ledger__content-wrapper inline-block width-100p padding-tb-20">
                                            <div className="row label__wrapper">
                                                <div className="subheader-title">
                                                    {customer_name}
                                                </div>
                                                <div className="padding-17">
                                                    {
                                                        this.props.userData.branch_info.branch_name === 'MAIN' ?
                                                        null :
                                                        <button className="btn btn-second-primary no-print"
                                                                onClick={this.reCompute}>
                                                            RECOMPUTE
                                                        </button>
                                                    }
                                                </div>
                                                <div className="padding-17">
                                                    <button className="btn btn-second-primary no-print"
                                                            onClick={() => window.print()}>
                                                        PRINT LEDGER
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="row label__wrapper">
                                                <div className="customer-ledger__label-wrapper">
                                                    <div>Account Number</div>
                                                    <div><b>{account_number}</b></div>
                                                </div>
                                                <div className="customer-ledger__label-wrapper">
                                                    <div>Unit</div>
                                                    <div><b>{unit.name}</b></div>
                                                </div>
                                                <div className="customer-ledger__label-wrapper">
                                                    <div>Down payment</div>
                                                    <div><b>{numberWithCommas(down_payment) + '.00'}</b></div>
                                                </div>
                                                <div className="customer-ledger__label-wrapper">
                                                    <div>OR Number</div>
                                                    <div><b>{or_number}</b></div>
                                                </div>
                                            </div>

                                            <div className="row label__wrapper">
                                                <div className="customer-ledger__label-wrapper">
                                                    <div>Promissory Note</div>
                                                    <div>
                                                        <b>{promisory_note ? numberWithCommas(promisory_note) + '.00' : null}</b>
                                                    </div>
                                                </div>
                                                <div className="customer-ledger__label-wrapper">
                                                    <div>Remaining Balance</div>
                                                    <div><b> <span
                                                        className="remain"><strong>{numberWithCommas(remaining_balance) + '.00'}</strong></span></b>
                                                        {/*<span> { paid_status ? <span className = "paid badge badge-primary"></span> : null }</span>*/}
                                                    </div>
                                                </div>
                                                <div className="customer-ledger__label-wrapper">
                                                    <div>Branch</div>
                                                    <div><b> <span
                                                        className="remain"><strong>{branch_name}</strong></span></b>
                                                    </div>
                                                </div>
                                                <div className="customer-ledger__label-wrapper">
                                                    <div>Date of First Payment</div>
                                                    <div><b>{first_payment_date}</b></div>
                                                </div>
                                                <div className="customer-ledger__label-wrapper">
                                                    <div></div>
                                                    <div><b>   {paid_status ?
                                                        <span className="paid badge badge-primary"></span> : null}</b>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="row label__wrapper">
                                                <div className="customer-ledger__label-wrapper">
                                                    <div>Term</div>
                                                    <div><b>{terms} months</b></div>
                                                </div>
                                                <div className="customer-ledger__label-wrapper">
                                                    <div>Engine Number</div>
                                                    <div><b>{engine_number}</b></div>
                                                </div>
                                                <div className="customer-ledger__label-wrapper">
                                                    <div>Status</div>
                                                    <div>
                                                        <b>{this.props.customer.recompute_status === '1' ? 'Original Customer' : 'New Customer'} <span className  = 'badge badge-success status-badge'>{status}</span></b>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="padding-10 no-print">
                                                <hr/>
                                            </div>
                                            <div className="no-print">
                                                <button  hidden={this.props.userData.branch_info.branch_name === 'MAIN'} className="btn btn-second-primary" onClick={this.addPayment}>Add
                                                    Payment/Credit
                                                </button>
                                                &nbsp;
                                                <button hidden={this.props.userData.branch_info.branch_name === 'MAIN'}  className="btn btn-second-primary" onClick={this.addDebit}>Add
                                                    Debit
                                                </button>
                                                &nbsp;
                                                <button className="btn btn-second-primary"
                                                        onClick={this.showPaymentHistory}>Payment History
                                                </button>
                                            </div>
                                            <div className="row">
                                                <br/>
                                            </div>
                                            <div className="row padding-bottom-20">
                                                <div className="container-fluid">
                                                    <table
                                                        className="table table-sm tbl-customer-payment table-bordered">
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <br/>
                                <br/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


const mapStateToProps = state => ({
    app: state.feathersClient.app,
    mainapp: state.feathersClient.mainapp,
    customer: state.customers.customerInfo,
    payment: state.customers.customerPayments,
    model: state.category.modelsList,
    brand: state.category.brandsList,
    userData: state.login.userData,
    userPermission: state.login.userPermission,
    ledger: state.customers.ledgerTableData,
    promisory_note: state.customers.promisory_note,
    paymentTableData: state.customers.paymentTableData,
    total_payments: state.customers.total_payments,
});

function mapDispatchToProps(dispatch) {
    return { actions: bindActionCreators(ActionCreators, dispatch) }
}

export default connect(mapStateToProps, mapDispatchToProps)(view_ledger_secondhand);
