import React, {Component} from 'react';
import {Link} from 'react-router-dom';

class sub_sidebar extends Component {

    constructor(props) {
        super(props);
    }

    handleChange(value) {
        this.props.history.push(`${value}`);
    }

    componentDidMount() {

    }

    render() {
        let permission = this.props.userPermission,
            link_all = permission.inventory === 0 ? " " : "/all_units/",
            link_recent = permission.inventory === 0 ? " " : "/inventory/",
            link_sold = permission.inventory === 0 ? " " : "/inventory_sold",
            link_outgoing = permission.inventory === 0 ? " " : "/inventory_outgoing",
            link_incoming = permission.inventory === 0 ? " " : "/inventory_incoming",
            link_transfer = permission.inventory === 0 ? " " : "/inventory_transfer",
            second_link_recent = permission.inventory === 0 ? " " : "/inventory_secondhand/",
            second_link_sold = permission.inventory === 0 ? " " : "/inventory_sold_secondhand",
            second_link_transfer = permission.inventory === 0 ? " " : "/inventory_transfer_secondhand",
            second_link_outgoing = permission.inventory === 0 ? " " : "/inventory_outgoing_secondhand",
            link_inventory_search_dr = permission.inventory === 0 ? " " : "/inventory_search_dr",
            link_inventory_duplicate_entries = permission.inventory === 0 ? " " : "/inventory_duplicate_entries";

        let x = {
            subnav: [
                {id: link_all, name: 'All Units'},
                {id: link_recent, name: 'In Stock (Brand New)'},
                {id: link_sold, name: 'Sold'},
                {id: link_transfer, name: 'Transfer'},
                {id: link_outgoing, name: 'Outgoing'},
                {id: link_incoming, name: 'Incoming'},
                {id: second_link_recent, name: 'In Stock (Secondhand)'},
                {id: second_link_sold, name: 'Sold'},
                {id: link_inventory_search_dr, name: 'Search D.R. Number'},
                {id: link_inventory_duplicate_entries, name: 'Duplicate Entries'}
            ]
        };

        let subnav = x.subnav.length > 0
            && x.subnav.map((item, i) => {
                if(this.props.history.location.pathname === item.id){
                    return (
                        <option key={i} value={item.id} selected>{item.name}</option>
                    )
                }else{
                    return (
                        <option key={i} value={item.id}>{item.name}</option>
                    )
                }

            }, this);

        return (
            <div>
                {<div className="sub-sidebar inv-sub-sidebar">
                    {/*<ul className="nav flex-column list">*/}
                    {window.outerWidth <= 480 ?
                        <div className="sub-sidebar inv-sub-sidebar mobile-inv-dashboard__wrapper">
                            <ul className="list">
                                <li className="non-nav subheader__name">
                                    <h4>Inventory </h4>
                                </li>
                                <div className="mobile-dropdown__wrapper">
                                    <select className="dropdown__select" onChange={event => this.handleChange(event.target.value)}>
                                        {subnav}
                                    </select>
                                </div>
                            </ul>
                        </div> :
                        <ul className="list">
                            {/*<li className="nav-item non-nav subheader__name">*/}
                            <li className="non-nav subheader__name">
                                <h4>Inventory</h4>
                            </li>
                            <li className="nav-item">
                                <Link to={link_all}
                                      className={this.props.pageName === 'master-list' ? 'nav-link active-inventory' : 'nav-link'}>
                                    All Units</Link>
                            </li>
                            <li className="nav-item">
                                <Link to={link_recent}
                                      className={this.props.pageName === 'main' ? 'nav-link active-inventory' : 'nav-link'}>
                                    In Stock (<small>Brand New</small>)</Link>
                            </li>
                            <li className="nav-item">
                                <Link to={link_sold}
                                      className={this.props.pageName === 'sold' ? 'nav-link active-inventory' : 'nav-link'}>Sold</Link>
                            </li>
                            <li className="nav-item">
                                <Link to={link_transfer}
                                      className={this.props.pageName === 'transfer' ? 'nav-link active-inventory' : 'nav-link'}>Transfer</Link>
                            </li>
                            <li className="nav-item">
                                <Link to={link_outgoing}
                                      className={this.props.pageName === 'outgoing' ? 'nav-link active-inventory' : 'nav-link'}>Outgoing</Link>
                            </li>
                            <li className="nav-item">
                                <Link to={link_incoming}
                                      className={this.props.pageName === 'incoming' ? 'nav-link active-inventory' : 'nav-link'}>Incoming</Link>
                            </li>
                            {window.outerWidth <= 768 ? '' :
                                <li className="nav-item divider"></li>
                            }
                            <li className="nav-item">
                                <Link to={second_link_recent}
                                      className={this.props.pageName === 'second_main' ? 'nav-link active-inventory' : 'nav-link'}>In
                                    Stock (<small>Secondhand</small>)</Link>
                            </li>
                            <li className="nav-item">
                                <Link to={second_link_sold}
                                      className={this.props.pageName === 'second_sold' ? 'nav-link active-inventory' : 'nav-link'}>
                                    Sold (<small>Secondhand</small>)
                                </Link>
                            </li>
                            {
                                // <li className="nav-item">
                                //     <Link to={second_link_transfer}  className={this.props.pageName === 'second_transfer' ? 'nav-link active-inventory' : 'nav-link'}>Transfer</Link>
                                // </li>
                                // <li className="nav-item">
                                //     <Link to={second_link_outgoing}  className={this.props.pageName === 'second_outgoing' ? 'nav-link active-inventory' : 'nav-link'}>Outgoing</Link>
                                // </li>
                            }
                            {window.outerWidth <= 768 ? '' :
                                <li className="nav-item divider">

                                </li>
                            }
                            <li className="nav-item">
                                <Link to={link_inventory_search_dr}
                                      className={this.props.pageName === 'search_dr' ? 'nav-link active-inventory' : 'nav-link'}>Search
                                    D.R. Number</Link>
                            </li>
                            {window.outerWidth <= 768 ? '' :
                                <li className="nav-item divider">

                                </li>
                            }
                            <li className="nav-item">
                                <Link to={link_inventory_duplicate_entries}
                                      className={this.props.pageName === 'duplicate_entries' ? 'nav-link active-inventory' : 'nav-link'}>Duplicate
                                    Entries</Link>
                            </li>
                            {/*<li className="nav-item">
                            <button className="btn padding-10-40" onClick={() => this.deleteInv()}>DELETE</button>
                        </li>*/}
                        </ul>}
                </div>}
            </div>
        );
    }
}

export default sub_sidebar;
