import React, {Component} from 'react';

// moment
import moment from 'moment';
import Cleave from 'cleave.js/react';

import ReactDataSheet from 'react-datasheet';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

//toastr
import toastr from 'toastr';
import Toggle from 'react-bootstrap-toggle';

//main NavBar
import MainNav from '../../../components/global_nav';
import MainSideBar from '../../../components/acc_sidebar';
//sub side bar
import SubSideBar from './sub_sidebar';

// import OverdueModal from './overdue_modal'

import defaultImage from '../../../assets/img/default_avatar.jpg';

import {twoDecimalPlaces, numberWithCommas} from '../../../helpers';

import DateRangePicker from "react-bootstrap-daterangepicker";
import "./plugins/daterangepicker.css";

//custom styles
import '../../../stylesheet/styles.css';

import app from '../../../helpers/feathers';

//redux
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import dashboardLogo from '../../../assets/icons/dashboard.svg';
import inventoryLogo from '../../../assets/icons/inventory.svg';
import customerLogo from '../../../assets/icons/customer.svg';
import reportLogo from '../../../assets/icons/reports.svg';
import overdueLogo from '../../../assets/icons/overdue-white.png';
import financeLogo from '../../../assets/icons/financial-statement-white.png';
import reportIcon from '../../../assets/icons/reports.svg';
import userLogo from '../../../assets/icons/user.svg';
import settingLogo from '../../../assets/icons/settings.svg';
import financialStatementIcon from '../../../assets/icons/financialstatement_icon.svg';
import ledgerIcon from '../../../assets/icons/ledger_icon.svg';

// actions
import {ActionCreators} from '../../../actions';

const $ = require('jquery');
$.DataTable = require('datatables.net');

var that;
const SH_CUSTOMERS = "customer-secondhand-unit";
const SH_LEDGER = "ledger-secondhand";
const SH_PAYMENTS = "customer-payments-secondhand"

class overdue_customer extends Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    componentDidMount() {
        $('.back-button-header').hide();
    }

    componentWillMount() {
        that = this;

        $('.back-button-header').hide();

        let LOCAL_APP = app,
            LOCAL_CUSTOMERS = LOCAL_APP.service("customers"),
            LOCAL_SH_CUSTOMERS = LOCAL_APP.service("customer-secondhand-unit");

        /*LOCAL_CUSTOMERS.find({
            // query: {
            //     $limit: 20
            // }
        })
        .then((customers) => {
            if (customers.total) {
                let customerData = customers.data;

                var recursive = function (index) {
                    let itemData = customerData[index],
                        id = itemData._id,
                        new_date_formatted = moment(itemData.date_purchased).format("MM/YYYY");

                    if (index > 0) {
                        LOCAL_CUSTOMERS.patch(id, {
                            date_purchased_formatted: new_date_formatted
                        })
                            .then(() => {
                                return recursive(index - 1)
                            })
                            .catch(() => {
                                return recursive(index - 1)
                            })
                    } else {
                        LOCAL_CUSTOMERS.patch(id, {
                            date_purchased_formatted: new_date_formatted
                        })
                            .then(() => {
                                // return recursive(index - 1)
                            })
                            .catch(() => {
                                // return recursive(index - 1)
                            })
                    }
                }

                recursive(customers.data.length - 1)
            }
        })
        .catch((err) => {
            console.log(err)
        })

        LOCAL_SH_CUSTOMERS.find()
            .then((customers) => {
                if (customers.total) {
                    let customerData = customers.data;

                    var recursive = function (index) {
                        let itemData = customerData[index],
                            id = itemData._id,
                            new_date_formatted = moment(itemData.date_purchased).format("MM/YYYY");

                        if (index > 0) {
                            LOCAL_SH_CUSTOMERS.patch(id, {
                                date_purchased_formatted: new_date_formatted
                            })
                                .then(() => {
                                    return recursive(index - 1)
                                })
                                .catch(() => {
                                    return recursive(index - 1)
                                })
                        } else {
                            LOCAL_SH_CUSTOMERS.patch(id, {
                                date_purchased_formatted: new_date_formatted
                            })
                                .then(() => {
                                    // return recursive(index - 1)
                                })
                                .catch(() => {
                                    // return recursive(index - 1)
                                })
                        }
                    }

                    recursive(customers.total - 1)
                }
            })
        .catch((err) => {
            console.log(err)
        }) */
    }

    gotoPage = (page) => {
        this.props.history.push(page)
    }

    render() {

        return (
            <div className="">
                <MainNav historyProp={this.props.history} userPermission={this.props.userPermission}
                         usersProp={this.props.userData} systemType="accounting"/>
                <div className="container-fluid">
                    <div className="row full-width">
                        <div className="sidebar">
                            <div className="sidebar-content">
                                <MainSideBar page="accounting"/>
                            </div>
                        </div>
                        <div>
                            <div className="main-content web-acc-dashboard__wrapper">
                                <div className="row">
                                    <div className="col-md-12">
                                        {/*<div className="space-100"/>*/}
                                        <div className="inline-block width-100p padding-tb-20">
                                            <div className="row padding-bottom-20">
                                                <div className="col center-items menu-items">
                                                    <div className="inner-menu-item"
                                                         onClick={() => this.gotoPage("/all_customers")}>
                                                        <div className="item-wrap">
                                                            <img alt="icon" src={customerLogo}
                                                                 className="sidebarLogo " style={{height: 70}}/>
                                                            <p className="text1">Customers</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col center-items menu-items">
                                                    <div className="inner-menu-item"
                                                         onClick={() => this.gotoPage("/ledger/")}>
                                                        <img alt="icon" src={ledgerIcon} className="sidebarLogo"
                                                             style={{height: 55}}/>
                                                        <p className="text2">Ledger</p>
                                                    </div>
                                                </div>
                                                <div className="col center-items menu-items">
                                                    <div className="inner-menu-item"
                                                         onClick={() => this.gotoPage("/acc_total_paid/")}>
                                                        <img alt="icon" src={reportIcon} className="sidebarLogo"
                                                             style={{height: 55}}/>
                                                        <p className="text2">Reports</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row padding-bottom-20">
                                                <div className="col center-items menu-items">
                                                    <div className="inner-menu-item"
                                                         onClick={() => this.gotoPage("/overdue_customer/")}>
                                                        <img alt="icon" src={overdueLogo}
                                                             className="sidebarLogo" style={{height: 55}}/>

                                                        <p className="text2">Overdue</p>
                                                    </div>
                                                </div>
                                                <div className="col center-items menu-items">
                                                    <div className="inner-menu-item"
                                                         onClick={() => this.gotoPage("/acc_financial_statement/")}>
                                                        <img alt="icon" src={financeLogo}
                                                             className="sidebarLogo" style={{height: 55}}/>

                                                        <p className="text2">Financial Statement</p>
                                                    </div>
                                                </div>
                                                <div className="col center-items menu-items">
                                                    <div className="inner-menu-item"
                                                         onClick={() => this.gotoPage("/accounts_payable/")}>
                                                        <img alt="icon" src={reportLogo} className="sidebarLogo"
                                                             style={{height: 70}}/>
                                                        <p className="text1">Accounts Payable</p>
                                                    </div>
                                                </div>
                                                {
                                                    // <div className="col center-items menu-items">
                                                    //     <div className="inner-menu-item" onClick={() => this.gotoPage("/acc_financial_statement/")}>
                                                    //         <img alt="icon" src={financialStatementIcon} className="sidebarLogo" style={{height: 70}}/>
                                                    //         <p className="text1">Financial Statement</p>
                                                    //     </div>
                                                    // </div>
                                                    // <div className="col center-items menu-items">
                                                    //     <div className="inner-menu-item" onClick={() => this.gotoPage("/overdue_customer/")}>
                                                    //         <img alt="icon" src={ledgerIcon} className="sidebarLogo" style={{height: 55}}/>
                                                    //         <p className="text2">Customers w/ Overdue</p>
                                                    //     </div>
                                                    // </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <br/>
                                <br/>
                            </div>

                            <div className="main-content mobile-acc-dashboard__wrapper">
                                {/*<div className="row">*/}
                                <div className="space-50"/>
                                <div className="row padding-tb-20">
                                    <div className="col">
                                        <div className="mobile-list-dashboard__wrapper">
                                            <div className="inner-menu-item"
                                                 onClick={() => this.gotoPage("/acc_customer/")}>
                                                <div className="item-wrap">
                                                    <img alt="icon" src={customerLogo}
                                                         className="sidebarLogo icon-dashboard"/>
                                                    <p className="text1 label-dashboard">Customers</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mobile-list-dashboard__wrapper">
                                            <div className="inner-menu-item"
                                                 onClick={() => this.gotoPage("/ledger/")}>
                                                <img alt="icon" src={ledgerIcon} className="sidebarLogo
                                                icon-dashboard"/>
                                                <p className="text2 label-dashboard">Ledger</p>
                                            </div>
                                        </div>
                                        <div className="mobile-list-dashboard__wrapper">
                                            <div className="inner-menu-item"
                                                 onClick={() => this.gotoPage("/acc_total_paid/")}>
                                                <img alt="icon" src={reportIcon}
                                                     className="sidebarLogo icon-dashboard"/>
                                                <p className="text2 label-dashboard">Reports</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="mobile-list-dashboard__wrapper">
                                            <div className="inner-menu-item"
                                                 onClick={() => this.gotoPage("/acc_financial_statement/")}>
                                                <img alt="icon" src={financeLogo}
                                                     className="sidebarLogo icon-dashboard"/>

                                                <p className="text2 label-dashboard">Financial</p>
                                                <p className="text2 sub-label-dashboard">Statement</p>
                                            </div>
                                        </div>
                                        <div className="mobile-list-dashboard__wrapper">
                                            <div className="inner-menu-item"
                                                 onClick={() => this.gotoPage("/accounts_payable/")}>
                                                <img alt="icon" src={reportLogo} className="sidebarLogo icon-dashboard"
                                                />
                                                <p className="text1 label-dashboard">Accounts </p>
                                                <p className="text2 sub-label-dashboard">Payable</p>
                                            </div>
                                        </div>
                                        <div className="mobile-list-dashboard__wrapper">
                                            <div className="inner-menu-item"
                                                 onClick={() => this.gotoPage("/overdue_customer/")}>
                                                <img alt="icon" src={overdueLogo}
                                                     className="sidebarLogo icon-dashboard"/>
                                                <p className="text2 label-dashboard">Overdue</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <br/>
                                <br/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    app: state.feathersClient.app,
    userData: state.login.userData,
});

function mapDispatchToProps(dispatch) {
    return {actions: bindActionCreators(ActionCreators, dispatch)}
}

export default connect(mapStateToProps, mapDispatchToProps)(overdue_customer);
