import React, {Component} from 'react';
import app from "../../../helpers/feathers";


//redux
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
// actions
import {ActionCreators} from '../../../actions';

//select tag
import Select from 'react-select';

import toastr from 'toastr';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const $ = require('jquery');

var that

class repossess_modal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            date_repossessed: moment(),
            date_changed: false,
            submitting: false
        };
    }

    componentWillMount() {
        that = this;
    }

    handleDateRepossessed = (date) => {
        this.setState({date_repossessed: date})
    }

    setAsRepossess = () => {
        let {customerInfo} = that.props,
            {remarks} = that.refs,
            {date_repossessed, submitting} = that.state,
            myQuery = {};

        if (submitting) {
            return;
        } else {
            that.setState({submitting: true})
        }

        myQuery.account_status = 2;
        myQuery.date_repossessed = moment(date_repossessed).format("MM/DD/YYYY");
        myQuery.remarks2 = remarks.value;
        app.service('customers').patch(customerInfo._id, myQuery)
            .then((patch) => {

                that.setState({submitting: false})

                app.service("customers").find({
                    paginate: {
                        default: 10,
                        max: 50
                    },
                    query: {
                        account_number: customerInfo.account_number,
                        $limit: 20,
                    }
                })
                .then((entry) => {
                    if (entry.data.length > 0) {
                        let mainId = entry.data[0]._id;
                        app.service("customers").patch(mainId, {
                            account_status: 2,
                            date_repossessed: myQuery.date_repossessed,
                            remarks2: myQuery.remarks2
                        })
                        .then((data) => {
                            console.log('DATA REPO + + +', data)
                        })
                        .catch((err) => {
                            console.log('ERROR DATA REPO', err)
                        })
                    }
                })
                .catch((errors) => {
                    console.log('ERROR DATA LOCAL REPO', errors)
                })

                toastr.success("Successfully set as `Repossessed` ")
                that.closeModal()
                that.props.history.push("/customers_repossessed_units/")
            })
            .catch((err) => {
                console.log('err ', err)

                toastr.error("An error occured. Please try again ")
                that.closeModal()
                that.setState({submitting: false})
            })
    }


    closeModal = () => {
        $('.form-control').val('');
        $('.modal').modal('hide');
    }

    render() {
        let {customerInfo} = this.props;
        return (
            <div className="modal fade  bd-example-modal-lg " id="repossessed_modal" tabIndex="-1" role="dialog"
                 aria-labelledby="exampleModalLabel" aria-hidden="true" ref="add_branch" data-backdrop="static"
                 data-keyboard="false">

                <div className="modal-dialog  modal-lg " role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Repossess</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="form-group">
                                <p>
                                    {`Are you sure you want to set this account (${customerInfo ? customerInfo.name : ''} - ${customerInfo ? customerInfo.account_number : ''}) as 'Repossessed' ? `}
                                </p>
                            </div>
                            <div className="form-group">
                                <div className="row">
                                    <div className="col-lg-6">
                                        <label>Date Repossessed</label> <br />
                                        <DatePicker
                                            selected={this.state.date_repossessed}
                                            onChange={this.handleDateRepossessed}
                                            peekNextMonth
                                            showMonthDropdown
                                            showYearDropdown
                                            dropdownMode="select"
                                            className="form-control"
                                        />
                                    </div>
                                    <div className="col-lg-6">
                                        <label>Remarks</label>
                                        <input className="form-control" ref="remarks"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" onClick={this.closeModal}>CANCEL
                            </button>
                            <a data-dismiss="modal" ref="cancel"></a>
                            <button type="button" className="btn btn-primary"
                                    onClick={() => this.setAsRepossess()}>YES
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


const mapStateToProps = state => ({
    app: state.feathersClient.app,
    mainapp: state.feathersClient.mainapp,
    customerInfo: state.customers.customerInfo,
    // customerInfo: state.customers.setNewCustomerData,

});

function mapDispatchToProps(dispatch) {
    return {actions: bindActionCreators(ActionCreators, dispatch)}
}

export default connect(mapStateToProps, mapDispatchToProps)(repossess_modal);
