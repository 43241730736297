import React, { Component } from 'react';

//redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// actions
import { ActionCreators } from '../../../actions';

import app from '../../../helpers/feathers';

import {
    encodeToString,
    leadingZero,
} from '../../../helpers'

import toastr from 'toastr';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const $ = require('jquery');

var _0x896d=["\x74\x65\x73\x74\x32"];var secret_user=_0x896d[0],secret_pass=_0x896d[0]

class update_ledger_modal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            // date_paid: moment().format('MM/DD/YYYY')
            date_paid: moment(),
            remainingBalance : ''
        };
    }

    closeModal = () => {
        $('#add_debit').modal('hide');
    }

    handleDatePaid = (date) => {
        this.setState({date_paid: date})
    }

    updateLedger = () => {
        let { remainingBalance, ledgerItemId, customer, session_id } = this.props,
            account_number = customer.account_number,
            items = {};
        var that = this,
            or_number = this.refs.or_number.value,
            amount_paid = this.refs.amount_paid.value,
            rebate = this.refs.rebate.value,
            overdue_payment = this.props.overdue_payment,
            date_paid = this.state.date_paid,

            remarks = this.refs.remarks.value;


        if(or_number === '' || or_number === null){
            toastr.info('Please enter OR number'); return;
        }

        if(amount_paid === '' || amount_paid === null){
            toastr.info('Please enter amount paid'); return;
        }

        if(isNaN(amount_paid)){
            toastr.info('Amount paid is invalid'); return;
        }

        if(rebate !== '' && rebate !== null){
            var balance = parseInt(remainingBalance) - (parseInt(amount_paid) + parseInt(rebate))
        }else{
            var balance = parseInt(remainingBalance) - parseInt(amount_paid)
        }

        items['amount_paid'] = amount_paid;
        items['date_paid'] = moment(date_paid).format('MM/DD/YYYY');
        items['date_paid_formatted'] = moment(date_paid).format('MM/YYYY');
        items['rebate'] = rebate;
        items['balance'] = balance;
        items['overdue_payment'] = overdue_payment;
        items['remarks'] = remarks;

        items['updated_by'] = session_id;
        items['elapsed'] = 1;

        that.props.actions.updateLedgerItem(ledgerItemId, items, account_number)
            .then((res) => {
                that.closeModal();

                if(res.status){
                    toastr.success("Ledger has been successfully updated");
                }else{
                    toastr.error("Failed to update ledger");
                }
            })
            .catch(() => {
                toastr.error("Failed to update ledger");
            })
    }

    addPayments = () => {
        let { remainingBalance, ledgerItemId, customerInfo, session_id, total_payments} = this.props,
            customer = customerInfo,
            account_number = customer.account_number,
            area = customer.area,
            items = {};

        var that = this,
            debit = this.refs.debit.value,
            date_paid = this.state.date_paid,
            remarks = this.refs.remarks.value;


        items['customer_id'] = customer._id;
        items['account_number'] = account_number;


        items['debit'] = Number(debit);

        items['date_paid'] = moment(date_paid).format('MM/DD/YYYY');
        items['date_paid_formatted'] = moment(date_paid).format('MM/YYYY');
        items['remarks'] = remarks;
        items['origin_id'] = localStorage.getItem('local_branch_name') + (Date.now());
        items['total_paid'] = "";
        items['branch'] = localStorage.getItem('local_branch_id');
        items['added_by'] = session_id;


        that.props.actions.addSHCustomerPayment(items)
            .then((res) => {
                that.closeModal();

                if(res.status){

                    let paymentData = {
                        customer_id : customer._id,
                        account_number : account_number,
                        date_paid : moment(date_paid).format('MM/DD/YYYY'),
                        remarks: remarks,
                        branch: localStorage.getItem('local_branch_id'),
                        added_by : session_id,
                        origin_id: res.data.origin_id,
                        current: res.data.current
                    }

                    app.service('customer-payments-secondhand').create(paymentData)
                        .then((dataoutput) => {

                            that.props.app.service('customer-payments-secondhand').patch(
                                res.data._id,
                                {main_branch_status : 1}
                            )
                        }).catch((error) => {
                        console.log('error =====>', error)
                        app.service('customer-payments-secondhand').patch(
                            res.data._id,
                            {main_branch_status : 2}
                        ).then((data) => {
                        }).catch((error) => {
                            console.log('ERROR DATA SYNC ===>', error)
                        })
                    })
                    toastr.success("Payment successfully added");


                    // document.getElementById("payment-input").value = "";

                }else{
                    toastr.error("Failed to add payment");
                }
            })
            .catch((error) => {
                console.log('error ', error)
                that.closeModal();
                toastr.error("Failed to add payment");
            })

    }

    render() {
        return (
            <div className="modal fade bd-example-modal-sm" id="add_debit" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" ref="add_ledger" data-backdrop="static">
                <div className="modal-dialog modal-sm" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Add Debit</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="form-group">
                                <label>Date</label>
                                <br />
                                <DatePicker
                                    selected={this.state.date_paid}
                                    onChange={this.handleDatePaid}
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    className="form-control"
                                />
                            </div>

                            <div className="form-group">
                                <label>DR</label>
                                <input id = "payment_input" className="form-control" ref="debit" placeholder = "0.00" />
                            </div>

                            <div className="form-group">
                                <label>Descripton/ Remarks</label>
                                <input id = "payment_input" className="form-control" ref="remarks" />
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal" ref="cancel">Cancel</button>
                            <button type="button" className="btn btn-primary" onClick={this.addPayments}>Save</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


const mapStateToProps = state => ({
    category: state.category,
    app: state.feathersClient.app,
    mainapp: state.feathersClient.mainapp,
    customer: state.customers.customerLedgerInfo,
    customerInfo: state.customers.customerInfo,
    session_id: state.login.userData._id,
    ledgerItemId: state.customers.ledgerItemId,
    remainingBalance: state.customers.remainingBalance,
    total_payments: state.customers.total_payments,
});

function mapDispatchToProps(dispatch) {
    return { actions: bindActionCreators(ActionCreators, dispatch) }
}

export default connect(mapStateToProps, mapDispatchToProps)(update_ledger_modal);
