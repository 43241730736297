import React, {Component} from 'react';

//redux
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
// actions
import {ActionCreators} from '../../../actions';

//main NavBar
import MainNav from '../../../components/global_nav';
import MainSideBar from '../../../components/global_sidebar';

//custom sidebar
import SubSideBar from './sub_sidebar';

//select tag
import Select from 'react-select';

import AddPriceModal from './add_price_modal'

import toastr from 'toastr';

import app from '../../../helpers/feathers';

//daterange
import moment from 'moment';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import '../report/plugins/daterangepicker.css';

//custom styles
import '../../../stylesheet/styles.css';

//datatables
import '../../../stylesheet/datatables/datatables.css';

import ConfirmationDelete from './confirmation_delete';

let that;
const $ = require('jquery');
$.DataTable = require('datatables.net');

class inventory extends Component {
    pageIsMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            product: this.props.product.product_list,
            products: [],
            product_count: 0,
            selectedOption: '',
            models: this.props.models,
            filter_model: null,
            filter_color: null,
            filter_branch: null,
            filter_engine_number: null,
            selectChanged: false,
            selectedBranch: {value: '', label: 'All'},
            selectedBrand: '',
            selectedBrandChanged: false,
            selectBranchChanged: false,
            modelsObj: [],
            startDate: moment().subtract(29, 'days'),
            endDate: moment(),
            drPickerApplied: false,
            selectedDaterange: false,
            onlyModelIsSelected: false,
            toggle: false
        };
    }

    componentWillMount() {
        that = this;
        this.fetchInventory();
        // this.createListener();
        if (this.props.userData.user_position_info.position_type !== 'ADMINISTRATOR'
            || this.props.userData.branch_info.branch_name !== 'MAIN') {
            this.state.selectedBranch = {
                value: that.props.userData.branch_info._id,
                label: that.props.userData.branch_info.branch_name
            };
        }
        const objct = this.props.selectmodellist;
        // manually insert 'all' option in models
        const ckrModel = [];
        objct.map(item => {
            ckrModel.push(item.value)
        });
        const modelExists = ckrModel.includes("all");
        if (!modelExists) {
            objct.unshift({
                value: 'all',
                label: 'All'
            })
        }
        this.setState({modifiedModels: objct})
    }

    componentDidMount() {
        const _this = this;
        _this.pageIsMounted = true;
        $('.tbl-inventory').removeClass('not-visible');
        // const ckr = [];
        // this.props.branch.map(item => {
        //     ckr.push(item.value)
        // });
        const that = this,
            productTable = $('.tbl-inventory').DataTable({
                data: this.state.products,
                columns: [
                    {"title": "model object"},
                    {"title": "MODEL"},
                    // { "title": "BRAND" },
                    {"title": "CHASSIS NO."},
                    {"title": "ENGINE NO."},
                    {"title": "BRANCH"},
                    {"title": "DATE RECEIVED"},
                    // { "title": "COLOR" },
                    {"title": "ACTION"}
                ],
                columnDefs: [
                    {
                        targets: [6],
                        orderable: false,
                        width: '10%',
                    },
                    {
                        targets: [0],
                        visible: false
                    }
                ],
                'dom': "<'row'<'col-sm-6'l><'col-sm-6'p>>" +
                    "<'row'<'col-sm-12'tr>>" +
                    "<'row'<'col-sm-12'i>>",
            });

        $('.search').keyup(function () {
            productTable.search($(this).val()).draw();
        });

        $('.tbl-inventory').on('click', '.view-inventory', function () {
            const data = productTable.row($(this).parents('tr')).data();

            that.props.actions.selectModel(data[0]);
            that.props.actions.getSelectedModel(data[0]).then((products) => {
                that.props.actions.setselectedFilters(data[1], null, null)
                that.props.actions.selectedModelSet(products)
                that.props.history.push('/inventory/filter')
            })
        })

        $('.tbl-inventory').on('click', '.delete', function () {
            const data = productTable.row($(this).parents('tr')).data();
            const id = data[0]._id;
            const permission = that.props.userPermission;
            
            if (permission.inventory === 2) {
                that.props.actions.deleteId(id);
                that.props.actions.deleteService('products');
                that.refs.confirm_delete.click();
            }
        })

        $('.tbl-inventory').on('click', '.edit', function () {
            const data = productTable.row($(this).parents('tr')).data();
            const permission = that.props.userPermission;

            if (permission.inventory === 2) {
                that.props.actions.updateUnitInfo(data[0]);
                that.props.history.push('/update_inventory')
            }
        })

        $('.tbl-inventory').on('click', '.view', function () {
            const data = productTable.row($(this).parents('tr')).data();
            that.props.actions.productInfo(data[0]);
            that.props.history.push('/view_inventory')
        });

        // that.createListener();
    }

    fetchInventory = () => {
        if(that.pageIsMounted){
            // get brand list
            this.props.actions.getAllBrands().then((data) => {
                if(data){
                    this.props.actions.setBrands(data);
                }
            });
            // get branch list
            this.props.actions.getAllBranches().then((data) => {
                if(data){
                    this.props.actions.setBranches(data);
                }
            });
        }
    }

    productList = data => {
        this.setState({
            products: data,
            product_count: data.length
        });
        const table = $('.tbl-inventory').DataTable();
        table.clear();
        table.rows.add(data);
        table.draw();

    };

    createListener = () => {
        const products = app.service('products');

        products.on('created', products => {
            // this.fetchInventory();
        });

        products.on('removed', products => {
            // this.advancedFilter();
        });
    }

    enumDate = (startDate, endDate) => {
        const dates = [];

        const currDate = moment(startDate).startOf('day');
        const lastDate = moment(endDate).startOf('day');

        while (currDate.add(1, 'days').diff(lastDate) < 0) {
            // console.log(currDate.toDate());
            const mdate = moment(currDate.clone().toDate()).format('MM/DD/YYYY')
            dates.push(mdate);
            // dates.push(currDate.clone().toDate());
        }
        dates.unshift(moment(startDate).format('MM/DD/YYYY'));
        dates.push(moment(endDate).format('MM/DD/YYYY'));

        this.setState({
            selectedDaterange: dates
        })
    }

    handleApply(event, picker) {
        that.setState({
            startDate: picker.startDate,
            endDate: picker.endDate,
            drPickerApplied: true
        });

        that.enumDate(picker.startDate, picker.endDate);
    }

    handleChange = (selectedOption) => {
        const value = selectedOption;

        if (value != null) {
            this.setState({
                selectedOption: value,
                filter_model: value,
                selectChanged: true
            });
        } else {
            this.setState({
                selectedOption: '',
                filter_model: '',
                selectChanged: false
            })
        }
    }

    handleChangeBranch = (selectedOption) => {
        const value = selectedOption === null ? '' : selectedOption;
        this.setState({
            selectedBranch: value
        });
        setTimeout(() => {
            this.advancedFilter();
        }, 1000);
    }

    advancedFilter = () => {
        const that = this,
            color = this.state.filter_color ? this.state.filter_color : null,
            branch = this.state.selectedBranch.value !== 'all' ? this.state.selectedBranch.value : null,
            daterange = this.state.drPickerApplied ? this.state.selectedDaterange : null,
            {brand, model, engine} = this.refs,
            query = {};
        that.props.actions.pricesUpdated(false);

        const permission = this.props.userPermission;
        if (permission.inventory === 0) {
            return;
        }

        if (model && brand && engine) {
            if (!daterange && model.value === '' && !branch && brand.value === '' && engine.value === '') {
                that.productList([]);
                return;
            }
            if (daterange) {
                query.date_created = {
                    $in: daterange
                }
            }
            if (brand.value !== '') {
                query.unit_brand_name = {$regex: brand.value}
            }
            if (model.value !== '') {
                query.unit_model_name = {
                    $regex: model.value.includes('GIXXER') ? 'GIXXER' : model.value.replace(/\s*\(.*?\)\s*/g, '')
                }
                // const escRegExp = escapeRegEx(model.value)
                // query.unit_model_name = {
                //     $regex: new RegExp(escRegExp, 'i')
                // }
            }
            if (engine.value !== '') {
                query.engine_number = {
                    $regex: engine.value
                }
                /*query.$or = [
                    {
                        engine_number: {
                            $regex: engine.value
                        }
                    },
                    {
                        unit_engine_number: {
                            $regex: engine.value
                        }
                    },
                ]*/
            }
            if (branch) {
                query.branch = branch
            }

            query.type = 0;
            query.transferred = {
                $ne: 1
            };
        }

        if(that.pageIsMounted){
            that.props.actions.inventoryAdvancedFilter(query).then((data) => {
                that.productList(data);
                if (data.length) {
                    that.props.actions.forPricing(data);
                } else {
                    that.setState({
                        onlyModelIsSelected: false
                    })
                }
            })
        }
    }

    permissionInventory = () => {
        const permission = this.props.userPermission;

        if (permission.inventory === 0) {
            return "not-visible"
        } else {
            return "";
        }
    }
    addUnit = () => {
        const permission = this.props.userPermission;

        if (permission.inventory === 2) {
            this.props.history.push('/inventory_add');
        }
    }

    render() {
        return (
            <div className="" id="main">
                {/* main NavBar component*/}
                <MainNav historyProp={this.props.history} usersProp={this.props.userData}/>
                <ConfirmationDelete/>
                <AddPriceModal ids={this.state.selectedUnitsByDr}/>
                <div className="container-fluid">
                    <div className="row full-width">
                        <div className="sidebar">
                            <div className="sidebar-content">
                                <MainSideBar page="inventory"/>
                            </div>
                        </div>
                        {
                            /*<div className="row background-white"><h1>You have no access to this page</h1></div> */}
                        <SubSideBar history={this.props.history} allProps={this.props}
                                    userPermission={this.props.userPermission} pageName="main"/>
                        <div className="sub-inv-main-content">
                            <div className="application-container">
                                {
                                    this.props.userPermission.inventory === 0 ?
                                        <div className="row no-access-div">
                                            <div className="col-md-12">
                                                <h1>Access denied</h1>
                                            </div>
                                        </div>
                                        : null
                                }
                                <div className={this.permissionInventory()}>
                                    {
                                        // <div className="row background-white">
                                        //     <div className="d-flex flex-row justify-content-left content-header">
                                        //          <input type="search" className="form-control form-control-lg align-items-center search" placeholder="&#xF002; Search" />
                                        //     </div>
                                        // </div>
                                    }
                                    <div className="row my-inventory">
                                        <div className="col-md-12">
                                            <div className="col-md-12">
                                                <div className="row col-md-12">
                                                    <div className="">
                                                        <h4 className="subheader-title">Units in Stock</h4>
                                                    </div>
                                                    <div className="">
                                                        {this.state.onlyModelIsSelected ?
                                                            <button className="btn btn-block btn-second-primary"
                                                                    data-toggle="modal" data-target="#add_price">Add
                                                                Price</button>
                                                            : null
                                                        }
                                                        <button className="btn btn-second-primary add-new-btn"
                                                                onClick={() => this.addUnit()}>
                                                            <span><i className="fa fa-plus"/> Add</span>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-inline">
                                                <div className="col-md-12 subheader-label">
                                                    ADVANCED FILTER
                                                </div>
                                                <div className="col-md-12">
                                                    <br/>
                                                </div>
                                                <div className="container-fluid">
                                                    <div className="row">
                                                        <div className="col">
                                                            <DateRangePicker
                                                                onApply={this.handleApply}
                                                                startDate={this.state.startDate}
                                                                endDate={this.state.endDate}
                                                            >
                                                                <button className="form-control">
                                                                    {
                                                                        this.state.drPickerApplied ? (moment(this.state.startDate).format('ll') + ' - ' + moment(this.state.endDate).format('ll')) : 'Select date'
                                                                    }
                                                                </button>
                                                            </DateRangePicker>
                                                        </div>
                                                        <div className="col">
                                                            <input className="form-control" placeholder="Enter Brand"
                                                                   ref="brand" onKeyUp={this.advancedFilter}/>
                                                        </div>
                                                        <div className="col">
                                                            <input className="form-control" placeholder="Enter Model"
                                                                   ref="model" onKeyUp={this.advancedFilter}/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <br/>
                                                </div>
                                                <div className="container-fluid">
                                                    <div className="row">
                                                        <div className="col">
                                                            <input className="form-control"
                                                                   placeholder="Enter Engine Number" ref="engine"
                                                                   onKeyUp={this.advancedFilter}/>
                                                        </div>
                                                        <div className="col">
                                                            <Select
                                                                name="select-branch"
                                                                value={this.state.selectedBranch.value}
                                                                onChange={this.handleChangeBranch}
                                                                options={this.props.branch}
                                                                disabled={this.props.userData.user_position_info.position_type !== 'ADMINISTRATOR' || this.props.userData.branch_info.branch_name !== 'MAIN'}
                                                                clearableValue={true}
                                                                placeholder="Branch"
                                                            />
                                                        </div>
                                                        <div className="col">
                                                            <button className="btn btn-second-primary width-100"
                                                                    onClick={() => this.advancedFilter()}>
                                                                Apply Filter
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="row margin-20">
                                        {/*<div className="col-md-12">
                                            <span>Result : {this.state.product_count}</span>
                                        </div>*/}
                                        <div className="full-width">
                                            <a data-toggle="modal" data-target="#confirm_delete"
                                               ref="confirm_delete"></a>
                                            <table className="table tbl-mobile tbl-inventory"></table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    app: state.feathersClient.app,
    product: state.product,
    models: state.category.modelsSelect,
    branch: state.category.branchesSelect,
    brandsSelect: state.category.brandsSelect,
    selectmodellist: state.category.modelsSelect,
    userData: state.login.userData,
    userPermission: state.login.userPermission,
    unitsForPricing: state.product.unitsForPricing,
});

function mapDispatchToProps(dispatch) {
    return {actions: bindActionCreators(ActionCreators, dispatch)}
}

export default connect(mapStateToProps, mapDispatchToProps)(inventory);
