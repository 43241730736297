

import React, { Component } from 'react';
import app from '../../../helpers/feathers'

// moment
import moment from 'moment';
import {IntlProvider, FormattedMessage, FormattedNumber} from 'react-intl';
import Cleave from 'cleave.js/react';

import ReactDataSheet from 'react-datasheet';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

//toastr
import toastr from 'toastr';
import Toggle from 'react-bootstrap-toggle';

//main NavBar
import MainNav from '../../../components/global_nav';
import MainSideBar from '../../../components/acc_sidebar';
//sub side bar
import SubSideBar from './sub_sidebar';

import OverdueModal from './overdue_modal'

import defaultImage from '../../../assets/img/default_avatar.jpg';

import { twoDecimalPlaces, numberWithCommas } from '../../../helpers';

import DateRangePicker from "react-bootstrap-daterangepicker";
import "./plugins/daterangepicker.css";

//custom styles
import '../../../stylesheet/styles.css';

//redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// actions
import { ActionCreators } from '../../../actions';
const $ = require('jquery');
$.DataTable = require('datatables.net');

var that;
const SH_CUSTOMERS = "customer-secondhand-unit";
const SH_LEDGER = "ledger-secondhand";
const SH_PAYMENTS = "customer-payments-secondhand"

class overdue_customer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: [],
            table_data: [],
            start_date: '',
            unit: '',
            editing: false,
            startDate: moment(),
            endDate: moment(),
            first_payment_date: moment(),
            selectedDaterange: false,
            drPickerApplied: false,
            formatted_sched: [],
            formatted_payments: [],
        };
    }

    componentWillMount(){

        that = this;

        // let { customer, total_payments } = this.props,
        //     promisory_note = customer.promisory_note,
        //     down_payment = customer.payment,
        //     initialBal = ((promisory_note - down_payment) - total_payments);
    }

    componentDidMount() {
        var mainDtTable = $('.tbl-overdue').DataTable({
            // data: this.props.ledger,
            "columnDefs": [
                {
                    "visible": false,
                    "targets": 0
                },
                {
                    "targets": [1,3,4],
                    "width": '20%'
                },
                {
                    "targets": 2,
                    "width": '30%'
                },
            ],
            columns: [
                { title: "" },
                { title: "ACCOUNT NO" },
                { title: "NAME" },
                { title: "MONTHLY AMORTIZATION" },
                { title: "AMOUNT OVERDUE" }
            ],
            "paging": true,
            "pageLength": 15,
            "searching": false,
            "ordering": false,
            "info": false,
            "sDom": '<"bottom"<t>ip><"clear">'
        });

        $('.tbl-overdue').on('click', 'button.view', function () {
            var data = mainDtTable.row( $(this).parents('tr') ).data();
            // that.props.actions.setCustomerInfo(data[0]);
            // that.props.history.push('/view_overdue_details')
        });

        let services = [
            {name: 'customers'},
            {name: 'products'},
            {name: 'ledger'},
            {name: 'customer-payments'},
            {name: 'customer-area'},
            {name: 'reports'},
            {name: 'secondhand'},
            {name: 'customer-secondhand-unit'},
            {name: 'customer-payments-secondhand'},
            {name: 'ledger-secondhand'},
        ];

        let ind = 9,
            srv = services[ind].name,
            fthrs = that.props.app;

        // fthrs.service(srv).find()
        // .then((service) => {
        //   console.log('service === ', service.data)

        //   let d = service.data;

        //   d.map((v) => {
        //     fthrs.service(srv).remove(v._id)
        //     .then((rm) => {
        //       console.log('rm ', rm)
        //     })
        //   })
        // })
        // .catch((service_err) => {
        //   console.log('service_err ', service_err)
        // })
    }


    updateDataTable = (data) => {
        this.setState({
            data: data
        });

        const table = $('.tbl-overdue').DataTable();
        table.clear();
        table.rows.add(data);
        table.draw();
    }


    handleApply(event, picker) {
        that.setState({
            startDate: picker.startDate,
            endDate: picker.endDate,
            drPickerApplied: true
        });

        // moment(this.state.startDate).format('MM/DD/YYYY')
        that.enumDate(picker.startDate, picker.endDate);
    }


    enumDate = (startDate, endDate) => {
        var dates = [];


        var newStartDate = moment(startDate).subtract(1, "months");
        var newEndDate = moment(endDate).subtract(1, "months");

        var currDate = moment(startDate).subtract(31, "days").startOf("day");
        var lastDate = moment(endDate).subtract(31, "days").startOf("day");

        // var currDate = moment(startDate).startOf("day");
        // var lastDate = moment(endDate).startOf("day");

        while (currDate.add(1, "days").diff(lastDate) < 0) {

            // console.log(currDate.toDate());
            var mdate = moment(currDate.clone().toDate()).format("MM/DD/YYYY");

            var today = new Date();
            var todayFormat = moment(today);

            var m = moment(currDate.clone().toDate());

            if(m <= todayFormat){
                dates.push(mdate);
            }

            // dates.push(currDate.clone().toDate());
        }

        var dateEnd = new Date();
        var dateLast = moment(dateEnd),
            dateNewEnd = moment(newEndDate);

        if(dateNewEnd <= dateLast){
            dates.push(moment(newEndDate).format("MM/DD/YYYY"));
        }


        var dateStart = new Date(),
            dateNewStart = moment(dateStart),
            dateFirst = moment(newStartDate);

        if(dateFirst <= dateNewStart){
            dates.unshift(moment(newStartDate).format("MM/DD/YYYY"));
        }

        that.setState({
            selectedDaterange: dates
        });

    };



    applyReportFilter = () => {

        // that.applyReportFilter1(); return;

        var query = daterange = that.state.selectedDaterange,
            daterange = that.state.selectedDaterange,
            report_type = that.state.report_type;

         if(!daterange){
             toastr.info("Please select date");
             return;
         }

        that.props.actions.getOverdueCustomer(daterange)
            .then((ledger) => {

                let dt_data = [];
                if(ledger){
                    dt_data = ledger.data;

                    var customerPayments = app.service('customer-payments');
                    return customerPayments.find({
                        query: {
                            date_paid: {
                                $in: daterange
                            },
                            current: 1
                        }
                    })
                        .then((dataItems)=>{
                            if(dataItems.data.length > 0){
                                var paymentItems = dataItems.data,
                                dateRangeData = daterange,
                                hasDueDate = [],
                                withPayments = [],
                                withOutPayments = [];
                                paymentItems.map((val, index) => {
                                    withPayments.push(val.account_number)
                                })

                                ledger.arr.map((acc, index) => {
                                    if(!withPayments.includes(acc)){
                                        withOutPayments.push(acc)
                                    }
                                });
                                app.service('customers').find({
                                    query: {
                                        account_number: {
                                            $in: withOutPayments
                                        }
                                    }
                                })
                                .then((noPayment) => {

                                    if(noPayment.total){
                                        var input = [],
                                            output = [],
                                            dataValue = noPayment.data;

                                        dataValue.map((value, index) => {
                                            let {
                                                    account_number,
                                                    model,
                                                    promisory_note,
                                                    term,
                                                    customer_ledger,
                                                    customer_payments,
                                                } = value,
                                                customer_name = value.name,
                                                ma = (promisory_note / term),
                                                amount_overdue = '',
                                                total_payments = 0,
                                                total_months_paid = 0,
                                                start_date = customer_ledger[0].due_date,
                                                formatted_start_date = moment(start_date),
                                                sd_month_date = moment(formatted_start_date).format("MM"),
                                                sd_year_date = moment(formatted_start_date).format("YYYY"),
                                                sd_month_count = (Number(sd_month_date) + (Number(sd_year_date) * 12)),
                                                todays_date = moment(),
                                                formatted_todays_date = moment(todays_date),
                                                todays_month_date = moment(todays_date).format("MM"),
                                                todays_year_date = moment(todays_date).format("YYYY"),
                                                todays_month_count = (Number(todays_month_date) + (Number(todays_year_date) * 12)),
                                                sd_month_count_till_today = (todays_month_count - sd_month_count),
                                                months_diff = formatted_todays_date.diff(moment(value.date_purchased), 'months',true),
                                                months_diff_whole_num = parseInt(months_diff),
                                                no_of_months_not_paid = 0,
                                                overdue_result = 0,
                                                overdue_amount = 0,
                                                remaining_balance = 0,
                                                actionBtn = '<button class="btn btn-sm btn-warning view" title="View"><span class="fa fa-eye"></span> View </button>';

                                            customer_payments.length > 0 && customer_payments.map((cp_v, cp_i) => {
                                                total_payments += (Number(cp_v.amount_paid))
                                            })

                                            remaining_balance = ( promisory_note - total_payments );

                                            total_months_paid = parseInt(total_payments / Number(ma));
                                            // no_of_months_not_paid = (parseInt(months_diff) - total_months_paid);
                                            no_of_months_not_paid = (months_diff_whole_num - total_months_paid);
                                            if(no_of_months_not_paid > 0){
                                                // overdue_result = (promisory_note - (parseInt(months_diff) * Number(ma)));
                                                overdue_result = (promisory_note - (months_diff_whole_num * Number(ma)));
                                                overdue_amount = ((remaining_balance - overdue_result) * .01);

                                                let formatted_overdue = overdue_amount.toFixed(2);
                                                if(remaining_balance > overdue_result){
                                                    input.push([value, account_number, customer_name, numberWithCommas(parseInt(ma)), numberWithCommas(formatted_overdue)]);
                                                }
                                            }
                                        })

                                        output['table_data'] = input;
                                        that.payments_and_schedules(output);
                                    }else{
                                        // toastr.error("Fetching data failed. Please try again")
                                        var output = [];
                                        output['table_data'] = [];
                                        that.payments_and_schedules(output);
                                    }
                                })
                                .catch((cust_e) => {
                                    console.log('cust_e')
                                    console.log(cust_e)
                                    toastr.error("Error fetching data. Please try again.")
                                    
                                    var output = [];
                                    output['table_data'] = [];
                                    that.payments_and_schedules(output);
                                })
                            }else{
                                var output = [];
                                output['table_data'] = [];
                                that.payments_and_schedules(output);
                            }
                        })
                        .catch((payment_e) => {
                            console.log('payment_e')
                            console.log(payment_e)
                            toastr.error("Error fetching data. Please try again.")
                            var output = [];
                            output['table_data'] = [];
                            that.payments_and_schedules(output);
                        })
                }else{
                    // toastr.error("Fetching data failed. Please try again")
                    var output = [];
                    output['table_data'] = [];
                    that.payments_and_schedules(output);
                }
            })
    }

    payments_and_schedules = (data) => {
        let {
            table_data,
        } = data;

        var query = daterange = that.state.selectedDaterange,
            daterange = that.state.selectedDaterange;

        that.props.actions.getOverdueSHCustomer(daterange)
            .then((ledger) => {
                let dt_data = [];
                if(ledger){
                    dt_data = ledger.data;

                    var customerPayments = app.service(SH_PAYMENTS);
                    return customerPayments.find({
                        query: {
                            date_paid: {
                                $in: daterange
                            },
                            current: 1
                        }
                    })
                        .then((dataItems)=>{
                            if(dataItems.data.length){
                                var paymentItems = dataItems.data,
                                    dateRangeData = daterange,
                                    hasDueDate = [],
                                    withPayments = [],
                                    withOutPayments = [];
                                paymentItems.map((val, index) => {

                                    withPayments.push(val.account_number)
                                })

                                ledger.arr.map((acc, index) => {
                                    if(!withPayments.includes(acc)){
                                        withOutPayments.push(acc)
                                    }
                                });
                                app.service(`${SH_CUSTOMERS}`).find({
                                    query: {
                                        account_number: {
                                            $in: withOutPayments
                                        }
                                    }
                                })
                                .then((noPayment) => {

                                    if(noPayment.total){
                                        var input = [],
                                            output = [],
                                            dataValue = noPayment.data;

                                        dataValue.map((value, index) => {
                                            let {
                                                    account_number,
                                                    model,
                                                    promisory_note,
                                                    term,
                                                    customer_ledger,
                                                    customer_payments,
                                                } = value,
                                                customer_name = value.name,
                                                ma = (promisory_note / term),
                                                amount_overdue = '',
                                                total_payments = 0,
                                                total_months_paid = 0,
                                                start_date = customer_ledger[0].due_date,
                                                formatted_start_date = moment(start_date),
                                                sd_month_date = moment(formatted_start_date).format("MM"),
                                                sd_year_date = moment(formatted_start_date).format("YYYY"),
                                                sd_month_count = (Number(sd_month_date) + (Number(sd_year_date) * 12)),
                                                todays_date = moment(),
                                                formatted_todays_date = moment(todays_date),
                                                todays_month_date = moment(todays_date).format("MM"),
                                                todays_year_date = moment(todays_date).format("YYYY"),
                                                todays_month_count = (Number(todays_month_date) + (Number(todays_year_date) * 12)),
                                                sd_month_count_till_today = (todays_month_count - sd_month_count),
                                                months_diff = formatted_todays_date.diff(moment(value.date_purchased), 'months',true),
                                                months_diff_whole_num = parseInt(months_diff),
                                                no_of_months_not_paid = 0,
                                                overdue_result = 0,
                                                overdue_amount = 0,
                                                remaining_balance = 0,
                                                actionBtn = '<button class="btn btn-sm btn-warning view" title="View"><span class="fa fa-eye"></span> View </button>';

                                            customer_payments.length > 0 && customer_payments.map((cp_v, cp_i) => {
                                                total_payments += (Number(cp_v.amount_paid))
                                            })

                                            remaining_balance = ( promisory_note - total_payments );

                                            total_months_paid = parseInt(total_payments / Number(ma));
                                            no_of_months_not_paid = (months_diff_whole_num - total_months_paid);

                                            if(no_of_months_not_paid > 0){
                                                overdue_result = (promisory_note - (months_diff_whole_num * Number(ma)));
                                                overdue_amount = ((remaining_balance - overdue_result) * .01);

                                                let formatted_overdue = overdue_amount.toFixed(2);

                                                if(remaining_balance > overdue_result){
                                                    input.push([value, account_number, customer_name, numberWithCommas(parseInt(ma)), numberWithCommas(formatted_overdue)]);
                                                }
                                            }
                                        })

                                        output['table_data'] = input;
                                        that.merge_data(input, table_data);
                                    }else{
                                        if(table_data.length > 0){
                                            that.merge_data(input, table_data);
                                        }else{
                                            that.merge_data([], []);
                                            toastr.success("No customer with overdue found")
                                        }
                                    }
                                })
                                .catch((sh_cust_e) => {
                                    console.log('sh_cust_e')
                                    console.log(sh_cust_e)
                                    // that.merge_data([], []);
                                    that.merge_data([], table_data);
                                    // toastr.error("Error fetching data. Please try again.")
                                })
                            }else{
                                that.merge_data([], table_data);
                            }
                        })
                        .catch((sh_payments_e) => {
                            console.log('sh_payments_e')
                            console.log(sh_payments_e)
                            that.merge_data([], []);
                            toastr.error("Error fetching data. Please try again.")
                        })
                }else{
                    if(table_data.length > 0){
                        that.merge_data([], table_data);
                    }else{
                        that.merge_data([], []);
                        toastr.success("No customer with overdue found")
                    }
                }
            })

    }

    merge_data = (second_hand, brand_new) => {
        let new_data = brand_new.concat(second_hand);

        that.updateDataTable(new_data);
    }

    render() {

        return (
            <div className="">
                <MainNav historyProp={this.props.history} userPermission={this.props.userPermission} usersProp={this.props.userData} systemType="accounting" />
                <div className="container-fluid">
                    <div className="row full-width">
                        <div className="sidebar">
                            <div className="sidebar-content">
                                <MainSideBar page="overdue_customer"/>
                            </div>
                        </div>
                        <div>
                            <div className="main-content">
                                <div className="row">
                                    <div className="col-md-12">
                                        <h3>Customers with Overdue</h3>
                                        <div className="col">
                                            <div className="col-lg-6">
                                                <div className="row">
                                                    <div className="col-sm-7">
                                                        <label>Date Range</label>
                                                        <br />
                                                        <DateRangePicker
                                                            onApply={this.handleApply}
                                                            startDate={this.state.startDate}
                                                            endDate={this.state.endDate}

                                                        >
                                                            <button className="form-control">
                                                                {
                                                                    this.state.drPickerApplied ? (moment(this.state.startDate).format('ll')+' - '+moment(this.state.endDate).format('ll')) : 'No daterange selected'
                                                                }
                                                            </button>
                                                        </DateRangePicker>
                                                    </div>

                                                    <div className="col">
                                                        <label>&nbsp;</label>
                                                        <br />
                                                        <button
                                                            className="btn btn-block btn-second-primary pull-right"
                                                            onClick={() => this.applyReportFilter()}
                                                        >
                                                            Filter
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                            </div>
                                            <div className="col-lg-12">
                                                <br/>
                                            </div>

                                        </div>
                                        <div className="background-white inline-block width-100p padding-tb-20">
                                            <div className="row padding-bottom-20">
                                                <div className="col-md-12">
                                                    <div className="container-fluid">
                                                        <table className="table table-sm tbl-overdue tbl-mobile"/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <br/>
                                <br/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
const mapStateToProps = state => ({
    // app: state.feathersClient.app,
    customer: state.customers.customerInfo,
    payment: state.customers.customerPayments,
    model: state.category.modelsList,
    brand: state.category.brandsList,
    userData: state.login.userData,
    userPermission: state.login.userPermission,
    ledger: state.customers.ledgerTableData,
    promisory_note: state.customers.promisory_note,
    paymentTableData: state.customers.paymentTableData,
    total_payments: state.customers.total_payments,
    selected_payments: state.customers.selected_payments,
    selected_schedule: state.customers.selected_schedule,
});

function mapDispatchToProps(dispatch) {
    return { actions: bindActionCreators(ActionCreators, dispatch) }
}

export default connect(mapStateToProps, mapDispatchToProps)(overdue_customer);
