import React, {Component} from 'react';
import app from '../../../helpers/feathers';
// react-router
import {Link} from 'react-router-dom';

//redux
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
// actions
import {ActionCreators} from '../../../actions';

import defaultImage from '../../../assets/img/default_avatar.jpg';

//toastr
import toastr from 'toastr';

import moment from 'moment';

//select tag
import Select from 'react-select';


import ConfirmationModalSH from './confirm_modal_sh'


//main NavBar
import MainNav from '../../../components/global_nav';
import MainSideBar from '../../../components/acc_sidebar';

//sub side bar
import SubSideBar from '../accounting/sub_sidebar';
import DatePicker from 'react-datepicker';
import {
    encodeToString
} from '../../../helpers'

import Autosuggest from 'react-autosuggest';

//custom styles
import '../../../stylesheet/styles.css';

//datatables
import '../../../stylesheet/datatables/datatables.css';

const $ = require('jquery');
$.DataTable = require('datatables.net');

var that;
var _0x896d = ["\x74\x65\x73\x74\x32"];
var secret_user = _0x896d[0], secret_pass = _0x896d[0]

class add_new_customer_secondhand extends Component {

    constructor(props) {
        super(props);

        this.state = {
            selectedModel: '',
            clickSubmit: false,
            selectedTerms: '',
            selectedTitle: '',
            selectedTitleChanged: false,
            selectedArea: '',
            selectedAreaChanged: false,
            selectedTermsChanged: false,
            submitting: false,
            selectedBranch: '',
            selectBranch: [
                {value: this.props.userData.branch_info._id, label: this.props.userData.branch_info._branch_name}
            ],
            mobile_num: '',
            age: '',
            selectedBrand: '',
            selectedBrandChanged: false,
            selectedOption: '',
            selectChanged: false,
            chassisOption: '',
            chassisChanged: false,
            modelsObj: [],
            chassisObj: [],
            termsIsDisabled: false,

            value: '',
            suggestions: [],
            secondhand_chassis: [],
            showDiv: false,
            confirmed: false,
            date_purchased: moment(),
            first_payment_date: moment(),
        };

    }

    componentWillMount() {
        that = this;
        if (that.props.type === null) {
            this.props.actions.getArea();
            // that.props.history.push('/customer')
        }
    }

    componentDidMount() {
        $('.hide-view-customer').css('display', 'none', '!important')

        var localBranch = {
            value: localStorage.getItem('local_branch_id'),
            label: localStorage.getItem('local_branch_name')
        }

        this.setState({selectedBranch: localBranch})

        $('#confirmation_modal_sh').on('shown.bs.modal', function () {
            $(".modal #submitForm").focus();
        });

    }

    handleDatePurchased = (date) => {
        this.setState({date_purchased: date})
    }
    handleFirstPayment = (date) => {
        this.setState({first_payment_date: date})
    }

    handleChangeModel = (selectedOption) => {
        var value = selectedOption;

        if (value) {
            this.setState({
                selectedModel: value
            });
        } else {
            this.setState({
                selectedModel: ''
            });
        }

        // if (value){
        //     this.props.actions.getModelInAddCust(value)
        //     .then((data) => {
        //         console.log('setSelectedBranchInAddCust ',data[0].branches)
        //         if (data){
        //             that.props.actions.setSelectedBranchInAddCust(data[0].branches);
        //         }else{
        //             that.props.actions.setSelectedBranchInAddCust('');
        //         }
        //     });
        // }

        $('#confirmation_modal_sh').on('hidden.bs.modal', function () {
            that.setState({
                confirmed: false, submitting: false
            })
        })

    }


    confirmation = () => {

        $('#confirmation_modal_sh').modal('show')

    }

    submitForm = () => {
        that.setState({confirmed: true})

        setTimeout(() => {
            that.handleAddCustomer();
        }, 500)
    }

    handleChangeBranch = (selectedOption) => {
        var value = selectedOption === null ? '' : selectedOption;
        this.setState({
            selectedBranch: value
        });
    }

    branchOption = () => {
        var array = this.props.branchSelect,
            newArray = []
        array.map((obj) => {
            if (obj.value !== 'all') {
                newArray.push(obj)
            }
        })
        return newArray
    }

    handleselectedTerms = (selectedOption) => {
        var value = selectedOption;
        if (value != null) {
            if (value.value === 0) { // cash
                that.refs.terms.value = "";
                that.refs.account_number.value = "";
                that.refs.promisory_note.value = "";
                that.setState({
                    termsIsDisabled: true
                })
            } else { // installment
                that.setState({
                    termsIsDisabled: false
                })
            }
            this.setState({
                selectedTerms: value,
                selectedTermsChanged: true,
            });
        } else {
            this.setState({
                selectedTerms: '',
                selectedTermsChanged: false,
                termsIsDisabled: true,
            });
        }
    }

    // handleselectedTerms= (selectedOption) => {
    //     var value = selectedOption === null ? '' : selectedOption;
    //     this.setState({
    //         selectedTerms: value
    //     });
    // }


    handleselectedTitle = (selectedOption) => {
        var value = selectedOption;
        if (value != null) {
            if (value.value) {
                this.setState({selectedTitle: value})
            }
        } else {
            this.setState({selectedTitle: '', selectedTitleChanged: false})
        }
    }


    validateForm = (formData) => {
        var a = formData;
        if (formData.type === 1) {
            if (a.name !== '' &&
                a.unit !== '' &&
                // a.branch != '' &&
                a.mobile !== '' &&
                a.tin !== '' &&
                a.address.present !== '' &&
                a.address.length_of_stay !== '' &&
                a.age !== '' &&
                a.employer.present !== '' &&
                a.months !== '' &&
                a.terms.down_payment !== '' &&
                a.terms.monthly_payment !== ''
            ) {
                return true;
            }

            return false;
        } else {

            if (a.name !== '' &&
                a.unit !== '' &&
                a.branch !== '' &&
                a.mobile !== '' &&
                a.tin !== '' &&
                a.address.present !== '' &&
                a.age !== '' &&
                a.payment !== '') {
                return true;
            }

            return false;
        }
    }

    redirect = () => {
        setTimeout(() => {
            $("#confirmation_modal_sh").modal("hide")
            that.props.history.push('/view_customer_secondhand/');
        }, 2000)
    }

    showDiv = () => {
        that.setState({showDiv: true})
    }

    hideDiv = () => {
        that.setState({showDiv: false})
    }

    handleAddCustomer = () => {
        // e.preventDefault();

        let {selectedTitle} = this.state;

        if (this.state.submitting) {
            toastr.info("Please wait while saving data")
            return;
        }

        var form = this.refs.customerForm,
            termValue = (this.refs.terms.value).trim(),
            // that = this,
            formData = {
                name: (this.refs.name.value).toUpperCase().trim(),
                title: selectedTitle.value,
                unit: {
                    name: this.state.value
                },
                unit_id: this.state.chassisOption ? this.state.chassisOption.unitId : '',
                unitPrice: this.state.chassisOption ? this.state.chassisOption.unitPrice : '',
                unitName: this.state.value,
                // branchId: this.state.selectedBranch.value,
                // branchId: this.props.addCustBranch ? this.props.addCustBranch[0].value : '',
                // branch: localStorage.getItem('local_branch_id'),
                // branch_info: this.state.selectedBranch,
                branch: this.props.userData.branch_info._id,
                term: (this.refs.terms.value).trim(),
                current_owner: 1,
                payment: (this.refs.payment.value).trim(),
                payment_method: this.state.selectedTerms.value,
                origin_id: localStorage.getItem('local_branch_name') + (Date.now()),
                account_number: (this.refs.account_number.value).trim(),
                contact_number: (this.refs.contact_number.value).trim(),
                customer_address: (this.refs.customer_address.value).trim(),
                // address : (this.refs.address.value).trim(),
                promisory_note: (this.refs.promisory_note.value).trim(),
                date_purchased: moment(this.state.date_purchased).format("MM/DD/YYYY"),
                date_purchased_formatted: moment(this.state.date_purchased).format("MM/YYYY"),
                remarks: (this.refs.remarks.value).trim(),

                area: this.state.selectedAreaChange ? this.state.selectedArea.label : this.refs.area.value.trim(),
                first_payment_date: this.state.first_payment_date,
                or_number: this.refs.or_number.value,

            };
        var i_chassis_number = this.state.selectedModel.chassis_number,
            origin_id = this.state.chassisOption.origin_id;

        if (!this.state.chassisChanged) {
            toastr.remove();
            toastr.info('Please select a unit');
            return;
        }
        if (!this.state.selectedTermsChanged) {
            toastr.remove();
            toastr.info('Please select payment method');
            return;
        }

        if (this.state.selectedTerms.value === 1) {
            if ((this.refs.terms.value).trim() == "") {
                toastr.remove();
                toastr.info('Please enter terms');
                return;
            } else {
                if (isNaN(this.refs.terms.value)) {
                    toastr.remove();
                    toastr.info('Please enter a numeric value for terms');
                    return;
                }
            }
        }

        if (this.state.selectedTerms.value === 1) {
            if ((this.refs.account_number.value).trim() == "") {
                toastr.remove();
                toastr.info('Please enter account number');
                return;
            }
        }

        if (selectedTitle == "") {
            toastr.remove();
            toastr.info('Please select customer title');
            return;
        }

        if (!(this.refs.name.value).trim()) {
            toastr.remove();
            toastr.info('Please enter customer name');
            return;
        }

        if ((this.refs.customer_address.value).trim() == "") {
            toastr.remove();
            toastr.info('Please enter customer address');
            return;
        }

        if (!this.state.selectedAreaChange) {
            if (this.refs.area.value == "") {
                toastr.remove();
                toastr.info('Please select area');
                return;
            }
        }

        if (!(this.refs.payment.value).trim()) {
            toastr.remove();
            toastr.info('Please enter amount for payment');
            return;
        } else {

            if (isNaN((this.refs.payment.value).trim())) {
                toastr.remove();
                toastr.info('Please enter a valid amount for payment');
                return;
            }
        }


        if (this.state.selectedTerms.value === 1) {

            if (!(this.refs.promisory_note.value).trim()) {
                toastr.remove();
                toastr.info('Please enter promissory note');
                return;
            } else {

                if (isNaN((this.refs.promisory_note.value).trim())) {
                    toastr.remove();
                    toastr.info('Please enter a valid amount for promissory note');
                    return;
                }
            }
        }

        if((this.refs.or_number.value).trim() === ''){
            toastr.remove();
            toastr.info("Please enter OR Number");
            return;
        }

        // console.log(formData);
        // return;
        if (!this.state.confirmed) {
            that.confirmation();
            return
        }

        this.setState({submitting: true});

        toastr.info('Please wait data is saving')


        var mainapp = app,
            secondhandService = app.service('secondhand'),
            customerSecondhandService = app.service('customer-secondhand-unit');
        that.props.actions.checkIfExistCustomerSH(formData)
            .then((result) => {
                if (result.status) {
                    console.log('Account Number already Exists');
                    toastr.info(result.message)
                    this.setState({submitting: false});
                } else {
                    this.props.actions.addCustomerWithSecondhandUnit(formData).then((data) => {
                        if (data) {
                            toastr.remove();
                            toastr.success('Customer has been successfully added');

                            var custId = data.customer_id,
                                prodService = app.service('products'),
                                dateSold = moment().format('MM/DD/YYYY');

                            let i = 0;

                            var recursive = function (value) {

                                if (value > 0) {
                                    // let  start_date = moment(data.date_purchased).add(1, 'months').format('MM/DD/YYYY'),
                                    let start_date = moment(data.first_payment_date).format('MM/DD/YYYY'),
                                        due_date = moment(start_date).add(i, 'months').format('MM/DD/YYYY'),
                                        promisory_note = parseFloat(data.promisory_note),
                                        balance = promisory_note;

                                    i += 1;

                                    let raw_data = {
                                        customer_id: data._id,
                                        area: data.area,
                                        account_number: data.account_number,
                                        due_date: due_date,
                                        monthly_amortization: (balance / parseFloat(termValue)),
                                        balance: balance,
                                        promisory_note: promisory_note,
                                        added_by: that.props.session_id,
                                        branch: that.props.userData.branch_info._id,
                                    }

                                    that.props.actions.insertToLedgerSecondhand(raw_data)
                                        .then((result) => {
                                            return recursive(value - 1);
                                        })
                                        .catch((e) => {
                                            console.log('error ', e)
                                            return recursive(value - 1);
                                        })
                                } else {
                                    console.log('done!');
                                    that.props.actions.updateLedgerSecondhand(data._id, 1).then((result) => {
                                    });
                                    // toastr.success('Ledger successfully created!');
                                    // $('#add_ledger').modal('hide');

                                    that.redirect()
                                }
                            }

                            // that.props.actions.setNewCustomerData(data)
                            that.props.actions.setCustomerInfo(data);
                            $('#add_ledger .btn-primary').prop('disabled', true);
                            recursive(termValue)

                        } else {
                            toastr.remove();
                            toastr.info('An error occured. Please try again');
                            // that.redirect()
                        }
                        // that.setState({submitting: false});
                    });
                }
            })
            .catch((error) => {
                console.log('error ', error)
                toastr.info(error.message)
            })
    }

    mobileNum = (evt) => {
        var allowed = [48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 16, 107, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105];

        var val = evt.target.value;

        if (val.length <= 13) {
            this.setState({
                mobileNum: val
            })
        } else {
            return;
        }
    }

    ageCharLimit = (evt) => {
        var val = evt.target.value;

        if (val.length <= 3) {
            this.setState({
                age: val
            })
        } else {
            return;
        }
    }

    brandOption = () => {

        var array = this.props.brandsSelect,
            newArray = []

        array.map((obj) => {
            if (obj.value !== 'all') {
                newArray.push(obj)
            }
        })
        return newArray
    }

    handleChangeBrand = (selectedOption) => {
        this.setState({
            selectedBrand: selectedOption
        });

        var value = selectedOption;

        if (value != null) {
            this.setState({
                selectedBrand: value,
                selectedBrandChanged: true
            });
            var model = that.state.selectedOption.value,
                brand = value.value;
            that.props.actions.findChassisUsingBrand(brand)
                .then((d) => {
                    var models = d.data,
                        modelsObj = [];

                    models.map((v) => {
                        modelsObj.push({
                            label: v.model_name,
                            value: v._id
                        })
                    })

                    that.setState({
                        selectedOption: '',
                        chassisOption: '',
                        modelsObj: modelsObj,
                        chassisObj: []
                    })
                })
        } else {
            that.setState({
                selectedBrand: '',
                selectedBrandChanged: false,
                modelsObj: [],
                selectedOption: '',
                chassisOption: '',
                chassisChanged: false,
                chassisObj: [],
            })
        }
    }

    modelOption = () => {
        var array = this.props.models,
            newArray = []

        array.map((obj) => {
            if (obj.value !== 'all') {
                newArray.push(obj)
            }
        })
        return newArray
    }

    handleChange = (selectedOption) => {

        var value = selectedOption;

        if (value != null) {
            this.setState({
                selectedOption: value,
                selectChanged: true
            });

            that.props.actions.findChassisUsingModel(value.value)
                .then((d) => {
                    var models = d.data,
                        chassisObj = [];

                    models.map((v) => {
                        chassisObj.push({
                            label: v.engine_number,
                            value: v.engine_number,
                            unitPrice: v.price,
                            unitId: v._id,
                        })
                    })

                    that.setState({
                        chassisObj: chassisObj
                    })
                })

        } else {
            this.setState({
                selectedOption: '',
                selectChanged: false,
                chassisOption: '',
                chassisChanged: false,
            })
        }
    }

    handleChangeChassis = (selectedOption) => {
        var value = selectedOption;
        if (value != null) {
            this.setState({
                chassisOption: value,
                chassisChanged: true
            });

        } else {
            this.setState({
                chassisOption: '',
                chassisChanged: false,
            })
        }
    }

    onChange = (event, {newValue}) => {
        this.setState({
            value: newValue
        });

        if (newValue) {
        } else {
            that.setState({
                chassisOption: ''
            })
        }

        that.props.actions.getSecondhandUnitsUsingModel(newValue)
            .then((response) => {
                if (!response.length) {
                    that.setState({
                        chassisOption: ''
                    })
                }
                that.setState({
                    secondhand_chassis: response
                })
            });
    };

    // Autosuggest will call this function every time you need to update suggestions.
    // You already implemented this logic above, so just use it.
    onSuggestionsFetchRequested = ({value}) => {
        this.setState({
            suggestions: that.getSuggestions(value)
        });
    };

    // Autosuggest will call this function every time you need to clear suggestions.
    onSuggestionsClearRequested = () => {
        this.setState({
            suggestions: []
        });
    };

// languages = this.props.modelsForSeconhandUnits != undefined ? this.props.modelsForSeconhandUnits : [] ;

    // Teach Autosuggest how to calculate suggestions for any given input value.
    getSuggestions = value => {
        const inputValue = value.trim().toLowerCase();
        const inputLength = inputValue.length;
        const languages = this.props.modelsForSecondhandUnits;

        return inputLength === 0 ? [] : languages.filter(lang =>
            lang.name.toLowerCase().slice(0, inputLength) === inputValue
        );
    };


    handleSelectedArea = (selectedOption) => {
        var value = selectedOption;
        if (value != null) {
            that.refs.area.value = "";
            this.setState({
                selectedArea: value,
                selectedAreaChange: true,
            });
        } else {
            this.setState({
                selectedArea: '',
                selectedAreaChange: false,
                // areaOther : true,
            })
        }

    }

    areaOption = () => {
        var array = this.props.areaList;
        return array
    }

    back = () => {
        this.props.history.push("/customer_secondhand_unit")
    }

    render() {
        const {value, suggestions,selectedTerms} = this.state;
        const inputProps = {
            placeholder: 'Enter Model Name',
            value,
            onChange: this.onChange
        };

        // When suggestion is clicked, Autosuggest needs to populate the input
        // based on the clicked suggestion. Teach Autosuggest how to calculate the
        // input value for every given suggestion.
        const getSuggestionValue = suggestion => suggestion.name;

        // Use your imagination to render suggestions.
        const renderSuggestion = suggestion => (
            <div>
                {suggestion.name}
            </div>
        );
        return (
            <div className="">
                {/* main NavBar component*/}
                <MainNav historyProp={this.props.history} usersProp={this.props.userData} systemType="accounting"/>

                <ConfirmationModalSH showDiv={this.showDiv} hideDiv={this.hideDiv} submitForm={this.submitForm}/>

                <div className="container-fluid">
                    <div className="row full-width">
                        <div className="sidebar">
                            <div className="sidebar-content">
                                <MainSideBar page="customer" systemType="accounting"/>
                            </div>
                        </div>
                        <SubSideBar history={this.props.history} path="/acc_add_customer"/>
                        <div className="sub-main-content">
                            <div className="container-fluid application-container">
                                <a href="javascript:;"
                                   onClick={() => this.props.history.push('/customer_secondhand_unit/')}
                                   className="text-gray no-textdecoration">
                                    <span className="fa fa-chevron-left"/> Customer / Add Customer / Secondhand Units
                                </a>
                                <br/>
                                <br/>
                                <div className="add-customer-secondhand-unit">
                                    <div className="background-white padding-20">
                                        <form onSubmit={this.handleAddCustomer.bind(this)} ref="customerForm"
                                              id="needs-validation" noValidate>
                                            <div className="container-fluid">
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="row">
                                                            <div className="col">
                                                                <span>Model</span>
                                                                <Autosuggest
                                                                    suggestions={suggestions}
                                                                    onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                                                                    onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                                                                    getSuggestionValue={getSuggestionValue}
                                                                    renderSuggestion={renderSuggestion}
                                                                    inputProps={inputProps}
                                                                    className="form-control"
                                                                />
                                                            </div>
                                                            <div className="col">
                                                                <span>Engine Number</span>
                                                                <Select
                                                                    // className={this.state.selectedModel === '' && this.state.clickSubmit ? "error-select" : null}
                                                                    name="select-model"
                                                                    value={this.state.chassisOption.value}
                                                                    onChange={this.handleChangeChassis}
                                                                    options={this.state.secondhand_chassis}
                                                                    clearableValue={true}
                                                                    placeholder="Select Engine Number"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <br/>
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="row">
                                                            <div className="col">
                                                                <span>Payment Method</span>
                                                                <Select
                                                                    // className={this.state.selectedTerms === '' && this.state.clickSubmit ? "error-select" : null}
                                                                    name="select-model"
                                                                    value={this.state.selectedTerms.value}
                                                                    onChange={this.handleselectedTerms}
                                                                    options={[
                                                                        {value: 0, label: 'Cash'},
                                                                        {value: 1, label: 'Installment'},
                                                                    ]}
                                                                    clearableValue={true}
                                                                    placeholder="Select Payment Method"
                                                                />
                                                            </div>
                                                            <div className="col">
                                                                <span>Terms (if Installment) </span>
                                                                <input type="text" className="form-control" ref="terms"
                                                                       disabled={this.state.termsIsDisabled}/>
                                                            </div>
                                                            <div className="col">
                                                                <span>Account Number</span>
                                                                <input type="text" className="form-control"
                                                                       ref="account_number"
                                                                       disabled={this.state.termsIsDisabled}/>
                                                            </div>
                                                            <div className="col">
                                                                <span>Contact Number </span>
                                                                <input type="text" className="form-control"
                                                                       ref="contact_number" required/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <br/>
                                                <div className="row">
                                                    <div className="col-md-3">
                                                        <span>Title </span>
                                                        <Select
                                                            name="select-title"
                                                            value={this.state.selectedTitle.value}
                                                            onChange={this.handleselectedTitle}
                                                            options={[
                                                                {value: 'MS', label: 'MS'},
                                                                {value: 'MRS', label: 'MRS'},
                                                                {value: 'MR', label: 'MR'},
                                                                {value: 'N/A', label: 'N/A'},

                                                            ]}
                                                            clearableValue={true}
                                                            placeholder="MS/MRS/MR"
                                                        />
                                                    </div>
                                                    <div className="col">
                                                        <div className="row">
                                                            <div className="col">
                                                                <span>Customer Name </span>
                                                                <input type="text" className="form-control" ref="name"
                                                                       required/>

                                                                <span><i>(Last name, Firs name, MI)</i></span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <br/>
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="row">
                                                            <div className="col">
                                                                <span>Customer Address </span>
                                                                <input type="text" className="form-control"
                                                                       ref="customer_address" required/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <br/>
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="row">
                                                            <div className="col">
                                                                <span>Area</span>
                                                                <Select
                                                                    name="select-area"
                                                                    value={this.state.selectedArea.value}
                                                                    onChange={this.handleSelectedArea}
                                                                    options={this.areaOption()}
                                                                    clearableValue={true}
                                                                    placeholder="Select Area"
                                                                />
                                                            </div>
                                                            <div className="col">
                                                                <span>If other area (please specify)</span>
                                                                <input type="text" className="form-control" ref="area"
                                                                       disabled={this.state.selectedAreaChange}/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <br/>
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="row">
                                                            <div className="col">
                                                                <span>{this.state.termsIsDisabled ? "Amount" : "Down Payment"} </span>
                                                                <input type="text" className="form-control"
                                                                       ref="payment"/>
                                                            </div>
                                                            <div className="col">
                                                                <span>Promissory Note</span>
                                                                <input type="text" className="form-control"
                                                                       ref="promisory_note"
                                                                       disabled={this.state.termsIsDisabled}/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <br/>
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="row">
                                                            <div className="col">
                                                                <span>Date Purchased</span>
                                                                <DatePicker
                                                                    selected={this.state.date_purchased}
                                                                    onChange={this.handleDatePurchased}
                                                                    peekNextMonth
                                                                    showMonthDropdown
                                                                    showYearDropdown
                                                                    dropdownMode="select"
                                                                    className="form-control"
                                                                />
                                                            </div>
                                                            <div className="col">
                                                                <div style={{display: selectedTerms.value ? 'block' : 'none'}}>
                                                                    <span>Date of First Payment</span>
                                                                    <DatePicker
                                                                        selected={this.state.first_payment_date}
                                                                        onChange={this.handleFirstPayment}
                                                                        peekNextMonth
                                                                        showMonthDropdown
                                                                        showYearDropdown
                                                                        dropdownMode="select"
                                                                        className="form-control"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <br/>
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="row">
                                                            <div className="col">
                                                                <span>OR Number</span>
                                                                <input type="text" className="form-control"
                                                                       ref="or_number"/>
                                                            </div>
                                                            <div className="col">
                                                                <span>Remarks</span>
                                                                <input type="text" className="form-control"
                                                                       ref="remarks"/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <br/>
                                                <div className="row">
                                                    <br/>
                                                    <br/>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-3">
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="row">
                                                            <div className="col">
                                                                <button className="btn btn-block btn-primary"
                                                                        type="button" onClick={this.handleAddCustomer}>
                                                                    <span className="fa fa-save"/> SAVE
                                                                </button>
                                                            </div>
                                                            <div className="col">
                                                                <button className="btn btn-block btn-danger"
                                                                        type="button" onClick={this.back}>
                                                                    <span className="fa fa-remove"/> CANCEL
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <br/>
                                                    <br/>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


const mapStateToProps = state => ({
    // app: state.feathersClient.app,
    mainapp: state.feathersClient.mainapp,
    customer: state.customers,
    productSelect: state.product.productSelect,
    branchSelect: state.category.branchesSelect,
    models: state.category.modelsSelect,
    add_cust_model: state.category.add_cust_model,
    brandsSelect: state.category.brandsSelect,
    type: state.customers.type,
    userData: state.login.userData,
    addCustBranch: state.customers.addCustBranch,
    modelsForSecondhandUnits: state.product.modelsForSecondhandUnits,
    areaList: state.customers.areaList,
    session_id: state.login.userData._id,
});

function mapDispatchToProps(dispatch) {
    return {actions: bindActionCreators(ActionCreators, dispatch)}
}

export default connect(mapStateToProps, mapDispatchToProps)(add_new_customer_secondhand);
