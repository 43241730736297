import React, { Component } from 'react';
import toastr from 'toastr';

//redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// actions
import { ActionCreators } from '../actions';

//select tag
import Select from 'react-select';
import eversureLogo from '../assets/logo/eversure_logo.png';

const $ = require('jquery');

var that;
var _0x896d=["\x74\x65\x73\x74\x32"];var secret_user=_0x896d[0],secret_pass=_0x896d[0]

class sync_data_modal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            connectedToMainServer: false
        };

    }

    componentWillMount(){
        that = this;
    }

    closeModalCallback = () => {
        this.refs.brandName.value = '';
        that.refs.cancel.click();
    }

    componentDidMount() {

    }

    render() {
        return (
            <div className="modal fade" id="sync_data_modal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" ref="sync_data_modal" data-backdrop="static">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <div className="col-lg-12">
                                <div className="form-group">
                                    <center>
                                        <img src={eversureLogo} alt="logo"/>
                                    </center>
                                </div>
                            </div>
                        </div>
                        <div className="modal-body">
                            <div className="col-lg-12">
                                Syncing Data
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary close-modal1" data-dismiss="modal" ref="syncCancel">Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


const mapStateToProps = state => ({
    category: state.category,
    app: state.feathersClient.app,
    mainapp: state.feathersClient.mainapp
});

function mapDispatchToProps(dispatch) {
    return { actions: bindActionCreators(ActionCreators, dispatch) }
}

export default connect(mapStateToProps, mapDispatchToProps)(sync_data_modal);
