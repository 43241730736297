import React, {
    Component
} from 'react';

//redux
import {
    connect
} from 'react-redux';
import {
    bindActionCreators
} from 'redux';
// actions
import {
    ActionCreators
} from '../../../actions';

//main NavBar
import MainNav from '../../../components/global_nav';
import MainSideBar from '../../../components/global_sidebar';

//sub side bar
import SubSideBar from './sub_sidebar';

//custom helpers
import {
    numberWithCommas
} from '../../../helpers/';

//custom styles
import '../../../stylesheet/styles.css'

//datatables
import '../../../stylesheet/datatables/datatables.css';

//daterange
import moment from 'moment';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import './plugins/daterangepicker.css';

import html2canvas from 'html2canvas';
import jsPDF from '../../../../node_modules/jspdf/dist/jspdf.min.js';

import toastr from 'toastr';

let that;
const $ = require('jquery');
$.DataTable = require('datatables.net');

class ViewInventoryReport extends Component {
    constructor(props) {
        super(props);

        this.state = {
            brand: [],
            filter_reporttype: null,
            filter_daterange: null,
            filter_branch: '',
            filter_showby: null,
            filter_brand: null,
            filter_columnby: '',
            filter_columndata: null,
            sample: null,
            saving: false,
            drPickerApplied: false,
            table_data: [],
            table_class: 'table tbl-view-report-pdf tbl-mobile'
        };
    }

    componentWillMount() {
        that = this;
        const reportDetail = this.props.viewReportData,
            table_data = reportDetail.table_data;
        if (this.props.viewReportData.daterange_start !== null) {
            this.setState({
                drPickerApplied: true
            })
        }
        this.updateData(table_data);
    }

    componentDidMount() {
        const reportDetail = this.props.viewReportData,
            report_type = parseFloat(reportDetail.report_type);
        let dt_columns;

        if (report_type === 0) {
            that.table_class = 'table tbl-view-report-pdf tbl-mobile';
            dt_columns = [
                {title: ""},
                {title: "MODEL"},
                {title: "ENGINE"},
                {title: "BRANCH"},
                {title: "DATE <br/>RECEIVED"},
            ];
        }

        if (report_type === 1) {
            that.table_class = 'table tbl-view-report-pdf-1 tbl-mobile';
            dt_columns = [
                {title: "<br/>&nbsp;"},
                {title: "DATE SOLD"},
                {title: "CUSTOMER <br/>NAME"},
                {title: "ACCOUNT <br/> NUMBER"},
                {title: "MODEL <br/>&nbsp;"},
                {title: "ENGINE <br/>&nbsp;"},
                {title: "BRANCH <br/>&nbsp;"},
                {title: "TERM <br/>&nbsp;"},
                {title: "PAYMENT <br/>&nbsp;"},
            ];
        }

        if (report_type === 2 || report_type === 3 || report_type === 5) {
            that.table_class = 'table tbl-view-report-pdf-2 tbl-mobile';
            dt_columns = [
                {title: ""},
                {title: "MODEL"},
                {title: "ENGINE"},
                {title: "BRANCH"},
                {title: "DATE RECEIVED"},
                {title: "INVOICE <br/>NUMBER"},
                {title: "DELIVERY RECEIPT<br/> NO."},
            ];
        }

        if (report_type === 4 || report_type === 6) {
            that.table_class = 'table tbl-view-report-pdf-4 tbl-mobile';
            dt_columns = [
                {title: ""},
                {title: "BRAND <br/>&nbsp;"},
                {title: "MODEL <br/>&nbsp;"},
                {title: "COST <br/>&nbsp;"},
            ];
        }

        return $('#table-pdf').DataTable({
            data: this.state.table_data,
            "columnDefs": [
                {
                    "visible": false, "targets": 0
                },
            ],
            columns: dt_columns,
            "sDom": '<"bottom"<t>ip><"clear">',
            info: false,
            paging: false,
            paginglenght: 1000000,
            "bFilter": false
        });
    }

    updateData = (data) => {
        this.setState({
            table_data: data
        });

        const table = $('#table-pdf').DataTable();
        table.clear();
        table.rows.add(data);
        table.draw();
    }

    printDocument() {
        const input = document.getElementById('divToPrint');
        html2canvas(input)
            .then((canvas) => {
                const imgData = canvas.toDataURL('image/png');
                const pdf = new jsPDF({
                    orientation: 'portrait',
                });
                const imgProps = pdf.getImageProperties(imgData);
                const pdfWidth = pdf.internal.pageSize.getWidth();
                const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
                pdf.addImage(imgData, 'JPEG', 0, 0, pdfWidth, pdfHeight);
                pdf.save(this.props.viewReportData.report_title + ".pdf");
            });
    }

    mailTo = () => {
        const attachments = "test"
        const body_message = 'test';
        const email = '';
        const subject = 'Eversure Report';
        const mailto_link = 'mailto:' + email + '?subject=' + subject + '&body=' + body_message;
        const win = window.open(mailto_link, 'emailWindow');
        if (win && win.open && !win.closed) win.close();
    }

    render() {
        return (
            <div className="">
                {/* main NavBar component*/}
                <MainNav historyProp={this.props.history} usersProp={this.props.userData}/>

                <div className="container-fluid">
                    <div className="row full-width">
                        <div className="sidebar">
                            <div className="sidebar-content">
                                <MainSideBar page="report"/>
                            </div>
                        </div>
                        <SubSideBar history={this.props.history}/>
                        <div className="sub-inv-main-content">
                            <div className="container-fluid">
                                <div className="application-container">
                                    <div className="margin-y-24">
                                        <a href="#"
                                           onClick={() => this.props.history.goBack()}
                                           className="text-gray no-textdecoration breadcrumb-inv print-not-visible">
                                            <span className="fa fa-chevron-left"/> Reports
                                        </a>
                                        <div className="padding-20 background-white print-not-visible">
                                            <div className="col-md-12">
                                                <div className="row">
                                                    <div className="col">
                                                        <div className="row report-option-icons-2">
                                                            <a href="javascript:;" className="print-not-visible"
                                                               onClick={() => this.printDocument()}><i
                                                                className="fa fa-download"></i></a>
                                                            {
                                                                // <a href="javascript:;"><i className="fa fa-file-pdf-o"></i></a>
                                                                // <a href="javascript:;" className="print-not-visible" onClick={() => this.mailTo() }><i className="fa fa-envelope-o"></i></a>
                                                            }
                                                            <a href="javascript:;" className="print-not-visible"
                                                               onClick={() => window.print()}><i
                                                                className="fa fa-print"></i></a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row col-md-12">
                                            <a onClick={() => this.props.history.goBack()}
                                               className="subheader-title print-not-visible">Inventory
                                                Reports / {this.props.viewReportData.report_title}</a>
                                        </div>
                                        <div className="row padding-20 background-white" id="divToPrint">
                                            <div className="col-md-6">
                                                <h2>{this.props.viewReportData.report_title}</h2>
                                                <p>Created By {this.props.viewReportData.user_fullname}</p>
                                            </div>
                                            <div className="col-md-1">
                                            </div>
                                            <div className="col-md-5">
                                                <h5>INVENTORY REPORT</h5>
                                                <span>
                                        {
                                            this.state.drPickerApplied ? moment(this.props.viewReportData.daterange_start).format('LL') + ' to ' + moment(this.props.viewReportData.daterange_end).format('LL') : moment(this.props.viewReportData.createdAt).format('LL')
                                        }
                                        </span><br/>
                                                <span>{this.props.userData.branch_info.branch_name + ' BRANCH'}</span>
                                            </div>
                                            <div className="col-md-12">
                                                <br/>
                                            </div>
                                            <div className="col-md-12 scroll-y">
                                                <div className="header-pagination-container">
                                                    <ul className="pagination">

                                                    </ul>

                                                </div>
                                                <h3 className="float-right">
                                                    <strong>{this.props.viewReportData.extraData ? 'TOTAL COST = ' + numberWithCommas(this.props.viewReportData.extraData) : ''}</strong>
                                                </h3>
                                                <table className={this.table_class} id="table-pdf">
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    app: state.feathersClient.app,
    userData: state.login.userData,
    viewReportData: state.reports.viewReportData,
});

function mapDispatchToProps(dispatch) {
    return {actions: bindActionCreators(ActionCreators, dispatch)}
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewInventoryReport);
