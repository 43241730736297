import React, { Component } from 'react';

// react-router
import { Link } from 'react-router-dom';

//redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// actions
import { ActionCreators } from '../../../actions';

//main NavBar
import MainNav from '../../../components/global_nav';
// import MainSideBar from '../../../components/global_sidebar';

import MainSideBar from '../../../components/acc_sidebar';
//sub side bar
import SubSideBar from './sub_sidebar'; 

//custom styles
import '../../../stylesheet/styles.css';

//datatables
import '../../../stylesheet/datatables/datatables.css';

import app from '../../../helpers/feathers';
const $ = require('jquery');
$.DataTable = require('datatables.net');

var that;

class fully_paid_customers extends Component {


    constructor(props) {
        super(props);
    
        this.state = {
            data: [],
            customerName: "",
            accountNumber : ""
        };
    }

    componentWillMount() {
        that = this;
    }

    componentDidMount() {
        
        var applicationTbl = $('.tbl-customer').DataTable({
            // data: this.props.customers,
            "columnDefs": [
                {
                    "visible": false, "targets": [4,7]
                },
                {
                    targets: [3],
                    orderable: false,
                    width: '10%'
                }
            ],
            "order": [[ 8, "desc" ]],
            columns: [
                { title: "NAME" },
                { title: "ACCOUNT NO." },
                { title: "AREA" },
                { title: "MODEL" },
                { title: "ENGINE NO." },
                { title: "DATE PURCHASED." },
                { title: "DATE CLOSED" },
                { title: "REMARKS"},
                { title: "DATE CREATED"},
                { title: "ACTION" }
            ],
            "sDom": '<"bottom"<t>ip><"clear">' 
        });


        $('.search').keyup(function(){
            applicationTbl.search($(this).val()).draw() ;
        });

        $('.tbl-customer').on('click', 'button.btn-new-view', function () {
          var data = applicationTbl.row( $(this).parents('tr') ).data(),
                name = (data[0]).replace(/ /g,"");

            that.props.actions.setCustomerInfo(data[10]);
            that.props.history.push('/view_customer');
            // that.props.history.push('/acc_view_customer');
        });

        var custService = app.service('customers');

        custService.on('created', () => {
            // that.getAllCustomers();
        })

        that.getLatestCustomers();

    }



    // getAllCustomers = () => {
    //     this.props.actions.getFullyPaidCustomers().then((data) => {
    //     // this.props.actions.getAllCustomers().then((data) => {
    //         console.log('customers ', data)
    //         if(data){

    //             that.props.actions.setTermsCustomers(data);
    //             that.updateData(data);
    //         }
    //         else{
    //             // that.updateData([]);
    //         }

           
    //     });

    //     this.props.app.service('products').find()
    //     .then((data) => {
    //         // console.log('products ', data)
    //     })

    //     // this.props.app.service('customers').remove('5a7d7ebe6a03f17fb120f9cd')
    //     // .then(() => {

    //     // })

    // }

    getAllCustomers = (customerName, accountNumber) => {
        that.props.actions.getFullyPaidCustomers(customerName,accountNumber)
        .then((data) => {
            if(data){
                var d = data.data;
                that.props.actions.setTermsCustomers(data);
                that.updateData(d);  
                var totalNum = data.data.length ;   
                this.setState({totalCustomer:totalNum})
            }           
            
           
        })
    }

    getLatestCustomers = () => {
        that.props.actions.getFullyPaidCustomers(false,false,true)
        .then((res) => {
            if(res){
                that.updateData(res.data);
                const totalNum = res.length;
                that.setState({totalCustomer: totalNum});
            }
        })
    }

    advancedSearch = () => {
        var that = this,
        customerName = this.state.customerName,
        accountNumber = this.state.accountNumber,
        query = {};

        var permission = this.props.userPermission;

        if (!customerName && !accountNumber){
            that.getLatestCustomers()
             return;
        }

        if (permission.inventory === 0) {
            return
        }
        if (customerName) {
            query.name = {
                $regex: customerName.toUpperCase()
            }
        }

        if (accountNumber) {
            query.account_number = {
                $regex: accountNumber
            }
        }

        that.getAllCustomers(customerName.toUpperCase(), accountNumber.toUpperCase());

    }

    fastSearch = () => {

        let { customerName, accountNumber } = this.state;

        if(customerName.length > 0 || accountNumber.length > 0){
            that.advancedSearch()
        }else{
            that.getLatestCustomers()
        }
    }

    updateData = (data) => {
        this.setState({
            data: data
        });

        const table = $('.tbl-customer').DataTable();
        table.clear();
        table.rows.add(data);
        table.draw();
    }
    
    permissionCustomer = () => {
        var permission = this.props.userPermission;

        if (permission.customer === 0){
            return "not-visible"
        }else{
            return "";
        }
    }

    render() {
        return (
            <div className="">  
                
                {/* main NavBar component*/}
               <MainNav historyProp={this.props.history} usersProp={this.props.userData} systemType="accounting" />

                <div className="container-fluid">
                    <div className="row full-width">
                        <div className="sidebar">
                            <div className="sidebar-content">
                                <MainSideBar page="customer"/>
                            </div>
                        </div>
                        <SubSideBar history={this.props.history} userPermission={this.props.userPermission} page="customer"/>
                        <div>
                            <div className="sub-main-content mobile-customer-content-wrapper">
                                <div className = "container margin-top-40-percent">
                                    <div className="application-container2">
                                        <div className = "form-inline">
                                        <div className="col-md-12">
                                        <h4>Search By Filter</h4>
                                            <br />                                          
                                        </div>
                                        <div className="col-md-4">
                                            <label>Name</label>
                                            <input
                                                className="form-control full-width"
                                                placeholder="Enter Full Name"
                                                onChange={(e) => this.setState({customerName: e.target.value })}
                                                onKeyUp={(e) => this.fastSearch()}
                                            />
                                        </div>
                                        <div className="col-md-4">
                                            <label>Account No.</label>
                                            <input
                                                className="form-control full-width"
                                                placeholder="Enter Account No."
                                                onChange={(e) => this.setState({accountNumber: e.target.value })}
                                                onKeyUp={(e) => this.fastSearch()}
                                            />
                                        </div>                                      
                                        <div className="col-md-4">
                                            <label>&nbsp;</label>
                                            <button className  = "btn full-width btn-second-primary btn-sm" onClick={() => this.advancedSearch() } >Search</button>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                                <div className="container-fluid">
                                    <div className="application-container">
                                        <div className="form-inline">
                                            <span className="global__header-label">Customers with Fully Paid Accounts</span>
                                        </div>
                                        <div>
                                            <br />
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <table className="table tbl-customer tbl-mobile"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


const mapStateToProps = state => ({
    app: state.feathersClient.app,
    customers: state.customers.customersTerms,
    userData: state.login.userData,
    userPermission: state.login.userPermission,
});

function mapDispatchToProps(dispatch) {
   return { actions: bindActionCreators(ActionCreators, dispatch) }
}

export default connect(mapStateToProps, mapDispatchToProps)(fully_paid_customers);
