import React, {Component} from 'react';
import app from '../../../helpers/feathers';
import SyncDataModal from '../../../components/sync_data_modal';
// react-router
import {Link} from 'react-router-dom';
import moment from 'moment';

//redux
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
// actions
import {ActionCreators} from '../../../actions';

//main NavBar
import MainNav from '../../../components/global_nav';
// import MainSideBar from '../../../components/global_sidebar';

import MainSideBar from '../../../components/acc_sidebar';
//sub side bar
import SubSideBar from './sub_sidebar';


//custom styles
import '../../../stylesheet/styles.css';

//datatables
import '../../../stylesheet/datatables/datatables.css';


import SelectUnitType from './select_customer_modal';
import EditCustomerModal from './edit_customer_modal';
import RepossessModal from './repossess_modal';
import DeleteAccountModal from './delete_account_modal';
import Spinner from '../../../components/Spinner';

const $ = require('jquery');
$.DataTable = require('datatables.net');

var that;

class all_customers extends Component {

    _isMounted = false;

    constructor(props) {
        super(props);

        this.state = {
            data: [],
            customerName: "",
            accountNumber: "",
            searching: false,
            totalCustomer: 0,
            loading: false,
            searching: false,
        };
    }

    componentWillMount() {
        that = this;
    }

    componentDidMount() {

        that._isMounted = true;
        const { customers_bn_units, customers_sh_units } = this.props;
        const DT_DATA = customers_bn_units ? customers_bn_units.concat(customers_sh_units ? customers_sh_units : []) : [];

        const applicationTbl = $('.tbl-all-customer').DataTable({
            // data: this.props.customers,
            data: DT_DATA,
            "columnDefs": [
                {
                    "visible": false, "targets": 8
                },
                {
                    targets: 7,
                    orderable: false,
                    width: 30
                },
                {
                    // targets: 6,
                    // render: (data) => {
                    //     return moment(data).format('YYYY/MM/DD')
                    // }
                }
            ],
            "order": [[ 6, "desc" ]],
            columns: [
                {title: "NAME"},
                {title: "ACCOUNT NO."},
                {title: "ADDRESS"},
                {title: "CONTACT NO."},
                {title: "TYPE"},
                {title: "STATUS"},
                {title: "DATE CREATED"},
                {title: "ACTION"}
            ],
            "sDom": '<"bottom"<t>ip><"clear">'
        });


        $('.search').keyup(function () {
            applicationTbl.search($(this).val()).draw();
        });

        $('.tbl-all-customer').on('click', 'button.btn-new-view', function () {
            const data = applicationTbl.row($(this).parents('tr')).data(),
                name = (data[0]).replace(/ /g, "");
            that.props.actions.setCustomerInfo(data[8]);

            if(data[4] == "SECONDHAND"){
                that.props.history.push('/view_customer_secondhand');
            }else{
                that.props.history.push('/view_customer/');
            }
        });

        $('.tbl-all-customer').on('click', 'button.delete', function () {
            const data = applicationTbl.row($(this).parents('tr')).data();
            that.props.actions.setCustomerInfo(data[8]);

            $("#delete_account").modal("show")
        });

        that.getLatestCustomers();
    }

    componentWillUnmount(){
        that._isMounted = false;
    }
    
    advancedSearch = () => {
        var that = this,
            customerName = this.state.customerName,
            accountNumber = this.state.accountNumber,
            query = {};

        // var permission = this.props.userPermission;

        if (customerName === '' && accountNumber === '') {
            that.getLatestCustomers();
            return;
        }

        // if (permission.inventory === 0) {
        //     return
        // }
        if (customerName) {
            query.name = {
                $regex: customerName.toUpperCase()
            }
        }

        if (accountNumber) {
            query.account_number = {
                $regex: accountNumber
            }
        }

        that.getAllCustomers(customerName.toUpperCase(), accountNumber.toUpperCase());

    }

    fastSearch = () => {

        let {customerName, accountNumber} = this.state;

        if (customerName.length > 0 || accountNumber.length > 0) {
            that.advancedSearch()
        }
    }


    // getAllCustomers = (query) => {
    //     this.props.actions.getTermsCustomers(query).then((data) => {

    //         if(data){
    //             that.props.actions.setTermsCustomers(data);
    //             that.updateData(data);
    //             var totalNum = data.length ;
    //             this.setState({totalCustomer:totalNum})
    //         }
    //         else{
    //             that.updateData([]);
    //         }
    //     });
    // }

    getAllCustomers = (customerName, accountNumber) => {
        if(that._isMounted){
            if(that.state.searching) return;
            that.setState({searching: true, loading: true});
            that.props.actions.getAllCustomersList(customerName, accountNumber).then((data) => {
                var bn_data = data.data ? data.data : [];
                that.props.actions.getAllShCustomersList(customerName, accountNumber)
                .then((sh_data) => {
                    that.setState({searching: false, loading: false});
                    if(sh_data.status){
                        let dt_data = bn_data.concat(sh_data.data);
                        that.updateData(dt_data);
                        const totalNum = dt_data.length;
                        that.setState({totalCustomer: totalNum});
                    }else{
                        if(bn_data){
                            that.updateData(bn_data);
                            that.setState({totalCustomer: bn_data.length});
                        }else{
                            that.updateData([]);
                            that.setState({totalCustomer: 0});
                        }

                    }
                });
            })
        }
    };

    getLatestCustomers = () => {
        if(that._isMounted){
            that.setState({loading: true});
            that.props.actions.getAllCustomersList(false,false,true)
            .then((res) => {
                that.setState({loading: false});
                var bn_data = res.data ? res.data : [];
                that.props.actions.getAllShCustomersList(false,false,true)
                .then((sh_data) => {
                    if(sh_data.status){
                        let dt_data = bn_data.concat(sh_data.data);
                        that.updateData(dt_data);
                        const totalNum = dt_data.length;
                        that.setState({totalCustomer: totalNum});
                    }else{
                        that.updateData([]);
                        that.setState({totalCustomer: 0});

                    }
                });
            })
        }
        // that.props.actions.testC();
    }


    updateData = (data) => {
        this.setState({
            data: data
        });
        const table = $('.tbl-all-customer').DataTable();
        table.clear();
        table.rows.add(data);
        table.draw();
    };

    permissionAccounting = () => {
        const permission = this.props.userPermission;
        return permission.accounting === 0 ? "not-visible" : "";
        // if (permission.accounting === 0){
        //     return "not-visible"
        // }else{
        //     return "";
        // }
    };

    render() {
        let { loading } = this.state;
        return (
            <div className="">
                {/* main NavBar component*/}
                <MainNav historyProp={this.props.history} usersProp={this.props.userData} systemType="accounting"/>
                <SyncDataModal/>
                <EditCustomerModal/>
                <DeleteAccountModal callBack={this.advancedSearch}/>
                <RepossessModal history={this.props.history}/>
                <SelectUnitType history={this.props.history}/>
                <div className="container-fluid">
                    <div className="row full-width">
                        <div className="sidebar">
                            <div className="sidebar-content">
                                <MainSideBar page="customer"/>
                            </div>
                        </div>
                        <SubSideBar
                            history={this.props.history}
                            userPermission={this.props.userPermission}
                            page="all_customers"
                            path="/all_customers"
                            pageTitle="ALL_CUSTOMERS"
                        />
                        <div>
                            <div className="sub-main-content mobile-container-installment">
                                <div className="container">
                                    <div className="application-container2">
                                        <div className="form-inline">
                                            <div className="col-md-12">
                                                <h4>Search By Filter</h4>
                                                <br/>
                                            </div>
                                            <div className="col-md-4">
                                                <label>Name</label>
                                                <input
                                                    className="form-control full-width"
                                                    placeholder="Enter Full Name"
                                                    onChange={(e) => this.setState({customerName: e.target.value})}
                                                    ref="customer_name"
                                                    onKeyUp={(e) => this.advancedSearch()}
                                                />
                                            </div>
                                            <div className="col-md-4">
                                                <label>Account No.</label>
                                                <input
                                                    className="form-control full-width"
                                                    placeholder="Enter Account No."
                                                    onChange={(e) => this.setState({accountNumber: e.target.value})}
                                                    ref="account_number"
                                                    onKeyUp={(e) => this.advancedSearch()}
                                                />
                                            </div>
                                            <div className="col-md-4">
                                                <label>&nbsp;</label>
                                                <button
                                                    className="btn full-width btn-second-primary btn-sm"
                                                    onClick={() => this.advancedSearch()}
                                                >
                                                    {
                                                        loading ? (
                                                            <div className="spinner-grow spinner-grow-sm" role="status">
                                                            <span className="sr-only">Loading...</span>
                                                            </div>
                                                        ) : (`Search`)
                                                    }
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="container-fluid">
                                    <div className="application-container">
                                        <div className="row padding-0-15">
                                            <div className="col">
                                                <span className="global__header-label">
                                                    All Customers
                                                    {/* <Spinner status={loading} /> */}
                                                </span>
                                                <span className="badge badge-info customer-count float-right">No of Customers : {this.state.totalCustomer} </span>
                                            </div>
                                        </div>
                                        <div>
                                            <br/>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <table className="table tbl-all-customer tbl-mobile"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


const mapStateToProps = state => ({
    app: state.feathersClient.app,
    customers: state.customers.customersTerms,
    userData: state.login.userData,
    userPermission: state.login.userPermission,
    customers_bn_units: state.customers.customers_bn_units,
    customers_sh_units: state.customers.customers_sh_units,
});

function mapDispatchToProps(dispatch) {
    return {actions: bindActionCreators(ActionCreators, dispatch)}
}

export default connect(mapStateToProps, mapDispatchToProps)(all_customers);
