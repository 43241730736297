const feathers = require('@feathersjs/feathers');
const socketio = require('@feathersjs/socketio-client');
// const io = require('socket.io-client');
const io = require('socket.io-client/dist/socket.io');
const auth = require('@feathersjs/authentication-client');

const production = true;

const ipHost = "203.177.206.174";
const dHost = "https://cloud.eversuremotorcycles.com";
const localHost = "http://localhost";
// const host = "https://203.177.206.174";
// const host = "https://cloud.eversuremotorcycles.com";
// const host = "http://192.168.1.5";
// const host = "http://localhost";

const host = production ? dHost : localHost;

const socket = io(`${host}:3030`,{
    transports: ['websocket'],
    maxHttpBufferSize:1e8,
    wsEngine: 'uws',
    'sync disconnect on unload': true,
    // forceNew: true,
    // transports: ['websocket','polling'],
 });
const app = feathers();
// Setup the transport (Rest, Socket, etc.) here
app.configure(socketio(socket));
app.configure(auth({ storage: window.localStorage }));
export default app;
