import React, {Component} from 'react';
import toastr from 'toastr';

//redux
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
// actions
import {ActionCreators} from '../../../actions';

import app from '../../../helpers/feathers';

const $ = require('jquery');

class add_brand_modal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            saving: false,
            mainbranch: ''
        };
    }

    componentWillMount() {
        app.service('branches').find({
            query: {
                branch_name: 'MAIN'
            }
        }).then((d) => {
            this.setState({
                mainbranch: d.data[0]._id
            });
        })
        .catch((err) => {
            console.log(err)
        });
    }

    componentDidMount(){
        this.setState({
            saving: false
        })
    }

    closeModalCallback = () => {
        this.refs.brandName.value = '';
        this.refs.cancel.click();
    }

    addBrand = () => {
        const brandName = this.refs.brandName.value.trim(),
            u_brandName = brandName.toUpperCase();

        if (this.state.saving) {
            return;
        } else {
            this.setState({
                saving: true
            });
        }

        if (brandName.length < 1) {
            this.refs.brandName.value = '';
            return;
        }

        this.props.actions.addBrands(u_brandName, this.state.mainbranch).then((data) => {
            this.closeModalCallback();
            if (data === 'exist') {
                toastr.error(brandName + ' already exists.');
                this.setState({
                    saving: false
                });
            } else if (data === 'success') {
                toastr.success(brandName + ' has been successfully added');
                this.setState({
                    saving: false
                });
            } else {
                toastr.error('Failed to add. Please try again.');
                this.setState({
                    saving: false
                });
            }
        });

    }

    componentDidMount() {
    }

    render() {
        return (
            <div className="modal fade" id="add_brand" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel"
                 aria-hidden="true" ref="add_brand" data-backdrop="static" data-keyboard="false">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Add Motorcycle Brand</h5>
                            <button type="button" className="close" onClick={this.closeModalCallback}
                                    aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="form-group">
                                <label>Brand Name</label>
                                <input type="text" className="form-control" ref="brandName"/>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <a data-dismiss="modal" ref="cancel"></a>
                            <button type="button" className="btn btn-secondary"
                                    onClick={this.closeModalCallback}>Cancel
                            </button>
                            <button type="button" className="btn btn-primary" onClick={this.addBrand}>Save</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


const mapStateToProps = state => ({
    category: state.category
});

function mapDispatchToProps(dispatch) {
    return {actions: bindActionCreators(ActionCreators, dispatch)}
}

export default connect(mapStateToProps, mapDispatchToProps)(add_brand_modal);