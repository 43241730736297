import React, {Component} from 'react';
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../../node_modules/font-awesome/css/font-awesome.min.css';

import {Link} from 'react-router-dom';

//custom styles
import '../stylesheet/styles.css';

import moment from 'moment';
import Tooltip from "react-simple-tooltip"

//redux
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
// actions
import {ActionCreators} from '../actions';

import notificationLogo from '../assets/icons/notifications.svg';
import signoutLogo from '../assets/icons/signout.svg';
import eversureLogo from '../assets/logo/eversure_logo.png';

import app from '../helpers/feathers';
import { conf } from '../helpers';

import hamburger from "../assets/icons/menu.svg";
import exis from "../assets/icons/x.svg";

const _user = conf.defaults.username, _pass = conf.defaults.password;

const $ = require('jquery');

var that;

// const AddTripButton = props => {
//     return <button onClick={this.state.props.addTrip}>Add a trip</button>
// }

class global_nav extends Component {

    constructor(props) {
        super(props);
        this.state = {
            visible: false
        };
        this.toggleMenu = this.toggleMenu.bind(this);
        if (window.innerWidth <= 480) {
            this.state.visible = true;
        }
    }

    componentWillMount() {
        that = this;
        const _this = this;
        var usersProp = this.props.usersProp;
        this.saveModelsforAutoSuggest();
        this.props.actions.getAllBranches().then((data) => {
            if(data){
                that.props.actions.setBranches(data);
            }
        });

        if(typeof this.props.userData == 'undefined'){
            that.props.actions.loginUser(false);
        }

        // get brands for select options to be used in 'add customer' page
        _this.props.actions.selectOptionBrands();
    }

    componentDidMount() {
        if (window.innerWidth <= 768) {
        }

        if (window.innerWidth <= 480) {
            this.state.visible = true;
            $("body .sidebar").fadeOut();
            $("body .nav-content__wrapper").css({left: "0", width: "99%"});
            $("body .tab-nav-logo__wrapper").css({display: "block"});
            $("body .main-content").css({width: "100%", left: "0"});
            $("body .tab-header-logo").css({display: "block"});
        } else {
            this.state.visible = false;
        }
        //COntrol textbox to UPPERCASE
        $(".form-control").on('keyup change', function (e) {
            $(this).val($(this).val().toUpperCase());
        });

        if (this.props.systemType == "inventory") {
            $("body .dropdown.accounting").hide()
        } else {
            $("body .dropdown.inventory").hide()
        }

        // console.log('usersProp ',( typeof this.props.usersProp.access_level === 'undefined' ? 'undefinedzzzz' : this.props.usersProp.access_level )

        this.props.actions.getNotifications()

        app.service("password-request").on("created", function(){
            that.props.actions.getNotifications()
        })
    }

    logOut = () => {
        var that = this;
        // that.props.historyProp.push('/');
        // that.props.actions.loginUser(false);
        // localStorage.removeItem('_id_secret');
        app.logout().then(() => {
            that.props.actions.loginUser(false);
            that.props.actions.setBnUnits([]); // clear brand new units saved data
            that.props.actions.setShUnits([]); // clear brand new units saved data
            localStorage.removeItem('_id_secret');
            localStorage.removeItem('_position_secret');
            that.props.historyProp.push('/');
            // localStorage.clear();
        }).catch((error) => {
            return error;
            // console.log(error.message)
        })
    }

    saveModelsforAutoSuggest = () => {
        app.authenticate({
            strategy: 'local',
            username: _user,
            password: _pass
        })
        .then(() => {
            app.service('motorcycle-models').find({
                paginate: {
                    default: 10,
                    max: 50
                }
            })
                .then((data) => {
                    var d = data.data,
                        models = [];

                    d.map((v) => {
                        models.push({
                            name: v.model_name
                        })
                    })

                    that.props.actions.setModelsForSecondhand(models);
                })
                .catch((error) => {
                    // console.log('')
                })
        })
        .catch((err) => {
            return false;
            // console.log('')
        })
    }

    userName = () => {
        var usersProp = this.props.usersProp;
        if (typeof usersProp === 'undefined') {
            return
        } else {
            return usersProp.fullname
        }
    }

    toggleDropdown = () => {
        this.setState({dropdownShown: !this.state.dropdownShown})
    }

    toggleMenu() {
        var boxWidth = $(".sidebar").width();
        if (window.outerWidth <= 768) {
            $("body .sidebar").fadeIn();
        } else {
            if (this.state.visible) {
                $("body .sidebar").fadeIn();
                $("body .nav-content__wrapper").css({left: "19%", width: "82%"});
                $("body .tab-nav-logo__wrapper").css({display: "none"});
                $("body .main-content").css({width: "86%", left: "14%"});
                $("body .tab-header-logo").css({display: "none"});
                // $("body .sidebar-logo").css({display: "block"});
            } else {
                $("body .sidebar").fadeOut();
                $("body .nav-content__wrapper").css({left: "0", width: "99%"});
                $("body .tab-nav-logo__wrapper").css({display: "block"});
                $("body .main-content").css({width: "100%", left: "0"});
                $("body .tab-header-logo").css({display: "block"});
                // $("body .sidebar-logo").css({display: "none"});
            }
        }

        this.setState({visible: !this.state.visible})
    }

    notif = () => {
        if(typeof this.props.userData != 'undefined' && this.props.userData.branch_info.branch_name !== "MAIN"){
            return null
        }

        return <li className="nav-item notifications-link">
            <Link to="/notifications">
                {
                    this.props.unread > 0 ? <span className="badge badge-danger">{this.props.unread}</span> : null
                }
                <i className="fa fa-bell"/>
            </Link>
        </li>
    }


    render() {
        return (
            <nav className="nav-content__wrapper header">
                {/*     Mobile/Tab Display    */}
                <form className="tab-wrapper">
                    {/*<AddTripButton addTrip={this.triggerAddTripState} />*/}
                    {window.outerWidth > 768 &&
                    <div>
                        {!!this.state.visible &&
                        < button className="clear-button" hidden={window.outerWidth <= 480}
                                 onClick={this.toggleMenu}>
                            <img src={exis} alt="close" />
                        </button>
                        }
                        {this.state.visible &&
                        <button className="clear-button"
                                onClick={this.toggleMenu}>
                            <img src={hamburger} alt="menu" />
                        </button>}
                    </div>
                    }
                    {window.outerWidth <= 768 &&
                    <div>
                        <button className="clear-button"
                                onClick={this.toggleMenu}>
                            <img src={hamburger} alt="menu" />
                        </button>
                    </div>
                    }
                    <div className="tab-nav-logo__wrapper">
                        <Link to={this.props.page === "landing_page" ? "/accounting/" : "/"} className="navbar-brand">
                            <img alt="icon" src={eversureLogo} className="tab-header-logo"/>
                        </Link>
                    </div>
                    <div hidden={this.props.userData !== 'undefined' && this.props.userData.branch_info.branch_name !== "MAIN"}
                        className="notifications-link">
                        <Link to="/notifications">
                            {
                                this.props.unread > 0 ? <span className="badge badge-danger">{this.props.unread}</span> : null
                            }
                            <i className="fa fa-bell"/>
                        </Link>
                    </div>
                    <div hidden={this.props.userData !== 'undefined' && this.props.userData.branch_info.branch_name !== "MAIN"}
                         className="notifications-link-mobile">
                        <Link to="/notifications">
                            {
                                this.props.unread > 0 ? <span className="badge badge-danger">{this.props.unread}</span> : null
                            }
                            <i className="fa fa-bell"/>
                        </Link>
                    </div>
                    <div className="tab-welcoming__wrapper">
                        <a className="nav-link session-label" href="#">Welcome, <b>{this.userName()}</b></a>
                    </div>
                    <div className="tab-logout-logo__wrapper">
                        <a className="nav-link" href="#" onClick={this.logOut.bind(this)}>
                            <img alt="icon" src={signoutLogo} className="nav-header-icon"/>
                        </a>
                    </div>
                </form>
                <div className="tab-wrapper form-inline logout__wrapper">
                    <ul className="nav">
                        <li className="nav-item logout-back">
                            <Link to={this.props.systemType === "accounting" ? "/accounting" : "/"}>
                                <button><i className="fa fa-caret-left"/> Back to main menu</button>
                            </Link>
                        </li>
                    </ul>
                </div>
                {/*   End  Mobile/Tab Display    */}

                {/*     Web Display    */}
                <form className="web-wrapper form-inline logout__wrapper">
                    <ul className="nav">
                        <li className="nav-item logout-back">
                            <Link to={this.props.systemType === "accounting" ? "/accounting" : "/"}>
                                <button><i className="fa fa-caret-left"/> Back to main menu</button>
                            </Link>
                        </li>
                        <li className="nav-item logout-label">
                            <a className="nav-link session-label" href="#">Welcome, <b>{this.userName()}</b></a>
                        </li>
                        {
                            this.notif()
                        }
                        <li className="nav-item logout-icon">
                            <a className="nav-link" href="#" onClick={this.logOut.bind(this)}>
                                <img alt="icon" src={signoutLogo} className="nav-header-icon"/>
                            </a>
                        </li>
                    </ul>
                </form>
                {/*   End  Web Display    */}
            </nav>
        );
    }
}

const mapStateToProps = state => ({
   app: state.feathersClient.app,
   userData: state.login.userData,
   unread: state.dashboard.unread,
});

function mapDispatchToProps(dispatch) {
    return {actions: bindActionCreators(ActionCreators, dispatch)}
}

export default connect(mapStateToProps, mapDispatchToProps)(global_nav);
