import React, {Component} from 'react';

//toastr
import toastr from 'toastr';

//redux
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
// actions
import {ActionCreators} from '../../../actions';

import app from '../../../helpers/feathers';

//datatables
import '../../../stylesheet/datatables/datatables.css';

const $ = require('jquery');
$.DataTable = require('datatables.net');

class brand extends Component {

    constructor(props) {
        super(props);

        this.state = {
            brandList: []
        }
    }

    componentWillMount() {
        // get all models from category actions
        this.getBrandList();

        // triggered when new model was added
        this.listenCreatedBrand();

        // triggered when a model was deleted/removed
        this.listenDeletedBrand();
    }

    componentDidMount() {
        // app.service("products").find({
        //     paginate: {
        //         default: 10,
        //         paginate: 40
        //     },
        //     query: {
        //         // brand: "62a16655953fea06e698b2f6",
        //         brand: "5f2e19a953d33a23a081ee93",
        //         $limit: 1
        //     }
        // }).then((data) => {
        //     console.log(data)
        // }).catch((error) => {
        //     console.log(error)
        // })
        const that = this,
            tblBrand = $('.tbl-category-brand'),
            tblBrandDataTable = tblBrand.DataTable({
                data: this.props.category.brandsList,
                "columnDefs": [
                    {
                        "visible": false, "targets": 0
                    },
                    {
                        "targets": 1,
                        "width": '10%'
                    },
                    {
                        "targets": 3,
                        "orderable": false,
                        "width": '20%'
                    },
                    {
                        order: [[0, 'desc']]
                    }
                ],
                columns: [
                    {title: "Id"},
                    {title: "No."},
                    {title: "Name"},
                    {title: "Action"},
                ],
                'dom': "<'row'<'col-sm-6'l><'col-sm-6'p>>" +
                    "<'row'<'col-sm-12'tr>>" +
                    "<'row'<'col-sm-12'i>>",
            });

        tblBrand.on('click', 'button.delete', function () {
            const data = tblBrandDataTable.row($(this).parents('tr')).data(),
                permission = that.props.userPermission;
            //call delete actions in category actions
            that.props.actions.brandInUse(data[0])
                .then((brandInUse) => {
                    if (brandInUse) {
                        toastr.error('Could not delete. Item is in use')
                    } else {
                        app.service('motorcycle-models').find({
                            paginate: {
                                default: 10,
                                paginate: 40
                            },
                            query: {
                                brand: data[0],
                                $limit: 5,
                            }
                        }).then((res) => {
                            if (res.total) {
                                toastr.error('Could not delete. Item is in use')
                            } else {
                                if (permission.inventory === 2) {
                                    that.props.actions.deleteId(data[0]);
                                    that.props.actions.deleteService('brands');
                                    that.refs.confirm_delete_brand.click();
                                }
                            }
                        }).catch(() => {
                            toastr.error('An error occurred')
                        });
                    }
                })
        });

        //when edit button was clicked
        tblBrand.on('click', 'button.edit', function () {
            const data = tblBrandDataTable.row($(this).parents('tr')).data(),
                brandInfo = {
                    id: data[0],
                    name: data[2]
                }
            console.log(data)
            that.refs.updateBrand.click();
            that.props.actions.brandUpdateInfo(brandInfo);
        });

        this.getBrandList();
    }

    getBrandList = () => {
        this.props.actions.getAllBrandsWithoutBranchName().then((data) => {
            if(data){
                this.props.actions.setBrands(data);
                this.setBrandList(data);
            }
        });
    }

    listenCreatedBrand = () => {
        const motorcycleModel = app.service('brands'),
            that = this;

        motorcycleModel.on('created', function (brand) {
            that.getBrandList();
        });
        motorcycleModel.on('patched', function (brand) {
            that.getBrandList();
        });
    }

    listenDeletedBrand = () => {
        const brandModel = app.service('brands'),
            that = this;

        brandModel.on('removed', function (brand) {
            that.getBrandList();
        });
    }

    setBrandList = data => {
        this.setState({
            brandList: data
        });
        const table = $('.tbl-category-brand').DataTable();
        table.clear();
        table.rows.add(data ? data : []);
        table.draw();
    }

    updateBrandList = data => {
        const table = $('.tbl-category-brand').DataTable();
        const brandTables = this.state.brandList;
        const newData = brandTables.concat(data);

        this.setState({
            brandList: newData
        });
        table.clear();
        table.rows.add(newData);
        table.draw();
    }

    render() {
        return (
            <div className="row">
                <div className="col-md-12">
                    <div className="row col-md-6">
                        <div className="form-inline col">
                            <h4 className="subheader-title">Brands</h4>
                        </div>
                        <div className="col">
                            <button className="btn btn-second-primary add-new-btn" data-toggle="modal"
                                    data-target="#add_brand">
                                <span><i className="fa fa-plus"/> Add </span>
                            </button>
                        </div>
                    </div>
                    <button className="btn btn-primary d-none btn-block" data-toggle="modal" data-target="#update_brand"
                            ref="updateBrand"><span className="fa fa-plus"/> Add
                    </button>
                    <a data-toggle="modal" data-target="#confirm_delete" ref="confirm_delete_brand"></a>
                    <table className="table tbl-category-brand full-width tbl-mobile"></table>
                </div>
            </div>
        );
    }
}


const mapStateToProps = state => ({
    category: state.category,
    userPermission: state.login.userPermission,
});

function mapDispatchToProps(dispatch) {
    return {actions: bindActionCreators(ActionCreators, dispatch)}
}

export default connect(mapStateToProps, mapDispatchToProps)(brand);
