import React, {Component} from 'react';

//redux
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
// actions
import {ActionCreators} from '../../../actions';

//main NavBar
import MainNav from '../../../components/global_nav';
import MainSideBar from '../../../components/global_sidebar';

//custom sidebar
import SubSideBar from './sub_sidebar';

import app from '../../../helpers/feathers';

//select tag
import Select from 'react-select';

import AcceptIncomingModal from './accept_incoming_modal';


//custom styles
import '../../../stylesheet/styles.css';

//datatables
import '../../../stylesheet/datatables/datatables.css';

const $ = require('jquery');
$.DataTable = require('datatables.net');

const _0x896d = ["\x74\x65\x73\x74\x32"];
const secret_user = _0x896d[0], secret_pass = _0x896d[0];

class incoming extends Component {
    constructor(props) {
        super(props);

        this.state = {
            sold: []
        };
    }

    componentWillMount() {
        this.getIncomingProducts()
    }

    getIncomingProducts = () => {
        const that = this;
        const branch = this.props.userData.branch_info._id;
        // const incomingProducts = this.props.actions.getIncomingProducts(secret_user, secret_pass, branch);
        // Promise.resolve(incomingProducts).then(function (value) {
        //     console.log(value)
        //     // that.props.actions.setIncomingProducts(value);
        //     that.updateData(value);
        // })
        this.props.actions.getIncomingProducts(secret_user, secret_pass, branch)
        .then((data) => {
            if(data){
                that.updateData(data);
            }else{
                // toastr.error("Failed to retrieve data")
            }
        })
        .catch((err) => {
            console.log(err)
        });
    }

    componentDidMount() {
        const that = this, productTable = $('.tbl-incoming').DataTable({
            data: this.props.product,
            columns: [
                {"title": "ID"},
                {"title": "DATE TRANSFERRED"},
                {"title": "MODEL NAME"},
                {"title": "ENGINE NO"},
                {"title": "FROM"},
                {"title": "ACTION"},
                {"title": "DETAILS"},
            ],
            columnDefs: [
                {
                    targets: [0],
                    visible: false
                },
                {
                    targets: [4],
                    width: '20%',
                },
                {
                    targets: [6],
                    visible: false,
                }
            ],
            'dom': "<'row'<'col-sm-6'l><'col-sm-6'p>>" +
                "<'row'<'col-sm-12'tr>>" +
                "<'row'<'col-sm-12'i>>",
        });

        $('.search').keyup(function () {
            productTable.search($(this).val()).draw();
        });

        $('.tbl-incoming').on('click', '.accept', function () {
            const data = productTable.row($(this).parents('tr')).data();
            const permission = that.props.userPermission;
            if (permission.inventory === 2) {
                that.props.actions.acceptDataId(data[0]);
                that.refs.accept_incoming.click();
            }
        })

        $('.tbl-incoming').on('click', '.view-details', function () {
            const data = productTable.row($(this).parents('tr')).data();
            that.props.actions.productInfo(data[0]);
            that.props.history.push('/view_inventory')
        })

        app.service('products').on('patched', function () {
            that.getIncomingProducts();
        })
    }


    updateData = data => {
        this.setState({
            sold: data
        });

        const table = $('.tbl-incoming').DataTable();
        table.clear();
        table.rows.add(data);
        table.draw();
    }

    permissionInventory = () => {
        const permission = this.props.userPermission;

        if (permission.inventory === 0) {
            return "not-visible"
        } else {
            return "";
        }
    }

    render() {
        return (
            <div className="">
                {/* main NavBar component*/}
                <MainNav historyProp={this.props.history} usersProp={this.props.userData}/>
                <AcceptIncomingModal/>
                <div className="container-fluid">
                    <div className="row full-width">
                        <div className="sidebar">
                            <div className="sidebar-content">
                                <MainSideBar page="inventory"/>
                            </div>
                        </div>
                        <SubSideBar history={this.props.history} allProps={this.props}
                                    userPermission={this.props.userPermission} pageName="incoming"
                                    pageTitle="UNITS_INCOMING"/>
                        <div className="sub-inv-main-content">
                            <div className="container-fluid">
                                {
                                    this.props.userPermission.inventory === 0 ?
                                        <div className="row no-access-div">
                                            <div className="col-md-12">
                                                <h1>Access Denied</h1>
                                            </div>
                                        </div>
                                        : null
                                }
                                <div className={this.permissionInventory()}>
                                    <div className="application-container">
                                        <div className="col">
                                            <div className="row">
                                                <div className="form-inline">
                                                    <h4 className="subheader-title">Incoming Units</h4>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="content-header col-md-4">
                                                    <input type="search"
                                                           className="search-class search form-control"
                                                           placeholder="&#xF002; Search"/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <a data-toggle="modal" data-target="#accept_incoming"
                                                   ref="accept_incoming"></a>
                                                <table className="table tbl-incoming tbl-mobile">
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    app: state.feathersClient.app,
    mainapp: state.feathersClient.mainapp,
    product: state.product.incomingProducts,
    userData: state.login.userData,
    userPermission: state.login.userPermission,
});

function mapDispatchToProps(dispatch) {
    return {actions: bindActionCreators(ActionCreators, dispatch)}
}

export default connect(mapStateToProps, mapDispatchToProps)(incoming);
