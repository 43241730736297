import React, {Component} from 'react';

import {Link} from 'react-router-dom';
const $ = require('jquery');

class sub_sidebar extends Component {

    handleChange(value) {
        if (value === 'modal') {
            console.log('==============clear');
            return $("#clear-data-tag").click();
        } else {
            this.props.history.push(`${value}`);
        }
    }

    render() {
        let permission = this.props.userPermission,
            category_link = permission !== undefined ? permission.category === 0 ? "#" : "/settings" : "/settings",
            user_link = permission !== undefined ? permission.category === 0 ? "#" : "/settings_users" : "/settings_users";

        let x = {
            subnav: [
                {id: category_link, name: 'Category Management'},
                {id: user_link, name: 'User Management'},
                {id: 'modal', name: 'Clear Data'},
            ]
        };

        let subnav = x.subnav.length > 0
            && x.subnav.map((item, i) => {
                if (this.props.history.location.pathname === item.id) {
                    return (
                        <option key={i} value={item.id} selected>{item.name}</option>
                    )
                } else {
                    return (
                        <option key={i} value={item.id}>{item.name}</option>
                    )
                }

            }, this);

        return (
            <div>
                <div className="sub-sidebar inv-sub-sidebar">
                    {/*<ul className="nav flex-column list">*/}
                    {window.outerWidth <= 480 ?
                        <div className="sub-sidebar inv-sub-sidebar mobile-inv-dashboard__wrapper">
                            <a href="javascript:;" id="clear-data-tag" data-toggle={permission.category !== 2 ? "" : "modal"}
                               data-target="#clear-data" className="nav-link clear-data-mobile">Clear Data</a>
                            <ul className="list">
                                <li className="non-nav subheader__name">
                                    <h4>Settings </h4>
                                </li>
                                <div className="mobile-dropdown__wrapper">
                                    <select className="dropdown__select"
                                            onChange={event => this.handleChange(event.target.value)}>
                                        {subnav}
                                    </select>
                                </div>
                            </ul>
                        </div> :
                        <ul className="list">
                            {/*<li className="nav-item non-nav subheader__name">*/}
                            <li className="non-nav subheader__name">
                                <h4>Settings</h4>
                            </li>
                            <li className="nav-item">
                                <Link to={category_link}
                                      className={this.props.pageName === 'settings' ? 'nav-link active-inventory' : 'nav-link'}>Category
                                    Management</Link>
                            </li>
                            <li className="nav-item">
                                <Link to={user_link}
                                      className={this.props.pageName === 'users' ? 'nav-link active-inventory' : 'nav-link'}>User
                                    Management</Link>
                            </li>
                            {
                                <li className="nav-item">
                                    <a href="javascript:;" id="clear-data-tag" data-toggle={permission.category !== 2 ? "" : "modal"}
                                       data-target="#clear-data" className="nav-link">Clear Data</a>
                                </li>
                            }
                        </ul>
                    }
                </div>
            </div>
        );
    }
}

export default sub_sidebar;