import React from "react";

class Spinner extends React.PureComponent{
    constructor(props){
        super(props);

    }
    render(){
        const { status } = this.props;

        if(!status){
            return null;
        }
        return(
            <>
                <div class="spinner-grow text-primary spinner-grow-sm" role="status" style={{marginRight: 5, marginLeft: 5}}>
                    <span class="sr-only">Loading...</span>
                </div>
                <div class="spinner-grow text-primary spinner-grow-sm" role="status" style={{marginRight: 5}}>
                    <span class="sr-only">Loading...</span>
                </div>
                <div class="spinner-grow text-primary spinner-grow-sm" role="status" style={{marginRight: 5}}>
                    <span class="sr-only">Loading...</span>
                </div>
            </>
        )
    }
}

export default Spinner;