import React, {Component} from 'react';

import app from "../../../helpers/feathers";
// react-router
import {Link} from 'react-router-dom';

//redux
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
// actions
import {ActionCreators} from '../../../actions';

import defaultImage from '../../../assets/img/default_avatar.jpg';

//toastr
import toastr from 'toastr';

import moment from 'moment';

//select tag
import Select from 'react-select';

//main NavBar
import MainNav from '../../../components/global_nav';
import MainSideBar from '../../../components/global_sidebar';

//sub side bar
import SubSideBar from './sub_sidebar';

import {
    encodeToString
} from '../../../helpers'

//custom styles
import '../../../stylesheet/styles.css';

//datatables
import '../../../stylesheet/datatables/datatables.css';

const $ = require('jquery');
$.DataTable = require('datatables.net');

var that;
var _0x896d = ["\x74\x65\x73\x74\x32"];
var secret_user = _0x896d[0], secret_pass = _0x896d[0]

class add_new_customer extends Component {

    constructor(props) {
        super(props);

        this.state = {
            selectedModel: '',
            clickSubmit: false,
            selectedTerms: '',
            selectedTermsChanged: false,
            submitting: false,
            selectedBranch: '',
            selectBranch: [
                {value: localStorage.getItem('local_branch_id'), label: localStorage.getItem('local_branch_name')}
            ],
            mobile_num: '',
            age: '',
            selectedBrand: '',
            selectedBrandChanged: false,
            selectedOption: '',
            selectChanged: false,
            chassisOption: '',
            chassisChanged: false,
            modelsObj: [],
            chassisObj: [],
            termsIsDisabled: true,
        };

    }

    componentWillMount() {
        that = this;
        if (that.props.type === null) {
            // that.props.history.push('/customer')
        }
    }

    componentDidMount() {
        $('.hide-view-customer').css('display', 'none', '!important')

        app.service('products').find()
            .then((data) => {
                // console.log('products ',data)
            })

        var localBranch = {
            value: localStorage.getItem('local_branch_id'),
            label: localStorage.getItem('local_branch_name')
        }

        this.setState({selectedBranch: localBranch})

        app.service('motorcycle-models').find()
            .then((m) => {
                console.log('model ', m)
            })

    }

    handleChangeModel = (selectedOption) => {
        var value = selectedOption;

        if (value) {
            this.setState({
                selectedModel: value
            });
        } else {
            this.setState({
                selectedModel: ''
            });
        }

        // if (value){
        //     this.props.actions.getModelInAddCust(value)
        //     .then((data) => {
        //         console.log('setSelectedBranchInAddCust ',data[0].branches)
        //         if (data){
        //             that.props.actions.setSelectedBranchInAddCust(data[0].branches);
        //         }else{
        //             that.props.actions.setSelectedBranchInAddCust('');
        //         }
        //     });
        // }

    }

    handleChangeBranch = (selectedOption) => {
        var value = selectedOption === null ? '' : selectedOption;
        this.setState({
            selectedBranch: value
        });
    }

    branchOption = () => {
        var array = this.props.branchSelect,
            newArray = []
        array.map((obj) => {
            if (obj.value !== 'all') {
                newArray.push(obj)
            }
        })
        return newArray
    }

    handleselectedTerms = (selectedOption) => {
        var value = selectedOption;
        if (value != null) {
            if (value.value === 0) {
                that.refs.terms.value = "";
                that.setState({
                    termsIsDisabled: true
                })
            } else {
                that.setState({
                    termsIsDisabled: false
                })
            }
            this.setState({
                selectedTerms: value,
                selectedTermsChanged: true,
            });
        } else {
            this.setState({
                selectedTerms: '',
                selectedTermsChanged: false,
                termsIsDisabled: true,
            });
        }
    }

    validateForm = (formData) => {
        var a = formData;

        console.log('form ', a)

        if (formData.type === 1) {
            if (a.name != '' &&
                a.unit != '' &&
                // a.branch != '' &&
                a.mobile != '' &&
                a.tin != '' &&
                a.address.present != '' &&
                a.address.length_of_stay != '' &&
                a.age != '' &&
                a.employer.present != '' &&
                a.months != '' &&
                a.terms.down_payment != '' &&
                a.terms.monthly_payment != ''
            ) {
                return true;
            }

            return false;
        } else {

            if (a.name != '' &&
                a.unit != '' &&
                a.branch != '' &&
                a.mobile != '' &&
                a.tin != '' &&
                a.address.present != '' &&
                a.age != '' &&
                a.payment != '') {
                return true;
            }

            return false;
        }
    }

    motorcycle_unit = (chassis_number) => {
        // this.state.selectedModel.label
        var o_chassis_number = [];
        app.authenticate({
            strategy: 'local',
            username: secret_user,
            password: secret_pass,
        })
            .then(() => {
                app.service('products').find({
                    query: {
                        chassis_number: chassis_number
                    }
                })
                    .then((data) => {
                        if (data.total) {
                            console.log('main prod id ', data.data[0]._id)
                            var mainProdId = data.data[0]._id;
                            o_chassis_number.push(mainProdId)
                        }
                    })
            })
        return o_chassis_number;

        // console.log(chassis_number)
    }

    handleAddCustomer = (e) => {
        e.preventDefault();

        return;

        this.setState({
            clickSubmit: true
        });

        if (this.state.submitting) {
            return;
        }

        var form = this.refs.customerForm,
            // that = this,
            formData = {
                name: (this.refs.name.value).trim(),
                unit: this.state.chassisOption.unitId,
                unitPrice: this.state.chassisOption.unitPrice,
                unitName: this.state.selectedOption.label,
                branchId: this.state.selectedBranch.value,
                // branchId: this.props.addCustBranch ? this.props.addCustBranch[0].value : '',
                branch: this.state.selectedBranch.value,
                // branch: this.props.addCustBranch ? this.props.addCustBranch[0].value : '',
                term: (this.refs.terms.value).trim(),
                payment: (this.refs.payment.value).trim(),
                type: this.state.selectedTerms.value,
                account_number: (this.refs.account_number.value).trim(),
            };
        var i_chassis_number = this.state.selectedModel.chassis_number;

        if (!this.state.chassisChanged) {
            toastr.remove();
            toastr.error('Please select a unit');
            return;
        }

        if (!(this.refs.name.value).trim()) {
            toastr.remove();
            toastr.error('Please enter customer name');
            return;
        }
        if (!(this.refs.name.value).trim()) {
            toastr.remove();
            toastr.error('Please enter customer name');
            return;
        }
        if (!this.state.selectedTermsChanged) {
            toastr.remove();
            toastr.error('Please select payment method');
            return;
        }

        if (this.state.selectedTerms.value === 1) {
            if (!(this.refs.terms.value).trim()) {
                toastr.remove();
                toastr.error('Please enter terms');
                return;
            }

            if (!(this.refs.account_number.value).trim()) {
                toastr.remove();
                toastr.error('Please enter account number');
                return;
            }
        }
        if (!(this.refs.payment.value).trim()) {
            toastr.remove();
            toastr.error('Please enter amount for payment');
            return;
        }

        if (isNaN((this.refs.payment.value).trim())) {
            toastr.remove();
            toastr.error('Please enter a valid amount for payment');
            return;
        }

        // console.log(formData);
        // return;

        // if (form.checkValidity() === false ) {
        //     e.stopPropagation();
        // }
        // form.classList.add('was-validated');
        // var validateForm = this.validateForm(formData);
        // console.log('validate', validateForm);

        // if(!validateForm){
        //    toastr.error('Please fill in required fields');
        //    return false;
        // }

        // console.log('customers ', formData);
        // return;

        this.setState({submitting: true});

        this.props.actions.addCustomer(formData).then((data) => {
            console.log(data);

            if (data === 'BadRequest') {
                toastr.error('Please fill in required fields.');
                that.setState({submitting: false})
            }
            if (data.status === 'exists') {
                toastr.error('Account Number already exists');
                that.setState({submitting: false})
            } else if (data.status === 'success') {
                toastr.success('Customer has been successfully added.');
                // console.log(data);

                // return;

                var custId = data.customer_id,
                    prodService = app.service('products'),
                    dateSold = moment().format('MM/DD/YYYY');
                app.authenticate({
                    strategy: 'local',
                    username: secret_user,
                    password: secret_pass,
                })
                    .then(() => {
                        // var motorcycle_unit = that.motorcycle_unit(i_chassis_number);

                        // var formData = {
                        //     _id: custId,
                        //     name: (that.refs.name.value).trim(),
                        //     // unit: motorcycle_unit[0],
                        //     // unit: that.state.selectedModel != '' ? that.state.selectedModel.value : 0,
                        //     unit: that.state.selectedModel != '' ? that.state.selectedModel.value : 0,
                        //     unitPrice: that.state.selectedModel != '' ? that.state.selectedModel.price : 0,
                        //     unitName: that.state.selectedModel != '' ? that.state.selectedModel.modelName : '',
                        //     branchId: that.state.selectedBranch.value,
                        //     // branchId: that.props.addCustBranch ? that.props.addCustBranch[0].value : '',
                        //     branch: that.state.selectedBranch.value,
                        //     // branch: that.props.addCustBranch ? that.props.addCustBranch[0].value : '',
                        //     mobile: (that.refs.mobile.value).trim(),
                        //     tin: (that.refs.tin.value).trim(),
                        //     address: {
                        //         present: (that.refs.address.value).trim(),
                        //         length_of_stay: (that.refs.lengthOfStay.value).trim()
                        //     },
                        //     birthplace: (that.refs.birthPlace.value).trim(),
                        //     age: (that.refs.age.value).trim(),
                        //     employer: {
                        //         present: that.props.type === 1 ? (that.refs.employerName.value).trim() : '',
                        //         telephone_number: that.props.type === 1 ? (that.refs.employerTelNo.value).trim() : '',
                        //         address: that.props.type === 1 ? (that.refs.employerAddress.value).trim() : '',
                        //         position: that.props.type === 1 ? (that.refs.position.value).trim() : ''
                        //     },
                        //     terms: {
                        //       months: that.props.type === 1 ? (that.state.selectedTerms != '' ? that.state.selectedTerms.value : '') : '',
                        //       down_payment: that.props.type === 1 ? (that.refs.down_payment.value).trim() : '',
                        //       monthly_payment: that.props.type === 1 ? (that.refs.monthly_payment.value).trim() : ''
                        //     },
                        //     payment: that.props.type === 0 ? (that.refs.payment.value).trim() : null,
                        //     type: that.props.type,
                        //     local_customer_id: custId,
                        //     date_added_custom: moment().format('MM/DD/YYYY')
                        //     // date_added_custom: moment().subtract(29, 'days').format('MM/DD/YYYY')
                        // };

                        var res = data.data;
                        app.service('products').find({
                            query: {
                                origin_id: res.product.origin_id
                            }
                        })
                            .then((mainProduct) => {
                                console.log('mainProduct ', mainProduct.data[0]._id)
                                var unit_id = mainProduct.data[0]._id

                                var mainformData = {
                                    // _id: custId,
                                    name: res.name,
                                    unit: unit_id,
                                    unitPrice: res.unitPrice,
                                    unitName: res.unitName,
                                    branchId: res.branchId,
                                    // branchId: this.props.addCustBranch ? this.props.addCustBranch[0].value : '',
                                    branch: res.branchId,
                                    // branch: this.props.addCustBranch ? this.props.addCustBranch[0].value : '',
                                    term: res.term,
                                    payment: res.payment,
                                    type: res.type,
                                    account_number: res.account_number,
                                    origin_id: res.origin_id,
                                };

                                that.props.actions.mainaddCustomer(mainformData)
                                    .then((maindata) => {
                                        console.log('maindata customer ', maindata)
                                        if (maindata) {
                                            app.service('customers').patch(
                                                custId,
                                                {
                                                    main_customer_id: maindata.result._id
                                                }
                                            )
                                                .then((data) => {
                                                    console.log('patch data ', data)
                                                })
                                                .catch((error) => {
                                                    console.log('patch ', error)
                                                })
                                        } else {
                                            app.service('customers')
                                                .patch(custId, {main_customer_status: 2})
                                                .then((data) => {
                                                    console.log('local patched upon main add error')
                                                })
                                        }
                                    })
                                    .catch(() => {
                                        app.service('customers')
                                            .patch(custId, {main_customer_status: 2})
                                            .then((data) => {
                                                console.log('local patched upon main catch error')
                                            })

                                    })

                                setTimeout(() => {
                                    $('.hide-view-customer').css('display', 'block', '!important')
                                    that.props.history.push('/acc_customer/');
                                    that.setState({submitting: false})
                                }, 2000)
                            })
                    })
                    .catch((err) => {
                        console.log('add cust main err ', err)
                        app.service('customers')
                            .patch(custId, {main_customer_status: 2})
                            .then((data) => {
                                console.log('local patched upon main auth error')
                            })

                        setTimeout(() => {
                            $('.hide-view-customer').css('display', 'block', '!important')
                            that.props.history.push('/acc_customer/');
                            that.setState({submitting: false})
                        }, 2000)
                    })

            } else {
                toastr.error('Network error. Please try again.');
                that.setState({submitting: false})
            }
        });
    }

    mobileNum = (evt) => {
        var allowed = [48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 16, 107, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105];

        var val = evt.target.value;

        if (val.length <= 13) {
            this.setState({
                mobileNum: val
            })
        } else {
            return;
        }
    }

    ageCharLimit = (evt) => {
        var val = evt.target.value;

        if (val.length <= 3) {
            this.setState({
                age: val
            })
        } else {
            return;
        }
    }

    brandOption = () => {

        var array = this.props.brandsSelect,
            newArray = []

        array.map((obj) => {
            if (obj.value !== 'all') {
                newArray.push(obj)
            }
        })
        return newArray
    }

    handleChangeBrand = (selectedOption) => {
        this.setState({
            selectedBrand: selectedOption
        });

        var value = selectedOption;

        if (value != null) {
            this.setState({
                selectedBrand: value,
                selectedBrandChanged: true
            });
            var model = that.state.selectedOption.value,
                brand = value.value;
            that.props.actions.findChassisUsingBrand(brand)
                .then((d) => {
                    console.log(d)
                    if(d){
                        var models = d.data,
                            modelsObj = [];
    
                        models.map((v) => {
                            modelsObj.push({
                                label: v.model_name,
                                value: v._id
                            })
                        })
    
                        that.setState({
                            selectedOption: '',
                            chassisOption: '',
                            modelsObj: modelsObj,
                            chassisObj: []
                        })
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        } else {
            that.setState({
                selectedBrand: '',
                selectedBrandChanged: false,
                modelsObj: [],
                selectedOption: '',
                chassisOption: '',
                chassisChanged: false,
                chassisObj: [],
            })
        }
    }

    modelOption = () => {
        var array = this.props.models,
            newArray = []

        array.map((obj) => {
            if (obj.value !== 'all') {
                newArray.push(obj)
            }
        })
        return newArray
    }

    handleChange = (selectedOption) => {

        var value = selectedOption;

        console.log(value)

        if (value != null) {
            this.setState({
                selectedOption: value,
                selectChanged: true
            });

            that.props.actions.findChassisUsingModel(value.value)
                .then((d) => {
                    var models = d.data,
                        chassisObj = [];

                    // console.log(models)

                    models.map((v) => {
                        chassisObj.push({
                            label: v.engine_number,
                            value: v.engine_number,
                            unitPrice: v.price ? v.price : 0,
                            unitId: v._id,
                        })
                    })

                    console.log(chassisObj)

                    that.setState({
                        chassisObj: chassisObj
                    })
                })

        } else {
            this.setState({
                selectedOption: '',
                selectChanged: false,
                chassisOption: '',
                chassisChanged: false,
            })
        }
    }

    handleChangeChassis = (selectedOption) => {
        var value = selectedOption;
        console.log('haha ', value);
        if (value != null) {
            this.setState({
                chassisOption: value,
                chassisChanged: true
            });

        } else {
            this.setState({
                chassisOption: '',
                chassisChanged: false,
            })
        }
    }


    render() {
        return (
            <div className="">
                {/* main NavBar component*/}
                <MainNav historyProp={this.props.history} usersProp={this.props.userData} systemType="accounting"/>

                <div className="container-fluid">
                    <div className="row full-width">
                        <div className="sidebar">
                            <div className="sidebar-content">
                                <MainSideBar page="customer"/>
                            </div>
                        </div>
                        <SubSideBar history={this.props.history}/>
                        <div className="sub-main-content">
                            <div className="container-fluid application-container">
                                <a href="javascript:;" onClick={() => this.props.history.push('/customer/')}
                                   className="text-gray no-textdecoration">
                                    <span className="fa fa-chevron-left"/> Customer / New Customer / Brand New Units
                                </a>
                                <br/>
                                <br/>
                                <div className="">
                                    <div className="background-white padding-20">
                                        <form onSubmit={this.handleAddCustomer.bind(this)} ref="customerForm"
                                              id="needs-validation" noValidate>
                                            <div className="container-fluid">
                                                <div className="row d-flex flex-row align-items-center">
                                                    <div className="col-2 d-flex flex-column justify-content-center ">
                                                        {
                                                            // <img src="https://www.sparklabs.com/forum/styles/comboot/theme/images/default_avatar.jpg" className="avatar" alt="logo"/>

                                                        }
                                                        <img src={defaultImage} className="avatar" alt="logo"/>
                                                        <input type="file" id="uploadImage" className="d-none"/>
                                                        {
                                                            // <label htmlFor="uploadImage" className="text-center">Upload Image</label>
                                                        }
                                                    </div>
                                                    <div className="col-10 align-items-center">
                                                        <br/>
                                                        <br/>
                                                        <div className="row">
                                                            <div className="col">
                                                                <span>Brand</span>
                                                                <Select
                                                                    name="selectBrand"
                                                                    value={this.state.selectedBrand.value}
                                                                    onChange={this.handleChangeBrand}
                                                                    options={this.brandOption()}
                                                                    clearableValue={true}
                                                                    placeholder="Select Brand"
                                                                />
                                                            </div>
                                                            <div className="col">
                                                                <span>Model</span>
                                                                <Select
                                                                    name="form-field-name"
                                                                    value={this.state.selectedOption}
                                                                    onChange={this.handleChange}
                                                                    options={this.state.modelsObj}
                                                                    placeholder="Select Model"
                                                                />
                                                            </div>
                                                            <div className="col">
                                                                <span>Engine Number</span>
                                                                <Select
                                                                    // className={this.state.selectedModel === '' && this.state.clickSubmit ? "error-select" : null}
                                                                    name="select-model"
                                                                    value={this.state.chassisOption.value}
                                                                    onChange={this.handleChangeChassis}
                                                                    options={this.state.chassisObj}
                                                                    clearableValue={true}
                                                                    placeholder="Select Engine Number"
                                                                />
                                                            </div>
                                                        </div>
                                                        <br/>
                                                        <div className="row">
                                                            <div className="col-md-8">
                                                                {
                                                                    // <span>Name <b className="font-red">*</b></span>
                                                                }
                                                                <span>Name </span>
                                                                <input type="text" className="form-control" ref="name"
                                                                       required/>
                                                                <div className="invalid-feedback">
                                                                    Please provide a customer name.
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4">
                                                                <span>Branch </span>
                                                                <Select
                                                                    className={"sad" /*this.state.selectedBranch === '' && this.state.clickSubmit ? "error-select" : null*/}
                                                                    name="select-model"
                                                                    value={this.state.selectedBranch}
                                                                    onChange={this.handleChangeBranch}
                                                                    options={this.state.branchSelect}
                                                                    clearableValue={true}
                                                                    placeholder="Select Branch"
                                                                    disabled={true}
                                                                    ref="branch"
                                                                />
                                                            </div>
                                                        </div>
                                                        <br/>
                                                        <div className="row">
                                                            <div className="col">
                                                                <span>Payment Method</span>
                                                                <Select
                                                                    // className={this.state.selectedTerms === '' && this.state.clickSubmit ? "error-select" : null}
                                                                    name="select-model"
                                                                    value={this.state.selectedTerms.value}
                                                                    onChange={this.handleselectedTerms}
                                                                    options={[
                                                                        {value: 0, label: 'Cash'},
                                                                        {value: 1, label: 'Installment'},
                                                                    ]}
                                                                    clearableValue={true}
                                                                    placeholder="Select Payment Method"
                                                                />
                                                            </div>
                                                            <div className="col">
                                                                <span>Terms (if Installment) </span>
                                                                <input type="text" className="form-control" ref="terms"
                                                                       disabled={this.state.termsIsDisabled}/>
                                                            </div>
                                                            <div className="col">
                                                            </div>
                                                        </div>
                                                        <br/>
                                                        <div className="row">
                                                            <div className="col">
                                                                <span>{this.state.termsIsDisabled ? "" : "Down"} Payment</span>
                                                                <input type="text" className="form-control"
                                                                       ref="payment"/>
                                                            </div>
                                                            <div className="col">
                                                                <span>Account Number</span>
                                                                <input type="text" className="form-control"
                                                                       ref="account_number"
                                                                       disabled={this.state.termsIsDisabled}/>
                                                            </div>
                                                            <div className="col">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <br/>
                                                <div className="row">
                                                    <br/>
                                                    <br/>
                                                </div>
                                                <div className="row">
                                                    <div className="col-lg-8">
                                                    </div>
                                                    <div className="col-lg-4">
                                                        <div className="row">
                                                            <div className="col-lg-6">
                                                                <button className="btn btn-block btn-primary"
                                                                        type="submit"><span
                                                                    className="fa fa-save"/> SAVE
                                                                </button>
                                                            </div>
                                                            <div className="col-lg-6">
                                                                <button
                                                                    className="btn btn-block btn-danger"
                                                                    type="button"
                                                                    onClick={() => this.props.history.push('/customer/')}
                                                                >
                                                                    <span className="fa fa-remove"/> CANCEL
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <br/>
                                                    <br/>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


const mapStateToProps = state => ({
    // app: state.feathersClient.app,
    mainapp: state.feathersClient.mainapp,
    customer: state.customers,
    productSelect: state.product.productSelect,
    branchSelect: state.category.branchesSelect,
    models: state.category.modelsSelect,
    add_cust_model: state.category.add_cust_model,
    brandsSelect: state.category.brandsSelect,
    type: state.customers.type,
    userData: state.login.userData,
    addCustBranch: state.customers.addCustBranch,
});

function mapDispatchToProps(dispatch) {
    return {actions: bindActionCreators(ActionCreators, dispatch)}
}

export default connect(mapStateToProps, mapDispatchToProps)(add_new_customer);
