const initialState = {
    app : '',
    feathers_host: ''
}

export default function feathersClient(state = initialState, action) {
  switch (action.type) {

    default:
      return state
  }
}