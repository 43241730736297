import React, {Component} from "react";

// react-router
import {Link} from "react-router-dom";

class sub_sidebar extends Component {
    componentDidMount() {
        var pathName = this.props.history.location.pathname;
        let permission = this.props.userPermission;
    }

    createReport = () => {
    };

    handleChange(value) {
        this.props.history.push(`${value}`);
    }

    render() {
        var pathName = this.props.history.location.pathname;
        let permission = this.props.userPermission,
            inventory = permission
                ? permission.accounting === 0
                    ? "#"
                    : "/report"
                : "/report",
            total_paid = permission
                ? permission.accounting === 0
                    ? "#"
                    : "/acc_total_paid"
                : "/acc_total_paid",
            total_paid_secondhand = permission
                ? permission.accounting === 0
                    ? "#"
                    : "/acc_total_paid_secondhand"
                : "/acc_total_paid_secondhand",
            total_ma = permission
                ? permission.accounting === 0
                    ? "#"
                    : "/acc_total_ma"
                : "/acc_total_ma",
            total_ma_secondhand = permission
                ? permission.accounting === 0
                    ? "#"
                    : "/acc_total_ma_secondhand"
                : "/acc_total_ma_secondhand",
            customer_who_paid_secondhand = permission
                ? permission.accounting === 0
                    ? "#"
                    : "/acc_customer_paid_reports_secondhand"
                : "/acc_customer_paid_reports_secondhand",
            total_current_monthly = permission
                ? permission.accounting === 0
                    ? "#"
                    : "/acc_total_current_monthly"
                : "/acc_total_current_monthly",
            customer_per_area_secondhand = permission
                ? permission.accounting === 0
                    ? "#"
                    : "/acc_customer_per_area_secondhand"
                : "/acc_customer_per_area_secondhand",
            // schedule_payments = permission
            //   ? permission.accounting === 0
            //     ? "#"
            //     : "/acc_schedule_payments"
            //   : "/acc_schedule_payments",
            computing_penalties = permission
                ? permission.accounting === 0
                    ? "#"
                    : "/acc_computing_penalties"
                : "/acc_computing_penalties",
            schedule_of_collectibles = permission
                ? permission.accounting === 0
                    ? "#"
                    : "/acc_schedule_of_collectibles"
                : "/acc_schedule_of_collectibles",
            account_no_payment = permission
                ? permission.accounting === 0
                    ? "#"
                    : "/acc_account_no_payment"
                : "/acc_account_no_payment",
            customer_per_area = permission
                ? permission.accounting === 0
                    ? "#"
                    : "/acc_customer_per_area"
                : "/acc_customer_per_area",
            total_customers_paid = permission
                ? permission.accounting === 0
                    ? "#"
                    : "/acc_total_customers_paid"
                : "/acc_total_customers_paid",
            new_customers = permission
                ? permission.accounting === 0
                    ? "#"
                    : "/new_customers"
                : "/new_customers",
            total_customers_paid_secondhand = permission
                ? permission.accounting === 0
                    ? "#"
                    : "/acc_total_customers_paid_secondhand"
                : "/acc_total_customers_paid_secondhand",
            account_no_payment_secondhand = permission
                ? permission.accounting === 0
                    ? "#"
                    : "/acc_account_no_payment_secondhand"
                : "/acc_account_no_payment_secondhand",
            sales = permission
                ? permission.accounting === 0
                    ? "#"
                    : "/acc_sales_report"
                : "/acc_sales_report",
            no_clearance = permission
                ? permission.accounting === 0
                    ? "#"
                    : "/acc_no_clearance"
                : "/acc_no_clearance",
            with_warranty = permission
                ? permission.accounting === 0
                    ? "#"
                    : "/acc_with_warranty"
                : "/acc_with_warranty",
            tba = permission
                ? permission.accounting === 0
                    ? "#"
                    : "/acc_tba"
                : "/acc_tba",
            total_cost = permission
                ? permission.accounting === 0
                    ? "#"
                    : "/acc_total_cost"
                : "/acc_total_cost",
            total_cost_sold = permission
                ? permission.accounting === 0
                    ? "#"
                    : "/acc_total_cost_sold"
                : "/acc_total_cost_sold",
            new_sh_customers = permission
                ? permission.accounting === 0
                    ? "#"
                    : "/new_sh_customers"
                : "/new_sh_customers",
            repo_customers_report = permission
                ? permission.accounting === 0
                    ? "#"
                    : "/repo_customers_report"
                : "/repo_customers_report",

            fullypaid_customers_report = permission
                ? permission.accounting === 0
                    ? "#"
                    : "/fullypaid_customers_report"
                : "/fullypaid_customers_report";

            let report_sales_report = permission ? (permission.accounting === 0 ? "#" : "/report_sales_report")  : "/report_sales_report"

        let {pageTitle} = this.props;
        let x = {
            subnav: [
                {id: total_paid, name: 'Total Paid'},
                {id: total_ma, name: 'Total Current Monthly Amortization'},
                {id: account_no_payment, name: 'Account No Payment (Brand New)'},
                {id: account_no_payment_secondhand, name: 'Account No Payment (Secondhand)'},
                {id: customer_per_area, name: 'Customer List Per Area'},
                {id: total_customers_paid, name: 'Customer Who Paid'},
                {id: new_customers, name: 'New Customers'},
                {id: fullypaid_customers_report, name: 'Fully Paid Customers'},
                {id: repo_customers_report, name: 'Repossessed Unit Customer'},
            ]
        };
        let subnav = x.subnav.length > 0
            && x.subnav.map((item, i) => {
                if (this.props.history.location.pathname === item.id) {
                    return (
                        <option key={i} value={item.id} selected>{item.name}</option>
                    )
                } else {
                    return (
                        <option key={i} value={item.id}>{item.name}</option>
                    )
                }

            }, this);
        return (
            <div className="print-not-visible">
                <div className="sub-sidebar web-acc-reports__wrapper print-not-visible">
                    {/*<ul className="nav flex-column list">*/}
                    <ul className="list">
                        {/*<li className="non-nav-item">*/}
                        <li className="non-nav subheader__name">
                            <h4>Reports</h4> <h5 className="">(Brand New)</h5>
                        </li>
                        <li className={pageTitle === "REPORTS_TOTAL_PAID" ? "nav-item active" : "nav-item"}>
                            <Link
                                className={
                                    pathName === "/acc_total_paid" ||
                                    pathName === "/acc_total_paid"
                                        ? "nav-link active-inventory"
                                        : "nav-link"
                                }
                                to={total_paid}
                            >
                                Total Paid
                            </Link>
                        </li>
                        <li className={pageTitle === "REPORTS_MNTHLY_AMRTZTION" ? "nav-item active" : "nav-item"}>
                            <Link
                                className={
                                    pathName === "/acc_total_ma" ||
                                    pathName === "/acc_total_ma"
                                        ? "nav-link active-inventory"
                                        : "nav-link"
                                }
                                to={total_ma}
                            >
                                Total Current Monthly Amortization
                            </Link>
                        </li>

                        <li className={pageTitle === "REPORTS_NO_PAYEMENT" ? "nav-item active" : "nav-item"}>
                            <Link
                                className={
                                pathName === "/acc_account_no_payment" ||
                                    pathName === "/acc_account_no_payment"
                                        ? "nav-link active-inventory"
                                        : "nav-link"
                                }
                                to={account_no_payment}
                            >
                               <span className='tab-show-wrapper'> Account No Payment (<small>Brand New</small>)</span>
                               <span className='tab-hide-wrapper'> Account No Payment <br/> (<small>Brand New</small>)</span>
                            </Link>
                        </li>
                        <li className={pageTitle === "REPORTS_SH_NO_PAYMENT" ? "nav-item active" : "nav-item"}>
                            <Link
                                className={
                                pathName === "/acc_account_no_payment_secondhand" ||
                                    pathName === "/acc_account_no_payment_secondhand"
                                        ? "nav-link active-inventory"
                                        : "nav-link width-38-percent"
                                }
                                to={account_no_payment_secondhand}
                            >
                                <span className='tab-show-wrapper'> Account No Payment (<small>Secondhand</small>)</span>
                                <span className='tab-hide-wrapper'> Account No Payment <br/> (<small>Secondhand</small>)</span>
                            </Link>
                        </li>
                        <li className={pageTitle === "REPORTS_SOLD_UNITS" ? "nav-item active" : "nav-item"}>
                            <Link
                                className={
                                pathName === "/report_sales_report" ||
                                    pathName === "/report_sales_report"
                                        ? "nav-link active-inventory"
                                        : "nav-link width-38-percent"
                                }
                                to={report_sales_report}
                            >
                                <span className='tab-show-wrapper'> Sold Units</span>
                                <span className='tab-hide-wrapper'> Sold Units</span>
                            </Link>
                        </li>

                        <li className={pageTitle === "REPORTS_PER_AREA" ? "nav-item active" : "nav-item"}>
                            <Link
                                className={
                                    pathName === "/acc_customer_per_area" ||
                                    pathName === "/acc_customer_per_area"
                                        ? "nav-link active-inventory"
                                        : "nav-link"
                                }
                                to={customer_per_area}
                            >
                                Customer List Per Area
                            </Link>
                        </li>
                        <li className={pageTitle === "REPORTS_PAID_CUST" ? "nav-item active" : "nav-item"}>
                            <Link
                                className={
                                    pathName === "/acc_total_customers_paid" ||
                                    pathName === "/acc_total_customers_paid"
                                        ? "nav-link active-inventory"
                                        : "nav-link"
                                }
                                to={total_customers_paid}
                            >
                                Customer Who Paid
                            </Link>
                        </li>
                        <li className={pageTitle === "REPORTS_NEW_CUST" ? "nav-item active" : "nav-item"}>
                            <Link
                                className={
                                    pathName === "/new_customers" ||
                                    pathName === "/new_customers"
                                        ? "nav-link active-inventory"
                                        : "nav-link"
                                }
                                to={new_customers}
                            >
                                New Customers
                            </Link>
                        </li>
                        <li className={pageTitle === "REPORTS_FULLY_PAID" ? "nav-item active" : "nav-item"}>
                            <Link
                                className={
                                    pathName === "/fullypaid_customers_report" ||
                                    pathName === "/fullypaid_customers_report"
                                        ? "nav-link active-inventory"
                                        : "nav-link"
                                }
                                to={fullypaid_customers_report}
                            >
                                Fully Paid Customers
                            </Link>
                        </li>
                        <li className={pageTitle === "REPORTS_REPO_CUSST" ? "nav-item active" : "nav-item"}>
                            <Link
                                className={
                                    pathName === "/repo_customers_report" ||
                                    pathName === "/repo_customers_report"
                                        ? "nav-link active-inventory"
                                        : "nav-link"
                                }
                                to={repo_customers_report}
                            >
                                Repossessed Unit Customer
                            </Link>
                        </li>
                    </ul>
                </div>
                <div className="mobile-acc-reports__wrapper">
                    <ul className="list">
                        <li className="non-nav subheader__name">
                            <h4>Reports</h4>
                        </li>
                        <div className="mobile-dropdown__wrapper">
                            <select className="dropdown__select"
                                    onChange={event => this.handleChange(event.target.value)}>
                                {subnav}
                            </select>
                        </div>
                    </ul>
                </div>
            </div>
        );
    }
}

export default sub_sidebar;
