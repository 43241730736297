import React, {Component} from 'react';

//main NavBar
import MainNav from '../../../components/global_nav';
import MainSideBar from '../../../components/global_sidebar';

// moment
import moment from 'moment';
import Cleave from 'cleave.js/react';

import ReactDataSheet from 'react-datasheet';

//toastr
import toastr from 'toastr';
import Toggle from 'react-bootstrap-toggle';

//sub side bar
import SubSideBar from './sub_sidebar';

//custom styles
import '../../../stylesheet/styles.css';

import {
    numberWithCommas
} from '../../../helpers/';

//redux
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
// actions
import {ActionCreators} from '../../../actions';

const $ = require('jquery');
$.DataTable = require('datatables.net');

let that;

class view_inventory_secondhand extends Component {


    constructor(props) {
        super(props);

        this.state = {
            activeTab: 'home',
            modelName: '',
            payment: [],
            paymentData: [],
            form: {},
            currentBalance: 0,
            add: false,
            delinquent: false,
            saving: false,
            toggleActive: false
        };
    }

    componentWillMount() {
        that = this;
    }

    componentDidMount() {
        $('.tbl-inventory').addClass('not-visible');
        $('.table-due-date').DataTable({
            "paging": false,
            "pageLength": 36,
            "searching": false,
            "ordering": false,
            "info": false,
            "sDom": '<center<"bottom"<t>p><"clear">>'
        });
        const product = this.props.product;
    }

    render() {
        const product = this.props.product;

        return (
            <div className="">
                {/* main NavBar component*/}
                <MainNav historyProp={this.props.history} usersProp={this.props.userData}/>

                <div className="container-fluid">
                    <div className="row full-width">
                        <div className="sidebar">
                            <div className="sidebar-content">
                                <MainSideBar page="inventory"/>
                            </div>
                        </div>
                        <SubSideBar history={this.props.history} userPermission={this.props.userPermission}/>
                        <div className="sub-inv-main-content">
                            <div className="container-fluid">
                                {
                                    //<div className="d-flex flex-row justify-content-center content-header">
                                    //<button className="btn application-btn">APPROVE</button>
                                    //<button className="btn application-btn">REJECT</button>
                                    //</div>
                                }
                                <div className="application-container">
                                    <br/>
                                    <a href="javascript:;"
                                       onClick={() => this.props.history.goBack()}
                                       className="text-gray no-textdecoration breadcrumb-inv">
                                        <span className="fa fa-chevron-left"/> Inventory
                                        / {product.model ? product.model.name : ''}
                                    </a>
                                    <br/>
                                    <br/>
                                    {/* <ul className="nav nav-tabs" role="tablist">
                                    <li role="presentation" className={this.state.activeTab === 'home' ? 'active' : ''} onClick={() => this.navigateTab('home')}><a href="javascript:;" aria-controls="home" role="tab" data-toggle="tab">PROFILE</a></li>
                                   <li role="presentation" className={this.state.activeTab === 'payments' ? 'active' : ''} onClick={() => this.navigateTab('payments')}><a href="javascript:;" aria-controls="payments" role="tab" data-toggle="tab">PAYMENTS</a></li>
                                </ul>*/}

                                    <div className="tab-content background-white">
                                        <div role="tabpanel"
                                             className={"hide-view-customer tab-pane view-cust " + (this.state.activeTab === 'home' ? 'active' : '')}
                                             id="home">
                                            <div className="container-fluid">
                                                <div className="personal-info">
                                                    <div className="row">
                                                        <h5><strong>UNIT INFORMATION</strong></h5>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col">
                                                            <span><strong>Model:</strong> {product.model ? product.model.name : ''}</span>
                                                        </div>
                                                        <div className="col">
                                                            <span><strong>Customer Name:</strong> {product.customer ? product.customer : ''}</span>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col">
                                                            <span><strong>Engine Number:</strong> {product.engine_number ? product.engine_number : ''}</span>
                                                        </div>
                                                        <div className="col">
                                                            <span><strong>Account Number:</strong> {product.account_number ? product.account_number : ''}</span>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col">
                                                            <span><strong>Selling Price:</strong> {product.selling_price ? numberWithCommas(product.selling_price) : ''}</span>
                                                        </div>
                                                        <div className="col">
                                                            <span><strong>Date Foreclosed:</strong> {product.date_foreclosed ? moment(product.date_foreclosed).format('MM/DD/YYYY') : ''} </span>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col">
                                                            <span><strong>Discount(Percentage):</strong> {product.discount ? product.discount + '%' : ''}</span>
                                                        </div>
                                                        <div className="col">
                                                            <span><strong>Remarks:</strong> {product.remarks ? product.remarks : ''}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <br/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


const mapStateToProps = state => ({
    customer: state.customers.customerInfo,
    payment: state.customers.customerPayments,
    model: state.category.modelsList,
    brand: state.category.brandsList,
    userData: state.login.userData,
    userPermission: state.login.userPermission,
    product: state.product.productInfo,
});

function mapDispatchToProps(dispatch) {
    return {actions: bindActionCreators(ActionCreators, dispatch)}
}

export default connect(mapStateToProps, mapDispatchToProps)(view_inventory_secondhand);