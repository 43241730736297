import React, { Component } from 'react';
import app from "../../../helpers/feathers";

// react-router
import { Link } from 'react-router-dom';

//redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// actions
import { ActionCreators } from '../../../actions';

//main NavBar
import MainNav from '../../../components/global_nav';
import MainSideBar from '../../../components/global_sidebar';
import SyncDataModal from '../../../components/sync_data_modal';

//sub side bar
import SubSideBar from './sub_sidebar';
import SelectUnitType from './select_customer_modal';

//custom styles
import '../../../stylesheet/styles.css';

//datatables
import '../../../stylesheet/datatables/datatables.css';

const $ = require('jquery');
$.DataTable = require('datatables.net');

var g_branch = localStorage.getItem('_branch_secret'), that;
var _0x896d=["\x74\x65\x73\x74\x32"];var secret_user=_0x896d[0],secret_pass=_0x896d[0]

class customer extends Component {

    constructor(props) {
        super(props);

        this.state = {
            data: []
        };
    }


    componentWillMount() {
        that = this;
        this.getAllCustomers();

        // products select options
        that.props.actions.getProducts().then((data) => {
            that.props.actions.setProducts(data);
        });

        //inventory
        this.props.actions.getAllProducts().then((data) => {
            if (data) {
                that.props.actions.setAllProducts(data);
            }

        });

        // check connection to main server
        // that.props.mainapp.authenticate({
        //     strategy: 'local',
        //     'username': secret_user,
        //     'password': secret_pass
        // })
        //     .then(() => {
        //         that.props.actions.connectedToMainServer(true);
        //     })
        //     .catch(() => {
        //         that.props.actions.connectedToMainServer(false);
        //     })

        // models for secondhand units in autosuggest
        app.service('motorcycle-models').find()
            .then((data) => {
                var d = data.data,
                    models = [];

                d.map((v) => {
                    models.push({
                        name: v.model_name
                    })
                })

                that.props.actions.setModelsForSecondhand(models);
            })

        app.service('secondhand').find()
            .then((res) => {
                var d = res.data;

                d.map((v) => {
                    // that.props.app.service('secondhand').remove(v._id)
                })
            })

        app.service('customer-secondhand-unit').find()
            .then((res) => {
                const d = res.data;
                console.log('customer-secondhand-unit ', d);
                d.map((v) => {
                    // that.props.app.service('customer-secondhand-unit').remove(v._id)
                })
            })
    }


    componentDidMount() {
        const applicationTbl = $('.tbl-customer').DataTable({
            data: this.props.customers,
            "columnDefs": [
                {
                    "visible": false, "targets": 3
                },
                {
                    targets: [2],
                    orderable: false,
                    width: '10%'
                }
            ],
            columns: [
                {title: "NAME"},
                {title: "ACCOUNT NO."},
                {title: "ACTION"}
            ],
            "sDom": '<"bottom"<t>ip><"clear">'
        });


        $('.search').keyup(function () {
            applicationTbl.search($(this).val()).draw();
        });

        $('.tbl-customer').on('click', 'button.view', function () {
            var data = applicationTbl.row($(this).parents('tr')).data(),
                name = (data[0]).replace(/ /g, "");
            that.props.actions.setCustomerInfo(data[3]);
            // that.props.history.push('/customer/'+name);
            that.props.history.push('/view_customer/');
        });

        const custService = app.service('customers');

        custService.on('created', () => {
            that.getAllCustomers();
        });
        custService.on('patched', () => {
            that.getAllCustomers();
        });

    }


    getAllCustomers = () => {
        this.props.actions.getTermsCustomers().then((data) => {
            that.props.actions.setTermsCustomers(data);
            that.updateData(data);
        });
    }

    updateData = (data) => {
        this.setState({
            data: data
        });

        const table = $('.tbl-customer').DataTable();
        table.clear();
        table.rows.add(data);
        table.draw();
    }

    permissionCustomer = () => {
        var permission = this.props.userPermission;

        if (permission.customer === 0){
            return "not-visible"
        }else{
            return "";
        }
    }

    render() {
        return (
            <div className="">

                {/* main NavBar component*/}
                <MainNav historyProp={this.props.history} usersProp={this.props.userData} />

                <SyncDataModal/>
                <SelectUnitType history={this.props.history} />

                <div className="container-fluid">
                    <div className="row full-width">
                        <div className="sidebar">
                            <div className="sidebar-content">
                                <MainSideBar page="customer"/>
                            </div>
                        </div>
                        <SubSideBar history={this.props.history}/>
                        {/*{*/}
                        {/*    this.props.userPermission.customer === 0 ?*/}
                        {/*        <div className="sub-main-content">*/}
                        {/*            <div className="no-access-div">*/}
                        {/*                <div className="col-md-12">*/}
                        {/*                    <h1>Access Denied</h1>*/}
                        {/*                </div>*/}
                        {/*            </div>*/}
                        {/*        </div>*/}
                        {/*        : null*/}
                        {/*}*/}
                        <div>
                            <div className="sub-main-content">
                                <div className="d-flex flex-row justify-content-left content-header">
                                    <input type="search" className="form-control form-control-lg align-items-center search" placeholder="&#xF002; Search" />
                                </div>
                                <div className="container-fluid">
                                    <div className="application-container">
                                        <div className="form-inline">
                                            <h4>Customers with Brand New Units</h4>
                                        </div>
                                        <div>
                                            <br />
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <table className="table tbl-customer background-white tbl-mobile"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


const mapStateToProps = state => ({
    app: state.feathersClient.app,
    customers: state.customers.customersTerms,
    userData: state.login.userData,
    userPermission: state.login.userPermission,
});

function mapDispatchToProps(dispatch) {
    return {actions: bindActionCreators(ActionCreators, dispatch)}
}

export default connect(mapStateToProps, mapDispatchToProps)(customer);
