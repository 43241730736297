import React, {Component} from "react";
import app from "../../../helpers/feathers";
//redux
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
// actions
import {ActionCreators} from "../../../actions";

//main NavBar
import MainNav from "../../../components/global_nav";
import MainSideBar from "../../../components/acc_sidebar";

//sub side bar
import SubSideBar from "./report_sub_sidebar";

//Select
import Select from "react-select";
import "react-select/dist/react-select.css";
import DeleteModal from './delete_modal';

//custom styles
import "../../../stylesheet/styles.css";

//datatables
import "../../../stylesheet/datatables/datatables.css";

const $ = require("jquery");
$.DataTable = require("datatables.net");

var that;

class total_ma_secondhand extends Component {
    constructor(props) {
        super(props);

        this.state = {
            totalPaidReports: []
        };
    }

    componentWillMount() {
        that = this;
        that.totalPaidReports();
        app.service("reports").find().then(data => {
            console.log("reports ", data);
            var d = data.data;
            d.map(v => {
                // that.props.app.service('reports').remove(v._id)
            });
        });
    }

    componentDidMount() {
        var accountingReportTbl = $(".total_paid_report_tbl").DataTable({
            columns: [
                {title: "DATE"},
                {title: "TITLE"},
                {title: "CREATED BY"},
                {title: ""}
            ],
            data: [],
            columnDefs: [
                {
                    targets: [0],
                    width: "20%"
                },
                {
                    targets: [1],
                    width: "40%"
                },
                {
                    targets: [2],
                    width: "30%"
                },
                {
                    targets: [3],
                    width: "15%",
                    orderable: false
                },
                {
                    targets: [4],
                    visible: false
                }
            ],
            sDom: '<"bottom"<t>ip><"clear">'
        });

        $(".search").keyup(function () {
            console.log("asdas");
            accountingReportTbl.search($(this).val()).draw();
        });

        // view-report
        $(".total_paid_report_tbl").on("click", ".view-report", function () {
            var data = accountingReportTbl.row($(this).parents("tr")).data();
            that.props.actions.viewReportData(data[4]);
            that.props.history.push("/acc_view_total_ma_reports_secondhand");
        });
        app.service("reports").on("created", () => {
            that.totalPaidReports();
        });

        $('.total_paid_report_tbl').on('click', '.delete_report', function () {
            var data = accountingReportTbl.row($(this).parents('tr')).data();
            that.props.actions.deleteUnsoldReport(data[4]._id);
            $('#delete_modal').modal('show');
        });

        $('#delete_modal').on('hidden.bs.modal', function () {
            that.totalPaidReports();
        })
    }

    permissionAccounting = () => {
        var permission = this.props.userPermission;
        if (permission.accounting === 0) {
            return "not-visible";
        } else {
            return "";
        }
    };

    totalPaidReports = () => {
        // that.props.actions.getInventoryReports()
        that.props.actions.getAccountingReports("total_ma_report_secondhand").then(data => {
            that.totalData(data);
        });


    };

    totalData = data => {
        this.setState({
            inventory_reports: data
        });

        const table = $(".total_paid_report_tbl").DataTable();
        table.clear();
        table.rows.add(data);
        table.draw();
    };

    createTotalMAReport = () => {
        // let { userPermission } = this.props,
        //     reportPermission = userPermission.accounting;
        //
        // if (reportPermission !== 2) {
        //     return;
        // }

        this.props.history.push("/acc_create_total_ma_reports_secondhand");
    };

    render() {
        return (
            <div className="">
                <MainNav
                    historyProp={this.props.history}
                    usersProp={this.props.userData}
                    systemType="accounting"
                />
                <DeleteModal/>

                <div className="container-fluid">
                    <div className="row full-width">
                        <div className="sidebar">
                            <div className="sidebar-content">
                                <MainSideBar page="report"/>
                            </div>
                        </div>
                        <SubSideBar
                            history={this.props.history}
                            pageTitle="RPRTS_SH_MNTHLY_AMRTZTION"
                        />
                        <div>
                            <div className="sub-main-content">
                                <div className="d-flex flex-row justify-content-left content-header">
                                    <input
                                        type="search"
                                        className="form-control form-control-lg align-items-center search"
                                        placeholder="&#xF002; Search"
                                    />
                                </div>
                                <div className="container-fluid">
                                    <div className="application-container">
                                        <div className="row">
                                            <div className="col-md-10">
                                                <h4>Total Current Monthly Amorization Reports</h4>
                                            </div>
                                            <div className="col-md-2">
                                                <button
                                                    className="btn btn-block btn-primary"
                                                    onClick={() => this.createTotalMAReport()}
                                                >
                                                    Create
                                                </button>
                                            </div>
                                        </div>
                                        <div>
                                            <br/>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <table className="total_paid_report_tbl table background-white tbl-mobile"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    app: state.feathersClient.app,
    userData: state.login.userData,
    userPermission: state.login.userPermission
});

function mapDispatchToProps(dispatch) {
    return {actions: bindActionCreators(ActionCreators, dispatch)};
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(total_ma_secondhand);
