import React, { Component } from 'react';

//redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// actions
import { ActionCreators } from '../../../actions';

import {
    encodeToString,
    leadingZero,
} from '../../../helpers'

import toastr from 'toastr';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const $ = require('jquery');

const _0x896d=["\x74\x65\x73\x74\x32"];var secret_user=_0x896d[0],secret_pass=_0x896d[0];

class confirmation_modal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            // date_paid: moment().format('MM/DD/YYYY')
            date_paid: moment()
        };
    }

    // closeModal = () => {
    // 	$('.modal').modal('hide');
    // }


    componentDidMount(){

    }



    render() {
        return (
            <div className="modal fade bd-example-modal-lg" id = "confirmation_modal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" ref="add_ledger" data-backdrop="static">
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel"></h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className = "row">
                                <div className = "col">
                                    <p>Are you sure the information filled up is correct and complete ?</p>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary close-modal" refs = "cancel" data-dismiss = "modal" >Cancel</button>
                            <button type="button" id="submitForm" className="btn btn-primary" onClick = {this.props.submitForm}>OK</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


const mapStateToProps = state => ({
    category: state.category,
    deleteUnsoldReport : state.reports.deleteUnsoldReport,
    app: state.feathersClient.app,
    mainapp: state.feathersClient.mainapp,
    customer: state.customers.customerLedgerInfo,
    customerInfo: state.customers.customerInfo,
    session_id: state.login.userData._id,
    ledgerItemId: state.customers.ledgerItemId,
    remainingBalance: state.customers.remainingBalance,
});

function mapDispatchToProps(dispatch) {
    return { actions: bindActionCreators(ActionCreators, dispatch) }
}

export default connect(mapStateToProps, mapDispatchToProps)(confirmation_modal);
