import React, {
    Component
} from 'react';

//redux
import {
    connect
} from 'react-redux';
import {
    bindActionCreators
} from 'redux';
// actions
import {
    ActionCreators
} from '../../../actions';

//main NavBar
import MainNav from '../../../components/global_nav';
import MainSideBar from '../../../components/global_sidebar';


//sub side bar
import SubSideBar from './sub_sidebar';

//custom styles
import '../../../stylesheet/styles.css'

//datatables
import '../../../stylesheet/datatables/datatables.css';

//daterange
import moment from 'moment';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import './plugins/daterangepicker.css';

import toastr from 'toastr';

const $ = require('jquery'), that = this;
$.DataTable = require('datatables.net');

class sales_report extends Component {

    constructor(props) {
        super(props);

        this.state = {
            brand: [],
            filter_reporttype: null,
            filter_daterange: null,
            filter_branch: '',
            filter_showby: null,
            filter_brand: null,
            filter_columnby: '',
            filter_columndata: null,
            sample: null,
            saving: false,
            drPickerApplied: false
        };
    }

    componentWillMount() {
        this.selectedBrandsOpt();
        this.products();
    }

    selectedBrandsOpt = () => {
        let app = this.props.app;

        app.service('brands').find()
            .then((data) => {
                const brandlist = data.data
                const newdata = [];

                brandlist.forEach(function (x, i) {
                    newdata.push({
                        brandname: x.brand_name
                    })
                });

                if (newdata.length) {
                    let html = '<option value="all">ALL</option>';
                    for (const x in newdata) {
                        html += '<option value="' + newdata[x].brandname + '">' + newdata[x].brandname + '</option>';
                    }

                    $('.filter-by-brand').html(html);
                }

                this.setState({
                    brands: newdata
                });
            })
    }

    products = () => {
        let app = this.props.app;
        app.service('products').find({
            query: {
                type: 1
            }
        }).then((data) => {
            const allproducts = data.data;
            const filteredProducts = {};
            const filteredSpecifidProducts = {};
            const date = data.data;

            for (let x = 0; x < date.length; x++) {
                const createdat = date[x];
                const datecreated = createdat.createdAt;
                const test = datecreated
            }

            for (const x = 0; x < allproducts.length; x++) {
                const localData = allproducts[x];
                const brandName = localData.brands ? localData.brands.brand_name : '';
                const branchName = localData.branches ? localData.branches.branch_name : '';
                const modelName = localData.motorModels ? localData.motorModels.model_name : '';
                const price = parseFloat(localData.price) ? parseFloat(localData.price) : 0;
                const createdAt = (moment(localData.createdAt).format('YYYY-MM-DD'));
                const customdate = "Date";
                const customprice = "Price";
                const newdata = [];

                if (!filteredProducts[brandName]) {
                    filteredProducts[brandName] = {};
                    filteredSpecifidProducts[brandName] = {};

                    if (!filteredSpecifidProducts[brandName][customdate]) {
                        filteredSpecifidProducts[brandName][customdate] = createdAt;

                        if (!filteredProducts[brandName][branchName]) {
                            filteredProducts[brandName][branchName] = 0;

                            if (!filteredSpecifidProducts[brandName][modelName]) {

                                filteredSpecifidProducts[brandName][modelName] = {};
                                if (!filteredSpecifidProducts[brandName][modelName][branchName]) {
                                    filteredSpecifidProducts[brandName][modelName][branchName] = 0;
                                }
                            }
                            filteredSpecifidProducts[brandName][modelName][branchName] += price;
                        }
                    }

                    filteredProducts[brandName][branchName] += price;

                } else {
                    if (!filteredProducts[brandName][branchName]) {
                        filteredProducts[brandName][branchName] = 0;
                        if (!filteredSpecifidProducts[brandName][modelName]) {
                            filteredSpecifidProducts[brandName][modelName] = {};
                            if (!filteredSpecifidProducts[brandName][modelName][branchName]) {
                                filteredSpecifidProducts[brandName][modelName][branchName] = 0;
                            }
                        } else {
                            if (!filteredSpecifidProducts[brandName][modelName][branchName]) {
                                filteredSpecifidProducts[brandName][modelName][branchName] = 0;
                            }
                        }

                        filteredSpecifidProducts[brandName][modelName][branchName] += price;
                    } else {
                        if (!filteredSpecifidProducts[brandName][modelName]) {
                            filteredSpecifidProducts[brandName][modelName] = {};
                            if (!filteredSpecifidProducts[brandName][modelName][branchName]) {
                                filteredSpecifidProducts[brandName][modelName][branchName] = 0;
                            }
                        } else {
                            if (!filteredSpecifidProducts[brandName][modelName][branchName]) {
                                filteredSpecifidProducts[brandName][modelName][branchName] = 0;
                            }
                        }
                        filteredSpecifidProducts[brandName][modelName][branchName] += price;
                    }
                    filteredProducts[brandName][branchName] += price;
                }
            }

            this.setState({
                allProducts: filteredProducts,
                allSpecifiedProducts: filteredSpecifidProducts
            });
        })
    }

    loadAllData = () => {
        let app = this.props.app;

        app.service('branches').find()
            .then((data) => {
                const that = this;
                let html = '<option value="all">ALL</option>';
                const branchlist = data.data
                const newdata = [];

                branchlist.forEach(function (x, i) {
                    newdata.push(x.branch_name)

                    html += '<option value="' + x.branch_name + '">' + x.branch_name + '</option>';
                    $('.filter-by-branch').html(html);
                });

                that.setState({
                    branchstorage: newdata
                });

                const branchdata = that.state.branchstorage;
                const branches = data.data;
                let localBrand = [];
                const tableElement = $('.tbl-create-sales-report');
                const dt = null;
                const localBrandData = {};
                const colInit = 10;
                // const colInit = 50;
                const allProducts = this.state.allProducts;
                const allSpecifiedProducts = this.state.allSpecifiedProducts;

                const allBrand = this.state.brands;

                if (allProducts && allBrand) {

                    function genallBrand(data, param = null) {
                        for (const x in data) {
                            for (const y in data[x]) {
                                const brandName = data[x][y];
                                if ($.inArray(brandName, localBrand) === -1) {

                                    localBrand[localBrand.length] = brandName;
                                    for (let x = 0; x < branches.length; x++) {
                                        if (!localBrandData[brandName]) {
                                            localBrandData[brandName] = {}
                                        }
                                        localBrandData[brandName][branches[x].branch_name] = 0;
                                        if (!param) {
                                            if (allProducts[brandName]) {
                                                if (allProducts[brandName][branches[x].branch_name]) {
                                                    localBrandData[brandName][branches[x].branch_name] = allProducts[brandName][branches[x].branch_name];
                                                }
                                            }
                                        } else {
                                            if (allSpecifiedProducts[param][brandName]) {
                                                if (allSpecifiedProducts[param][brandName][branches[x].branch_name]) {
                                                    localBrandData[brandName][branches[x].branch_name] = allSpecifiedProducts[param][brandName][branches[x].branch_name];
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }

                    function reloadTable(header, data, state) {
                        if (state === 'reload') {
                            tableElement.DataTable().clear();
                            tableElement.DataTable().destroy();

                            $(".tbl-create-sales-report thead").remove();
                            tableElement.DataTable({
                                data: data,
                                columns: header,
                                info: false,
                                paging: false,
                                // scrollY: '50vh',
                                "bFilter": false
                            });
                        }
                        if (state === 'load') {
                            tableElement.DataTable({

                                data: data,
                                columns: header,
                                info: false,
                                paging: false,
                                "bFilter": false
                            });
                        }
                    }

                    function getPaginateAction(action) {
                        const that = $('.header-pagination-container')
                        const current_page = that.find('.page-item.active');
                        const current_page_data = parseInt(current_page.data('page'));
                        const total_pagination = that.find('li.page-item').length - 2;
                        const current_page_count_next = current_page_data + 1;
                        const current_page_count_prev = current_page_data - 1;

                        //console.log(current_page_data + " : " + total_pagination);
                        if (action.toLowerCase() === 'next') {
                            if (current_page_count_next < total_pagination) {
                                that.find('.page-item.active').removeClass('active');
                                that.find('.page-item[data-page=' + current_page_count_next + ']').addClass('active');
                                getActiveHeader(current_page_count_next, 1);
                            }
                        }

                        if (action.toLowerCase() === 'prev') {
                            if (current_page_count_prev >= 0) {
                                that.find('.page-item.active').removeClass('active');
                                that.find('.page-item[data-page=' + current_page_count_prev + ']').addClass('active');
                                getActiveHeader(current_page_count_prev, 1);
                            }
                        }
                    }


                    function genPagnation() {
                        let paginate = (parseInt(branches.length) / parseInt(colInit));
                        let html;
                        paginate = paginate.toFixed(0);
                        paginate = parseInt(paginate);

                        for (let x = 0; x < paginate; x++) {
                            if (x === 0) {
                                html += '<li class="page-item active" data-page="' + x + '"><a class="page-link header-pagination header-page-1">' + (x + 1) + '</a></li>';
                            } else {
                                html += '<li class="page-item" data-page="' + x + '"><a class="page-link header-pagination header-page-1">' + (x + 1) + '</a></li>';
                            }
                        }
                        $('.header-pagination-container .pagination').html('<li class="page-item"><a class="page-link header-pagination-prev">Previous</a></li>' + html + '<li class="page-item"><a class="page-link header-pagination-next">Next</a></li>');
                    }

                    function getActiveHeader(data = 0, param = null) {
                        const that_page = (parseInt(data) * parseInt(colInit));
                        const tableData = generateTable(that_page);
                        if (param) {
                            reloadTable(tableData.header, tableData.data, 'reload');
                        } else {
                            reloadTable(tableData.header, tableData.data, 'load');
                        }

                    }

                    function numberWithCommas(x) {
                        const parts = x.toString().split(".");
                        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                        return parts.join(".");
                    }

                    /* Generate Datatable*/
                    function generateTable(inputData, allData = false) {

                        const tdColumn = [{
                            data: "Brand",
                            title: "Brand"
                        }];
                        const tdColumnData = [];
                        let resetBranch = [];
                        let localColLimit = 0;

                        if (!inputData) {
                            inputData = 0;
                        }

                        /* Create Dynamic Content Base on branches */
                        let antiOverlapLimit = 0;

                        antiOverlapLimit = parseInt(branches.length) - parseInt(inputData);

                        if (antiOverlapLimit < localColLimit) {
                            if (allData) {
                                localColLimit = parseInt(branches.length);
                            } else {
                                localColLimit = parseInt(antiOverlapLimit);
                            }
                        } else {
                            if (allData) {
                                localColLimit = parseInt(branches.length);
                            } else {
                                localColLimit = colInit;
                            }
                        }

                        for (let x = 0; x < branches.length; x++) {
                            if (x >= inputData) {
                                resetBranch[resetBranch.length] = branches[x];
                            }
                        }

                        /* Gegerate Header */
                        for (let x = 0; x < localColLimit; x++) {
                            if (resetBranch[x]) {
                                tdColumn[tdColumn.length] = {
                                    "data": resetBranch[x].branch_name,
                                    "title": resetBranch[x].branch_name
                                    // "data": (resetBranch[x].branch_name).substring(0,3),
                                    // "title": (resetBranch[x].branch_name).substring(0,3)
                                };
                            }
                        }

                        /* Row Content */
                        for (const x = 0; x < localBrand.length; x++) {
                            const local_data = {};
                            /* Assign row COntent Base on Product List */
                            for (const key in tdColumn) {

                                const local_brandname = localBrand[x];
                                const local_branchname = tdColumn[key].data;

                                if (key === 0) {
                                    local_data[local_branchname] = local_brandname;
                                } else {
                                    const localBrandDataCon = localBrandData[local_brandname];

                                    if (localBrandDataCon[local_branchname]) {
                                        local_data[local_branchname] = numberWithCommas(localBrandDataCon[local_branchname]);
                                    } else {
                                        local_data[local_branchname] = 0;
                                    }
                                }
                            }
                            tdColumnData[tdColumnData.length] = local_data;
                        }
                        return {
                            data: tdColumnData,
                            header: tdColumn
                        };
                    }

                    function branchfiltering(data) {
                        const chester = data;
                        if (chester === 'all') {
                            tableElement.DataTable().clear();
                            tableElement.DataTable().destroy();
                            $(".tbl-create-sales-report thead").remove();
                            genallBrand(allBrand);
                            genPagnation();
                            getActiveHeader();

                        } else {
                            const chester = data;
                        }
                    }

                    function loadNewData(data) {
                        if (data === 'all') {
                            $('.current-selected-brand').html('ALL');
                            tableElement.DataTable().clear();
                            tableElement.DataTable().destroy();
                            $(".tbl-create-sales-report thead").remove();
                            genallBrand(allBrand);
                            genPagnation();
                            getActiveHeader();
                        } else {
                            $('.current-selected-brand').html(data);
                            const current_brand = data;
                            const current_brand_model = allSpecifiedProducts[current_brand];
                            // console.log('YYYYYYYYYYYYYYYYYYYYYYY',current_brand_model);

                            const current_data = [];
                            for (const x in current_brand_model) {

                                if (x !== 'Date') {
                                    current_data[Object.keys(current_data).length] = {
                                        brandname: x
                                    };
                                }
                            }

                            tableElement.DataTable().clear();
                            tableElement.DataTable().destroy();
                            $(".tbl-create-sales-report thead").remove();

                            genallBrand(current_data, data);
                            genPagnation();
                            getActiveHeader();
                        }
                    }

                    function updateList(selected) {
                        if (selected === 'all') {
                            loadNewData('all')
                        } else {

                            // console.log(selected);
                            let table = generateTable(0, true);

                            let tdColumnData = table.data;
                            let tdColumn = table.header;
                            let updatedtdColumnData = [];

                            for (let index in tdColumnData) {
                                let obj = tdColumnData[index];
                                let updatedObj = {};

                                updatedObj['Brand'] = obj.Brand;
                                updatedObj[selected] = obj[selected] ? obj[selected] : 0;
                                updatedtdColumnData.push(updatedObj);
                            }

                            const updatedtdColumn = [];
                            updatedtdColumn.push({data: "Brand", title: "Brand"});
                            for (let index in tdColumn) {
                                let obj = tdColumn[index];
                                if (obj.data === selected) {
                                    updatedtdColumn.push(obj);
                                }
                            }

                            $('.header-pagination-container .pagination').html('<li class="page-item"><a class="page-link header-pagination-prev">Previous</a></li><li class="page-item"><a class="page-link header-pagination-next">Next</a></li>');

                            reloadTable(updatedtdColumn, updatedtdColumnData, 'reload');
                        }
                    }

                    function savereport() {
                        let app = this.props.app;
                        const that = $(this);

                        const filterByReports = $('.filter-by-reports').val();
                        const dateRangePickerInput = $('.current-selected-date').data('daterangepicker');
                        const dateRangePickerInputStart = (moment(dateRangePickerInput.startDate).format('YYYY-MM-DD'));
                        const dateRangePickerInputEnd = (moment(dateRangePickerInput.endDate).format('YYYY-MM-DD'));
                        const filterByBranch = $('.filter-by-branch').val();
                        const userdataid = this.props.userData._id;
                        const userdatafullname = this.props.userData.fullname;
                        const reportTitle = $.trim($('.report-title').val());

                        if (reportTitle === '') {
                            toastr.error('Please add a title for the report');
                            return;
                        }
                        this.setState({
                            saving: true
                        });

                        let reportdata = {
                            report_type: filterByReports,
                            report_title: reportTitle,
                            daterange_start: dateRangePickerInputStart,
                            // datepickerstart:dateRangePickerInputStart ,
                            daterange_end: dateRangePickerInputEnd,
                            // datepickerend: dateRangePickerInputEnd,
                            branchfilter: filterByBranch,
                            user_id: userdataid,
                            // userid:userdataid,
                            user_fullname: userdatafullname,
                            // userfullname:userdatafullname
                            extraData: that.state.sample,
                        };

                        app.service('reports').create(reportdata).then(function (data) {
                            toastr.success('Report Successfully Created!');
                            that.props.history.push('/report');
                            that.setState({
                                saving: false
                            });
                        }).catch(() => {
                            toastr.error('Report not saved');
                            this.setState({
                                saving: false
                            });
                        })
                    }

                    function loadDateRange(data, pickerrange) {
                        const dates = data;
                        const splitted = dates.split(' - ');
                        const startDate = splitted[0];
                        const endDate = splitted[1];
                        const Data = allSpecifiedProducts;
                        const _range = pickerrange;
                        const motors = [];

                        for (const name in Data) {
                            const datadate = {
                                'name': name,
                                'date': Data[name].Date
                            };

                        }
                        const updatedData = {};
                        for (const index in Data) {
                            const row = Data[index];
                            if (_range.indexOf(row.Date) === -1) {
                                continue;
                            }
                            updatedData[index] = row;
                        }
                        if (updatedData !== 0) {
                            const current_brand = updatedData;
                            const current_data = [];

                            for (const _k in current_brand) {
                                if (_k !== 'Date') {
                                    let free = _k;
                                    current_data[Object.keys(current_data).length] = {
                                        brandname: free
                                    };
                                }
                            }
                            tableElement.DataTable().clear();
                            tableElement.DataTable().destroy();
                            $(".tbl-create-sales-report thead").remove();
                            genallBrand(dateremovezerodata(current_data));

                            genPagnation();
                            getActiveHeader();
                        } else {
                            tableElement.DataTable().clear();
                            tableElement.DataTable().destroy();
                            $(".tbl-create-sales-report thead").remove();
                            genallBrand(allBrand);
                            genPagnation();
                            getActiveHeader();
                        }
                    }

                    function dateremovezerodata(data, param = null) {
                        localBrand = [];
                        const Data = allSpecifiedProducts;

                        for (const x in data) {
                            for (const y in data[x]) {
                                const brandName = data[x][y];
                                if ($.inArray(brandName, localBrand) === -1) {

                                    localBrand[localBrand.length] = brandName;
                                    for (let x = 0; x < branches.length; x++) {
                                        if (!localBrandData[brandName]) {
                                            localBrandData[brandName] = {}
                                        }

                                        if (!param) {
                                            if (allProducts[brandName]) {
                                                if (allProducts[brandName][branches[x].branch_name]) {
                                                    localBrandData[brandName][branches[x].branch_name] = allProducts[brandName][branches[x].branch_name];
                                                }
                                            }
                                        } else {
                                            if (allSpecifiedProducts[param][brandName]) {
                                                if (allSpecifiedProducts[param][brandName][branches[x].branch_name]) {
                                                    localBrandData[brandName][branches[x].branch_name] = allSpecifiedProducts[param][brandName][branches[x].branch_name];
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        const _counted = {};
                        const updatedData = [];

                        for (const key in data) {
                            let local_counter = 0;
                            const local_brand_name = data[key].brandname;

                            for (const key2 in localBrandData[local_brand_name]) {
                                const localData = localBrandData[local_brand_name][key2];
                                if (parseInt(localData) > 0) {
                                    local_counter++;
                                }
                            }
                            if (local_counter > 0) {
                                updatedData[updatedData.length] = {brandname: local_brand_name};
                            }
                        }
                        return updatedData;
                    }

                    genallBrand(allBrand);
                    genPagnation();
                    getActiveHeader();

                    $(function () {
                        const start = moment();
                        const end = moment();
                        $('input[name="daterange"]').daterangepicker({
                            startDate: start,
                            endDate: end,
                        });
                        $('input[name="daterange"]').on('apply.daterangepicker', function (ev, picker) {

                            const that = $(this);
                            const that_value = that.val();
                            const dates = that_value;
                            const splitted = dates.split(' - ');
                            const startDate = splitted[0];
                            const endDate = splitted[1];

                            this.setState({
                                drPickerApplied: true
                            })

                            const startDateMoment = (moment(startDate).format('YYYY-MM-DD'));
                            const endDateMoment = (moment(endDate).format('YYYY-MM-DD'));
                            const _startDate = new Date(startDateMoment);
                            const _endDate = new Date(endDateMoment);
                            const _date_diff = Math.abs((_startDate.getTime() - _endDate.getTime()) / (24 * 3600 * 1000));
                            const _dates = [];
                            const _adates = [];

                            for (let _i = 0; _i < _date_diff; _i++) {
                                _dates.push(new Date(_startDate.setDate(_startDate.getDate() + 1)));
                            }
                            const _datearray = _dates;
                            for (const x = 0; x < _datearray.length; x++) {
                                _adates.push((moment(_datearray[x]).format('YYYY-MM-DD')));
                            }
                            const dateFrom = [startDateMoment];
                            const dateTo = [endDateMoment];
                            const adates = _adates;
                            const combinedDates = dateFrom.concat(adates);
                            loadDateRange(that_value, combinedDates);
                        });
                    });

                    $('.header-pagination-container').on('click', '.page-link.header-pagination', function () {
                        const that = $(this);
                        const that_page_item = that.closest('.page-item');
                        const that_page = that_page_item.data('page');

                        $('.header-pagination-container').find('.page-item.active').removeClass('active');
                        that.closest('.page-item').addClass('active');
                        getActiveHeader(that_page, 1);
                    });

                    $('.header-pagination-container').on('click', '.header-pagination-prev', function () {
                        getPaginateAction('Prev');
                    });

                    $('.header-pagination-container').on('click', '.header-pagination-next', function () {
                        getPaginateAction('Next');
                    });

                    $('.sub-inv-main-content').on('change', '.filter-by-brand', function () {
                        const that = $(this);
                        const that_value = that.val();
                        loadNewData(that_value);
                    });

                    $('.sub-inv-main-content').on('change', '.filter-by-branch', function () {
                        const that = $(this);
                        const that_value = that.val();

                        this.setState({
                            sample: that_value
                        });
                        updateList(that_value);
                    });

                    $('.sub-inv-main-content').on('change', '.filter-by-reports', function () {
                        const that = $(this);
                        const that_value = that.val();

                        if (that_value === 'Sales') {
                            that.props.history.push('/report/create_sales_report');
                        } else {
                            that.props.history.push('/report/create_inventory_report');
                        }
                        return false;
                    });

                    $('.sub-inv-main-content').on('click', '.savebutton', function () {
                        if (!that.state.saving) {
                            savereport();
                        }
                    });
                } else {
                    this.reloadAllData()
                }
            })
    }

    reloadAllData = () => {
        this.products();
        this.loadAllData();
    }

    componentDidMount() {
        this.loadAllData();
    }

    render() {
        const dateRangePickerInput = $('.current-selected-date').data('daterangepicker');
        const dateRangePickerInputStart = (this.state.drPickerApplied ? moment(dateRangePickerInput.startDate).format('YYYY-MM-DD') : '');
        const dateRangePickerInputEnd = (this.state.drPickerApplied ? moment(dateRangePickerInput.endDate).format('YYYY-MM-DD') : '');
        return (
            <div className="">
                {/* main NavBar component*/}
                <MainNav historyProp={this.props.history} usersProp={this.props.userData}/>

                <div className="container-fluid">
                    <div className="row full-width">
                        <div className="sidebar">
                            <div className="sidebar-content">
                                <MainSideBar page="report"/>
                            </div>
                        </div>
                        <SubSideBar history={this.props.history} userPermission={this.props.userPermission}/>
                        <div className="sub-inv-main-content">
                            <div className="container-fluid">
                                <div className="application-container">
                                    <div className="row padding-20 background-white">
                                        <div className="col-md-12">
                                            <div className="row">
                                                <div className="col d-flex flex-row align-items-center">
                                                    <label className="col-md-5">Report Type</label>
                                                    <select className="form-control col-md-7 filter-by-reports">
                                                        <option>Sales</option>
                                                        <option>Inventory</option>
                                                    </select>
                                                </div>
                                                <div className="col d-flex flex-row align-items-center">
                                                    <label className="col-md-5">Date Range</label>
                                                    <input type="text" name="daterange"
                                                           className="current-selected-date btn btn-primary"/>
                                                </div>
                                                <div className="col d-flex flex-row">
                                                    <label className="col-md-5">Branch</label>
                                                    <select className="form-control col-md-7 filter-by-branch">
                                                        <option>All</option>
                                                        <option>Branch</option>
                                                    </select>
                                                </div>
                                                <div className="col">
                                                    <div className="row report-option-icons">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row padding-20 background-white">
                                        <div className="center-div">
                                            <button
                                                className="btn application-btn border-radius-50 padding-10-40 text-white bg-active-color savebutton">SAVE
                                                REPORT
                                            </button>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <p>Sales Reports</p>
                                    </div>
                                    <div className="row padding-20 background-white">
                                        <div className="col-md-4">
                                            <input
                                                className="form-control report-title"
                                                type="text"
                                                placeholder="Report title here"
                                            />
                                            <br/>
                                            <p>By {this.props.userData.fullname}</p>
                                        </div>
                                        <div className="col-md-3">
                                        </div>
                                        <div className="col-md-3">
                                            <h5>INVENTORY REPORT</h5>
                                            <span>
                                        {
                                            this.state.drPickerApplied ? moment(dateRangePickerInputStart).format('LL') + ' to ' + moment(dateRangePickerInputEnd).format('LL') : moment().format('LL')
                                        }
                                        </span><br/>
                                            <span>MAIN BRANCH</span>
                                        </div>
                                        <div className="col-md-12">
                                            <br/>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="header-pagination-container">
                                                <ul className="pagination">

                                                </ul>

                                            </div>
                                            <table className="table tbl-create-sales-report tbl-mobile">
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    app: state.feathersClient.app,
    userData: state.login.userData,
    userPermission: state.login.userPermission,
});

function mapDispatchToProps(dispatch) {
    return {actions: bindActionCreators(ActionCreators, dispatch)}
}

export default connect(mapStateToProps, mapDispatchToProps)(sales_report);