import React, { Component } from 'react';

//main NavBar
import MainNav from '../../../components/global_nav';
import MainSideBar from '../../../components/acc_sidebar';

import SelectUnitType from './select_customer_modal';

// moment
import Moment from 'moment';
import {IntlProvider, FormattedMessage, FormattedNumber} from 'react-intl';
import Cleave from 'cleave.js/react';

import ReactDataSheet from 'react-datasheet';

//toastr
import toastr from 'toastr';
import Toggle from 'react-bootstrap-toggle';

//sub side bar
import SubSideBar from '../accounting/sub_sidebar'; 

import app from '../../../helpers/feathers';

import RepossessModalSH from '../accounting/repossess_sh_modal';

import defaultImage from '../../../assets/img/default_avatar.jpg';
import {
    numberWithCommas,
    twoDecimalPlaces,
} from '../../../helpers/';

//custom styles
import '../../../stylesheet/styles.css';

//redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// actions
import { ActionCreators } from '../../../actions';
const $ = require('jquery');
$.DataTable = require('datatables.net');

var that

class view_customer_secondhand extends Component {


    constructor(props) {
        super(props);
    
        this.state = {
            activeTab: 'home',
            modelName: '',
            payment: [],
            paymentData: [],
            form: {},
            currentBalance: 0,
            add: false,
            delinquent: false,
            saving: false,
            toggleActive: false,
            SHLedgerData: [],
            remaining : 0
        };
    }

    componentWillMount(){
        that = this;

        that.getCustomerPayments();
        var badRecord = that.props.customer.bad_record;

        that.setState({
            delinquent: badRecord === 0 ? false : true
        })
        let {customer} = this.props,
             promisory_note = customer.promisory_note;

        var totalPaid = customer.customer_payments,
               totalDebit = customer.customer_payments;             

           let total_pay = 0,
               total_debit = 0 ;


               totalPaid.length > 0 && totalPaid.map((values) => {
                            total_pay += Number(values.total_paid)
                        })

                totalDebit.length > 0 && totalDebit.map((data) => {
                                total_debit += Number(data.debit ? data.debit : 0);
                                console.log('DEBITTTTTTTTTTT', total_debit)
                })

                var remain = Number(promisory_note) - Number(total_pay) + Number(total_debit)

            this.setState({
                remaining : remain
            })

        that.getSHLedgerInfo();

        /* 
            in case unit has not been tagged as `sold` after creating customer,
            this will update unit status to `sold`
         */
        if(customer.unit_id){
            let secondhandService = app.service('secondhand');
            secondhandService.find({
                query: {
                    _id: customer.unit_id,
                    type: 0
                }
            }).then((result) => {
                secondhandService.patch(customer.unit_id, {type: 1})
                .then(() => {

                })
                .catch(() => {

                })
            })
            .catch((e) => {
                console.log(e)
            })
        }
    }

    componentDidMount() {

        if($('span.exp').text() == 'CONTRACT IS EXPIRED'){
           
            $('.checkbox-data').hide();  

             $('span.new-terms').text('99 months');
        }
        else{
            $('.checkbox-data').show();
          
        }
        
        if($('p#account_number_section').text() == 'CASH'){
            $('button.btn.btn-success.show_sched').hide();
        }
        else{
            $('button.btn.btn-success.show_sched').show();
        }

        
        $('.hide_sched').hide(); 
        $('.button-print').hide();  
        $('.show_sched').on('click', function(){
            $('#table-ledger').css('visibility', 'visible');
            $('.show_sched').hide();
            $('.button-print').show();
            $('.hide_sched').show();
            $('.customer_sch').show()
        });
        $('.hide_sched').on('click', function(){
            $('#table-ledger').css('visibility', 'hidden');
            $('.hide_sched').hide();
            $('.button-print').hide();
            $('.show_sched').show();
            $('.customer_sch').hide()
        });

        var mainDtTable = $('.tbl-ledger').DataTable({
            data: this.state.SHLedgerData,
            "columnDefs": [
                {
                    "visible": false,
                    "targets": 0
                },
         
            ],
            columns: [
                { title: "ID" },
                { title: "Date" },
                { title: "Invoice" },
                { title: "DR" },
                { title: "CR" },
                { title: "balance" },
                { title: "DATE DUE" },
                { title: "MA" },           
                { title: "BALANCE" },     
            ],
            "paging": false,
            "pageLength": 36,
            "searching": false,
            "ordering": false,
            "info": false,
            "sDom": '<"bottom"<t>ip><"clear">' 
        });



       

        $('.table-due-date').DataTable({
            "paging": false,
            "pageLength": 36,
            "searching": false,
            "ordering": false,
            "info": false,
            "sDom": '<center<"bottom"<t>p><"clear">>'
        });

        // var tblPayment = $('.tbl-payment').DataTable({
        //     "searching": false,
        //     "ordering": false,
        //     "info":     false
        // });

        $('.tbl-payment').DataTable({
            "paging": false,
            "async": false,
            "searching": false,
            "empty": false,
            "info": false,
            "ordering": false,
        });
        
         var empty = $(".tbl-payment tbody tr:eq(0) td").html();
         if(empty == "No data available in table")
         {
            $(".tbl-payment tbody tr:eq(0) td").hide();
         }

        if(this.props.customer.payment_method == 0){
            // this.getPayments();
            $('.menu').hide();
            $('.show_sched').hide();
            $('.hide_sched').hide();
        }

        $('.customer_sch').hide()


         var paymentSHTbl = $('.tpaid_sh').DataTable({
          data: this.props.paymentTableData,   
            "columnDefs": [
                {
                    "visible": false,
                    "targets": 0
                },              
            ],        
            columns: [               
                { title: "" },
                { title: "DATE" },
                { title: "OR NUMBER" },
                { title: "DR" },
                { title: "CR" },
                { title: "REBATE" }, 
                { title: " BALANCE"},
                { title: "REMARKS" },
               
            ],
            "paging": false,
            "pageLength": 100,
            "searching": false,
            "ordering": false,
            "info": false,
            "sDom": '<"bottom"<t>ip><"clear">' 
        });
    }



getCustomerPayments = () => {
        let { customer, total_payments } = this.props,
            promisory_note = customer.promisory_note,
            remaining_balance = (promisory_note  - total_payments);


        that.props.actions.getSHCustomerPayment(customer.account_number, promisory_note)
        .then((res) => {
            that.setState({sh_total_payments: res.total_payments})
            if(res.status){
                that.updateDataTable('.tpaid_sh', res.data)
            }else{
                that.updateDataTable('.sh', [])
            }
        })
    }

    getPayments = () => {
        var customerId = this.props.customer._id,
            that = this;
        this.props.actions.getCustomerPayments(customerId).then(data => {

            

            that.getPaymentsInfo('load', data);
            that.props.actions.setCustomerPayments(data);
            that.updatePayment(data);
        });
    }

    updatePayment = (data) => {
        this.setState({
            payment: data
        });
    }

    getSHLedgerInfo = () => {
        let { customer } = this.props,
            { promisory_note } = customer;

        let query = {
            account_number: customer.account_number,
            current: 1
        }

        app.service('customer-payments-secondhand').find({query: query})
        .then((payments) => {
            if(payments.total){
                let paymentsObj = payments.data,
                    total_payments = 0;

                paymentsObj.map((v, i) => {
                    total_payments += (Number(v.amount_paid))
                })

                computeLedger(total_payments)
            }else{
                computeLedger(0)
            }
        })
        .catch(() => {
            computeLedger(0)
        })


        var computeLedger = (total_payments) => {
            app.service('ledger-secondhand').find({query: query})
            .then((ledger) => {
                
                if(ledger.total){
                    let res = ledger.data,
                        multiplier = 0,
                        balance = 0,
                        initialBal = (Number(promisory_note) - total_payments),
                        data = [];

                    res.map((value, i) => {
                        let actionBtn = '<button class="btn btn-sm btn-warning edit" title="Update"><span class="fa fa-edit" /></button>',
                            amount_paid = value.amount_paid,
                            eachBalance = amount_paid ? (initialBal - amount_paid) : initialBal;

                        multiplier += 1;

                        let balance = (parseFloat(value.monthly_amortization) * multiplier),
                            displayed_balance = Math.round(value.balance - balance),
                            displayed_ma = Math.round(value.monthly_amortization);

                        data.push([
                            value._id,
                            '','','','','',
                            value.due_date,
                            numberWithCommas(twoDecimalPlaces(displayed_ma)),
                            value.balance ? numberWithCommas(twoDecimalPlaces((displayed_balance))) : '',
                        ])
                    })

                    

                    this.setState({SHLedgerData: data})
                    this.updateData(data)
                }else{
                    this.setState({SHLedgerData: []})
                    this.updateData([])
                }
            })
            .catch(() => {
                this.setState({SHLedgerData: []})
                this.updateData([])
            })
        }

    }

    getModelInfo = () => {
        var modelId = this.props.customer.product != undefined ? this.props.customer.product.model: 0,
            data = this.props.model;

            
            for(var i in data){
                if(data[i][0] === modelId){
                    return data[i][2];
                }
            }
    }

    getBrandInfo = () => {
        var brandId = this.props.customer.product != undefined ? this.props.customer.product.brand: 0,
        data = this.props.brand;
            for(var i in data){
                if(data[i][0] === brandId){
                    return data[i][2];
                }
            }
    }

    getPaymentDue = () => {
        var startDate = this.props.customer.date_added,
            termsLength = this.props.customer.terms.months,
            monthlyPayment = this.props.customer.terms.monthly_payment,
            rows = [],
            paymentRows = [];

            for(var i = 1; i <= termsLength; i++){
                var newMonths = Moment(startDate).add(i, 'months').format('MM/DD/YYYY');
                rows.push(<tr key={i}><td>{newMonths}</td><td><FormattedNumber value={monthlyPayment}  style="currency" currency="Php" /></td></tr>)
            }

        return rows;
    }

    getPaymentsInfo = (value, paymentHistory) => {
        var paymentData = [],
            paymentHistory = paymentHistory,
            dataLength = paymentHistory.length,
            termsLength = this.props.customer.terms.months,
            newLength = termsLength - dataLength,
            balance = this.props.customer.product.price - this.props.customer.terms.down_payment,
            startDate = this.props.customer.date_added,
            date_purchased = this.props.date_purchased,
            payedAmount = 0,
            currentBalance = this.state.currentBalance;


        paymentHistory.map(x => {
            payedAmount += x[2];
            payedAmount += x[4];
            paymentData.push(
                <tr key={i} className="table-primary text-center">
                    <td>{Moment(x[1]).format('MM/DD/YYYY')}</td>
                    <td><FormattedNumber value={x[2]} className="form-control" style="currency" currency="Php" /></td>
                    <td><FormattedNumber value={x[3] / 100} style="percent" /></td>
                    <td><FormattedNumber value={x[4]} className="form-control" style="currency" currency="Php" /></td>
                    <td><FormattedNumber value={balance - payedAmount} className="form-control" style="currency" currency="Php" /></td>
                    <td>{x[6]}</td> 
                </tr>
            )
        });

        this.setState({
            currentBalance: balance - payedAmount
        });

        if(value === 'load'){
            for(var i = 1; i <= newLength; i++){
                var newMonths = Moment(startDate).add(i, 'months').format('MM/DD/YYYY');
                paymentData.push(<tr key={i} className="text-center">
                        <td>MM/DD/YYYY</td>
                        <td>Php 0.00</td>
                        <td>0%</td>
                        <td>Php 0.00</td>
                        <td><FormattedNumber value={balance - payedAmount} className="form-control" style="currency" currency="Php" /></td>
                        <td>--</td>
                    </tr>
                );
            }
        }else{
            var permission = this.props.userPermission;
            if(permission.customer !== 2){
                return;
            }

            this.setState({
                add: true
            })
            paymentData.push(
                <tr key={0} className="text-center">
                    <td><input type="date" className="form-control" name="payment_date" onChange={this.handleOnChange}/></td>
                    <td><Cleave className="form-control" placeholder="Amount" options={{numeral: true, numeralThousandsGroupStyle: 'thousand'}} name="amount" onChange={this.handleOnChange}/></td>
                    <td><Cleave className="form-control" placeholder="Interest" options={{numeral: true, numeralThousandsGroupStyle: 'thousand'}} name="interest" onChange={this.handleOnChange}/></td>
                    <td><Cleave className="form-control" placeholder="Rebate" options={{numeral: true, numeralThousandsGroupStyle: 'thousand'}} name="rebate" onChange={this.handleOnChange}/></td>
                    <td><FormattedNumber value={balance - payedAmount} className="form-control" style="currency" currency="Php" /></td>
                    <td><input type="text" className="form-control" name="remarks" onChange={this.handleOnChange}/></td>
                </tr>);
            for(var i = 1; i <= (newLength - 1); i++){
                var newMonths = Moment(startDate).add(i, 'months').format('MM/DD/YYYY');
                paymentData.push(<tr key={i} className="text-center">
                        <td>MM/DD/YYYY</td>
                        <td>Php 0.00</td>
                        <td>0%</td>
                        <td>Php 0.00</td>
                        <td><FormattedNumber value={payedAmount - balance} className="form-control" style="currency" currency="Php" /></td>
                        <td>--</td>
                    </tr>
                );
            }
        }

        this.setState({
            paymentData: paymentData
        });
        
    }

    handleOnChange = (e) => {
        var form = this.state.form,
            value = (e.target.name === 'amount' || e.target.name === 'interest' || e.target.name === 'rebate') ? parseFloat((e.target.value).replace(/,/g , "")) : e.target.value;
        
        form[e.target.name] = value;
        this.setState({
            form: form
        });
    }


    savePayments = () => {
        // console.log(this.refs.amount.value);
        // var tblPayment = $('.tbl-payment').DataTable();
        // tblPayment.rows().every( function ( rowIdx, tableLoop, rowLoop ) {
        //     var data = this.data();
        //     console.log('data', data[0])
        // });
        var form = this.state.form,
            count = 0,
            balance = this.props.customer.product.price - this.props.customer.terms.down_payment,
            payment = 0,
            that = this;

        for(var data in form){
            var value = form[data];

            if(data === 'amount' || data === 'rebate'){
                payment += value;
            }

            if(value != '' || value != undefined){
                count++;
            }
        }

        if(count < 5){
            toastr.error('Please fill in required fields');
            return false;
        }

        form['customer_id'] = this.props.customer._id;
        form['balance'] = (balance - payment);

        this.props.actions.addPayment(form).then(data => {
            if(data === 'success'){
                toastr.success('Payment sucessfully added.')
                that.getPayments();
            }
        });

    }

    cancel = () => {
        this.setState({
            add: false
        });
        this.getPayments();
    }

    setAsDelinquent = () => {

        if(that.state.saving){
            return false;
        }else{
            that.setState({
                delinquent: true,
                saving: true
            })
        }

        var user_id = that.props.customer._id,
            status = 1,
            set_by = that.props.userData._id,
            delinquent_payee = 1;

        var data = {
            user_id: user_id,
            status: status,
            set_by: set_by,
            delinquent_payee: delinquent_payee
        }

        that.props.actions.setAsDelinquent(data)
        .then((data) => {
            that.setState({
                saving: false
            })
        })
    }
    removeDelinquency = () => {

        if(that.state.saving){
            return false;
        }else{
            that.setState({
                delinquent: false,
                saving: true
            })
        }

        var user_id = that.props.customer._id,
            status = 1,
            set_by = that.props.userData._id,
            delinquent_payee = 0;

        var data = {
            user_id: user_id,
            status: status,
            set_by: set_by,
            delinquent_payee: delinquent_payee
        }

        that.props.actions.removeDelinquency(data)
        .then((data) => {
            that.setState({
                saving: false
            })
        })
    }

    onToggle = () => {
        var permission = this.props.userPermission;
        if(permission.customer !== 2){
            return;
        }

        if (this.state.delinquent){
            that.removeDelinquency();
        }else{
            that.setAsDelinquent();
        }
    }


    showDiv(){
       var showTable = document.getElementById("table-ledger");
       if(showTable.display == "none"){
           showTable.display = "block";
       }
       else{
           showTable.display = "none";
       }
    }

    status_change = (e) => {
        var val = e.target.checked,
            id = this.props.customer._id;
        that.setState({
            expiry: val
        })
        

        that.props.actions.updateSHExpiry(id, val)
        .then((data) => {
            toastr.remove();
            toastr.success('Customer Contract Expired');
        })

        setTimeout(() => {
            that.props.history.push("/customer_secondhand_unit/");
        },3000);
       
    }

    updateData = (data) => {
        this.setState({
            SHLedgerData: data
        });

        const table = $('.tbl-ledger').DataTable();
        table.clear();
        table.rows.add(data);
        table.draw();
    }

    updateDataTable = (table_class, data) => {
        // console.log('updateDataTable ')
        const table = $(table_class).DataTable();
        table.clear();
        table.rows.add(data);
        table.draw();
    }

    redirect = () => {
         that.props.actions.setCustomerInfo(this.props.customer);
        that.props.history.push('/view_ledger_secondhand/');
    }
    repo = () => {
        that.props.actions.setCustomerInfo(this.props.customer);
        $('#repossessed_sh_modal').modal('show');
    }


    render() {
            var modelName = this.getModelInfo(),
            brandName = this.getBrandInfo();
            // paymentDueDate = this.props.customer.type === 1 ? this.getPaymentDue() : null;

            let {customer} = this.props,
                unit = customer.secondhand_unit_info,
                prohibited_acc = ['1','2'],
                {account_status} = customer;

        return (
            <div className="">
                {/* main NavBar component*/}
                <MainNav
                    historyProp={this.props.history}
                    userPermission={this.props.userPermission}
                    usersProp={this.props.userData}
                    systemType="accounting"
                />
                <SelectUnitType history={this.props.history} />
                <RepossessModalSH history={this.props.history} />
                
                <div className="container-fluid">
                    <div className="row full-width">
                        <div className="sidebar">
                            <div className="sidebar-content">
                                <MainSideBar page="customer"/>
                            </div>
                        </div>
                       <SubSideBar history={this.props.history} userPermission={this.props.userPermission} path = "/acc_add_customer" />
                        <div className="sub-main-content">
                            {
                                //<div className="d-flex flex-row justify-content-center content-header">
                                    //<button className="btn application-btn">APPROVE</button>
                                    //<button className="btn application-btn">REJECT</button>
                                //</div>
                            }
                            <div className="container-fluid application-container">
                            <a href="javascript:;" onClick={() => this.props.history.goBack()}
                               className="text-gray no-textdecoration"><span className="fa fa-chevron-left" /> Customer / {this.props.customer.name}</a>
                            <br/>
                            <br/>
                                {/* <ul className="nav nav-tabs" role="tablist">
                                    <li role="presentation" className={this.state.activeTab === 'home' ? 'active' : ''} onClick={() => this.navigateTab('home')}><a href="javascript:;" aria-controls="home" role="tab" data-toggle="tab">PROFILE</a></li>
                                   <li role="presentation" className={this.state.activeTab === 'payments' ? 'active' : ''} onClick={() => this.navigateTab('payments')}><a href="javascript:;" aria-controls="payments" role="tab" data-toggle="tab">PAYMENTS</a></li>
                                </ul>*/}
                                
                                <div className="tab-content background-white">
                                    <div role="tabpanel" className={"hide-view-customer tab-pane view-cust " + (this.state.activeTab === 'home' ? 'active' : '') } id="home">
                                        <div className="">
                                            <div className="background-white p-5">
                                                <div className="container-fluid">
                                                    <div className="row d-flex flex-row align-items-center">
                                                        <div className="col-md-2 d-flex justify-content-center ">
                                                            <img src={defaultImage} className="avatar" alt="logo"/>
                                                        </div>
                                                        <div className="col-md-10">
                                                            <div className="row">
                                                                <div className="col">
                                                                    <h4>{this.props.customer.title} {this.props.customer.name}</h4>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col">
                                                                    <span>Account Number</span>
                                                                    <p id="account_number_section">{this.props.customer.account_number ? this.props.customer.account_number :
                                                                        <h5><span
                                                                            className="badge badge-success badge-pill">CASH</span>
                                                                        </h5>}</p>
                                                                </div>

                                                                {
                                                                    account_status == 1 ?
                                                                        <div className="col">
                                                                            <div>
                                                                                <span>Date Closed</span>
                                                                                <p>{Moment(this.props.customer.date_closed).format('MMMM DD, YYYY')}</p>
                                                                            </div>
                                                                        </div> : ''
                                                                }
                                                                {
                                                                    account_status == 2 ?
                                                                        <div className="col">
                                                                            <div>
                                                                                <span>Date Repossessed</span>
                                                                                <p>{Moment(this.props.customer.date_repossessed).format('MMMM DD, YYYY')}</p>
                                                                            </div>
                                                                        </div> : ''
                                                                }

                                                                <div className="col">
                                                                    {
                                                                        account_status == 1 ? <h4><span
                                                                            className="badge badge-success badger">FULLY PAID</span>
                                                                        </h4> : ''
                                                                    }
                                                                    {
                                                                        account_status == 2 ? <div><h4><span
                                                                            className="badge badge-success badger">REPOSSESSED</span>
                                                                        </h4></div> : ''
                                                                    }
                                                                    {
                                                                        !prohibited_acc.includes(customer.account_status) ?
                                                                            <div>
                                                                                <span>Remaining Balance </span>
                                                                                <p>
                                                                                    {

                                                                                        !this.props.customer.payment_method ? "N/A" :
                                                                                            <FormattedNumber
                                                                                                value={parseInt(this.state.remaining)}
                                                                                                style="currency"
                                                                                                currency="Php"/>
                                                                                    }
                                                                                </p>
                                                                            </div>
                                                                            : null
                                                                    }

                                                                </div>
                                                                <div className="col">
                                                                    <span>Branch</span>
                                                                    <p>{customer.branch_info ? customer.branch_info.branch_name : ''}</p>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                {
                                                                    account_status == 2 ? <div className="col">
                                                                        <span>Remarks</span>
                                                                        <p>{this.props.customer.remarks2}</p>
                                                                    </div> : null
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <br/>
                                                    <div className="personal-info">
                                                        <div className="row">
                                                            <div className = "col">
                                                            <h5><strong>UNIT INFORMATION</strong></h5>
                                                            </div>
                                                            <div className = "col">
                                                                {
                                                                    !prohibited_acc.includes(customer.account_status) ?
                                                                    <button className = "btn btn-success menu" onClick = {this.redirect}>Go to Ledger <i className = "fa fa-arrow-right"></i></button>
                                                                    : null
                                                                }
                                                                 &nbsp;
                                                                {
                                                                   !prohibited_acc.includes(customer.account_status) && customer.payment_method == 1 ? <button class="btn btn-danger repossess" title="Repossess" onClick = {this.repo} ><span class="fa fa-archive"></span> Repossess </button> : ''
                                                                }
                                                            </div>

                                                        </div>
                                                        <div className="row">
                                                            <div className="col">
                                                                <span><strong>Model:</strong>  {customer.old_unit ? customer.unit_model : (unit ? unit.model.name : <span className="text-danger">REMOVED</span>)}</span>
                                                            </div>
                                                            <div className="col">
                                                                <span><strong>Previous Customer:</strong>  {customer.old_unit ? "N/A" : (unit ? unit.customer : '')}</span>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col">
                                                                <span><strong>Engine Number:</strong>  {customer.old_unit ? customer.unit_engine_number : (unit ? unit.engine_number : '')}</span>
                                                            </div>
                                                            <div className="col">
                                                                <span><strong>Previous Account Number:</strong>  {customer.old_unit ? "N/A" : (unit ? unit.account_number : '')} </span>
                                                            </div>
                                                        </div>
                                                        <div className="row">                                                            
                                                            <div className="col">
                                                                <span><strong>Customer Address:</strong>  {customer.customer_address} </span>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col">
                                                                <span><strong>Payment:</strong>  {customer.payment ? customer.payment : ''}</span>
                                                            </div>
                                                            <div className="col">
                                                                <span><strong>OR Number:</strong>  { customer.or_number ? customer.or_number : ''}</span>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col">
                                                                <span><strong>Selling Price:</strong>  {customer.old_unit ? "N/A" : (unit ? customer.secondhand_unit_info.selling_price : '')}</span>
                                                            </div>
                                                            <div className="col">
                                                                <span><strong>Date Foreclosed:</strong>  {customer.old_unit ? "N/A" : (unit ? Moment(customer.secondhand_unit_info.date_foreclosed).format('MMMM D, YYYY') : '')}</span>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col">
                                                                <span><strong>Discount:</strong>  {customer.old_unit ? "N/A" : (unit ? (unit.discount ? unit.discount+'%' : '') : '')}</span>
                                                            </div>
                                                            <div className="col">
                                                                <span><strong>Remarks:</strong>  {customer.old_unit ? customer.remarks : (unit ? unit.remarks : '')}</span>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col">
                                                                <span><strong>Area:</strong>  {customer.area}</span>
                                                            </div>
                                                            <div className="col">
                                                                <span><strong>Date Purchased:</strong>  {Moment(customer.date_purchased).format('MMMM D, YYYY')}</span>
                                                            </div>
                                                        </div>
                                                        <div className = "row">
                                                            <div className="col">
                                                            {
                                                                !prohibited_acc.includes(customer.account_status) ?
                                                                <div>
                                                                <button className = "btn btn-success show_sched" onClick ={()=> this.showDiv()}>Show Schedule</button>
                                                                <button className = "btn btn-danger hide_sched" onClick ={()=> this.showDiv()}>Hide Schedule</button>
                                                                </div> : null
                                                                
                                                            }
                                                                
                                                            </div> 
                                                            <div className="col">
                                                            {
                                                               !prohibited_acc.includes(customer.account_status) ?
                                                                <div>
                                                                <span className = "exp badge badge-success">{ this.props.customer.expiry_status  ? 'CONTRACT IS EXPIRED' : '' }</span>
                                                                {
                                                                    customer.payment_method ? 
                                                                    <label className ="checkbox-data">
                                                                        <input type="checkbox"  onChange={(e) => this.status_change(e) } />  Check if customer contract is expired
                                                                    </label> : <label className ="checkbox-data">
                                                                        <input type="checkbox"  onChange={(e) => this.status_change(e) } />  Check if customer contract is expired
                                                                    </label>
                                                                }
                                                                </div> : null
                                                            }
                                                                
                                                            </div> 
                                                        </div>
                                                    </div>
                                                    <br/>
                                                    <div className = "row">
                                                        <div className="col-md-12">
                                                            <div className = "container-fluid">
                                                                {
                                                                    account_status == 1 ? 
                                                                    <table id = "" className="table table-lg tpaid_sh table-bordered"></table>
                                                                    : ''
                                                                }

                                                            </div>  
                                                            <div className="container-fluid customer_sch" id = "divToPrint">       
                                                            <a href="javascript:;" className="print-not-visible button-print btn btn-info" onClick={() => window.print() }><i className="fa fa-print"></i> Print Schedule</a>                                                                                                     
                                                            <br/>
                                                            <table id = "table-ledger" className="table table-sm tbl-ledger table-bordered"></table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


const mapStateToProps = state => ({
    app: state.feathersClient.app,
    customer: state.customers.customerInfo,
    payment: state.customers.customerPayments,
    model: state.category.modelsList,
    brand: state.category.brandsList,
    userData: state.login.userData,
    paymentTableData: state.customers.paymentTableData,
    userPermission: state.login.userPermission,
});

function mapDispatchToProps(dispatch) {
   return { actions: bindActionCreators(ActionCreators, dispatch) }
}

export default connect(mapStateToProps, mapDispatchToProps)(view_customer_secondhand);
