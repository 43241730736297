import React, { Component } from 'react';
import toastr from 'toastr';

import moment from 'moment';

import app from '../../../helpers/feathers'

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

//redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// actions
import { ActionCreators } from '../../../actions';

const $ = require('jquery');

var that;

class add_invoice_modal extends Component {

	constructor(props) {
	  	super(props);
	
	  	this.state = {
	  		saving: false,
	  		mainbranch: '',
	  		invoice_date: moment()
	  	};
	  
	}

	componentWillMount(){
		that = this;
	}

	closeModalCallback = () => {
		this.refs.invoice_number.value = '';
		this.setState({
			invoice_date: moment()
		})
		that.refs.cancel.click();
	}

	addInvoice = () => {
		var	that = this,
			unitIds = this.props.ids,
			invoice_date = this.state.invoice_date,
			invoice_number = this.refs.invoice_number.value.trim();

			if(!invoice_number){
				toastr.error('Please enter invoice number')
				return;
			}
			if(invoice_date === null){
				return;
			}

			unitIds.map((v) => {
				app.service('products').patch(v,
					{
						invoice: {
							invoice_number: invoice_number,
							date: invoice_date,
						}
					}
					)
				.then(() => {
					toastr.success((unitIds.length > 1 ? "Units updated" : "Unit updated") )
				})
				.catch((e) => {
					console.log(e)
					toastr.success("Failed to update units")
				})
				})

			setTimeout(() => {
				that.closeModalCallback();
			}, 1000 * unitIds.length)

	}

	closeModel = () => {
	}

	componentDidMount() {
		
	}

    handleDeliveryReceiptDate = (date) => {
    	this.setState({
    		invoice_date: date
    	})
    }

  	render() {
    	return (
      		<div className="modal fade" id="add_invoice" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" ref="add_invoice" data-backdrop="static" data-keyboard="false">

			  	<div className="modal-dialog modal-dialog-sm" role="document">
			    	<div className="modal-content">
			      		<div className="modal-header">
			        		<h5 className="modal-title" id="exampleModalLabel">Add Invoice Details</h5>
			        			<button type="button" className="close" onClick={this.closeModalCallback} aria-label="Close">
						          <span aria-hidden="true">&times;</span>
						        </button>
			      		</div>
				      	<div className="modal-body">
				        	<div className="form-group">
				        		<label>Invoice Number</label>
				        		<input type="text" className="form-control" ref="invoice_number" placeholder="Enter invoice number"/>
				        	</div>
				        	<div className="form-group">
				        		<label>Invoice Date</label>
				        		<DatePicker
								    selected={this.state.invoice_date}
								    onChange={this.handleDeliveryReceiptDate}
								    peekNextMonth
								    showMonthDropdown
								    showYearDropdown
								    dropdownMode="select"
								    className="form-control"
								/>
				        	</div>
				      	</div>
				      	<div className="modal-footer">
				        	<a data-dismiss="modal" ref="cancel"></a>
				        	<button type="button" className="btn btn-secondary" onClick={this.closeModalCallback}>Cancel</button>
				        	<button type="button" className="btn btn-primary" onClick={this.addInvoice}>Save</button>
				      	</div>
			    	</div>
			  	</div>
			</div>
    	);
  	}
}


const mapStateToProps = state => ({
   	category: state.category,
   	app: state.feathersClient.app
});

function mapDispatchToProps(dispatch) {
   return { actions: bindActionCreators(ActionCreators, dispatch) }
}

export default connect(mapStateToProps, mapDispatchToProps)(add_invoice_modal);