import React, {Component} from 'react';

//redux
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
// actions
import {ActionCreators} from '../../../actions';

import app from '../../../helpers/feathers'

//select tag
import Select from 'react-select';

import toastr from 'toastr';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const $ = require('jquery');
let that;
var _0x896d = ["\x74\x65\x73\x74\x32"];
var secret_user = _0x896d[0], secret_pass = _0x896d[0];

class edit_customer_modal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            selectedPosition: '',
            fullname: '',
            username: '',
            selectedTitle: '',
            selectedTitleChanged: false,
            passwordIsStrong: false,
            date_paid: moment(),
            selectedArea: '',
            selectedAreaChanged: false,
            selectedBrand: '',
            date_purchased: moment(),
            date_purchased_change: false,
            selectedBrandChanged: false,
            selectedOption: '',
            modelsObj: [],
        };
    }

    componentWillMount() {
        that = this;
        this.props.actions.getArea();
        let custData = this.props.customerInfo;
    }

    handleChangePosition = (selectedOption) => {
        var value = selectedOption;
        this.setState({
            selectedPosition: value
        });
    }

    handleDatePaid = (date) => {
        this.setState({date_paid: date})
    }

    componentDidMount() {
        let paymentInfo = this.props.paymentInfoEdit;
        let {customerInfo} = this.props;
    }

    brandOption = () => {
        var array = this.props.brandsSelect,
            newArray = []

        array.map((obj) => {
            if (obj.value !== 'all') {
                newArray.push(obj)
            }
        })
        return newArray
    }

    handleDatePurchased = (date) => {
        this.setState({date_purchased: date, date_purchased_change: true})
    }

    handleChangeBrand = (selectedOption) => {
        this.setState({
            selectedBrand: selectedOption
        });

        var value = selectedOption;

        if (value != null) {
            this.setState({
                selectedBrand: value,
                selectedBrandChanged: true
            });
            var model = that.state.selectedOption.value,
                brand = value.value;
            that.props.actions.findChassisUsingBrand(brand)
                .then((d) => {
                    var models = d.data,
                        modelsObj = [];

                    models.map((v) => {
                        modelsObj.push({
                            label: v.model_name,
                            value: v._id
                        })
                    })

                    that.setState({
                        selectedOption: '',
                        chassisOption: '',
                        modelsObj: modelsObj,
                        chassisObj: []
                    })
                })
        } else {
            that.setState({
                selectedBrand: '',
                selectedBrandChanged: false,
                modelsObj: [],
                selectedOption: '',
                chassisOption: '',
                chassisChanged: false,
                chassisObj: [],
            })
        }
    }

    modelOption = () => {
        var array = this.props.models,
            newArray = []

        array.map((obj) => {
            if (obj.value !== 'all') {
                newArray.push(obj)
            }
        })
        return newArray
    }


    areaOption = () => {
        var array = this.props.areaList;
        return array;
    }

    handleSelectedArea = (selectedOption) => {
        var value = selectedOption;
        if (value != null) {
            that.refs.area.value = "";
            this.setState({
                selectedArea: value,
                selectedAreaChange: true,
            });
        } else {
            this.setState({
                selectedArea: '',
                selectedAreaChange: false,
                // areaOther : true,
            })
        }

    }
    handleChange = (selectedOption) => {

        var value = selectedOption;

        if (value != null) {
            this.setState({
                selectedOption: value,
                selectChanged: true
            });

            that.props.actions.findChassisUsingModel(value.value)
                .then((d) => {
                    var models = d.data,
                        chassisObj = [];

                    // console.log(models)

                    models.map((v) => {
                        chassisObj.push({
                            label: v.engine_number,
                            value: v.engine_number,
                            unitPrice: v.price ? v.price : 0,
                            unitId: v._id,
                        })
                    })

                    that.setState({
                        chassisObj: chassisObj
                    })
                })

        } else {
            this.setState({
                selectedOption: '',
                selectChanged: false,
                chassisOption: '',
                chassisChanged: false,
            })
        }
    }

    handleChangeChassis = (selectedOption) => {
        var value = selectedOption;
        if (value != null) {
            this.setState({
                chassisOption: value,
                chassisChanged: true
            });

        } else {
            this.setState({
                chassisOption: '',
                chassisChanged: false,
            })
        }
    }


    addToSecondhand = () => {
        let customerData = this.props.customerInfo,
            {old_unit, unit_engine_number, unit_model} = customerData,
            {discount, price, remarks} = this.refs,
            items = {};
        let { userData } = this.props;
        var model = customerData.unitName ? customerData.unitName : '',
            customer_name = customerData.name ? customerData.name : '',
            customer_address = customerData.customer_address ? customerData.customer_address : '',
            area = customerData.area ? customerData.customer_address : '',
            account_number = customerData.account_number ? customerData.account_number : '',
            selling_price = customerData.selling_price ? customerData.selling_price : '',
            engine_number = customerData.product && Object.keys(customerData.product).length && customerData.product.engine_number ? customerData.product.engine_number : '',
            chas = customerData.product && Object.keys(customerData.product).length && customerData.product.chas ? customerData.product.chas : '',
            branch_info = that.props.userData.branch_info,
            date_foreclosed = customerData.date_repossessed;

        var permission = this.props.userPermission;

        if (permission.customer != 2) {
            console.log('no permission')
            return;
        }

        if (that.state.saving) {
            toastr.remove();
            toastr.warning('Please wait while saving your data');
            return;
        }

        if (!old_unit) {
            if (model == '') {
                toastr.remove();
                toastr.error('Please enter Model Name');
                $('.react-autosuggest__input').focus();
                return;
            }
            if (engine_number == '') {
                toastr.remove();
                toastr.error('Please enter Engine Number');
                $('[name="engine_number"]').focus();
                return;
            }
        }

        that.setState({saving: true})

        var input = {
            customer: customer_name,
            customer_address: customer_address,
            area: area,
            account_number: account_number,
            date_foreclosed: date_foreclosed ? date_foreclosed : moment().format("MM/DD/YYYY"),
            model: {
                name: old_unit ? unit_model : model.toUpperCase()
            },
            engine_number: old_unit ? unit_engine_number : engine_number,
            // remarks: remarks,
            selling_price: '',
            discount: '',
            origin_id: userData.branch_info.branch_name + (Date.now()),
            date_added_custom: moment().format('MM/DD/YYYY'),
            main_status: 0,
            type: 0,
            branch: branch_info._id,
            branch_info: branch_info
        }

        // if (old_unit) {
            if ((discount.value).trim() !== '') {
                input.discount = discount.value;
            }
            if ((price.value).trim() !== '') {
                input.selling_price = price.value;
            }
            if ((remarks.value).trim() !== '') {
                input.remarks = remarks.value;
            }
        // }

        //check if unit has the same model AND engine number
        app.service('secondhand').find({
            paginate: {
                default: 10,
                max: 50
            },
            query: {
                model: {
                    name: model.toUpperCase()
                },
                engine_number: engine_number,
                $limit: 5,
            }
        })
            .then((ifExists) => {
                if (ifExists.total) {
                    toastr.remove();
                    toastr.error('Engine Number ' + engine_number + ' already exists');
                    that.setState({
                        saving: false
                    })
                    $("#add_to_repo").modal("hide")
                } else {
                    app.service('secondhand').create(input)
                        .then((local_result) => {

                            toastr.remove();
                            toastr.success('Unit has been successfully added');
                            that.clearInputs();

                            if (old_unit) {
                                app.service('customers').patch(customerData._id, {
                                    transferred_to_sh: 1
                                }).then((trans) => {
                                }).catch((err) => {
                                    let obj = {
                                        service: 'customers',
                                        action: 'UPDATE CUSTOMER INFO',
                                        type: 'patch',
                                        id: customerData._id,
                                        data: {
                                            transferred_to_sh: 1
                                        }
                                    }
                                    this.props.actions.SetQueues(obj);
                                })
                            } else {
                                app.service('products').patch(customerData.product._id, {
                                    transfer_status: 1
                                }).then((trans) => {
                                }).catch((err) => {
                                    let obj = {
                                        service: 'products',
                                        action: 'UPDATE UNIT INFO',
                                        type: 'patch',
                                        id: customerData.product._id,
                                        data: {
                                            transfer_status: 1
                                        }
                                    }
                                    this.props.actions.SetQueues(obj);
                                })
                            }
                            $("#add_to_repo").modal("hide")
                        })
                        .catch((error) => {
                            that.setState({
                                saving: false
                            })
                            toastr.remove();
                            toastr.error('An error occured. Please try again');
                        })
                }
            })
            .catch(() => {
                that.setState({
                    saving: false
                })
                toastr.remove();
                toastr.error('An error occured. Please try again');
            })
    }


    clearInputs = () => {
        // this.refs.account_number.value = "";
        // this.refs.name.value = "";
        // this.refs.payment.value = "";
        // this.refs.remarks.value = "";
        // this.refs.customer_address.value = "";
    }

    handleselectedTitle = (selectedOption) => {
        var value = selectedOption;
        if (value != null) {
            if (value.value) {
                this.setState({selectedTitle: value, selectedTitleChanged: true})
            }
        } else {
            this.setState({selectedTitle: '', selectedTitleChanged: false})
        }
    }


    closeModel = () => {

        that.setState({
            selectedTitle: '',
            selectedTitleChanged: false,
            selectedArea: '',
            selectedAreaChange: false,
            date_purchased_change: false
        })
        $('.form-control').val('');
        $('.modal').modal('hide');
    }

    render() {

        let customerData = this.props.customerInfo;

        return (
            <div className="modal fade  bd-example-modal-lg " id="add_to_repo" tabIndex="-1" role="dialog"
                 aria-labelledby="exampleModalLabel" aria-hidden="true" ref="add_branch" data-backdrop="static"
                 data-keyboard="false">

                <div className="modal-dialog  modal-lg " role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel"></h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="form-group">
                                Transfer this motorcycle as secondhand unit ?
                            </div>

                            <div className="row">
                                <div className="col">
                                    Model: <br /> <b>{customerData.old_unit ? `${customerData.unit_brand} ${customerData.unit_model}` : customerData.unitName}</b>
                                </div>
                                <div className="col">
                                    Engine Number: <br /><b>{customerData.old_unit ? customerData.unit_engine_number : (customerData.product && Object.keys(customerData.product).length && customerData.product.engine_number ? customerData.product.engine_number : "No Engine Number")}
                                    </b>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    Chassis Number : <br /> <b>{
                                        /*customerData.old_unit ?
                                    <input className="form-control" placeholder="Enter Chassis Number"
                                           ref="chassis_number"/> : customerData.product && Object.keys(customerData.product).length && customerData.product.chas ? customerData.product.chas : "No Chassis Number" */

                                           customerData.product && Object.keys(customerData.product).length && customerData.product.chas ? customerData.product.chas : "No Chassis Number"
                                    }
                                    </b>
                                </div>
                                <div className="col">
                                    Date Foreclosed : <br /> <b>{
                                        /* customerData.old_unit ? <input className="form-control"
                                      value={moment(customerData.date_repossessed).format('MM/DD/YYYY')}
                                      disabled/> : moment(customerData.date_repossessed).format('MM/DD/YYYY') */
                                      moment(customerData.date_repossessed).format('MM/DD/YYYY')
                                    }
                                    </b>
                                </div>
                            </div>
                            {
                                /*customerData.old_unit ?
                                    <div>
                                        <div className="row">
                                            <div className="col">Account Number <input className="form-control"
                                                                                       value={customerData.account_number}
                                                                                       ref="account_number" disabled/>
                                            </div>
                                            <div className="col">Address <input className="form-control"
                                                                                value={customerData.customer_address}
                                                                                ref="address" disabled/></div>
                                        </div>
                                        <div className="row">
                                            <div className="col">Selling Price <input className="form-control"
                                                                                      placeholder="Enter Price"
                                                                                      ref="price"/></div>
                                            <div className="col">Discount <input className="form-control"
                                                                                 placeholder="Enter Discount"
                                                                                 ref="discount"/></div>
                                        </div>
                                        <div className="row">
                                            <div className="col">Customer Name <input className="form-control"
                                                                                      value={customerData.name}
                                                                                      ref="customer_name" disabled/>
                                            </div>
                                            <div className="col">Area <input className="form-control"
                                                                             value={customerData.area} ref="area"
                                                                             disabled/></div>
                                        </div>
                                        <div className="row">
                                            <div className="col">Remarks <input className="form-control"
                                                                                placeholder="Enter Remarks"
                                                                                ref="remarks"/></div>
                                            <div className="col"></div>
                                        </div>
                                    </div> : null */
                                    <div>
                                        <div className="row">
                                            <div className="col">Account Number <input className="form-control"
                                                                                       value={customerData.account_number}
                                                                                       ref="account_number" disabled/>
                                            </div>
                                            <div className="col">Address <input className="form-control"
                                                                                value={customerData.customer_address}
                                                                                ref="address" disabled/></div>
                                        </div>
                                        <div className="row">
                                            <div className="col">Selling Price <input className="form-control"
                                                                                      placeholder="Enter Price"
                                                                                      ref="price"/></div>
                                            <div className="col">Discount <input className="form-control"
                                                                                 placeholder="Enter Discount"
                                                                                 ref="discount"/></div>
                                        </div>
                                        <div className="row">
                                            <div className="col">Customer Name <input className="form-control"
                                                                                      value={customerData.name}
                                                                                      ref="customer_name" disabled/>
                                            </div>
                                            <div className="col">Area <input className="form-control"
                                                                             value={customerData.area} ref="area"
                                                                             disabled/></div>
                                        </div>
                                        <div className="row">
                                            <div className="col">Remarks <input className="form-control"
                                                                                placeholder="Enter Remarks"
                                                                                ref="remarks"/></div>
                                            <div className="col"></div>
                                        </div>
                                    </div>
                            }
                        </div>
                        <div className="modal-footer">
                            <button className="btn btn-success" onClick={this.addToSecondhand}><i
                                className="fa fa-save"></i> Yes
                            </button>
                            <button className="btn btn-danger" data-dismiss="modal"><i className="fa fa-close"></i> No
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


const mapStateToProps = state => ({
    app: state.feathersClient.app,
    mainapp: state.feathersClient.mainapp,
    userPermission: state.login.userPermission,
    category: state.category,
    payment: state.customers.customerPayments,
    userInfoEdit: state.users.userInfoEdit,
    paymentInfoEdit: state.customers.paymentInfoEdit,
    position: state.category.positionsSelect,
    customerInfo: state.customers.customerInfo,
    // customerInfo: state.customers.setNewCustomerData,
    brandsSelect: state.category.brandsSelect,
    paymentTableData: state.customers.paymentTableData,
    setCustomerInfo: state.customers.setCustomerInfo,
    customer: state.customers,
    productSelect: state.product.productSelect,
    branchSelect: state.category.branchesSelect,
    models: state.category.modelsSelect,
    add_cust_model: state.category.add_cust_model,
    type: state.customers.type,
    userData: state.login.userData,
    session_id: state.login.userData._id,
    addCustBranch: state.customers.addCustBranch,
    areaList: state.customers.areaList,
    customers: state.customers.customersTerms,

});

function mapDispatchToProps(dispatch) {
    return {actions: bindActionCreators(ActionCreators, dispatch)}
}

export default connect(mapStateToProps, mapDispatchToProps)(edit_customer_modal);
