import React, {Component} from 'react';

import '../stylesheet/bootstrap/dist/css/bootstrap.min.css';
import '../stylesheet/font-awesome/css/font-awesome.min.css';

import {Link} from 'react-router-dom';
//custom styles
import '../stylesheet/styles.css';

import dashboardLogo from '../assets/icons/dashboard-purple.png';
import dashboardLogoGrey from '../assets/icons/dashboard-gray.png';
import inventoryLogo from '../assets/icons/inventory-purple.png';
import inventoryLogoGrey from '../assets/icons/inventory-gray.png';
import reportLogoPurple from '../assets/icons/reports-purple.png';
import reportLogoGrey from '../assets/icons/reports-gray.png';
import userLogo from '../assets/icons/user-purple.png';
import userLogoGrey from '../assets/icons/user-gray.png';
import settingLogo from '../assets/icons/settings-purple.png';
import settingLogoGrey from '../assets/icons/settings-gray.png';
import paymentsLogo from '../assets/icons/payments-purple.png';
import paymentsLogoGrey from '../assets/icons/payments-gray.png';
import div from "react-simple-tooltip"

import {MY_APP} from '../helpers'
import eversureLogo from "../assets/logo/eversure_logo.png";
import ex from "../assets/icons/x.svg";
import customerLogoBlue from "../assets/icons/customers-purple.png";
import customerLogo from "../assets/icons/customers-gray.png";
import accIconsBlue from "../assets/icons/reports-purple.png";
import accIcons from "../assets/icons/reports-gray.png";
import overdueIconBlue from "../assets/icons/overdue-purple.png";
import overdueIcon from "../assets/icons/overdue-gray.png";
import financialIconBlue from "../assets/icons/financial-statement-purple.png";
import financialIcon from "../assets/icons/financial-statement-gray.png";
import ledgerIconBlue from "../assets/icons/ledgers-purple.png";
import ledgerIcon from "../assets/icons/ledgers-gray.png";

const $ = require('jquery');

export default class global_sidebar extends Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        $(".form-control").on('keyup change', function (e) {
            $(this).val($(this).val().toUpperCase());
        });
        this.toggleMenuOnLoad();
    }

    toggleDropdown = () => {
        this.setState({dropdownShown: !this.state.dropdownShown})
    }

    toggleMenu() {
        $("body .sidebar").fadeOut();
        $("body .nav-content__wrapper").css({left: "4px", width: "99%"});
        $("body .tab-nav-logo__wrapper").css({display: "block"});
        $("body .main-content").css({width: "100%", left: "0"});
    }

    toggleMenuOnLoad() {
        if (window.outerWidth <= 768) {
            $("body .sidebar").css({display: "none"});
            $("body .nav-content__wrapper").css({left: "4px", width: "99%"});
            $("body .tab-nav-logo__wrapper").css({display: "block"});
            $("body .main-content").css({width: "100%", left: "0", top: "20px"});
        } else {
            $("body .sidebar").fadeIn();
        }
    }

    render() {
        return (
            <ul className="nav flex-column dropdown">
                <div className="logo__wrapper">
                    <div className="system-type">
                        <div className="row">
                            <button className="tab-wrapper clear-button x-button-sidebar"
                                    onClick={this.toggleMenu}>
                                <img src={ex} alt="close"/>
                            </button>
                            {/* <Link
                                to={this.props.page === "landing_page" ? "/dash/" : "/"}
                                className="sidebar-logo"
                            > */}
                                <div className='sidebar-logo'>
                                    <img alt="icon" src={eversureLogo} className="header-logo"/>
                                </div>
                            {/* </Link> */}
                            <span onClick={this.toggleDropdown} className="">
		  				{this.props.page === "accounting" ? "Sales" : this.props.page === "old_records" ? "Old Records " : "Inventory"} &nbsp;
                                <i className={this.state.dropdownShown ? "fa fa-chevron-up" : "fa fa-chevron-down"}/>
		  			    </span>
                            {
                                this.state.dropdownShown ?
                                    // <div className="dropdown">
                                    this.props.page === "old_records" ?
                                        <ul className="dropdown-content">
                                            <li>
                                                <Link to={this.props.page === "accounting" ? "/" : "/accounting/"}>
                                        <span
                                            className="dropdown-selection">Sales </span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to={this.props.page === "inventory" ? "/" : "/inventory/"}>
                                        <span
                                            className="dropdown-selection">Inventory </span>
                                                </Link>
                                            </li>
                                        </ul> :
                                        <ul className="dropdown-content">
                                            <li>
                                                <Link to={this.props.page === "accounting" ? "/" : "/accounting/"}>
                                        <span
                                            className="dropdown-selection">Sales</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link
                                                    to={this.props.page === "old_records" ? "/" : "/old_records/"}>
                                        <span
                                            className="dropdown-selection">Old Records </span>
                                                </Link>
                                            </li>
                                        </ul>
                                    // </div>
                                    : null
                            }
                        </div>
                    </div>
                    <div content="Dashboard" className="sidebar__wrapper">
                        <li className={"nav-item " + (this.props.page === 'dashboard' ? 'active' : '')}>
                            <Link
                                to='/dash/'
                                className={"sidebarLabel " + (this.props.page === 'dashboard' ? 'active' : '')}
                                style={styles.linkColor}
                            >
                                <div className="sidebarIcons" style={styles.iconWrap}>
                                    <img
                                        alt="icon"
                                        src={this.props.page === 'dashboard' ? dashboardLogo : dashboardLogoGrey}
                                        className="sidebarLogo"
                                        style={{
                                            width: 30
                                        }}
                                    />
                                </div>
                                <div className={this.props.page === 'dashboard' ? "sidebarLabelBlue sidebarLabel" : "sidebarLabel"}>
                                    <span>Dashboard</span>
                                </div>
                            </Link>
                        </li>
                    </div>
                    <div content="Inventory" className="sidebar__wrapper">
                        <li className={"nav-item " + (this.props.page === 'inventory' ? 'active' : '')}>
                            <Link
                                to='/all_units/'
                                className="sidebarLabel"
                                style={styles.linkColor}
                            >
                                <div className="sidebarIcons" style={styles.iconWrap}>
                                    <img
                                        alt="icon"
                                        src={this.props.page === 'inventory' ? inventoryLogo : inventoryLogoGrey}
                                        className="sidebarLogo"
                                        style={{
                                            width: 25
                                        }}
                                    />
                                </div>
                                <div className={this.props.page === 'inventory' ? "sidebarLabelBlue sidebarLabel" : "sidebarLabel"}>
                                    <span>Inventory</span>
                                </div>
                            </Link>
                        </li>
                    </div>
                    <div content="Payments" className="sidebar__wrapper">
                        <li className={"nav-item " + (this.props.page === 'payments' ? 'active' : '')}>
                            <Link to='/payments/' className="sidebarLabel" style={styles.linkColor}>
                                <div className="sidebarIcons" style={styles.iconWrap}>
                                    <img
                                        alt="icon"
                                        src={this.props.page === 'payments' ? paymentsLogo : paymentsLogoGrey}
                                        className="sidebarLogo"
                                    />
                                </div>
                                <div className={this.props.page === 'payments' ? "sidebarLabelBlue sidebarLabel" : "sidebarLabel"}>
                                    <span>Payments</span>
                                </div>

                            </Link>
                        </li>
                    </div>
                    <div content="Reports" className="sidebar__wrapper">
                        <li className={"nav-item " + (this.props.page === 'report' ? 'active' : '')}>
                            <Link to='/report' className="sidebarLabel" style={styles.linkColor}>
                                <div className="sidebarIcons" style={styles.iconWrap}>
                                    <img
                                        alt="icon"
                                        src={this.props.page === 'report' ? reportLogoPurple : reportLogoGrey}
                                        className="sidebarLogo"
                                        style={{
                                            width: 25
                                        }}
                                    />
                                </div>
                                <div className={this.props.page === 'report' ? "sidebarLabelBlue sidebarLabel" : "sidebarLabel"}>
                                    <span>Reports</span>
                                </div>
                            </Link>
                        </li>
                    </div>
                    <div content="Users" className="sidebar__wrapper">
                        <li className={"nav-item " + (this.props.page === 'user' ? 'active' : '')}>
                            <Link to='/user' className="sidebarLabel" style={styles.linkColor}>
                                <div className="sidebarIcons" style={styles.iconWrap}>
                                    <img
                                        alt="icon"
                                        src={this.props.page === 'user' ? userLogo : userLogoGrey}
                                        className="sidebarLogo"
                                        style={{
                                            width: 27
                                        }}
                                    />
                                </div>
                                <div className={this.props.page === 'user' ? "sidebarLabelBlue sidebarLabel" : "sidebarLabel"}>
                                    <span>User</span>
                                </div>
                            </Link>
                        </li>
                    </div>
                    <div content="Settings" className="sidebar__wrapper">
                        <li className={"nav-item " + (this.props.page === 'settings' ? 'active' : (this.props.page === 'category' ? 'active' : ''))}>
                            <Link to='/settings' className="sidebarLabel" style={styles.linkColor}>
                                <div className="sidebarIcons" style={styles.iconWrap}>
                                    <img
                                        alt="icon"
                                        src={this.props.page === 'settings' ? settingLogo : settingLogoGrey}
                                        className="sidebarLogo"
                                        style={{
                                            width: 30
                                        }}
                                    />
                                </div>
                                <div className={this.props.page === 'settings' ? "sidebarLabelBlue sidebarLabel" : "sidebarLabel"}>
                                    Settings
                                </div>
                            </Link>
                        </li>
                    </div>
                    <li className={"app-version"}>
                        <small>
                            Version: <strong>{MY_APP.version}</strong>
                        </small>
                    </li>
                </div>
            </ul>
        );
    }
}

const styles = {
    linkColor: {
        color: "#4f4f4f"
    },
    iconWrap: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    }
}
