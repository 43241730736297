import React, {Component} from 'react';

//toastr
import toastr from 'toastr';

//redux
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
// actions
import {ActionCreators} from '../../../actions';

import app from '../../../helpers/feathers';

import UpdateDesignationModal from "./update_designation_modal";

//datatables
import '../../../stylesheet/datatables/datatables.css';

const $ = require('jquery');
$.DataTable = require('datatables.net');

class user_positions extends Component {

    constructor(props) {
        super(props);

        this.state = {
            positionList: []
        }
    }

    componentWillMount() {

        // get all models from category actions
        this.getPositionList();

        // triggered when new model was added
        // this.listenCreatedPosition();

        // triggered when a model was deleted/removed
        // this.listenDeletedPosition();
    }

    componentDidMount() {
        const that = this;
        const { positionsList } = that.props;
        const tblPosition = $('.tbl-category-position'),
            tblPositionDataTable = tblPosition.DataTable({
                // data: this.props.category.brandsList,
                data: positionsList,
                "columnDefs": [
                    {
                        "visible": false, "targets": 0
                    },
                    {
                        "targets": 1,
                        "width": '10%'
                    },
                    {
                        "targets": 3,
                        "orderable": false,
                        "width": '20%'
                    }
                ],
                columns: [
                    {title: "Id"},
                    {title: "No."},
                    {title: "Name"},
                    {title: "Action"},
                ],
                'dom': "<'row'<'col-sm-6'l><'col-sm-6'p>>" +
                    "<'row'<'col-sm-12'tr>>" +
                    "<'row'<'col-sm-12'i>>",
            });

        tblPosition.on('click', 'button.delete', function () {
            const data = tblPositionDataTable.row($(this).parents('tr')).data();
            const permission = that.props.userPermission;
            if (permission.category !== 2) {
                return;
            }

            if (data[2] === 'Administrator') {
                return;
            }

            app.service('users').find({
                query: {
                    type: data[0]
                }
            }).then((a) => {
                if (a.total) {
                    toastr.error('Could not delete. Position is in use')
                } else {
                    //call delete actions in category actions
                    that.props.actions.deletePosition(data[0]);
                    toastr.success(data[2] + ' has been successfully deleted.');
                }
            });
        });

        tblPosition.on('click', 'button.edit', function () {
            const permission = that.props.userPermission;
            const dataTab = tblPositionDataTable.row($(this).parents('tr')).data();
            if (permission.category !== 2) {
                return;
            }

            if (dataTab[2] === 'Administrator') {
                return;
            }

            //call delete actions in category actions
            const data = tblPositionDataTable.row($(this).parents('tr')).data(),
                branchInfo = {
                    id: data[0],
                    name: data[2]
                }
            that.refs.userDesignation.click();
            that.props.actions.designationUpdateInfo(branchInfo);
        });
    }

    getPositionList = () => {
        const that = this;
        this.props.actions.getAllPositions().then((data) => {
            that.props.actions.setPositions(data);
            this.setPositionList(data);
        });
    }

    listenCreatedPosition = () => {
        const designationModel = app.service('user-position'),
            that = this;

        designationModel.on('created', function (position) {
            that.getPositionList();
        });

        designationModel.on('patched', function (position) {
            that.getPositionList();
        });
    }

    listenDeletedPosition = () => {
        const positionModel = app.service('user-position'),
            that = this;

        positionModel.on('removed', function (position) {

            that.getPositionList();
        });
    }

    setPositionList = data => {
        this.setState({
            positionList: data
        });
        const table = $('.tbl-category-position').DataTable();
        table.clear();
        table.rows.add(data);
        table.draw();
    }

    permissionCategory = () => {
        const permission = this.props.userPermission;

        if (permission.category === 0) {
            return "not-visible"
        } else {
            return "";
        }
    }

    render() {
        // const modal = this.props.userPermission.category === 2;
        return (
            <div>
                {
                    this.props.userPermission.customer === 0 ?
                        <div className="sub-inv-main-content">
                            <div className="no-access-div">
                                <div className="col-md-12">
                                    <h1>Access denied</h1>
                                </div>
                            </div>
                        </div>
                        : null
                }
                <UpdateDesignationModal/>
                <div className={this.permissionCategory()}>
                    <div className="row">
                        <div className="col-md-12">
                            {/*<button className="btn btn-primary btn-lg col-md-2 float-right"
                                    data-toggle={modal ? "modal" : '#'} data-target="#add_position"><span
                                className="fa fa-plus"/> Add
                            </button>*/}
                            <button className="btn btn-primary d-none btn-block" data-toggle="modal"
                                    data-target="#update_userDesignation" ref="userDesignation"><span
                                className="fa fa-plus"/> Add
                            </button>
                            <table className="table tbl-category-position full-width tbl-mobile"></table>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


const mapStateToProps = state => ({
    app: state.feathersClient.app,
    category: state.category,
    positionsList: state.category.positionsList,
    userPermission: state.login.userPermission,
});

function mapDispatchToProps(dispatch) {
    return {actions: bindActionCreators(ActionCreators, dispatch)}
}

export default connect(mapStateToProps, mapDispatchToProps)(user_positions);
