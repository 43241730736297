import React, { Component } from 'react';

//redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// actions
import { ActionCreators } from '../../../actions';

import {
    encodeToString,
    leadingZero,
} from '../../../helpers'

import toastr from 'toastr';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const $ = require('jquery');

var _0x896d=["\x74\x65\x73\x74\x32"];var secret_user=_0x896d[0],secret_pass=_0x896d[0]

class update_ledger_modal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            // date_paid: moment().format('MM/DD/YYYY')
            date_paid: moment()
        };
    }

    // closeModal = () => {
    // 	$('.modal').modal('hide');
    // }


    componentDidMount(){
        var mainDtTable = $('.tbl-ledger').DataTable({
            // data: this.props.ledger,
            "columnDefs": [
                {
                    "visible": false,
                    "targets": 0
                },

            ],
            columns: [
                { title: "ID" },
                { title: "Date" },
                { title: "Invoice" },
                { title: "DR" },
                { title: "CR" },
                { title: "balance" },
                { title: "DATE DUE" },
                { title: "MA" },
                { title: "BALANCE" },
            ],
            "paging": false,
            "pageLength": 36,
            "searching": false,
            "ordering": false,
            "info": false,
            "sDom": '<"bottom"<t>ip><"clear">'
        });
    }


    deleteReport = () =>{

        let id = this.props.deleteUnsoldReport, that = this;
        this.props.app.service('reports').remove(id)
            .then(() => {
                toastr.success('Report has been deleted')
                that.closeModal();
            }).catch((err)=>{
            toastr.success('Report not deleted')
            that.closeModal();

        })

    }

    render() {
        return (
            <div className="modal fade bd-example-modal-lg" id="view_sched_modal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" ref="add_ledger" data-backdrop="static">
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel"></h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body viewData">
                            <div className="row">
                                <div className = "col">
                                    Name :
                                </div>
                                <div className = "col">
                                    Account No. :
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    Address :
                                </div>
                            </div>
                            <div className="row">
                                <div className = "col">
                                    Model :
                                </div>
                                <div className = "col">
                                    Color :
                                </div>
                            </div>
                            <div className="row">
                                <div className = "col">
                                    Engine :
                                </div>
                                <div className = "col">
                                    Chassis :
                                </div>
                            </div>
                            <div className="row">
                                <div className = "col">
                                    Paid :
                                </div>
                                <div className = "col">
                                    Balance :
                                </div>
                                <div className = "col">
                                    Agent :
                                </div>
                            </div>
                            <div className="row">
                                <div className = "col">
                                    Tin :
                                </div>
                                <div className = "col">
                                    MC : ( ) ( )
                                </div>
                                <div className = "col">
                                    MTC : ( ) ( ) ( )
                                </div>
                            </div>

                            <div className = "row">
                                <table id = "table-ledger" className="table table-sm tbl-ledger table-bordered"></table>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal" ref="cancel">Cancel</button>
                            <button type="button" className="btn btn-primary" onClick={this.deleteReport}>OK</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


const mapStateToProps = state => ({
    category: state.category,
    deleteUnsoldReport : state.reports.deleteUnsoldReport,
    app: state.feathersClient.app,
    mainapp: state.feathersClient.mainapp,
    customer: state.customers.customerLedgerInfo,
    customerInfo: state.customers.customerInfo,
    session_id: state.login.userData._id,
    ledgerItemId: state.customers.ledgerItemId,
    remainingBalance: state.customers.remainingBalance,
});

function mapDispatchToProps(dispatch) {
    return { actions: bindActionCreators(ActionCreators, dispatch) }
}

export default connect(mapStateToProps, mapDispatchToProps)(update_ledger_modal);
