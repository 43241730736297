import React, {Component} from 'react';
import toastr from 'toastr';

//redux
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
// actions
import {ActionCreators} from '../../../actions';
//select tag
import Select from 'react-select';

import app from '../../../helpers/feathers';

const $ = require('jquery');
const that = this;

class add_model_modal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            saving: false,
            mainbranch: '',
            selectedBranchChanged: false,
            selectedBrand: '',
        };
    }

    componentWillMount() {
        app.service('branches').find({
            query: {
                branch_name: 'MAIN'
            }
        }).then((d) => {
            this.setState({
                mainbranch: d.data[0]._id
            });
        })
        .catch((err) => {
            console.log(err)
        });
    }

    addModel = () => {
        const modelName = this.refs.modelName.value.trim(),
            u_modelName = modelName.toUpperCase(),
            brand = this.state.selectedBrand.value;

        if (!this.state.selectedBrandChanged) {
            toastr.remove();
            toastr.error('Please select a brand');
            return;
        }

        if (modelName.length < 1) {
            toastr.remove();
            toastr.error('Please enter model name');
            this.refs.modelName.value = '';
            return;
        }

        if (this.state.saving) {
            return;
        } else {
            this.setState({
                saving: true
            });
        }

        this.props.actions.addModel(u_modelName, this.state.mainbranch, brand).then((data) => {
            this.setState({
                saving: false,
                selectedBrand: ''
            });
            if (data === 'exist') {
                toastr.error(u_modelName + ' already exists.');
            } else if (data === 'success') {
                this.closeModal();
                toastr.success(u_modelName + ' has been successfully added');
            } else {
                toastr.error('Failed to add. Please try again.');
            }
        });
    }

    closeModal = () => {
        this.refs.modelName.value = '';
        this.refs.cancel.click();
        this.setState({
            selectedBrand: '',
            selectedBrandChanged: false
        });
    }

    brandOption = () => {
        const array = this.props.brandsSelect,
            newArray = []

        array.map((obj) => {
            if (obj.value !== 'all') {
                newArray.push(obj)
            }
        })
        return newArray
    }

    handleChangeBrand = (selectedOption) => {
        this.setState({
            selectedBrand: selectedOption
        });

        const value = selectedOption;

        if (value != null) {
            this.setState({
                selectedBrand: value,
                selectedBrandChanged: true
            });
        } else {
            this.setState({
                selectedBrand: '',
                selectedBrandChanged: false
            });
        }
    }

    render() {
        return (
            <div className="modal fade" id="add_model" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel"
                 aria-hidden="true" ref="addModel" data-backdrop="static" data-keyboard="false">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Add Motorcycle Model</h5>
                            <button type="button" className="close" onClick={this.closeModal} aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="form-group">
                                <label>Brand Name</label>
                                <Select
                                    name="selectBrand"
                                    value={this.state.selectedBrand.value}
                                    onChange={this.handleChangeBrand}
                                    options={this.brandOption()}
                                    clearableValue={true}
                                    placeholder="Select Brand"
                                />
                            </div>
                            <div className="form-group">
                                <label>Model Name</label>
                                <input type="text" className="form-control" ref="modelName"/>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <a data-dismiss="modal" ref="cancel"></a>
                            <button type="button" className="btn btn-secondary" onClick={this.closeModal}>Cancel
                            </button>
                            <button type="button" className="btn btn-primary" onClick={this.addModel}>Save</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    category: state.category,
    brandsSelect: state.category.brandsSelect,
});

function mapDispatchToProps(dispatch) {
    return {actions: bindActionCreators(ActionCreators, dispatch)}
}

export default connect(mapStateToProps, mapDispatchToProps)(add_model_modal);