import React, {Component} from 'react';

//redux
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
// actions
import {ActionCreators} from '../../../actions';

//main NavBar
import MainNav from '../../../components/global_nav';
import MainSideBar from '../../../components/global_sidebar';

//custom sidebar
import SubSideBar from './sub_sidebar';

//select tag
import Select from 'react-select';
//toastr
import toastr from 'toastr';

import Tooltip from "react-simple-tooltip"
import Autosuggest from 'react-autosuggest';

import app from '../../../helpers/feathers';

//daterange
import moment from 'moment';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import '../report/plugins/daterangepicker.css';

//custom styles
import '../../../stylesheet/styles.css';

//datatables
import '../../../stylesheet/datatables/datatables.css';
import ConfirmationDelete from "./confirmation_delete";

const $ = require('jquery');
$.DataTable = require('datatables.net');

let that;
const g_branch = localStorage.getItem('_branch_secret');
const _0x896d = ["\x74\x65\x73\x74\x32"], secret_user = _0x896d[0], secret_pass = _0x896d[0];

class inventory_second extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showModal: false,
            product: this.props.product.product_list,
            products: [],
            product_count: 0,
            selectedOption: '',
            models: this.props.models,
            filter_model: null,
            filter_color: null,
            filter_branch: null,
            selectChanged: false,
            selectBranchChanged: false,
            selectedBranch: '',
            selectedBrand: '',
            selectedBrandChanged: false,
            selectBranch: [
                {value: localStorage.getItem('local_branch_id'), label: localStorage.getItem('local_branch_name')}
            ],
            modelsObj: [],
            startDate: moment().subtract(29, 'days'),
            endDate: moment(),
            drPickerApplied: false,
            selectedDaterange: [],

            value: '',
            suggestions: [],
        };
    }

    componentWillMount() {
        // this.createListener();
        // this.fetchInventory();
        // this.getSecondhandUnits();
        this.saveModelsforAutoSuggest();

    }

    componentDidMount() {
        that = this;
        const ckr = [];
        this.props.branch.map(item => {
            ckr.push(item.value)
        })
        const branchExists = ckr.includes("all");
        if (!branchExists) {
            this.props.branch.unshift({
                value: 'all',
                label: 'All'
            })
        }

        const productTable = $('.tbl-inventory').DataTable({
            data: this.state.products,
            columns: [
                {"title": "model object"},
                {"title": "MODEL"},
                {"title": "ENGINE NO."},
                {"title": "DATE RECEIVED"},
                {"title": "SELLING PRICE"},
                {"title": "BRANCH"},
                {"title": "ACTION"}
            ],
            columnDefs: [
                {
                    targets: [5],
                    orderable: false,
                    width: '15%',
                },
                {
                    targets: [0],
                    visible: false
                }
            ],
            'dom': "<'row'<'col-sm-6'l><'col-sm-6'p>>" +
                "<'row'<'col-sm-12'tr>>" +
                "<'row'<'col-sm-12'i>>",
        });

        $('.search').keyup(function () {
            productTable.search($(this).val()).draw();
        });

        $('.tbl-inventory').on('click', '.view-inventory', function () {
            const data = productTable.row($(this).parents('tr')).data();
            this.props.actions.selectModel(data[0]);
            this.props.actions.getSelectedModel(data[0], false, null, null, null).then((products) => {
                this.props.actions.setselectedFilters(data[1], null, null)
                this.props.actions.selectedModelSet(products)
                this.props.history.push('/inventory/filter')
            });
        });

        $('.tbl-inventory').on('click', '.delete', function () {
            const data = productTable.row($(this).parents('tr')).data();
            const id = data[0]._id;
            const permission = that.props.userPermission;

            if (permission.inventory === 2) {
                that.props.actions.deleteId(id);
                that.props.actions.deleteService('secondhand');
                that.refs.confirm_delete.click();
            }
        });

        $('.tbl-inventory').on('click', '.edit', function () {
            const data = productTable.row($(this).parents('tr')).data();
            const permission = that.props.userPermission;

            if (permission.inventory !== 2) {
                toastr.error('Local Branch Unit(s) cannot be updated');
            } else {
                that.props.actions.updateSecondhandUnitInfo(data[0]);
                that.props.history.push('/update_secondhand');
            }
        });

        $('.tbl-inventory').on('click', '.view', function () {
            const data = productTable.row($(this).parents('tr')).data();
            that.props.actions.productInfo(data[0]);
            that.props.history.push('/view_secondhand');
        });

        this.createListener();
    }

    getSecondhandUnits = () => {
        const query = {};
        query.branch = this.props.userData.branch_info._id;
        query.type = 0;

        this.props.actions.secondhandAdvancedFilter(query)
        .then((data) => {
            this.productList(data);
        })
        .catch((err) => {
            console.log(err)
        })
    }

    fetchInventory = () => {
        const that = this,
            userData = this.props.userData

        that.props.actions.getProducts().then((data) => {
            that.props.actions.setProducts(data);
            // that.productList(data);
        });
    }

    productList = data => {
        this.setState({
            products: data,
            product_count: data.length
        });

        const table = $('.tbl-inventory').DataTable();
        table.clear();
        table.rows.add(data);
        table.draw();
    }

    createListener = () => {
        const products = app.service('secondhand'),
            that = this;

        products.on('created', products => {
            that.fetchInventory();
        });

        products.on('removed', products => {
            that.getSecondhandUnits();
        });
    }

    handleChange = (selectedOption) => {
        const value = selectedOption;

        if (value != null) {
            this.setState({
                selectedOption: value,
                filter_model: value,
                selectChanged: true
            });
        } else {
            this.setState({
                selectedOption: '',
                filter_model: '',
                selectChanged: false
            })
        }
    }

    handleChangeBranch = (selectedOption) => {
        const value = selectedOption;

        if (value === null) {
            this.setState({
                selectedBranch: '',
                filter_branch: value,
                selectBranchChanged: false
            });
        } else {
            this.setState({
                selectedBranch: value,
                filter_branch: value,
                selectBranchChanged: true
            });
        }
    }

    defaultBranch = (value) => {
        const userBranch = this.props.userData.branch;
        const array = this.props.branch, newArray = [];

        array.map((obj) => {
            if (userBranch === obj.value) {
                newArray.push({'branch': obj});
                const disable = obj.label !== 'MAIN';
                newArray.push({'isDisabled': disable})
            }
        });

        return value === 'getValue' ? (newArray.length > 0 ? newArray[0].branch.value : '') : newArray[1].isDisabled;
    }

    handleChangeBrand = (selectedOption) => {
        this.setState({
            selectedBrand: selectedOption
        });

        const value = selectedOption;

        if (value != null) {
            this.setState({
                selectedBrand: value,
                selectedBrandChanged: true
            });
            const model = this.state.selectedOption.value,
                brand = value.value === 'all' ? null : value.value;

            this.props.actions.findChassisUsingBrand(brand)
                .then((d) => {
                    const models = d.data,
                        modelsObj = [];

                    models.map((v) => {
                        modelsObj.push({
                            label: v.model_name,
                            value: v._id
                        })
                    })

                    this.setState({
                        selectedOption: '',
                        filter_model: '',
                        chassisOption: '',
                        modelsObj: modelsObj,
                    })
                })
        } else {
            this.setState({
                selectedBrand: '',
                selectedBrandChanged: false,
                modelsObj: [],
                selectedOption: '',
            })
        }
    }


    secondhandAdvancedFilter = () => {
        const model = this.state.value,
            engine_number = this.state.filter_color,
            branch = this.state.selectedBranch ? this.state.selectedBranch.value : this.defaultBranch('getValue'),
            daterange = this.state.drPickerApplied ? this.state.selectedDaterange : null,
            query = {},
            permission = this.props.userPermission;

        if (permission.inventory === 0) {
            return;
        }

        if (!model && !engine_number && !daterange && !branch) return;

        if (daterange) {
            query.date_added_custom = {
                $in: daterange
            }
        }

        if (model) {
            query.model = {
                name: model.toUpperCase()
            }
        }
        if (engine_number) {
            query.engine_number = {
                $regex: engine_number
            }
        }
        if (branch) {
            if (branch !== 'all') {
                query.branch = branch
            }
        }

        query.type = 0;
        this.props.actions.secondhandAdvancedFilter(query)
            .then((data) => {
                this.productList(data);
            })

    }

    permissionInventory = () => {
        const permission = this.props.userPermission;

        if (permission.inventory === 0) {
            return "not-visible"
        } else {
            return "";
        }
    }

    handleApply(event, picker) {
        that.setState({
            startDate: picker.startDate,
            endDate: picker.endDate,
            drPickerApplied: true
        });

        that.enumDate(picker.startDate, picker.endDate);
    }

    enumDate = (startDate, endDate) => {
        const dates = [];

        const currDate = moment(startDate).startOf('day');
        const lastDate = moment(endDate).startOf('day');

        while (currDate.add(1, 'days').diff(lastDate) < 0) {
            const mdate = moment(currDate.clone().toDate()).format('MM/DD/YYYY')
            dates.push(mdate);
        }
        dates.unshift(moment(startDate).format('MM/DD/YYYY'));
        dates.push(moment(endDate).format('MM/DD/YYYY'));

        this.setState({
            selectedDaterange: dates
        })
    }

    addSecondhand = () => {
        this.props.history.push('/inventory_add_secondhand')
    }

    saveModelsforAutoSuggest = () => {
        app.service('motorcycle-models').find()
            .then((data) => {
                const d = data.data,
                    models = [];

                d.map((v) => {
                    models.push({
                        name: v.model_name
                    })
                })

                this.props.actions.setModelsForSecondhand(models);
            })
            .catch((err) => {
                console.log(err)
            })
    }


    onChange = (event, {newValue}) => {
        this.setState({
            value: newValue
        });
    };

    // Auto suggest will call this function every time you need to update suggestions.
    // You already implemented this logic above, so just use it.
    onSuggestionsFetchRequested = ({value}) => {
        this.setState({
            suggestions: this.getSuggestions(value)
        });
    };

    // Auto suggest will call this function every time you need to clear suggestions.
    onSuggestionsClearRequested = () => {
        this.setState({
            suggestions: []
        });
    };

// languages = this.props.modelsForSecondhandUnits != undefined ? this.props.modelsForSecondhandUnits : [] ;

    // Teach Auto suggest how to calculate suggestions for any given input value.
    getSuggestions = value => {
        const inputValue = value.trim().toLowerCase();
        const inputLength = inputValue.length;
        const languages = this.props.modelsForSecondhandUnits

        return inputLength === 0 ? [] : languages.filter(lang =>
            lang.name.toLowerCase().slice(0, inputLength) === inputValue
        );
    };

    render() {
        const {value, suggestions} = this.state;
        const inputProps = {
            placeholder: 'Model Name',
            value,
            onChange: this.onChange,
            className: 'form-control'
        };

        // When suggestion is clicked, Auto suggest needs to populate the input
        // based on the clicked suggestion. Teach Auto suggest how to calculate the
        // input value for every given suggestion.
        const getSuggestionValue = suggestion => suggestion.name;

        // Use your imagination to render suggestions.
        const renderSuggestion = suggestion => (
            <div>
                {suggestion.name}
            </div>
        );

        return (
            <div className="">
                {/* main NavBar component*/}
                <MainNav historyProp={this.props.history} usersProp={this.props.userData}/>
                <ConfirmationDelete/>
                <div className="container-fluid">
                    <div className="row full-width">
                        <div className="sidebar">
                            <div className="sidebar-content">
                                <MainSideBar page="inventory"/>
                            </div>
                        </div>
                        {
                            /*<div className="row background-white"><h1>You have no access to this page</h1></div> */}
                        <SubSideBar history={this.props.history} allProps={this.props}
                                    userPermission={this.props.userPermission} pageName="second_main"/>
                        <div className="sub-inv-main-content">
                            {
                                this.props.userPermission.inventory === 0 ?
                                    <div className="row no-access-div">
                                        <div className="col-md-12">
                                            <h1>Access Denied</h1>
                                        </div>
                                    </div>
                                    : null
                            }
                            <div className={this.permissionInventory()}>
                                <div className="application-container">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="col-md-12">
                                                <div className="row col-md-12">
                                                    <div className="">
                                                        <h4 className="subheader-title">Units in Stock (Secondhand)</h4>
                                                    </div>
                                                    <div className="">
                                                        <button className="btn btn-second-primary add-new-btn"
                                                                onClick={() => this.addSecondhand()}>
                                                            <span><i className="fa fa-plus"/> Add</span>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-inline">
                                                {
                                                    // <strong className="marginR-15"></strong>
                                                }
                                                <div className="col-md-12 subheader-label">
                                                    ADVANCED FILTER
                                                </div>
                                                <div className="col-md-12">
                                                    <br/>
                                                </div>
                                                <div className="container-fluid">
                                                    <div className="row">
                                                        <div className="col inventory-daterangepicker">
                                                            <DateRangePicker
                                                                onApply={this.handleApply}
                                                                startDate={this.state.startDate}
                                                                endDate={this.state.endDate}
                                                            >
                                                                <button className="form-control">
                                                                    {
                                                                        this.state.drPickerApplied ? (moment(this.state.startDate).format('ll') + ' - ' + moment(this.state.endDate).format('ll')) : 'Select Date'
                                                                    }
                                                                </button>
                                                            </DateRangePicker>
                                                        </div>
                                                        <div className="col inventory_secondhand">
                                                            <Autosuggest
                                                                suggestions={suggestions}
                                                                onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                                                                onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                                                                getSuggestionValue={getSuggestionValue}
                                                                renderSuggestion={renderSuggestion}
                                                                inputProps={inputProps}
                                                                className="form-control"
                                                            />

                                                        </div>
                                                        <div className="col">
                                                            <input
                                                                className="form-control full-width"
                                                                placeholder="Engine No."
                                                                onChange={(e) => this.setState({filter_color: e.target.value})}
                                                            />
                                                        </div>
                                                        <div className="col">
                                                            <Select
                                                                name="select-branch"
                                                                value={this.state.selectedBranch.value ? this.state.selectedBranch.value : this.defaultBranch('getValue')}
                                                                onChange={this.handleChangeBranch}
                                                                disabled={this.props.userData.user_position_info.position_type !== 'ADMINISTRATOR' || this.props.userData.branch_info.branch_name !== 'MAIN'}
                                                                options={this.props.branch}
                                                                clearableValue={true}
                                                                placeholder="Branch"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-12">
                                                    <br/>
                                                </div>

                                                <div className="container-fluid">
                                                    <div className="row">
                                                        <div className="col-lg-3">
                                                            <button className="btn btn-second-primary btn-block"
                                                                    onClick={() => this.secondhandAdvancedFilter()}>Search
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <br/>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <a data-toggle="modal" data-target="#confirm_delete"
                                                               ref="confirm_delete"></a>
                                                            <table className="table tbl-inventory tbl-mobile">
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    app: state.feathersClient.app,
    branch: state.category.branchesSelect,
    brandsSelect: state.category.brandsSelect,
    models: state.category.modelsSelect,
    product: state.product,
    selectmodellist: state.category.modelsSelect,
    userData: state.login.userData,
    userPermission: state.login.userPermission,
    modelsForSecondhandUnits: state.product.modelsForSecondhandUnits,
});

function mapDispatchToProps(dispatch) {
    return {actions: bindActionCreators(ActionCreators, dispatch)}
}

export default connect(mapStateToProps, mapDispatchToProps)(inventory_second);
