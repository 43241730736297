import React, {Component} from 'react';
import app from '../../../helpers/feathers';

//redux
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
// actions
import {ActionCreators} from '../../../actions';

import {
    encodeToString,
    leadingZero,
} from '../../../helpers'

import toastr from 'toastr';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

toastr.options.fadeOut = 2500;

const $ = require('jquery');

class sh_recompute_modal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            first_payment_date: moment(),
            current: 1

        };
    }

    closeModel = () => {
        $('.modal').modal('hide');
    }

    handleFirstPaymentDate = (date) => {
        this.setState({first_payment_date: date})
    }


    counting = () => {
        var that = this;
        let i = 0,
            {customer} = this.props,
            customer_id = customer._id,
            account_number = customer.account_number,
            customer_name = that.refs.customer_name.value,
            promissory_note = that.refs.promissory_note.value,
            terms = that.refs.terms.value,
            { first_payment_date } = that.state,
            recompute_status = 0,
            SECONDHAND = true;


        that.props.actions.updateLedger(customer_id, 0, SECONDHAND, first_payment_date).then((result) => {
            var recursive = function (value) {
                if (value > 0) {
                    let start_date = first_payment_date,
                        due_date = moment(start_date).add(i, 'months').format('MM/DD/YYYY'),
                        balance = promissory_note;

                    let raw_data = {
                        customer_id: customer_id,
                        account_number: customer.account_number,
                        due_date: due_date,
                        monthly_amortization: (promissory_note / terms),
                        balance: balance,
                        promissory_note: promissory_note,
                        added_by: that.props.session_id,
                    }

                    that.props.actions.insertToLedger(raw_data, SECONDHAND)
                        .then((result) => {
                            return recursive(value - 1);
                        })
                        .catch((e) => {
                            console.log('error ', e)
                            return recursive(value - 1);
                        })

                    i += 1;
                } else {
                    // toastr.success('Ledger successfully created!');
                    $('#re_compute').modal('hide');
                    that.refs.promissory_note.value = '';
                }
            };
            recursive(terms)
        });

    }

    updateLedgerData = () => {

        let that = this,
            {customer} = that.props,
            customer_id = customer._id,
            account_number = customer.account_number,
            customer_name = that.refs.customer_name.value,
            promissory_note = that.refs.promissory_note.value,
            terms = that.refs.terms.value,
            start_date = that.state.first_payment_date,
            prev_customer_name = customer.name,
            recompute_status = 0;

        if (!(that.refs.promissory_note.value).trim()) {
            toastr.remove();
            toastr.info('Please enter promissory note');
            return;
        }else{
            if(isNaN((that.refs.promissory_note.value).trim())){
                toastr.remove();
                toastr.info('Please use numerical values for promissory note');
                return;
            }
        }
        if (!(that.refs.terms.value).trim()) {
            toastr.remove();
            toastr.info('Please enter Terms');
            return;
        }else{
            if(isNaN((that.refs.terms.value).trim())){
                toastr.remove();
                toastr.info('Please use numerical values for terms');
                return;
            }
        }
        this.props.actions.updateExpirySHLedger(customer_id)
            .then((data) => {
                toastr.remove();
                toastr.success('Customer Recomputed successfully');
            })

        app.service('customer-secondhand-unit').find({
            query: {
                account_number: account_number
            }
        }).then((array) => {

            const SECONDHAND = true;

            if (array.total) {
                let prev_customer = array.data[0].prev_customer_name;
                prev_customer.push(prev_customer_name)
                that.props.actions.reCompute(customer_id, customer_name, start_date, promissory_note, terms, recompute_status, prev_customer, SECONDHAND).then((result) => {
                    if (result) {
                        that.props.actions.changePaymentStatus(account_number, SECONDHAND);
                        that.props.actions.changeCustomerSchedule(account_number, SECONDHAND);
                        that.props.actions.setCustomerInfo(result);
                        that.counting(terms, promissory_note)
                    }
                });
            } else {
                let prev_customer = [];
                that.props.actions.reCompute(customer_id, customer_name, start_date, promissory_note, terms, recompute_status, prev_customer, SECONDHAND).then((result) => {
                    if (result) {
                        that.props.actions.changePaymentStatus(account_number, SECONDHAND);
                        that.props.actions.changeCustomerSchedule(account_number, SECONDHAND);
                        that.props.actions.setCustomerInfo(result);
                        that.counting(terms, promissory_note)
                    }
                });

            }
        })

    }


    render() {
        return (
            <div className="modal fade bd-example-modal-sm" id="re_compute" tabIndex="-1" role="dialog"
                 aria-labelledby="exampleModalLabel" aria-hidden="true" ref="re_compute" data-backdrop="static">
                <div className="modal-dialog modal-sm" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Recomputation </h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="form-group">
                                <label>Customer Name</label>
                                <input className="form-control form-valid" ref="customer_name"/>
                            </div>
                            <div className="form-group">
                                <label>Start Date</label>
                                <br/>
                                <DatePicker
                                    selected={this.state.first_payment_date}
                                    onChange={this.handleFirstPaymentDate}
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    className="form-control"
                                />
                            </div>
                            <div className="form-group">
                                <label>Promissory Note</label>
                                <input className="form-control" ref="promissory_note"/>
                            </div>
                            <div className="form-group">
                                <label>Terms</label>
                                <input className="form-control form-valid" ref="terms"/>
                            </div>

                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal"
                                    ref="cancel">Cancel
                            </button>
                            <button type="button" className="btn btn-primary"
                                    onClick={this.updateLedgerData}>Recompute
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


const mapStateToProps = state => ({
    category: state.category,
    reCompute: state.customers.recomputeData,
    app: state.feathersClient.app,
    customer: state.customers.customerInfo,
    session_id: state.login.userData._id
});

function mapDispatchToProps(dispatch) {
    return {actions: bindActionCreators(ActionCreators, dispatch)}
}

export default connect(mapStateToProps, mapDispatchToProps)(sh_recompute_modal);
