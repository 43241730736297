import React, {Component} from 'react';

// react-router
import {Link} from 'react-router-dom';
import {connect} from "react-redux";

class sub_sidebar extends Component {

    componentDidMount() {
    }

    createReport = () => {
        const pathName = this.props.history.location.pathname;
        let permission = this.props.userPermission;

        if (permission !== undefined) {
            if (permission.report !== 2) {
                return;
            }
        }
        if (pathName === '/report_sales_report') {
            this.props.history.push('/report_create_report_sold');
            return;
        }
        if (pathName === '/report_no_clearance') {
            this.props.history.push('/report_create_report_no_clearance');
            return;
        }
        if (pathName === '/report_with_warranty') {
            this.props.history.push('/report_create_report_with_warranty');
            return;
        }
        if (pathName === '/report_total_cost') {
            this.props.history.push('/report_create_report_total_cost');
            return;
        }
        if (pathName === '/report_tba') {
            this.props.history.push('/report_create_report_tba');
            return;
        }
        if (pathName === '/report_total_cost_sold') {
            this.props.history.push('/report_create_report_total_cost_sold');
            return;
        }
        if (pathName === '/report') {
            this.props.history.push('/report_create_report');
        }
    }

    handleChange(value) {
        this.props.history.push(`${value}`);
    }

    render() {
        const pathName = this.props.history.location.pathname;
        let permission = this.props.userPermission,
            inventory = permission ? permission.report === 0 ? "#" : "/report" : "/report",
            sales = permission ? permission.report === 0 ? "#" : "/report_sales_report" : "/report_sales_report",
            no_clearance = permission ? permission.report === 0 ? "#" : "/report_no_clearance" : "/report_no_clearance",
            with_warranty = permission ? permission.report === 0 ? "#" : "/report_with_warranty" : "/report_with_warranty",
            tba = permission ? permission.report === 0 ? "#" : "/report_tba" : "/report_tba",
            total_cost = permission ? permission.report === 0 ? "#" : "/report_total_cost" : "/report_total_cost",
            total_cost_sold = permission ? permission.report === 0 ? "#" : "/report_total_cost_sold" : "/report_total_cost_sold",
            cash_and_installments = permission ? permission.report === 0 ? "#" : "/report_cash_and_installments" : "/report_cash_and_installments",
            bir_report = permission ? permission.report === 0 ? "#" : "/bir_report" : "/bir_report";

        let x = {
            subnav: [
                {id: inventory, name: 'Unsold', create: '/report_create_report'},
                {id: sales, name: 'Sold', create: '/report_create_report_sold'},
                {id: no_clearance, name: 'No Clearance', create: '/report_create_report_no_clearance'},
                {id: tba, name: 'No TBA\'s', create: '/report_create_report_tba'},
                {id: with_warranty, name: 'Warranty Booklet', create: '/report_create_report_with_warranty'},
                {id: total_cost, name: 'Total Cost (Unsold)', create: '/report_create_report_total_cost'},
                {id: total_cost_sold, name: 'Total Cost (Sold)', create: '/report_create_report_total_cost_sold'},
                {id: cash_and_installments, name: 'Cash and Installments', create: '/report_cash_and_installments'},
                {id: bir_report, name: 'BIR', create: ''}
            ]
        };

        let subnav = x.subnav.length > 0
            && x.subnav.map((item, i) => {
                if (this.props.history.location.pathname === item.id) {
                    return (
                        <option key={i} value={item.id} selected>{item.name}</option>
                    )
                } else if (this.props.history.location.pathname === item.create) {
                    return (
                        <option key={i} value={item.create} selected>{item.name}</option>
                    )
                } else {
                    if (item.name === 'BIR') {
                        if (this.props.userData.branch_info.branch_name !== 'MAIN') {
                            return (
                                <option key={i} value={item.id}>{item.name}</option>
                            )
                        }
                    } else {
                        return (
                            <option key={i} value={item.id}>{item.name}</option>
                        )
                    }
                }
            }, this);

        return (
            <div className="">
                <div className="sub-sidebar inv-sub-sidebar print-not-visible">
                    {/*<ul className="nav flex-column list">*/}
                    {window.outerWidth <= 480 ?
                        <div className="sub-sidebar inv-sub-sidebar mobile-inv-dashboard__wrapper">
                            <ul className="list">
                                <li className="non-nav subheader__name">
                                    <h4>Reports </h4>
                                </li>
                                <div className="mobile-dropdown__wrapper">
                                    <select className="dropdown__select"
                                            onChange={event => this.handleChange(event.target.value)}>
                                        {subnav}
                                    </select>
                                </div>
                            </ul>
                        </div> :
                        <ul className="list">
                            {/*<li className="nav-item non-nav subheader__name">*/}
                            <li className="non-nav subheader__name">
                                <h4 className="">Reports</h4>
                            </li>
                            <li className="nav-item">
                                <Link
                                    className={pathName === '/report' || pathName === '/report_create_report' ? "nav-link active-inventory" : "nav-link"}
                                    to={inventory}>Unsold</Link>
                            </li>
                            {
                                /*
                                <li className="nav-item">
                                    <Link
                                        className={pathName === '/report_sales_report' || pathName === '/report_create_sales_report' ? "nav-link active-inventory" : "nav-link"}
                                        to={sales}>Sold</Link>
                                </li>
                                */
                            }
                            <li className="nav-item">
                                <Link
                                    className={pathName === '/report_no_clearance' || pathName === '/report_no_clearance' ? "nav-link active-inventory" : "nav-link"}
                                    to={no_clearance}>No Clearance</Link>
                            </li>
                            <li className="nav-item">
                                <Link
                                    className={pathName === '/report_tba' || pathName === '/report_tba' ? "nav-link active-inventory" : "nav-link"}
                                    to={tba}>No TBA's</Link>
                            </li>
                            <li className="nav-item">
                                <Link
                                    className={pathName === '/report_with_warranty' || pathName === '/report_with_warranty' ? "nav-link active-inventory" : "nav-link"}
                                    to={with_warranty}>Warranty Booklet</Link>
                            </li>
                            <li className="nav-item">
                                <Link
                                    className={pathName === '/report_total_cost' || pathName === '/report_total_cost' ? "nav-link active-inventory" : "nav-link"}
                                    to={total_cost}>Total Cost (<small>Unsold</small>)</Link>
                            </li>
                            <li className="nav-item">
                                <Link
                                    className={pathName === '/report_total_cost_sold' || pathName === '/report_total_cost_sold' ? "nav-link active-inventory" : "nav-link"}
                                    to={total_cost_sold}>Total Cost (<small>Sold</small>)</Link>
                            </li>
                            <li className="nav-item">
                                <Link
                                    className={pathName === '/report_cash_and_installments' || pathName === '/report_cash_and_installments' ? "nav-link active-inventory" : "nav-link"}
                                    to={cash_and_installments}>
                                    Cash and Installments
                                </Link>
                            </li>
                            {this.props.userData.branch_info.branch_name === 'MAIN' ?
                                <li className="nav-item">
                                    <Link
                                        className={pathName === '/bir_report' || pathName === '/bir_report' ? "nav-link active-inventory" : "nav-link"}
                                        to={bir_report}>
                                        For BIR
                                    </Link>
                                </li> : ''}
                        </ul>
                    }
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    userData: state.login.userData,
});

export default connect(mapStateToProps)(sub_sidebar);