import React, {Component} from 'react';

//main NavBar
import MainNav from '../../../components/global_nav';
import MainSideBar from '../../../components/global_sidebar';

// moment
import moment from 'moment';
import Cleave from 'cleave.js/react';

import ReactDataSheet from 'react-datasheet';

//toastr
import toastr from 'toastr';
import Toggle from 'react-bootstrap-toggle';

//sub side bar
import SubSideBar from './sub_sidebar';

import app from '../../../helpers/feathers';

//custom styles
import '../../../stylesheet/styles.css';

import {
    numberWithCommas
} from '../../../helpers/';

//redux
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
// actions
import {ActionCreators} from '../../../actions';

const $ = require('jquery');
$.DataTable = require('datatables.net');

let that;

class view_inventory extends Component {


    constructor(props) {
        super(props);

        this.state = {
            activeTab: 'home',
            modelName: '',
            payment: [],
            paymentData: [],
            form: {},
            currentBalance: 0,
            add: false,
            delinquent: false,
            saving: false,
            toggleActive: false,
            transferred_from: false
        };
    }

    componentWillMount() {
        that = this;
        if (this.props.product.transferred_from_branch) {
            app.service('branches').find()
                .then((data) => {
                    var branches = data.data;

                    var b = [];

                    branches.map((v) => {
                        if (v._id === this.props.product.transferred_from_branch) {
                            b.push({
                                branch: v.branch_name
                            });
                        }
                    });

                    setTimeout(() => {
                        that.setState({
                            transferred_from: b[0].branch
                        })
                    }, 100)
                })
        }
    }

    componentDidMount() {

        $('.tbl-inventory').addClass('not-visible');

        $('.table-due-date').DataTable({
            "paging": false,
            "pageLength": 36,
            "searching": false,
            "ordering": false,
            "info": false,
            "sDom": '<center<"bottom"<t>p><"clear">>'
        });
        var product = this.props.product;
    }

    render() {
        var product = this.props.product,
            model = product.motorModels == null ? product.unit_model_name : product.motorModels.model_name,
            brand = product.brands == null ? product.unit_brand_name : product.brands.brand_name,
            branch = product.branches !== null ? product.branches.branch_name : '--';

        return (
            <div className="">
                {/* main NavBar component*/}
                <MainNav historyProp={this.props.history} usersProp={this.props.userData}/>
                <div className="container-fluid">
                    <div className="row full-width">
                        <div className="sidebar">
                            <div className="sidebar-content">
                                <MainSideBar page="inventory"/>
                            </div>
                        </div>
                        <SubSideBar history={this.props.history} userPermission={this.props.userPermission}/>
                        <div className="sub-inv-main-content">
                            <div className="container-fluid">
                                {
                                    //<div className="d-flex flex-row justify-content-center content-header">
                                    //<button className="btn application-btn">APPROVE</button>
                                    //<button className="btn application-btn">REJECT</button>
                                    //</div>
                                }
                                <div className="application-container">
                                    <br/>
                                    <a href="javascript:;"
                                       onClick={() => this.props.history.goBack()}
                                       className="text-gray no-textdecoration breadcrumb-inv">
                                        <span className="fa fa-chevron-left"/> Inventory
                                        / {(brand ? brand : '') + ' ' + (model ? model : '')}
                                    </a>
                                    <br/>
                                    <br/>
                                    {/* <ul className="nav nav-tabs" role="tablist">
                                    <li role="presentation" className={this.state.activeTab === 'home' ? 'active' : ''} onClick={() => this.navigateTab('home')}><a href="javascript:;" aria-controls="home" role="tab" data-toggle="tab">PROFILE</a></li>
                                   <li role="presentation" className={this.state.activeTab === 'payments' ? 'active' : ''} onClick={() => this.navigateTab('payments')}><a href="javascript:;" aria-controls="payments" role="tab" data-toggle="tab">PAYMENTS</a></li>
                                </ul>*/}
                                    <div className="tab-content background-white">
                                        <div role="tabpanel"
                                             className={"hide-view-customer tab-pane view-cust " + (this.state.activeTab === 'home' ? 'active' : '')}
                                             id="home">
                                            <div className="container-fluid">
                                                <div className="personal-info">
                                                    <div className="row">
                                                        <h5><strong>UNIT INFORMATION</strong></h5>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col">
                                                            <span><strong>Brand:</strong> {brand ? brand : '--'}</span>
                                                        </div>
                                                        <div className="col">
                                                            <span><strong>Model:</strong> {model}</span>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col">
                                                            <span><strong>Branch:</strong> {branch}</span>
                                                        </div>
                                                        <div className="col">
                                                            <span><strong>Color:</strong> {product.color}</span>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col">
                                                            <span><strong>Chassis Number:</strong> {product.chas}</span>
                                                        </div>
                                                        <div className="col">
                                                            <span><strong>Engine Number:</strong> {product.engine_number} </span>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col">
                                                            <span><strong>Delivery Receipt Number:</strong> {product.delivery.receipt_number}</span>
                                                        </div>
                                                        <div className="col">
                                                            <span><strong>Date Received:</strong> {moment(product.delivery.date).format('MM/DD/YYYY')}</span>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col">
                                                            <span><strong>Invoice Number:</strong> {product.invoice.invoice_number ? product.invoice.invoice_number : '--'}</span>
                                                        </div>
                                                        <div className="col">
                                                            <span><strong>Invoice Date:</strong> {product.invoice.date ? moment(product.invoice.date).format('MM/DD/YYYY') : '--'}</span>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col">
                                                            <span><strong>Warranty Claims: </strong> {product.warranty_claims ? 'Yes' : 'None'}</span>
                                                        </div>
                                                        <div className="col">
                                                            <span><strong>Clearances: </strong> {product.clearances ? 'Yes' : 'None'}</span>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col">
                                                            <span><strong>TBA's (Tools, Batteries and Accessories): </strong> {product.tba ? 'Yes' : 'None'}</span>
                                                        </div>
                                                        <div className="col">
                                                            <span><strong>Price: </strong> {numberWithCommas(product.price ? product.price : 0)}</span>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col">
                                                                <span><strong>{product.transferred_from_branch ? (this.state.transferred_from ? "Transferred from:  " : '') : ''}</strong>
                                                                    {product.transferred_from_branch ? (this.state.transferred_from ? this.state.transferred_from + ' BRANCH' : '') : ''}</span>
                                                        </div>
                                                        <div className="col">
                                                            <span></span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <br/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


const mapStateToProps = state => ({
    app: state.feathersClient.app,
    customer: state.customers.customerInfo,
    payment: state.customers.customerPayments,
    model: state.category.modelsList,
    brand: state.category.brandsList,
    userData: state.login.userData,
    userPermission: state.login.userPermission,
    product: state.product.productInfo,
});

function mapDispatchToProps(dispatch) {
    return {actions: bindActionCreators(ActionCreators, dispatch)}
}

export default connect(mapStateToProps, mapDispatchToProps)(view_inventory);
