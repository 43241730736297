export const PRODUCT_LIST = "PRODUCT_LIST";
export const SELECTED_MODEL = "SELECTED_MODEL";
export const SELECTED_MODEL_SET = "SELECTED_MODEL_SET";
export const ADVANCED_FILTER = "ADVANCED_FILTER";
export const SET_ALL_PRODUCTS = "SET_ALL_PRODUCTS";
export const SELECTED_FILTERS = "SELECTED_FILTERS";
export const SET_ALL_SOLD_PRODUCTS = "SET_ALL_SOLD_PRODUCTS";
export const PRODUCTS_TO_TRANSFER = "PRODUCTS_TO_TRANSFER";
export const OUTGOING_PRODUCTS = "OUTGOING_PRODUCTS";
export const PRODUCT_INFO = "PRODUCT_INFO";
export const UPDATE_UNIT_INFO = "UPDATE_UNIT_INFO";
export const MODELS_FOR_SECONDHAND_UNITS = "MODELS_FOR_SECONDHAND_UNITS";
export const FOR_PRICING = "FOR_PRICING";
export const PRICES_UPDATED = "PRICES_UPDATED";
export const SET_INCOMING_PRODUCTS = "SET_INCOMING_PRODUCTS";
export const ACCEPT_DATA_ID = "ACCEPT_DATA_ID";
export const UPDATE_SECONDHAND_UNIT_INFO = "UPDATE_SECONDHAND_UNIT_INFO";
export const DELETE_ID = "DELETE_ID";
export const DELETE_SERVICE = "DELETE_SERVICE";

export const BRAND_NEW_UNITS = "BRAND_NEW_UNITS";
export const SECONDHAND_UNITS = "SECONDHAND_UNITS";
