import React, {Component} from 'react';

// react-router
import {Link} from 'react-router-dom';

//redux
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
// actions
import {ActionCreators} from '../../../actions';

//main NavBar
import MainNav from '../../../components/global_nav';
// import MainSideBar from '../../../components/global_sidebar';

import MainSideBar from '../../../components/acc_sidebar';
//sub side bar
import SubSideBar from './sub_sidebar';
import EditCustomerModal from '../accounting/edit_customer_modal';

//custom styles
import '../../../stylesheet/styles.css';

//datatables
import '../../../stylesheet/datatables/datatables.css';

import app from '../../../helpers/feathers';

const $ = require('jquery');
$.DataTable = require('datatables.net');

let that;

class OldRecordsCustomerFullypaid extends Component {

    constructor(props) {
        super(props);

        this.state = {
            data: [],
            customerName: "",
            accountNumber: ""
        };
    }

    componentWillMount() {
        that = this;
    }

    componentDidMount() {
        const applicationTbl = $('.tbl-old-records-installment').DataTable({
            // data: this.props.customers,
            "columnDefs": [
                {
                    "visible": false, "targets": 4
                },
                {
                    targets: [3],
                    orderable: false,
                    width: '10%'
                }
            ],
            "order": [[ 8, "desc" ]],
            columns: [
                {title: "NAME"},
                {title: "ACCOUNT NO."},
                {title: "AREA"},
                {title: "MODEL"},
                {title: "ENGINE NO."},
                {title: "DATE PURCHASED."},
                {title: "DATE CLOSED"},
                {title: "REMARKS"},
                {title: "DATE CREATED"},
                {title: "ACTION"}
            ],
            "sDom": '<"bottom"<t>ip><"clear">'
        });


        $('.search').keyup(function () {
            applicationTbl.search($(this).val()).draw();
        });

        $('.tbl-old-records-installment').on('click', 'button.btn-new-view', function () {
            const data = applicationTbl.row($(this).parents('tr')).data();
            that.props.actions.setCustomerInfo(data[10]);
            that.props.history.push('/view_customer_old_records');
        });

        $('.tbl-old-records-installment').on('click', 'button.btn-new-edit', function () {
            const data = applicationTbl.row($(this).parents('tr')).data();
            that.props.actions.setCustomerInfo(data[10]);
            $('#edit_customer').modal('show');
        });

        $('#edit_customer').on('hidden.bs.modal', function () {
            that.getLatestCustomers();
        })

        const custService = app.service('customers');

        custService.on('created', () => {
            // that.getAllCustomers();
        })
        this.getLatestCustomers();
    }

    getLatestCustomers = () => {
        that.props.actions.getOldRecordsWithFilterFullyPaid().then((data) => {
            if (data) {
                const d = data.data;
                that.props.actions.setTermsCustomers(d);
                that.updateData(d);
                const totalNum = d.length;
                this.setState({totalCustomer: totalNum});
            }
        })
    };

    // getAllCustomers = () => {
    //     this.props.actions.getFullyPaidCustomers().then((data) => {
    //     // this.props.actions.getAllCustomers().then((data) => {
    //         console.log('customers ', data)
    //         if(data){

    //             that.props.actions.setTermsCustomers(data);
    //             that.updateData(data);
    //         }
    //         else{
    //             // that.updateData([]);
    //         }


    //     });

    //     this.props.app.service('products').find()
    //     .then((data) => {
    //         // console.log('products ', data)
    //     })

    //     // this.props.app.service('customers').remove('5a7d7ebe6a03f17fb120f9cd')
    //     // .then(() => {

    //     // })

    // }

    getAllCustomers = (customerName, accountNumber) => {
        that.props.actions.getOldRecordsFullyPaid(customerName, accountNumber)
            .then((data) => {
                if (data) {
                    const d = data.data;
                    that.props.actions.setTermsCustomers(data);
                    that.updateData(d);
                    const totalNum = data.data.length;
                    this.setState({totalCustomer: totalNum});
                }
            });
    }

    advancedSearch = () => {
        const that = this,
            customerName = this.state.customerName,
            accountNumber = this.state.accountNumber,
            query = {};

        const permission = this.props.userPermission;

        if (!customerName && !accountNumber) {
            that.getLatestCustomers()
            return;
        }

        if (permission.inventory === 0) {
            return
        }
        if (customerName) {
            query.name = {
                $regex: customerName.toUpperCase()
            }
        }

        if (accountNumber) {
            query.account_number = {
                $regex: accountNumber
            }
        }

        that.getAllCustomers(customerName.toUpperCase(), accountNumber.toUpperCase());

    }

    fastSearch = () => {

        let {customerName, accountNumber} = this.state;

        if (customerName.length > 0 || accountNumber.length > 0) {
            that.advancedSearch()
        }
    }

    updateData = (data) => {
        this.setState({
            data: data
        });

        const table = $('.tbl-old-records-installment').DataTable();
        table.clear();
        table.rows.add(data);
        table.draw();
    }

    addRecord = () => {
        const permission = this.props.userPermission;

        if (permission.inventory === 2) {
            this.props.history.push('/old_record_add_customer_fullypaid');
        }
    }

    permissionCustomer = () => {
        const permission = this.props.userPermission;

        if (permission.customer === 0) {
            return "not-visible"
        } else {
            return "";
        }
    }

    render() {
        return (
            <div className="" id="main">
                {/* main NavBar component*/}
                <MainNav historyProp={this.props.history} usersProp={this.props.userData}/>
                <EditCustomerModal/>
                <div className="container-fluid">
                    <div className="row full-width">
                        <div className="sidebar">
                            <div className="sidebar-content">
                                <MainSideBar page="old_records"/>
                            </div>
                        </div>
                        <SubSideBar history={this.props.history} userPermission={this.props.userPermission}
                                    page="fully_paid"/>
                        <div className="sub-inv-main-content">
                            <div className="application-container">
                                <div className="">
                                    <div className="row my-inventory">
                                        <div className="col-md-12">
                                            <div className="col-md-12">
                                                <div className="row col-md-12">
                                                    <div className="">
                                                        <h4 className="subheader-title">Old Records Fully Paid</h4>
                                                    </div>
                                                    <div className="">
                                                        <button className="btn btn-second-primary add-new-btn"
                                                                onClick={() => this.addRecord()}>
                                                            <span><i className="fa fa-plus"/> Add</span>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-inline">
                                                <div className="col-md-12 subheader-label">
                                                    ADVANCED FILTER
                                                </div>
                                                <div className="col-md-12">
                                                    <br/>
                                                </div>
                                                <div className="container-fluid">
                                                    <div className="row">
                                                        <div className="col-md-4">
                                                            <input
                                                                className="form-control full-width"
                                                                placeholder="Enter Full name"
                                                                onChange={(e) => this.setState({customerName: e.target.value})}
                                                                onKeyUp={(e) => this.fastSearch()}
                                                            />
                                                        </div>
                                                        <div className="col-md-4">
                                                            <input
                                                                className="form-control full-width"
                                                                placeholder="Enter Account No."
                                                                onChange={(e) => this.setState({accountNumber: e.target.value})}
                                                                onKeyUp={(e) => this.fastSearch()}
                                                            />
                                                        </div>
                                                        <div className="col-md-4">
                                                            <button className="btn full-width btn-second-primary btn-sm"
                                                                    onClick={() => this.advancedSearch()}>Search
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="container-fluid">
                                        <div className="application-container">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <table className="table tbl-old-records-installment tbl-mobile"/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


const mapStateToProps = state => ({
    app: state.feathersClient.app,
    customers: state.customers.customersTerms,
    userData: state.login.userData,
    userPermission: state.login.userPermission,
});

function mapDispatchToProps(dispatch) {
    return {actions: bindActionCreators(ActionCreators, dispatch)}
}

export default connect(mapStateToProps, mapDispatchToProps)(OldRecordsCustomerFullypaid);
