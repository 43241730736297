import React, {Component} from 'react';

//redux
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
// actions
import {ActionCreators} from '../../../actions';

//main NavBar
import MainNav from '../../../components/global_nav';
import MainSideBar from '../../../components/global_sidebar';

//custom sidebar
import SubSideBar from './sub_sidebar';

//select tag
import Select from 'react-select';

import app from '../../../helpers/feathers';

//custom styles
import '../../../stylesheet/styles.css';

//datatables
import '../../../stylesheet/datatables/datatables.css';
import ConfirmationDelete from "./confirmation_delete";

const $ = require('jquery');
$.DataTable = require('datatables.net');

class duplicate_chassis extends Component {
    pageIsMounted = false;

    constructor(props) {
        super(props);

        this.state = {
            sold: [],
            productsToTransfer: [],
            selectedBrand: '',
            selectedOption: {value: 0, label: 'Chassis No.'},
            selectedBranch: '',
            filter_color: null,
            filter_chassis: null,
            filter_model: '',
            selectChanged: false,
            filter_branch: null,
            selectBranchChanged: false,
            product_count: 0,
            modelsObj: [],
            mainId: '',
            duplicate: [],
            isLoading: false,
        };
    }

    componentWillMount() {
        app.service('branches').find({
            paginate: {
                default: 10,
                max: 50
            },
            query: {
                branch_name: this.props.userData.branch_info.branch_name,
                $limit: 2,
            }
        }).then((d) => {
            const v = d.data[0]._id;

            this.setState({
                mainId: v
            })
            this.getDuplicates();
        })
        .catch((err) => {
            console.log(err)
        })
    }

    componentWillUnmount(){
        const that = this;
        that.pageIsMounted = false;
    }

    componentDidMount() {
        const that = this,
            productTable = $('.tbl-duplicate').DataTable({
                data: this.state.duplicate,
                columns: [
                    {"title": "ID"},
                    {"title": "MODEL NAME"},
                    {"title": "CHASSIS NO."},
                    {"title": "ENGINE NO."},
                    {"title": "BRANCH"},
                    {"title": "ACTION"},
                ],
                columnDefs: [
                    {
                        targets: [0],
                        visible: false
                    },
                    {
                        targets: [4],
                        orderable: false,
                        width: '5%',
                    },
                ],
                order: [[2, 'desc']],
                'dom': "<'row'<'col-sm-6'i><'col-sm-2'l><'col-sm-4'p>>" +
                    "<'row'<'col-sm-12'tr>>",
            });

        that.pageIsMounted = true;

        $('.search').keyup(function () {
            productTable.search($(this).val()).draw();
        });

        $('.tbl-duplicate').on('click', '.delete', function () {
            const data = productTable.row($(this).parents('tr')).data(),
                id = data[0];

            /*app.service('products').patch(id, {deletedInBranch: 1})
                .then(() => {
                    that.getDuplicates()
                })*/
            that.props.actions.deleteId(id);
            that.props.actions.deleteService('products');
            that.refs.confirm_delete.click();
            that.getDuplicates();
        });

        // that.createListener();
        this.getDuplicates();
    }

    createListener = () => {
        const products = app.service('products');

        products.on('removed', products => {
            this.getDuplicates();
        });
    }

    updateData = data => {
        this.setState({
            duplicate: data,
            product_count: data ? data.length : 0
        });

        const table = $('.tbl-duplicate').DataTable();
        table.clear();
        table.rows.add(data);
        table.draw();
    }

    getDuplicates = () => {
        const chassis_number = true;
        const that = this;

        if(that.pageIsMounted){
            const { isLoading } = that.state;
            const { userData } = that.props;
            const { _id, branch_name } = userData.branch_info;
            const isMainBranch = branch_name === 'MAIN' ? false : _id;
            if(isLoading) return;
            that.setState({isLoading: true});
            that.props.actions.countProducts(isMainBranch).then((result) => {
                if(result.status){
                    that.props.actions.recursiveProducts(result.data, isMainBranch).then((res) => {
                        that.props.actions.duplicateProducts(res.data).then((r) => {
                            that.setState({isLoading: false});
                            that.updateData(r);
                        })
                    })
                }else{
                    that.setState({isLoading: false});
                }
            })
        }

        // this.props.actions.duplicateProducts(chassis_number).then(data => {
        //     console.log(data)
        //     if(data){
        //         this.updateData(data);
        //     }
        // });
    }

    handleChange = (selectedOption) => {
        const value = selectedOption;

        if (value != null) {
            this.setState({
                selectedOption: value,
                filter_model: value,
                selectChanged: true
            });

            if (value.value === 1) {
                this.props.history.push('/inventory_duplicate_entries_engine')
            }
        } else {
            this.setState({
                selectedOption: '',
                filter_model: value,
                selectChanged: false
            })
        }
    }

    filterOutgoingProducts = () => {
        const branch = this.state.mainId ? this.state.mainId : null,
            brand = this.state.selectedBrand ? this.state.selectedBrand.value : null,
            model = this.state.selectedOption ? this.state.selectedOption.value : null,
            color = this.state.filter_color ? (this.state.filter_color).trim() : this.state.filter_color,
            chassis_number = this.state.filter_chassis ? (this.state.filter_chassis).trim() : this.state.filter_chassis;

        if (!brand && !model && !color && !chassis_number) {
            this.refs.filter_color.value = null;
            this.refs.filter_chassis.value = null;
        } else {
            const filters = {
                brand,
                model,
                color,
                chassis_number,
                branch,
            };

            const query = {};

            if (brand) {
                if (brand !== 'all') {
                    query.brand = brand
                }
            }
            if (model) {
                if (model !== 'all') {
                    query.model = model
                }
            }
            if (color) {
                if (color.toLowerCase() !== 'all') {
                    query.color = color.toUpperCase()
                }
            }
            if (chassis_number) {
                query.engine_number = chassis_number
            }

            query.branch = branch;
            query.transferred = {
                $in: [1, 2]
            };
            query.type = 0;
            query.brandNew = 1;
            this.props.actions.outgoingProductsFilter(query)
                .then((data) => {
                    this.updateData(data);
                })
        }

    }

    branchOption = () => {
        const array = this.props.branch,
            newArray = [];

        array.map((obj) => {
            if (obj.value !== 'all' && obj.label !== 'MAIN') {
                newArray.push(obj)
            }
        })
        return newArray
    }

    render() {
        const { isLoading } = this.state;
        return (
            <div className="">
                {/* main NavBar component*/}
                <MainNav historyProp={this.props.history} usersProp={this.props.userData}/>
                <ConfirmationDelete/>
                <div className="container-fluid">
                    <div className="row full-width">
                        <div className="sidebar">
                            <div className="sidebar-content">
                                <MainSideBar page="inventory"/>
                            </div>
                        </div>
                        <SubSideBar history={this.props.history} allProps={this.props}
                                    userPermission={this.props.userPermission} pageName="duplicate_entries"/>
                        <div className="sub-inv-main-content">
                            <div className="container-fluid">
                                <div className="application-container">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <h4 className="subheader-title">Duplicate Entries</h4>
                                        </div>
                                        <div className="col-md-4">
                                            <Select
                                                name="select-model"
                                                value={this.state.selectedOption.value}
                                                onChange={this.handleChange}
                                                options={[
                                                    {value: 0, label: 'Chassis No.'},
                                                    {value: 1, label: 'Engine No.'},
                                                ]}
                                                clearableValue={true}
                                                placeholder="Select Type"
                                            />
                                        </div>
                                        <div className="col-md-4" style={{display: "flex", alignItems: "center"}}>
                                            {
                                                isLoading && (
                                                    <div className="spinner-grow spinner-grow-sm" role="status">
                                                        <span className="sr-only">Loading...</span>
                                                    </div>
                                                )
                                            }
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <a data-toggle="modal" data-target="#confirm_delete"
                                               ref="confirm_delete"></a>
                                            <table className="table tbl-duplicate tbl-mobile">
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    app: state.feathersClient.app,
    product: state.product.outgoingProducts,
    userData: state.login.userData,
    userPermission: state.login.userPermission,
    models: state.category.modelsSelect,
    brandsSelect: state.category.brandsSelect,
    branch: state.category.branchesSelect,
});

function mapDispatchToProps(dispatch) {
    return {actions: bindActionCreators(ActionCreators, dispatch)}
}

export default connect(mapStateToProps, mapDispatchToProps)(duplicate_chassis);
