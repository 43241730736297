import React, {Component} from 'react';

import CustomerModal from './select_customer_modal';

// react-router
import {Link} from 'react-router-dom';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {ActionCreators} from "../../../actions";
import Select from "react-select";

class sub_sidebar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            brandNew: [],
            secondHand: []
        };
    }

    componentDidMount() {
        let permission = this.props.userPermission;
        this.setState({
            brandNew: [
                {id: 'acc_customer', name: 'Installment'},
                {id: 'acc_customer_cash', name: 'Cash'},
                {id: 'fully_paid_customers', name: 'Fully Paid'},
                {id: 'customers_repossessed_units', name: 'Repossessed'}
            ],
            secondHand: [
                {id: 'customer_secondhand_unit', name: 'Installment'},
                {id: 'customer_secondhand_unit_cash', name: 'Cash'},
                {id: 'fully_paid_sh_customers', name: 'Fully Paid'},
                {id: 'customers_sh_repossessed_units', name: 'Repossessed'}
            ]
        });
    }

    add_customer = () => {
        const permission = this.props.userPermission;
        const pathName = this.props.history.location.pathname;
        if (permission !== undefined) {
            if (pathName === '/add_customer') {
                return;
            } else {
                this.props.history.push('/add_new_customer')
            }
        }
    };

    handleChange(value) {
        this.props.history.push(`${value}`);
    }

    render() {
        const pathName = this.props.history.location.pathname,
            {page, path} = this.props;
        let permission = this.props.userPermission,
            // link_installment = "/customer/",
            // link_cash = "/customer/cash",
            // link_bad_record = "/customer/badrecords"
            link_installment = "/acc_customer/",
            all_customers = "/all_customers",
            link_cash_customer = "/acc_customer_cash/",
            link_fully_paid = "/fully_paid_customers/",
            link_fully_paid_sh = "/fully_paid_sh_customers/",
            link_customer_repossessed = "/customers_repossessed_units/",
            link_customer_repossessed_sh = "/customers_sh_repossessed_units/",
            link_secondhand = "/customer_secondhand_unit/",
            link_secondhand_cash = "/customer_secondhand_unit_cash/",
            link_cash = "/customer_cash",
            link_bad_record = "/customer_badrecords",
            add_customer_btn = "modal",
            new_customer = "/add_customer";
        let {pageTitle} = this.props;

        let x = {
            subnav: [
                {id: link_installment, name: 'Installment'},
                {id: link_cash_customer, name: 'Cash'},
                {id: link_fully_paid, name: 'Fully Paid'},
                {id: link_customer_repossessed, name: 'Repossessed'},
            ]
        };
        let xSH = {
            subnavSH: [
                {id: link_secondhand, name: 'Installment'},
                {id: link_secondhand_cash, name: 'Cash'},
                {id: link_fully_paid_sh, name: 'Fully Paid'},
                {id: link_customer_repossessed_sh, name: 'Repossessed'},
            ]
        };

        let subnav = x.subnav.length > 0
            && x.subnav.map((item, i) => {
                if (this.props.history.location.pathname === item.id) {
                    return (
                        <option key={i} value={item.id} selected>{item.name}</option>
                    )
                } else {
                    return (
                        <option key={i} value={item.id}>{item.name}</option>
                    )
                }

            }, this);

        let subnavSH = xSH.subnavSH.length > 0
            && xSH.subnavSH.map((item, i) => {
                if (this.props.history.location.pathname === item.id) {
                    return (
                        <option key={i} value={item.id} selected>{item.name}</option>
                    )
                } else {
                    return (
                        <option key={i} value={item.id}>{item.name}</option>
                    )
                }
            }, this);

        this.brandNew = this.state.brandNew;
        this.secondHand = this.state.secondHand;
        this.brandNewList = this.brandNew.length > 0
            && this.brandNew.map((item, i) => {
                return (
                    <option key={i} value={item.id}>{item.name}</option>
                )
            }, this);
        this.secondHandList = this.secondHand.length > 0
            && this.secondHand.map((item, i) => {
                return (
                    <option key={i} value={item.id}>{item.name}</option>
                )
            }, this);
        return (
            <div>
                {/* select customer modal*/}
                <CustomerModal history={this.props.history}/>
                {/*Mobile*/}
                <div className="accounting-customer-mobile__wrapper">
                    <ul className="nav flex-column list">
                        <li className="non-nav-item margin-top-5 margin-left-3">
                            <h4>Customers</h4>
                        </li>
                        <li className="all-customers-mobile">
                            <Link to={all_customers}>All Customers</Link>
                        </li>
                        <li className="all-customers-mobile">
                            <Link to={"/queues"}>Queues</Link>
                        </li>
                        <div className="mobile-add-customer">
                            <button hidden={this.props.userData.branch_info.branch_name === 'MAIN' ||
                            this.props.path === '/acc_add_customer' && this.props.page === undefined}
                                    className="btn full-width padding-10-40" data-toggle={add_customer_btn}
                                    data-target={"#selectUnitType"}>NEW CUSTOMER
                            </button>
                        </div>
                        <div className="mobile-dropdown__wrapper"
                             hidden={this.props.path === '/acc_add_customer' && this.props.page === undefined}>
                            <div>
                                <label>Brand New</label>
                            </div>

                            <div className="mobile-dropdown__wrapper">
                                <select className="dropdown__select"
                                        onChange={event => this.handleChange(event.target.value)}>
                                    {subnav}
                                </select>
                            </div>
                        </div>
                        <div className="mobile-dropdown__wrapper"
                             hidden={this.props.path === '/acc_add_customer' && this.props.page === undefined}>
                            <div>
                                <label>Secondhand</label>
                            </div>
                            <div className="mobile-dropdown__wrapper">
                                <select className="dropdown__select"
                                        onChange={event => this.handleChange(event.target.value)}>
                                    {subnavSH}
                                </select>
                            </div>
                        </div>
                    </ul>
                </div>
                {/*End Mobile*/}

                {/*Web Display*/}
                <div className="sub-sidebar web-acc-dashboard__wrapper">
                    <ul className="nav flex-column list">
                        <li className="nav-item non-nav subheader__name">
                            <h4>Customers</h4>
                        </li>
                        <li className="nav-item">
                            <button hidden={this.props.userData.branch_info.branch_name === 'MAIN'}
                                    className="btn padding-10-40" data-toggle={add_customer_btn}
                                    data-target={"#selectUnitType"}>
                                NEW CUSTOMER
                            </button>
                        </li>
                        <li className={pageTitle === "CUSTOMERS_BRANDNEW" ? "nav-item active" : "nav-item"}>
                            <Link
                                className={pathName === '/all_customers/' ? "nav-link activeLabel" : (page === 'all_customers' ? "nav-link activeLabel" : "nav-link")}
                                to={all_customers}>All Customers</Link>
                        </li>
                        <li className={pageTitle === "QUEUES" ? "nav-item active" : "nav-item"}>
                            <Link
                                className={pathName === '/queues/' ? "nav-link activeLabel" : (page === 'queues' ? "nav-link activeLabel" : "nav-link")}
                                to={"/queues"}>Queues</Link>
                        </li>
                        <div className="space"/>
                        <li className="nav-item divider"/>
                        <div className="space"/>
                        <li className="">
                            <center>Brand New</center>
                        </li>
                        <li className={pageTitle === "CUSTOMERS_BRANDNEW" ? "nav-item active" : "nav-item"}>
                            <Link
                                className={pathName === '/acc_customer/' ? "nav-link activeLabel" : (page === 'acc_customer' ? "nav-link activeLabel" : "nav-link")}
                                to={link_installment}>Installment</Link>
                        </li>
                        <li className={pageTitle === "CUSTOMERS_BRANDNEW_CASH" ? "nav-item active" : "nav-item"}>
                            <Link
                                className={pathName === '/acc_customer_cash/' ? "nav-link activeLabel" : (page === 'acc_customer_cash' ? "nav-link activeLabel" : "nav-link")}
                                to={link_cash_customer}>Cash</Link>
                        </li>

                        <li className={pageTitle === "CUSTOMERS_FULLY_PAID" ? "nav-item active" : "nav-item"}>
                            <Link
                                className={pathName === '/fully_paid_customers/' ? "nav-link activeLabel" : (page === 'fully_paid_customers' ? "nav-link activeLabel" : "nav-link")}
                                to={link_fully_paid}>Fully Paid</Link>
                        </li>
                        <li className={pageTitle === "CUSTOMERS_REPOSSESSED" ? "nav-item active" : "nav-item"}>
                            <Link
                                className={pathName === '/customers_repossessed_units/' ? "nav-link activeLabel" : (page === 'customers_repossessed_units' ? "nav-link activeLabel" : "nav-link")}
                                to={link_customer_repossessed}>Repossessed</Link>
                        </li>
                        <div className="space"/>
                        <li className="nav-item divider"/>
                        <div className="space"/>
                        <li>
                            <center>Secondhand</center>
                        </li>
                        <li className={pageTitle === "CUSTOMERS_SECONDHAND" ? "nav-item active" : "nav-item"}>
                            <Link
                                className={pathName === '/customer_secondhand_unit/' ? "nav-link activeLabel" : "nav-link"}
                                to={link_secondhand}>Installment</Link>
                        </li>
                        <li className={pageTitle === "CUSTOMERS_SECONDHAND_CASH" ? "nav-item active" : "nav-item"}>
                            <Link
                                className={pathName === '/customer_secondhand_unit_cash/' ? "nav-link activeLabel" : "nav-link"}
                                to={link_secondhand_cash}>Cash</Link>
                        </li>
                        <li className={pageTitle === "CUSTOMERS_SH_FULLY_PAID" ? "nav-item active" : "nav-item"}>
                            <Link
                                className={pathName === '/fully_paid_sh_customers/' ? "nav-link activeLabel" : "nav-link"}
                                to={link_fully_paid_sh}>Fully Paid</Link>
                        </li>
                        <li className={pageTitle === "CUSTOMERS_SH_REPOSSESSED" ? "nav-item active" : "nav-item"}>
                            <Link
                                className={pathName === '/customers_sh_repossessed_units/' ? "nav-link activeLabel" : "nav-link"}
                                to={link_customer_repossessed_sh}>Repossessed</Link>
                        </li>
                        {
                            // <li className="nav-item">
                            //     <Link className={pathName === '/customer_cash' ? "nav-link activeLabel" : "nav-link"} to={link_cash}>Cash</Link>
                            // </li>
                            // <li className="nav-item">
                            //     <Link className={pathName === '/customer_badrecords' ? "nav-link activeLabel" : "nav-link"} to={link_bad_record}>Bad Records</Link>
                            // </li>
                        }
                    </ul>
                </div>
                {/*End Web Display*/}

                {/*TAB Display*/}
                <div className="sub-sidebar column-tab-content tab-acc-dashboard__wrapper">
                    <div className="row">
                        <h4 className="tab-h4">Customers</h4>
                        <div className="nav-item tab-add-new-customer">
                            <button hidden={this.props.userData.branch_info.branch_name === 'MAIN'}
                                    className="btn padding-10-40" data-toggle={add_customer_btn}
                                    data-target={"#selectUnitType"}>
                                NEW CUSTOMER
                            </button>
                        </div>
                    </div>
                    <div className="non-nav tab-subheader-label">
                        <ul className="nav flex-column list">
                            <li className={pageTitle === "CUSTOMERS_BRANDNEW" ? "nav-item active" : "nav-item"}>
                                <Link
                                    className={pathName === '/all_customers/' ? "nav-link activeLabel" : (page === 'all_customers' ? "nav-link activeLabel" : "nav-link")}
                                    to={all_customers}>All Customers</Link>
                            </li>
                            <li className={pageTitle === "QUEUES" ? "nav-item active" : "nav-item"}>
                                <Link
                                    className={pathName === '/queues/' ? "nav-link activeLabel" : (page === 'queues' ? "nav-link activeLabel" : "nav-link")}
                                    to={"/queues"}>Queues</Link>
                            </li>
                        </ul>
                    </div>
                    <div className="non-nav tab-subheader-label">
                        <h6>CUSTOMER LIST (Brand New) </h6>
                        <ul className="nav flex-column list">
                            <li className={pageTitle === "CUSTOMERS_BRANDNEW" ? "nav-item active" : "nav-item"}>
                                <Link
                                    className={pathName === '/acc_customer/' ? "nav-link activeLabel" : (page === 'acc_customer' ? "nav-link activeLabel" : "nav-link")}
                                    to={link_installment}>Installment</Link>
                            </li>
                            <li className={pageTitle === "CUSTOMERS_BRANDNEW_CASH" ? "nav-item active" : "nav-item"}>
                                <Link
                                    className={pathName === '/acc_customer_cash/' ? "nav-link activeLabel" : (page === 'acc_customer_cash' ? "nav-link activeLabel" : "nav-link")}
                                    to={link_cash_customer}>Cash</Link>
                            </li>

                            <li className={pageTitle === "CUSTOMERS_FULLY_PAID" ? "nav-item active" : "nav-item"}>
                                <Link
                                    className={pathName === '/fully_paid_customers/' ? "nav-link activeLabel" : (page === 'fully_paid_customers' ? "nav-link activeLabel" : "nav-link")}
                                    to={link_fully_paid}>Fully Paid</Link>
                            </li>
                            <li className={pageTitle === "CUSTOMERS_REPOSSESSED" ? "nav-item active" : "nav-item"}>
                                <Link
                                    className={pathName === '/customers_repossessed_units/' ? "nav-link activeLabel" : (page === 'customers_repossessed_units' ? "nav-link activeLabel" : "nav-link")}
                                    to={link_customer_repossessed}>Repossessed</Link>
                            </li>
                            <li className="nav-item divider"/>
                        </ul>
                    </div>
                    <div className="non-nav tab-subheader-label">
                        <h6>CUSTOMER LIST (Secondhand) </h6>
                        <ul className="nav flex-column list">
                            <li className={pageTitle === "CUSTOMERS_SECONDHAND" ? "nav-item active" : "nav-item"}>
                                <Link
                                    className={pathName === '/customer_secondhand_unit/' ? "nav-link activeLabel" : "nav-link"}
                                    to={link_secondhand}>Installment</Link>
                            </li>
                            <li className={pageTitle === "CUSTOMERS_SECONDHAND_CASH" ? "nav-item active" : "nav-item"}>
                                <Link
                                    className={pathName === '/customer_secondhand_unit_cash/' ? "nav-link activeLabel" : "nav-link"}
                                    to={link_secondhand_cash}>Cash</Link>
                            </li>
                            <li className={pageTitle === "CUSTOMERS_SH_FULLY_PAID" ? "nav-item active" : "nav-item"}>
                                <Link
                                    className={pathName === '/fully_paid_sh_customers/' ? "nav-link activeLabel" : "nav-link"}
                                    to={link_fully_paid_sh}>Fully Paid</Link>
                            </li>
                            <li className={pageTitle === "CUSTOMERS_SH_REPOSSESSED" ? "nav-item active" : "nav-item"}>
                                <Link
                                    className={pathName === '/customers_sh_repossessed_units/' ? "nav-link activeLabel" : "nav-link"}
                                    to={link_customer_repossessed_sh}>Repossessed</Link>
                            </li>
                        </ul>
                    </div>
                </div>
                {/*End TAB Display*/}
            </div>
        );
    }
}

const mapStateToProps = state => ({
    app: state.feathersClient.app,
    userData: state.login.userData,
    userPermission: state.login.userPermission,
});

function mapDispatchToProps(dispatch) {
    return {actions: bindActionCreators(ActionCreators, dispatch)}
}

export default connect(mapStateToProps, mapDispatchToProps)(sub_sidebar);
// export default sub_sidebar;
