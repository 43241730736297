import React, { Component } from 'react';
import toastr from 'toastr';

//redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// actions
import { ActionCreators } from '../../../actions';

const $ = require('jquery');

var that;

class update_branch_modal extends Component {

	constructor(props) {
	  	super(props);
	
	  	this.state = {
	  		id: null,
	  		userPosition: '',
	  		newBranchName: ''
	  	};  
	}

	componentWillMount() {
		that = this;
	}

	componentDidMount(){
	}

	closeModalCallback = () => {
		that.refs.cancel.click();
		that.refs.positionType.value = '';
	}

	updateDesignation = () => {
		
		var userPosition = this.state.userPosition,
			updatedPositionType = this.refs.positionType.value.trim(),
		 	that = this;

		if(userPosition === updatedPositionType){
			that.closeModalCallback();
			toastr.info('No changes made');
			return;
		}

		if(this.props.updateDesignation.name === updatedPositionType){
			that.closeModalCallback();
			toastr.info('No changes made');
			return;
		}

		this.props.actions.updateDesignation(this.props.updateDesignation.id, updatedPositionType)
		.then((res) => {
			that.closeModalCallback();
			if (res){
				toastr.success('Designation successfully updated!');
			}else{
				toastr.error('Could not update designation!');
			}
		})

	}

	onChangeBranch = (e) => {
		// console.log(e.target.value)
	}

  	render() {
    	return (
      		<div className="modal fade" id="update_userDesignation" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" data-keyboard="false">
			  	<div className="modal-dialog" role="document">
			    	<div className="modal-content">
			      		<div className="modal-header">
			        		<h5 className="modal-title" id="exampleModalLabel">Update Designation</h5>
			        			<button type="button" className="close" onClick={this.closeModalCallback} aria-label="Close">
						          <span aria-hidden="true">&times;</span>
						        </button>
			      		</div>
				      	<div className="modal-body">
				        	<div className="form-group">
				        		<label>Designation : <strong>{this.props.updateDesignation.name}</strong></label>
				        		<input type="text" key={(this.props.updateDesignation).length > 0 ? 'input' : 'data'} className="form-control" ref="positionType" defaultValue={this.props.updateDesignation.name} onChange={this.onChangeBranch}/>
				        	</div>
				      	</div>
				      	<div className="modal-footer">
				        	<a data-dismiss="modal" ref="cancel"></a>
				        	<button type="button" className="btn btn-secondary" onClick={this.closeModalCallback} >Cancel</button>
				        	<button type="button" className="btn btn-primary" onClick={this.updateDesignation}>Update</button>
				      	</div>
			    	</div>
			  	</div>
			</div>
    	);
  	}
}


const mapStateToProps = state => ({
   	category: state.category.updateModelInfo,
   	updateDesignation: state.category.updateDesignationInfo,
   	app: state.feathersClient.app
});

function mapDispatchToProps(dispatch) {
   return { actions: bindActionCreators(ActionCreators, dispatch) }
}

export default connect(mapStateToProps, mapDispatchToProps)(update_branch_modal);