import React, {
    Component
} from 'react';

//redux
import {
    connect
} from 'react-redux';
import {
    bindActionCreators
} from 'redux';
// actions
import {
    ActionCreators
} from '../../../actions';

//main NavBar
import MainNav from '../../../components/global_nav';
import MainSideBar from '../../../components/global_sidebar';

//sub side bar
import SubSideBar from './sub_sidebar';

//custom styles
import '../../../stylesheet/styles.css'

//datatables
import '../../../stylesheet/datatables/datatables.css';


//select tag
import Select from 'react-select';

//daterange
import moment from 'moment';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import './plugins/daterangepicker.css';

import toastr from 'toastr';

const $ = require('jquery'), that = this;
$.DataTable = require('datatables.net');

class CreateReport extends Component {

    constructor(props) {
        super(props);

        this.state = {
            table_data: [],
            brand: [],
            filter_reporttype: null,
            filter_daterange: null,
            filter_branch: '',
            filter_showby: null,
            filter_brand: null,
            filter_columnby: '',
            filter_columndata: null,
            sample: null,
            saving: false,
            drPickerApplied: false,
            date_today: moment().format('MM/DD/YYYY'),
            startDate: moment().subtract(29, 'days'),
            endDate: moment(),
            report_type: '',
            report_type_changed: false,
            selectReportType: [
                {value: 'no_clearance', label: 'No clearances or TBA\'s'},
                {value: 'with_warranty', label: 'With Warranty Claims'},
            ],
            selectedBranch: {value: 'all', label: 'All'},
            selectedModel: '',
            selectModelChanged: false,
            selectedBrand: '',
            selectedBrandChanged: false,
            selectedDaterange: false,
            modelsObj: [],
            selectedOption: [],
            result_count: 0,
        };
    }

    componentWillMount() {
        this.defaultBranch('getValue');
        this.table_data();
    }

    componentDidMount() {
        const dt_table = $('.tbl-create-report').DataTable({

            // data: this.state.table_data,
            columns: [
                {"title": "product object"},
                {"title": "MODEL"},
                {"title": "ENGINE"},
                {"title": "BRANCH"},
                {"title": "DATE RECEIVED"},
            ],
            columnDefs: [
                {
                    targets: [0],
                    visible: false
                }
            ],
            "bFilter": false,
            "paginationRowsPerPageOptions": [10, 15, 20, 25, 30],
            "paging": false,
            "pageLength": 10000000,
            "sDom": '<"bottom"<t>ip><"clear">'
        });
    }

    handleApply(event, picker) {
        this.setState({
            startDate: picker.startDate,
            endDate: picker.endDate,
            drPickerApplied: true
        });

        this.enumDate(picker.startDate, picker.endDate);
    }

    handleDate = (e, p) => {
        const d = p.startDate;
        const formatted = moment(d).format('MM/DD/YYYY');

        this.setState({
            date_today: formatted,
            drPickerApplied: true,
        });
    }

    enumDate = (startDate, endDate) => {
        const dates = [];
        const currDate = moment(startDate).startOf('day');
        const lastDate = moment(endDate).startOf('day');

        while (currDate.add(1, 'days').diff(lastDate) < 0) {
            const mdate = moment(currDate.clone().toDate()).format('MM/DD/YYYY')
            dates.push(mdate);
        }
        dates.unshift(moment(startDate).format('MM/DD/YYYY'));
        dates.push(moment(endDate).format('MM/DD/YYYY'));

        this.setState({
            selectedDaterange: dates
        });
    }

    applyReportFilter = () => {
        const query = {},
            daterange = this.state.selectedDaterange,
            branch = this.state.selectedBranch ? (this.state.selectedBranch.value === 'all' ? '' : this.state.selectedBranch.value) : this.state.selectedBranch,
            // brand = this.state.selectedBrand ? (this.state.selectedBrand.value === 'all' ? '' : this.state.selectedBrand.value) : this.state.selectedBrand,
            // model = this.state.selectedModel ? (this.state.selectedModel.value === 'all' ? '' : this.state.selectedModel.value) : this.state.selectedModel,
            brand = this.refs.unit_brand_name.value,
            model = this.refs.unit_model_name.value,
            report_type = this.state.report_type;

        if (daterange) {
            query.date_created = {
                $in: daterange
            }
        }
        if (branch) {
            query.branch = branch
        }
        if (brand) {
            query.unit_brand_name = {
                $regex: brand
            }
        }
        if (model) {
            query.unit_model_name = {
                $regex: model
            }
        }

        query.type = 0;

        this.props.actions.filterReport(query)
            .then((data) => {
                this.productList(data);
            });
    }

    table_data = () => {
        const query = {},
            branch = this.state.selectedBranch ? (this.state.selectedBranch.value === 'all' ? '' : this.state.selectedBranch.value) : this.state.selectedBranch
        if (branch) {
            query.branch = branch
        }
        query.type = 0;
        this.props.actions.getUnsoldUnits(query)
            .then((data) => {
                this.productList(data);
            });
    }

    productList = data => {
        this.setState({
            table_data: data,
            result_count: data.length,
        });

        const table = $('.tbl-create-report').DataTable();
        table.clear();
        table.rows.add(data);
        table.draw();
    }

    handleChangeReportType = (selectedOption) => {
        const value = selectedOption;

        if (value === null) {
            this.setState({
                report_type: '',
                report_type_changed: false,
            });
        } else {
            this.setState({
                report_type: value,
                report_type_changed: true
            });
        }
    }

    handleChangeBranch = (selectedOption) => {
        const value = selectedOption;

        if (value === null) {
            this.setState({
                selectedBranch: '',
                filter_branch: value,
                selectBranchChanged: false
            });
        } else {
            this.setState({
                selectedBranch: value,
                filter_branch: value,
                selectBranchChanged: true
            });
        }
    }

    defaultBranch = (value) => {
        const userBranch = this.props.userData.branch;
        const mainBranch = this.props.userData.branch_info.branch_name;
        const array = this.props.branch, newArray = [];
        array.map((obj) => {
            if (mainBranch === 'MAIN') {
                newArray.push({'branch': 'all'});
            } else if (userBranch === obj.value) {
                newArray.push({'branch': obj});
                newArray.push({'isDisabled': true});
                this.state.selectedBranch = newArray[0].branch;
            }
        });

        return value === 'getValue' ? newArray[0].branch.value : newArray[1].isDisabled;
    }

    handleChangeModel = (selectedOption) => {
        const value = selectedOption;

        if (value != null) {
            this.setState({
                selectedModel: value,
                selectModelChanged: true
            });
        } else {
            this.setState({
                selectedModel: '',
                selectModelChanged: false
            })
        }
    }

    handleChangeBrand = (selectedOption) => {
        this.setState({
            selectedBrand: selectedOption
        });

        const value = selectedOption;

        if (value != null) {
            this.setState({
                selectedBrand: value,
                selectedBrandChanged: true
            });
            const model = this.state.selectedOption.value,
                brand = value.value === 'all' ? null : value.value;
            this.props.actions.findChassisUsingBrand(brand)
                .then((d) => {

                    const models = d.data,
                        modelsObj = [];

                    models.map((v) => {
                        modelsObj.push({
                            label: v.model_name,
                            value: v._id
                        });
                    });

                    this.setState({
                        selectedOption: '',
                        modelsObj: modelsObj,
                    });
                });
        } else {
            this.setState({
                selectedBrand: '',
                selectedBrandChanged: false,
                modelsObj: [],
                selectedOption: '',
            });
        }
    }

    saveReport = () => {
        const title = (this.refs.report_title.value).trim(),
            table_data = this.state.table_data,
            type = 0,
            drPickerApplied = this.state.drPickerApplied,
            startDate = drPickerApplied ? this.state.startDate : null,
            endDate = drPickerApplied ? this.state.endDate : null;

        const reportData = {
            report_type: type,
            report_title: title,
            user_id: this.props.userData._id,
            user_fullname: this.props.userData.fullname,
            table_data: table_data,
            daterange_start: startDate,
            daterange_end: endDate,
            branch: this.state.selectedBranch.value,
        }

        let {userPermission} = this.props,
            reportPermission = userPermission.report;

        if (reportPermission !== 2) {
            return;
        }

        if (!title) {
            toastr.remove();
            toastr.error('Please add report title');
            return;
        }

        if (this.state.saving) {
            toastr.info('Please wait while saving your report ...');
        } else {
            this.setState({
                saving: true
            });

            console.log('reportData', reportData);

            this.props.actions.saveReport(reportData)
                .then((d) => {
                    if (d) {
                        toastr.remove();
                        toastr.success('Report has been successfully saved');
                        this.refs.report_title.value = '';
                        this.props.history.push("/report");
                    } else {
                        toastr.remove();
                        toastr.error('An error occurred. Please try again');
                    }
                    this.setState({
                        saving: false
                    });
                }).catch(() => {
                toastr.remove();
                toastr.error('An error occurred. Please try again');
                this.setState({
                    saving: false
                });
            });
        }
    }

    render() {
        return (
            <div className="">
                {/* main NavBar component*/}
                <MainNav historyProp={this.props.history} usersProp={this.props.userData}/>
                <div className="container-fluid">
                    <div className="row full-width">
                        <div className="sidebar">
                            <div className="sidebar-content">
                                <MainSideBar page="report"/>
                            </div>
                        </div>
                        <SubSideBar history={this.props.history}/>
                        <div className="sub-inv-main-content">
                            <div className="container-fluid">
                                <div className="application-container">
                                    <div className="margin-y-24">
                                        <a href="#"
                                           onClick={() => this.props.history.goBack()}
                                           className="text-gray no-textdecoration breadcrumb-inv">
                                            <span className="fa fa-chevron-left"/> Reports
                                        </a>
                                        <div className="row padding-20 background-white create-report-unsold">
                                            <div className="col-md-12">
                                                <div className="row">
                                                    <div className="col">
                                                        <label>Branch</label>
                                                        <br/>
                                                        <Select
                                                            name="select-branch"
                                                            value={this.state.selectedBranch.value}
                                                            onChange={this.handleChangeBranch}
                                                            options={this.props.branch}
                                                            clearableValue={true}
                                                            placeholder="Select Branch"
                                                            disabled={this.defaultBranch('isDisabled')}
                                                        />
                                                    </div>
                                                    <div className="col">
                                                        <label>Brand</label>
                                                        <br/>
                                                        <input
                                                            className="form-control"
                                                            ref="unit_brand_name"
                                                            onKeyUp={() => this.applyReportFilter()}
                                                        />
                                                    </div>
                                                    <div className="col">
                                                        <label>Model</label>
                                                        <br/>
                                                        <input
                                                            className="form-control"
                                                            ref="unit_model_name"
                                                            onKeyUp={() => this.applyReportFilter()}
                                                        />
                                                    </div>
                                                </div>
                                                <br/>
                                                <div className="row">
                                                    {/*
                                                    <div className="col">
                                                        <label>Date Range</label>
                                                        <br/>
                                                        <DateRangePicker
                                                            onApply={this.handleApply}
                                                            startDate={this.state.startDate}
                                                            endDate={this.state.endDate}
                                                        >
                                                            <button className="form-control">
                                                                {
                                                                    this.state.drPickerApplied ? (moment(this.state.startDate).format('ll') + ' - ' + moment(this.state.endDate).format('ll')) : 'No daterange selected'
                                                                }
                                                            </button>
                                                        </DateRangePicker>
                                                    </div> */ }
                                                    <div className="col">
                                                        <label>&nbsp;</label>
                                                        <br/>
                                                        <button className="btn btn-block btn-second-primary"
                                                                onClick={() => this.applyReportFilter()}>Filter
                                                        </button>
                                                    </div>
                                                    <div className="col" />
                                                    <div className="col" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row padding-20 background-white">
                                            <div className="center-div">
                                                <button
                                                    className="btn btn-block btn-second-primary"
                                                    onClick={() => this.saveReport()}
                                                >
                                                    SAVE REPORT
                                                </button>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <h4 className="subheader-title">Inventory Reports / Unsold Units</h4>
                                        </div>
                                        <div className="row padding-20 background-white">
                                            <div className="col-md-4">
                                                <input
                                                    className="form-control report-title"
                                                    type="text"
                                                    placeholder="Report title here"
                                                    ref="report_title"
                                                />
                                                <br/>
                                                <p>By {this.props.userData.fullname}</p>
                                            </div>
                                            <div className="col-md-4">
                                            </div>
                                            <div className="col-md-4">
                                                <h5>INVENTORY REPORT</h5>
                                                <span>
                                        {
                                            moment().format('LL')
                                        }
                                        </span><br/>
                                                <span>{this.state.selectedBranch.label} BRANCH</span>
                                            </div>
                                            <div className="col-md-12">
                                                <br/>
                                            </div>
                                            <div className="col-md-12">
                                                <span>Result : {this.state.result_count}</span>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="header-pagination-container">
                                                    <ul className="pagination">

                                                    </ul>
                                                </div>
                                                <table className="table tbl-create-report tbl-mobile">
                                                </table>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    app: state.feathersClient.app,
    branch: state.category.branchesSelect,
    brandsSelect: state.category.brandsSelect,
    models: state.category.modelsSelect,
    userData: state.login.userData,
    userPermission: state.login.userPermission,
});

function mapDispatchToProps(dispatch) {
    return {actions: bindActionCreators(ActionCreators, dispatch)}
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateReport);