import React, {Component} from 'react';
import app from "../../../helpers/feathers";

// moment
import moment from 'moment';
import {IntlProvider, FormattedMessage, FormattedNumber} from 'react-intl';
import Cleave from 'cleave.js/react';

import ReactDataSheet from 'react-datasheet';
// import DatePicker from 'react-datepicker';
// import 'react-datepicker/dist/react-datepicker.css';

//toastr
import toastr from 'toastr';
// import Alert from 'react-bootstrap';
import Toggle from 'react-bootstrap-toggle';


//main NavBar
import MainNav from '../../../components/global_nav';
import MainSideBar from '../../../components/acc_sidebar';
//sub side bar
import SubSideBar from './sub_sidebar';

import AddPaymentModal from './add_payment_modal';
import AddDebitModal from './add_debit_modal';
import DeletePaymentModal from './delete_payment_modal';
import EditPaymentModal from './edit_payment_modal';
import ReComputeModal from './re_compute_modal';
import FullPaidModal from './full_paid_modal';
import PaymentHistoryModal from './payment_history_modal';

import defaultImage from '../../../assets/img/default_avatar.jpg';

import {twoDecimalPlaces, numberWithCommas} from '../../../helpers';

//custom styles
import '../../../stylesheet/styles.css';

//redux
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

// actions
import {ActionCreators} from '../../../actions';
import CustomerModal from './select_customer_modal';


const $ = require('jquery');
$.DataTable = require('datatables.net');

var that

class view_customer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            table_data: [],
            start_date: '',
            unit: '',
            editing: false,
            first_payment_date: moment(),
            currentBalance: 0,
            recompute_status: '',
            promisory_note: 0,
            monthly: '',
            status_paid: ''

        };
    }

    componentWillMount() {
        that = this;
        let {customer, total_payments} = this.props,
            prohibited_acc = ['1', '2'],
            promisory_note = customer.promisory_note,
            recompute_status = customer.recompute_status,
            down_payment = customer.payment,
            initialBal;

        if (recompute_status === '1') {
            initialBal = ((promisory_note - down_payment) - total_payments);
            this.setState({recompute_status: 'Original Customer'})
        } else {
            initialBal = (promisory_note - total_payments);
            this.setState({recompute_status: 'New Customer'})
        }

        that.getLedgerInfo(initialBal);

        that.props.actions.getFirstDate(customer.account_number)
            .then((result) => {
                // console.log('result ', result)
                that.setState({start_date: result.data})
            })

        // that.updateRemainingBalance(true)

        that.getCustomerPayments();
        // that.props.actions.clearData('customer-payments');
    }

    componentDidMount() {


        if ($('.status-badge').text() == 'EXPIRED') {
            $('.status-badge').show();
        } else {
            $('.status-badge').hide();
        }

        if ($('.status-badge').text() == 'EXPIRED') {
            $('.terms-container').text('99 months');
        }

        let {customer, total_payments} = this.props,
            {unit, start_date} = this.state,
            promisory_note = customer.promisory_note,
            down_payment = customer.payment,
            recompute_status = customer.recompute_status,
            initialBal = promisory_note - down_payment,
            terms = customer.term,

            ma = promisory_note / terms,
            remaining_balance;

        this.setState({
            monthly: ma
        });


        if (recompute_status === '1') {
            remaining_balance = ((promisory_note - down_payment) - total_payments);
        } else {
            remaining_balance = (promisory_note - total_payments);
        }


        var mainDtTable = $('.tbl-ledger').DataTable({
            data: this.props.ledger,
            "columnDefs": [
                {
                    "visible": false,
                    "targets": 0
                },
                // {
                //     "targets": 9,
                //     "width": '10%',
                // },
            ],
            columns: [
                {title: "ID"},
                {title: "DATE DUE"},
                {title: "MA"},
                // { title: "AMOUNT" },
                // { title: "DATE PAID" },
                // { title: "REBATE" },
                // { title: "OVERDUE" },
                {title: "BALANCE"},
                // { title: "REMARKS" },
                // { title: "" },
            ],
            "paging": false,
            "pageLength": 36,
            "searching": false,
            "ordering": false,
            "info": false,
            "sDom": '<"bottom"<t>ip><"clear">'
        });

        var paymentDtTable = $('.tbl-customer-payment').DataTable({
            data: this.props.paymentTableData,
            "columnDefs": [
                {
                    "visible": false,
                    "targets": 0
                },
            ],
            columns: [
                {title: ""},
                {title: "DATE"},
                {title: "OR NUMBER"},
                {title: "DR"},
                {title: "CR"},
                {title: "REBATE"},
                {title: " BALANCE"},
                {title: "REMARKS"},
                {title: "EDIT", className: 'no-print'},
            ],
            "paging": false,
            "pageLength": 100,
            "searching": false,
            "ordering": false,
            "info": false,
            "sDom": '<"bottom"<t>ip><"clear">'
        });


        $('.tbl-ledger').on('click', '.btn-new-edit', function () {
            var tr = mainDtTable.row($(this).parents('tr'))
            var row = $(this).parents('tr')[0],
                tds = $('>td', row),
                {editing} = that.state;
            var data = mainDtTable.row($(this).parents('tr')).data();

            that.props.actions.setLedgerItemId(data[0]);

            $('#update_ledger').modal('show');
            return;


            // $(this).parents('tr')
            //   .remove()
            //   .draw();

            //   return;


            if (editing) {
                toastr.warning('Action not allowed');
                return;
            } else {
                that.setState({editing: true})
            }


            tds[2].innerHTML = '<input type="text">'
            tds[3].innerHTML = '<input type="date" className="amount-paid-input" ref="amount_paid">'
            {/*tds[3].innerHTML = <DatePicker
                                    selected={that.state.first_payment_date}
                                    onChange={that.handleFirstPaymentDate}
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    className="form-control"
                                /> // date paid */
            }
            tds[4].innerHTML = '<input type="text" ref="rebate" className="rebate-input">'
            tds[8].innerHTML = '<button className="btn btn-sm btn-success save" title="Save"><span className="fa fa-check" /></button> <button className="btn btn-sm btn-danger cancel" title="Cancel"><span className="fa fa-close" /></button>'
        })

        $('.tbl-ledger').on('click', '.cancel', function () {
            that.setState({editing: false})
            that.getLedgerInfo(remaining_balance);
        })

        $('.tbl-ledger').on('click', '.save', function () {
            var amount_paid = $('.amount_paid').val(),
                rebate = $('.rebate').val();
        })

        $('#add_payment').on('hidden.bs.modal', function () {
            that.getCustomerPayments();
            // that.getLedgerInfo(remaining_balance);
            // that.updateRemainingBalance(false);
            $('#add_payment input#payment_input').val('');
            that.getStartDate();
            that.checkIfFullyPaid();
        })

        $('#add_debit').on('hidden.bs.modal', function () {
            that.getCustomerPayments();
            // that.getLedgerInfo(remaining_balance);
            // that.updateRemainingBalance(false);
            $('#add_debit input#payment_input').val('');
            that.getStartDate();
            that.checkIfFullyPaid();
        })

        $('#re_compute').on('hidden.bs.modal', function () {
            that.getCustomerPayments();
            $('#re_compute .form-valid').val('');
            that.getStartDate();
            that.checkIfFullyPaid();

            that.updateMa()
        })

        $('.tbl-customer-payment').on('click', '.edit', function () {
            var data = paymentDtTable.row($(this).parents('tr')).data();

            that.props.actions.setCustomerPayments(data[0]);

            $('#edit').modal('show');
        });

        $('.tbl-customer-payment').on('click', '.delete', function () {
            var data = paymentDtTable.row($(this).parents('tr')).data();

            that.props.actions.setPaymentId(data[0]._id);
            $('#delete_payment').modal('show');
        });

        $('#delete_payment').on('hidden.bs.modal', function () {
            that.getCustomerPayments();
            that.getStartDate();
            that.checkIfFullyPaid();
        })

        $('#edit').on('hidden.bs.modal', function () {
            that.getCustomerPayments();
            that.getStartDate();
            that.checkIfFullyPaid();
        })

        $('#full_paid').on('hidden.bs.modal', function () {
            that.props.history.push("/fully_paid_customers/")
        })

        that.checkIfFullyPaid();

    }

    updateMa = () => {
        let {customer, total_payments} = this.props,
            promisory_note = customer.promisory_note,
            terms = customer.term,
            ma = promisory_note / terms;

        this.setState({
            monthly: ma
        });
    }

    checkIfFullyPaid = () => {
        let {customer} = this.props,
            promisory_note = customer.promisory_note,
            down_payment = customer.payment,
            recompute_status = customer.recompute_status,
            remaining_balance;

        app.service('customer-payments').find({
            query: {
                account_number: customer.account_number,
                current: 1,
                $limit: 1,
                $sort: {
                    date_paid: -1
                }
            }
        }).then((data) => {
            if (data.total) {
                var last_date_paid = data.data[0].date_paid;
                that.props.actions.getCustomerPayment(customer.account_number, promisory_note)
                    .then((data) => {
                        let total_payments = data.total_payments;
                        remaining_balance = (promisory_note - total_payments);
                        if (remaining_balance <= 0) {
                            $('.modal').modal("hide"); // hide all modal if any exists
                            $('#full_paid').modal("show");
                            that.updateAccountStatus(moment(last_date_paid).format("MM/DD/YYYY"));
                        }
                    })
            }
        })
        .catch((err) => {
            console.log(err)
        })
    }

    updateAccountStatus = (last_date_paid) => {
        let {customer} = this.props;
        app.service("customers").patch(customer._id, {account_status: 1, date_closed: last_date_paid})
            .then((data) => {
                app.service("customers").find({
                    query: {
                        account_number: customer.account_number
                    }
                }).then((entry) => {
                    if (entry.data.length > 0) {
                        let mainId = entry.data[0]._id;
                        that.props.mainapp.service("customers").patch(mainId, {
                            account_status: 1,
                            date_closed: last_date_paid
                        })
                            .then((data) => {
                            }).catch((err) => {
                            console.log('ERROR MAIN', err)
                        })
                    }
                })
                    .catch((errors) => {
                        console.log('ERROR DATA LOCAL', errors)
                    })
            })
        .catch((err) => {
            console.log(err)
        })
    }

    getStartDate = () => {
        let {customer, total_payments} = this.props;
        that.props.actions.getFirstDate(customer.account_number)
            .then((result) => {
                that.setState({start_date: result.data})
            })
    }


    handleFirstPaymentDate = (date) => {
        this.setState({first_payment_date: date})
    }


    getCustomerPayments = () => {
        let {customer, total_payments} = this.props,
            promisory_note = customer.promisory_note,
            remaining_balance = (promisory_note - total_payments);

        that.props.actions.getCustomerPayment(customer.account_number, promisory_note)
            .then((res) => {
                if (res.status) {
                    that.updateDataTable('.tbl-customer-payment', res.data)
                } else {
                    that.updateDataTable('.tbl-customer-payment', [])
                }
            })
    }

    updateRemainingBalance = (initial) => {
        let {customer, total_payments} = this.props,
            down_payment = customer.payment,
            promisory_note = customer.promisory_note,
            recompute_status = customer.recompute_status,
            initialBal;
        if (recompute_status === '1') {
            initialBal = (promisory_note - total_payments);
        } else {
            initialBal = (promisory_note - total_payments);
        }


        that.props.actions.getTotalAmountPaid(customer.account_number)
            .then((res) => {
                let total_payments = res.data,
                    initialBalance = promisory_note - down_payment,
                    remainingBalance = initialBalance - total_payments;

                that.props.actions.setRemainingBalance(remainingBalance);
                that.setState({currentBalance: remainingBalance});
                if (!initial) {
                    that.props.actions.updateRemainingBalance(customer.account_number, remainingBalance)
                        .then((res) => {
                            if (res.status) {
                                that.getLedgerInfo(initialBal)
                            }

                        })
                }
            })
    }

    getLedgerInfo = (initialBal) => {

        let {customer} = this.props,
            {old_unit} = customer,
            account_number = customer.account_number;


        that.props.actions.customerLedger(account_number, initialBal)
            .then((result) => {
                if (result.status) {
                    // that.setState({table_data: result.data})
                    that.tableData(result.data)
                    that.updateDataTable('.tbl-ledger', result.data)
                }
            })
            .catch((e) => {
                console.log(e)
            })

        const query = {
            _id: old_unit ? 0 : customer.product.model
        }


        app.service('motorcycle-models').find()
            .then((result) => {
                if (result.data.length > 0) {
                    let brand = result.data[0].brand_details.brand_name,
                        model = result.data[0].model_name,
                        unit = `${brand} ${model}`
                    that.setState({unit: unit})
                }
            })
        .catch((err) => {
            console.log(err)
        })
    }

    tableData = (data) => {
        let dt = [];
        data.map((v, i) => {
            dt.push(
                <tr key={i}>
                    <td>{v.due_date}</td>
                    <td>{v.amount_paid}</td>
                    <td>{v.date_paid}</td>
                    <td>{v.rebate}</td>
                    <td>{v.overdue}</td>
                    <td>{v.balance}</td>
                    <td>{v.remarks}</td>
                    <td>
                        <button>Update</button>
                    </td>
                </tr>
            );
        })
        that.setState({table_data: dt})
    }

    updateDataTable = (table_class, data) => {
        const table = $(table_class).DataTable();
        table.clear();
        table.rows.add(data);
        table.draw();
    }

    addPayment = () => {
        let {customer} = this.props;
        that.props.actions.setLedgerItemId(customer);
        $('#add_payment').modal('show');
    }

    addDebit = () => {
        let {customer} = this.props;
        that.props.actions.setLedgerItemId(customer);
        $('#add_debit').modal('show');
    }

    reCompute = () => {
        let {customer} = this.props;

        that.props.actions.setLedgerItemId(customer);
        $('#re_compute').modal('show');
    }
    showPaymentHistory = () => {
        $("#payment_history").modal("show");
    }

    add_customer = () => {
        var permission = this.props.userPermission,
            that = this;

        if (permission !== undefined) {
            if (permission.accounting !== 2) {
                return
            } else {
                that.props.history.push('/add_new_customer')
            }
        }
    }

    addRecord = () => {
        // const permission = this.props.userPermission;
        // if (permission.inventory === 2) {
            this.props.history.push('/old_record_add_customer');
        // }
    }

    render() {
        let {customer, total_payments} = this.props,
            prohibited_acc = ['1', '2'],
            {unit, start_date} = this.state,
            {old_unit, unit_engine_number, unit_brand, unit_model,unit_chassis} = customer,
            branch_name = customer.branch_info ? customer.branch_info.branch_name : "",
            customer_id = customer._id,
            customer_name = customer.name,
            account_number = customer.account_number,
            engine_number = old_unit ? unit_engine_number : customer.product.engine_number,
            status = customer.expiry_status,
            chassis_number = old_unit ? '' : customer.product.chas,
            terms = customer.term,
            date_purchased = moment(customer.date_purchased).format('MMMM DD, YYYY'),
            first_payment_date = moment(customer.first_payment_date).format('MMMM DD, YYYY'),
            promisory_note = customer.promisory_note,
            down_payment = customer.payment,
            or_number = customer.or_number ? customer.or_number : '',
            paid_status = '',
            recompute_status = customer.recompute_status,
            add_customer_btn = "modal",
            remaining_balance;
        if (recompute_status === '1') {
            remaining_balance = (promisory_note - total_payments);
        } else {
            remaining_balance = (promisory_note - total_payments);

        }

        if (remaining_balance <= '0.00') {
            paid_status = 'FULLY PAID'
        } else {
            paid_status = ''

        }
        if (paid_status == '') {

        }

        if (customer.expiry_status == true) {
            status = 'EXPIRED';
        } else {
            status = 'ACTIVE';
        }

        return (
            <div className="">
                <MainNav historyProp={this.props.history}
                         usersProp={this.props.userData} systemType="accounting"/>
                <AddPaymentModal/>
                <AddDebitModal/>
                <DeletePaymentModal />
                <FullPaidModal/>
                <PaymentHistoryModal/>

                <CustomerModal history={this.props.history}/>
                <EditPaymentModal remainingBalance={remaining_balance}/>
                <ReComputeModal remainingBalance={remaining_balance} down_payment={down_payment}
                                customer_name={customer_name}/>
                <div className="container-fluid">
                    <div className="row full-width">
                        <div className="sidebar">
                            <div className="sidebar-content">
                                <MainSideBar page="ledger"/>
                            </div>
                        </div>
                        <div>
                            <div id="idNotToPrintLeft0" className="main-content">
                                <div className="row customer-ledger__wrapper">
                                    <div className="col-md-12">
                                        <div className="col no-print">
                                            <div className="row no-print">
                                                <div className="col-lg-12">
                                                    <div className="subheader-title">
                                                        Customer Ledger
                                                    </div>
                                                    <div className="row">
                                                        <div id="idNotToPrintThisDiv" className="col-lg-8">
                                                            <a href="javascript:;"
                                                               onClick={() => this.props.history.goBack()}
                                                               className="text-gray no-textdecoration">
                                                                <span className="fa fa-chevron-left"/> CUSTOMER LEDGER
                                                                / {customer.name}
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-12">
                                                    <br/>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className="web-ledger__wrapper customer-ledger__content-wrapper inline-block width-100p padding-tb-20">
                                            <div className="row label__wrapper">
                                                <div className="subheader-title">
                                                    {customer_name}
                                                </div>
                                                <div
                                                     className="padding-17">
                                                         {
                                                             this.props.userData.branch_info.branch_name === 'MAIN' ?
                                                            null :
                                                            <button className="btn btn-second-primary no-print"
                                                                    onClick={this.reCompute}>
                                                                RECOMPUTE
                                                            </button>
                                                         }
                                                    
                                                </div>
                                                <div className="padding-17">
                                                    <button className="btn btn-second-primary no-print"
                                                            onClick={() => window.print()}>
                                                        PRINT LEDGER
                                                    </button>
                                                </div>
                                                {/*<div className="col-md-12">*/}
                                                {/*    <div className="row padding-10">*/}
                                                {/*        <div className="col">*/}
                                                {/*            <table className="table customer-info-ledger">*/}
                                                {/*                <tbody>*/}
                                                {/*                <tr>*/}
                                                {/*                    <td>Account Number : <strong>{account_number}</strong></td>*/}
                                                {/*                    <td>Unit : <strong>{old_unit ? `${unit_brand} ${unit_model}` : unit}</strong></td>*/}
                                                {/*                    <td>Down payment : <strong>{numberWithCommas(down_payment)+'.00'}</strong></td>*/}
                                                {/*                    <td>Date of First Payment: <strong>{first_payment_date}</strong></td>*/}
                                                {/*                </tr>*/}
                                                {/*                <tr>*/}
                                                {/*                    <td>Customer Name : <strong>{customer_name}</strong></td>*/}
                                                {/*                    <td>Promissory Note : <strong>{promisory_note ? numberWithCommas(promisory_note)+'.00' : null}</strong></td>*/}
                                                {/*                    <td>Remaining Balance : <span className = "remain"><strong>{numberWithCommas(remaining_balance)+'.00'}</strong></span></td>*/}
                                                {/*                    <td> { paid_status ? <span className = "paid badge badge-primary"></span> : null }</td>*/}
                                                {/*                </tr>*/}
                                                {/*                <tr>*/}
                                                {/*                    <td>Term: <strong><span className = "terms-container">{terms} months</span></strong></td>*/}
                                                {/*                    <td>Engine Number : <strong>{engine_number}</strong></td>*/}
                                                {/*                    <td>Chassis Number : <strong>{chassis_number}</strong></td>*/}
                                                {/*                    <td>Status : <span className = "badge badge-primary"> { this.props.customer.recompute_status === '1' ? 'Original Customer' : 'New Customer'}  </span> <span className  = 'badge badge-success status-badge'>{ status }</span> </td>*/}
                                                {/*                </tr>*/}
                                                {/*                <tr className = "no-print">*/}
                                                {/*                    <td colSpan="4"><button className = "btn btn-primary no-print" onClick = {this.reCompute}> <i className = "fa fa-refresh"></i> RECOMPUTE</button> | <button className = "btn btn-primary no-print" onClick = {() => window.print()}><i className = "fa fa-print" ></i> PRINT LEDGER </button></td>*/}

                                                {/*                </tr>*/}
                                                {/*                </tbody>*/}
                                                {/*            </table>*/}
                                                {/*        </div>*/}
                                                {/*    </div>*/}
                                                {/*</div>*/}
                                            </div>
                                            <div className="row label__wrapper">
                                                <div className="customer-ledger__label-wrapper">
                                                    <div>Account Number</div>
                                                    <div><b>{account_number}</b></div>
                                                </div>
                                                <div className="customer-ledger__label-wrapper">
                                                    <div>Unit</div>
                                                    <div><b>{`${unit_brand} ${unit_model}`}</b></div>
                                                </div>
                                                <div className="customer-ledger__label-wrapper">
                                                    <div>Down payment</div>
                                                    <div><b>{numberWithCommas(down_payment) + '.00'}</b></div>
                                                </div>
                                                <div className="customer-ledger__label-wrapper">
                                                    <div>OR Number</div>
                                                    <div><b>{or_number}</b></div>
                                                </div>
                                            </div>

                                            <div className="row label__wrapper">
                                                <div className="customer-ledger__label-wrapper">
                                                    <div>Customer Name</div>
                                                    <div className="ledger-name-truncate"><b>{customer_name}</b></div>
                                                </div>
                                                <div className="customer-ledger__label-wrapper">
                                                    <div>Promissory Note</div>
                                                    <div>
                                                        <b>{promisory_note ? numberWithCommas(promisory_note) + '.00' : null}</b>
                                                    </div>
                                                </div>
                                                <div className="customer-ledger__label-wrapper">
                                                    <div>Remaining Balance</div>
                                                    <div><b> <span
                                                        className="remain"><strong>{numberWithCommas(remaining_balance) + '.00'}</strong></span></b>
                                                        {/*<span> { paid_status ? <span className = "paid badge badge-primary"></span> : null }</span>*/}
                                                    </div>
                                                </div>
                                                <div className="customer-ledger__label-wrapper">
                                                    <div>Date of First Payment</div>
                                                    <div><b>{first_payment_date}</b></div>
                                                </div>
                                                <div className="customer-ledger__label-wrapper">
                                                    <div></div>
                                                    <div><b>   {paid_status ?
                                                        <span className="paid badge badge-primary"></span> : null}</b>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="row label__wrapper">
                                                <div className="customer-ledger__label-wrapper">
                                                    <div>Term</div>
                                                    <div><b>{terms} months</b></div>
                                                </div>
                                                <div className="customer-ledger__label-wrapper">
                                                    <div>Engine Number</div>
                                                    <div><b>{engine_number}</b></div>
                                                </div>
                                                <div className="customer-ledger__label-wrapper">
                                                    <div>Chassis Number</div>
                                                    <div><b>{old_unit ? unit_chassis : chassis_number}</b></div>
                                                </div>
                                                <div className="customer-ledger__label-wrapper">
                                                    <div>Branch</div>
                                                    <div><b> <span
                                                        className="remain"><strong>{branch_name}</strong></span></b>
                                                    </div>
                                                </div>
                                                <div className="customer-ledger__label-wrapper">
                                                    <div>Monthly Amortization:</div>
                                                    <div><b>
                                                        {
                                                            !prohibited_acc.includes(customer.account_status) ?
                                                                !this.props.customer.type ? "N/A" :
                                                                    <FormattedNumber
                                                                        value={parseInt(this.state.monthly)}
                                                                        style="currency" currency="Php"/>
                                                                : 'N/A'
                                                        }
                                                    </b></div>
                                                </div>
                                                <div className="customer-ledger__label-wrapper">
                                                    <div>Status</div>
                                                    <div>
                                                        <b>{this.props.customer.recompute_status === '1' ? 'Original Customer' : 'New Customer'}
                                                            <span
                                                                className='badge badge-success status-badge'>{status}</span></b>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="padding-10 no-print sticky-division">
                                                <hr/>
                                            </div>
                                            <div className="no-print sticky">
                                                <button hidden={this.props.userData.branch_info.branch_name === 'MAIN'}
                                                        className="btn btn-second-primary" onClick={this.addPayment}>Add
                                                    Payment/Credit
                                                </button>
                                                &nbsp;
                                                <button hidden={this.props.userData.branch_info.branch_name === 'MAIN'}
                                                        className="btn btn-second-primary" onClick={this.addDebit}>Add
                                                    Debit
                                                </button>
                                                &nbsp;
                                                <button className="btn btn-second-primary"
                                                        onClick={this.showPaymentHistory}>Payment History
                                                </button>
                                                <button
                                                    className="ledger-add-customer-btn" data-toggle={add_customer_btn}
                                                    data-target={"#selectUnitType"}>
                                                    Add New Customer
                                                </button>
                                                <button
                                                    className="ledger-add-customer-btn margin-right-5"
                                                    onClick={() => this.addRecord()}>
                                                    Add Old Customer
                                                </button>
                                            </div>
                                            <div className="row">
                                                <br/>
                                            </div>
                                            <div className="row padding-bottom-20 overflow-y-auto">
                                                <div className="container-fluid">
                                                    <table
                                                        className="table table-sm tbl-customer-payment tbl-mobile table-bordered">
                                                    </table>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="mobile-ledger__wrapper">
                                            <div className="subheader-title">
                                                {customer_name}
                                            </div>
                                            <div hidden={this.props.userData.branch_info.branch_name === 'MAIN'}
                                                 className="padding-17">
                                                <button className="full-width btn btn-second-primary no-print"
                                                        onClick={this.reCompute}>
                                                    RECOMPUTE
                                                </button>
                                            </div>
                                            <div className="padding-17">
                                                <button className="full-width btn btn-second-primary no-print"
                                                        onClick={() => window.print()}>
                                                    PRINT LEDGER
                                                </button>
                                            </div>
                                            {/*<div className="row label__wrapper">*/}
                                            <div className="row">
                                                <div className="col">
                                                    <div>Account Number</div>
                                                    <div><b>{account_number}</b></div>
                                                </div>
                                                <div className="col">
                                                    <div>Unit</div>
                                                    <div><b>{!old_unit ? `${unit_brand} ${unit_model}` : unit}</b></div>
                                                </div>
                                            </div>
                                            <div className="row ledger-view-row-contents">
                                                <div className="col">
                                                    <div>Down payment</div>
                                                    <div><b>{numberWithCommas(down_payment) + '.00'}</b></div>
                                                </div>
                                                <div className="col">
                                                    <div>Date of First Payment</div>
                                                    <div><b>{first_payment_date}</b></div>
                                                </div>
                                            </div>
                                            <div className="row ledger-view-row-contents">
                                                <div className="col">
                                                    <div>Promissory Note</div>
                                                    <div>
                                                        <b>{promisory_note ? numberWithCommas(promisory_note) + '.00' : null}</b>
                                                    </div>
                                                </div>
                                                <div className="col">
                                                    <div>Branch</div>
                                                    <div><b> <span
                                                        className="remain"><strong>{branch_name}</strong></span></b>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row ledger-view-row-contents">
                                                <div className="col">
                                                    <div>Remaining Balance</div>
                                                    <div><b> <span
                                                        className="remain"><strong>{numberWithCommas(remaining_balance) + '.00'}</strong></span></b>
                                                        {/*<span> { paid_status ? <span className = "paid badge badge-primary"></span> : null }</span>*/}
                                                    </div>
                                                </div>
                                                <div className="col">
                                                    <div>Term</div>
                                                    <div><b>{terms} months</b></div>
                                                </div>
                                            </div>
                                            <div className="row ledger-view-row-contents">
                                                <div className="col">
                                                    <div></div>
                                                    <div><b>   {paid_status ?
                                                        <span className="paid badge badge-primary"></span> : null}</b>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row ledger-view-row-contents">
                                                <div className="col">
                                                    <div>Engine Number</div>
                                                    <div><b>{engine_number}</b></div>
                                                </div>
                                                <div className="col">
                                                    <div>Chassis Number</div>
                                                    <div><b>{chassis_number}</b></div>
                                                </div>
                                            </div>
                                            <div className="row ledger-view-row-contents">
                                                <div className="col">
                                                    <div>Status</div>
                                                    <div>
                                                        <b>{this.props.customer.recompute_status === '1' ? 'Original Customer' : 'New Customer'}
                                                            <span
                                                                className='badge badge-success status-badge'>{status}</span></b>
                                                    </div>
                                                </div>
                                                <div className="col">
                                                    <div>Monthly Amortization:</div>
                                                    <div><b>
                                                        {
                                                            !prohibited_acc.includes(customer.account_status) ?
                                                                !this.props.customer.type ? "N/A" :
                                                                    <FormattedNumber
                                                                        value={parseInt(this.state.monthly)}
                                                                        style="currency" currency="Php"/>
                                                                : 'N/A'
                                                        }
                                                    </b></div>
                                                </div>
                                                {/*</div>*/}
                                            </div>
                                            <div className="padding-10 no-print sticky-division">
                                                <hr/>
                                            </div>
                                            <div className="no-print sticky">
                                                <button hidden={this.props.userData.branch_info.branch_name === 'MAIN'}
                                                        className="btn full-width btn-second-primary" onClick={this.addPayment}>Add
                                                    Payment/Credit
                                                </button>
                                                &nbsp;
                                                <button hidden={this.props.userData.branch_info.branch_name === 'MAIN'}
                                                        className="btn full-width btn-second-primary" onClick={this.addDebit}>Add
                                                    Debit
                                                </button>
                                                &nbsp;
                                                <button className="btn full-width btn-second-primary"
                                                        onClick={this.showPaymentHistory}>Payment History
                                                </button>
                                                <button
                                                    className="full-width ledger-add-customer-btn"
                                                    data-toggle={add_customer_btn}
                                                    data-target={"#selectUnitType"}>
                                                    Add New Customer
                                                </button>
                                                <button
                                                    className="full-width ledger-add-customer-btn"
                                                    onClick={() => this.addRecord()}>
                                                    Add Old Customer
                                                </button>
                                            </div>
                                            <div className="row">
                                                <br/>
                                            </div>
                                            <div className="row padding-bottom-20 overflow-y-auto">
                                                <div className="container-fluid">
                                                    <table
                                                        className="table table-sm tbl-customer-payment tbl-mobile table-bordered">
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <br/>
                                <br/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


const mapStateToProps = state => ({
    app: state.feathersClient.app,
    mainapp: state.feathersClient.mainapp,
    customer: state.customers.customerInfo,
    payment: state.customers.customerPayments,
    model: state.category.modelsList,
    brand: state.category.brandsList,
    userData: state.login.userData,
    userPermission: state.login.userPermission,
    ledger: state.customers.ledgerTableData,
    promisory_note: state.customers.promisory_note,
    paymentTableData: state.customers.paymentTableData,
    total_payments: state.customers.total_payments,
    areaList: state.customers.areaList,
});

function mapDispatchToProps(dispatch) {
    return {actions: bindActionCreators(ActionCreators, dispatch)}
}

export default connect(mapStateToProps, mapDispatchToProps)(view_customer);
