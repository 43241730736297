import React, {Component} from 'react';

//redux
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
// actions
import {ActionCreators} from '../../../actions';

//main NavBar
import MainNav from '../../../components/global_nav';
import MainSideBar from '../../../components/global_sidebar';

import toastr from 'toastr';

//custom sidebar
import SubSideBar from './sub_sidebar';

//custom styles
import '../../../stylesheet/styles.css';

import EditUserModal from './edit_user_modal';

import app from '../../../helpers/feathers';

//datatables
import '../../../stylesheet/datatables/datatables.css';
import ConfirmationDelete from "../inventory/confirmation_delete";

const $ = require('jquery');
$.DataTable = require('datatables.net');

class user extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: []
        };
    }

    componentWillMount() {
        this.getAllUsers();

        //branches
        this.props.actions.getAllBranches().then((data) => {
            if(data){
                this.props.actions.setBranches(data);
            }
        });

        app.service('user-position').find({
            query: {
                position_type: 'ADMINISTRATOR'
            }
        }).then((data) => {
            if (data.total) {
                const adminId = data.data[0]._id;
                this.props.actions.activeUserInPermission(adminId);
            }
        })
        .catch((err) => {
            return err;
        })
    }

    componentDidMount() {
        const { usersList } = this.props;
        const that = this, usersTbl = $('.tbl-users').DataTable({
            data: usersList,
            "columnDefs": [
                {
                    "visible": false, "targets": 0
                },
                {
                    targets: [5],
                    orderable: false,
                    width: '15%'
                }
            ],
            columns: [
                {title: "ID"},
                {title: "NAME"},
                {title: "USERNAME"},
                {title: "ROLE"},
                {title: "BRANCH"},
                {title: "ACTION"}
            ],
            'dom': "<'row'<'col-sm-6'l><'col-sm-6'p>>" +
                "<'row'<'col-sm-12'tr>>" +
                "<'row'<'col-sm-12'i>>",
        });

        $('.search').keyup(function () {
            usersTbl.search($(this).val()).draw();
        });

        $('.tbl-users').on('click', 'button.view', function () {
            const data = usersTbl.row($(this).parents('tr')).data(),
                name = (data[0].fullname).replace(/ /g, "");
            that.props.actions.setUserInfo(data[0]);
            that.props.history.push('/user/' + name);
        });

        $('.tbl-users').on('click', 'button.edit', function () {
            const data = usersTbl.row($(this).parents('tr')).data(),
                name = (data[0].fullname).replace(/ /g, "");
            const username = data[2];

            const permission = that.props.userPermission;
            if (permission.user === 2) {
                if (username === 'superadmin') {
                    toastr.error('The selected user cannot be updated');
                    return;
                }
                if (username === 'admin') {
                    toastr.error('The selected user cannot be updated');
                    return;
                }
                that.props.actions.userInfoEdit(data)
                that.refs.edit_user_modal.click();
            }
        });

        $('.tbl-users').on('click', 'button.delete', function () {
            const data = usersTbl.row($(this).parents('tr')).data(),
                id = data[0]._id,
                username = data[2];

            const permission = that.props.userPermission;
            if (permission.user === 2) {
                if (username === 'superadmin') {
                    toastr.error('The selected user cannot be deleted');
                } else if (username === 'admin') {
                    toastr.error('The selected user cannot be deleted');
                } else {
                    that.props.actions.deleteId(id);
                    that.props.actions.deleteService('users');
                    that.refs.confirm_delete.click();
                }
            }
        });
        that.createListener();
    }

    createListener = () => {
        const that = this;
        app.service('users').on('created', function () {
            that.getAllUsers();
        })
        app.service('users').on('removed', function () {
            that.getAllUsers();
        })
        app.service('users').on('patched', function () {
            that.getAllUsers();
        })
    }

    getAllUsers = () => {
        const that = this;
        let branch, branch_name;
        branch = this.props.userData.branch_info._id;
        branch_name = this.props.userData.branch_info.branch_name;
        this.props.actions.getAllUsers(branch_name, branch).then((data) => {
            if (data.length) {
                that.props.actions.setUsers(data);
                that.updateData(data);
            }
        });
    }

    updateData = (data) => {
        this.setState({
            data: data ? data : ''
        });

        const table = $('.tbl-users').DataTable();
        table.clear();
        table.rows.add(data ? data : '');
        table.draw();
    }

    permissionUser = () => {
        const permission = this.props.userPermission;

        if (permission.user === 0) {
            return "not-visible"
        } else {
            return "";
        }
    }

    addUser = () => {
        const permission = this.props.userPermission;
        const pathName = this.props.history.location.pathname;

        if (pathName === '/user_new_user') {
            return;
        }

        if (permission.user === 2) {
            this.props.history.push('/user_new_user');
        }
    }

    render() {
        return (
            <div className="">
                <EditUserModal/>
                {/* main NavBar component*/}
                <MainNav historyProp={this.props.history} usersProp={this.props.userData}/>
                <ConfirmationDelete/>
                <div className="container-fluid">
                    <div className="row full-width">
                        <div className="sidebar">
                            <div className="sidebar-content">
                                <MainSideBar page="user"/>
                            </div>
                        </div>
                        <SubSideBar history={this.props.history} pageName="user"
                                    userPermission={this.props.userPermission}/>
                        {
                            this.props.userPermission.user === 0 ?
                                <div className="sub-main-content">
                                    <div className="no-access-div">
                                        <div className="col-md-12">
                                            <h1>Access denied</h1>
                                        </div>
                                    </div>
                                </div>
                                : null
                        }
                        <div className={this.permissionUser()}>
                            {<div className="sub-inv-main-content">
                                <a ref="edit_user_modal" data-toggle="modal" data-target="#edit_user"/>
                                <div className="container-fluid">
                                    <div className="application-container">
                                        <div className="row col-md-8">
                                            <div className="col">
                                                <h4 className="subheader-title">List of Users</h4>
                                            </div>
                                            <div className="col">
                                                <button className="btn btn-second-primary add-new-btn" onClick={() => this.addUser()}>
                                                    <span><i className="fa fa-plus"/> Add</span>
                                                </button>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-4">
                                                <div className="justify-content-left content-header">
                                                    <input type="search" className="search-class search form-control" placeholder="&#xF002; Search"/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <a data-toggle="modal" data-target="#confirm_delete"
                                                   ref="confirm_delete"></a>
                                                <table className="table tbl-users tbl-mobile">
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


const mapStateToProps = state => ({
    app: state.feathersClient.app,
    users: state.users,
    usersList: state.users.usersList,
    userData: state.login.userData,
    userPermission: state.login.userPermission,
});

function mapDispatchToProps(dispatch) {
    return {actions: bindActionCreators(ActionCreators, dispatch)}
}

export default connect(mapStateToProps, mapDispatchToProps)(user);
