import React, {Component} from 'react';
// react-router
import {Link} from 'react-router-dom';

// redux
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
// actions
import {ActionCreators} from '../../../actions';

//select tag
import Select from 'react-select';
import toastr from 'toastr';
import default_avatar from '../../../assets/icons/default_avatar.jpg';

// Main NavBar
import MainNav from '../../../components/global_nav';
import MainSideBar from '../../../components/global_sidebar';

//custom sidebar
import SubSideBar from './sub_sidebar';

// custom styles
import '../../../stylesheet/styles.css';
import eversureLogo from "../../../assets/logo/eversure_logo.png";

class add_user extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedBranch: {value: 'all', label: 'All'},
            selectedPosition: '',
            clickSubmit: false,
            passwordIsStrong: false,
            passwordIsMatch: false,
            inputsDisabled: false,
            saving: false,
            emailInput: '',
            validEmail: false,
            existingEmail: false,
        };
    }

    componentWillMount() {
        const array = this.props.branch, newArray = [];
        array.map((obj) => {
            if (obj.label === 'MAIN') {
                newArray.push(obj)
            }
        });
        if(newArray.length > 0){
            this.setState({
                selectedBranch: newArray[0]
            })
        }
        // const table = $('.tbl-users').DataTable();
        // table.clear();
    }

    componentDidMount() {
    }

    handleSubmit = e => {
        const that = this;

        e.preventDefault();
        this.setState({
            clickSubmit: true
        });

        let formData = {
            username: this.refs.username.value,
            password: this.refs.password.value,
            confirmPassword: this.refs.confirmPassword.value,
            fullName: this.refs.name.value,
            email: this.refs.email.value,
            branch: this.state.selectedBranch === null || this.state.selectedBranch === '' ? '' : this.state.selectedBranch.value,
            type: this.state.selectedPosition === null || this.state.selectedPosition === '' ? '' : this.state.selectedPosition.value,
            address: this.refs.address.value
        };
        const form = this.refs.userForm;
        if (form.checkValidity() === false) {
            e.stopPropagation();
        }
        form.classList.add('was-validated');
        if (formData.fullName !== '' &&
            formData.branch !== '' &&
            formData.position !== '' &&
            formData.username !== '' &&
            formData.password !== '' &&
            formData.confirmPassword !== '' &&
            formData.address !== '' &&
            formData.email !== '' &&
            form.checkValidity() === true) {

            if (!this.state.passwordIsMatch) {
                toastr.error('Password not match')
            } else {
                this.submitUserForm(formData);
            }

        } else {
            if (formData.fullName !== '' &&
                formData.branch !== '' &&
                formData.position !== '' &&
                formData.username !== '' &&
                formData.password !== '' &&
                formData.email !== '' &&
                formData.confirmPassword !== '' &&
                formData.address !== '') {
                if (form.checkValidity() === false) {
                    if (!this.state.passwordIsMatch) {
                        toastr.error('Password not match');
                    }
                    if (!this.state.passwordIsStrong) {
                        toastr.error('Please use a valid Password');
                    }
                    console.log(formData);
                    this.submitUserForm(formData);
                }
            } else {
                toastr.error('Please fill in the required fields')
            }
        }

    };
    submitUserForm = (formData) => {
        const that = this;
        this.setState({
            saving: true
        })
        if (!this.state.saving) {
            this.setState({
                saving: true
            });
            if(!this.state.existingEmail){

                that.props.actions.userNameExists(formData.username)
                .then((res) => {
                    if(res.status){
                        toastr.remove()
                        toastr.error(res.message)
                        that.setState({
                            saving: false
                        });
                    }else{
                        that.props.actions.addUser(formData).then((data) => {
                            if (data) {
                                if (data === 'eusername') {
                                    toastr.error('Username is in use');
                                    that.setState({
                                        saving: false
                                    })
                                } else if (data === 'eemail') {
                                    toastr.error('Email is in use');
                                    that.setState({
                                        saving: false
                                    })
                                } else if (data === 'efullname') {
                                    toastr.error('Name is in use');
                                    that.setState({
                                        saving: false
                                    })
                                } else {
                                    toastr.success('User has been successfully added');
                                    setTimeout(() => {
                                        that.clearInputs();
                                        that.props.history.push('/user')
                                    }, 3700)
                                }
                            } else {
                                toastr.error('An error occured. Please try again');
                                that.setState({
                                    saving: false
                                })
                            }
                        }).catch(() => {
                            toastr.error('An error occured. Please try again');
                            that.setState({
                                saving: false
                            })
                        });
                    }
                })
                .catch(() => {
                    toastr.remove();
                    toastr.error("An error occured. Please try again.")
                })
            } else {
                that.setState({
                    saving: false
                })
                toastr.error('Email Address is in use');
            }
        }
    };

    clearInputs = () => {
        this.setState({
            inputsDisabled: true
        })
    }

    handleChangeBranch = (selectedOption) => {
        const value = selectedOption === null ? '' : selectedOption;
        this.setState({
            selectedBranch: value
        });
    }

    emailLowerCase = () => {
        const value = this.refs.email.value;
        this.props.actions.getCheckEmailIfExist(value).then((data) => {
            console.log(data);
            this.setState({
                existingEmail: data,
            });
        });
        const validEmailRegex =
            RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);
        this.setState({
            validEmail: validEmailRegex.test(value),
            emailInput: value
        });
    }
    mainBranchOption = (value = false) => {
        const usersBranch = this.props.userData.branch_info.branch_name;
        const array = this.props.branch, newArray = [];
        array.map((obj) => {
            if (usersBranch === 'MAIN') {
                newArray.push(obj)
            } else {
                if (usersBranch === obj.label) {
                    newArray.push(obj);
                }
            }
        });
        return value ? newArray[0].value : newArray
    };

    handleChangePosition = (selectedOption) => {
        const value = selectedOption === null ? '' : selectedOption;
        this.setState({
            selectedPosition: value
        });
    };

    passwordStrength = (e) => {
        const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{6,}$/;
        const val = e.target.value;
        const m = val.match(regex);
        const matched = !!m;
        this.setState({
            passwordIsStrong: matched
        });
    };

    passwordMatch = (e) => {
        this.setState({
            passwordIsMatch: this.refs.password.value === this.refs.confirmPassword.value
        });
    };

    render() {
        return (
            <div className="">
                {/* main NavBar component*/}
                <MainNav historyProp={this.props.history} usersProp={this.props.userData}/>
                <div className="container-fluid">
                    <div className="row full-width">
                        <div className="sidebar">
                            <div className="sidebar-content">
                                <MainSideBar page="user"/>
                            </div>
                        </div>
                        <SubSideBar history={this.props.history} userPermission={this.props.userPermission}/>
                        <div className="sub-main-content">
                            <div className="container-fluid">
                                <div className="application-container">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <br/>
                                            <a href="#" onClick={() => this.props.history.goBack()}
                                               className="breadcrumb-inv"><span className="fa fa-chevron-left"/> Users /
                                                Add New User</a>
                                            <br/>
                                            <h4 className="subheader-title">Add New User</h4>
                                            <div className="tab-content">
                                                <div role="tabpanel" className="tab-pane active" id="home">
                                                    <form onSubmit={this.handleSubmit.bind(this)}
                                                          encType="multipart/form-data" ref="userForm"
                                                          id="needs-validation" noValidate>
                                                        <div className="row">
                                                            <div className="col-md-12">
                                                                <div className="row">
                                                                    <div className="col-md-8">
                                                                        <h4>Name</h4>
                                                                        <input type="text" className="form-control"
                                                                               ref="name"
                                                                               disabled={this.state.inputsDisabled}
                                                                               required/>
                                                                    </div>
                                                                </div>
                                                                <br/>
                                                                <div className="row">
                                                                    <div className="col">
                                                                        <span>Position</span>
                                                                        <Select
                                                                            required
                                                                            className={this.state.selectedPosition === '' && this.state.clickSubmit ? "error-select" : null}
                                                                            name="select-position"
                                                                            value={this.state.selectedPosition.value}
                                                                            onChange={this.handleChangePosition}
                                                                            options={this.props.position}
                                                                            clearableValue={true}
                                                                            placeholder="Select Position"
                                                                            disabled={this.state.inputsDisabled}
                                                                        />
                                                                    </div>
                                                                    <div className="col">
                                                                        <span>Branch</span>
                                                                        <Select
                                                                            name="selectBranch"
                                                                            value={this.state.selectedBranch.value}
                                                                            onChange={this.handleChangeBranch}
                                                                            options={this.mainBranchOption()}
                                                                            clearableValue={true}
                                                                            placeholder="Select Branch"
                                                                            disabled={false}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <br/>
                                                        <div className="row">
                                                            <div className="col-md-12">
                                                                <div className="row">
                                                                    <div className="col">
                                                                        <span>Username</span>
                                                                        <input type="text" ref="username"
                                                                               className="user-form-control"
                                                                               disabled={this.state.inputsDisabled}
                                                                               required/>
                                                                    </div>
                                                                    <div className="col">
                                                                        <span>Email</span>
                                                                        <input type="email"
                                                                               className="user-form-control"
                                                                               value={
                                                                                   this.state.emailInput}
                                                                               onChange={this.emailLowerCase}
                                                                               disabled={this.state.inputsDisabled}
                                                                               ref="email"
                                                                               required/>
                                                                        <small className="text-danger">
                                                                            {
                                                                                this.state.validEmail ? this.state.existingEmail ? "Email address is already taken." : "" :
                                                                                    "Please enter a valid email address."
                                                                            }
                                                                        </small>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <br/>
                                                        <div className="row">
                                                            <div className="col-md-12">
                                                                <div className="row">
                                                                    <div className="col">
                                                                        <span>Password</span>
                                                                        <input
                                                                            type="password"
                                                                            ref="password"
                                                                            className="user-form-control"
                                                                            required
                                                                            pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}"
                                                                            disabled={this.state.inputsDisabled}
                                                                            onChange={(e) => this.passwordStrength(e)}
                                                                        />
                                                                        <small className="text-danger">
                                                                            {
                                                                                this.state.passwordIsStrong ? "" : "Use at least 6 alphanumeric characters, Password value should atleast have 1 Uppercase letter and 1 numeric character"
                                                                            }
                                                                        </small>
                                                                    </div>
                                                                    <div className="col">
                                                                        <span>Confirm Password</span>
                                                                        <input
                                                                            type="password"
                                                                            ref="confirmPassword"
                                                                            className="user-form-control"
                                                                            required
                                                                            disabled={this.state.inputsDisabled}
                                                                            onChange={(e) => this.passwordMatch(e)}
                                                                        />
                                                                        <small className="text-danger">
                                                                            {
                                                                                this.state.passwordIsMatch ? "" : "Password not match."
                                                                            }
                                                                        </small>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <br/>
                                                        <div className="row">
                                                            <div className="col-md-12">
                                                                <div className="row">
                                                                    <div className="col">
                                                                        <span>Address</span>
                                                                        <textarea className="form-control" ref="address"
                                                                                  disabled={this.state.inputsDisabled}/>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <br/>
                                                        <div className="col-md-2 pull-right">
                                                            <button className="btn btn-primary col-md-12"
                                                                    type="submit">
                                                                SAVE
                                                            </button>
                                                        </div>
                                                        <div className="col-md-2 pull-right">
                                                            <button className="btn btn-danger col-md-12"
                                                                    onClick={() => this.props.history.push('/user')}>
                                                                CANCEL
                                                            </button>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


const mapStateToProps = state => ({
    app: state.feathersClient.app,
    loginStatus: state.login.loginStatus,
    branch: state.category.branchesSelect,
    position: state.category.positionsSelect,
    userData: state.login.userData,
});

function mapDispatchToProps(dispatch) {
    return {actions: bindActionCreators(ActionCreators, dispatch)}
}

export default connect(mapStateToProps, mapDispatchToProps)(add_user);
