import {
    ADD_MODEL,
    MODEL_DATATABLES,
    PRODUCT_LIST,
    UPDATE_MODEL_INFO,
    BRANCH_DATATABLES,
    BRAND_DATATABLES,
    POSITIONS_DATATABLES,
    UPDATE_BRANCH_INFO,
    UPDATE_BRAND_INFO,
    UPDATE_DESIGNATION_INFO,
    SELECT_OPT_BRANDS,
} from '../constants/category';

import feathers from '../helpers/feathers';

import { conf } from '../helpers';

const _user = conf.defaults.username, _pass = conf.defaults.password

/* ================ models actions ================ */

export const brandtest = () => {
    return (dispatch,getState) => {

    // console.log(conf.defaults)
    }
}

export function addModel(modelName, branch, brand) {
    return (dispatch, getState) => {
        var modelService = feathers.service('motorcycle-models');

        return modelService.find({
            paginate: {
                default: 10,
                max: 50
            },
            query: {
                model_name: modelName,
                // localbranch_id: branch,
                brand: brand
            }
        }).then((model) => {
            if (model.total) {
                return Promise.resolve('exist');
            } else {
                return modelService.create({
                    model_name: modelName,
                    localbranch_id: branch,
                    brand: brand
                }).then((res) => {
                    return Promise.resolve('success');
                }).catch((err) => {
                    if ((err.message).includes('already exists')) {
                        return Promise.resolve('exist');
                    } else {
                        return Promise.resolve('failed');
                    }
                });
            }
        }).catch((err) => {
            if ((err.message).includes('already exists')) {
                return Promise.resolve('exist');
            } else {
                return Promise.resolve('failed');
            }
        });

    }
}


//category management models
export function getCategoryModels() {
    return (dispatch, getState) => {
        const modelService = feathers.service('motorcycle-models');
        return modelService.find({
            paginate: {
                default: 10,
                max: 50
            },
        }).then((models) => {
            if(models.total){
                const results = models.data,
                    data = [];
                results.forEach((value, index) => {
                    const branch = value.branch ? value.branch.branch_name : 'N/A';
                    const brand_name = value.brand_details ? value.brand_details.brand_name : 'N/A';
                    const actionBtn = '<button class="btn btn-sm btn-primary edit"><span class="fa fa-edit" /></button> <button class="btn btn-sm btn-danger delete" data-toggle="modal" data-target="#confirm_model_delete1"><span class="fa fa-trash" /> </button>';
                    data.push([value._id, index + 1, value.model_name, brand_name, actionBtn, value]);
                });
                return Promise.resolve(data);
            }else{
                return Promise.resolve(false);
            }
        }).catch((err) => {
            return Promise.resolve(false);
        })
    }
}

export function deleteModel(id) {
    return (dispatch, getState) => {
        var modelService = feathers.service('motorcycle-models');

        modelService.remove(id).then((data) => {
            return data
            
        }).catch((err) => {
            return err
        });
    }
}

export function setModels(data) {
    return {
        type: MODEL_DATATABLES,
        data: data
    }
}

export function modelUpdateInfo(data) {
    return {
        type: UPDATE_MODEL_INFO,
        data: data
    }
}

export function updateModel(id, query) {
    return (dispatch, getState) => {
        var modelService = feathers.service('motorcycle-models');

        return modelService.patch(id, query).then((data) => {
            return Promise.resolve(true)
        }).catch((error) => {
            return Promise.resolve(false)
        });

    }
}

export function modelInUse(data) {
    return (dispatch, getState) => {
        var Service = feathers.service('products');

        return Service.find({
            paginate: {
                default: 10,
                max: 50
            },
            query: {
                model: data
            }
        }).then((data) => {
            if (data.total) {
                return Promise.resolve(true)
            } else {
                return Promise.resolve(false)
            }
        }).catch(() => {
            return Promise.resolve(false)
        })
    }
}

/* ================ closing models actions ================ */


/* ================  branch actions ================ */

export function getAllBranches() {
    return (dispatch, getState) => {
        var branchService = feathers.service('branches');

        return branchService.find({
            paginate: {
                default: 10,
                max: 50
            },
        }).then((branches) => {
            if(branches.total){
                var results = branches.data,
                    data = [];
    
                results.forEach((value, index) => {
                    var actionBtn = '<button class="btn btn-sm btn-primary edit"><span class="fa fa-edit" /></button> <button class="btn btn-sm btn-danger delete" data-toggle="modal" data-target="#confirm_branch_delete1"><span class="fa fa-trash" /> </button>';
                    data.push([value._id, index + 1, value.branch_name, actionBtn]);
                });
    
                return Promise.resolve(data);
            }else{
                return Promise.resolve(false);
            }

        }).catch((err) => {
            return Promise.resolve(false);
        })
    }
}

export function addBranch(branchName) {
    return (dispatch, getState) => {
        var branchService = feathers.service('branches');

        return branchService.find({
            query: {
                branch_name: branchName
            }
        })
        .then((res) => {
            if(res.data.length > 0){
                return Promise.resolve('exist');
            }else{
                return branchService.create({
                    branch_name: branchName
                }).then((result) => {
                    return Promise.resolve('success');
                }).catch((err) => {
                    if ((err.message).includes('already exists')) {
                        return Promise.resolve('exist');
                    } else {
                        return Promise.resolve('failed');
                    }

                });
            }
        })
        .catch(() => {
            return Promise.resolve('failed');
        })
        
    }
}

export function setBranches(data) {
    return {
        type: BRANCH_DATATABLES,
        data: data
    }
}

export function deleteBranch(id) {
    return (dispatch, getState) => {
        var branchService = feathers.service('branches');

        branchService.remove(id).then((data) => {
            return data;
        });
    }
}

export function branchUpdateInfo(data) {
    return {
        type: UPDATE_BRANCH_INFO,
        data: data
    }
}

export function updateBranch(id, name) {
    return (dispatch, getState) => {
        var branchService = feathers.service('branches');

        return branchService.patch(id, {branch_name: name})
            .then((data) => {
                return Promise.resolve(true)
            })
            .catch((error) => {
                return Promise.resolve(false)
            });

    }
}
/* ================  closing branch actions ================ */

/* ================  brand actions ================ */
export function selectOptionBrands(){
    return(dispatch, getState) => {
        const response = {};
        const brandsService = feathers.service('brands');
        return brandsService.find({
            paginate: {
                default: 10,
                max: 50
            },
            query: {
                $limit: 50
            }
        }).then((brandsData) => {
            const collection = [];
            if(brandsData.total){
                const brands = brandsData.data;
                brands.map((item, i) => {
                    const { brand_name, _id } = item;
                    collection.push({
                        value: _id,
                        label: brand_name
                    })
                });

                dispatch(setSelectOptionBrands(collection));

                response.status = true;
                response.data = collection;
            }else{
                response.status = false;
                response.message = "No data found";
            }

            return Promise.resolve(response);
        }).catch((brandsError) => {
            response.status = false;
            response.data = brandsError;

            return Promise.resolve(response);
        })
    }
}
export const setSelectOptionBrands = (data) => {
    return {
        type: SELECT_OPT_BRANDS,
        data: data
    }
}
export function getAllBrands() {
    return (dispatch, getState) => {
        const brandsService = feathers.service('brands');
        return brandsService.find({
            paginate: {
                default: 10,
                max: 50
            },
            query: {
                $sort: {
                    createdAt: 1
                }
            }
        }).then((brands) => {
            if(brands.total){
                const results = brands.data,
                    data = [];
                results.forEach((value, index) => {
                    const branch = value.branch ? value.branch.branch_name : 'No branch';
                    const actionBtn = '<button class="btn btn-sm btn-primary edit"><span class="fa fa-edit" /></button> <button class="btn btn-sm btn-danger delete" data-toggle="modal" data-target="#confirm_brand_delete1"><span class="fa fa-trash" /> </button>';
                    data.push([value._id, index + 1, value.brand_name, actionBtn]);
                });
                return Promise.resolve(data);
            }else{
                return Promise.resolve(false);
            }
        }).catch((err) => {
            return Promise.resolve(false);
        })
    }
}

export function getAllBrandsWithoutBranchName() {
    return (dispatch, getState) => {
        const brandsService = feathers.service('brands');
        return brandsService.find({
            paginate: {
                default: 10,
                max: 50
            },
            query: {
                $sort: {
                    createdAt: 1
                }
            }
        }).then((brands) => {
            if(brands.total){
                const results = brands.data,
                    data = [];
                results.forEach((value, index) => {
                    const branch = value.branch ? value.branch.branch_name : 'No branch';
                    const actionBtn = '<button class="btn btn-sm btn-primary edit"><span class="fa fa-edit" /></button> <button class="btn btn-sm btn-danger delete" data-toggle="modal" data-target="#confirm_brand_delete1"><span class="fa fa-trash" /> </button>';
                    data.push([value._id, index + 1, value.brand_name, actionBtn]);
                });
                return Promise.resolve(data);
            }else{
                return Promise.resolve(false)
            }
        }).catch((err) => {
            return Promise.resolve(false)
        })
    }
}

export function addBrands(brandName, branch) {
    return (dispatch, getState) => {
        var brandsService = feathers.service('brands');

        return feathers.authenticate({
            strategy: 'local',
            username: _user,
            password: _pass,
        })
        .then((a) => {
            return brandsService.find({
                paginate: {
                    default: 10,
                    max: 50
                },
                query: {
                    brand_name: brandName,
                    // localbranch_id: branch
                }
            })
            .then((data) => {
                if (data.total) {
                    return Promise.resolve('exist');
                } else {
                    return brandsService.create({
                        brand_name: brandName,
                        localbranch_id: branch
                    }).then((result) => {
                        return Promise.resolve('success');
                    }).catch((err) => {
                        if ((err.message).includes('already exists')) {
                            return Promise.resolve('exist');
                        } else {
                            return Promise.resolve('failed');
                        }
                    });
                }
            })
            .catch((e) => {
                return Promise.resolve('failed')
            })
        }).catch((err) => {
            return Promise.resolve('failed')
        })
    }
}

export function setBrands(data) {
    return {
        type: BRAND_DATATABLES,
        data: data
    }
}

export function deleteBrand(id) {
    return (dispatch, getState) => {
        var brandService = feathers.service('brands');

        brandService.remove(id).then((data) => {
            return data;
        }).catch((err) => {
            return err;
        });
    }
}

export function brandUpdateInfo(data) {
    return {
        type: UPDATE_BRAND_INFO,
        data: data
    }
}

export function updateBrand(id, name) {
    return (dispatch, getState) => {
        var branchService = feathers.service('brands');

        return branchService.patch(id, {brand_name: name}).then((data) => {
            return Promise.resolve(true)
        }).catch((error) => {
            return Promise.resolve(false)
        });

    }
}

export function brandExists(brandName){
    return (dispatch, getState) => {
        const brandsService = feathers.service('brands');

        return brandsService.find({
            paginate: {
                default: 10,
                max: 50,
            },
            query: {
                brand_name: brandName,
                $limit: 5
            }
        }).then((data) => {
            if(data.total){
                return Promise.resolve('exists')
            }else{
                return Promise.resolve('success')
            }
        }).catch((error) => {
            return Promise.resolve('error')
        })
    }
}

export function brandInUse(data) {
    return (dispatch, getState) => {
        var Service = feathers.service('products');

        return Service.find({
            query: {
                brand: data,
                $limit: 5,
            }
        })
        .then((data) => {
            if (data.total) {
                return Promise.resolve(true)
            } else {
                return Promise.resolve(false)
            }
        })
        .catch((err) => {
            return Promise.resolve(false)
        })
    }
}


/* ================  closing brands actions ================ */

/* ================  user's position actions ================ */
export function getAllPositions() {
    return (dispatch, getState) => {
        var positionsService = feathers.service('user-position');
        let query = {};
        let { userData } = getState().login;
        let session = {
            branchId: userData.branch_info._id,
            branchName: userData.branch_info.branch_name,
        }

        if(session.branchName !== "MAIN"){
            query.position_type = {
                $ne: "ADMINISTRATOR"
            }
        }

        return positionsService.find({
            paginate: {
                default: 10,
                max: 50
            },
            query: query
        }).then((positions) => {
            if(positions.data.length > 0){

                var results = positions.data,
                    data = [];

                results.forEach((value, index) => {
                    var actionBtn = '<button class="btn btn-sm btn-primary edit"><span class="fa fa-edit" /></button> <button class="btn btn-sm btn-danger delete"><span class="fa fa-trash" /> </button>';

                    data.push([value._id, index + 1, value.position_type, actionBtn]);
                });

                return Promise.resolve(data);
            }else{
                return Promise.resolve([]);
            }

        }).catch((err) => {
            return Promise.resolve([]);
        })
    }
}

export function addPosition(positionName) {
    return (dispatch, getState) => {
        var brandsService = feathers.service('user-position');

        return brandsService.find({
            paginate: {
                default: 10,
                max: 50
            },
            query: {
                position_type: positionName
            }
        })
        .then((result) => {
            if(result.data.length > 0){
                return Promise.resolve('exist');
            }else{
               return brandsService.create({
                    position_type: positionName
                }).then((result) => {
                    return Promise.resolve('success');
                }).catch((err) => {
                    if ((err.message).includes('already exists')) {
                        return Promise.resolve('exist');
                    } else {
                        return Promise.resolve('failed');
                    }

                });
            }
        })
        .catch(() => {
            return Promise.resolve('failed');
        })
        
    }
}

export function setPositions(data) {
    return {
        type: POSITIONS_DATATABLES,
        data: data
    }
}

export function deletePosition(id) {
    return (dispatch, getState) => {
        var positionService = feathers.service('user-position');

        positionService.remove(id).then((data) => {
            return Promise.resolve(data)
        }).catch((err) => {
            return Promise.resolve(err)
        });
    }
}

export function designationUpdateInfo(data) {
    return {
        type: UPDATE_DESIGNATION_INFO,
        data: data
    }
}

export function updateDesignation(id, name) {
    return (dispatch, getState) => {
        var branchService = feathers.service('user-position');

        return branchService.patch(id, {position_type: name})
            .then((data) => {
                return Promise.resolve(true)
            })
            .catch((error) => {
                return Promise.resolve(false)
            });

    }
}

export function positionInUse(data) {
    return (dispatch, getState) => {
        var Service = feathers.service('users');

        return Service.find({
            paginate: {
                default: 10,
                max: 50
            },
            query: {
                type: data
            }
        })
            .then((data) => {
                if (data.total) {
                    return Promise.resolve(true)
                } else {
                    return Promise.resolve(false)
                }
            })
            .catch(() => {
                return Promise.resolve(false)
            })
    }
}


/* ================  user's branches actions ================ */


