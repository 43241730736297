

import React, { Component } from 'react';

// moment
import moment from 'moment';
import {IntlProvider, FormattedMessage, FormattedNumber} from 'react-intl';
import Cleave from 'cleave.js/react';

import ReactDataSheet from 'react-datasheet';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

//toastr
import toastr from 'toastr';
import Toggle from 'react-bootstrap-toggle';


//main NavBar
import MainNav from '../../../components/global_nav';
import MainSideBar from '../../../components/acc_sidebar';
//sub side bar
import SubSideBar from './sub_sidebar';

import OverdueModal from './overdue_modal'

import defaultImage from '../../../assets/img/default_avatar.jpg';

import { twoDecimalPlaces, numberWithCommas } from '../../../helpers';

import DateRangePicker from "react-bootstrap-daterangepicker";
import "./plugins/daterangepicker.css";

//custom styles
import '../../../stylesheet/styles.css';

//redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// actions
import { ActionCreators } from '../../../actions';
const $ = require('jquery');
$.DataTable = require('datatables.net');

var that

class overdue_customer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: [],
            table_data: [],
            start_date: '',
            unit: '',
            editing: false,
            startDate: moment(),
            endDate: moment(),
            first_payment_date: moment(),
            selectedDaterange: false,
            drPickerApplied: false,
        };
    }

    componentWillMount(){

        that = this;


        let { customer, total_payments } = this.props,
            promisory_note = customer.promisory_note,
            down_payment = customer.payment,
            initialBal = ((promisory_note - down_payment) - total_payments);

        that.getOverdueDetails();
    }

    componentDidMount() {
        var mainDtTable = $('.tbl-overdue-details').DataTable({
            // data: this.props.ledger,
            "columnDefs": [
                {
                    "visible": false,
                    "targets": 0
                },
            ],
            columns: [
                { title: "" },
                { title: "DUE DATE" },
                { title: "MONTHLY AMORTIZATION" },
                { title: "AMOUNT OVERDUE" }
            ],
            "paging": true,
            "pageLength": 36,
            "searching": false,
            "ordering": false,
            "info": false,
            "sDom": '<"bottom"<t>ip><"clear">'
        });

        $('.tbl-overdue-details').on('click', 'button.view', function () {
            var data = mainDtTable.row( $(this).parents('tr') ).data();

            console.log('data ', data)
        });
    }


    updateDataTable = (data) => {
        this.setState({
            data: data
        });

        const table = $('.tbl-overdue-details').DataTable();
        table.clear();
        table.rows.add(data);
        table.draw();
    }

    getOverdueDetails = () => {
        let { customer } = this.props,
            {
                account_number,
                promisory_note,
                payment,
                term
            } = customer,
            ma = (promisory_note / term);

        that.props.app.service('ledger').find({
            query: {
                account_number: account_number,
                current: 1
            }
        })
            .then((find_ledger) => {
                console.log('find_ledger ', find_ledger)
                if(find_ledger.total){
                    let tb_data = find_ledger.data,
                        output = [];

                    tb_data.map((v, i) => {
                        output.push([
                            v,
                            v.due_date,
                            ma,
                            ''
                        ])
                    })

                    that.updateDataTable(output)
                }else{
                    that.updateDataTable([])
                }
            })
            .catch((find_ledger_err) => {
                that.updateDataTable([])
                console.log('find_ledger_err ', find_ledger_err)
            })
    }

    handleApply(event, picker) {
        that.setState({
            startDate: picker.startDate,
            endDate: picker.endDate,
            drPickerApplied: true
        });

        // moment(this.state.startDate).format('MM/DD/YYYY')
        that.enumDate(picker.startDate, picker.endDate);
    }


    enumDate = (startDate, endDate) => {
        var dates = [];


        var newStartDate = moment(startDate).subtract(1, "months");
        var newEndDate = moment(endDate).subtract(1, "months");


        console.log('newStartDate ====', newStartDate.format("MM/DD/YYYY"));
        console.log('newEndDate ====', newEndDate.format("MM/DD/YYYY"));

        var currDate = moment(startDate).subtract(31, "days").startOf("day");
        var lastDate = moment(endDate).subtract(31, "days").startOf("day");

        // var currDate = moment(startDate).startOf("day");
        // var lastDate = moment(endDate).startOf("day");

        while (currDate.add(1, "days").diff(lastDate) < 0) {


            // console.log(currDate.toDate());
            var mdate = moment(currDate.clone().toDate()).format("MM/DD/YYYY");

            var today = new Date();
            var todayFormat = moment(today);

            var m = moment(currDate.clone().toDate());

            if(m <= todayFormat){
                dates.push(mdate);
            }

            // dates.push(currDate.clone().toDate());
        }

        var dateEnd = new Date();
        var dateLast = moment(dateEnd),
            dateNewEnd = moment(newEndDate);

        if(dateNewEnd <= dateLast){
            dates.push(moment(newEndDate).format("MM/DD/YYYY"));
        }


        var dateStart = new Date(),
            dateNewStart = moment(dateStart),
            dateFirst = moment(newStartDate);

        if(dateFirst <= dateNewStart){
            dates.unshift(moment(newStartDate).format("MM/DD/YYYY"));
        }

        that.setState({
            selectedDaterange: dates
        });

    };



    applyReportFilter = () => {

        var query = daterange = that.state.selectedDaterange,
            daterange = that.state.selectedDaterange,
            report_type = that.state.report_type;

        that.props.actions.getOverdueCustomer(daterange)
            .then((ledger) => {
                let dt_data = [];
                if(ledger){
                    dt_data = ledger.data;

                    var customerPayments = that.props.app.service('customer-payments');
                    return customerPayments.find({
                        query: {
                            date_paid: {
                                $in: daterange
                            }
                        }
                    })
                        .then((dataItems)=>{

                            var paymentItems = dataItems.data,
                                dateRangeData = daterange,
                                hasDueDate = [],
                                withPayments = [],
                                withOutPayments = [];
                            paymentItems.map((val, index) => {

                                withPayments.push(val.account_number)
                            })

                            ledger.arr.map((acc, index) => {
                                if(!withPayments.includes(acc)){
                                    withOutPayments.push(acc)
                                }
                            })

                            that.props.app.service('customers').find({
                                query: {
                                    account_number: {
                                        $in: withOutPayments
                                    }
                                }
                            })
                                .then((noPayment) => {

                                    var input = [],
                                        dataValue = noPayment.data;
                                    // console.log('CUSTOMER DATA INFO', data)

                                    dataValue.map((value, index) => {

                                        var account_number = value.account_number,
                                            customer_name = value.name,
                                            model = value.unitName,
                                            actionBtn = '<button class="btn btn-sm btn-warning view" title="View"><span class="fa fa-eye"></span> View </button>';

                                        input.push([value, account_number,  customer_name, actionBtn]);
                                    })

                                    that.updateDataTable(input);
                                })
                        })
                }
            })
    }

    render() {

        return (
            <div className="">
                <MainNav historyProp={this.props.history} userPermission={this.props.userPermission} usersProp={this.props.userData} systemType="accounting" />
                <OverdueModal />
                <div className="container-fluid">
                    <div className="row full-width">
                        <div className="sidebar">
                            <div className="sidebar-content">
                                <MainSideBar page="overdue_customer"/>
                            </div>
                        </div>
                        <div>
                            <div className="main-content">
                                <div className="row">
                                    <div className="col-md-12">
                                        <h3>Overdue Details</h3>
                                        <div className="col">
                                            <br/>
                                        </div>
                                        <div className="background-white inline-block width-100p padding-tb-20">
                                            <div className="row padding-bottom-20">
                                                <div className="col-md-12">
                                                    <div className="container-fluid">
                                                        <table className="table table-sm tbl-overdue-details"></table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <br/>
                                <br/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
const mapStateToProps = state => ({
    app: state.feathersClient.app,
    customer: state.customers.customerInfo,
    payment: state.customers.customerPayments,
    model: state.category.modelsList,
    brand: state.category.brandsList,
    userData: state.login.userData,
    userPermission: state.login.userPermission,
    ledger: state.customers.ledgerTableData,
    promisory_note: state.customers.promisory_note,
    paymentTableData: state.customers.paymentTableData,
    total_payments: state.customers.total_payments,
});

function mapDispatchToProps(dispatch) {
    return { actions: bindActionCreators(ActionCreators, dispatch) }
}

export default connect(mapStateToProps, mapDispatchToProps)(overdue_customer);
