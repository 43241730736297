import React, {Component} from 'react';

import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../../node_modules/font-awesome/css/font-awesome.min.css';

import {Link} from 'react-router-dom';

import '../stylesheet/styles.css';
import customerLogoBlue from '../assets/icons/customers-purple.png';
import customerLogo from '../assets/icons/customers-gray.png';
import ledgerIcon from '../assets/icons/ledgers-gray.png';
import hamburger from '../assets/icons/menu.svg';
import ex from "../assets/icons/x.svg";
import overdueIcon from '../assets/icons/overdue-gray.png';
import overdueIconBlue from '../assets/icons/overdue-purple.png';
import financialIcon from '../assets/icons/financial-statement-gray.png';
import financialIconBlue from '../assets/icons/financial-statement-purple.png';
import ledgerIconBlue from '../assets/icons/ledgers-purple.png';
import accIcons from '../assets/icons/reports-gray.png';
import accIconsBlue from '../assets/icons/reports-purple.png';

import {MY_APP} from '../helpers'
import eversureLogo from "../assets/logo/eversure_logo.png";

//redux
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
// actions
import {ActionCreators} from '../actions';

import app from '../helpers/feathers'
import exis from "../assets/icons/x.svg";
import signoutLogo from "../assets/icons/signout.svg";

const $ = require('jquery');

export default class global_sidebar extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            active: false,
            visible: false
        };
        // this.toggleMenu = this.toggleMenu.bind(this);
    }

    componentDidMount() {
        $(".form-control").on('keyup change', function (e) {
            $(this).val($(this).val().toUpperCase());
        });
        this.toggleMenuOnLoad();
    }

    toggleDropdown = () => {
        this.setState({dropdownShown: !this.state.dropdownShown})
    }

    triggerAddTripState = () => {
        this.setState({
            ...this.state,
            isEmptyState: false,
            isAddTripState: true
        })
    }

    toggleMenu() {
        // var boxWidth = $(".sidebar").width();
        // console.log(this.state.visible);
        // console.log(this.state.visible);
        // console.log(this.state.visible);
        // console.log(this.props.systemType);
        // if (this.props.systemType === "inventory") {
        //     if (this.state.visible) {
        //         $("body .sidebar").fadeIn();
        //         $("body .nav-content__wrapper").css({left: "19%", width: "82%"});
        //         $("body .tab-nav-logo__wrapper").css({display: "none"});
        //         $("body .main-content").css({width: "86%"});
        //         // $("body .sidebar-logo").css({display: "block"});
        //     } else {
        if (window.outerWidth <= 768) {
            $("body .sidebar").fadeOut();
            $("body .nav-content__wrapper").css({left: "4px", width: "99%"});
            $("body .tab-nav-logo__wrapper").css({display: "block"});
            $("body .main-content").css({width: "100%", left: "0"});
        }

        //         // $("body .sidebar-logo").css({display: "none"});
        //     }
        // }
        //
        // this.setState({visible: true});
        // $("body .sidebar .dropdown.inventory").animate({
        //     width: boxWidth
        // })
        //
        // $("body .sidebar").fadeIn();
    }

    toggleMenuOnLoad() {
        if (window.outerWidth <= 768) {
            $("body .sidebar").css({display: "none"});
            $("body .nav-content__wrapper").css({left: "4px", width: "99%"});
            $("body .tab-nav-logo__wrapper").css({display: "block"});
            $("body .main-content").css({width: "100%", left: "0", top: "20px"});
        } else {
            $("body .sidebar").fadeIn();
        }
    }

    render() {
        return (
            <ul className="nav flex-column dropdown">
                <div className="logo__wrapper">
                    <div className="system-type">
                        <div className="row">
                            <button className="tab-wrapper clear-button x-button-sidebar"
                                    onClick={this.toggleMenu}>
                                <img src={ex} alt="close"/>
                            </button>
                            {/* <Link to={this.props.page === "landing_page" ? "/accounting/" : "/"}
                                  className="sidebar-logo"> */}
                                <div className='sidebar-logo'>
                                    <img alt="logo" src={eversureLogo} className="header-logo"/>
                                </div>
                            {/* </Link> */}
                            <span onClick={this.toggleDropdown} className="web-page">
        		  				{this.props.page === "landing_page"
                                || this.props.page === "accounts_payable"
                                || this.props.page === "overdue_customer"
                                || this.props.page === "financial_statement"
                                || this.props.page === "ledger"
                                || this.props.page === "acc_total_paid"
                                || this.props.page === "report"
                                || this.props.page === "customer"
                                || this.props.page === "accounting"
                                    ? "Sales" : this.props.page === "inventory" ? "Inventory" : "Old Records"} &nbsp;
                                <i className={this.state.dropdownShown ? "fa fa-chevron-up" : "fa fa-chevron-down"}/>
        		  			</span>
                            <span onClick={this.toggleDropdown} className="tab-page">
        		  				{this.props.page === "landing_page"
                                || this.props.page === "overdue_customer"
                                || this.props.page === "financial_statement"
                                || this.props.page === "accounts_payable"
                                || this.props.page === "ledger"
                                || this.props.page === "acc_total_paid"
                                || this.props.page === "report"
                                || this.props.page === "customer"
                                || this.props.page === "accounting"
                                    ? "Sales" : "Old Records"} &nbsp;
                                <i className={this.state.dropdownShown ? "fa fa-chevron-up" : "fa fa-chevron-down"}/>
        		  			</span>
                            {
                                this.state.dropdownShown ?
                                    this.props.page === "old_records" ?
                                        <ul className="dropdown-content">
                                            <li>
                                                <center>
                                                    <Link to={"/"}>
                                            <span
                                                className="dropdown-selection">
                                                Inventory
                                            </span>
                                                    </Link>
                                                </center>
                                            </li>
                                            <li>
                                                <center>
                                                    <Link to={"/accounting"}>
                                        <span
                                            className="dropdown-selection">
                                            Sales
                                        </span>
                                                    </Link>
                                                </center>
                                            </li>
                                        </ul> :
                                        <ul className="dropdown-content">
                                            <li>
                                                <center>
                                                    <Link to={"/"}>
                                            <span
                                                className="dropdown-selection">
                                                Inventory
                                            </span>
                                                    </Link>
                                                </center>
                                            </li>
                                            <li>
                                                <center>
                                                    <Link to={"/old_records"}>
                                        <span
                                            className="dropdown-selection">
                                            Old Records
                                        </span>
                                                    </Link>
                                                </center>
                                            </li>
                                        </ul>
                                    :
                                    null
                            }
                        </div>
                    </div>
                    {this.props.page === "old_records" ?
                        (<div content="Customers" className="sidebar__wrapper">
                            <li className={"nav-item " + (this.props.page === 'old_records' ? 'active' : '')}>
                                <Link
                                    to='/old_records'
                                    className={"sidebarLabel " + (this.props.page === 'old_records' ? 'active' : '')}
                                    style={styles.linkColor}
                                >
                                    <div className="sidebarIcons" style={styles.iconWrap}>
                                        <img
                                            alt="icon"
                                            src={this.props.page === 'old_records' ? customerLogoBlue : customerLogo}
                                            className="sidebarLogo"
                                            style={{
                                                width: 25
                                            }}
                                        />
                                    </div>
                                    <div className={"sidebarLabel__wrapper"}>
                                       <span
                                           className={this.props.page === 'old_records' ? "sidebarLabelBlue sidebarLabel" : "sidebarLabel"}>
                                           Customers
                                       </span>
                                    </div>
                                </Link>
                            </li>
                        </div>) :
                        <>
                            <div content="Customers" className="sidebar__wrapper">
                                <li className={"nav-item " + (this.props.page === 'customer' ? 'active' : '')}>
                                    <Link
                                        to='/all_customers'
                                        className={"sidebarLabel " + (this.props.page === 'customer' ? 'active' : '')}
                                        style={styles.linkColor}
                                    >
                                        <div className="sidebarIcons" style={styles.iconWrap}>
                                            <img
                                                alt="icon"
                                                src={this.props.page === 'customer' ? customerLogoBlue : customerLogo}
                                                className="sidebarLogo"
                                                style={{
                                                    width: 25
                                                }}
                                            />
                                        </div>
                                        <div className={"sidebarLabel__wrapper"}>
                                            <span
                                                className={this.props.page === 'customer' ? "sidebarLabelBlue sidebarLabel" : "sidebarLabel"}
                                            >
                                                Customers
                                            </span>
                                        </div>
                                    </Link>
                                </li>
                            </div>
                            <div content="Reports" onClick={this.toggleMenu}>
                                <li className={"nav-item " + (this.props.page === 'report' ? 'active' : '')}>
                                    <Link
                                        to='/acc_total_paid'
                                        className={"sidebarLabel " + (this.props.page === 'report' ? 'active' : '')}
                                        style={styles.linkColor}
                                    >
                                        <div className="sidebarIcons" style={styles.iconWrap}>
                                            <img
                                                alt="icon"
                                                src={this.props.page === 'report' ? accIconsBlue : accIcons}
                                                className="sidebarLogo"
                                                style={{
                                                    width: 25
                                                }}
                                            />
                                        </div>
                                        <div
                                            className={this.props.page === 'report' ? "sidebarLabelBlue sidebarLabel" : "sidebarLabel"}
                                        >
                                            Reports
                                        </div>
                                    </Link>
                                </li>
                            </div>
                            <div content="Overdue" onClick={this.toggleMenu}>
                                <li className={"nav-item " + (this.props.page === 'overdue_customer' ? 'active' : '')}>
                                    <Link
                                        to='/overdue_customer/'
                                        className={"sidebarLabel " + (this.props.page === 'overdue_customer' ? 'active' : '')}
                                        style={styles.linkColor}
                                    >
                                        <div className="sidebarIcons" style={styles.iconWrap}>
                                            <img
                                                alt="icon"
                                                src={this.props.page === 'overdue_customer' ? overdueIconBlue : overdueIcon}
                                                className="sidebarLogo"
                                                style={{
                                                    width: 30
                                                }}
                                            />
                                        </div>
                                        <div
                                            className={this.props.page === 'overdue_customer' ? "sidebarLabelBlue sidebarLabel" : "sidebarLabel"}
                                        >
                                            Overdue
                                        </div>
                                    </Link>
                                </li>
                            </div>
                            <div content="Financial Statement" onClick={this.toggleMenu}>
                                <li className={"nav-item " + (this.props.page === 'financial_statement' ? 'active' : '')}>
                                    <Link
                                        to='/acc_financial_statement/'
                                        className={"sidebarLabel " + (this.props.page === 'financial_statement' ? 'active' : '')}
                                        style={styles.linkColor}
                                    >
                                        <div className="sidebarIcons" style={styles.iconWrap}>
                                            <img
                                                alt="icon"
                                                src={this.props.page === 'financial_statement' ? financialIconBlue : financialIcon}
                                                className="sidebarLogo"
                                                style={{
                                                    width: 25
                                                }}
                                            />
                                        </div>
                                        <div
                                            className={this.props.page === 'financial_statement' ? "sidebarLabelBlue sidebarLabel" : "sidebarLabel"}
                                        >
                                            Financial <br/> Statement
                                        </div>
                                    </Link>
                                </li>
                            </div>
                            <div content="Ledger" onClick={this.toggleMenu}>
                                <li className={"nav-item " + (this.props.page === 'ledger' ? 'active' : '')}>
                                    <Link
                                        to='/ledger/'
                                        className={"sidebarLabel " + (this.props.page === 'ledger' ? 'active' : '')}
                                        style={styles.linkColor}
                                    >
                                        <div className="sidebarIcons" style={styles.iconWrap}>
                                            <img
                                                alt="icon"
                                                src={this.props.page === 'ledger' ? ledgerIconBlue : ledgerIcon}
                                                className="sidebarLogo"
                                                style={{
                                                    width: 27
                                                }}
                                            />
                                        </div>
                                        <div
                                            className={this.props.page === 'ledger' ? "sidebarLabelBlue sidebarLabel" : "sidebarLabel"}
                                        >
                                            Ledgers
                                        </div>
                                    </Link>
                                </li>
                            </div>
                            <div content="Accounts Payable" onClick={this.toggleMenu}>
                                <li className={"nav-item " + (this.props.page === 'accounts_payable' ? 'active' : '')}>
                                    <Link
                                        to='/accounts_payable/'
                                        className={"sidebarLabel " + (this.props.page === 'accounts_payable' ? 'active' : '')}
                                        style={styles.linkColor}
                                    >
                                        <div className="sidebarIcons" style={styles.iconWrap}>
                                            <img
                                                alt="icon"
                                                src={this.props.page === 'accounts_payable' ? accIconsBlue : accIcons}
                                                className="sidebarLogo"
                                                style={{
                                                    width: 25
                                                }}
                                            />
                                        </div>
                                        <div
                                            className={this.props.page === 'accounts_payable' ? "sidebarLabelBlue sidebarLabel" : "sidebarLabel"}
                                        >
                                            Accounts
                                        </div>
                                    </Link>
                                </li>
                            </div>
                        </>
                    }
                    <li className={"app-version"}>
                        <small>
                            Version: {MY_APP.version}
                        </small>
                    </li>
                </div>
            </ul>
        );
    }
}

const styles = {
    linkColor: {
        color: "#4f4f4f"
    },
    iconWrap: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    }
}
