import React, { Component } from 'react';

//redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// actions
import { ActionCreators } from '../../../actions';

import {
    encodeToString,
    leadingZero,
} from '../../../helpers'

import toastr from 'toastr';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const $ = require('jquery');

var _0x896d=["\x74\x65\x73\x74\x32"];var secret_user=_0x896d[0],secret_pass=_0x896d[0]
const table_class = ".tbl-payment-history"

class payment_history_modal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            // date_paid: moment().format('MM/DD/YYYY')
            date_paid: moment(),
            remainingBalance : ''
        };
    }
    componentDidMount(){
        const that = this;

        var paymentDtTable = $(table_class).DataTable({
            "columnDefs": [
                {
                    "visible": false,
                    "targets": 0
                },
            ],
            columns: [
                { title: "" },
                { title: "DATE" },
                { title: "OR NUMBER" },
                { title: "DR" },
                { title: "CR" },
                { title: "REBATE" },

                { title: "REMARKS" },
            ],
            "paging": false,
            "pageLength": 100,
            "searching": false,
            "ordering": false,
            "info": false,
            "sDom": '<"bottom"<t>ip><"clear">'
        });

        this.getPaymentHistory();
    }

    getPaymentHistory = () => {
        const that = this;
        let { customerInfo } = this.props,
            { account_number } = customerInfo;

        this.props.actions.getCustomerPaymentHistory(account_number)
            .then((res) => {
                if(res.status){
                    that.reloadDT(res.data)
                }
            })
            .catch(() => {

            })
    }

    reloadDT = (data) => {
        const table = $(table_class).DataTable();
        table.clear();
        table.rows.add(data);
        table.draw();
    }

    closeModal = () => {
        $('.modal').modal('hide');
    }

    handleDatePaid = (date) => {
        this.setState({date_paid: date})
    }

    render() {
        return (
            <div className="modal fade bd-example-modal-lg" id="payment_history" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" data-backdrop="static">
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Payment History</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="form-group">
                                <button
                                    className="btn btn-sm btn-warning"
                                    onClick={this.getPaymentHistory}
                                >
                                    Reload Payment History
                                </button>
                            </div>
                            <table style={{width: '100%'}} className="table table-sm tbl-payment-history table-bordered"></table>
                        </div>
                        <div className="modal-footer">
                            <button type="button"  className="btn btn-secondary" data-dismiss="modal" ref="cancel">Close</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


const mapStateToProps = state => ({
    category: state.category,
    app: state.feathersClient.app,
    mainapp: state.feathersClient.mainapp,
    customer: state.customers.customerLedgerInfo,
    customerInfo: state.customers.customerInfo,
    session_id: state.login.userData._id,
    ledgerItemId: state.customers.ledgerItemId,
    remainingBalance: state.customers.remainingBalance,
    total_payments: state.customers.total_payments,
});

function mapDispatchToProps(dispatch) {
    return { actions: bindActionCreators(ActionCreators, dispatch) }
}

export default connect(mapStateToProps, mapDispatchToProps)(payment_history_modal);
