import React, { Component } from "react";
import app from "../../../helpers/feathers";

//redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
// actions
import { ActionCreators } from "../../../actions";

//main NavBar
import MainNav from "../../../components/global_nav";
import MainSideBar from "../../../components/acc_sidebar";

//sub side bar
import SubSideBar from "./report_sub_sidebar";

//custom styles
import "../../../stylesheet/styles.css";

//datatables
import "../../../stylesheet/datatables/datatables.css";

//select tag
import Select from "react-select";


import {
  numberWithCommas
} from '../../../helpers/';

//daterange
import moment from "moment";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "./plugins/daterangepicker.css";

import toastr from "toastr";
const $ = require("jquery");
$.DataTable = require("datatables.net");

var that;

class create_total_paid_report extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectChanged: false,
      selectedArea : '',
      selectedAreaChanged : false,
      value: '',
      table_data: [],
      brand: [],
      filter_reporttype: null,
      filter_daterange: null,
      filter_branch: "",
      filter_showby: null,
      filter_brand: null,
      filter_columnby: "",
      filter_columndata: null,
      sample: null,
      saving: false,
      drPickerApplied: false,
      date_today: moment().format("MM/DD/YYYY"),
      startDate: moment().subtract(29, "days"),
      endDate: moment(),
      report_type: "",
      report_type_changed: false,
      selectReportType: [
        { value: "no_clearance", label: "No clearances or TBA's" },
        { value: "with_warranty", label: "With Warranty Claims" }
      ],
      selectedBranch: { value: "all", label: "All" },
      selectedModel: "",
      selectModelChanged: false,
      selectedBrand: "",
      selectedBrandChanged: false,
      selectedDaterange: false,
      selectedOption: "",
      modelsObj: [],
      selectedMonth: [],
      totalPaid:0,
      selectedYear: []
    };
  }

  componentWillMount() {
    that = this;
    // that.table_data();
    this.props.actions.getArea();
  }

  componentDidMount() {
    var dt_table = $(".tbl-create-report").DataTable({
      data: this.state.table_data,
      columns: [
        { title: "product object" },
        { title: "ACCOUNT #" },
        { title: "OR #" },
        { title: "AMOUNT" },
        { title: "REBATE" },
        { title: "DATE PAID" },
        {title : "AREA"}
      ],
      columnDefs: [
        {
          targets: [0],
          visible: false
        }
      ],
      "lengthMenu": [[10, 25, 50, -1], [10, 25, 50, "All"]],
      "paginationRowsPerPageOptions": [10, 15, 20, 25, 30],
      "paging": false,
      "pageLength": 10000,
      "searching": true,
      "ordering": false,
      // "info": false,
      "sDom": '<"bottom"<t>ip><"clear">',
      "bFilter": false,

    });
  }

  handleApply(event, picker) {
    that.setState({
      startDate: picker.startDate,
      endDate: picker.endDate,
      drPickerApplied: true
    });

    // moment(this.state.startDate).format('MM/DD/YYYY')
    that.enumDate(picker.startDate, picker.endDate);
  }

  handleDate = (e, p) => {
    var d = p.startDate;
    var formatted = moment(d).format("MM/DD/YYYY");

    this.setState({
      date_today: formatted,
      drPickerApplied: true
    });
  };

  enumDate = (startDate, endDate) => {
    var dates = [];

    var currDate = moment(startDate).startOf("day");
    var lastDate = moment(endDate).startOf("day");

    while (currDate.add(1, "days").diff(lastDate) < 0) {
      // console.log(currDate.toDate());
      var mdate = moment(currDate.clone().toDate()).format("MM/DD/YYYY");
      dates.push(mdate);
      // dates.push(currDate.clone().toDate());
    }
    dates.unshift(moment(startDate).format("MM/DD/YYYY"));
    dates.push(moment(endDate).format("MM/DD/YYYY"));

    that.setState({
      selectedDaterange: dates
    });
  };


//  Table for Display

  table_data = () => {
    that.props.actions.getTotalPaid().then(data => {
      console.log("getTotalPaid ", data);

      if(data){
        that.totalPaid(data.data);
        that.setState({
          totalPaid : data.total
        })
      }
      else{
        that.totalPaid([]);
        that.setState({
          totalPaid : 0
        })
      }

    });
  };

  totalPaid = data => {
    this.setState({
      table_data: data
    });
    // that.setState({
    //   totalPaid : data.total
    // })
    const table = $(".tbl-create-report").DataTable();
    table.clear();
    table.rows.add(data);
    table.draw();
  };

  applyReportFilter = () => {

    var query = {
          area : that.state.selectedArea.label
        },
        daterange = that.state.selectedDaterange,
        branch = that.state.selectedBranch
            ? that.state.selectedBranch.value === "all"
                ? ""
                : that.state.selectedBranch.value
            : that.state.selectedBranch,
        brand = that.state.selectedBrand
            ? that.state.selectedBrand.value === "all"
                ? ""
                : that.state.selectedBrand.value
            : that.state.selectedBrand,
        model = that.state.selectedModel
            ? that.state.selectedModel.value === "all"
                ? ""
                : that.state.selectedModel.value
            : that.state.selectedModel,
        report_type = that.state.report_type;
    if (daterange) {
      query.date_paid = {
        $in: daterange
      };
    }

    that.props.actions.getTotalPaid(query)
    .then(data => {
      that.props.actions.getTotalPaidSH(query)
      .then((res)=>{
        if(res.data){
          if(data.data){
            var merged = data.data.concat(res.data)
            that.totalPaid(merged)
          }else{
            that.totalPaid(res.data)
          }
          that.setState({
            totalPaid : (data.total) + res.total
          })
        }
        else{
          if(data.data){
            that.totalPaid(data.data)
          }else{
            that.totalPaid([])
          }

          that.setState({
            totalPaid : (data.total) + res.total
          })
        }
      })
    });
  };


  handleChangeReportType = selectedOption => {
    var value = selectedOption;

    if (value === null) {
      this.setState({
        report_type: "",
        report_type_changed: false
      });
    } else {
      this.setState({
        report_type: value,
        report_type_changed: true
      });
    }
  };

  handleChangeBranch = selectedOption => {
    var value = selectedOption;

    if (value === null) {
      this.setState({
        selectedBranch: "",
        filter_branch: value,
        selectBranchChanged: false
      });
    } else {
      this.setState({
        selectedBranch: value,
        filter_branch: value,
        selectBranchChanged: true
      });
    }
  };

  handleChangeModel = selectedOption => {
    var value = selectedOption;

    if (value != null) {
      this.setState({
        selectedModel: value,
        selectModelChanged: true
      });
    } else {
      this.setState({
        selectedModel: "",
        selectModelChanged: false
      });
    }
  };

  handleChangeBrand = selectedOption => {
    var value = selectedOption;

    if (value != null) {
      this.setState({
        selectedBrand: value,
        selectedBrandChanged: true
      });
      var model = that.state.selectedOption.value,
          brand = value.value === "all" ? null : value.value;

      that.props.actions.findChassisUsingBrand(brand).then(d => {
        var models = d.data,
            modelsObj = [];

        models.map(v => {
          modelsObj.push({
            label: v.model_name,
            value: v._id
          });
        });

        that.setState({
          selectedOption: "",
          modelsObj: modelsObj
        });
      });
    } else {
      that.setState({
        selectedBrand: "",
        selectedBrandChanged: false,
        modelsObj: [],
        selectedOption: ""
      });
    }
  };

  handleChange = selectedOption => {
    var value = selectedOption;

    if (value != null) {
      this.setState({
        selectedOption: value,
        selectedModel: value,
        selectChanged: true
      });
    } else {
      this.setState({
        selectedOption: "",
        selectedModel: "",
        selectChanged: false
      });
    }
  };

  saveReport = () => {
    var title = that.refs.report_title.value.trim(),
        table_data = that.state.table_data,
        type = "total_paid_report",
        drPickerApplied = that.state.drPickerApplied,
        startDate = drPickerApplied ? that.state.startDate : null,
        endDate = drPickerApplied ? that.state.endDate : null;

    var reportData = {
      report_type: type,
      report_title: title,
      user_id: that.props.userData._id,
      user_fullname: that.props.userData.fullname,
      table_data: table_data,
      daterange_start: startDate,
      daterange_end: endDate
    };

    if (!title) {
      toastr.remove();
      toastr.error("Please add report title");
      return;
    }

    if (that.state.saving) {
      toastr.info("Please wait while saving your report ...");
      return;
    } else {
      that.setState({
        saving: true
      });

      that.props.actions
          .saveReport(reportData)
          .then(d => {
            if (d) {
              toastr.remove();
              toastr.success("Report has been successfully saved");
              that.refs.report_title.value = "";
              that.props.history.push("/acc_total_paid");
            } else {
              toastr.remove();
              toastr.error("An error occured. Please try again");
            }
            that.setState({
              saving: false
            });
          })
          .catch(() => {
            toastr.remove();
            toastr.error("An error occured. Please try again");
            that.setState({
              saving: false
            });
          });
    }
  };

  monthOption = () => {
    var months = [
      { label: "January", value: "1" },
      { label: "February", value: "2" },
      { label: "March", value: "3" }
    ];
    return months;
  };

  yearOption = () => {
    var range = new Date().getFullYear(),
        years_range = [];

    for (var i = range; i > range - 60; i--) {
      var years = { label: i, value: i };
      years_range.push(years);
    }
    return years_range;
  };

  handleChangeMonth = month => {
    if (month == null) {
      var empty = { label: "Select Month", value: "0" };
      this.setState({ selectedMonth: empty });
      return;
    }
    this.setState({ selectedMonth: month });
  };

  handleChangeYear = year => {
    if (year == null) {
      var yearEmpty = { label: "Select Year", value: "0" };
      this.setState({ selectedYear: yearEmpty });
      return;
    }
    this.setState({ selectedYear: year });
  };


  handleSelectedArea = (selectedOption) => {
    var value = selectedOption;
    if(value != null) {
      // that.refs.area.value = "";
      this.setState({
        selectedArea : value,
        selectedAreaChange: true,
      });
    }else{
      this.setState({
        selectedArea : '',
        selectedAreaChange :  true,
        // areaOther : true,
      })
    }

  }

  areaOption = () => {
    var array = this.props.areaList;
    return array
  }

  onChange = (event, { newValue }) => {
    this.setState({
      value: newValue
    });
  };

  render() {
    return (
        <div className="">
          {/* main NavBar component*/}
          <MainNav
              historyProp={this.props.history}
              usersProp={this.props.userData}
              systemType="accounting"
          />

          <div className="container-fluid">
            <div className="row full-width">
              <div className="sidebar">
                <div className="sidebar-content">
                  <MainSideBar page="report" />
                </div>
              </div>
              <SubSideBar history={this.props.history} pageTitle="RPRTS_TOTAL_PAID"/>
              <div className="sub-main-content__create">
                <div className="container-fluid">
                  <div className="row padding-10 background-white create-report-unsold">
                    <div className="col-md-12">
                      <div className="row">

                        <div className = "col-lg-4">
                          <label>Select Area</label>
                          <br />
                          <Select
                              name="select-area"
                              value={this.state.selectedArea.value}
                              onChange={this.handleSelectedArea}
                              options = {this.areaOption()}
                              clearableValue={true}
                              placeholder="Select Area"
                          />
                        </div>
                        <div className="col-lg-4">
                          <label>Date Range</label>
                          <br />
                          <DateRangePicker
                              onApply={this.handleApply}
                              startDate={this.state.startDate}
                              endDate={this.state.endDate}
                          >
                            <button className="form-control">
                              {
                                this.state.drPickerApplied ? (moment(this.state.startDate).format('ll')+' - '+moment(this.state.endDate).format('ll')) : 'No daterange selected'
                              }
                            </button>
                          </DateRangePicker>
                        </div>

                        <div className="col-lg-3 pull-right">
                          <label>&nbsp;</label>
                          <br />
                          <button
                              className="btn btn-block btn-primary"
                              onClick={() => this.applyReportFilter()}>
                            Filter
                          </button>
                        </div>
                      </div>
                      <br />
                      <div className="row">
                        <div className="col" />
                      </div>
                    </div>
                  </div>
                  <div className="row padding-10 background-white">
                    <div className="center-div">
                      <button
                          className="btn btn-secondary application-btn border-radius-50 padding-10-40 text-white bg-active-color savebutton"
                          onClick={() => this.saveReport()}>
                        SAVE REPORT
                      </button>
                    </div>
                  </div>

                  <div className="row padding-20">
                    <h4>Total Paid Reports</h4>
                  </div>

                  <div className="row padding-20 background-white">
                    <div className="col-md-4">
                      <input
                          className="form-control report-title"
                          type="text"
                          placeholder="Report title here"
                          ref="report_title"
                      />
                      <br />
                      <p>By {this.props.userData.fullname}</p>
                    </div>
                    <div className="col-md-3" />
                    <div className="col-md-5">
                      <h5>TOTAL PAID REPORT</h5>
                      <span>{moment().format("LL")}</span>
                      <br />
                      <span>
                      {this.props.userData.branch_info.branch_name + " BRANCH"}
                    </span>
                    </div>
                    <div className="col-md-12">
                      <br />
                    </div>
                    <div className="col-md-12">
                      <div className="header-pagination-container">
                        <ul className="pagination" />
                      </div>
                      <div className = "pull-right">
                        TOTAL : <span className = "alert alert-primary">{numberWithCommas(this.state.totalPaid ? this.state.totalPaid : 0)}</span>
                      </div>
                      <table className="table tbl-create-report tbl-mobile" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    );
  }
}

const mapStateToProps = state => ({
  app: state.feathersClient.app,
  branch: state.category.branchesSelect,
  brandsSelect: state.category.brandsSelect,
  models: state.category.modelsSelect,
  userData: state.login.userData,
  areaList: state.customers.areaList
});

function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators(ActionCreators, dispatch) };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(create_total_paid_report);
