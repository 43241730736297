import React, {Component} from 'react';
import app from '../../../../helpers/feathers';
//redux
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
// actions
import {ActionCreators} from '../../../../actions';

//select tag
import Select from 'react-select';

import toastr from 'toastr';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const $ = require('jquery');

var that

class edit_customer_modal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            selectedPosition: '',
            fullname: '',
            username: '',
            selectedTitle: '',
            selectedTitleChanged: false,
            passwordIsStrong: false,
            date_paid: moment(),
            selectedArea: '',
            selectedAreaChanged: false,
            selectedBrand: '',
            date_purchased: moment(),
            date_purchased_change: false,
            first_payment_date: moment(),
            first_payment_date_change: false,
            first_payment_date_changed: false,
            selectedBrandChanged: false,
            selectedOption: '',
            modelsObj: [],
            submitting: false,
        };
    }

    componentWillMount() {
        that = this;
        this.props.actions.getArea();

    }


    handleChangePosition = (selectedOption) => {
        var value = selectedOption;
        this.setState({
            selectedPosition: value
        });
    }

    handleDatePaid = (date) => {
        this.setState({date_paid: date})
    }

    componentDidMount() {
        let paymentInfo = this.props.paymentInfoEdit;
        let {customerInfo} = this.props;
    }

    brandOption = () => {

        var array = this.props.brandsSelect,
            newArray = []

        array.map((obj) => {
            if (obj.value !== 'all') {
                newArray.push(obj)
            }
        })
        return newArray
    }

    handleDatePurchased = (date) => {
        this.setState({date_purchased: date, date_purchased_change: true})
    }
    handlefirstPaymentDate = (date) => {
        this.setState({first_payment_date: date, first_payment_date_changed: true})
    }

    handleChangeBrand = (selectedOption) => {
        this.setState({
            selectedBrand: selectedOption
        });

        var value = selectedOption;

        if (value != null) {
            this.setState({
                selectedBrand: value,
                selectedBrandChanged: true
            });
            var model = that.state.selectedOption.value,
                brand = value.value;
            that.props.actions.findChassisUsingBrand(brand)
                .then((d) => {
                    var models = d.data,
                        modelsObj = [];

                    models.map((v) => {
                        modelsObj.push({
                            label: v.model_name,
                            value: v._id
                        })
                    })

                    that.setState({
                        selectedOption: '',
                        chassisOption: '',
                        modelsObj: modelsObj,
                        chassisObj: []
                    })
                })
        } else {
            that.setState({
                selectedBrand: '',
                selectedBrandChanged: false,
                modelsObj: [],
                selectedOption: '',
                chassisOption: '',
                chassisChanged: false,
                chassisObj: [],
            })
        }
    }

    modelOption = () => {
        var array = this.props.models,
            newArray = []

        array.map((obj) => {
            if (obj.value !== 'all') {
                newArray.push(obj)
            }
        })
        return newArray
    }


    areaOption = () => {
        var array = this.props.areaList;
        return array;
    }

    handleSelectedArea = (selectedOption) => {
        var value = selectedOption;
        if (value != null) {
            that.refs.area.value = "";
            this.setState({
                selectedArea: value,
                selectedAreaChange: true,
            });
        } else {
            this.setState({
                selectedArea: '',
                selectedAreaChange: false,
                // areaOther : true,
            })
        }

    }
    handleChange = (selectedOption) => {

        var value = selectedOption;

        if (value != null) {
            this.setState({
                selectedOption: value,
                selectChanged: true
            });

            that.props.actions.findChassisUsingModel(value.value)
                .then((d) => {
                    var models = d.data,
                        chassisObj = [];

                    // console.log(models)

                    models.map((v) => {
                        chassisObj.push({
                            label: v.engine_number,
                            value: v.engine_number,
                            unitPrice: v.price ? v.price : 0,
                            unitId: v._id,
                        })
                    })

                    console.log(chassisObj)

                    that.setState({
                        chassisObj: chassisObj
                    })
                })

        } else {
            this.setState({
                selectedOption: '',
                selectChanged: false,
                chassisOption: '',
                chassisChanged: false,
            })
        }
    }

    handleChangeChassis = (selectedOption) => {
        var value = selectedOption;
        console.log('haha ', value);
        if (value != null) {
            this.setState({
                chassisOption: value,
                chassisChanged: true
            });

        } else {
            this.setState({
                chassisOption: '',
                chassisChanged: false,
            })
        }
    }


    /* editCustomerDetails START */
    editCustomerDetails = () => {
        const that = this;
        let query = {};
        let {customerInfo} = this.props;
        let {selectedTitleChanged, selectedAreaChange, date_purchased_change, date_purchased, first_payment_date, first_payment_date_changed, selectedTitle, selectedArea} = this.state;
        let {name, area, payment, remarks, contact_number, customer_address, account_number} = this.refs;
        let chosenArea = selectedAreaChange ? selectedArea.label : area.value;
        if (!selectedTitleChanged &&
            account_number.value === '' &&
            name.value === '' &&
            customer_address.value === '' &&
            contact_number.value === "" &&
            !selectedAreaChange &&
            payment.value === '' &&
            remarks.value === '' &&
            !date_purchased_change &&
            !first_payment_date_changed) {
            toastr.remove();
            toastr.info("No changes made");
            return;
        }

        if (account_number.value) {
            query.account_number = account_number.value
        }
        if (selectedTitle !== '') {
            query.title = selectedTitle.value
        }
        if (name.value) {
            query.name = name.value
        }
        if (customer_address.value) {
            query.customer_address = customer_address.value
        }
        if (contact_number.value) {
            query.contact_number = contact_number.value
        }
        if (chosenArea) {
            query.area = chosenArea
        }
        if (payment.value) {
            query.payment = payment.value
        }
        if (remarks.value) {
            query.remarks = remarks.value
        }
        if (date_purchased_change) {
            query.date_purchased = moment(date_purchased).format("MM/DD/YYYY")
            query.date_purchased_formatted = moment(date_purchased).format("MM/YYYY")
        }
        if (first_payment_date_changed) {
            query.first_payment_date = moment(first_payment_date).format("MM/DD/YYYY")
        }


        if (account_number.value === '') {
            // update_customer_info(customerInfo._id, query);
            let l_customer_service = app.service('customers').patch(customerInfo._id, query)
                .then((update_customer) => {
                    console.log('update_customer ', update_customer)

                    toastr.success('Customer information successfully updated');
                    that.closeModel();
                    /* update customer info in head office START */
                    var update_head_customer_info = function () {
                        let head_customer_service = app.service("customers");
                        let local_customer_service = app.service("customers");
                        /* head customer find START */
                        head_customer_service.find({
                            query: {
                                account_number: customerInfo.account_number
                            }
                        })
                            .then((f_head_customer) => {
                                console.log('f_head_customer ', f_head_customer)
                                let head_customer_data = f_head_customer.data;
                                if (head_customer_data.length > 0) {
                                    let head_customer_id = head_customer_data[0]._id;
                                    /* head customer info patch START */
                                    head_customer_service.patch(head_customer_id, query)
                                        .then((p_head_customer) => {
                                            console.log('p_head_customer ', p_head_customer)
                                        })
                                        .catch((p_head_customer_e) => {
                                            console.log('p_head_customer_e ', p_head_customer_e)
                                            local_customer_service.patch(customerInfo._id, {main_customer_status: 3})
                                                .then((p_local_customer) => {
                                                    console.log('p_local_customer ', p_local_customer)
                                                })
                                                .catch((p_local_customer_e) => {
                                                    console.log('p_local_customer_e ', p_local_customer_e)
                                                })
                                        })
                                    /* head customer info patch END */
                                }
                            })
                            .catch((f_head_customer_e) => {
                                console.log('f_head_customer_e ', f_head_customer_e)
                            })
                        /* head customer find END */
                    }
                    /* update customer info in head office END */
                    // update_head_customer_info();
                    if (first_payment_date_changed) {
                        console.log('first_payment_date_changed ', first_payment_date_changed)
                        /* delete_existing_ledger START */
                        var delete_existing_ledger = function (data, acc_num_updated) {
                            console.log('delete_existing_ledger')
                            let l_ledger = app.service("ledger");
                            /* l_ledger find START */
                            l_ledger.find({
                                query: {
                                    account_number: customerInfo.account_number
                                }
                            })
                                .then((find_l_ledger) => {
                                    let ledger_data = find_l_ledger.data;
                                    if (ledger_data.length > 0) {
                                        console.log('customerInfo.term')
                                        console.log(customerInfo.term)
                                        /* delete_recursive START */
                                        var delete_recursive = function (value) {
                                            console.log('delete_recursive ', value)
                                            let item = ledger_data[value];
                                            let item_id = item._id;
                                            if (value > 0) {
                                                l_ledger.remove(item_id)
                                                    .then(() => {
                                                        delete_recursive(value - 1);
                                                    })
                                                    .catch(() => {
                                                        delete_recursive(value - 1);
                                                    })
                                            } else {
                                                l_ledger.remove(item_id)
                                                    .then(() => {
                                                        insertNewLedger((customerInfo.term - 1), data, acc_num_updated);
                                                    })
                                                    .catch(() => {
                                                        insertNewLedger((customerInfo.term - 1), data, acc_num_updated);
                                                    })
                                            }
                                        }
                                        /* delete_recursive END */
                                        delete_recursive(ledger_data.length - 1)
                                    } else {
                                        insertNewLedger((customerInfo.term - 1), data, acc_num_updated);
                                    }
                                })
                                .catch((find_l_ledger_e) => {
                                    console.log('find_l_ledger_e ', find_l_ledger_e)
                                })
                            /* l_ledger find END */
                        }
                        /* delete_existing_ledger END */
                        delete_existing_ledger()
                    }
                })
                .catch((update_customer_e) => {
                    console.log('update_customer_e ', update_customer_e);
                    toastr.error("An error occured! Please try again");
                })
        } else {
            app.service('customers').find({
                query: {
                    account_number: query.account_number
                }
            })
                .then((findExistingAN) => {
                    if (findExistingAN.data.length > 0) {
                        toastr.error("Account Number already exists!");
                    } else {
                        // if account number is updated
                        let acc_num_updated = true;
                        // update_customer_info(customerInfo._id, query, acc_num_updated);
                        let l_customer_service = app.service('customers').patch(customerInfo._id, query)
                            .then((update_customer) => {
                                console.log('update_customer ', update_customer)
                                /* update customer info in head office START */
                                var update_head_customer_info = function () {
                                    let head_customer_service = app.service("customers");
                                    let local_customer_service = app.service("customers");
                                    /* head customer find START */
                                    head_customer_service.find({
                                        query: {
                                            account_number: customerInfo.account_number
                                        }
                                    })
                                        .then((f_head_customer) => {
                                            let head_customer_data = f_head_customer.data;
                                            if (head_customer_data.length > 0) {
                                                let head_customer_id = head_customer_data[0]._id;
                                                /* head customer info patch START */
                                                head_customer_service.patch(head_customer_id, query)
                                                    .then((p_head_customer) => {
                                                        console.log('p_head_customer ', p_head_customer)
                                                    })
                                                    .catch((p_head_customer_e) => {
                                                        console.log('p_head_customer_e ', p_head_customer_e)
                                                        local_customer_service.patch(customerInfo._id, {main_customer_status: 3})
                                                            .then((p_local_customer) => {
                                                                console.log('p_local_customer ', p_local_customer)
                                                            })
                                                            .catch((p_local_customer_e) => {
                                                                console.log('p_local_customer_e ', p_local_customer_e)
                                                            })
                                                    })
                                                /* head customer info patch END */
                                            }
                                            toastr.success('Customer information successfully updated');
                                            that.closeModel();
                                        })
                                        .catch((f_head_customer_e) => {
                                            console.log('f_head_customer_e ', f_head_customer_e)
                                        })
                                    /* head customer find END */
                                }
                                /* update customer info in head office END */
                                // update_head_customer_info();
                                if (first_payment_date_changed) {
                                    console.log('first_payment_date_changed ', first_payment_date_changed)
                                    var delete_existing_ledger = function (data, acc_num_updated) {
                                        console.log('delete_existing_ledger')
                                        let l_ledger = app.service("ledger");
                                        /* l_ledger find START */
                                        l_ledger.find({
                                            query: {
                                                account_number: customerInfo.account_number
                                            }
                                        })
                                            .then((find_l_ledger) => {
                                                let ledger_data = find_l_ledger.data;
                                                if (ledger_data.length > 0) {
                                                    console.log('customerInfo.term')
                                                    console.log(customerInfo.term)
                                                    /* delete_recursive START */
                                                    var delete_recursive = function (value) {
                                                        console.log('delete_recursive ', value)
                                                        let item = ledger_data[value];
                                                        let item_id = item._id;
                                                        if (value > 0) {
                                                            l_ledger.remove(item_id)
                                                                .then(() => {
                                                                    delete_recursive(value - 1);
                                                                })
                                                                .catch(() => {
                                                                    delete_recursive(value - 1);
                                                                })
                                                        } else {
                                                            l_ledger.remove(item_id)
                                                                .then(() => {
                                                                    insertNewLedger((customerInfo.term - 1), data, acc_num_updated);
                                                                })
                                                                .catch(() => {
                                                                    insertNewLedger((customerInfo.term - 1), data, acc_num_updated);
                                                                })
                                                        }
                                                    }
                                                    /* delete_recursive END */
                                                    delete_recursive(ledger_data.length - 1)
                                                } else {
                                                    insertNewLedger((customerInfo.term - 1), data, acc_num_updated);
                                                }
                                            })
                                            .catch((find_l_ledger_e) => {
                                                console.log('find_l_ledger_e ', find_l_ledger_e)
                                            })
                                        /* l_ledger find END */
                                    }
                                    /* delete_existing_ledger END */
                                    delete_existing_ledger()
                                }
                            })
                            .catch((update_customer_e) => {
                                console.log('update_customer_e ', update_customer_e);
                                toastr.error("An error occured! Please try again");
                            })
                    }
                })
        }
        /* update_customer_info END */
        /* update customer info in head office START */
        var update_head_customer_info = function () {
            let head_customer_service = app.service("customers");
            let local_customer_service = app.service("customers");
            /* head customer find START */
            head_customer_service.find({
                query: {
                    account_number: customerInfo.account_number
                }
            })
                .then((f_head_customer) => {
                    let head_customer_data = f_head_customer.data;
                    if (head_customer_data.length > 0) {
                        let head_customer_id = head_customer_data[0]._id;
                        /* head customer info patch START */
                        head_customer_service.patch(head_customer_id, query)
                            .then((p_head_customer) => {
                                console.log('p_head_customer ', p_head_customer)
                            })
                            .catch((p_head_customer_e) => {
                                console.log('p_head_customer_e ', p_head_customer_e)
                                local_customer_service.patch(customerInfo._id, {main_customer_status: 3})
                                    .then((p_local_customer) => {
                                        console.log('p_local_customer ', p_local_customer)
                                    })
                                    .catch((p_local_customer_e) => {
                                        console.log('p_local_customer_e ', p_local_customer_e)
                                    })
                            })
                        /* head customer info patch END */
                    }
                })
                .catch((f_head_customer_e) => {
                    console.log('f_head_customer_e ', f_head_customer_e)
                })
            /* head customer find END */
        }
        let i = 0;
        /* insertNewLedger START */
        var insertNewLedger = function (value, data, acc_num_updated) {
            let local_ledger = app.service("ledger");
            let head_ledger = app.service("ledger");
            let start_date = moment(first_payment_date).add('months').format('MM/DD/YYYY'),
                due_date = moment(start_date).add(i, 'months').format('MM/DD/YYYY'),
                date_value = moment(start_date).add(i, 'months').format('MM/YYYY'),
                promisory_note = Number(customerInfo.promisory_note),
                balance = promisory_note;

            console.log('MONTHS ==', i)
            console.log('DUE DATE ==', due_date)

            i += 1;


            let raw_data = {
                customer_id: customerInfo._id,
                account_number: acc_num_updated ? data.account_number : customerInfo.account_number,
                area: acc_num_updated ? data.area : customerInfo.area,
                due_date: due_date,
                date_value: date_value,
                monthly_amortization: (balance / Number(customerInfo.term)),
                balance: balance,
                promisory_note: promisory_note,
                branch: localStorage.getItem('local_branch_id'),
                added_by: that.props.session_id,
            }
            if (value > 0) {
                /* local ledger create START */
                local_ledger.create(raw_data)
                    .then((c_local_ledger) => {
                        console.log('c_local_ledger ', c_local_ledger)
                        insertNewLedger(value - 1)
                    })
                    .catch((c_local_ledger_e) => {
                        console.log('c_local_ledger_e ', c_local_ledger_e)
                        insertNewLedger(value - 1)
                    })
                /* local ledger create END */
            } else {
                /* local ledger create START */
                local_ledger.create(raw_data)
                    .then((c_local_ledger) => {
                        console.log('c_local_ledger ', c_local_ledger)
                        toastr.success('Customer successfully updated');
                        that.closeModel();
                    })
                    .catch((c_local_ledger_e) => {
                        console.log('c_local_ledger_e ', c_local_ledger_e)
                    })
                /* local ledger create END */
            }
        }
        /* insertNewLedger END */
    }
    /* editCustomerDetails END */

    editShCustomer = () => {
        let { customerInfo } = this.props;
        let  {selectedTitle,
        selectedArea,
        selectedAreaChange,
        date_purchased,
        date_purchased_change,
        first_payment_date,
        first_payment_date_changed,
        submitting } = this.state;
        let {
            account_number,
            name,
            customer_address,
            area,
            contact_number,
            payment,
            remarks,
        } = this.refs;
        let formData = {};
        let an_updated = false; // account number updated
        let fpd_updated = false; // first payment date updated

        if(submitting){
            toastr.info("Please wait while saving data")
            return;
        }

        if(selectedTitle !== ''){
            formData.title = selectedTitle.value
        }
        if( (account_number.value).trim() !== ''){
            formData.account_number = account_number.value
        }
        if( (name.value).trim() !== ''){
            formData.name = name.value
        }
        if( (customer_address.value).trim() !== ''){
            formData.customer_address = customer_address.value
        }
        if(selectedAreaChange){
            formData.area = selectedArea.label
        }else{
            if( (area.value).trim() !== '' ){
                formData.area = area.value
            }
        }
        if( (contact_number.value).trim() !== '' ){
            formData.contact_number = contact_number.value
        }
        if(date_purchased_change){
            formData.date_purchased = date_purchased
        }
        if(first_payment_date_changed){
            let fpd = moment(first_payment_date).format("MM/DD/YYYY");
            formData.first_payment_date = fpd;
            if( fpd !== customerInfo.first_payment_date){
                fpd_updated = true;
            }else{
                fpd_updated = false;
            }
        }
        if( (payment.value).trim() !== '' ){
            formData.payment = payment.value
        }
        if( (remarks.value).trim() !== '' ){
            formData.remarks = remarks.value
        }

        const ShCustomerService = app.service("customer-secondhand-unit");
        let query = {};

        that.setState({submitting: true})

        if(formData.account_number){
            if(formData.account_number !== customerInfo.account_number){
                query.account_number = formData.account_number;

                ShCustomerService.find({query: query})
                .then((find_acc_num) => {
                    if(find_acc_num.data.length > 0){
                        toastr.remove();
                        toastr.error("Account number already exists");
                    }else{
                        an_updated = true;
                        that.saveCustomerUpdate(formData,an_updated,fpd_updated);
                    }
                })
                .catch((error) => {
                    console.log('error ',error)
                    toastr.error("Error updating user data")
                })
            }else{
                that.saveCustomerUpdate(formData,an_updated,fpd_updated);
            }
        }else{
            that.saveCustomerUpdate(formData,an_updated,fpd_updated);
        }

    }

    saveCustomerUpdate = (formData,an_updated,fpd_updated) => {
        let { customerInfo } = this.props;
        if(Object.keys(formData).length > 0){
            const ShCustomerService = app.service("customer-secondhand-unit");
            const ShPaymentsService = app.service("customer-payments-secondhand");
            const ShLedgerService = app.service("ledger-secondhand");
            ShCustomerService.patch(customerInfo._id, formData)
            .then(() => {
                if(!an_updated && !fpd_updated){
                    toastr.success("Customer data successfully updated");
                    that.setState({submitting: false})
                    that.closeModel();
                }else{
                    that.props.actions.updatePaymentsAndLedger(customerInfo._id,formData,an_updated,fpd_updated,customerInfo)
                    .then((res) => {
                        console.log('res')
                        console.log(res)
                        that.setState({submitting: false})
                        if(res){
                            toastr.success("Customer data successfully updated");
                            that.closeModel();
                        }
                    })
                }
            })
            .catch((error) => {
                console.log('error ',error)
                toastr.error("Error updating user data")
            })
        }else{
            toastr.info("No changes made")
        }
    }

    clearInputs = () => {
        this.refs.account_number.value = "";
        this.refs.name.value = "";
        this.refs.payment.value = "";
        this.refs.remarks.value = "";
        this.refs.customer_address.value = "";
    }

    handleselectedTitle = (selectedOption) => {
        var value = selectedOption;
        if (value != null) {
            if (value.value) {
                this.setState({selectedTitle: value, selectedTitleChanged: true})
            }
        } else {
            this.setState({selectedTitle: '', selectedTitleChanged: false})
        }
    }


    closeModel = () => {

        that.setState({
            selectedTitle: '',
            selectedTitleChanged: false,
            selectedArea: '',
            selectedAreaChange: false,
            date_purchased_change: false
        })
        $('.form-control').val('');
        $('.modal').modal('hide');
    }

    render() {
        return (
            <div className="modal fade  bd-example-modal-lg " id="edit_customer" tabIndex="-1" role="dialog"
                 aria-labelledby="exampleModalLabel" aria-hidden="true" ref="add_branch" data-backdrop="static"
                 data-keyboard="false">

                <div className="modal-dialog  modal-lg " role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Edit Customer Information</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">

                            <div className="row">
                                <div className="col">
                                    <span>Title - {this.props.customerInfo ? this.props.customerInfo.title : ''}</span>
                                    <Select
                                        name="select-title"
                                        value={this.state.selectedTitle.value}
                                        onChange={this.handleselectedTitle}
                                        options={[
                                            {value: 'MS', label: 'MS'},
                                            {value: 'MRS', label: 'MRS'},
                                            {value: 'MR', label: 'MR'},

                                        ]}
                                        clearableValue={true}
                                        placeholder="MS/MRS/MR"
                                    />

                                </div>
                                <div className="col">
                                    <span>Account No. - {this.props.customerInfo ? this.props.customerInfo.account_number : ''}</span>
                                    <input type="text" className="form-control" ref="account_number"
                                           disabled={this.props.customerInfo ? !this.props.customerInfo.payment_method : false}/>
                                </div>
                            </div>
                            <br/>
                            <div className="row">
                                <div className="col">
                                    <span>Customer Name - {this.props.customerInfo ? this.props.customerInfo.name : ''}</span>
                                    <input type="text" className="form-control" ref="name" required/>
                                    <span><i>(Last name , First name , MI)</i></span>
                                </div>

                            </div>
                            <br/>
                            <div className="row">
                                <div className="col">
                                    <span>Customer Address - {this.props.customerInfo ? this.props.customerInfo.customer_address : ''}</span>
                                    <input type="text" className="form-control" ref="customer_address" required/>
                                </div>

                            </div>
                            <br/>
                            <div className="row">
                                <div className="col">
                                    <span>Area - {this.props.customerInfo ? this.props.customerInfo.area : ''}</span>
                                    <Select
                                        name="select-area"
                                        value={this.state.selectedArea.value}
                                        onChange={this.handleSelectedArea}
                                        options={this.areaOption()}
                                        clearableValue={true}
                                        placeholder="Select Area"
                                    />
                                </div>
                                <div className="col">
                                    <span>If other area (please specify)</span>
                                    <input type="text" className="form-control" ref="area"
                                           disabled={this.state.selectedAreaChange}/>
                                </div>

                                <div className="col">
                                    <span>Contact Number - {this.props.customerInfo ? this.props.customerInfo.contact_number : ''}</span>
                                    <input type="text" className="form-control" ref="contact_number"/>
                                </div>
                            </div>
                            <br/>
                            <div className="row">
                                <div className="col">
                                    <span>Date Purchased - {this.props.customerInfo ? moment(this.props.customerInfo.date_purchased).format('MM/DD/YYYY') : ''}</span>
                                    <DatePicker
                                        selected={this.state.date_purchased}
                                        onChange={this.handleDatePurchased}
                                        peekNextMonth
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                        className="form-control"
                                    />
                                </div>
                                {
                                    this.props.customerInfo.payment_method != 0 ?
                                    <div className="col">
                                        <span>First payment date - {this.props.customerInfo ? moment(this.props.customerInfo.first_payment_date).format('MM/DD/YYYY') : ''}</span>
                                        <DatePicker
                                            selected={this.state.first_payment_date}
                                            onChange={this.handlefirstPaymentDate}
                                            peekNextMonth
                                            showMonthDropdown
                                            showYearDropdown
                                            dropdownMode="select"
                                            className="form-control"
                                        />
                                    </div> : null
                                }
                                <div className="col">
                                    <span>Downpayment - {this.props.customerInfo ? this.props.customerInfo.payment : ''}</span>
                                    <input type="text" className="form-control" ref="payment"/>
                                </div>


                            </div>

                            <br/>

                            <div className="row">
                                <div className="col">
                                    <span>Remarks - {this.props.customerInfo ? this.props.customerInfo.remarks : ''}</span>
                                    <input type="text" className="form-control" ref="remarks"/>
                                </div>
                            </div>


                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" onClick={this.closeModel}>Cancel
                            </button>
                            <a data-dismiss="modal" ref="cancel"/>
                            <button type="button" className="btn btn-primary" onClick={this.editShCustomer}>Save
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


const mapStateToProps = state => ({
    // app: state.feathersClient.app,
    mainapp: state.feathersClient.mainapp,
    category: state.category,
    payment: state.customers.customerPayments,
    userInfoEdit: state.users.userInfoEdit,
    paymentInfoEdit: state.customers.paymentInfoEdit,
    position: state.category.positionsSelect,
    customerInfo: state.customers.customerInfo,
    // customerInfo: state.customers.setNewCustomerData,
    brandsSelect: state.category.brandsSelect,
    paymentTableData: state.customers.paymentTableData,
    setCustomerInfo: state.customers.setCustomerInfo,
    customer: state.customers,
    productSelect: state.product.productSelect,
    branchSelect: state.category.branchesSelect,
    models: state.category.modelsSelect,
    add_cust_model: state.category.add_cust_model,
    type: state.customers.type,
    userData: state.login.userData,
    session_id: state.login.userData._id,
    addCustBranch: state.customers.addCustBranch,
    areaList: state.customers.areaList,
    customers: state.customers.customersTerms,

});

function mapDispatchToProps(dispatch) {
    return {actions: bindActionCreators(ActionCreators, dispatch)}
}

export default connect(mapStateToProps, mapDispatchToProps)(edit_customer_modal);
