import React, { Component } from 'react';

// react-router
import { Link } from 'react-router-dom';

//redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// actions
import { ActionCreators } from '../../../actions';

//toastr
import toastr from 'toastr';

//select tag
import Select from 'react-select';

import moment from 'moment';

//main NavBar
import MainNav from '../../../components/global_nav';
import MainSideBar from '../../../components/global_sidebar';

import default_avatar from '../../../assets/icons/default_avatar.jpg';

//sub side bar
import SubSideBar from './sub_sidebar'; 

//custom styles
import '../../../stylesheet/styles.css';

//datatables
import '../../../stylesheet/datatables/datatables.css';
const $ = require('jquery');
$.DataTable = require('datatables.net');

var that;

class add_customer extends Component {

    constructor(props) {
        super(props);
    
        this.state = {
            selectedModel: '',
            selectedBranch: '',
            clickSubmit: false,
            selectedTerms: '',
            submitting: false
        };

    }

    componentWillMount(){
        that = this;
        // if (that.props.type){
        //     // that.props.history.push('/customer')
        // }
    }

    componentDidMount() {
        console.log(this.props)
        $('.hide-view-customer').css('display', 'none', '!important')

        this.props.app.service('products').find()
        .then((data) => {
            // console.log('products ',data)
        })
    }

    handleChangeModel = (selectedOption) => {
        var value = selectedOption;

        console.log(value)

        if(value){
            this.setState({
                selectedModel: value
            });
        }else{
            this.setState({
                selectedModel: ''
            });
        }

        if (value){
            this.props.actions.getModelInAddCust(value)
            .then((data) => {
                console.log('setSelectedBranchInAddCust ',data[0].branches)
                if (data){
                    that.props.actions.setSelectedBranchInAddCust(data[0].branches);
                }else{
                    that.props.actions.setSelectedBranchInAddCust('');
                }
            });
        }else{
            that.props.actions.setSelectedBranchInAddCust('');
        }

    }

    handleChangeBranch = (selectedOption) => {
        var value = selectedOption === null ? '' : selectedOption;
        this.setState({
            selectedBranch: value
        });
    }

    branchOption = () => {
        var array = this.props.branchSelect,
            newArray = []
        array.map((obj) => {
            if(obj.value !== 'all'){
                newArray.push(obj)
            }
        })
        return newArray
    }

    handleselectedTerms= (selectedOption) => {
        var value = selectedOption === null ? '' : selectedOption;
        this.setState({
            selectedTerms: value
        });
    }

    validateForm = (formData) => {
        var a = formData;

        console.log('form ', a)

        if(formData.type === 1){
            if(a.name != '' &&
                a.unit != '' && 
                // a.branch != '' && 
                a.mobile != '' && 
                a.tin != '' && 
                a.address.present != '' &&
                a.address.length_of_stay != '' &&
                a.age != '' &&
                a.employer.present != '' &&
                a.months != '' &&
                a.terms.down_payment != '' &&
                a.terms.monthly_payment != ''
                ){
                return true;
            }

            return false;
        }else{

            if(a.name != '' &&
                a.unit != '' && 
                a.branch != '' && 
                a.mobile != '' && 
                a.tin != '' && 
                a.address.present != '' &&
                a.age != '' &&
                a.payment != ''){
                return true;
            }

            return false;
        }
    }

    handleAddCustomer = (e) => {
        e.preventDefault();

        this.setState({
            clickSubmit: true
        });

        if (this.state.submitting){
            return;
        }

        var form = this.refs.customerForm,
            that = this,
            formData = {
                name: (this.refs.name.value).trim(),
                unit: this.state.selectedModel != '' ? this.state.selectedModel.value : '',
                unitPrice: this.state.selectedModel != '' ? this.state.selectedModel.price : 0,
                unitName: this.state.selectedModel != '' ? this.state.selectedModel.modelName : '',
                branchId: this.props.addCustBranch ? this.props.addCustBranch[0].value : '',
                branch: this.props.addCustBranch ? this.props.addCustBranch[0].value : '',
                mobile: (this.refs.mobile.value).trim(),
                tin: (this.refs.tin.value).trim(),
                address: {
                    present: (this.refs.address.value).trim(),
                    length_of_stay: (this.refs.lengthOfStay.value).trim()
                },
                birthplace: (this.refs.birthPlace.value).trim(),
                age: (this.refs.age.value).trim(),
                employer: {
                    present: this.props.type === 1 ? (this.refs.employerName.value).trim() : '',
                    telephone_number: this.props.type === 1 ? (this.refs.employerTelNo.value).trim() : '',
                    address: this.props.type === 1 ? (this.refs.employerAddress.value).trim() : '',
                    position: this.props.type === 1 ? (this.refs.position.value).trim() : ''
                },
                terms: {
                  months: this.props.type === 1 ? (this.state.selectedTerms != '' ? this.state.selectedTerms.value : '') : '', 
                  down_payment: this.props.type === 1 ? (this.refs.down_payment.value).trim() : '',
                  monthly_payment: this.props.type === 1 ? (this.refs.monthly_payment.value).trim() : '' 
                },
                payment: this.props.type === 0 ? (this.refs.payment.value).trim() : null,
                type: this.props.type,
                date_added_custom: moment().format('MM/DD/YYYY')
            };

        if (form.checkValidity() === false ) {
            e.stopPropagation();
        }
        form.classList.add('was-validated');
        var validateForm = this.validateForm(formData);
        console.log('validate', validateForm);

        if(!validateForm){
           toastr.error('Please fill in required fields');
           return false;
        }

        this.setState({submitting: true})

        this.props.actions.addCustomer(formData).then((data) => {
           if(data === 'BadRequest'){
                toastr.error('Please fill in required fields.');
                that.setState({submitting: false})
            }else if(data === 'success'){
                toastr.success('Customer successfully added.');
                setTimeout(() => {
                    that.props.history.goBack();
                }, 2000)
            }else{
                toastr.error('Network error. Please try again.');
                that.setState({submitting: false})
            }
        });
    }

    customerPage = () => {
        this.props.history.push('/customer')
    }

  	render() {
        return (
            <div className="">
                {/* main NavBar component*/}
                <MainNav historyProp={this.props.history} usersProp={this.props.userData} />
                
                <div className="container-fluid">
                    <div className="row full-width">
                        <div className="sidebar">
                            <div className="sidebar-content">
                                <MainSideBar page="customer"/>
                            </div>
                        </div>
                        <SubSideBar history={this.props.history} />
                        <div className="sub-main-content">
                            <div className="container-fluid application-container">
                                <a href="javascript:;"
                                   onClick={() => this.props.history.push('/customer')} className="text-gray no-textdecoration">
                                    <span className="fa fa-chevron-left" /> Customer / New Customer / {this.props.type === 1 ? 'Installment' : 'Cash'}
                                </a>
                                <br/>
                                <br/>
                                <div className="">
                                    <div className="background-white p-5">
                                        <form onSubmit={this.handleAddCustomer.bind(this)} ref="customerForm" id="needs-validation" noValidate>
                                            <div className="container-fluid">
                                                <div className="row d-flex flex-row align-items-center">
                                                    <div className="col-2 d-flex flex-column justify-content-center ">
                                                        <img src={default_avatar} className="avatar" alt="logo"/>
                                                        {
                                                        // <input type="file" id="uploadImage" className="d-none" />
                                                        // <label htmlFor="uploadImage" className="text-center">Upload Image</label>
                                                        }
                                                    </div>
                                                    <div className="col-10 align-items-center">
                                                        <div className="row">
                                                            <div className="col-md-9">
                                                                <h4>Name <b className="font-red">*</b></h4>
                                                                <input type="text" className="form-control" ref="name" required/>
                                                                <div className="invalid-feedback">
                                                                    Please provide a customer name.
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                {/*
                                                                    <div className="row">
                                                                        <div className="col">
                                                                            <button className="btn col-md-12 border-radius-50">Save</button>
                                                                        </div>
                                                                        <div className="col">
                                                                            <button className="btn col-md-12 border-radius-50">Cancel</button>
                                                                        </div>
                                                                    </div>
                                                                */}
                                                            </div>
                                                        </div>
                                                        <br/>
                                                        <div className="row">
                                                            <div className="col">
                                                                <span>Unit Applied for <b className="font-red">*</b></span>
                                                                <Select
                                                                    className={this.state.selectedModel === '' && this.state.clickSubmit ? "error-select" : null}
                                                                    name="select-model"
                                                                    value={this.state.selectedModel.value}
                                                                    onChange={this.handleChangeModel}
                                                                    options={this.props.productSelect}
                                                                    clearableValue={true}
                                                                    placeholder="Select Motorycle"
                                                                />
                                                            </div>
                                                            <div className="col">
                                                                <span>Branch <b className="font-red">*</b></span>
                                                                <Select
                                                                    className={ "sad" /*this.state.selectedBranch === '' && this.state.clickSubmit ? "error-select" : null*/}
                                                                    name="select-model"
                                                                    value={this.props.addCustBranch ? this.props.addCustBranch[0].value : ''}
                                                                    onChange={this.handleChangeBranch}
                                                                    options={this.branchOption()}
                                                                    clearableValue={true}
                                                                    placeholder="Branch"
                                                                    disabled={true}
                                                                    ref="branch"
                                                                />
                                                            </div>
                                                            {this.props.type == 1 ? 

                                                                <div className="col">
                                                                    <span>Terms (in months) <b className="font-red">*</b></span>
                                                                    <Select
                                                                        className={this.state.selectedTerms === '' && this.state.clickSubmit ? "error-select" : null}
                                                                        name="select-model"
                                                                        value={this.state.selectedTerms.value}
                                                                        onChange={this.handleselectedTerms}
                                                                        options={[
                                                                                {value: 12, label: '12 months'},
                                                                                {value: 24, label: '24 months'},
                                                                                {value: 36, label: '36 months'},
                                                                            ]}
                                                                        clearableValue={true}
                                                                        placeholder="Select Term"
                                                                    />
                                                                </div>
                                                                : null
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                                <br/>
                                                <div className="personal-info">
                                                    <div className="row">
                                                        <h5><strong>PERSONAL DATA</strong></h5>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col">
                                                            <div className="row">
                                                                <span className="col-md-3">Mobile Number <b className="font-red">*</b></span>
                                                                <div className="col-md-9">
                                                                    <input type="number" className="form-control" ref="mobile" required/>
                                                                    <div className="invalid-feedback">
                                                                        Please provide a mobile/landline number.
                                                                    </div>
                                                                </div>
                                                                
                                                            </div>
                                                        </div>
                                                         <div className="col">
                                                            <div className="row">
                                                                <span className="col-md-3">TIN <b className="font-red">*</b></span>
                                                                <div className="col-md-9">
                                                                    <input type="text" className="form-control" ref="tin" required/>
                                                                    <div className="invalid-feedback">
                                                                        Please provide a valid TIN.
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {/*
                                                        <div className="col">
                                                            <div className="row">
                                                                <span className="col-md-3">Cedula No: </span>
                                                                <input type="text" className="form-control col-md-9" />
                                                            </div>
                                                        </div>
                                                        */}
                                                    </div>
                                                    {/*
                                                    <div className="row">
                                                        <div className="col">
                                                            <div className="row">
                                                                <span className="col-md-3">Date and Place of Issue: </span>
                                                                <input type="text" className="form-control col-md-9" />
                                                            </div>
                                                        </div>
                                                        <div className="col">
                                                            <div className="row">
                                                                <span className="col-md-3">TIN: </span>
                                                                <input type="text" className="form-control col-md-9" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    */}
                                                    <div className="row">
                                                        <div className="col">
                                                             <div className="row">
                                                                <span className="col-md-3">Address <b className="font-red">*</b></span>
                                                                <div className="col-md-9">
                                                                    <input type="text" className="form-control" ref="address" required/>
                                                                    <div className="invalid-feedback">
                                                                        Please provide a valid present address.
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col">
                                                            <div className="row">
                                                                <span className="col-md-3">Length of Stays: </span>
                                                                <div className="col-md-9">
                                                                    <input type="number" className="form-control" ref="lengthOfStay" required={this.props.type === 1 ? true : false}/>
                                                                    <div className="invalid-feedback">
                                                                        Please provide a number length of stay.
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/*
                                                    <div className="row">
                                                        <div className="col">
                                                             <div className="row">
                                                                <span className="col-md-3">Previous Address: </span>
                                                                <input type="text" className="form-control col-md-9" />
                                                            </div>
                                                        </div>
                                                        <div className="col">
                                                            <div className="row">
                                                                <span className="col-md-3">Length of Stays: </span>
                                                                <input type="text" className="form-control col-md-9" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    */}
                                                    <div className="row">
                                                        <div className="col">
                                                            <div className="row">
                                                                <span className="col-md-3">Birthplace: </span>
                                                                <div className="col-md-9">
                                                                    <input type="text" className="form-control" ref="birthPlace"/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col">
                                                            <div className="row">
                                                                <span className="col-md-3">Age <b className="font-red">*</b></span>
                                                                <div className="col-md-9">
                                                                    <input type="text" className="form-control" ref="age" required/>
                                                                    <div className="invalid-feedback">
                                                                        Please provide a valid age.
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/*
                                                    <div className="row">
                                                        <div className="col">
                                                            <div className="row">
                                                                <span className="col-md-3">Name of spouse: </span>
                                                                <input type="text" className="form-control col-md-9" />
                                                            </div>
                                                        </div>
                                                        <div className="col">
                                                            <div className="row">
                                                                <span className="col-md-3">List of properties owned: </span>
                                                                <input type="text" className="form-control col-md-9" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <table className="table table-striped">
                                                            <thead>
                                                                <tr>
                                                                    <th>Name of children studying</th>
                                                                    <th>Level(Grade/HS/College)</th>
                                                                    <th>School name/area</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td>
                                                                        <input type="text" className="form-control" />
                                                                    </td>
                                                                    <td>
                                                                        <input type="text" className="form-control" />
                                                                    </td>
                                                                    <td>
                                                                        <input type="text" className="form-control" />
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    */}
                                                    
                                                    {this.props.type === 1 ? 
                                                        <div>
                                                        <div className="row">
                                                            <h5><strong>PERSONAL DATA SOURCE OF INCOME</strong></h5>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col">
                                                                <div className="row">
                                                                    <span className="col-md-3">Present Employer: </span>
                                                                    <input type="text" className="form-control col-md-9" ref="employerName" required={this.props.type === 1 ? true : false} />
                                                                </div>
                                                            </div>
                                                            <div className="col">
                                                                <div className="row">
                                                                    <span className="col-md-3">Employer Tel No.: </span>
                                                                    <input type="text" className="form-control col-md-9" ref="employerTelNo"/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col">
                                                                <div className="row">
                                                                    <span className="col-md-3">Employer Address: </span>
                                                                    <input type="text" className="form-control col-md-9" ref="employerAddress"/>
                                                                </div>
                                                            </div>
                                                            <div className="col">
                                                                <div className="row">
                                                                    <span className="col-md-3">Position: </span>
                                                                    <input type="text" className="form-control col-md-9" ref="position"/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col">
                                                                <div className="row">
                                                                    <span className="col-md-3">Salary: </span>
                                                                    <input type="text" className="form-control col-md-9" />
                                                                </div>
                                                            </div>
                                                            <div className="col">
                                                                <div className="row">
                                                                   
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col">
                                                                <div className="row">
                                                                    <span className="col-md-3">Customer nature of Business: </span>
                                                                    <input type="text" className="form-control col-md-9" />
                                                                </div>
                                                            </div>
                                                            <div className="col">
                                                                <div className="row">
                                                                    <span className="col-md-3">Address: </span>
                                                                    <input type="text" className="form-control col-md-9" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <h5><strong>PAYMENT</strong></h5>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col">
                                                                <div className="row">
                                                                    <span className="col-md-3">Down Payment <b className="font-red">*</b></span>
                                                                   <div className="col-md-9">
                                                                        <input type="number" className="form-control" ref="down_payment" required/>
                                                                        <div className="invalid-feedback">
                                                                            Please provide a down payment.
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col">
                                                                <div className="row">
                                                                    <span className="col-md-3">Monthly Payment <b className="font-red">*</b></span>
                                                                    <div className="col-md-9">
                                                                        <input type="number" className="form-control" ref="monthly_payment" required/>
                                                                        <div className="invalid-feedback">
                                                                            Please provide a monthly payment.
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div> 
                                                        </div>
                                                        : 
                                                        <div className="row">
                                                            <div className="col">
                                                                <div className="row">
                                                                    <span className="col-md-3">Payment <b className="font-red">*</b></span>
                                                                    <div className="col-md-9">
                                                                        <input type="number" className="form-control col-md-9" ref="payment" required/>
                                                                        <div className="invalid-feedback">
                                                                            Please provide amount of payment.
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col">
                                                            </div>
                                                        </div> 
                                                    }
                                                    {/*
                                                    <div className="row">
                                                        <div className="col">
                                                            <div className="row">
                                                                <span className="col-md-3">Salary: </span>
                                                                <input type="text" className="form-control col-md-9" />
                                                            </div>
                                                        </div>
                                                        <div className="col">
                                                            <div className="row">
                                                                <span className="col-md-3">Spouse Employer: </span>
                                                                <input type="text" className="form-control col-md-9" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col">
                                                            <div className="row">
                                                                <span className="col-md-3">Customer nature of Business: </span>
                                                                <input type="text" className="form-control col-md-9" />
                                                            </div>
                                                        </div>
                                                        <div className="col">
                                                            <div className="row">
                                                                <span className="col-md-3">Address: </span>
                                                                <input type="text" className="form-control col-md-9" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <span>CREDIT ACCOUNT WITH OTHER COMPANIES</span>
                                                        <table className="table table-striped">
                                                            <thead>
                                                                <tr>
                                                                    <th>Company Name</th>
                                                                    <th>Item Bought</th>
                                                                    <th>Present Balance</th>
                                                                    <th>When fully paid</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td>Sample Text</td>
                                                                    <td>Sample Text</td>
                                                                    <td>Sample Text</td>
                                                                    <td>Sample Text</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    <div className="row">
                                                        <span className="col-md-3">Appliances/Gadgets/etc. owned: </span>
                                                        <input type="text" className="form-control col-md-9" />
                                                    </div>
                                                    */}
                                                </div>
                                            </div>
                                            <div className="row float-right">
                                                <div className="col">
                                                     <button className="btn btn-primary" type="submit"><span className="fa fa-save"/> SAVE</button>
                                                </div>
                                                <div className="col">
                                                    <button className="btn btn-danger" type="button" onClick={() => this.customerPage()}>
                                                        <span className="fa fa-remove"/> CANCEL
                                                    </button>
                                                </div>
                                            </div>
                                        </form>    
                                    </div>
                                </div>                            
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


const mapStateToProps = state => ({
    app: state.feathersClient.app,
    customer: state.customers,
    productSelect: state.product.productSelect,
    branchSelect: state.category.branchesSelect,
    type: state.customers.type,
    userData: state.login.userData,
    addCustBranch: state.customers.addCustBranch,
});

function mapDispatchToProps(dispatch) {
   return { actions: bindActionCreators(ActionCreators, dispatch) }
}

export default connect(mapStateToProps, mapDispatchToProps)(add_customer);
