import React, { Component } from 'react';

//redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// actions
import { ActionCreators } from '../../../actions';

//main NavBar
import MainNav from '../../../components/global_nav';
import MainSideBar from '../../../components/global_sidebar';

//custom sidebar
import SubSideBar from './sub_sidebar';

//select tag
import Select from 'react-select';

//custom styles
import '../../../stylesheet/styles.css';

//datatables
import '../../../stylesheet/datatables/datatables.css';
const $ = require('jquery');
$.DataTable = require('datatables.net');

var that;

class outgoing_secondhand extends Component {

    constructor(props) {
        super(props);
    
        this.state = {
            sold: [],
            productsToTransfer: [],
            selectedBrand: '',
            selectedOption: '',
            selectedBranch: '',
            filter_color: null,
            filter_chassis: null,
            filter_model: '',
            selectChanged: false,
            filter_branch: null,
            selectBranchChanged: false,
            outgoing_secondhand: [],
            product_count: 0,
        };
    }

    componentWillMount(){
        that = this;
        this.props.app.service('branches').find({
          query: {
            branch_name: 'MAIN'
          }
        })
        .then((d) => {
          var v = d.data[0]._id;

          that.setState({
            mainId: v 
          })

        this.props.actions.outgoingProducts(v).then(data => {
            that.props.actions.setOutgoingProducts(data);
            // that.updateData(data);
        });

        })
    }

    componentDidMount() {
       
        var that = this,
        productTable =  $('.tbl-outgoing').DataTable({
            data: this.state.outgoing_secondhand,
            columns: [
                { "title": "ID" },
                { "title": "MODEL NAME" },
                { "title": "BRAND NAME" },
                { "title": "COLOR" },
                { "title": "ENGINE NO" },
                { "title": "BRANCH" },
                { "title": "DATE TRANSFERRED" },
            ],
             columnDefs: [
                {
                  targets: [0],
                  visible: false
                }
            ],
            "sDom": '<"bottom"<t>ip><"clear">'
        });

        $('.search').keyup(function(){
            productTable.search($(this).val()).draw() ;
        });
    
    }


    updateData = data => {
        this.setState({
            outgoing_secondhand: data,
            product_count: data.length
        });

        const table = $('.tbl-outgoing').DataTable();
        table.clear();
        table.rows.add(data);
        table.draw();

    }

    handleChange = (selectedOption) => {
        var value = selectedOption;

        if (value != null){
            this.setState({
                selectedOption: value,
                filter_model: value,
                selectChanged: true
            });
        }else{
            this.setState({
                selectedOption: '',
                filter_model: '',
                selectChanged: false
            })
        }
    }

    handleChangeBrand = (selectedOption) => {

        var value = selectedOption;

        if (value != null){
            this.setState({
                selectedBrand: value,
                selectedBrandChanged: true
            });
            var model = that.state.selectedOption.value,
                brand = value.value === 'all' ? null : value.value;

            that.props.actions.findChassisUsingBrand(brand)
            .then((d) => {
                var models = d.data,
                    modelsObj = [];

                models.map((v) => {
                    modelsObj.push({
                        label: v.model_name,
                        value: v._id
                    })
                })

                that.setState({
                    selectedOption: '',
                    filter_model: '',
                    modelsObj: modelsObj,
                })
            })
        }else{
            that.setState({
                selectedBrand: '',
                selectedBrandChanged: false,
                modelsObj: [],
                selectedOption: '',
            })
        }
    }

    handleChangeBranch = (selectedOption) => {
        var value = selectedOption;

        if (value === null){
            this.setState({
                selectedBranch: '',
                filter_branch: value,
                selectBranchChanged: false
            });
        }else{
            this.setState({
                selectedBranch: value,
                filter_branch: value,
                selectBranchChanged: true
            });
        }
    }

  filterOutgoingProducts = () => {
    var branch = that.state.mainId ? that.state.mainId : null,
        brand = that.state.selectedBrand ? that.state.selectedBrand.value : null,
        model = that.state.selectedOption ? that.state.selectedOption.value : null,
        color = that.state.filter_color ? (that.state.filter_color).trim() : that.state.filter_color,
        chassis_number = that.state.filter_chassis ? (that.state.filter_chassis).trim() : that.state.filter_chassis;

    if (!brand && !model && !color && !chassis_number){
        that.refs.filter_color.value = null;
        that.refs.filter_chassis.value = null;
      return;
    }else{
      var filters = {
        brand,
        model,
        color,
        chassis_number,
        branch,
      };

      var query = {};

      if (brand){
        if (brand !== 'all'){
          query.brand = brand
        }
      }
      if (model){
        if (model !== 'all'){
          query.model = model
        }
      }
      if (color){
        if (color.toLowerCase() !== 'all'){
          query.color = color.toUpperCase()
        }
      }
      if (chassis_number){
          query.engine_number = chassis_number
      }

      query.branch = branch;
      query.transferred = {
        $in: [1,2]
      };
      query.type = 0;
      query.brandNew = 0;

      console.log(query)

      that.props.actions.outgoingProductsFilter(query)
      .then((data) => {
        console.log(data)
        that.updateData(data);
      })
    }

  }

  branchOption = () => {
    var array = this.props.branch,
      newArray = [];

    array.map((obj) => {
      if(obj.value !== 'all' && obj.label !== 'MAIN'){
        newArray.push(obj)
      }
    })
    return newArray
  }

  	render() {
    	return (
     	 	<div className="">
     	 		{/* main NavBar component*/}
     	 		<MainNav historyProp={this.props.history} usersProp={this.props.userData} />
     	 		<div className="container-fluid">
     	 			<div className="row full-width">
	     	 			<div className="sidebar">
	     	 				<div className="sidebar-content">
	     	 					<MainSideBar page="inventory"/>
	     	 				</div>
	     	 			</div>
              <SubSideBar history={this.props.history} allProps={this.props} userPermission={this.props.userPermission} pageName="second_outgoing"/>
	     	 			<div className="sub-inv-main-content">
                <div className="container-fluid">
                    <div className="row margin-20 padding-20">
                        <div className="col-md-12">
                          <h4>Outgoing Units</h4>
                          <br />
                        </div>
                        <div className="col-md-12">
                            <div className="form-inline">
                                <div className="col-md-12">
                                ADVANCED FILTER 
                                </div>
                                <div className="col-md-12">
                                    <br />
                                </div>
                                <div className="col">
                                    <Select
                                        name="selectBrand"
                                        value={this.state.selectedBrand.value}
                                        onChange={this.handleChangeBrand}
                                        options={this.props.brandsSelect}
                                        clearableValue={true}
                                        placeholder="Select Brand"
                                    />
                                </div>
                                <div className="col">
                                  <Select
                                      name="select-model"
                                      value={this.state.selectedOption.value}
                                      onChange={this.handleChange}
                                      // options={this.props.models}
                                      options={this.state.modelsObj}
                                      clearableValue={true}
                                      placeholder="Select Model"
                                    />
                                </div>
                                <div className="col">
                                    <input
                                        className="form-control full-width"
                                        placeholder="Enter Color"
                                        onChange={(e) => this.setState({filter_color: e.target.value })}
                                        ref="filter_color"
                                    />
                                </div>
                                <div className="col">
                                    <input
                                        className="form-control full-width"
                                        placeholder="Enter Engine Number"
                                        onChange={(e) => this.setState({filter_chassis: e.target.value })}
                                        ref="filter_chassis"
                                    />
                                </div>
                                <div className="col">
                                    <button className="btn btn-block btn-primary" onClick={() => this.filterOutgoingProducts() }>Search</button>
                                </div>
                            </div>
                        </div>
                    </div>
                  <div className="row margin-20 padding-20">
                      <div className="col-md-12">
                          <span>Result : {this.state.product_count}</span>
                      </div>
                      <div className="col-md-12">
                          <br />
                      </div>
                      <div className="col-md-12 background-white">
                          <table className="table tbl-outgoing tbl-mobile">
                          </table>
                      </div>
                      <div className="col-md-12">
                        <br />
                        <br />
                      </div>
                  </div>
                </div>
	     	 			</div>
     	 			</div>
     	 		</div>
     	 	</div>
    	);
  	}
}

const mapStateToProps = state => ({
  app: state.feathersClient.app,
  // product: state.product.productsToTransfer,
  product: state.product.outgoingProducts,
  userData: state.login.userData,
  userPermission: state.login.userPermission,
  models: state.category.modelsSelect,
  brandsSelect: state.category.brandsSelect,
  branch: state.category.branchesSelect,
});

function mapDispatchToProps(dispatch) {
   return { actions: bindActionCreators(ActionCreators, dispatch) }
}

export default connect(mapStateToProps, mapDispatchToProps)(outgoing_secondhand);
