import React, {Component} from 'react';
import app from '../../../../helpers/feathers';
import SyncDataModal from '../../../../components/sync_data_modal';
// react-router
import {Link} from 'react-router-dom';

//redux
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
// actions
import {ActionCreators} from '../../../../actions';

//main NavBar
import MainNav from '../../../../components/global_nav';
// import MainSideBar from '../../../components/global_sidebar';

import MainSideBar from '../../../../components/acc_sidebar';
//sub side bar
import SubSideBar from '../sub_sidebar';


//custom styles
import '../../../../stylesheet/styles.css';

//datatables
import '../../../../stylesheet/datatables/datatables.css';


import EditCustomerModal from './edit_customer';

const $ = require('jquery');
$.DataTable = require('datatables.net');

let that;

class OldRecordsCustomerInstallmentSh extends Component {


    constructor(props) {
        super(props);

        this.state = {
            data: [],
            customerName: "",
            accountNumber: "",
            searching: false,
            totalCustomer: 0
        };
    }

    componentWillMount() {
        that = this;
    }

    componentDidMount() {
        const applicationTbl = $('.tbl-customer-sh').DataTable({
            // data: this.props.customers,
            "columnDefs": [
                {
                    "visible": false, "targets": 0
                },
                {
                    targets: 1,
                    width: '20%'

                },
            ],
            "order": [[ 6, "desc" ]],
            columns: [
                {title: "details"},
                {title: "NAME"},
                {title: "ACCOUNT NO."},
                {title: "ADDRESS"},
                {title: "CONTACT NO."},
                {title: "BALANCE"},
                {title: "DATE CREATED"},
                {title: "ACTION"}
            ],
            "sDom": '<"bottom"<t>ip><"clear">'
        });


        $('.search').keyup(function () {
            applicationTbl.search($(this).val()).draw();
        });

        $('.tbl-customer-sh').on('click', 'button.btn-new-view', function () {
            const data = applicationTbl.row($(this).parents('tr')).data();
            that.props.actions.setCustomerInfo(data[0]);
            that.props.history.push('/view_customer_old_records/');
        });
        //
        //
        $('.tbl-customer-sh').on('click', 'button.btn-new-edit', function () {
            const data = applicationTbl.row($(this).parents('tr')).data();
            that.props.actions.setCustomerInfo(data[0]);
            $('#edit_customer').modal('show');
        });

        $('#edit_customer').on('hidden.bs.modal', function () {
            that.getLatestCustomers();
        });


        // const custService = app.service('customers');
        // custService.on('created', () => {
        //     that.getAllCustomers();
        // });
        // custService.on('patched', () => {
        //     that.getAllCustomers();
        // })

        that.getLatestCustomers();
    }


    advancedSearch = () => {
        const that = this,
            customerName = this.state.customerName,
            accountNumber = this.state.accountNumber,
            query = {};

        // var permission = this.props.userPermission;

        if (customerName === '' && accountNumber === '') {
            that.getLatestCustomers()
            that.setState({totalCustomer: 0});
            return;
        }

        // if (permission.inventory === 0) {
        //     return
        // }
        if (customerName) {
            query.name = {
                $regex: customerName.toUpperCase()
            }
        }

        if (accountNumber) {
            query.account_number = {
                $regex: accountNumber.toUpperCase()
            }
        }

        that.getAllCustomers(customerName.toUpperCase(), accountNumber.toUpperCase());

    }

    fastSearch = () => {

        let {customerName, accountNumber} = this.state;

        if (customerName.length > 0 || accountNumber.length > 0) {
            that.advancedSearch()
        }
    }


    // getAllCustomers = (query) => {
    //     this.props.actions.getTermsCustomers(query).then((data) => {

    //         if(data){
    //             that.props.actions.setTermsCustomers(data);
    //             that.updateData(data);
    //             var totalNum = data.length ;
    //             this.setState({totalCustomer:totalNum})
    //         }
    //         else{
    //             that.updateData([]);
    //         }
    //     });
    // }

    getAllCustomers = (customerName, accountNumber) => {
        console.log(customerName)
        that.props.actions.getOldRecInstallmentSh(customerName, accountNumber).then((data) => {
            if (data) {
                const d = data.data;
                that.props.actions.setTermsCustomers(d);
                that.updateData(d);
                const totalNum = d.length;
                this.setState({totalCustomer: totalNum});
            }else{
                that.updateData([]);
                this.setState({totalCustomer: 0})
            }
        })
    };

    getLatestCustomers = () => {
        that.props.actions.getOldRecInstallmentSh(false,false,true).then((data) => {
            if (data) {
                const d = data.data;
                that.updateData(d);
                const totalNum = d.length;
                this.setState({totalCustomer: totalNum});
            }
        })
    };

    updateData = (data) => {
        this.setState({
            data: data
        });
        const table = $('.tbl-customer-sh').DataTable();
        table.clear();
        table.rows.add(data);
        table.draw();
    };

    permissionAccounting = () => {
        const permission = this.props.userPermission;
        return permission.accounting === 0 ? "not-visible" : "";
        // if (permission.accounting === 0){
        //     return "not-visible"
        // }else{
        //     return "";
        // }
    };

    addRecord = () => {
        const permission = this.props.userPermission;

        if (permission.inventory === 2) {
            this.props.history.push('/add_customer_old_records_sh');
        }
    }

    render() {
        return (
            <div className="" id="main">
                {/* main NavBar component*/}
                <MainNav historyProp={this.props.history} usersProp={this.props.userData}/>
                <SyncDataModal/>
                <EditCustomerModal/>
                <div className="container-fluid">
                    <div className="row full-width">
                        <div className="sidebar">
                            <div className="sidebar-content">
                                <MainSideBar page="old_records"/>
                            </div>
                        </div>
                        <SubSideBar
                            history={this.props.history}
                            userPermission={this.props.userPermission}
                            page="installment_sh"
                        />
                        <div className="sub-inv-main-content">
                            <div className="application-container">
                                <div className="">
                                    <div className="row my-inventory">
                                        <div className="col-md-12">
                                            <div className="col-md-12">
                                                <div className="row col-md-12">
                                                    <div className="">
                                                        <h4 className="subheader-title">Old Records Installment (Secondhand)</h4>
                                                    </div>
                                                    <div className="">
                                                        <button className="btn btn-second-primary add-new-btn"
                                                                onClick={() => this.addRecord()}>
                                                            <span><i className="fa fa-plus"/> Add</span>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-inline">
                                                <div className="col-md-12 subheader-label">
                                                    ADVANCED FILTER
                                                </div>
                                                <div className="col-md-12">
                                                    <br/>
                                                </div>
                                                <div className="container-fluid">
                                                    <div className="row">
                                                        <div className="col">
                                                            <input
                                                                className="form-control full-width"
                                                                placeholder="Enter Full name"
                                                                onChange={(e) => this.setState({customerName: e.target.value})}
                                                                ref="customer_name"
                                                                onKeyUp={(e) => this.advancedSearch()}
                                                            />
                                                        </div>
                                                        <div className="col">
                                                            <input
                                                                className="form-control full-width"
                                                                placeholder="Enter Account No.."
                                                                onChange={(e) => this.setState({accountNumber: e.target.value})}
                                                                ref="account_number"
                                                                onKeyUp={(e) => this.advancedSearch()}
                                                            />
                                                        </div>
                                                        <div className="col">
                                                            <button className="btn full-width btn-second-primary btn-sm"
                                                                    onClick={() => this.advancedSearch()}>Search
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row margin-20">
                                        <div className="full-width">
                                            <table className="table tbl-customer-sh tbl-mobile"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


const mapStateToProps = state => ({
    app: state.feathersClient.app,
    customers: state.customers.customersTerms,
    userData: state.login.userData,
    userPermission: state.login.userPermission,
});

function mapDispatchToProps(dispatch) {
    return {actions: bindActionCreators(ActionCreators, dispatch)}
}

export default connect(mapStateToProps, mapDispatchToProps)(OldRecordsCustomerInstallmentSh);
