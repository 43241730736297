import React, { Component } from 'react';
import toastr from 'toastr';

//redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// actions
import { ActionCreators } from '../../../actions';

const $ = require('jquery');

class add_branch_modal extends Component {

	constructor(props) {
	  	super(props);
	
	  	this.state = {};
	  
	}

	addBranch = () => {
		
		var branchName = this.refs.branchName.value.trim(),
			u_branchName = branchName.toUpperCase(),
		 	that = this;

		if(branchName.length < 1){
			// toastr.error('failed');
			this.refs.branchName.value = '';
			return;
		}

		this.props.actions.addBranch(u_branchName).then((data) => {
			if(data === 'exist'){
				toastr.error(branchName + ' already exists.');
			}else if(data === 'success') {
				that.closeModal()
				toastr.success(branchName + ' has been successfully added');
			}else{
				toastr.error('Failed to add. Please try again.');
			}
		});

	}

	closeModal = () => {
		var that = this;
		that.refs.cancel.click();
		that.refs.branchName.value = '';
	}

	componentDidMount() {
		
	}

  	render() {
    	return (
      		<div className="modal fade" id="add_branch" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" ref="add_branch" data-backdrop="static" data-keyboard="false">

			  	<div className="modal-dialog" role="document">
			    	<div className="modal-content">
			      		<div className="modal-header">
			        		<h5 className="modal-title" id="exampleModalLabel">Add Branch</h5>
			        			<button type="button" className="close" onClick={this.closeModal} aria-label="Close">
						          <span aria-hidden="true">&times;</span>
						        </button>
			      		</div>
				      	<div className="modal-body">
				        	<div className="form-group">
				        		<label>Branch Name</label>
				        		<input type="text" className="form-control" ref="branchName"/>
				        	</div>
				      	</div>
				      	<div className="modal-footer">
				        	<a data-dismiss="modal" ref="cancel"></a>
				        	<button type="button" className="btn btn-secondary" onClick={this.closeModal}>Cancel</button>
				        	<button type="button" className="btn btn-primary" onClick={this.addBranch}>Save</button>
				      	</div>
			    	</div>
			  	</div>
			</div>
    	);
  	}
}


const mapStateToProps = state => ({
   	category: state.category,
   	app: state.feathersClient.app
});

function mapDispatchToProps(dispatch) {
   return { actions: bindActionCreators(ActionCreators, dispatch) }
}

export default connect(mapStateToProps, mapDispatchToProps)(add_branch_modal);