import React, {Component} from 'react';
import toastr from 'toastr';

//redux
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
// actions
import {ActionCreators} from '../../../actions';

import moment from 'moment';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import app from '../../../helpers/feathers';

const $ = require('jquery');

class add_payment_modal extends React.PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            saving: false,
            mainbranch: '',
            date_paid: moment()
        };

    }

    componentWillMount() {
        // app.service('branches').find({
        // 	query: {
        // 		branch_name: 'MAIN'
        // 	}
        // })
        // .then((d) => {
        // 	// console.log('branch_name ', d.data[0]._id)
        // 	that.setState({
        // 		mainbranch: d.data[0]._id
        // 	})
        // })
    }

    closeModalCallback = () => {
        this.refs.supplier_name.value = '';
        this.refs.supplier_receipt.value = '';
        this.refs.amount.value = '';
        this.refs.remarks.value = '';
        this.setState({
            date_paid: moment()
        })
        this.refs.cancel.click();
    }

    addPayment = () => {
        const that = this,
            supplier_name = that.refs.supplier_name.value,
            supplier_receipt = that.refs.supplier_receipt.value,
            amount = that.refs.amount.value,
            date_paid = that.state.date_paid,
            remarks = that.refs.remarks.value;

        const data = {
            supplier_name: supplier_name,
            receipt_number: supplier_receipt,
            amount: amount,
            date_paid: moment(date_paid).format('MM/DD/YYYY'),
            remarks: remarks,
        }

        if (!supplier_name && !supplier_receipt && !amount) {
            toastr.remove();
            toastr.warning('All fields are required');
            return;
        }

        if (isNaN(amount)) {
            toastr.remove();
            toastr.warning('Please enter a valid amount');
            return;
        }

        app.service('payment').create(data)
            .then((d) => {
                toastr.remove();
                toastr.success('Payment has been successfully added');
                this.setState({
                    saving: false
                });
                this.closeModalCallback()
            }).catch((err) => {
            console.log(err)
            toastr.remove();
            toastr.error('Failed to add. Please try again.');
        });
    }

    closeModel = () => {
    }

    componentDidMount() {

    }

    handleDatePaid = (date) => {
        this.setState({
            date_paid: date
        })
    }

    render() {
        return (
            <div className="modal fade" id="add_payment" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel"
                 aria-hidden="true" ref="add_payment" data-backdrop="static">

                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Add Payment</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="form-group">
                                <label>Supplier Name</label>
                                <input type="text" className="form-control" ref="supplier_name"/>
                            </div>
                            <div className="form-group">
                                <label>Supplier Receipt</label>
                                <input type="text" className="form-control" ref="supplier_receipt"/>
                            </div>
                            <div className="form-group">
                                <label>Amount</label>
                                <input type="text" className="form-control" ref="amount"/>
                            </div>
                            <div className="form-group">
                                <label>Date Paid</label>
                                <DatePicker
                                    selected={this.state.date_paid}
                                    onChange={this.handleDatePaid}
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    className="form-control"
                                />
                            </div>
                            <div className="form-group">
                                <label>Remarks</label>
                                <textarea className="form-control" ref="remarks">
			        		</textarea>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary"
                                    onClick={() => this.closeModalCallback()}>Cancel
                            </button>
                            <a data-dismiss="modal" ref="cancel"></a>
                            <button type="button" className="btn btn-primary" onClick={this.addPayment}>Save</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


const mapStateToProps = state => ({
    category: state.category,
});

function mapDispatchToProps(dispatch) {
    return {actions: bindActionCreators(ActionCreators, dispatch)}
}

export default connect(mapStateToProps, mapDispatchToProps)(add_payment_modal);