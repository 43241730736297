import React, { Component } from 'react';
import toastr from 'toastr';

import app from '../../../helpers/feathers'

//redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// actions
import { ActionCreators } from '../../../actions';

const $ = require('jquery');

class clear_data_modal extends Component {

	constructor(props) {
	  	super(props);
	
	  	this.state = {
	  		clearDataGranted: false,
	  		validCode: false,
	  		clickIsEnabled: true,
	  	};

	  
	}

	addBranch = () => {
		
		var branchName = this.refs.branchName.value,
		 	that = this;

		if(branchName.length < 1){
			toastr.error('failed');

			return false;
		}

		this.props.actions.addBranch(branchName).then((data) => {
			if(data === 'exist'){
				toastr.error(branchName + ' already exists.');
			}else if(data === 'success') {
				that.refs.cancel.click();
				toastr.success(branchName + ' has been successfully added');
			}else{
				toastr.error('Failed to add. Please try again.');
			}
		});

	}

	closeModal = () => {

		if (!this.state.clickIsEnabled){
			return;
		}

		this.refs.cancel.click();
		this.refs.code.value = '';
		this.setState({
			clearDataGranted: false
		})
	}

	componentDidMount() {
		
	}

	successMessage = () => {
		this.setState({
			clickIsEnabled: true
		})
		this.closeModal();
		toastr.success('Data has been successfully cleared.')
	}

	errorOccured = () => {
		this.closeModal();
		toastr.error('An error occured')
	}

	clearData = () => {
		var that = this;

		that.setState({
			clickIsEnabled: false
		})

		toastr.info('Clearing data. Please wait...')

		app.service('products').find()
		.then((data) => {
			var d = data.data

			d.map((v) => {
				app.service('products').remove(v._id)
			})
		})

		app.service('payments').find()
		.then((data) => {
			var d = data.data

			d.map((v) => {
				app.service('payments').remove(v._id)
			})
		})

		app.service('customers').find()
		.then((data) => {
			var d = data.data

			d.map((v) => {
				app.service('customers').remove(v._id)
			})
		})

		app.service('customer-secondhand-unit').find()
		.then((data) => {
			var d = data.data

			d.map((v) => {
				app.service('customer-secondhand-unit').remove(v._id)
			})
		})

		app.service('reports').find()
		.then((data) => {
			var d = data.data

			d.map((v) => {
				app.service('reports').remove(v._id)
			})
		})

		// app.service('brands').find()
		// .then((data) => {
		// 	var d = data.data

		// 	d.map((v) => {
		// 		app.service('brands').remove(v._id)
		// 	})
		// })

		// app.service('motorcycle-models').find()
		// .then((data) => {
		// 	var d = data.data

		// 	d.map((v) => {
		// 		app.service('motorcycle-models').remove(v._id)
		// 	})
		// })

		app.service('secondhand').find()
		.then((data) => {
			var d = data.data

			d.map((v) => {
				app.service('secondhand').remove(v._id)
			})
		})

		app.service('transfers').find()
		.then((data) => {
			var d = data.data

			d.map((v) => {
				app.service('transfers').remove(v._id)
			})
		})

		app.service('ledger').find()
		.then((data) => {
			var d = data.data

			d.map((v) => {
				app.service('ledger').remove(v._id)
			})
		})

		app.service('ledger-secondhand').find()
		.then((data) => {
			var d = data.data

			d.map((v) => {
				app.service('ledger-secondhand').remove(v._id)
			})
		})

		app.service('customer-payments').find()
		.then((data) => {
			var d = data.data

			d.map((v) => {
				app.service('customer-payments').remove(v._id)
			})
		})

		app.service('customer-payments-secondhand').find()
		.then((data) => {
			var d = data.data

			d.map((v) => {
				app.service('customer-payments-secondhand').remove(v._id)
			})
		})

		setTimeout(() => {
			that.successMessage();
		}, 1000 * 10)

	}

	showPasscodeInput = () => {
		var _0xc3c2=["\x65\x76\x65\x72\x73\x75\x72\x33\x31\x30\x31"];var secret_passcode_=_0xc3c2[0]
		var status = this.state.clearDataGranted,
			validCode = this.state.validCode,
			code = this.refs.code.value;

		if (!this.state.clickIsEnabled){
			return;
		}

		if (status){
			if (code.toLowerCase() === secret_passcode_){
				this.clearData();
			}else{
				toastr.error('The code you entered is invalid!');
			}
		}else{
			this.setState({
				clearDataGranted: true
			})
		}
	}

	inputSubmitted = (e) => {
		if (e.key === 'Enter'){
			this.showPasscodeInput();
		}
	}

  	render() {
    	return (
      		<div className="modal fade" id="clear-data" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">

			  	<div className="modal-dialog" role="document">
			    	<div className="modal-content">
			      		<div className="modal-header">
			        		<h5 className="modal-title" id="exampleModalLabel">Clear Data</h5>
			        		{
			        			// <button type="button" className="close" data-dismiss="modal" aria-label="Close">
						        //   <span aria-hidden="true">&times;</span>
						        // </button>
			        		}
			      		</div>
				      	<div className="modal-body">
				        	<div className="form-group">
				        	{
				        		this.state.clearDataGranted ? <p>Enter Passcode</p> : <p>Are you sure you want to clear data?</p>
				        	}
				        	</div>
				        	<div className="form-group">
				        		<input type="password" className={this.state.clearDataGranted ? "form-control" : "form-control not-visible"} ref="code"  onKeyPress={(e) => this.inputSubmitted(e) } />
				        	</div>
				      	</div>
				      	<div className="modal-footer">
				      		<a data-dismiss="modal" ref="cancel"></a>
				      		<button type="button" className="btn btn-secondary" onClick={() => this.closeModal() }>Cancel</button>
				        	<button type="button" className="btn btn-danger" onClick={() =>this.showPasscodeInput() }>{this.state.clearDataGranted ? "Clear Data" : "YES" }</button>
				      	</div>
			    	</div>
			  	</div>
			</div>
    	);
  	}
}


const mapStateToProps = state => ({
   	category: state.category,
   	app: state.feathersClient.app
});

function mapDispatchToProps(dispatch) {
   return { actions: bindActionCreators(ActionCreators, dispatch) }
}

export default connect(mapStateToProps, mapDispatchToProps)(clear_data_modal);