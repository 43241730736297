import React, {Component} from 'react';

//redux
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
// actions
import {ActionCreators} from '../../../actions';

//main NavBar
import MainNav from '../../../components/global_nav';
// import MainSideBar from '../../../components/global_sidebar';
import MainSideBar from "../../../components/acc_sidebar";

//sub side bar
// import SubSideBar from './sub_sidebar';
import SubSideBar from '../accounting/report_sub_sidebar';

import app from '../../../helpers/feathers';

//custom styles
import '../../../stylesheet/styles.css';


//datatables
import '../../../stylesheet/datatables/datatables.css';
import ConfirmationDelete from "../inventory/confirmation_delete";

const $ = require('jquery');
$.DataTable = require('datatables.net');

class SalesReports extends Component {
    constructor(props) {
        super(props);

        this.state = {
            sales_reports: []
        };
    }

    componentWillMount() {
        this.salesReports();
    }

    componentDidMount() {
        const that = this, salesTbl = $('.sales_reports').DataTable({
            columns: [
                {"title": "DATE"},
                {"title": "TITLE"},
                {"title": "CREATED BY"},
                {"title": ""},
                {"title": ""},
                // { "title": "" },
            ],
            data: this.state.sales_reports,
            columnDefs: [
                {
                    targets: [0],
                    width: '20%'
                },
                {
                    targets: [1],
                    width: '40%'
                },
                {
                    targets: [2],
                    width: '30%'
                },
                {
                    targets: [3],
                    width: '15%',
                    orderable: false
                },
                {
                    targets: [4],
                    visible: false,
                }
            ],
            'dom': "<'row'<'col-sm-6'l><'col-sm-6'p>>" +
                "<'row'<'col-sm-12'tr>>" +
                "<'row'<'col-sm-12'i>>",
        });

        $('.search').keyup(function () {
            salesTbl.search($(this).val()).draw();
        });

        // view-report
        $('.sales_reports').on('click', '.view-report', function () {
            const data = salesTbl.row($(this).parents('tr')).data();
            that.props.actions.viewReportData(data[4]);
            that.props.history.push('/view_sold_units');
        });

        // delete-report
        $('.sales_reports').on('click', '.delete_report', function () {
            const data = salesTbl.row($(this).parents('tr')).data();
            const id = data[4];
            const permission = that.props.userPermission;

            if (permission.inventory === 2) {
                that.props.actions.deleteId(id);
                that.props.actions.deleteService('reports');
                that.refs.confirm_delete.click();
            }
        });

        that.createListener();
    }

    createListener = () => {
        const products = app.service('reports');

        products.on('created', products => {
            this.salesReports();
        });

        products.on('removed', products => {
            this.salesReports();
        });
    }

    salesReports = () => {
        this.props.actions.getAllInventoryReports(1)
            .then((data) => {
                this.productList(data);
            });
    }

    productList = data => {
        this.setState({
            sales_reports: data
        });

        const table = $('.sales_reports').DataTable();
        table.clear();
        table.rows.add(data);
        table.draw();
    }

    createReport = () => {
        let {userPermission} = this.props,
            reportPermission = userPermission.report;

        if (reportPermission === 2) {
            this.props.history.push('/report_create_report_sold');
        }
    }

    render() {
        return (
            <div className="">
                {/* main NavBar component*/}
                <MainNav historyProp={this.props.history} usersProp={this.props.userData} systemType="accounting" />
                <ConfirmationDelete/>
                <div className="container-fluid">
                    <div className="row full-width">
                        <div className="sidebar">
                            <div className="sidebar-content">
                                <MainSideBar page="report"/>
                            </div>
                        </div>
                        <SubSideBar history={this.props.history} userPermission={this.props.userPermission} pageTitle="REPORTS_SOLD_UNITS" />
                        <div className="sub-inv-main-content">
                            <div className="container-fluid">
                                <div className="application-container">
                                    <div className="row">
                                        <div className="">
                                            {/* <h5 className="subheader-title">Sold Units Report List</h5> */}
                                                <span
                                                    className="global__header-label">Sold Units Report List</span>
                                                    <button className="btn btn-second-primary add-new-btn"
                                                            onClick={() => this.createReport()}>
                                                        <span> Create</span>
                                                    </button>
                                        </div>
                                        <div className="col-md-3">
                                            {/* <button className="btn btn-second-primary add-new-btn"
                                                    onClick={() => this.createReport()}>
                                                <span> Create</span>
                                            </button> */}
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="justify-content-left content-header">
                                                <input type="search"
                                                       className="search search-class form-control"
                                                       placeholder="&#xF002; Search"/>
                                            </div>
                                        </div>
                                    </div>
                                    <br/>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <a data-toggle="modal" data-target="#confirm_delete" ref="confirm_delete"></a>
                                            <table className="sales_reports table tbl-mobile">
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


const mapStateToProps = state => ({
    app: state.feathersClient.app,
    userData: state.login.userData,
    userPermission: state.login.userPermission,
});

function mapDispatchToProps(dispatch) {
    return {actions: bindActionCreators(ActionCreators, dispatch)}
}

export default connect(mapStateToProps, mapDispatchToProps)(SalesReports);