import React, { Component } from 'react';

//redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// actions
import { ActionCreators } from '../../../actions';

import {
    encodeToString,
    leadingZero,
} from '../../../helpers'

import toastr from 'toastr';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

toastr.options.fadeOut = 2500;

const $ = require('jquery');

class add_ledger_modal extends Component {

	constructor(props) {
	  	super(props);
	  	this.state = {
	  		first_payment_date: moment()
	  	};
	}

	closeModel = () => {
		$('.modal').modal('hide');
	}

	handleFirstPaymentDate = (date) => {
		this.setState({first_payment_date: date})
	}

	createLedger = () => {
		let that = this,
			{ customer } = this.props,
			customer_id = customer._id,
			terms = customer.term,
			down_payment = customer.payment,
			// product_price = customer.product.price,
			promisory_note = that.refs.promisory_note.value;

		let i = 0;

		if(promisory_note.trim() === ''){
            toastr.remove();
            toastr.info('Please enter amount for promisory note');
            return;
		}else{
			if (isNaN((promisory_note).trim())){
	            toastr.remove();
	            toastr.info('Please enter a valid amount for promisory note');
	            return;
	        }else{
	            if((promisory_note).trim() == 0){
	                toastr.remove();
	                toastr.info('Please enter a valid amount for promisory note');
	                return;
	            }else{
	                if(leadingZero((promisory_note).trim())){
	                    toastr.remove();
	                    toastr.info('Please enter a valid amount for promisory note');
	                    return;
	                }
	            }
	        }
		}

		// that.props.actions.emptyLedger();return;
		// that.props.actions.findInLedger().then((result) => {}); return;

		that.props.actions.checkIfExist(customer.account_number)
		.then((result) => {
			if(result.status){
				toastr.error(result.message)
			}else{
				$('#add_ledger .btn-primary').prop('disabled', true);

				// if(product_price === null || product_price === 0 || product_price === ''){
				// 	toastr.error('Unit price not available!'); return;
				// }
				recursive(terms)
			}
		})
		.catch((error) => {
			console.log('error ', error)
			toastr.error(error.message)
		})

		var recursive = function(value) {
			if (value > 0) {
				let start_date = that.state.first_payment_date,
					due_date = moment(start_date).add(i, 'months').format('MM/DD/YYYY'),
					balance = promisory_note - down_payment;

				i += 1;

				let raw_data = {
					customer_id: customer_id,
					account_number: customer.account_number,
					due_date: due_date,
					monthly_amortization: (balance / terms),
					balance: balance,
					promisory_note: promisory_note,
					added_by: that.props.session_id,
				}

				that.props.actions.insertToLedger(raw_data)
				.then((result) => {
					return recursive(value - 1);
				})
				.catch((e) => {
					console.log('error ', e)
				})
			} else {
				that.props.actions.updateLedger(customer_id, 1).then((result) => {
				});
				toastr.success('Ledger successfully created!');
				$('#add_ledger').modal('hide');
			}
		};
	}

  	render() {
    	return (
      		<div className="modal fade bd-example-modal-sm" id="add_ledger" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" ref="add_ledger" data-backdrop="static">
			  	<div className="modal-dialog modal-sm" role="document">
			    	<div className="modal-content">
			      		<div className="modal-header">
			        		<h5 className="modal-title" id="exampleModalLabel">Create Ledger</h5>
			        			<button type="button" className="close" data-dismiss="modal" aria-label="Close">
						          <span aria-hidden="true">&times;</span>
						        </button>
			      		</div>
				      	<div className="modal-body">
					        	<div className="form-group">
					        		<label>Start Date</label>
					        		<br />
					        		<DatePicker
										selected={this.state.first_payment_date}
										onChange={this.handleFirstPaymentDate}
										peekNextMonth
										showMonthDropdown
										showYearDropdown
										dropdownMode="select"
										className="form-control"
									/>
					        	</div>
					        	<div className="form-group">
					        		<label>Promisory Note</label>
					        		<input className="form-control" ref="promisory_note" />
					        	</div>
					        	{
					        		/*
							        	<div className="form-group">
							        		<label>Unit Price</label>
							        		<input className="form-control" ref="selling_price" />
							        	</div>
					        		*/
					        	}
				      	</div>
				      	<div className="modal-footer">
					        	<button type="button" className="btn btn-secondary" data-dismiss="modal" ref="cancel">Cancel</button>
					        	<button type="button" className="btn btn-primary" onClick={this.createLedger}>Create</button>
				      	</div>
			    	</div>
			  	</div>
			</div>
    	);
  	}
}


const mapStateToProps = state => ({
   	category: state.category,
   	app: state.feathersClient.app,
   	customer: state.customers.customerLedgerInfo,
   	session_id: state.login.userData._id
});

function mapDispatchToProps(dispatch) {
   return { actions: bindActionCreators(ActionCreators, dispatch) }
}

export default connect(mapStateToProps, mapDispatchToProps)(add_ledger_modal);