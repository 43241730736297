import React, {
    Component
} from 'react';

//redux
import {
    connect
} from 'react-redux';
import {
    bindActionCreators
} from 'redux';
// actions
import {
    ActionCreators
} from '../../../actions';

//main NavBar
import MainNav from '../../../components/global_nav';
import MainSideBar from '../../../components/global_sidebar';

//sub side bar
import SubSideBar from './sub_sidebar';

//custom styles
import '../../../stylesheet/styles.css'

//datatables
import '../../../stylesheet/datatables/datatables.css';

//daterange
import moment from 'moment';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import './plugins/daterangepicker.css';

import toastr from 'toastr';
const $ = require('jquery');
$.DataTable = require('datatables.net');


var global_this

class recent extends Component {


    constructor(props) {
        super(props);

        this.state = {
            brand: [],
            filter_reporttype: null,
            filter_daterange: null,
            filter_branch: '',
            filter_showby: null,
            filter_brand: null,
            filter_columnby: '',
            filter_columndata: null,
            sample:null,
            saving:false,
            drPickerApplied: false
        };
    }



    componentWillMount() {
        global_this = this
        global_this.findProducts();
    }

    tableScripts = () => {
    }

    findProducts = () => {

        let app = this.props.app;
        
        app.service('products').find({
            query: {
                type: 1
            }
        })
        .then((data) => {
            
            var allproducts = data.data;
            var filteredProducts = {};
            var filteredSpecifidProducts = {};
            var date = data.data;

            for (var x = 0; x < date.length; x++) {
                var createdat = date[x];
                var datecreated = createdat.createdAt;
                var test = datecreated

            }

            for (var x = 0; x < allproducts.length; x++) {
                var localData = allproducts[x];
                var brandName = localData.brands ? localData.brands.brand_name : '';
                var branchName = localData.branches ? localData.branches.branch_name : '';
                var modelName = localData.motorModels ? localData.motorModels.model_name : '';
                var createdAt = (moment(localData.createdAt).format('YYYY-MM-DD'));
                var customdate = "Date";
                var newdata = [];

                if (!filteredProducts[brandName]) {
                    filteredProducts[brandName] = {};
                    filteredSpecifidProducts[brandName] = {};
                    
                    

                    if (!filteredSpecifidProducts[brandName][customdate]) {
                        filteredSpecifidProducts[brandName][customdate] = createdAt;
                    
                    if(!filteredProducts[brandName][branchName]){
                        filteredProducts[brandName][branchName] = 0;
                    
                    
                        if(!filteredSpecifidProducts[brandName][modelName]){
                            
                            
                            filteredSpecifidProducts[brandName][modelName] = {};
                            if(!filteredSpecifidProducts[brandName][modelName][branchName]){
                                filteredSpecifidProducts[brandName][modelName][branchName] = 0;
                            }
                        }
                        filteredSpecifidProducts[brandName][modelName][branchName]++;
                    }
                    }

                    filteredProducts[brandName][branchName]++;
                    
                    
                    
                } else {
                    if (!filteredProducts[brandName][branchName]) {
                        filteredProducts[brandName][branchName] = 0;
                        if (!filteredSpecifidProducts[brandName][modelName]) {
                            filteredSpecifidProducts[brandName][modelName] = {};
                            if (!filteredSpecifidProducts[brandName][modelName][branchName]) {
                                filteredSpecifidProducts[brandName][modelName][branchName] = 0;
                            }
                        } else {
                            if (!filteredSpecifidProducts[brandName][modelName][branchName]) {
                                filteredSpecifidProducts[brandName][modelName][branchName] = 0;
                            }
                        }

                        filteredSpecifidProducts[brandName][modelName][branchName]++;
                    } else {
                        if (!filteredSpecifidProducts[brandName][modelName]) {
                            filteredSpecifidProducts[brandName][modelName] = {};
                            if (!filteredSpecifidProducts[brandName][modelName][branchName]) {
                                filteredSpecifidProducts[brandName][modelName][branchName] = 0;
                            }
                        } else {
                            if (!filteredSpecifidProducts[brandName][modelName][branchName]) {
                                filteredSpecifidProducts[brandName][modelName][branchName] = 0;
                            }
                        }
                        filteredSpecifidProducts[brandName][modelName][branchName]++;
                    }
                    filteredProducts[brandName][branchName]++;
                }
            }

            this.setState({
                allProducts: filteredProducts,
                allSpecifiedProducts: filteredSpecifidProducts
            });

        })
    }

    loadAllData = () => {

        let app = this.props.app;

        app.service('brands').find()
        .then((data) => {
            var brandlist = data.data
            var newdata = [];

            brandlist.forEach(function(x, i) {
                newdata.push({
                    brandname: x.brand_name
                })
            });

            if (newdata.length) {
                var html = '<option value="all">ALL</option>';
                for (var x in newdata) {
                    html += '<option value="' + newdata[x].brandname + '">' + newdata[x].brandname + '</option>';
                }
                
                $('.filter-by-brand').html(html);
                
            }

            this.setState({
                brands: newdata
            });

        })
        
        app.service('branches').find()
        .then((data) => {
            
            const that = this;
            var html = '<option value="all">ALL</option>'; 
            var branchlist = data.data
            var newdata = [];

            branchlist.forEach(function(x, i) {
                newdata.push(x.branch_name)
        
            html += '<option value="' + x.branch_name + '">' + x.branch_name + '</option>';
            $('.filter-by-branch').html(html);
            });
            
            that.setState({
            branchstorage:newdata
            })
             
            var branchdata = that.state.branchstorage;
            var branches = data.data;
            
            var localBrand = [];
            var tableElement = $('.tbl-create-inventory-report');
            var dt = null;
            var localBrandData = {};
            var colInit = 10;
            var allProducts = this.state.allProducts;
            var allSpecifiedProducts = this.state.allSpecifiedProducts;
           
            var allBrand = this.state.brands;

            if (allProducts && allBrand) {

                function genallBrand(data, param = null) {
                    
                    localBrand = [];
                    for (var x in data) {
                        for (var y in data[x]) {
                            var brandName = data[x][y];
                            if ($.inArray(brandName, localBrand) == -1) {

                                localBrand[localBrand.length] = brandName;
                                // console.log('**************************************************************');
                                // console.log(brandName);
                                for (var x = 0; x < branches.length; x++) {
                                    if (!localBrandData[brandName]) {
                                        localBrandData[brandName] = {}
                                    }
                                    localBrandData[brandName][branches[x].branch_name] = 0;
                                    if (!param) {
                                        if (allProducts[brandName]) {
                                            if (allProducts[brandName][branches[x].branch_name]) {
                                                localBrandData[brandName][branches[x].branch_name] = allProducts[brandName][branches[x].branch_name];
                                            }
                                        }
                                    } else {
                                        if (allSpecifiedProducts[param][brandName]) {
                                            if (allSpecifiedProducts[param][brandName][branches[x].branch_name]) {
                                                localBrandData[brandName][branches[x].branch_name] = allSpecifiedProducts[param][brandName][branches[x].branch_name];
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                function reloadTable(header, data, state) {
                    if (state == 'reload') {

                        tableElement.DataTable().clear();
                        tableElement.DataTable().destroy();

                        $(".tbl-create-inventory-report thead").remove();
                        tableElement.DataTable({
                            data: data,
                            columns: header,
                            info: false,
                            paging: false,
                            "sDom": '<"bottom"<t>ip><"clear">',
                            "bFilter": false
                        });
                        // tableElement.DataTable({
                        //     data: data,
                        //     columns: header,
                        //     info: false,
                        //     paging: false,
                        //     paging: false,
                        //     scrollY: '50vh',
                        //     deferRender: true,
                        //     processing: true,
                        //     serverSide: true,
                        //     deferLoading: 57,
                        //     "sDom": '<"bottom"<t>ip><"clear">',
                        //     "bFilter": false
                        // });

                    }
                    if (state == 'load') {
                        tableElement.DataTable({

                            data: data,
                            columns: header,
                            info: false,
                            paging: false,
                            "bFilter": false
                        });
                    }
                }

                function getPaginateAction(action) {
                    var that = $('.header-pagination-container')
                    var current_page = that.find('.page-item.active');
                    var current_page_data = parseInt(current_page.data('page'));
                    var total_pagination = that.find('li.page-item').length - 2;
                    var current_page_count_next = current_page_data + 1;
                    var current_page_count_prev = current_page_data - 1;

                    //console.log(current_page_data + " : " + total_pagination);
                    if (action.toLowerCase() == 'next') {
                        if (current_page_count_next < total_pagination) {
                            that.find('.page-item.active').removeClass('active');
                            that.find('.page-item[data-page=' + current_page_count_next + ']').addClass('active');
                            getActiveHeader(current_page_count_next, 1);
                        } else {
                            // if next is greater than total pagination;;
                            // return false
                        }

                    }

                    if (action.toLowerCase() == 'prev') {
                        if (current_page_count_prev >= 0) {
                            that.find('.page-item.active').removeClass('active');
                            that.find('.page-item[data-page=' + current_page_count_prev + ']').addClass('active');
                            getActiveHeader(current_page_count_prev, 1);
                        } else {
                            // if prev is less than 0;
                            // return false
                        }
                    }
                }


                function genPagnation() {
                    var paginate = (parseInt(branches.length) / parseInt(colInit));
                    var html = '';
                    paginate = paginate.toFixed(0);
                    paginate = parseInt(paginate);

                    for (var x = 0; x < paginate; x++) {
                        if (x == 0) {
                            html += '<li class="page-item active" data-page="' + x + '"><a class="page-link header-pagination header-page-1">' + (x + 1) + '</a></li>';
                        } else {
                            html += '<li class="page-item" data-page="' + x + '"><a class="page-link header-pagination header-page-1">' + (x + 1) + '</a></li>';
                        }
                    }
                    $('.header-pagination-container .pagination').html('<li class="page-item"><a class="page-link header-pagination-prev">Previous</a></li>' + html + '<li class="page-item"><a class="page-link header-pagination-next">Next</a></li>');

                }

                function getActiveHeader(data = 0, param = null) {
                    var that_page = (parseInt(data) * parseInt(colInit));
                    console.log(that_page);
                    var tableData = generateTable(that_page);
                    if (param) {
                        reloadTable(tableData.header, tableData.data, 'reload');
                    } else {
                        reloadTable(tableData.header, tableData.data, 'load');
                    }

                }

                function numberWithCommas(x){
                  var parts = x.toString().split(".");
                  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                  return parts.join(".");
                }

                /* Generate Datatable*/
                function generateTable(inputData, allData = false) {
    
                    var tdColumn = [{
                        data: "Brand",
                        title: "Brand"
                    }];
                    var tdColumnData = [];
                    var resetBranch = [];
                    var localColLimit = 0;


                    if (!inputData) {
                        inputData = 0;
                    }
                    
                    /* Create Dynamic Content Base on branches */
                    var antiOverlapLimit = 0;

                    antiOverlapLimit = parseInt(branches.length) - parseInt(inputData);

                    if (antiOverlapLimit < localColLimit) {
                        if(allData){
                            localColLimit = parseInt(branches.length);
                        }else{
                            localColLimit = parseInt(antiOverlapLimit);
                        }
                    } else {
                        if(allData){
                            localColLimit = parseInt(branches.length);
                        }else{
                            localColLimit = colInit;
                        }
                    }

                    for (var x = 0; x < branches.length; x++) {
                        if (x >= inputData) {
                            resetBranch[resetBranch.length] = branches[x];
                        }
                    }
                    
                    /* Gegerate Header */
                    for (var x = 0; x < localColLimit; x++) {
                        if (resetBranch[x]) {
                            tdColumn[tdColumn.length] = {
                                "data": resetBranch[x].branch_name,
                                "title": resetBranch[x].branch_name
                            };
                        }
                    }

                    /* Row Content */
                    for (var x = 0; x < localBrand.length; x++) {
                        var local_data = {};
                        /* Assign row COntent Base on Product List */
                        for (var key in tdColumn) {

                            var local_brandname = localBrand[x];
                            var local_branchname = tdColumn[key].data;

                            // console.log('&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&')
                            // console.log(local_brandname);
                            // console.log(local_branchname);


                            if (key == 0) {
                                local_data[local_branchname] = local_brandname;
                            } else {
                                var localBrandDataCon = localBrandData[local_brandname];

                                // console.log('&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&');
                                // console.log(localBrandDataCon);
                                if (localBrandDataCon[local_branchname]) {
                                    local_data[local_branchname] = numberWithCommas(localBrandDataCon[local_branchname]);
                                } else {
                                    local_data[local_branchname] = 0;
                                }

                            }

                        }

                        tdColumnData[tdColumnData.length] = local_data;
                    }
                    
                    return {
                        data: tdColumnData,
                        header: tdColumn
                    };
                
                }
                
                function branchfiltering(data){
                    var chester = data;
                    
                    if(chester === 'all'){
                        
                        tableElement.DataTable().clear();
                        tableElement.DataTable().destroy();
                        $(".tbl-create-inventory-report thead").remove();
                        genallBrand(allBrand);
                        genPagnation();
                        getActiveHeader();
                        
                    }else{
                        var chester = data;
                    }
                }

                function loadNewData(data) {

                    if (data == 'all') {
                        $('.current-selected-brand').html('ALL');
                        tableElement.DataTable().clear();
                        tableElement.DataTable().destroy();
                        $(".tbl-create-inventory-report thead").remove();
                        genallBrand(allBrand);
                        genPagnation();
                        getActiveHeader();
                    } else {
                        $('.current-selected-brand').html(data);
                        var current_brand = data;
                        var current_brand_model = allSpecifiedProducts[current_brand];
                        // console.log('YYYYYYYYYYYYYYYYYYYYYYY',current_brand_model);
                        
                        var current_data = [];
                        for (var x in current_brand_model) {

                            if (x != 'Date') {
                                current_data[Object.keys(current_data).length] = {
                                    brandname: x
                                };
                            }
                        }

                        tableElement.DataTable().clear();
                        tableElement.DataTable().destroy();
                        $(".tbl-create-inventory-report thead").remove();
                        
                        genallBrand(current_data, data);
                        genPagnation();
                        getActiveHeader();
                    }
                }       
                
                function updateList(selected){
                    
                    if(selected === 'all'){
                        loadNewData('all')
                    } else {
                    
                    // console.log(selected);
                    let table = generateTable(0,true);
                    let tdColumnData = table.data;
                    let tdColumn = table.header;
                    // console.log(tdColumnData);
                    let updatedtdColumnData = [];
                    
                    for(let index in tdColumnData) {
                        let obj = tdColumnData[index];
                        let updatedObj = {};
                        
                        updatedObj['Brand'] = obj.Brand;
                        // updatedObj[selected] = typeof obj[selected] === 'undefined' ? 0 : obj[selected];
                        console.log('updatedObj[selected]', obj[selected]);
                        updatedObj[selected] = obj[selected] ? obj[selected] : 0;
                        
                        updatedtdColumnData.push(updatedObj);
                        //delete obj[selected];
                        //updatedtdColumnData.push(updatedtdColumnData);
                    }
                    
                    var updatedtdColumn = [];
                    updatedtdColumn.push({data: "Brand", title: "Brand"});
                    for(let index in tdColumn) {
                        let obj = tdColumn[index];
                        if(obj.data === selected){
                            updatedtdColumn.push(obj);
                        }
                        
                    }

                    $('.header-pagination-container .pagination').html('<li class="page-item"><a class="page-link header-pagination-prev">Previous</a></li><li class="page-item"><a class="page-link header-pagination-next">Next</a></li>');

                    
                        reloadTable(updatedtdColumn, updatedtdColumnData, 'reload');
                    }
                }
                
                function savereport() {
                    let app = global_this.props.app;
                    var that = $(this);
                    
                    var filterByReports = $('.filter-by-reports').val();
                    var dateRangePickerInput =  $('.current-selected-date').data('daterangepicker');
                    var dateRangePickerInputStart = (moment(dateRangePickerInput.startDate).format('YYYY-MM-DD'));  
                    var dateRangePickerInputEnd = (moment(dateRangePickerInput.endDate).format('YYYY-MM-DD'));
                    var filterByBranch = $('.filter-by-branch').val();
                    var userdataid = global_this.props.userData._id;
                    var userdatafullname = global_this.props.userData.fullname;
                    var reportTitle = $.trim($('.report-title').val());

                    if (reportTitle === ''){
                        toastr.error('Please add a title for the report');
                        return;
                    }
                    global_this.setState({
                        saving:true
                    })

                    
                    let reportdata = {
                        report_type:filterByReports ,
                        report_title:reportTitle ,
                        daterange_start: global_this.state.drPickerApplied ? dateRangePickerInputStart : null ,
                        // datepickerstart:dateRangePickerInputStart ,
                        daterange_end: global_this.state.drPickerApplied ? dateRangePickerInputEnd : null,
                        // datepickerend: dateRangePickerInputEnd,
                        branchfilter: filterByBranch,
                        user_id:userdataid,
                        // userid:userdataid,
                        user_fullname:userdatafullname,
                        // userfullname:userdatafullname
                        extraData: global_this.state.sample,
                        
                    }
                    
                    app.service('reports').create(reportdata).then(function(data){
                        console.log(data);   
                        toastr.success('Report Successfully Created!');
                        
                        global_this.props.history.push('/report');
                        
                        global_this.setState({
                            saving:false
                        })
                    }).catch(() => {
                        toastr.error('Report not saved');
                        
                        global_this.setState({
                            saving:false
                        })
                    })
                    
                }

                function loadDateRange(data, pickerrange) {

                    var dates = data;
                    var splitted = dates.split(' - ');
                    var startDate = splitted[0];
                    var endDate = splitted[1];
                    var Data = allSpecifiedProducts;
                    var _range = pickerrange;
                    var motors = [];
            
                     

                    for (var name in Data) {
                        var datadate = {
                            'name': name,
                            'date': Data[name].Date
                        };

                    }
                    var updatedData = {};
                    for (var index in Data) {  
                        var row = Data[index];  
                        if (_range.indexOf(row.Date) === -1) {    
                            continue;
                        }
                        updatedData[index] = row;
                    }



                    if (updatedData !== 0) {
                        
                        var current_brand = updatedData;

                        var current_data = [];
                        
                        for (var _k in current_brand) {
                           

                            if (_k != 'Date') {
                                var free = _k;
                                current_data[Object.keys(current_data).length] = {
                                    brandname: free
                                };
                            }

                        }
                        tableElement.DataTable().clear();
                        tableElement.DataTable().destroy();
                        $(".tbl-create-inventory-report thead").remove();
                       
                        genallBrand(dateremovezerodata(current_data));
                        genPagnation();
                        getActiveHeader();
                        
                        

                    } else {

                        tableElement.DataTable().clear();
                        tableElement.DataTable().destroy();
                        $(".tbl-create-inventory-report thead").remove();
                        genallBrand(allBrand);
                        genPagnation();
                        getActiveHeader();

                    }


                }
                
                function dateremovezerodata(data, param = null) {
                    
                    localBrand = [];
                    
                    var Data = allSpecifiedProducts;
                    
                    
                    for (var x in data) {
                        for (var y in data[x]) {
                            var brandName = data[x][y];
                            if ($.inArray(brandName, localBrand) == -1) {

                                localBrand[localBrand.length] = brandName;
                                for (var x = 0; x < branches.length; x++) {
                                    if (!localBrandData[brandName]) {
                                        localBrandData[brandName] = {}
                                    }
                                   
                                    if (!param) {
                                        if (allProducts[brandName]) {
                                            if (allProducts[brandName][branches[x].branch_name]) {
                                                localBrandData[brandName][branches[x].branch_name] = allProducts[brandName][branches[x].branch_name];
                                            }
                                        }
                                    } else {
                                        if (allSpecifiedProducts[param][brandName]) {
                                            if (allSpecifiedProducts[param][brandName][branches[x].branch_name]) {
                                                localBrandData[brandName][branches[x].branch_name] = allSpecifiedProducts[param][brandName][branches[x].branch_name];
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    
                    
                    var _counted = {};
                    var updatedData = [];
                    
                    for(var key in data){
                        var local_counter = 0;
                        var local_brand_name = data[key].brandname;
                        
                        for(var key2 in localBrandData[local_brand_name]){
                            var localData = localBrandData[local_brand_name][key2];
                            console.log(key2,localData)
                            if(parseInt(localData) > 0){
                                local_counter++;
                            }
                        }
                        if(local_counter > 0){
                            updatedData[updatedData.length] = {brandname:local_brand_name};
                        }
                    }
                    // for(let index in localBrandData){
                      // let row = localBrandData[index];
                      // _counted[index] = Object.keys(row).length;
                      
                      // for(let _index in row){
                        // let _row = row[_index];
                        
                        // if(_row === 0){
                         // _counted[index] = _counted[index] - 1;
                        // }
                      // }
                      
                      // if(_counted[index] === 0){
                        // continue;
                      // }
                      
                      // updatedData[index] = row;
                    // }
                    
                    
                    //localBrandData = updatedData;
                    return updatedData;
                }
                
                genallBrand(allBrand);
                genPagnation();
                getActiveHeader();

                $(function() {
                    var start = moment();
                    var end = moment();
                    $('input[name="daterange"]').daterangepicker({
                        startDate: start,
                        endDate: end,
                    });


                    $('input[name="daterange"]').on('apply.daterangepicker', function(ev, picker) {

                        var that = $(this);
                        var that_value = that.val();
                        var dates = that_value;
                        var splitted = dates.split(' - ');
                        var startDate = splitted[0];
                        var endDate = splitted[1];

                        global_this.setState({
                            drPickerApplied: true
                        })

                        var startDateMoment = (moment(startDate).format('YYYY-MM-DD'));
                        var endDateMoment = (moment(endDate).format('YYYY-MM-DD'));


                        var _startDate = new Date(startDateMoment);
                        var _endDate = new Date(endDateMoment);


                        var _date_diff = (_startDate.getTime() - _endDate.getTime()) / (24 * 3600 * 1000);
                        var _date_diff = Math.abs(_date_diff);
                        var _dates = [];
                        var _adates = [];

                        for (var _i = 0; _i < _date_diff; _i++) {
                            _dates.push(new Date(_startDate.setDate(_startDate.getDate() + 1)));
                        }
                        var _datearray = _dates;
                        for (var x = 0; x < _datearray.length; x++) {
                            _adates.push((moment(_datearray[x]).format('YYYY-MM-DD')));
                        }
                        var dateFrom = [startDateMoment];
                        var dateTo = [endDateMoment];
                        var adates = _adates;
                        var combinedDates = dateFrom.concat(adates);
                        loadDateRange(that_value, combinedDates);
                    });
                });




                $('.header-pagination-container').on('click', '.page-link.header-pagination', function() {
                    var that = $(this);
                    var that_page_item = that.closest('.page-item');
                    var that_page = that_page_item.data('page');

                    $('.header-pagination-container').find('.page-item.active').removeClass('active');
                    that.closest('.page-item').addClass('active');
                    getActiveHeader(that_page, 1);
                })

                $('.header-pagination-container').on('click', '.header-pagination-prev', function() {
                    getPaginateAction('Prev');
                })

                $('.header-pagination-container').on('click', '.header-pagination-next', function() {
                    getPaginateAction('Next');
                })

                $('.sub-inv-main-content').on('change', '.filter-by-brand', function() {
                    var that = $(this);
                    var that_value = that.val();
                    loadNewData(that_value);
                })
                
                 $('.sub-inv-main-content').on('change', '.filter-by-branch', function() {
                    var that = $(this);
                    var that_value = that.val();
                    
                    
                    global_this.setState({
                        sample:that_value
                    })
                    
                    updateList(that_value);
                        
                    })
                    
                $('.sub-inv-main-content').on('change', '.filter-by-reports', function() {
                    var that = $(this);
                    var that_value = that.val();
                    
                    if (that_value === 'Sales') { 
                        // window.location = 'http://localhost:3000/report/sales_report'; 
                        global_this.props.history.push('/report/create_sales_report');
                    }else{
                        global_this.props.history.push('/report/create_inventory_report');
                        // window.location = 'http://localhost:3000/report/inventory_report'; 
                    }
                    return false;
                    })  
                    
                    
                    $('.sub-inv-main-content').on('click', '.savebutton', function() {
                    
                    

                    if(global_this.state.saving ){
                        return
                    }else{
                        savereport();
                    }
                })  
            }else{
                global_this.reloadAllData()
            }
        })
    }

    reloadAllData = () => {
        global_this.findProducts()
        global_this.loadAllData()
    }

    componentDidMount() {
        $.getScript("../../../Plugins/bootstrap-daterangepicker-master/daterangepicker.js");
        global_this.loadAllData()
    }

    render() {
        var dateRangePickerInput =  $('.current-selected-date').data('daterangepicker');
        var dateRangePickerInputStart = ( global_this.state.drPickerApplied ? moment(dateRangePickerInput.startDate).format('YYYY-MM-DD') : '');  
        var dateRangePickerInputEnd = ( global_this.state.drPickerApplied ? moment(dateRangePickerInput.endDate).format('YYYY-MM-DD') : '');

        return (
            <div className="">
                {/* main NavBar component*/}
                <MainNav historyProp={this.props.history} usersProp={this.props.userData}/>
                
                <div className="container-fluid">
                    <div className="row full-width">
                        <div className="sidebar">
                            <div className="sidebar-content">
                                <MainSideBar page="report"/>
                            </div>
                        </div>
                        <SubSideBar history={this.props.history} />
                        <div className="sub-inv-main-content">
                            <div className="container-fluid">
                                <div className="row padding-20 background-white">
                                    <div className="col-md-12">
                                        <div className="row">
                                            <div className="col d-flex flex-row align-items-center">
                                                <label className="col-md-5">Report Type</label>
                                                <select className="form-control col-md-7 filter-by-reports">
                                                    <option>Inventory</option>
                                                    <option>Sales</option>
                                                    </select>
                                            </div>
                                            <div className="col d-flex flex-row align-items-center">
                                                <label className="col-md-5">Date Range</label>
                                                <input type="text" name="daterange" className="current-selected-date btn btn-primary"/>
                                            </div>
                                            <div className="col d-flex flex-row">
                                                <label className="col-md-5">Branch</label>
                                                <select className="form-control col-md-7 filter-by-branch">
                                                    <option>All</option>
                                                    <option>Branch</option>
                                                </select>
                                            </div>
                                            <div className="col">
                                                <div className="row report-option-icons">
                                                {
                                                    // <a href="javascript:;"><i className="fa fa-download"></i></a>
                                                    // <a href="javascript:;"><i className="fa fa-file-pdf-o"></i></a>
                                                    // <a href="javascript:;"><i className="fa fa-envelope-o"></i></a>
                                                    // <a href="javascript:;"><i className="fa fa-print"></i></a>
                                                }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row padding-20 background-white">
                                    <div className="center-div">
                                        <button className="btn application-btn border-radius-50 padding-10-40 text-white bg-active-color savebutton">SAVE REPORT</button>
                                    </div>
                                </div>

                                <div className="row padding-20">
                                    <p>Inventory Reports</p>
                                </div>

                                <div className="row padding-20 background-white">
                                    <div className="col-md-4">
                                        <input
                                            className="form-control report-title"
                                            type="text"
                                            placeholder="Report title here"
                                        />
                                        <br />
                                        <p>By {this.props.userData.fullname}</p>
                                    </div>
                                    <div className="col-md-3">
                                    </div>
                                    <div className="col-md-3">
                                        <h5>INVENTORY REPORT</h5>
                                        <span>
                                        {
                                            this.state.drPickerApplied ? moment(dateRangePickerInputStart).format('LL')+' to '+moment(dateRangePickerInputEnd).format('LL') : moment().format('LL')
                                        }
                                        </span><br />
                                        <span>MAIN BRANCH</span>
                                    </div>
                                    <div className="col-md-12">
                                        <br />
                                    </div>
                                    <div className="col-md-12">
                                        <div className="header-pagination-container">
                                            <ul className="pagination">
                                                
                                            </ul>

                                        </div>
                                        <table className="table tbl-create-inventory-report tbl-mobile">
                                        </table>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


const mapStateToProps = state => ({
    app: state.feathersClient.app,
    userData: state.login.userData,
});

function mapDispatchToProps(dispatch) {
   return { actions: bindActionCreators(ActionCreators, dispatch) }
}

export default connect(mapStateToProps, mapDispatchToProps)(recent);