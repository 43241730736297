import React, { Component } from 'react';
import toastr from 'toastr';

import moment from 'moment';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

//redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// actions
import { ActionCreators } from '../../../actions';

const $ = require('jquery');

var that;

class add_price_modal extends Component {

	constructor(props) {
	  	super(props);
	
	  	this.state = {
	  		saving: false,
	  		mainbranch: '',
	  		invoice_date: moment()
	  	};
	  
	}

	componentWillMount(){
		that = this;
	}

	closeModalCallback = () => {
		this.refs.price.value = '';
		that.refs.cancel.click();
	}

	addPrice = () => {
		var	that = this,
			unitIds = this.props.unitsForPricing,
			price = this.refs.price.value.trim();

			if(this.state.saving){
				return;
			}else{
				this.setState({
					saving: true
				})
			}


			// console.log(invoice_date)
			// console.log(invoice_number ? invoice_number : 'empty')
			// return;

			if(!price){
				toastr.error('Please enter price');
				this.refs.price.value = '';
				this.setState({
					saving: false
				})
				return;
			}
			if(isNaN(price)){
				toastr.error('Add a valid price');
				this.setState({
					saving: false
				})
				return;
			}



			unitIds.map((v) => {
				// console.log('haha ',v[0]._id)
				that.props.app.service('products').patch(v[0]._id,
				{
					price: price
				}
				)
				.then((data) => {
					// console.log('data ', data)
				})
				.catch((error) => {
					console.log('error ', error)
				})
			})

			setTimeout(() => {
				that.closeModalCallback();
				toastr.success('Units successfully updated')
				that.props.actions.pricesUpdated(true)
				that.setState({
					saving: false
				})
			}, 1000 * unitIds.length)

	}

	closeModel = () => {
	}

	componentDidMount() {
		
	}

    handleDeliveryReceiptDate = (date) => {
    	this.setState({
    		invoice_date: date
    	})
    }

  	render() {
    	return (
      		<div className="modal fade" id="add_price" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" ref="add_price" data-backdrop="static" data-keyboard="false">

			  	<div className="modal-dialog modal-dialog-sm" role="document">
			    	<div className="modal-content">
			      		<div className="modal-header">
			        		<h5 className="modal-title" id="exampleModalLabel">Add Price</h5>
			        			<button type="button" className="close" onClick={this.closeModalCallback} aria-label="Close">
						          <span aria-hidden="true">&times;</span>
						        </button>
			      		</div>
				      	<div className="modal-body">
				        	<div className="form-group">
				        		<label>Price</label>
				        		<input type="text" className="form-control" ref="price" placeholder="Enter price"/>
				        	</div>
				      	</div>
				      	<div className="modal-footer">
				        	<a data-dismiss="modal" ref="cancel"></a>
				        	<button type="button" className="btn btn-secondary" onClick={this.closeModalCallback}  disabled={this.state.saving}>Cancel</button>
				        	<button type="button" className="btn btn-primary" onClick={this.addPrice}  disabled={this.state.saving}>Save</button>
				      	</div>
			    	</div>
			  	</div>
			</div>
    	);
  	}
}


const mapStateToProps = state => ({
   	category: state.category,
   	app: state.feathersClient.app,
   	unitsForPricing: state.product.unitsForPricing,
});

function mapDispatchToProps(dispatch) {
   return { actions: bindActionCreators(ActionCreators, dispatch) }
}

export default connect(mapStateToProps, mapDispatchToProps)(add_price_modal);