import React, {Component} from 'react';
//main NavBar
import MainNav from '../../../components/global_nav';
import MainSideBar from '../../../components/global_sidebar';

//custom styles
import '../../../stylesheet/styles.css';

//redux
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import dashboardLogo from '../../../assets/icons/dashboard.svg';
import inventoryLogo from '../../../assets/icons/inventory.svg';
import paymentsLogo from '../../../assets/icons/payments.svg';
import customerLogo from '../../../assets/icons/customer.svg';
import reportLogo from '../../../assets/icons/reports.svg';
import userLogo from '../../../assets/icons/user.svg';
import settingLogo from '../../../assets/icons/settings.svg';
import financialStatementIcon from '../../../assets/icons/financialstatement_icon.svg';
import ledgerIcon from '../../../assets/icons/ledger_icon.svg';

// actions
import * as LoginActions from '../../../actions/login';
import * as UserActions from '../../../actions/users';
import * as CategoryActions from '../../../actions/category';

import feathers from '../../../helpers/feathers';
import { conf } from '../../../helpers';

const $ = require('jquery');
$.DataTable = require('datatables.net');

var that;
const SH_CUSTOMERS = "customer-secondhand-unit";
const SH_LEDGER = "ledger-secondhand";
const SH_PAYMENTS = "customer-payments-secondhand"

class overdue_customer extends Component {
    pageIsMounted = false;

    constructor(props) {
        super(props);

        this.state = {};
    }

    componentDidMount() {
        const _this = this;
        $('.back-button-header').hide();
        _this.setPermission()
        _this.pageIsMounted = true;
    }

    componentWillMount() {
        that = this;
        const _this = this;
        $('.back-button-header').hide();

        //positions
        this.props.actions.getAllPositions().then((data) => {
            if(data){
                that.props.actions.setPositions(data);    
            }
        });
    }

    componentWillUnmount(){
        that.pageIsMounted = false;
    }

    gotoPage = (page) => {
        this.props.history.push(page)
    }

    setPermission = () => {
        var that = this;
        
        if(that.pageIsMounted){
            feathers.authenticate({
                strategy: 'local',
                username: conf.defaults.username,
                password: conf.defaults.password,
            })
            .then(() => {
                let {userData} = this.props;
    
                if (userData) {
                    let id = userData._id;
    
                    feathers.service("users").find({
                        query: {
                            _id: id
                        }
                    })
                    .then((d) => {
                        var user = d.data[0];
    
                        // console.log('user.type')
                        // console.log(user.type)
                        // console.log(d.data[0].user_position_info._id)
    
                        that.props.actions.setUserData(user);
                        that.props.actions.getUserPermissions(user.type);
                    })
                    .catch((e) => {
                        return e;
                    })
                } else {
                    // if no userData found, log the user out of the system
                    this.props.actions.loginUser(false)
                }
            })
            .catch((err) => {
                console.log(err.message)
            })
        }
    }

    render() {
        return (
            <div className="">
                <MainNav historyProp={this.props.history} userPermission={this.props.userPermission}
                         usersProp={this.props.userData} systemType="inventory"/>
                <div className="container-fluid">
                    <div className="row full-width">
                        <div className="sidebar">
                            <div className="sidebar-content">
                                <MainSideBar page="landing_page"/>
                            </div>
                        </div>
                        <div>
                            {window.outerWidth > 768 ?
                                <div className="main-content inv-main-content">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="inline-block width-100p padding-tb-20">
                                                <div className="row padding-bottom-20">
                                                    <div className="col center-items menu-items">
                                                        <div className="inner-menu-item"
                                                             onClick={() => this.gotoPage("/dash")}>
                                                            <div className="item-wrap">
                                                                <img alt="icon" src={dashboardLogo}
                                                                     className="sidebarLogo"
                                                                     style={{height: 70}}/>
                                                                <p className="text1">Dashboard</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col center-items menu-items">
                                                        <div className="inner-menu-item"
                                                             onClick={() => this.gotoPage("/all_units")}>
                                                             <div className="item-wrap">
                                                                <img alt="icon" src={inventoryLogo}
                                                                    className="sidebarLogo" style={{height: 70}}/>
                                                                <p className="text2">Inventory</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col center-items menu-items">
                                                        <div className="inner-menu-item"
                                                             onClick={() => this.gotoPage("/payments/")}>
                                                             <div className="item-wrap">
                                                                <img alt="icon" src={paymentsLogo}
                                                                    className="sidebarLogo" style={{height: 60, marginBottom: 5}}/>
                                                                <p className="text2">Payments</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row padding-bottom-20">
                                                    <div className="col center-items menu-items">
                                                        <div className="inner-menu-item"
                                                             onClick={() => this.gotoPage("/report/unsold_report/")}>
                                                            <div className="item-wrap">
                                                                <img alt="icon" src={reportLogo}
                                                                    className="sidebarLogo"
                                                                    style={{height: 70}}/>
                                                                <p className="text1">Reports</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col center-items menu-items">
                                                        <div className="inner-menu-item"
                                                             onClick={() => this.gotoPage("/user/")}>
                                                             <div className="item-wrap">
                                                                <img alt="icon" src={userLogo}
                                                                    className="sidebarLogo"
                                                                    style={{height: 70}}/>
                                                                <p className="text1">User</p>
                                                             </div>
                                                        </div>
                                                    </div>
                                                    <div className="col center-items menu-items">
                                                        <div className="inner-menu-item"
                                                             onClick={() => this.gotoPage("/settings/")}>
                                                             <div className="item-wrap">
                                                                <img alt="icon" src={settingLogo}
                                                                    className="sidebarLogo" style={{height: 55}}/>

                                                                <p className="text2">Settings</p>
                                                             </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <br/>
                                    <br/>
                                </div> :
                                <div className="main-content inv-main-content">
                                    {/*<div className="row">*/}
                                    <div className="space-50"/>
                                    <div className="row padding-tb-20">
                                        <div className="col">
                                            <div className="mobile-list-dashboard__wrapper">
                                                <div className="inner-menu-item"
                                                     onClick={() => this.gotoPage("/dash")}>
                                                    <div className="item-wrap">
                                                        <img alt="icon" src={dashboardLogo}
                                                             className="sidebarLogo" style={{height: 70}}/>
                                                        <p className="text1">Dashboard</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="mobile-list-dashboard__wrapper">
                                                <div className="inner-menu-item"
                                                     onClick={() => this.gotoPage("/inventory/")}>
                                                    <img alt="icon" src={inventoryLogo}
                                                         className="sidebarLogo" style={{height: 70}}/>
                                                    <p className="text2">Inventory</p>
                                                </div>
                                            </div>
                                            <div className="mobile-list-dashboard__wrapper">
                                                <div className="inner-menu-item"
                                                     onClick={() => this.gotoPage("/payments/")}>
                                                    <img alt="icon" src={paymentsLogo}
                                                         className="sidebarLogo" style={{height: 70, padding: 8}}/>
                                                    <p className="text2">Payments</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className="mobile-list-dashboard__wrapper">
                                                <div className="inner-menu-item"
                                                     onClick={() => this.gotoPage("/report/unsold_report/")}>
                                                    <img alt="icon" src={reportLogo} className="sidebarLogo"
                                                         style={{height: 70}}/>
                                                    <p className="text1">Reports</p>
                                                </div>
                                            </div>
                                            <div className="mobile-list-dashboard__wrapper">
                                                <div className="inner-menu-item"
                                                     onClick={() => this.gotoPage("/user/")}>
                                                    <img alt="icon" src={userLogo} className="sidebarLogo"
                                                         style={{height: 70}}/>
                                                    <p className="text1">User</p>
                                                </div>
                                            </div>
                                            <div className="mobile-list-dashboard__wrapper">
                                                <div className="inner-menu-item"
                                                     onClick={() => this.gotoPage("/settings/")}>
                                                    <img alt="icon" src={settingLogo}
                                                         className="sidebarLogo" style={{height: 55}}/>
                                                    <p className="text2">Settings</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/*</div>*/}
                                    <br/>
                                    <br/>
                                </div>}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    userData: state.login.userData,
});

function mapDispatchToProps(dispatch) {
    return {actions: bindActionCreators(Object.assign({}, LoginActions,UserActions,CategoryActions,), dispatch)}
}

export default connect(mapStateToProps, mapDispatchToProps)(overdue_customer);
