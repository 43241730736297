import React, {Component} from 'react';

//redux
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
// actions
import {ActionCreators} from '../../../actions';

// react-router
// import { Link } from 'react-router-dom';


class select_customer_modal extends Component {

    constructor(props) {
        super(props);

        this.state = {};
    }

    addCustomer = (brandnew) => {
        if (brandnew) {
            this.props.history.push('/acc_add_customer');
        } else {
            this.props.history.push('/add_new_customer_secondhand_unit');
        }
    }

    render() {
        return (
            <div className="modal fade" id="selectUnitType" tabIndex="-1" role="dialog"
                 aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Select Unit Type</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="padding-20">
                                <div className="col-md-12 d-flex align-items-center flex-column">
                                    <button onClick={() => this.addCustomer(true)}
                                            className="btn-secondary btn btn-lg col-md-8 add-customer-modal"
                                            data-dismiss="modal">Brand New
                                    </button>
                                    <div className="form-group d-flex align-items-center">
                                        <p className="text-center">or</p>
                                    </div>
                                    <button onClick={() => this.addCustomer(false)}
                                            className="btn-secondary btn btn-lg col-md-8 add-customer-modal"
                                            data-dismiss="modal">Secondhand
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


const mapStateToProps = state => ({
    loginStatus: state.login.loginStatus
});

function mapDispatchToProps(dispatch) {
    return {actions: bindActionCreators(ActionCreators, dispatch)}
}

export default connect(mapStateToProps, mapDispatchToProps)(select_customer_modal);
