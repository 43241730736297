import React, {Component} from 'react';

//redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// actions
import { ActionCreators } from '../../../actions';

import app from '../../../helpers/feathers'

//main NavBar
import MainNav from '../../../components/global_nav';
import MainSideBar from '../../../components/global_sidebar';

import ConfirmRequest from './confirm_request';

import toastr from 'toastr';

//datatables
import '../../../stylesheet/datatables/datatables.css';

const $ = require('jquery');
$.DataTable = require('datatables.net');

// class notifications extends React.PureComponent {
class notifications extends Component {

	constructor(props) {
		super(props);

		this.state = {
			dt_data: [],
			account_data: []
		}
	}

	UNSAFE_componentWillMount(){
	}

	componentDidMount(){
    const that = this;

    this.displayNotifList()


    app.service("password-request").on("created", function(){
        that.displayNotifList()
    })

    const applicationTbl = $('.notifications-table').DataTable({
    		// data: this.state.dt_data,
        "columnDefs": [
            {
                "visible": false, "targets": 0
            },
            // {
            //     targets: [2],
            //     orderable: false,
            //     width: '10%'
            // }
        ],
        columns: [
            {title: "data"},
            {title: "EMAIL"},
            {title: "DATE"},
            {title: "ACTION"}
        ],
        "sDom": '<"bottom"<t>ip><"clear">',
        createdRow : function(row,data,index) {
        	if(data[0].unread){
        		$(row).addClass("unread-request")
        	}
        }
    });

    $('.notifications-table').on('click', 'button.action', function () {
      var data = applicationTbl.row($(this).parents('tr')).data();
      var id = data[0]._id;
      var email = data[0].email;

      that.props.actions.readRequest(id);

			that.props.actions.getDetails(email)
			.then((res) => {
				that.displayNotifList()

				if(res.status){
      		that.setState({account_data: res.data})
      		$("#confirm_request").modal("show")
				}else{
					toastr.remove()
					toastr.error(`No data found associated with the email "${email}" `)
				}

			})

      // that.props.actions.deleteOne('password-request',id)
      // .then((data) => {
      // 	that.displayNotifList()
      // })
      // .catch(() => {
      // 	that.displayNotifList()
      // })

      // that.props.actions.sendmail(email)
    });
	}

	displayNotifList = () => {
		const that = this;
		let { notifications } = this.props;

		console.log('display')

    that.props.actions.getNotifications()
    .then((res) => {
      if(res){
        that.updateTable(res)
      }
    })

		if(notifications.length > 0){
			// that.updateTable(notifications)
		}
	}

  updateTable = (data) => {
  	this.setState({
  		dt_data: data
  	})

    let table = $('.notifications-table').DataTable();
    table.clear();
    table.rows.add(data);
    table.draw();

    // const table = $('.notifications-table').DataTable();
    // // table.clear();
    // table.destroy();
    // table.rows.add(data);
    // table.draw();
  }
    
  permission = () => {
      var branch = this.props.userData.branch_info.branch_name;

      if (branch == "MAIN"){
          return "";
      }else{
          return "not-visible"
      }
  }

	render() {
		return (
			<div className="">
     	 		{/* main NavBar component*/}
     	 		<MainNav historyProp={this.props.history} usersProp={this.props.userData} />
     	 		<ConfirmRequest account_data={this.state.account_data}/>
     	 		
     	 		<div className="container-fluid">
     	 			<div className="row full-width">
	     	 			<div className="sidebar">
	     	 				<div className="sidebar-content">
	     	 					<MainSideBar page="notifications" />
	     	 				</div>
	     	 			</div>
                {
                    this.props.userData.branch_info.branch_name !== "MAIN" ?
                    <div className="main-content">
                        <div className="no-access-div">
                            <div className="col-md-12">
                                <h1>Access denied</h1>
                            </div>
                        </div>
                    </div>
                    : null
                }
                <div className={this.permission()}>
    	     	 			<div className="main-content" style={{marginTop: 15}}>
                    <span className="text-gray"></span>
                    <h4 className="subheader-title">Notifications
                        {
                            // <button onClick={() => this.deletions() }><span className="fa fa-trash" /></button>
                        }
                    </h4>
                    <br/>
                    <div className="container">
                    	<table className="table notifications-table"></table>
                    </div>
                    <br/>
                    <br/>
                    <br/>
                </div>
	     	 			</div>
     	 			</div>
     	 		</div>
     	 	</div>
		);
	}
}

const mapStateToProps = state => ({
   loginStatus: state.login.loginStatus,
   userData: state.login.userData,
   userPermission: state.login.userPermission,
   notifications: state.dashboard.notifications
});

function mapDispatchToProps(dispatch) {
   return { actions: bindActionCreators(ActionCreators, dispatch) }
}

export default connect(mapStateToProps, mapDispatchToProps)(notifications);
