import React, {Component} from 'react';


import app from "../../../helpers/feathers";
// react-router
import {Link} from 'react-router-dom';

//redux
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
// actions
import {ActionCreators} from '../../../actions';

//main NavBar
import MainNav from '../../../components/global_nav';
import MainSideBar from '../../../components/acc_sidebar';
import SyncDataModal from '../../../components/sync_data_modal';

//sub side bar
import SubSideBar from './ledger_sub_sidebar';

import SelectUnitType from './select_customer_modal';
import AddLedgerModal from "./add_ledger_modal";

//custom styles
import '../../../stylesheet/styles.css';
//datatables
import '../../../stylesheet/datatables/datatables.css';

// const search = require('feathers-mongodb-fuzzy-search')

const $ = require('jquery');
$.DataTable = require('datatables.net');

var g_branch = localStorage.getItem('_branch_secret');
var _0x896d = ["\x74\x65\x73\x74\x32"];
var secret_user = _0x896d[0], secret_pass = _0x896d[0];

var that;

class customer_ledger extends Component {


    constructor(props) {
        super(props);

        this.state = {
            data: [],
            obj: [],
            obj2: [],
            brandNew: false,
            customerName: "",
            accountNumber: "",
            promisory_note: 0
        };
    }

    componentWillMount() {
        that = this;
    }

    componentDidMount() {
        const applicationTbl = $('.tbl-customer-ledger').DataTable({
            "columnDefs": [
                {
                    "visible": false, "targets": [3, 4]
                },
                {
                    // targets: [2],
                    // orderable: false,
                    // width: '10%'
                }
            ],
            columns: [
                {title: "NAME"},
                {title: "ACCOUNT NO."},
                {title: "ACTION"},
                {title: "OBJECT"},
                {title: "TYPE"},
            ],
            "sDom": '<"bottom"<t>ip><"clear">'
        });


        $('.search').keyup(function () {
            applicationTbl.search($(this).val()).draw();
        });

        $('.tbl-customer-ledger').on('click', 'button.btn-new-view', function () {
            // var data = applicationTbl.row( $(this).parents('tr') ).data(),
            //       name = (data[0]).replace(/ /g,"");
            //   that.props.actions.setCustomerInfo(data[3]);
            //   // that.props.history.push('/customer/'+name);
            //   that.props.history.push('/view_customer/');
            const data = applicationTbl.row($(this).parents("tr")).data(),
                name = data[0].replace(/ /g, ""),
                customer = data[3],
                customer_id = customer._id;

            that.props.actions.setCustomerInfo(data[3]);
            that.props.actions.setCustomerLedgerInfo(data[3]);
            if (data[4]) {
                that.props.history.push('/view_ledger');
            } else {
                that.props.history.push('/view_ledger_secondhand');
            }

        });

        $(".tbl-customer-ledger").on("click", "button.create", function () {
            let data = applicationTbl.row($(this).parents("tr")).data(),
                customer_id = data[3]._id;

            that.setState({brandNew: data[4]})

            $("#add_ledger .btn-primary").prop("disabled", false);
            that.props.actions.setCustomerLedgerInfo(data[3]);
            $("#add_ledger").modal();
        });

        const custService = app.service('customers');

        custService.on('created', () => {
            // that.getAllCustomers();
        })
        custService.on('patched', () => {
            // that.getAllCustomers();
        })

        $("#add_ledger").on("hidden.bs.modal", function () {
            //   that.getAllCustomers();
        });
        that.updateData([]);

        // that.search_function('jo')

    }

    getAllCustomers = (query) => {
        that.props.actions.getCustomerList(query)
            .then((res) => {
                let d = res ? res.data : [];
                that.props.actions.getSecondhandCustomerList(query)
                    .then((inner_res) => {
                        if (inner_res.data) {
                            var merged = d.concat(inner_res.data)
                            // that.props.actions.setAllCustomers(merged);
                            that.updateData(merged);
                        } else {
                            if (d.length === 0) {
                                that.updateData([])
                            } else {
                                that.updateData(d)
                            }
                        }
                    })
            })
    }

    search_function = () => {
        let customers = app.service('customers'),
            name = "B";

        let escapeRegex = (text) => {
            return text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
        }

        // let regex = new RegExp(escapeRegex(name))
        let regex = new RegExp(name)

        console.log('regex ', regex);
        // return;

        customers.find({
            query: {
                name: {
                    $regex: name
                }
            }
        })
            .then((a) => {
                console.log('aa ', a)
            })

        // customers.Model.createIndex({ title: 'text' })

        // customers.hooks({
        //     before: {
        //       find: search()
        //     }
        // })

        // let docs = await customers.find({ query: { $search: name } })

        // console.log('docs ',docs)
    }

    updateData = (data) => {
        this.setState({
            data: data
        });

        const table = $('.tbl-customer-ledger').DataTable();
        table.clear();
        table.rows.add(data);
        table.draw();
    }

    advancedSearch = () => {
        const that = this,
            customerName = this.refs.customer_name.value,
            accountNumber = this.refs.account_number.value,
            query = {};

        const permission = this.props.userPermission;
        if (customerName === '' && accountNumber === '') {
            that.updateData([])
            return;
        }
        // if (permission.inventory === 0) {
        //     return
        // }
        if (customerName) {
            query.name = {
                $regex: customerName.toUpperCase()
            }
        }

        if (accountNumber) {
            query.account_number = {
                $regex: accountNumber
            }
        }
        that.getAllCustomers(query);

    }

    permissionAccounting = () => {
        var permission = this.props.userPermission;

        if (permission.accounting === 0) {
            return "not-visible"
        } else {
            return "";
        }
    }

    fastSearch = () => {

        let {customerName, accountNumber} = this.state;
        let {customer_name, account_number} = this.refs;
        that.advancedSearch()
    }

    render() {
        let {history, userData, userPermission} = this.props,
            {promisory_note, brandNew, terms} = this.state;

        return (
            <div className="">

                {/* main NavBar component*/}
                <MainNav historyProp={history} usersProp={userData} systemType="accounting"/>

                <SyncDataModal/>
                <SelectUnitType history={history}/>
                <AddLedgerModal terms={terms} brandNew={brandNew}/>

                <div className="container-fluid">
                    <div className="row full-width">
                        <div className="sidebar">
                            <div className="sidebar-content">
                                <MainSideBar page="ledger"/>
                            </div>
                        </div>
                        <SubSideBar history={history}/>
                        <div>
                            {/*sub-main-content-ledger*/}
                            <div className="sub-main-content-ledger">
                                {/* <div className="d-flex flex-row justify-content-left content-header">
                                     <input type="search" className="form-control form-control-lg align-items-center search" placeholder="&#xF002; Search" />
                                </div> */}
                                <div className="container">
                                    <div className="application-container2">
                                        <div className="form-inline">
                                            <div className="col-md-12 margin-bottom-minus-15">
                                                <h4>Search By Filter</h4>
                                                <br/>
                                            </div>
                                            <div className="col-md-4">
                                                <label>Name</label>
                                                <input
                                                    className="form-control full-width"
                                                    placeholder="Enter Fullname.."
                                                    // onChange={(e) => this.setState({customerName: e.target.value })}
                                                    ref="customer_name"
                                                    onKeyUp={(e) => this.advancedSearch()}
                                                />
                                            </div>
                                            <div className="col-md-4">
                                                <label>Account No.</label>
                                                <input
                                                    className="form-control full-width"
                                                    placeholder="Enter Account No.."
                                                    // onChange={(e) => this.setState({accountNumber: e.target.value })}
                                                    ref="account_number"
                                                    onKeyUp={(e) => this.advancedSearch()}
                                                />
                                            </div>

                                            {/* <div className="col-md-3">
                                            <label>Branch</label>
                                            <input className="form-control full-width" placeholder="Enter Branch.." onChange={(e) => this.setState({filter_color: e.target.value })}/>
                                        </div> */}
                                            <div className="col-md-4">
                                                <label>&nbsp;</label>
                                                <button className="btn btn-block btn-second-primary pull-right"
                                                        onClick={() => this.advancedSearch()}>Search
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="container-fluid">
                                    <div className="application-container">
                                        <div className="form-inline">
                                            <span className="global__header-label">Customer Ledger</span>
                                        </div>
                                        <div>
                                            <br/>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <table className="table tbl-ledger-wrapper tbl-customer-ledger tbl-mobile"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


const mapStateToProps = state => ({
    app: state.feathersClient.app,
    mainapp: state.feathersClient.mainapp,
    customers: state.customers.customersTerms,
    allCustomers: state.customers.allCustomers,
    userData: state.login.userData,
    userPermission: state.login.userPermission,
});

function mapDispatchToProps(dispatch) {
    return {actions: bindActionCreators(ActionCreators, dispatch)}
}

export default connect(mapStateToProps, mapDispatchToProps)(customer_ledger);
