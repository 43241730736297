import { combineReducers } from 'redux';
import login from './login';
import feathersClient from './feathers_config';
import category from './category';
import product from './product';
import users from './users';
import customers from './customers';
import dashboard from './dashboard';
import reports from './reports';

const rootReducer = combineReducers({
  	feathersClient,
  	login,
  	category,
  	product,
  	users,
  	customers,
  	dashboard,
  	reports,
})

export default rootReducer;