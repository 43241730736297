import React, {Component} from 'react';

import {Link} from 'react-router-dom';

class sub_sidebar extends Component {

    componentDidMount() {
        const permission = this.props.userPermission;
    }

    addUser = () => {
        const permission = this.props.userPermission;
        const pathName = this.props.history.location.pathname;

        if (pathName === '/user_new_user') {
            return;
        }

        if (permission.user === 2) {
            this.props.history.push('/user_new_user');
        }
    }

    userPermission = () => {
        this.props.history.push('/user_permissions');
    }

    roleLink = () => {
        const permission = this.props.userPermission;

        if (permission) {
            if (permission.user === 0) {
                return '#'
            } else {
                return '/user_permissions'
            }
        } else {
            return '/user_permissions'
        }

    }

    handleChange(value) {
        this.props.history.push(`${value}`);
    }

    render() {
        let x = {
            subnav: [
                {id: '/user', name: 'User List'},
                {id: this.roleLink(), name: 'Roles/ Permission'},
            ]
        };

        let subnav = x.subnav.length > 0
            && x.subnav.map((item, i) => {
                if (this.props.history.location.pathname === item.id) {
                    return (
                        <option key={i} value={item.id} selected>{item.name}</option>
                    )
                } else {
                    return (
                        <option key={i} value={item.id}>{item.name}</option>
                    )
                }

            }, this);

        return (
            <div>
                <div className="sub-sidebar">
                    {/*<ul className="nav flex-column list">*/}
                    {window.outerWidth <= 480 ?
                        <div className="sub-sidebar mobile-inv-dashboard__wrapper">
                            <ul className="list">
                                <li className="non-nav subheader__name">
                                    <h4>Users </h4>
                                </li>
                                <div className="mobile-dropdown__wrapper">
                                    <select className="dropdown__select"
                                            onChange={event => this.handleChange(event.target.value)}>
                                        {subnav}
                                    </select>
                                </div>
                            </ul>
                        </div> :
                        <ul className="list">
                            {/*<li className="nav-item non-nav subheader__name">*/}
                            <li className="non-nav subheader__name">
                                <h4>Users</h4>
                            </li>
                            {/*<li className="nav-item non-nav">
                            <button className="btn full-width" onClick={() => this.addUser()}>NEW USER</button>
                        </li>*/}
                            <li className="nav-item">
                                <Link to="/user"
                                      className={this.props.pageName === 'user' ? 'nav-link active-inventory' : 'nav-link'}>
                                    User List
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to={this.roleLink()}
                                      className={this.props.pageName === 'user_permission' ? 'nav-link active-inventory' : 'nav-link'}>Roles/
                                    Permission</Link>
                            </li>
                        </ul>
                    }
                </div>
            </div>
        );
    }
}

export default sub_sidebar;
