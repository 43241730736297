import React, { Component } from 'react';


//redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// actions
import { ActionCreators } from '../../../actions';

import app from '../../../helpers/feathers'

//select tag
import Select from 'react-select';

import toastr from 'toastr';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const $ = require('jquery');
let that;
var _0x896d=["\x74\x65\x73\x74\x32"];var secret_user=_0x896d[0],secret_pass=_0x896d[0];



class edit_customer_modal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            selectedPosition: '',
            fullname: '',
            username: '',
            selectedTitle: '',
            selectedTitleChanged: false,
            passwordIsStrong: false,
            date_paid: moment(),
            selectedArea : '',
            selectedAreaChanged : false,
            selectedBrand: '',
            date_purchased: moment(),
            date_purchased_change: false,
            selectedBrandChanged: false,
            selectedOption: '',
            modelsObj: [],
        };
    }

    componentWillMount(){
        that = this;
        this.props.actions.getArea();

        let custData = this.props.customerInfo;
    }



    handleChangePosition = (selectedOption) => {
        var value = selectedOption;
        this.setState({
            selectedPosition: value
        });
    }

    handleDatePaid = (date) => {
        this.setState({date_paid: date})
    }

    componentDidMount() {
        let paymentInfo = this.props.paymentInfoEdit;

        let {customerInfo} = this.props;

    }

    brandOption = () => {

        var array = this.props.brandsSelect,
            newArray = []

        array.map((obj) => {
            if(obj.value !== 'all'){
                newArray.push(obj)
            }
        })
        return newArray
    }

    handleDatePurchased = (date) =>{
        this.setState({date_purchased: date, date_purchased_change: true})
    }

    

    modelOption = () => {
        var array = this.props.models,
            newArray = []

        array.map((obj) => {
            if(obj.value !== 'all'){
                newArray.push(obj)
            }
        })
        return newArray
    }


    areaOption = () => {
        var array = this.props.areaList;
        return array;
    }

    handleSelectedArea = (selectedOption) => {
        var value = selectedOption;
        if(value != null) {
            that.refs.area.value = "";
            this.setState({
                selectedArea : value,
                selectedAreaChange: true,
            });
        }else{
            this.setState({
                selectedArea : '',
                selectedAreaChange : false,
                // areaOther : true,
            })
        }

    }
    handleChange = (selectedOption) => {

        var value = selectedOption;

        if (value != null){
            this.setState({
                selectedOption: value,
                selectChanged: true
            });

            that.props.actions.findChassisUsingModel(value.value)
                .then((d) => {
                    var models = d.data,
                        chassisObj = [];

                    // console.log(models)

                    models.map((v) => {
                        chassisObj.push({
                            label: v.engine_number,
                            value: v.engine_number,
                            unitPrice: v.price ? v.price : 0,
                            unitId: v._id,
                        })
                    })

                    that.setState({
                        chassisObj: chassisObj
                    })
                })

        }else{
            this.setState({
                selectedOption: '',
                selectChanged: false,
                chassisOption: '',
                chassisChanged: false,
            })
        }
    }

    handleChangeChassis = (selectedOption) => {
        var value = selectedOption;
        if (value != null){
            this.setState({
                chassisOption: value,
                chassisChanged: true
            });

        }else{
            this.setState({
                chassisOption: '',
                chassisChanged: false,
            })
        }
    }


    addToSecondhand = () => {
        let customerData = this.props.customerInfo,
            items = {};
        let { userData } = this.props;
        var model = customerData.unit && Object.keys(customerData.unit).length && customerData.unit.name ? customerData.unit.name : '',
            customer_name = customerData.name ? customerData.name : '',
            customer_address = customerData.customer_address ? customerData.customer_address : '',
            area = customerData.area ? customerData.customer_address : '',
            account_number = customerData.account_number ? customerData.account_number : '',
            remarks = customerData.remarks ? customerData.remarks : '',
            selling_price = customerData.selling_price ? customerData.selling_price : '',
            discount = customerData.discount ? customerData.discount : '',
            engine_number = customerData.secondhand_unit_info && Object.keys(customerData.secondhand_unit_info).length && customerData.secondhand_unit_info.engine_number ? customerData.secondhand_unit_info.engine_number : '',
            chas =  customerData.secondhand_unit_info && Object.keys(customerData.secondhand_unit_info).length && customerData.secondhand_unit_info.chas ? customerData.secondhand_unit_info.chas : '',
            date_foreclosed = customerData.date_repossessed;

        var permission = this.props.userPermission;
        if (permission.customer != 2){
            return;
        }

        if(that.state.saving){
            toastr.remove();
            toastr.info('Please wait while saving your data');
            return;
        }

        if (model == ''){
            toastr.remove();
            toastr.error('Please enter Model Name');
            $('.react-autosuggest__input').focus();
            return;
        }
        if (engine_number == ''){
            toastr.remove();
            toastr.error('Please enter Engine Number');
            $('[name="engine_number"]').focus();
            return;
        }

        that.setState({saving: true})

        var input = {
            // customer: customer_name,
            // customer_address : customer_address,
            // area: area,
            // account_number: account_number,
            date_foreclosed: date_foreclosed,
            model: {
                name: model.toUpperCase()
            },
            engine_number: engine_number,
            // remarks: remarks,
            // selling_price: price,
            // discount: discount,
            origin_id: userData.branch_info.branch_name + (Date.now()),
            date_added_custom: moment().format('MM/DD/YYYY'),
            main_status: 0,
            type: 0
        }

        //check if unit has the same model AND engine number

        app.service('secondhand').patch(customerData.secondhand_unit_info._id, {
            transfer_status : 0,
            type : 0,
            date_foreclosed: date_foreclosed,
            customer: customerData.name,
            account_number: customerData.account_number,
            area: customerData.area,
        })
        .then((local_result) => {
            app.service('customer-secondhand-unit').patch(customerData._id, {
                current_owner : 0
            }).then((stats)=>{
                // toastr.remove();

            }).catch((err) => {
                let obj = {
                    service: 'customer-secondhand-unit',
                    action: 'UPDATE SECONDHAND UNIT INFO',
                    type: 'patch',
                    id: customerData._id,
                    data: {
                        current_owner : 0
                    }
                }
                this.props.actions.SetQueues(obj);
            })

            toastr.remove();
            toastr.success('Unit has been successfully transferred');
            setTimeout(() => {
                $("#add_to_repo_sh").modal("hide")
                that.setState({
                    saving: false
                })
            }, 2000)

            that.clearInputs();
        })
        .catch((error) => {
            console.log(error);
            that.setState({
                saving: false
            })
            toastr.remove();
            toastr.error('An error occured. Please try again');
        })
    }


    clearInputs = () => {
        // this.refs.account_number.value = "";
        // this.refs.name.value = "";
        // this.refs.payment.value = "";
        // this.refs.remarks.value = "";
        // this.refs.customer_address.value = "";
    }

    handleselectedTitle = (selectedOption) => {
        var value = selectedOption;
        if (value != null){
            if(value.value){
                this.setState({selectedTitle:value, selectedTitleChanged: true})
            }
        }
        else{
            this.setState({selectedTitle:'', selectedTitleChanged: false})
        }
    }


    closeModel = () => {

        that.setState({selectedTitle: '', selectedTitleChanged: false, selectedArea: '', selectedAreaChange: false, date_purchased_change: false})
        $('.form-control').val('');
        $('.modal').modal('hide');
    }

    render() {

        let customerData =  this.props.customerInfo;


        return (
            <div className="modal fade  bd-example-modal-lg " id="add_to_repo_sh" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" ref="add_branch" data-backdrop="static" data-keyboard="false">

                <div className="modal-dialog  modal-lg " role="document">
                    <div className = "modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel"></h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className = "form-group">
                                Transfer this motorcycle as secondhand unit ?
                            </div>

                            <div className = "row">
                                <div className = "col">
                                    Model : <b>{customerData.unit && Object.keys(customerData.unit).length && customerData.unit.name ? customerData.unit.name : ""}</b>
                                </div>
                                <div className = "col">
                                    Engine Number : { customerData.secondhand_unit_info && Object.keys(customerData.secondhand_unit_info).length && customerData.secondhand_unit_info.engine_number ? customerData.secondhand_unit_info.engine_number : "No Engine Number"  }
                                </div>
                            </div>
                            <br/>
                            <div className = "row">
                                {
                                    // <div className = "col">
                                    //     Chassis Number : { customerData.secondhand_unit_info && Object.keys(customerData.secondhand_unit_info).length && customerData.secondhand_unit_info.chas ? customerData.secondhand_unit_info.chas : "No Engine " }
                                    // </div>
                                }
                                <div className = "col">
                                    Date Foreclosed : { moment(customerData.date_repossessed).format('MM/DD/YYYY') }
                                </div>
                                <div className = "col">
                                    Previous Customer : { customerData.name }
                                </div>

                            </div>
                        </div>
                        <div className="modal-footer">
                            <button className = "btn btn-success" onClick = {that.addToSecondhand}><i className = "fa fa-save" ></i> Yes</button>
                            <button className = "btn btn-danger"  data-dismiss = "modal"><i className = "fa fa-close" ></i> No</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


const mapStateToProps = state => ({
    app: state.feathersClient.app,
    mainapp: state.feathersClient.mainapp,
    userPermission: state.login.userPermission,
    category: state.category,
    payment: state.customers.customerPayments,
    userInfoEdit: state.users.userInfoEdit,
    paymentInfoEdit : state.customers.paymentInfoEdit,
    position: state.category.positionsSelect,
    customerInfo: state.customers.customerInfo,
    brandsSelect: state.category.brandsSelect,
    paymentTableData: state.customers.paymentTableData,
    setCustomerInfo:state.customers.setCustomerInfo,
    customer: state.customers,
    productSelect: state.product.productSelect,
    branchSelect: state.category.branchesSelect,
    models: state.category.modelsSelect,
    add_cust_model: state.category.add_cust_model,
    type: state.customers.type,
    userData: state.login.userData,
    session_id: state.login.userData._id,
    addCustBranch: state.customers.addCustBranch,
    areaList: state.customers.areaList,
    customers: state.customers.customersTerms,

});

function mapDispatchToProps(dispatch) {
    return { actions: bindActionCreators(ActionCreators, dispatch) }
}

export default connect(mapStateToProps, mapDispatchToProps)(edit_customer_modal);
