import React, {Component} from 'react';

//redux
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
// actions
import {ActionCreators} from '../../../actions';

//main NavBar
import MainNav from '../../../components/global_nav';
import MainSideBar from '../../../components/global_sidebar';
//sub side bar
import SubSideBar from './sub_sidebar';

// UserPositions
import UserPositions from './user_positions';

import AddModelModal from './add_model_modal';
import AddBranchModal from './add_branch_modal';
import AddBrandModal from './add_brand_modal';
import AddPositionModal from './add_position_modal';

import UpdateModelModal from './update_model_modal';
import ClearDataModal from "./clear_data_modal";

class category_users extends Component {

    constructor(props) {
        super(props);

        this.state = {
            activeTab: 'user_position'
        }
    }

    navigateTab = (tabName) => {
        this.setState({
            activeTab: tabName
        })
    }

    render() {
        const modal = this.props.userPermission.category === 2;
        return (
            <div className="">
                {/*==== main NavBar component ===*/}
                <MainNav historyProp={this.props.history} usersProp={this.props.userData}/>
                {/*==== add_category_modals ===*/}
                <AddModelModal/>
                <AddBranchModal/>
                <AddBrandModal/>
                <AddPositionModal/>
                {/*==== update_category_modal ===*/}
                <UpdateModelModal/>
                {/*====================*/}
                <ClearDataModal/>
                <div className="container-fluid">
                    <div className="row full-width">
                        <div className="sidebar">
                            <div className="sidebar-content">
                                <MainSideBar page="category"/>
                            </div>
                        </div>
                        <SubSideBar history={this.props.history} pageName="users"
                                    userPermission={this.props.userPermission}/>
                        <div className="sub-inv-main-content">
                            <div className="container-fluid">
                                <div className="application-container">
                                    <div className="row">
                                        <div className="form-inline col-md-6">
                                            <h4 className="subheader-title">List of User Positions</h4>
                                        </div>
                                        <div className="col-md-3">
                                            <button className="btn btn-second-primary add-new-btn"
                                                    data-toggle={modal ? "modal" : '#'} data-target="#add_position">
                                                <span><i className="fa fa-plus"/> Add </span>
                                            </button>
                                        </div>
                                        <button className="btn btn-primary d-none btn-block" data-toggle="modal"
                                                data-target="#update_userDesignation" ref="userDesignation"><span
                                            className="fa fa-plus"/> Add
                                        </button>
                                    </div>
                                    {/*<ul className="nav nav-tabs" role="tablist">
                                    <li role="presentation"
                                        className={this.state.activeTab === 'user_position' ? 'active' : ''}><a
                                        href="#user_position" onClick={() => this.navigateTab('user_position')}
                                        aria-controls="user_position" role="tab" data-toggle="tab">User's Position</a>
                                    </li>
                                </ul>*/}
                                    <div className="tab-content">
                                        <div role="tabpanel"
                                             className={"tab-pane" + (this.state.activeTab === 'user_position' ? 'active' : '')}
                                             id="user_position">
                                            <UserPositions/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    app: state.feathersClient.app,
    category: state.category,
    userData: state.login.userData,
    userPermission: state.login.userPermission,
});

function mapDispatchToProps(dispatch) {
    return {actions: bindActionCreators(ActionCreators, dispatch)}
}

export default connect(mapStateToProps, mapDispatchToProps)(category_users);