import React, { Component } from 'react';

//redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// actions
import { ActionCreators } from '../../../actions';

//main NavBar
import MainNav from '../../../components/global_nav';
import MainSideBar from '../../../components/global_sidebar';

//custom sidebar
import SubSideBar from './sub_sidebar';

//select tag
import Select from 'react-select';

//custom styles
import '../../../stylesheet/styles.css';

//datatables
import '../../../stylesheet/datatables/datatables.css';
const $ = require('jquery');
$.DataTable = require('datatables.net');

class inventory_filter extends React.PureComponent {

    constructor(props) {
        super(props);
    
        this.state = {
            selectedModel: this.props.selectedModelFilter ? this.props.selectedModelFilter : '',
            selectedBrand: this.props.selectedBrandFilter ? this.props.selectedBrandFilter : '',
            modelList: [],
            models: [],
            selectedBranch: this.props.selectedBranchFilter ? this.props.selectedBranchFilter : '',
            filter_model: this.props.selectedModelFilter ? this.props.selectedModelFilter : '',
            filter_color: this.props.selectedColorFilter ? this.props.selectedColorFilter : null,
            filter_branch: this.props.selectedBranchFilter ? this.props.selectedBranchFilter : null,
            filter_brand: this.props.selectedBrandFilter ? this.props.selectedBrandFilter : null,
            selectBrandChanged: this.props.selectedBrandFilter ? true : false,
            selectModelChanged: this.props.selectedModelFilter ? true : false,
            modifiedModels: '',
        };
    }

    componentWillMount() {

        // manually insert 'all' option in branches
        var ckr = [];
        this.props.branch.map(item => {
            ckr.push(item.value)
        })
        var branchExists = ckr.includes("all");
        if (!branchExists){
            this.props.branch.unshift({
                value: 'all',
                label: 'All'
            })
        }

        this.setState({
            modelList: this.props.selectedModelSet,
        })

        var models = [],
            that = this;
            
        this.props.actions.getCategoryModels().then((data) => {
            that.props.actions.setModels(data);
        });

        if(this.props.models){
            this.props.models.forEach((value, index) => {
                models.push({
                    value: value[0],
                    label: value[2]
                })
            });
        }

        this.setState({
            models: models
        })

        var objct = this.props.selectmodellist;

        // manually insert 'all' option in models
        var ckrModel = [];
        objct.map(item => {
            ckrModel.push(item.value)
        })
        var modelExists = ckrModel.includes("all");

        if (!modelExists){
            objct.unshift({
                value: 'all',
                label: 'All'
            })
        }
        this.setState({modifiedModels: objct})
        // this.props.advancedFilter ? '' : this.createListener(this.props.selectedModel);

    }

    componentDidMount() {
        var that = this;
        that.dataTableAction();
        let app = that.props.app,
            productService = app.service('products');

            productService.on('created', function(){
                that.createListener(that.props.selectedModel);
            })
    }

    createListener = (data) => {
        var that = this; 
        that.props.actions.getSelectedModel(data, false, null, null, null).then((products) => {
            that.props.actions.selectedModelSet(products)
            that.updateTable(products);
        })
    }

    updateTable = (data) => {
        this.setState({
            modelList: data
        });

        const table = $('.tbl-inventory-filter').DataTable();
        table.clear();
        table.rows.add(data);
        table.draw();
    }

    dataTableAction = () => {
        var inventoryFilterTbl = $('.tbl-inventory-filter').DataTable({
            data: this.state.modelList,
            columns: [
                { "title": "id" },
                { "title": "ENGINE NO." },
                { "title": "CHASSIS NO." },
                { "title": "COLOR" },
                { "title": "DATE DELIVERED" },
                { "title": "BRANCH" },
                { "title": "ACTION" },
                { "title": "PRICE" },
            ],
            columnDefs: [
                {
                    targets: [0,7],
                    visible: false
                },
                {
                    targets: 6,
                    orderable: false,
                    width: '10%'
                }
            ],
            "sDom": '<"bottom"<t>ip><"clear">',
            "order": [[4, 'desc']]
        });

        $('.search').keyup(function(){
            inventoryFilterTbl.search($(this).val()).draw() ;
        });

        var initialNum = 0,
            uniqueId = 0, prevId = 0;

        $('.tbl-inventory-filter').on('click', '.filtered-models', function(){
            var data = inventoryFilterTbl.row( $(this).parents('tr') ).data();
            if (uniqueId === 0){
                uniqueId = data[0]
                $('.filtered-models').html('More')
                $('.inserted-tr').remove();
                $(this).html('Less')
                $(this).parents('tr').after(function(){
                    return (
                        '<tr class="inserted-tr">'+
                        '<td><strong>PRICE: '+(data[7]).toLocaleString()+'</strong></td>'+
                        '</tr>'+
                        '<tr class="inserted-tr"><td></td></tr>'
                    )
                })
                prevId = data[0]
            }else{
                if (uniqueId === data[0]){
                    $(this).html('More')
                    $('.inserted-tr').remove();
                    uniqueId = 0;
                    return;
                }
                $('.inserted-tr').remove();
                if (uniqueId === prevId){
                    // prevId = 0
                    $('.filtered-models').html('More')
                    $('.inserted-tr').remove();
                    $(this).html('Less')
                    $(this).parents('tr').after(function(){
                        return (
                            '<tr class="inserted-tr"><td><strong>PRICE: '+(data[7]).toLocaleString()+'</strong></td></tr>'+
                            '<tr class="inserted-tr"><td></td></tr>'
                        )
                    })
                    uniqueId = 0;
                }
            }
        })
    }

    handleChangeBrand = (selectedOption) => {
        var value = selectedOption;

        if (value != null){
            this.setState({
                selectedBrand: value,
                filter_brand: value,
                selectBrandChanged: true
            });
        }else{
            this.setState({
                selectedBrand: '',
                filter_brand: value,
                selectBrandChanged: false
            })
        }
    }

    handleChange = (selectedOption) => {
        var value = selectedOption;

        if (value != null){
            this.setState({
                selectedModel: value,
                filter_model: value,
                selectModelChanged: true
            });
        }else{
            this.setState({
                selectedModel: '',
                filter_model: value,
                selectModelChanged: false
            })
        }
    }

    handleChangeBranch = (selectedOption) => {
        var value = selectedOption;

        if (value === null){
            this.setState({
                selectedBranch: '',
                filter_branch: value,
                selectBranchChanged: false
            });
        }else{
            this.setState({
                selectedBranch: value,
                filter_branch: value,
                selectBranchChanged: true
            });
        }
    }

    handleChangeColor = (e) => {
        var that = this;
        that.setState({filter_color: e.target.value })
        that.props.actions.setselectedFilters(this.state.filter_model, this.state.color)
    }

    advancedFilter = () => {

        var that = this,
            model = this.state.filter_model === null ? '' : this.state.filter_model.value,
            color = this.state.filter_color === 'All' ? null : this.state.filter_color,
            branch = this.state.filter_branch === null ? '' : this.state.filter_branch.value,
            brand = this.state.filter_brand === null ? '' : this.state.filter_brand.value;

            if (!color && !model && !branch && !brand) return;
            that.props.actions.getSelectedModel(true, true, model, color, branch, brand).then((products) => {
                that.props.actions.setselectedFilters(this.state.filter_model, color, branch)
                that.props.actions.advancedFilter(true)
                that.props.actions.selectedModelSet(products)
                that.updateTable(products)
            })
    }
    permissionInventory = () => {
        var permission = this.props.userPermission;

        if (permission.inventory === 0){
            return "not-visible"
        }else{
            return "";
        }
    }

    render() {
        return (
            <div className="">
                {/* main NavBar component*/}
                <MainNav historyProp={this.props.history} usersProp={this.props.userData}  />
                
                <div className="container-fluid">
                    <div className="row full-width">
                        <div className="sidebar">
                            <div className="sidebar-content">
                                <MainSideBar page="inventory"/>
                            </div>
                        </div>
                        <SubSideBar history={this.props.history} userPermission={this.props.userPermission} />
                        <div className="sub-inv-main-content">
                            <div className="container-fluid">
                                {
                                    this.props.userPermission.inventory === 0 ?
                                    <div className="row no-access-div">
                                        <div className="col-md-12">
                                            <h1>Access denied</h1>
                                        </div>
                                    </div>
                                    : null
                                }
                                <div className={this.permissionInventory()}>
                                    <div className="row background-white">
                                        <div className="d-flex flex-row justify-content-left content-header">
                                             <input type="search" className="form-control form-control-lg align-items-center search" placeholder="&#xF002; Search" />
                                        </div>
                                    </div>
                                    <div className="row margin-20 padding-20">
                                        <div className="col-md-12">
                                            <div className="row">
                                                <div className="col-md-1">
                                                    <span className="fa fa-angle-left fa-4x" onClick={() => this.props.history.push('/inventory/')}></span>
                                                </div>
                                                <div className="col-md-2">
                                                    <p className="text-gray">BRAND</p>
                                                    <Select
                                                        name="model"
                                                        value={this.state.selectedBrand.value}
                                                        onChange={this.handleChangeBrand}
                                                        options={this.props.brandsSelect}
                                                        clearableValue={true}
                                                        placeholder="Select Brand"
                                                    />
                                                </div>
                                                <div className="col-md-2">
                                                    <p className="text-gray">MODEL</p>
                                                    <Select
                                                        name="model"
                                                        value={this.state.selectedModel.value}
                                                        onChange={this.handleChange}
                                                        options={this.props.selectmodellist}
                                                        clearableValue={true}
                                                        placeholder="Select Model"
                                                    />
                                                </div>
                                                <div className="col-md-2">
                                                    <p className="text-gray">COLOR</p>
                                                    <input
                                                        className="form-control full-width"
                                                        placeholder={this.props.selectedColorFilter ? this.props.selectedColorFilter : "All"}
                                                        onChange={(e) => this.handleChangeColor(e)}
                                                    />
                                                </div>
                                                <div className="col-md-2">
                                                    <p className="text-gray">BRANCH</p>
                                                    <Select
                                                        name="select-branch"
                                                        value={this.state.selectedBranch.value}
                                                        onChange={this.handleChangeBranch}
                                                        options={this.props.branch}
                                                        placeholder="Select Branch"
                                                    />
                                                </div>
                                                <div className="col-md-1">
                                                    <p className="text-gray">QTY</p>
                                                    <p className="">{this.state.modelList ? this.state.modelList.length : '' }</p>
                                                </div>
                                                <div className="col-md-2">
                                                    <p>&nbsp;</p>
                                                    <button className="btn btn-primary" onClick={() => this.advancedFilter() }>Search</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row margin-20 padding-20">
                                        <div className="col-md-12 background-white">
                                            <table className="table tbl-inventory-filter tbl-mobile">
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


const mapStateToProps = state => ({
    app: state.feathersClient.app,
    models: state.category.models_list,
    brandsSelect: state.category.brandsSelect,
    selectmodellist: state.category.modelsSelect,
    product: state.product,
    selectedModel: state.product.selectedModel,
    selectedModelSet: state.product.selectedModelSet,
    advancedFilter: state.product.advanced_filter,
    branch: state.category.branchesSelect,
    selectedColorFilter: state.product.selectedColorFilter,
    selectedModelFilter: state.product.selectedModelFilter,
    selectedBranchFilter: state.product.selectedBranchFilter,
    selectedBrandFilter: state.product.selectedBrandFilter,
    userData: state.login.userData,
    userPermission: state.login.userPermission,
});

function mapDispatchToProps(dispatch) {
   return { actions: bindActionCreators(ActionCreators, dispatch) }
}

export default connect(mapStateToProps, mapDispatchToProps)(inventory_filter);