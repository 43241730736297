import React, {Component} from 'react';

//redux
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
// actions
import {ActionCreators} from '../../../actions';

//main NavBar
import MainNav from '../../../components/global_nav';
import MainSideBar from '../../../components/global_sidebar';
//sub side bar
import SubSideBar from './sub_sidebar';

// models
import Models from './models';
// Branch
import Branch from './branch';
// brands
import Brands from './brands';

import AddModelModal from './add_model_modal';
import AddBranchModal from './add_branch_modal';
import AddBrandModal from './add_brand_modal';
import AddPositionModal from './add_position_modal';

import UpdateModelModal from './update_model_modal';
import UpdateBranchModal from './update_branch_modal';
import UpdateBrandModal from './update_brand_modal';
import UpdateDesignationModal from './update_designation_modal';

import DeleteModelModal from './delete_model_modal';
import DeleteBranchModal from './delete_branch_modal';
import DeleteBrandModal from './delete_brand_modal';

import ClearDataModal from './clear_data_modal';
import ConfirmationDelete from "../inventory/confirmation_delete";

class category extends Component {

    constructor(props) {
        super(props);

        this.state = {
            activeTab: 'brands'
        }
    }

    navigateTab = (tabName) => {
        this.setState({
            activeTab: tabName
        })
    }

    componentWillMount() {
    }

    componentDidMount() {
        this.props.actions.getAllBrands().then((data) => {
            if (data) {
                this.props.actions.setBrands(data);
            }
        });

        //models
        this.props.actions.getCategoryModels().then((data) => {
            if (data) {
                this.props.actions.setModels(data);
            }
        });
    }

    permissionCategory = () => {
        const permission = this.props.userPermission;

        if (permission.category === 0) {
            return "not-visible"
        } else {
            return "";
        }
    }

    render() {
        return (
            <div className="">
                {/*==== main NavBar component ===*/}
                <MainNav historyProp={this.props.history} usersProp={this.props.userData}
                         userPermission={this.props.userPermission}/>
                {/*==== add_category_modals ===*/}
                <AddModelModal/>
                <AddBranchModal/>
                <AddBrandModal/>
                <AddPositionModal/>
                {/*==== update_category_modal ===*/}
                <UpdateModelModal/>
                <UpdateBranchModal/>
                <UpdateBrandModal/>
                <UpdateDesignationModal/>
                {/*==== delete_category_modal ===*/}
                <DeleteModelModal/>
                <DeleteBranchModal/>
                <DeleteBrandModal/>
                <ClearDataModal/>
                {/*====================*/}
                <ConfirmationDelete/>

                <div className="container-fluid">
                    <div className="row full-width">
                        <div className="sidebar">
                            <div className="sidebar-content">
                                <MainSideBar page="settings"/>
                            </div>
                        </div>
                        <SubSideBar history={this.props.history} pageName="settings"
                                    userPermission={this.props.userPermission}/>
                        {
                            this.props.userPermission.category === 0 ?
                                <div className="sub-inv-main-content">
                                    <div className="no-access-div">
                                        <div className="col-md-12">
                                            <h1>Access denied</h1>
                                        </div>
                                    </div>
                                </div>
                                : null
                        }
                        <div className={this.permissionCategory()}>
                            <div className="sub-inv-main-content">
                                <div className="container-fluid">
                                    <div className="application-container">
                                        <div className="form-inline">
                                            <h4 className="subheader-title">List of Brands, Models and Branches</h4>
                                        </div>
                                        <ul className="nav nav-tabs" role="tablist">
                                            <li role="presentation"
                                                className={this.state.activeTab === 'brands' ? 'active' : ''}
                                                onClick={() => this.navigateTab('brands')}><a
                                                href="#brands"
                                                aria-controls="brands" role="tab" data-toggle="tab">Brands</a></li>
                                            <li role="presentation"
                                                className={this.state.activeTab === 'models' ? 'active' : ''}
                                                onClick={() => this.navigateTab('models')}><a
                                                href="#models"
                                                aria-controls="models" role="tab" data-toggle="tab">Models</a></li>
                                            <li role="presentation"
                                                className={this.state.activeTab === 'branches' ? 'active' : ''}
                                                onClick={() => this.navigateTab('branches')}><a
                                                href="#branches"
                                                aria-controls="branches" role="tab" data-toggle="tab">Branches</a></li>
                                        </ul>
                                        <div className="tab-content">
                                            <div role="tabpanel"
                                                 className={"tab-pane" + (this.state.activeTab === 'models' ? 'active' : '')}
                                                 id="models">
                                                <Models/>
                                            </div>
                                            <div role="tabpanel"
                                                 className={"tab-pane" + (this.state.activeTab === 'brands' ? 'active' : '')}
                                                 id="brands">
                                                <Brands/>
                                            </div>
                                            <div role="tabpanel"
                                                 className={"tab-pane" + (this.state.activeTab === 'branches' ? 'active' : '')}
                                                 id="branches">
                                                <Branch/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    app: state.feathersClient.app,
    category: state.category,
    userData: state.login.userData,
    userPermission: state.login.userPermission,
});

function mapDispatchToProps(dispatch) {
    return {actions: bindActionCreators(ActionCreators, dispatch)}
}

export default connect(mapStateToProps, mapDispatchToProps)(category);
