import React, {Component} from 'react';


//redux
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
// actions
import {ActionCreators} from '../../../actions';

import app from '../../../helpers/feathers'

//select tag
import Select from 'react-select';

import toastr from 'toastr';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const $ = require('jquery');

var that

class edit_payment_modal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            selectedPosition: '',
            fullname: '',
            username: '',
            passwordIsStrong: false,
            date_paid_change: false,
            date_paid: moment(),
        };
    }

    componentWillMount() {
        that = this;


    }


    handleChangePosition = (selectedOption) => {
        var value = selectedOption;
        this.setState({
            selectedPosition: value
        });
    }

    closeModal = () => {
        //  	this.refs.cancel.click();

        //  	this.refs.user_name.value = '';
        //  	this.refs.user_username.value = '';
        //  	this.refs.user_password.value = '';
        //  	this.setState({
        //  		selectedPosition: '',
        // fullname: '',
        // username: '',
        //  	})
    }


    handleDatePaid = (date) => {

        this.setState({date_paid: date, date_paid_change: true})
    }

    componentDidMount() {
        let paymentInfo = this.props.paymentInfoEdit;
        console.log('DATA OF PAYMENT', paymentInfo)

    }

    passwordStrength = (e) => {
        var regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{6,}$/;
        var val = e.target.value;
        var m = val.match(regex);
        var matched = m ? true : false;

        that.setState({
            passwordIsStrong: matched
        })
    }

    disablefields = () => {

        let deb = this.props.payment.debit;
        return deb ? true : false
    }

    disablePaid = () => {
        let paid = this.props.payment.amount_paid;
        return paid ? true : false
    }


    updateLedger = () => {
        let {remainingBalance, ledgerItemId, customer, session_id} = this.props,
            account_number = customer.account_number,
            items = {};
        var that = this,
            amount_paid = this.refs.amount_paid.value,
            rebate = this.refs.rebate.value,
            date_paid = this.state.date_paid,
            remarks = this.refs.remarks.value;

        if (amount_paid === '' || amount_paid === null) {
            toastr.info('Please enter amount paid');
            return;
        }

        if (isNaN(amount_paid)) {
            toastr.info('Amount paid is invalid');
            return;
        }

        if (rebate !== '' && rebate !== null) {
            var balance = parseInt(remainingBalance) - (parseInt(amount_paid) + parseInt(rebate))
        } else {
            var balance = parseInt(remainingBalance) - parseInt(amount_paid)
        }

        items['amount_paid'] = amount_paid;
        items['date_paid'] = date_paid;
        items['rebate'] = rebate;
        items['balance'] = balance;
        items['remarks'] = remarks;
        items['updated_by'] = session_id;
        items['elapsed'] = 1;

        that.props.actions.updateLedgerItem(ledgerItemId, items, account_number)
            .then((res) => {
                that.closeModal();

                if (res.status) {
                    toastr.success("Ledger has been successfully updated");
                } else {
                    toastr.error("Failed to update ledger");
                }
            })
            .catch(() => {
                toastr.error("Failed to update ledger");
            })
    }


    editPayment = () => {

        const that = this;

        let val = this.props.payment;

        var or_number = this.refs.or_number.value,
            amount_paid = this.refs.amount_paid.value.trim(),
            date_paid = this.state.date_paid,
            date_paid_formatted = moment(this.state.date_paid).format('MM/YYYY'),
            rebate = this.refs.rebate.value.trim(),
            debit = this.refs.debit.value.trim(),
            overdue_payment = this.refs.overdue_payment.value,
            remarks = this.refs.remarks.value;
        let myQuery = {};

        let {date_paid_change} = this.state,
            current_amount_paid = this.props.payment.amount_paid,
            current_rebate = this.props.payment.rebate;

        if (debit) {
            myQuery.debit = debit

            if (date_paid_change) {
                myQuery.date_paid = date_paid
            }
            if (remarks) {
                myQuery.remarks = remarks
            }

        } else {
            if (!or_number && !date_paid_change && !remarks && !amount_paid && !rebate) {
                toastr.info('No changes made')
                return
            }
            if (date_paid == null) {
                toastr.info('Invalid date')
                return
            }

            if (or_number || date_paid_change || remarks) {
                if (or_number) {
                    myQuery.or_number = or_number
                }
                if (date_paid_change) {
                    myQuery.date_paid = date_paid
                }
                if (remarks) {
                    myQuery.remarks = remarks
                }
            } else {
                if (amount_paid === "") {
                    myQuery.amount_paid = current_amount_paid

                    if (rebate == "") {
                        toastr.error('CR or Rebate value is empty')
                        return
                    } else if (rebate == 0) {
                        myQuery.rebate = rebate

                    } else {
                        myQuery.rebate = rebate
                        myQuery.total_paid = Number(current_amount_paid) + Number(rebate)
                    }
                } else if (amount_paid == 0) {
                    myQuery.total_paid = 0
                    myQuery.rebate = 0
                    myQuery.amount_paid = 0
                } else {

                    if (rebate == "") {
                        myQuery.rebate = current_rebate
                        myQuery.amount_paid = amount_paid
                        myQuery.total_paid = Number(amount_paid) + Number(current_rebate)
                    } else if (rebate == 0) {
                        myQuery.rebate = rebate
                        myQuery.amount_paid = amount_paid
                        myQuery.total_paid = Number(amount_paid)
                    } else {
                        myQuery.amount_paid = amount_paid
                        myQuery.rebate = rebate
                        myQuery.total_paid = Number(amount_paid) + Number(rebate)
                    }
                }
            }
        }


        this.props.actions.updatePayment(this.props.payment._id, myQuery)
            .then((res) => {

                that.setState({date_paid_change: false})

                that.closeModel();
                if (res) {
                    var payment_id = res._id,
                        origin_id = res.origin_id,
                        account_number = res.account_number,
                        promi = res.customer_info ? res.customer_info.promisory_note : '';

                    app.service('customer-payments').find({
                        query: {
                            _id: payment_id
                        }
                    })
                        .then((data) => {
                            if (data.total) {
                                let tpaid = this.props.payment.total_paid,
                                    total_payments = this.props.total_payments;
                                var custID = data.data[0]._id;
                            }
                        })

                    toastr.success('Payment successfully updated!');

                } else {
                    toastr.error('Payment Update Error!');
                    that.closeModel()
                }
            })


    }


    closeModel = () => {

        $('.form-control').val('');
        $('.modal').modal('hide');
    }


    render() {


        return (
            <div className="modal fade  bd-example-modal-sm " id="edit" tabIndex="-1" role="dialog"
                 aria-labelledby="exampleModalLabel" aria-hidden="true" ref="add_branch" data-backdrop="static"
                 data-keyboard="false">

                <div className="modal-dialog  modal-sm " role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Edit Payment</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="form-group">
                                <label>Date Paid - {moment(this.props.payment.date_paid).format('MM/DD/YYYY')} </label>
                                <br/>
                                <DatePicker
                                    selected={this.state.date_paid}
                                    onChange={this.handleDatePaid}
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    className="form-control"
                                />
                            </div>


                            <div className="form-group">

                                <label>OR Number : {this.props.payment.or_number} </label>
                                <input id="payment_input" className="form-control" ref="or_number"
                                       disabled={this.disablefields()}/>
                            </div>
                            <div className="form-group">
                                <label> DR - {this.props.payment.debit} </label>
                                <input id="payment_input" className="form-control" ref="debit"
                                       disabled={this.disablePaid()}/>
                            </div>
                            <div className="form-group">
                                <label> CR - {this.props.payment.amount_paid} </label>
                                <input id="payment_input" className="form-control" ref="amount_paid"
                                       disabled={this.disablefields()}/>
                            </div>

                            <div className="form-group">
                                <label>Rebate - {this.props.payment.rebate}</label>
                                <input id="payment_input" className="form-control" ref="rebate"
                                       disabled={this.disablefields()}/>
                            </div>


                            <div className="form-group">

                                <input type="hidden" id="payment_input" className="form-control" ref="overdue_payment"
                                       disabled={this.disablefields()}/>
                            </div>


                            <div className="form-group">
                                <label>Remarks - {this.props.payment.remarks}</label>
                                <input id="payment_input" className="form-control" ref="remarks"/>
                            </div>


                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" onClick={this.closeModel}>Cancel
                            </button>
                            <a data-dismiss="modal" ref="cancel"></a>
                            <button type="button" className="btn btn-primary" onClick={this.editPayment}>Save</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


const mapStateToProps = state => ({
    app: state.feathersClient.app,
    mainapp: state.feathersClient.mainapp,
    category: state.category,
    payment: state.customers.customerPayments,
    userInfoEdit: state.users.userInfoEdit,
    paymentInfoEdit: state.customers.paymentInfoEdit,
    position: state.category.positionsSelect,
    paymentTableData: state.customers.paymentTableData,
    customer: state.customers.customerLedgerInfo,
    customerInfo: state.customers.customerInfo,
    session_id: state.login.userData._id,
    ledgerItemId: state.customers.ledgerItemId,
    total_payments: state.customers.total_payments,
    remainingBalance: state.customers.remainingBalance,
});

function mapDispatchToProps(dispatch) {
    return {actions: bindActionCreators(ActionCreators, dispatch)}
}

export default connect(mapStateToProps, mapDispatchToProps)(edit_payment_modal);
