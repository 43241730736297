import React, {Component} from 'react';

//redux
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
// actions
import {ActionCreators} from '../../../actions';

//select tag
import Select from 'react-select';
import 'react-select/dist/react-select.css';

//main NavBar
import MainNav from '../../../components/global_nav';
import MainSideBar from '../../../components/global_sidebar';

import moment from 'moment';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import '../report/plugins/daterangepicker.css';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

//custom sidebar
import SubSideBar from './sub_sidebar';

import app from '../../../helpers/feathers'

//custom helpers
import {
    numberWithCommas
} from '../../../helpers/';

import toastr from 'toastr';

const $ = require('jquery');

toastr.options = {
    "positionClass": "toast-bottom-right"
}

class update_inventory extends Component {
    constructor(props) {
        super(props);

        this.state = {
            brand: null,
            model: null,
            engine_number: '',
            chassis_number: '',
            color: '',
            date_delivered: moment(),
            location: null,
            delivery_receipt_number: '',
            delivery_receipt_date: moment(),
            invoice_number: null,
            invoice_date: moment(),
            price: '',
            saveTemplate: false,
            models: this.props.models,
            selectedOption: '',
            selectChanged: false,
            selectedBranch: '',
            selectedBranchChanged: false,
            selectedBrand: '',
            selectedBrandChanged: false,
            warranty_claims: this.props.updateUnitInfo.warranty_claims,
            clearances: this.props.updateUnitInfo.clearances,
            tba: this.props.updateUnitInfo.tba,
            warranty_claims_is_changed: false,
            tba_is_changed: false,
            clearances_is_changed: false,
            date_delivered_changed: false,
            invoice_date_changed: false,
            delivery_receipt_date_changed: false,
            changes_made: false,
            saving: false,
        };
    }

    componentWillMount() {
    }

    componentDidMount() {
        if (this.state.warranty_claims) {
            this.refs.warranty_claims.click();
        }
        if (this.state.clearances) {
            this.refs.clearances.click();
        }
        if (this.state.tba) {
            this.refs.tba.click();
        }
    }

    hasWhiteSpace = (s) => {
        if (s != null) {
            return s.indexOf(' ') >= 0;
        }
    }

    singleToast = (text) => {
        toastr.remove();
        toastr.error(text)
    }

    clearInputs = () => {
        this.setState({
            selectedBrand: '',
            model: null,
            engine_number: null,
            chassis_number: null,
            color: null,
            // date_delivered: null,
            location: null,
            delivery_receipt_number: null,
            // delivery_receipt_date: null,
            invoice_number: null,
            // invoice_date: null,
            price: null,
            selectedOption: '',
            selectedBranch: '',
        })

        // this.refs.brand.value = '';
        // this.refs.model.value = '';
        this.refs.engine_number.value = '';
        this.refs.chassis_number.value = '';
        this.refs.color.value = '';
        // this.refs.date_delivered.value = '';
        // this.refs.location.value = '';
        this.refs.delivery_receipt_number.value = '';
        // this.refs.delivery_receipt_date.value = '';
        this.refs.invoice_number.value = '';
        // this.refs.invoice_date.value = '';
        this.refs.price.value = '';
    }

    saveTemplate = () => {
        if (this.state.saveTemplate) {
            this.setState({saveTemplate: false})
        } else {
            this.setState({saveTemplate: true})
        }
    }

    save = (saveAndClose) => {

        const brand = this.state.selectedBrand,
            model = this.state.model,
            selectedmodel = this.state.selectedOption,
            engine_number = this.state.engine_number ? this.state.engine_number.trim() : '',
            chassis_number = this.state.chassis_number ? this.state.chassis_number.trim() : '',
            color = this.state.color ? this.state.color.trim() : '',
            date_delivered = this.state.date_delivered,
            location = this.state.selectedBranch,
            delivery_receipt_number = this.state.delivery_receipt_number ? this.state.delivery_receipt_number.trim() : '',
            delivery_receipt_date = this.state.delivery_receipt_date,
            invoice_number = this.state.invoice_number ? this.state.invoice_number.trim() : '',
            invoice_date = this.state.invoice_date,
            price = this.state.price ? this.state.price.trim() : '',
            saveTemplate = this.state.saveTemplate,
            warranty_claims = this.state.warranty_claims ? 1 : 0,
            clearances = this.state.clearances ? 1 : 0,
            tba = this.state.tba ? 1 : 0;

        const permission = this.props.userPermission;

        if (permission.inventory !== 2) {
            return;
        }

        if (this.state.saving) {
            return;
        }

        const trapper = {
            query: {
                chassis_number: chassis_number
            }
        }

        const unit = this.props.updateUnitInfo,
            trap_invoice_number = unit.invoice.invoice_number,
            current_model = unit.model,
            current_engine_number = unit.engine_number,
            current_chassis_number = unit.chas,
            current_location = unit.delivery.location;

        let {invoice_date_changed} = this.state;

        const data = {
            brand: brand.value,
            model: selectedmodel ? selectedmodel.value : null,
            engine_number: engine_number ? engine_number.toUpperCase() : '',
            chassis_number: chassis_number ? chassis_number.toUpperCase() : '',
            chas: chassis_number ? chassis_number.toUpperCase() : '',
            color: color ? color ? color.toUpperCase() : '' : '',
            branch: this.mainBranchOption(true),
            delivery: {
                date: date_delivered,
                location: 'MAIN',
                receipt_number: delivery_receipt_number ? delivery_receipt_number.trim() : '',
                receipt_date: delivery_receipt_date,
            },
            invoice: {
                invoice_number: invoice_number,
                date: trap_invoice_number ? (invoice_number ? invoice_date : '') : '',
            },
            price: price ? price.trim() : '',
            warranty_claims: warranty_claims,
            clearances: clearances,
            tba: tba,
            date_created: moment(date_delivered).format('MM/DD/YYYY'),
            year_added: moment(date_delivered).format('YYYY')
        }

        const product_id = this.props.updateUnitInfo._id,
            query = {}

        query.data_status = false;

        if (this.state.selectedBrandChanged) {
            query.brand = brand.value;
            query.unit_brand_name = brand.label;
            query.data_status = true;
        }
        if (this.state.selectChanged) {
            query.model = selectedmodel.value;
            query.unit_model_name = selectedmodel.label;
            query.data_status = true;
        }
        if (engine_number !== current_engine_number) {
            if (engine_number) {
                query.engine_number = engine_number.toUpperCase();
                query.data_status = true;
            }
        }
        if (chassis_number !== current_chassis_number) {
            if (chassis_number) {
                query.chas = chassis_number.toUpperCase();
                query.data_status = true;
            }
        }
        if (color) {
            query.color = color.toUpperCase();
            query.data_status = true;
        }

        // if(that.state.date_delivered_changed){
        // 	query.date_created = date_delivered;
        // 	query.year_added = moment(date_delivered).format('YYYY');
        // 	query.data_status = true;
        // }

        // for date received
        if (this.state.date_delivered_changed) {
            query.data_status = true;
            query.delivery = {
                receipt_number: delivery_receipt_number ? delivery_receipt_number : this.props.updateUnitInfo.delivery.receipt_number,
                date: date_delivered,
                receipt_date: this.state.delivery_receipt_date_changed ? delivery_receipt_date : this.props.updateUnitInfo.delivery.receipt_date,
                location: this.props.updateUnitInfo.delivery.location
            };
            query.year_added = moment(date_delivered).format('YYYY');
        }

        if (this.state.delivery_receipt_date_changed) {
            query.data_status = true;
            query.delivery = {
                receipt_number: delivery_receipt_number ? delivery_receipt_number : this.props.updateUnitInfo.delivery.receipt_number,
                date: this.state.date_delivered_changed ? date_delivered : this.props.updateUnitInfo.delivery.date,
                receipt_date: this.state.delivery_receipt_date_changed ? delivery_receipt_date : this.props.updateUnitInfo.delivery.receipt_date,
                location: this.props.updateUnitInfo.delivery.location
            };
        }

        if (invoice_number) {
            query.invoice = {
                invoice_number: invoice_number,
                date: invoice_date_changed ? invoice_date : this.props.updateUnitInfo.invoice.date
            };

            query.data_status = true;
            // if (that.state.invoice_date_changed){
            // }else{
            // 	query.invoice = {
            // 		invoice_number: invoice_number,
            // 	};
            // 	query.data_status = true;
            // }
        } else {

            query.invoice = {
                invoice_number: this.props.updateUnitInfo.invoice.invoice_number,
                date: invoice_date_changed ? invoice_date : this.props.updateUnitInfo.invoice.date
            };

            query.data_status = true;

            // if (trap_invoice_number){
            // 	if (that.state.invoice_date_changed){
            // 		query.invoice = {
            // 			date: invoice_date
            // 		};

            // 		query.data_status = true;
            // 	}
            // }
        }

        // for date delivered
        if (this.state.delivery_receipt_date_changed) {
            if (delivery_receipt_number) {
                query.data_status = true;
                query.receipt_number = delivery_receipt_number;
                query.delivery = {
                    receipt_number: delivery_receipt_number,
                    receipt_date: delivery_receipt_date,
                    date: this.state.date_delivered_changed ? date_delivered : this.props.updateUnitInfo.delivery.date,
                    location: this.props.updateUnitInfo.delivery.location
                };
                query.year_added = moment(this.props.updateUnitInfo.delivery.date).format('YYYY');
            } else {
                query.data_status = true;
                query.delivery = {
                    receipt_number: this.props.updateUnitInfo.delivery.receipt_number,
                    receipt_date: delivery_receipt_date,
                    date: this.state.date_delivered_changed ? date_delivered : this.props.updateUnitInfo.delivery.date,
                    location: this.props.updateUnitInfo.delivery.location
                };
                query.year_added = moment(this.props.updateUnitInfo.delivery.date).format('YYYY');
            }
        } else {
            if (delivery_receipt_number) {
                query.data_status = true;
                query.receipt_number = delivery_receipt_number;
                query.delivery = {
                    receipt_number: delivery_receipt_number,
                    receipt_date: this.props.updateUnitInfo.delivery.receipt_date,
                    date: this.state.date_delivered_changed ? date_delivered : this.props.updateUnitInfo.delivery.date,
                    location: this.props.updateUnitInfo.delivery.location
                };
                query.year_added = moment(this.props.updateUnitInfo.delivery.date).format('YYYY');
            }
        }

        // if (delivery_receipt_number){
        // 		query.delivery = {
        // 			receipt_number: delivery_receipt_number,
        // 			date: date_delivered,
        // 			location: current_location,
        // 			receipt_date: delivery_receipt_date,
        // 		};
        // 		query.receipt_number = delivery_receipt_number;
        // 		query.data_status = true;
        // }
        if (price) {
            query.price = price;
            query.data_status = true;
        }

        if (warranty_claims !== this.props.updateUnitInfo.warranty_claims) {
            if (this.state.warranty_claims_is_changed) {
                query.warranty_claims = warranty_claims;
                query.data_status = true;
            }
        }

        if (clearances !== this.props.updateUnitInfo.clearances) {
            if (this.state.clearances_is_changed) {
                query.clearances = clearances;
                query.data_status = true;
            }
        }

        if (tba !== this.props.updateUnitInfo.tba) {
            if (this.state.tba_is_changed) {
                query.tba = tba;
                query.data_status = true;
            }
        }

        if (isNaN(price)) {
            toastr.error('Please enter a valid price');
            $('[name="price"]').focus();
            return;
        }

        if (!query.data_status) {
            toastr.remove();
            toastr.info('No changes made');
            return;
        }

        this.setState({
            saving: true
        })

        if (engine_number !== current_engine_number) {
            app.service('products').find({
                query: {
                    model: current_model,
                    engine_number: engine_number
                }
            })
                .then((e_data) => {
                    if (e_data.total) {
                        toastr.remove();
                        toastr.error('Engine Number ' + engine_number + ' already exists');
                        this.setState({
                            saving: false
                        })
                    } else {
                        if(chassis_number !== ''){
                            if (chassis_number !== current_chassis_number) {
                                app.service('products').find({
                                    query: {
                                        model: current_model,
                                        chas: chassis_number,
                                    }
                                }).then((c_data) => {
                                    if (c_data.total) {
                                        toastr.remove();
                                        toastr.error('Chassis Number ' + chassis_number + ' already exists');
                                        this.setState({
                                            saving: false
                                        })
                                    } else {
                                        query.has_update = 1;
                                        app.service('products').patch(product_id, query)
                                            .then((result) => {
                                                toastr.remove();
                                                toastr.success('Unit successfully updated');
    
                                                setTimeout(() => {
                                                    this.props.history.push('/inventory');
                                                }, 2000)
                                            }).catch((err) => {
                                            console.log('Error ', err)
    
                                            this.setState({
                                                saving: false
                                            });
    
                                            if (err.name === 'Conflict') {
                                                toastr.remove();
                                                toastr.error('Chassis Number ' + chassis_number + ' already exists');
                                            } else {
                                                toastr.remove();
                                                toastr.error('An error occured!. Please try again');
                                            }
                                        })
                                    }
                                })
                            } else {
                                query.has_update = 1;
                                app.service('products').patch(product_id, query)
                                    .then((result) => {
                                        toastr.remove();
                                        toastr.success('Unit successfully updated');
    
                                        setTimeout(() => {
                                            this.props.history.push('/inventory');
                                        }, 2000)
                                    })
                                    .catch((err) => {
                                        console.log('Error ', err)
    
                                        this.setState({
                                            saving: false
                                        })
    
                                        if (err.name === 'Conflict') {
                                            toastr.remove();
                                            toastr.error('Chassis Number ' + chassis_number + ' already exists');
                                        } else {
                                            toastr.remove();
                                            toastr.error('An error occured!. Please try again');
                                        }
                                    })
                            }
                        } else {
                            query.has_update = 1;
                            app.service('products').patch(product_id, query)
                                .then((result) => {
                                    toastr.remove();
                                    toastr.success('Unit successfully updated');

                                    setTimeout(() => {
                                        this.props.history.push('/inventory');
                                    }, 2000)
                                })
                                .catch((err) => {
                                    console.log('Error ', err)

                                    this.setState({
                                        saving: false
                                    })

                                    if (err.name === 'Conflict') {
                                        toastr.remove();
                                        toastr.error('Chassis Number ' + chassis_number + ' already exists');
                                    } else {
                                        toastr.remove();
                                        toastr.error('An error occured!. Please try again');
                                    }
                                })
                        }
                    }
                })
        } else {
            if (chassis_number !== current_chassis_number) {
                app.service('products').find({
                    query: {
                        model: current_model,
                        chas: chassis_number,
                    }
                })
                    .then((c_data) => {
                        if (c_data.total) {
                            toastr.remove();
                            toastr.error('Chassis Number ' + chassis_number + ' already exists');
                            this.setState({
                                saving: false
                            })
                        } else {
                            app.service('products').patch(product_id, query)
                                .then((result) => {
                                    toastr.remove();
                                    toastr.success('Unit successfully updated');

                                    setTimeout(() => {
                                        this.props.history.push('/inventory');
                                    }, 2000)
                                })
                                .catch((err) => {
                                    console.log('Error ', err)

                                    this.setState({
                                        saving: false
                                    })

                                    if (err.name === 'Conflict') {
                                        toastr.remove();
                                        toastr.error('Chassis Number ' + chassis_number + ' already exists');
                                    } else {
                                        toastr.remove();
                                        toastr.error('An error occured!. Please try again');
                                    }
                                })
                        }
                    })
            } else {
                app.service('products').patch(product_id, query)
                    .then((result) => {
                        toastr.remove();
                        toastr.success('Unit successfully updated');

                        setTimeout(() => {
                            this.props.history.push('/inventory');
                        }, 2000)
                    })
                    .catch((err) => {
                        console.log('Error ', err)

                        this.setState({
                            saving: false
                        })

                        if (err.name === 'Conflict') {
                            toastr.remove();
                            toastr.error('Chassis Number ' + chassis_number + ' already exists');
                        } else {
                            toastr.remove();
                            toastr.error('An error occured!. Please try again');
                        }
                    })
            }
        }
    }

    branchOption = () => {
        const array = this.props.branch,
            newArray = []
        array.map((obj) => {
            if (obj.value !== 'all') {
                newArray.push(obj)
            }
        })
        return newArray
    }

    modelOption = () => {
        const array = this.props.models,
            newArray = []

        array.map((obj) => {
            if (obj.value !== 'all') {
                newArray.push(obj);
            }
        })
        return newArray;
    }

    brandOption = () => {
        const array = this.props.brandsSelect,
            newArray = []

        array.map((obj) => {
            if (obj.value !== 'all') {
                newArray.push(obj)
            }
        })
        return newArray;
    }

    handleChange = (selectedOption) => {
        const value = selectedOption;

        if (value != null) {
            this.setState({
                selectedOption: value,
                selectChanged: true
            });
        } else {
            this.setState({
                selectedOption: '',
                selectChanged: false
            })
        }
        this.setState({
            changes_made: true
        })
    }

    handleChangeBranch = (selectedOption) => {
        const value = selectedOption;

        if (value != null) {
            this.setState({
                selectedBranch: value,
                selectedBranchChanged: true
            });
        } else {
            this.setState({
                selectedBranch: '',
                selectedBranchChanged: false
            })
        }
    }

    handleChangeBrand = (selectedOption) => {
        const value = selectedOption;

        if (value != null) {
            this.setState({
                selectedBrand: value,
                selectedBrandChanged: true
            });
        } else {
            this.setState({
                selectedBrand: '',
                selectedBrandChanged: false
            })
        }
        this.setState({
            changes_made: true
        })

    }
    permissionInventory = () => {
        const permission = this.props.userPermission;

        if (permission.inventory === 0) {
            return "not-visible"
        } else {
            return "";
        }
    }

    mainBranchOption = (value = false) => {
        const array = this.props.branch,
            newArray = [];

        array.map((obj) => {
            if (obj.label === 'MAIN') {
                newArray.push(obj)
            }
        })

        return value ? newArray[0].value : newArray
    }

    handleDateDelivered = (date) => {
        this.setState({
            date_delivered_changed: true,
            date_delivered: date
        })
    }

    handleInvoiceDate = (date) => {
        this.setState({
            invoice_date_changed: true,
            invoice_date: date
        })
    }

    handleDeliveryReceiptDate = (date) => {
        this.setState({
            delivery_receipt_date: date,
            delivery_receipt_date_changed: true
        })
    }

    // handleDateDelivered = (e, p) => {
    // 	const d = p.startDate;
    // 	const formatted = moment(d).format('MM/DD/YYYY');

    // 	this.setState({
    // 		date_delivered: formatted,
    // 		date_delivered_changed: true,
    // 	})
    //     this.setState({
    //     	changes_made: true
    //     })
    // }

    // handleInvoiceDate = (e,p) => {
    // 	const d = p.startDate;
    // 	const formatted = moment(d).format('MM/DD/YYYY');

    // 	this.setState({
    // 		invoice_date: formatted,
    // 		invoice_date_changed: true,
    // 	})
    //     this.setState({
    //     	changes_made: true
    //     })
    // }

    // handleDeliveryReceiptDate = (e,p) => {
    // 	const d = p.startDate;
    // 	const formatted = moment(d).format('MM/DD/YYYY');

    // 	this.setState({
    // 		delivery_receipt_date: formatted,
    // 		delivery_receipt_date_changed: true,
    // 	})
    //     this.setState({
    //     	changes_made: true
    //     })
    // }

    warranty_change = (e) => {
        const val = e.target.checked;
        this.setState({
            warranty_claims: val,
            warranty_claims_is_changed: true,
        })
        this.setState({
            changes_made: true
        })
    }

    tba_change = (e) => {
        const val = e.target.checked;
        this.setState({
            tba: val,
            tba_is_changed: true,
        })
        this.setState({
            changes_made: true
        })
    }

    clearances = (e) => {
        const val = e.target.checked;
        this.setState({
            clearances: val,
            clearances_is_changed: true,
        })
    }

    render() {
        const unit = this.props.updateUnitInfo,
            brand = (unit.brands == null ? (unit.unit_brand_name ? unit.unit_brand_name : '--') : unit.brands.brand_name),
            branch = unit.branches.branch_name,
            model = (unit.motorModels == null ? (unit.unit_model_name ? unit.unit_model_name : '--') : unit.motorModels.model_name),
            engine_number = unit.engine_number,
            chassis_number = unit.chas,
            color = unit.color,
            date_created = unit.date_created,
            date_received = unit.delivery.date,
            delivery_receipt_number = unit.delivery.receipt_number,
            delivery_receipt_date = unit.delivery.receipt_date,
            invoice_number = unit.invoice.invoice_number,
            invoice_date = unit.invoice.date !== null ? unit.invoice.date : '',
            price = unit.price;

        return (
            <div className="">
                {/* main NavBar component*/}
                <MainNav historyProp={this.props.history} usersProp={this.props.userData}/>
                <div className="container-fluid">
                    <div className="row full-width">
                        <div className="sidebar">
                            <div className="sidebar-content">
                                <MainSideBar page="inventory"/>
                            </div>
                        </div>
                        <SubSideBar history={this.props.history} userPermission={this.props.userPermission}/>
                        <div className="sub-inv-main-content add_inventory">
                            <div className="application-container">
                                {
                                    this.props.userPermission.inventory === 0 ?
                                        <div className="row no-access-div">
                                            <div className="col-md-12">
                                                <h1>Access denied</h1>
                                            </div>
                                        </div>
                                        : null
                                }
                                <div className={this.permissionInventory()}>
                                    <div className="row margin-y-24">
                                        <div className="col-md-12">
                                            <a href="javascript:;"
                                               onClick={() => this.props.history.push('/inventory/')}
                                               className="breadcrumb-inv no-textdecoration">
                                                <span className="fa fa-chevron-left"/> Inventory / {brand + ' ' + model}
                                            </a>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <h4 className="subheader-title">Update Unit</h4>
                                                    <br/>
                                                    <div className="inv-form__wrapper">
                                                        <div className="inv-form__col">
                                                            <div className="inv-form__div">
                                                                <span>Brand : <b>{brand}</b></span>
                                                                <Select
                                                                    name="selectBrand"
                                                                    value={this.state.selectedBrand.value}
                                                                    onChange={this.handleChangeBrand}
                                                                    options={this.brandOption()}
                                                                    clearableValue={true}
                                                                    placeholder="Select Brand"
                                                                />
                                                            </div>
                                                            <div className="inv-form__div">
                                                                <span>Model : <b>{model}</b></span>
                                                                <Select
                                                                    name="form-field-name"
                                                                    value={this.state.selectedOption}
                                                                    onChange={this.handleChange}
                                                                    options={this.modelOption()}
                                                                    clearableValue={true}
                                                                    placeholder="Select Model"
                                                                />
                                                            </div>
                                                            <div className="inv-form__div">
                                                                <span>Engine Number
                                                                : <b>{engine_number}</b></span>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    name="engine_number"
                                                                    ref="engine_number"
                                                                    onChange={(e) => this.setState({engine_number: e.target.value})}
                                                                    maxLength={20}
                                                                />
                                                            </div>
                                                            <div className="inv-form__div">
                                                                <span>Chassis Number
                                                                : <b>{chassis_number}</b></span>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    name="chassis_number"
                                                                    ref="chassis_number"
                                                                    onChange={(e) => this.setState({chassis_number: e.target.value})}
                                                                    maxLength={20}
                                                                />
                                                            </div>
                                                            <div className="inv-form__div">
                                                                <span>Color : <b>{color}</b></span>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    name="color"
                                                                    ref="color"
                                                                    onChange={(e) => this.setState({color: e.target.value})}
                                                                />
                                                            </div>
                                                            <div className="inv-form__div">
                                                                <span>Date Received
                                                                : <b>{moment(date_received).format('MM/DD/YYYY')}</b></span>
                                                                <DatePicker
                                                                    selected={this.state.date_delivered}
                                                                    onChange={this.handleDateDelivered}
                                                                    peekNextMonth
                                                                    showMonthDropdown
                                                                    showYearDropdown
                                                                    dropdownMode="select"
                                                                    className="form-control"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="inv-form__col">
                                                            <div className="inv-form__div">
                                                                <span>Branch</span>
                                                                <Select
                                                                    name="selectBranch"
                                                                    // value={this.state.selectedBranch.value}
                                                                    value={{label: branch, value: branch}}
                                                                    onChange={this.handleChangeBranch}
                                                                    options={[{label: branch, value: branch}]}
                                                                    clearableValue={true}
                                                                    placeholder="Select Branch"
                                                                    disabled={true}
                                                                />
                                                            </div>
                                                            <div className="inv-form__div">
                                                                <span>Delivery Receipt No. : <b>{delivery_receipt_number}</b></span>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    name="delivery_receipt_number"
                                                                    ref="delivery_receipt_number"
                                                                    onChange={(e) => this.setState({delivery_receipt_number: e.target.value})}
                                                                    maxLength={20}
                                                                />
                                                            </div>
                                                            <div className="inv-form__div">
                                                                <span>Delivery Receipt Date
                                                                : <b>{delivery_receipt_date !== '--' ? moment(delivery_receipt_date).format('MM/DD/YYYY') : delivery_receipt_date}</b></span>
                                                                <DatePicker
                                                                    selected={this.state.delivery_receipt_date}
                                                                    onChange={this.handleDeliveryReceiptDate}
                                                                    peekNextMonth
                                                                    showMonthDropdown
                                                                    showYearDropdown
                                                                    dropdownMode="select"
                                                                    className="form-control"
                                                                />
                                                            </div>
                                                            <div className="inv-form__div">
                                                                <span>Invoice Number
                                                                : <b>{invoice_number ? invoice_number : '--'}</b></span>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    name="invoice_number"
                                                                    ref="invoice_number"
                                                                    onChange={(e) => this.setState({invoice_number: e.target.value})}
                                                                    maxLength={20}
                                                                />
                                                            </div>
                                                            <div className="inv-form__div">
                                                                <span>Invoice Date
                                                                : <b>{invoice_date ? moment(invoice_date).format('MM/DD/YYYY') : '--'}</b></span>
                                                                <DatePicker
                                                                    selected={this.state.invoice_date}
                                                                    onChange={this.handleInvoiceDate}
                                                                    peekNextMonth
                                                                    showMonthDropdown
                                                                    showYearDropdown
                                                                    dropdownMode="select"
                                                                    className="form-control"
                                                                />
                                                            </div>
                                                            <div className="inv-form__div">
                                                                <span>Price
                                                                : <b>{numberWithCommas(price ? price : 0)}</b></span>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    name="price"
                                                                    ref="price"
                                                                    onChange={(e) => this.setState({price: e.target.value})}
                                                                    maxLength={13}
                                                                />
                                                            </div>
                                                        </div>
                                                        <br/>
                                                        <br/>
                                                        <div className="row">
                                                            <div className="col">
                                                                <span>Warranty Claims </span>
                                                                <input type="checkbox"
                                                                       onChange={(e) => this.warranty_change(e)}
                                                                       ref="warranty_claims"/>
                                                            </div>
                                                            <div className="col">
                                                                <span>Clearances </span>
                                                                <input type="checkbox"
                                                                       onChange={(e) => this.clearances(e)}
                                                                       ref="clearances"/>
                                                            </div>
                                                            <div className="col">
                                                                <span>TBA's </span>
                                                                <input type="checkbox"
                                                                       onChange={(e) => this.tba_change(e)}
                                                                       ref="tba"/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <br/>
                                                    <div className="row">
                                                        <div className="col">
                                                            <div className="form-check">
                                                                {
                                                                    // <label className="form-check-label">
                                                                    //   <input className="form-check-input" onChange={() => this.saveTemplate() } type="checkbox" value="" />
                                                                    //   	Save template for next entry <br/>
                                                                    //   	(Except Chassis and Engine Number)
                                                                    // </label>
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className="col">
                                                            <div className="row">
                                                                <div className="col">
                                                                </div>
                                                                <div className="col">
                                                                    <button
                                                                        className="btn btn-block btn-second-primary"
                                                                        onClick={() => this.save()}
                                                                    >
                                                                        Save Changes
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


const mapStateToProps = state => ({
    models: state.category.modelsSelect,
    branch: state.category.branchesSelect,
    brandsSelect: state.category.brandsSelect,
    userData: state.login.userData,
    userPermission: state.login.userPermission,
    updateUnitInfo: state.product.updateUnitInfo,
});

function mapDispatchToProps(dispatch) {
    return {actions: bindActionCreators(ActionCreators, dispatch)}
}

export default connect(mapStateToProps, mapDispatchToProps)(update_inventory);

