export const ADD_MODEL = "ADD_MODEL"; 
export const MODEL_DATATABLES = "MODEL_DATATABLES";
export const UPDATE_MODEL_INFO = "UPDATE_MODEL_INFO";

//branches
export const BRANCH_DATATABLES = "BRANCH_DATATABLES";
export const UPDATE_BRANCH_INFO = "UPDATE_BRANCH_INFO";

//brands
export const BRAND_DATATABLES = "BRAND_DATATABLES";
export const UPDATE_BRAND_INFO = "UPDATE_BRAND_INFO";
export const SELECT_OPT_BRANDS = "SELECT_OPT_BRANDS";


//positions
export const POSITIONS_DATATABLES = "POSITIONS_DATATABLES";
export const UPDATE_DESIGNATION_INFO = "UPDATE_DESIGNATION_INFO";

