import React, { Component } from 'react';

//redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// actions
import { ActionCreators } from '../../../actions';

import {
    encodeToString,
    leadingZero,
} from '../../../helpers'

import app from '../../../helpers/feathers'

import toastr from 'toastr';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

toastr.options.fadeOut = 2500;

const $ = require('jquery');

class delete_account_modal extends Component {

	constructor(props) {
	  	super(props);
	  	this.state = {
	  	};
	}

	closeModel = () => {
		$('.modal').modal('hide');
	}

	deleteAccount = () => {
		const that = this;
		let { customer } = this.props;

		if(typeof customer.payment_method === 'undefined'){
			app.service('customers').remove(customer._id)
			.then(() => {
				that.props.actions.deleteSchedAndPayments(customer.account_number,customer.unit)
				.then(() => {
					toastr.success(`${customer.account_number ? customer.account_number : "CASH"} - ${customer.name} has been successfully removed`)
					// that.props.successRemoving();
					that.props.callBack();
					that.closeModel();
				})
			})
			.catch(() => {
				toastr.error('An error occured. Please try again');
				// that.props.errorRemoving();
				that.props.callBack();
				that.closeModel();
			})
		}else{
			app.service('customer-secondhand-unit').remove(customer._id)
			.then(() => {
				that.props.actions.deleteSchedAndPaymentsSh(customer.account_number,customer.unit_id)
				.then(() => {
					toastr.success(`${customer.account_number ? customer.account_number : "CASH"} - ${customer.name} has been successfully removed`)
					// that.props.successRemoving();
					that.props.callBack();
					that.closeModel();
				})
			})
			.catch(() => {
				toastr.error('An error occured. Please try again');
				// that.props.errorRemoving();
				that.props.callBack();
				that.closeModel();
			})
		}
	}

  	render() {
  		const { customer } = this.props;

    	return (
      		<div className="modal fade" id="delete_account" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" data-backdrop="static">
			  	<div className="modal-dialog" role="document">
			    	<div className="modal-content">
			      		<div className="modal-header">
			        		<h5 className="modal-title" id="exampleModalLabel">Delete Account</h5>
			        			<button type="button" className="close" data-dismiss="modal" aria-label="Close">
						          <span aria-hidden="true">&times;</span>
						        </button>
			      		</div>
				      	<div className="modal-body">
				        	<div className="form-group">
				        		<label>Are you sure you sure you want to delete this account? <br />
				        		<b>{customer ? (customer.account_number ? customer.account_number : "CASH"+' - '+customer.name) : null}</b></label>
				        	</div>
				      	</div>
				      	<div className="modal-footer">
					        	<button type="button" className="btn btn-secondary" data-dismiss="modal" ref="cancel">Cancel</button>
					        	<button type="button" className="btn btn-danger" onClick={this.deleteAccount}>YES</button>
				      	</div>
			    	</div>
			  	</div>
			</div>
    	);
  	}
}


const mapStateToProps = state => ({
   	category: state.category,
   	app: state.feathersClient.app,
   	customer: state.customers.customerInfo,
   	session_id: state.login.userData._id
});

function mapDispatchToProps(dispatch) {
   return { actions: bindActionCreators(ActionCreators, dispatch) }
}

export default connect(mapStateToProps, mapDispatchToProps)(delete_account_modal);