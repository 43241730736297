import React, {Component} from 'react';
import toastr from 'toastr';

//redux
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
// actions
import {ActionCreators} from '../../../actions';

import '../report/plugins/daterangepicker.css';
import Inventory from './inventory';
import app from "../../../helpers/feathers";
import moment from "moment";

const $ = require('jquery');

class ConfirmationDelete extends Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    componentWillMount() {
    }

    accept = () => {
        const id = this.props.deleteId, service = this.props.deleteService;
        app.service(service).remove(id)
            .then((d) => {
                let name;
                if (service === 'users') {
                    name = 'User';
                } else if (service === 'secondhand') {
                    name = 'Unit';
                } else if (service === 'products') {
                    name = 'Unit';
                } else if (service === 'branches') {
                    name = 'Branch';
                } else if (service === 'brands') {
                    name = 'Brand';
                } else if (service === 'motorcycle-models') {
                    name = 'Model';
                } else if (service === 'reports') {
                    name = 'Report';
                }
                toastr.remove();
                toastr.success(name + ' has been successfully deleted');
                this.refs.cancel.click();
            }).catch((error) => {
            console.log(error);
            toastr.error('There was an error');
        });
    }

    closeModel = () => {
        $('.modal').modal('hide');
    }

    componentDidMount() {

    }

    render() {
        return (
            <div className="modal fade" id="confirm_delete" tabIndex="-1" role="dialog"
                 aria-labelledby="exampleModalLabel" aria-hidden="true" ref="confirm_delete" data-backdrop="static">
                <div className="modal-dialog modal-sm" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Confirmation</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="form-group">
                                <span>Are you sure you want to delete?</span>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal"
                                    ref="cancel">Cancel
                            </button>
                            <button type="button" className="btn btn-primary" onClick={this.accept}>Confirm</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


const mapStateToProps = state => ({
    category: state.category,
    app: state.feathersClient.app,
    mainapp: state.feathersClient.mainapp,
    deleteId: state.product.deleteId,
    deleteService: state.product.deleteService,
    userData: state.login.userData,
});

function mapDispatchToProps(dispatch) {
    return {actions: bindActionCreators(ActionCreators, dispatch)}
}

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmationDelete);