import React, {Component} from 'react';

//redux
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
// actions
import {ActionCreators} from '../../../actions';

//main NavBar
import MainNav from '../../../components/global_nav';
import MainSideBar from '../../../components/global_sidebar';

//custom sidebar
import SubSideBar from './sub_sidebar';

//select tag
import Select from 'react-select';

import toastr from 'toastr';

import app from '../../../helpers/feathers';

import AddInvoiceModal from './add_invoice_modal'
import AddPriceModal from './add_price_modal'

//daterange
import moment from 'moment';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import '../report/plugins/daterangepicker.css';

//custom styles
import '../../../stylesheet/styles.css';

//datatables
import '../../../stylesheet/datatables/datatables.css';

const $ = require('jquery');
$.DataTable = require('datatables.net');

let that;

class search_dr extends Component {

    constructor(props) {
        super(props);

        this.state = {
            filter_dr_number: '',
            selectedUnitsByDr: []
        };
    }

    componentWillMount() {
    }

    componentDidMount() {
        that = this;
        const productTable = $('.tbl-search-dr').DataTable({
            data: this.state.products,
            columns: [
                {"title": "model object"},
                {"title": "MODEL"},
                {"title": "CHASSIS NO."},
                {"title": "ENGINE NO."},
                {"title": "BRANCH"},
                {"title": "DATE RECEIVED"},
                {"title": ""},
            ],
            columnDefs: [
                {
                    targets: [6],
                    orderable: false,
                    width: '15%',
                },
                {
                    targets: [0],
                    visible: false
                }
            ],
            'dom': "<'row'<'col-sm-6'l><'col-sm-6'p>>" +
                "<'row'<'col-sm-12'tr>>" +
                "<'row'<'col-sm-12'i>>",
        });

        $('.search').keyup(function () {
            productTable.search($(this).val()).draw();
        });

        $('.tbl-search-dr').on('click', '.clearance', function () {
            const data = productTable.row($(this).parents('tr')).data();
            let id = data[0]._id;
            app.service('products').patch(id, {
                clearances: 1
            }).then(() => {
                toastr.success('Unit successfully updated.')
                that.advancedFilter()
            }).catch(() => {
                toastr.error('An error occured')
            });
        });

        $('.tbl-search-dr').on('click', '.warranty', function () {
            const data = productTable.row($(this).parents('tr')).data();
            let id = data[0]._id;
            app.service('products').patch(id, {
                warranty_claims: 1
            }).then(() => {
                toastr.success('Unit successfully updated.')
                that.advancedFilter()
            }).catch(() => {
                toastr.error('An error occured')
            });
        });

        $('.tbl-search-dr').on('click', '.tba', function () {
            const data = productTable.row($(this).parents('tr')).data();
            let id = data[0]._id;
            app.service('products').patch(id, {
                tba: 1
            }).then(() => {
                toastr.success('Unit successfully updated.')
                that.advancedFilter()
            }).catch(() => {
                toastr.error('An error occured')
            });
        });

        $('.tbl-search-dr').on('click', '.edit', function () {
            const data = productTable.row($(this).parents('tr')).data();
            const permission = that.props.userPermission;

            if (permission.inventory !== 2) {
                return;
            }

            that.props.actions.updateUnitInfo(data[0]);
            that.props.history.push('/update_inventory')
        })
    }

    getAllUnits = () => {
        const query = {
            type: 0,
            transferred: {
                $ne: 1
            }
        }

        this.props.actions.inventoryAdvancedFilter(query)
            .then((data) => {
                this.productList(data);
            })
    }

    productList = data => {
        this.setState({
            products: data,
            product_count: data.length
        });

        const table = $('.tbl-search-dr').DataTable();
        table.clear();
        table.rows.add(data);
        table.draw();

    }

    advancedFilter = () => {
        let {dr_number} = this.refs;

        if (dr_number.value === '') {
            dr_number.value = '';
            this.productList([]);
            return;
        }

        const query = {
            receipt_number: {
                $regex: dr_number.value
            },
            // receipt_number: dr_number.value
        };

        this.props.actions.searchDr(query)
            .then((data) => {
                if (data) {
                    let unitIds = [];

                    data.map((v) => {
                        unitIds.push(v[0]._id)
                    });

                    this.setState({
                        selectedUnitsByDr: unitIds
                    });

                    this.productList(data);
                }
            })
    }

    permissionInventory = () => {
        const permission = this.props.userPermission;

        if (permission.inventory === 0) {
            return "not-visible"
        } else {
            return "";
        }
    }

    render() {
        return (
            <div className="">
                {/* main NavBar component*/}
                <MainNav historyProp={this.props.history} usersProp={this.props.userData}/>
                <AddInvoiceModal ids={this.state.selectedUnitsByDr}/>
                <AddPriceModal ids={this.state.selectedUnitsByDr}/>
                <div className="container-fluid">
                    <div className="row full-width">
                        <div className="sidebar">
                            <div className="sidebar-content">
                                <MainSideBar page="inventory"/>
                            </div>
                        </div>
                        {
                            /*<div className="row background-white"><h1>You have no access to this page</h1></div> */}
                        <SubSideBar history={this.props.history} allProps={this.props}
                                    userPermission={this.props.userPermission} pageName="search_dr"/>
                        <div className="sub-inv-main-content">
                            <div className="container-fluid">
                                <div className="application-container">
                                    {
                                        this.props.userPermission.inventory === 0 ?
                                            <div className="row no-access-div">
                                                <div className="col-md-12">
                                                    <h1>Access denied</h1>
                                                </div>
                                            </div>
                                            : null
                                    }
                                    <div className={this.permissionInventory()}>
                                        <div className="row my-inventory">
                                            <div className="col-md-12">
                                                <div className="form-inline">
                                                    <h4 className="subheader-title">Search Units by Delivery Receipt
                                                        Number</h4>
                                                </div>
                                                <div className="form-inline">
                                                    <div className="row">
                                                        <div className="col">
                                                            <input
                                                                className="form-control full-width"
                                                                placeholder="Enter D.R. No."
                                                                ref="dr_number"
                                                                onKeyUp={this.advancedFilter}
                                                                // onChange={(e) => this.setState({filter_dr_number: e.target.value })}
                                                            />
                                                        </div>
                                                        <div className="col">
                                                            <button className="btn btn-block btn-second-primary"
                                                                    onClick={() => this.advancedFilter()}>Search
                                                            </button>
                                                        </div>
                                                        {this.state.selectedUnitsByDr.length ?
                                                            <div className="col">
                                                                <button className="btn btn-block btn-second-primary"
                                                                        data-toggle="modal"
                                                                        data-target="#add_invoice">Add
                                                                    Invoice
                                                                </button>
                                                            </div> : null
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            {/*<div className="col-md-12">
                                                <span>Result : {this.state.product_count}</span>
                                            </div>*/}
                                            <div className="col-md-12">
                                                <br/>
                                            </div>
                                            <div className="col-md-12">
                                                <table className="table tbl-search-dr tbl-mobile">
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    app: state.feathersClient.app,
    product: state.product,
    models: state.category.modelsSelect,
    branch: state.category.branchesSelect,
    brandsSelect: state.category.brandsSelect,
    selectmodellist: state.category.modelsSelect,
    userData: state.login.userData,
    userPermission: state.login.userPermission,
});

function mapDispatchToProps(dispatch) {
    return {actions: bindActionCreators(ActionCreators, dispatch)}
}

export default connect(mapStateToProps, mapDispatchToProps)(search_dr);
