import React, { Component } from 'react';

//redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// actions
import { ActionCreators } from '../../../actions';

// react-router
// import { Link } from 'react-router-dom';


class select_customer_modal extends Component {

	constructor(props) {
	  super(props);
	
	  this.state = {};
	}

	addCustomer = (type) => {
		var customerType = type === 'cash' ? 0 : 1;
		this.props.actions.setCustomerType(customerType);
		this.props.history.push('/customer/add_customer/'+type);
	}

  	render() {
    	return (
			<div className="modal fade" id="selectCustomerModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
				<div className="modal-dialog" role="document">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title" id="exampleModalLabel">New Customer</h5>
							<button type="button" className="close" data-dismiss="modal" aria-label="Close">
							<span aria-hidden="true">&times;</span>
							</button>
						</div>
						<div className="modal-body">
							<div className="padding-20">
								<div className="col-md-12 d-flex align-items-center flex-column">
									<div className="col-md-12 d-flex align-items-center flex-column">
										<button onClick={()=> this.addCustomer('cash')} className="btn btn-lg col-md-8 add-customer-modal" data-dismiss="modal">Cash</button>
									</div>
									<div className="col-md-12 d-flex align-items-center flex-column">
										<p className="text-center"></p>
										<p className="text-center">or</p>
									</div>
									<div className="col-md-12 d-flex align-items-center flex-column">
										<button onClick={()=> this.addCustomer('installment')} className="btn btn-lg col-md-8 add-customer-modal" data-dismiss="modal">Installment</button>
									</div>			
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
    	);
  }
}


const mapStateToProps = state => ({
   loginStatus: state.login.loginStatus
});

function mapDispatchToProps(dispatch) {
   return { actions: bindActionCreators(ActionCreators, dispatch) }
}

export default connect(mapStateToProps, mapDispatchToProps)(select_customer_modal);