import React, {Component} from 'react';

// react-router
import {Link} from 'react-router-dom';

// redux
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
// actions
import {ActionCreators} from '../../../actions';


// main NavBar
import MainNav from '../../../components/global_nav';
import MainSideBar from '../../../components/global_sidebar';

import default_avatar from '../../../assets/icons/default_avatar.jpg';

//custom sidebar
import SubSideBar from './sub_sidebar';

// custom styles
import '../../../stylesheet/styles.css';

class user_info extends Component {


    constructor(props) {
        super(props);

        this.state = {};
    }

    componentDidMount() {
        // console.log(this.props);

    }

    render() {
        return (
            <div className="">
                {/* main NavBar component*/}
                <MainNav historyProp={this.props.history} usersProp={this.props.userData}/>

                <div className="container-fluid">
                    <div className="row full-width">
                        <div className="sidebar">
                            <div className="sidebar-content">
                                <MainSideBar page="user"/>
                            </div>
                        </div>
                        <SubSideBar history={this.props.history} userPermission={this.props.userPermission}/>
                        <div className="sub-main-content">
                            <div className="application-container">
                                <div className="row">
                                    <div className="col-md-12">
                                        {/*<ul className="nav nav-tabs" role="tablist">
                                                <li role="presentation" className="active"><a href="#home" aria-controls="home" role="tab" data-toggle="tab">PROFILE</a></li>
                                            </ul>*/}
                                        <button onClick={() => this.props.history.goBack()}
                                                className="text-gray btn-no-style breadcrumb-inv"><span
                                            className="fa fa-chevron-left"/> User
                                            / {this.props.user.fullname}
                                        </button>
                                        <br/>
                                        <br/>
                                        <div className="tab-content background-white">
                                            <div role="tabpanel" className="tab-pane user-info active" id="home">
                                                <div className="">
                                                    {/*<div className="col-md-2">
                                                            <img src={default_avatar} className="avatar" alt="logo"/>
                                                        </div>*/}
                                                    <div className="row">
                                                        <div className="col">
                                                            <div className="col">
                                                                <span>Name</span>
                                                            </div>
                                                            <div className="col">
                                                                <span><b>{this.props.user.fullname}</b></span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <br/>
                                                    <div className="row">
                                                        <div className="col">
                                                            <div className="col">
                                                                <span>Position</span>
                                                            </div>
                                                            <div className="col">
                                                                <span><b>{this.props.user.user_position_info !== undefined ? this.props.user.user_position_info.position_type : ''}</b></span>
                                                            </div>
                                                        </div>
                                                        <div className="col">
                                                            <div className="col">
                                                                <span>Branch</span>
                                                            </div>
                                                            <div className="col">
                                                                <span><b>{this.props.user.branch_info !== undefined ? this.props.user.branch_info.branch_name : ''}</b></span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <br/>
                                                    <div className="row">
                                                        <div className="col">
                                                            <div className="col">
                                                                <span>Username</span>
                                                            </div>
                                                            <div className="col">
                                                                <span><b>{this.props.user.username}</b></span>
                                                            </div>
                                                        </div>
                                                        <div className="col">
                                                            <div className="col">
                                                                <span>Email</span>
                                                            </div>
                                                            <div className="col">
                                                                <span><b>{this.props.user.email}</b></span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <br/>
                                                    <div className="row">
                                                        <div className="col">
                                                            <div className="col">
                                                                <span>Address</span>
                                                            </div>
                                                            <div className="col">
                                                                <span><b>{this.props.user.address}</b></span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


const mapStateToProps = state => ({
    user: state.users.userInfo,
    userData: state.login.userData,
    userPermission: state.login.userPermission,
});

function mapDispatchToProps(dispatch) {
    return {actions: bindActionCreators(ActionCreators, dispatch)}
}

export default connect(mapStateToProps, mapDispatchToProps)(user_info);
